import { Observable, Subscription } from 'rxjs'

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild
} from '@angular/core'
import { Dictionary } from '@ngrx/entity'
import { Store } from '@ngrx/store'

import { AppConfigService } from '../services/app-config.service'
import { ComponentStateService } from '../../core/services/component-state.service'
import { ConversionRatesRecord } from '../reducers/job-stage-stats.reducer'
import { DateFilter } from '../../shared/components/filters/date-filter/date-filter.interface'
import { EeocFilteredJobAnalytics, EeocFilters,
  JobAnalytics, isAnythingInEeocSet
} from '../reducers/job-analytics.reducer'
import {
  EeocFiltersOnJobAnalyticsModalComponent
} from './eeoc-filters-on-job-analytics-modal/eeoc-filters-on-job-analytics-modal.component'
import { ExternalUser } from '../models/external-user'
import { FetchJobAnalytics, UpdateEeocFiltersForJob } from '../actions/jobs.actions'
import { Job } from '../models/job'
import { JobAnalyticsClipboardService } from '../services/JobAnalyticsClipboardService'
import { JobStage } from '../models/job-stage'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { PdfComponentTrackerService } from '../../shared/services/pdf-component-tracker.service'
import { SegmentService } from '../../core/services/segment.service'
import { ShowGenericTableModal } from '../../core/actions/generic-table.actions'
import { WidgetDataType } from '../models/offer'
import { atsId } from '../models/types'
import { calcJobAnalyticsMaxHeight, totalProcessed } from '../../shared/utils/general-utils'
import { getProjectedHiresNumberForJob, getSelectEeocJobAnalytics,
  getSelectJobAnalytics, selectEeocFilters, selectExternalUserEntities
} from '../reducers'
import { isEqual } from 'lodash-es'
import { selectImmediately } from '../../shared/utils/store.utils'

@Component({
  selector: 'twng-job-analytics',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './job-analytics.component.html',
  styleUrls: ['./job-analytics.component.scss'],
  providers: [PdfComponentTrackerService],
})
export class JobAnalyticsComponent implements OnInit, OnDestroy {
  @Input()
    job: Job

  @Input()
    jobStages: JobStage[]

  @ViewChild('analyticsContainer') analyticsContainer: ElementRef

  @Input()
    exportingToPdf = false

  jobAnalytics: JobAnalytics
  jobAnalyticsSub: Subscription
  totalInterviewTime: number
  eeocJobAnalytics: EeocFilteredJobAnalytics
  isCopyingToClipboard$: Observable<boolean>
  eeocFilters: EeocFilters
  userEntities$: Observable<Dictionary<ExternalUser>>
  dateFilters: DateFilter

  yAxisMax = 0

  // Pass this function to HTML, so it can be called from tere
  isNaN = isNaN

  get stateCacheKey() {
    return `job-analytics-${this.job.id}`
  }

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    public appConfig: AppConfigService,
    private jobAnalyticsClipboardService: JobAnalyticsClipboardService,
    private segmentService: SegmentService,
    private modal: NgbModal,
    private pdf: PdfComponentTrackerService,
    private state: ComponentStateService<Partial<JobAnalyticsComponent>>,
  ) {
    this.isCopyingToClipboard$ = this.jobAnalyticsClipboardService.isCopyingToClipboard$
    this.userEntities$ = this.store.select(selectExternalUserEntities)
  }

  ngOnInit(): void {
    let previousState = this.state.tryGetAndDestroy(this.stateCacheKey)
    if (previousState) {
      Object.assign(this, previousState)
    } else if (!window.twng_demo) {
      this.store.dispatch(new FetchJobAnalytics({ job: this.job }))
    }

    this.userEntities$ = this.store.select(selectExternalUserEntities)
    const jobAnalyticsSelector = getSelectJobAnalytics(this.job.id)
    this.jobAnalyticsSub = this.store.select(jobAnalyticsSelector).subscribe(jobAnalytics => {
      this.jobAnalytics = jobAnalytics
      this.refreshMaxGraphHeight()

      if (jobAnalytics) {
        this.pdf.componentIsReady()
        this.totalInterviewTime = Object.values(jobAnalytics.time_interviewing || [])
          .reduce((sum, x) => sum + x.value, 0)
      }

      this.cd.markForCheck()
    })

    let trackStep = 0
    this.jobAnalyticsSub.add(
      this.store.select(selectEeocFilters).subscribe(filters => {
        if (!isEqual(filters, this.eeocFilters)) {
          this.store.dispatch(
            new UpdateEeocFiltersForJob({ eeocFilters: filters, jobId: this.job.id, dateFilters: this.dateFilters })
          )
          if (isAnythingInEeocSet(filters)) {
            trackStep = 2
          }
        }
        this.eeocFilters = filters
      })
    )

    const jobEeocAnalyticsSelector = getSelectEeocJobAnalytics(this.job.id)
    this.jobAnalyticsSub.add(this.store.select(jobEeocAnalyticsSelector).subscribe(jobAnalytics => {
      this.eeocJobAnalytics = jobAnalytics
      if (trackStep === 1) {
        this.pdf.componentIsReady()
      }
      trackStep--
      this.cd.markForCheck()
    }))

    // we unassign this variable so that any subsequent subscriptions above
    // wouldn't try to recover previous state any more. It should only be
    // recovered once, upon entering ngOnInit
    previousState = null
  }

  private refreshMaxGraphHeight() {
    this.yAxisMax = calcJobAnalyticsMaxHeight(this.jobAnalytics?.dropoff_rates)
  }

  dateFiltersChanged(dateFilter: DateFilter) {
    this.store.dispatch(new FetchJobAnalytics({
      job: this.job,
      filters: {
        dateFilter
      }
    }))
    this.dateFilters = dateFilter
    if (this.appConfig.config.feature_flags.enable_eeoc_and_demographic && this.eeocFilters) {
      this.store.dispatch(
        new UpdateEeocFiltersForJob({ eeocFilters: this.eeocFilters, jobId: this.job.id, dateFilters: dateFilter })
      )
    }
  }

  ngOnDestroy(): void {
    this.state.store(this.stateCacheKey, {
      dateFilters: this.dateFilters,
      eeocFilters: this.eeocFilters,
    })
    this.jobAnalyticsSub.unsubscribe()
  }

  totalProcessed(conversionRate: ConversionRatesRecord) {
    totalProcessed(conversionRate)
  }

  offerModal(offerIds) {
    if (offerIds) {
      this.store.dispatch(new ShowGenericTableModal({
        chart: WidgetDataType.OFFER,
        clickedGraphTitle: 'Offers',
        data_ids: offerIds,
      }))
    }
  }

  appsModal(appIds, title?: string) {
    if (appIds) {
      this.store.dispatch(new ShowGenericTableModal({
        chart: WidgetDataType.CANDIDATE,
        clickedGraphTitle: title || 'Offers',
        data_ids: appIds,
      }))
    }
  }

  trackStageById(_index: number, stage: JobStage) {
    return stage ? stage.id : null
  }

  dropoffRate(stageId, eeoc): number {
    const dropoffRates = eeoc ? this.eeocJobAnalytics.dropoff_rates : this.jobAnalytics.dropoff_rates
    if (!dropoffRates || !dropoffRates[stageId]) {
      return 0
    }
    return dropoffRates[stageId].dropoff_percent || 0
  }

  totalInterviewTimeString() {
    const timeInterviewing = this.totalInterviewTime
    if (!timeInterviewing) {
      return 0
    }
    return Math.round(timeInterviewing / 360) / 10
  }

  candidatesInterviewedForStage(stageId: atsId) {
    if (!this.jobAnalytics || !this.jobAnalytics.candidates_interviewed[stageId]) {
      return 0
    }
    return this.jobAnalytics.candidates_interviewed[stageId].value
  }

  hiringTeamSize(): number {
    const job = this.job

    return (job.recruiter_ids || []).length +
      (job.coordinator_ids || []).length +
      (job.sourcer_ids || []).length +
      (job.hiring_manager_ids || []).length
  }

  calcProjectedHires(): Promise<number> {
    return selectImmediately(this.store, getProjectedHiresNumberForJob(this.job.id))
  }

  getHiresAppIds() {
    const allHiredCandidates: string[] = []
    for (const stage of Object.values(this.jobAnalytics.dropoff_rates)) {
      allHiredCandidates.push(...stage.hired_app_ids.filter(Boolean).map(v => v.toString()))
    }
    return allHiredCandidates
  }

  async copy() {
    this.segmentService.track('Copy job Analytics to Clipboard')
    await this.jobAnalyticsClipboardService.copyToClipboard(this.analyticsContainer.nativeElement)
  }

  openEEOCModal() {
    this.modal.open(EeocFiltersOnJobAnalyticsModalComponent)
  }
}
