import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'twng-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input()
    size: 'small' | 'big' = 'big'

  constructor() { }

  ngOnInit(): void {
  }

}
