import * as fromWall from '../../wall/reducers'
import { Component, Input } from '@angular/core'
import { JobApplication } from '../../wall/models/job-application'
import { Store } from '@ngrx/store'
import { UpdateStarred } from '../actions/update-starred.actions'

@Component({
  selector: 'twng-star-toggle',
  template: `
    <twng-star-toggle-presenter
      (click)="toggleStar()"
      [jobApplication]="jobApplication"
      (toggleStar)="toggleStar()"
      ngbTooltip="Toggle Star"
    ></twng-star-toggle-presenter>
  `,
})
export class StarToggleComponent {
  @Input()
    jobApplication: JobApplication

  constructor(private store: Store<fromWall.State>) {}

  toggleStar() {
    this.store.dispatch(
      new UpdateStarred({ jobApplication: {
        ...this.jobApplication,
        starred: !this.jobApplication.starred
      }}),
    )
  }
}
