import { ParsedNumber, formatNumber, parseNumber } from 'libphonenumber-js'

import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core'
import { ContactMethod } from '../../wall/models/candidate'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-card-detail-item',
  styleUrls: [
    './card-detail-item.component.scss'
  ],
  template: `
    <ng-container *ngIf="values | present">
      <dl>
        <dt class="section__item-label fs-unmask">{{ title }}</dt>
        <dd class="section__item-value fs-exclude">
          <div *ngFor="let val of values">
            <a
              *ngIf="format === 'email'; else unformatted"
              href="mailto:{{ val }}"
              target="_blank"
              rel="noopener noreferrer"
              >{{ val }}</a
            >
            <ng-template #unformatted>{{ formatValue(val) }}</ng-template>
          </div>
        </dd>
      </dl>
    </ng-container>
  `,
})
export class CardDetailItemComponent implements OnChanges {
  @Input()
    title: string
  @Input()
    value: string | string[] | ContactMethod[]
  @Input()
    format: string
  values: (string | ContactMethod)[]

  constructor() { }

  ngOnChanges(): void {
    if (Array.isArray(this.value)) {
      this.values = this.value
    } else {
      this.values = [this.value]
    }
    this.values = this.values.filter(v => v)
  }

  formatValue(value) {
    switch (this.format) {
      case 'phone': {
        const contact = value
        const parsedNumber = parseNumber(contact.value, 'US') as ParsedNumber
        if (parsedNumber && parsedNumber.phone) {
          const formattedNumber = formatNumber(
            parsedNumber,
            'INTERNATIONAL',
          ).replace(/ /g, '-')
          return `${formattedNumber} (${contact.type})`
        }
        return `${contact.value} (${contact.type})`
      }
      default: {
        return value
      }
    }
  }
}
