import { Component, EventEmitter, Input, Output } from '@angular/core'
import { JobApplication } from '../../wall/models/job-application'

@Component({
  selector: 'twng-star-toggle-presenter',
  template: `
    <i [ngClass]="starClasses()" (ngClick)="toggleStar.emit(jobApplication)"></i>
  `,
  styles: [
    `i {
      margin-right: 1rem;
      font-size: 1rem;
      transition: color 0.1s;
      cursor: pointer;
    }

    i.fas { color: #2988E2; }
    i.far { color: #C8C8C8; }

    i:hover {
      color: #2988E2;
    }
    `,
  ],
})
export class StarTogglePresenterComponent {
  @Input() jobApplication: JobApplication
  @Output() toggleStar = new EventEmitter<JobApplication>()

  starClasses() {
    return {
      'fa-star': true,
      fas: this.jobApplication.starred,
      far: !this.jobApplication.starred
    }
  }
}
