import { CacheService } from '../../../../wall/services/cache.service'
import { ChartFilters } from '../../../models/chart-filters'
import { Component } from '@angular/core'
import { DepartmentWithChildren } from '../../../../wall/models/department'
import { HierarchicalSearchTypeaheadDataManager } from './hierarchical-search-typeahead-data-manager'
import { SearchTypeaheadDataManager, SingleChartFilterComponent } from './single-chart-filter.component'
import { SegmentService } from '../../../../core/services/segment.service'
import { Store } from '@ngrx/store'
import { first } from 'rxjs/operators'

export class DepartmentDataManager extends HierarchicalSearchTypeaheadDataManager<DepartmentWithChildren> {
  constructor(private cache: CacheService) {
    super('Departments')
  }

  protected loadAllData(): Promise<DepartmentWithChildren[]> {
    return this.cache.departmentsWithChildren$.pipe(first()).toPromise()
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.department_ids
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_departments
  }
}

@Component({
  selector: 'twng-department-single-chart-filter',
  templateUrl: './single-chart-filter.component.html',
  styleUrls: ['./single-chart-filter.component.scss',
    './single-chart-filter-template/single-chart-filter-template.component.scss'
  ],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  providers: [{ provide: SingleChartFilterComponent, useExisting: DepartmentSingleChartFilterComponent }]
})
export class DepartmentSingleChartFilterComponent extends SingleChartFilterComponent {
  id = 'DepartmentSingleChartFilterComponent';

  constructor(
    segmentService: SegmentService,
    store: Store,
    private cache: CacheService
  ) {
    super(segmentService, store, 'Departments', 'Department', 'fa-sitemap')
  }

  getChangedValueForStoring(): Partial<ChartFilters> {
    return {
      department_ids: this.managers[0].tempSelectedIds,
      should_exclude_departments: this.managers[0].shouldExcludeFilter,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getDataManagers(): SearchTypeaheadDataManager<any>[] {
    return [new DepartmentDataManager(this.cache)]
  }
}
