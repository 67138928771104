/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'

import { DashboardTab } from '../../dashboard/models/dashboard-tab'
import { Tab } from '../../wall/models/tab'
import { TabType } from '../models/tab.type'

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum CoreTabActionsTabActionTypes {
  ReorderTabs = '[Tab] ReorderTabs',
  ReorderTabsSuccess = '[Tab] ReorderTabsSuccess',
  ReorderTabsFailure = '[Tab] ReorderTabsFailure',
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */

export type TabsTypes = Tab | DashboardTab

export interface TabsActionPayload {
  type: TabType
  tabs: TabsTypes[]
  oldTabs: TabsTypes[]
}

export class ReorderTabs implements Action {
  readonly type = CoreTabActionsTabActionTypes.ReorderTabs

  constructor(public payload: TabsActionPayload) {
  }
}

export class ReorderTabsSuccess implements Action {
  readonly type = CoreTabActionsTabActionTypes.ReorderTabsSuccess

  constructor(public payload: TabsActionPayload) { }
}

export class ReorderTabsFailure implements Action {
  readonly type = CoreTabActionsTabActionTypes.ReorderTabsFailure

  constructor(public payload: TabsActionPayload) {
  }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type CoreTabActions =
  | ReorderTabs
  | ReorderTabsSuccess
  | ReorderTabsFailure
