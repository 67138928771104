import { AfterViewChecked, Directive, ElementRef, OnDestroy } from '@angular/core'
import { PdfGlobalService } from '../services/pdf-global.service'
import { Subscription } from 'rxjs'

/**
 * Sets to the current element height based on the 'clientHeight' property.
 * This was created to avoid some elements getting cut off in generated PDFs.
 */
@Directive({
  selector: '[setClientHeight]'
})
export class SetClientHeightDirective implements AfterViewChecked, OnDestroy {
  private sub = new Subscription()

  constructor(
    private elementRef: ElementRef,
    private globalPdf: PdfGlobalService,
  ) {
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  ngAfterViewChecked(): void {
    this.globalPdf.globalPdfExporting$.subscribe(
      isExporting => this.setHeight(isExporting)
    )
  }

  setHeight(isExporting: boolean) {
    if (!this.elementRef) {
      return
    }
    const el: HTMLElement = this.elementRef.nativeElement
    if (isExporting) {
      const height = el.clientHeight
      el.style.minHeight = `${height}px`
    } else {
      el.style.minHeight = null
    }
  }
}
