import { AppConfigService } from '../../../wall/services/app-config.service'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { ExecutiveDashboardJobFilters, } from '../../../wall/reducers/layout.reducer'
import { ExecutiveDashboardTab } from '../../../wall/models/executive-dashboard'
import { Router } from '@angular/router'
import { ShowGenericTableModal } from '../../../core/actions/generic-table.actions'
import { Store } from '@ngrx/store'

@Component({
  selector: 'twng-hiring-goal-charts',
  templateUrl: './hiring-goal-charts.component.html',
  styleUrls: ['./hiring-goal-charts.component.scss']
})
export class HiringGoalChartsComponent implements OnInit, OnDestroy {
  @Input()
    tab: ExecutiveDashboardTab

  safeUrl: SafeResourceUrl

  constructor(
    private store: Store,
    public app: AppConfigService,
    public sanitizer: DomSanitizer,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.setIFrameUrlWithParams(this.tab)

    window.addEventListener('message', this.onMessageListener)
  }

  setIFrameUrlWithParams(filters: ExecutiveDashboardJobFilters) {
    const custom_fields = filters.custom_fields.map(function(cf) {
      return {id: cf.id, values: cf.values.map(v => encodeURIComponent(v))}
    });

    const urlParams =
      `?hiring_team_ids=${JSON.stringify(filters.external_user_ids)}&department_ids=${JSON.stringify(filters.department_ids)}&office_ids=${JSON.stringify(filters.office_ids)}&job_ids=${JSON.stringify(filters.job_ids)}&custom_fields=${JSON.stringify(custom_fields)}&shared_tab=${window.location.href.includes('shared-executive-tab')}`;
    const url = `//${window.sobrio_host_name}/accounts/${window.twng_account?.id}/goals-summary${urlParams}`
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  onMessageListener = (event) => {
    const { data, origin } = event

    if (origin.includes(window.sobrio_host_name)) {
      const parsedData = JSON.parse(data)
      if (parsedData.ids?.length) {
        this.openModal(parsedData)
      }

      if (parsedData.openGoalsSettings) {
        this.router.navigate(['/executive-tools/edit-goals'])
      }
    }
  }

  openModal(parsedData) {
    let chart
    let clickedGraphTitle
    let clickedSeries = ''
    if (parsedData.metadata?.ids === 'projected_offer_accepts') {
      chart = 'offers-outstanding'
      clickedGraphTitle = 'Extended Offers'
      clickedSeries = parsedData.metadata.modal_subtitle
    } else {
      chart = parsedData.goals_achieved_on === 'hire_date'
        ? 'allegro-hire-goals-tracking' : 'allegro-starts-goals-tracking'
      clickedGraphTitle = parsedData.goals_achieved_on === 'hire_date' ? 'Hires' : 'Starts'
    }
    this.store.dispatch(new ShowGenericTableModal({
      data_ids: parsedData.ids,
      chart,
      clickedGraphTitle,
      clickedSeries,
    }))
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.onMessageListener)
  }
}
