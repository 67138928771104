import { Observable, Subscription, combineLatest } from 'rxjs'
import { ToastrService } from 'ngx-toastr'

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit
} from '@angular/core'
import { Dictionary } from '@ngrx/entity'
import { Store } from '@ngrx/store'

import * as fromCardDetails from '../reducers'
import * as fromWall from '../../wall/reducers'
import { API_OPEN_JOB_NAMES, WallApiService } from '../../wall/services/wall-api.service'
import { Actions } from '@ngrx/effects'
import { Candidate } from '../../wall/models/candidate'
import { FetchJobById } from '../../wall/actions/wall.actions'
import { Job } from '../../wall/models/job'
import { JobApplication } from '../../wall/models/job-application'
import { JobStage } from '../../wall/models/job-stage'
import { Office } from '../../wall/models/office'
import { SegmentService } from '../../core/services/segment.service'
import { SingleJobNamePayload } from '../../core/actions/loader.actions'
import {
  TransferCandidate, TransferCandidateUIActionTypes, UpdateSelectedJob, UpdateSelectedJobStage
} from '../actions/transfer-candidate-ui.actions'
import { atsId } from '../../wall/models/types'
import { isApiLoaded } from '../../shared/state/selectors'
import { sortByName } from '../../wall/reducers/sort-comparers'
import { toasterOnAction } from '../../shared/utils/store.utils'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-transfer-candidate-form',
  templateUrl: './transfer-candidate.component.html',
  styleUrls: [
    './card-detail-item.component.scss',
    './card-details-modal.component.scss',
    './transfer-candidate.component.scss',
  ],
})
export class TransferCandidateComponent implements OnInit, OnDestroy {
  @Input()
    job: Job
  @Input()
    jobApplication: JobApplication
  @Input()
    candidate: Candidate

  jobsLoaded$: Observable<boolean>

  constructor(
    private store: Store<fromWall.State>,
    private cd: ChangeDetectorRef,
    private wallApi: WallApiService,
    private segmentService: SegmentService,
    private toastr: ToastrService,
    private actions: Actions,
  ) { }

  sub: Subscription

  jobs: SingleJobNamePayload[]
  officeEntities: Dictionary<Office>
  jobStagesForSelectedJob: JobStage[]
  selectedJobId: atsId
  selectedJobStageId: atsId
  showError = false
  showTransferButton: boolean

  selectedJob = () => this.jobs && this.jobs.find(job => job.id === this.selectedJobId) || this.jobs[0]

  ngOnInit(): void {
    this.sub = combineLatest([
      this.store.select(fromWall.getOpenJobNamesFromWall),
      this.store.select(fromWall.selectJobStagesByJobId),
      this.store.select(fromCardDetails.selectTransferCandidateUI),
      this.store.select(fromWall.selectOfficeEntities),
    ]).subscribe(([jobs, jobStagesByJobId, uiByAppId, officeEntities]) => {
      this.officeEntities = officeEntities
      this.jobs = jobs.filter(job => job.id !== this.jobApplication.job_id).sort(sortByName)

      // Get the UI state for this application
      const ui = uiByAppId[this.jobApplication.id]

      if (ui) {
        this.showError = ui.showError
        this.selectedJobId = ui.selectedJobId
        this.selectedJobStageId = ui.selectedJobStageId
      }
      // default to first job
      if (!this.selectedJobId && this.jobs && this.jobs.length > 0) {
        this.selectedJobId = this.jobs[0].id
      }

      this.jobStagesForSelectedJob = jobStagesByJobId[this.selectedJobId]
      if (!this.jobStagesForSelectedJob && this.selectedJobId) {
        this.store.dispatch(new FetchJobById(this.selectedJobId))
      } else if (
        this.selectedJobId &&
        this.jobStagesForSelectedJob[0] &&
        !this.selectedJobStageId
      ) {
        this.selectedJobStageId = this.jobStagesForSelectedJob[0].id
      }

      if (this.jobStagesForSelectedJob && this.jobStagesForSelectedJob[0] && this.jobStagesForSelectedJob[0].name) {
        this.showTransferButton = true
      } else {
        this.showTransferButton = false
      }
      this.cd.markForCheck()
    })
    this.jobsLoaded$ = isApiLoaded(this.store, API_OPEN_JOB_NAMES)
  }

  onVisible() {
    this.store.dispatch(this.wallApi.getFetchOpenJobNamesAction())
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  updateSelectedJobId(jobId: atsId): void {
    this.store.dispatch(
      new UpdateSelectedJob({
        jobApplicationId: this.jobApplication.id,
        jobId,
      }),
    )
  }

  updateSelectedStageId(jobStageId: atsId): void {
    this.store.dispatch(
      new UpdateSelectedJobStage({
        jobApplicationId: this.jobApplication.id,
        jobStageId,
      }),
    )
  }

  async transferCandidate() {
    this.store.dispatch(
      new TransferCandidate({
        jobApplicationId: this.jobApplication.id,
        jobId: this.selectedJobId,
        jobStageId: this.selectedJobStageId,
      }),
    )
    const successMessage = `Transferred ${this.candidate.first_name} ${this.candidate.last_name} from ${this.job.name} to ${this.selectedJob().name}`

    toasterOnAction(
      [TransferCandidateUIActionTypes.TransferCandidateSuccess],
      [TransferCandidateUIActionTypes.TransferCandidateFailure],
      successMessage,
      null,
      this.toastr,
      this.actions)

    this.segmentService.track("Transfer Candidate")
  }

  candidateName(): string {
    return `${this.candidate.first_name} ${this.candidate.last_name}`
  }

  jobNameForSelect(job: SingleJobNamePayload) {
    const offices = job.office_ids.map(
      (officeId: atsId) => this.officeEntities[officeId],
    ).filter(office => office)

    const officeString = offices.map(office => office.name).join(', ')

    const jobNameAndOffice =
      officeString === '' ? job.name : `${job.name} (${officeString})`

    return jobNameAndOffice
  }
}
