import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { AppConfigService } from '../../wall/services/app-config.service'
import { InterviewLoaded } from '../../wall/models/interview'
import { JobApplication } from '../../wall/models/job-application'
import { UrlService } from '../../wall/services/url.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-interviews',
  styleUrls: [
    './card-detail-item.component.scss',
    './card-details-modal.component.scss',
    './interviews.component.scss'
  ],
  template: `
    <div *ngIf="interviews | present" class="section section--interviews">

      <h2 class="section__header tw-mb-2">
        <div class="fs-unmask tw-flex tw-items-center">
          <svg width="7" height="17" viewBox="0 0 7 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 11.5L2.5 15.5C2.5 16.05 2.95 16.5 3.5 16.5C4.05 16.5 4.5 16.05 4.5 15.5L4.5 11.5C4.5 10.95 4.05 10.5 3.5 10.5C2.95 10.5 2.5 10.95 2.5 11.5Z" fill="#767676"/>
            <path d="M2.5 11.5L2.5 15.5C2.5 16.05 2.95 16.5 3.5 16.5C4.05 16.5 4.5 16.05 4.5 15.5L4.5 11.5C4.5 10.95 4.05 10.5 3.5 10.5C2.95 10.5 2.5 10.95 2.5 11.5Z" fill="#767676"/>
            <path d="M2.5 1.5L2.5 5.5C2.5 6.05 2.95 6.5 3.5 6.5C4.05 6.5 4.5 6.05 4.5 5.5L4.5 1.5C4.5 0.95 4.05 0.5 3.5 0.5C2.95 0.5 2.5 0.95 2.5 1.5Z" fill="#767676"/>
            <path d="M2.5 1.5L2.5 5.5C2.5 6.05 2.95 6.5 3.5 6.5C4.05 6.5 4.5 6.05 4.5 5.5L4.5 1.5C4.5 0.95 4.05 0.5 3.5 0.5C2.95 0.5 2.5 0.95 2.5 1.5Z" fill="#767676"/>
            <circle cx="3.5" cy="9" r="1" fill="#767676"/>
            <circle cx="3.5" cy="8.5" r="2.875" fill="white" stroke="#767676" stroke-width="1.25"/>
          </svg>
          <span>Interview Stages</span>
        </div>

        <a
          *ngIf="urlService.applicationStorecardsUrl(jobApplication) | async as scorecardsUrl"
          class="icon
                  action-item--ats-secondary
                  btn-sm
                  btn-outline-secondary tw-p-2 tw-rounded-md tw-flex tw-items-center"
           target="_blank"
           rel="noopener noreferrer"
           href="{{ scorecardsUrl }}">
          <img class="action-item--ats-icon tw-rounded-md" src="{{ appConfig.atsIconUrl() }}" width="20px" height="20px" />
          <span>View Scorecards</span>
        </a>
      </h2>

      <div class="stages">
        <div class="stages__header fs-unmask">
          <div class="stages__header-col
                      stages__header-col--status">
          </div>
          <div class="stages__header-col
                      stages__header-col--info">
            Info
          </div>
          <div class="stages__header-col
                      stages__header-col--stage">
            Stage
          </div>
          <div class="stages__header-col
                      stages__header-col--reco">
            Recommendation
          </div>
        </div>

        <div class="stage" *ngFor="let interview of sortedInterviews()">
          <div class="stage__col
                      stage__col--status">
            <span *ngIf="interview.status === 'complete' || interview.status === 'awaiting_feedback'">
              <i class="fas fa-calendar-check interview--complete" ngbTooltip="Completed"></i>
            </span>

            <span *ngIf="interview.status === 'scheduled'">
              <i class="fas fa-calendar interview--scheduled" ngbTooltip="Scheduled"></i>
            </span>
          </div>

          <div class="stage__col
                      stage__col--info">
            <div>{{ interview.name }}</div>
            <div class="interview-time">{{ interview.start_time | date: 'LLL d, yyyy @ h:mm a z' }}</div>
          </div>

          <div class="stage__col
                      stage__col--stage">
            {{ interview.job_stage?.name }}
          </div>

          <div class="stage__col
                      stage__col--reco">
              <ul class="stage__interviewers list-unstyled">
                <li class="stage__interviewer" *ngFor="let interviewer of interview.interviewers">
                  <span *ngIf="interviewer.external_user as external_user">
                    {{ external_user.name }}
                  </span>

                  <span class="stage__feedback-msg"
                    *ngIf="interviewer.scorecard as scorecard"
                  >
                    {{ scorecard.overall_recommendation }}
                  </span>

                  <ng-container
                    *ngIf="(interview.status === 'awaiting_feedback' || interview.status === 'complete') && !interviewer.scorecard">
                    <span class="stage__feedback-msg">Awaiting Feedback</span>
                    <i class="fas fa-exclamation-circle stage__awaiting-icon"></i>
                  </ng-container>
                </li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  `
})
export class InterviewsComponent {
  @Input()
    jobApplication: JobApplication
  @Input()
    interviews: InterviewLoaded[]

  sortedInterviews(): InterviewLoaded[] {
    return this.interviews.sort((inta, intb) =>
      new Date(inta.start_time).getTime() - new Date(intb.start_time).getTime()
    )
  }

  constructor(
    public urlService: UrlService,
    public appConfig: AppConfigService,
  ) { }
}
