export class AppConfig {
  send_reject_email: boolean
  help_center_url: string
  ats_icon_url: string
  url_method: string
  update_protocol: string
  rejection_reason_type: string
  special_filters: string[]
  ats_name: string
  fiscal_start_month: number
  feature_flags: {
    dashboard: boolean
    can_view_exec_dashboard: boolean
    wall: boolean
    enable_eeoc_and_demographic: boolean
    transfer_candidate: boolean
    reject_candidate: boolean
    view_notes: boolean
    create_notes: boolean
    active_job_filters: string[]
    rejected_candidates_at_stage: boolean
    activity_time_distance_ago: boolean
    my_dashboard: boolean
    filter_by_any_tag: boolean
    custom_dashboards: boolean
    export_dashboard_to_pdf: boolean
    export_wall_to_pdf: boolean
    view_prospects_tab: boolean
    show_ats_link_on_small_card: boolean

    interview_phases: boolean
    candidates_in_stage: boolean

    application_ratings: boolean
    hiring_management: boolean
    show_job_status_on_wall: boolean
    show_recruiter_on_small_card: boolean
    show_job_id_on_wall: boolean
    show_hired_candidates_on_wall: boolean
    show_hiring_managers_on_wall: boolean
    enable_brio_exec_dashboard_tab_for_testing: boolean
    chart_caching: boolean
    slack_configured: boolean
    can_schedule_reports: boolean
  }
  custom_labels: {
    last_activity: string
    note: string
    notes: string
    reject: string
  }
  job_url_template?: string
  job_application_url_template?: string
  job_application_scorecard_url_template?: string
  auth_token?: string
  dashboard_external_links: { [path: string]: string }
  job_priorities: string[]
  employment_types: string[]
  account_timezone: string
}

export const STATIC_METHOD = 'static'
export const REPLACE_METHOD = 'replace'
export const ACTION_CABLE = 'action-cable'
export const SELECT_REJECTION_REASON = 'select'
export const OPEN_REJECTION_REASON = 'open'

export type FeatureFlags = keyof AppConfig['feature_flags']
