<div *ngIf="tab && tab.default_template" class="alert alert-danger">
  BE CAREFUL &mdash; This dashboard is a default for all users.
  <br>
  Changes made here will affect other accounts!
</div>

<div class="row no-gutters export-pdf-row pdf-container" *ngIf="dashboardPdfService.exporting$ | async">
  <div class="col">
    <div class="export-pdf-header">
      <span *ngIf="sharedDashboardToken" class="pdf-header-info">Shared dashboard: </span>
      <span>{{ tab.name }}</span>
    </div>
  </div>
</div>

<section *ngIf="tab && grid" id="dashboard-{{ tab.id }}" 
  class="tw-flex tw-flex-col"
  [class.editing-dashboard]="gridsterEditModeEnabled$ | async">
  <div class="section__header dashboard__actions tw-flex !tw-items-start" *ngIf="!sharedDashboardToken || (loaded$ | async)">
    <div class="section__header-content" [ngClass]="{'tw-w-full' : sharedDashboardToken}" data-html2canvas-ignore>
      <div class="edit-tabs" [ngClass]="{'tw-w-full' : sharedDashboardToken}">
        <a (click)="editTabModal(tab)" ngbTooltip="Rename" placement="top auto"
          class="actions-button actions-button--dark-bg" *ngIf="!sharedDashboardToken && !tab.editable">
          <i class="fa-solid fa-pen-to-square fa-fw"></i>
        </a>
        <a class="actions-button actions-button--dark-bg"
          *ngIf="!sharedDashboardToken && !(gridsterEditModeEnabled$ | async)" [ngbTooltip]="editLayoutTooltip"
          placement="top auto" (click)="enableEditMode()">
          <span class="section__row-icon rotate--270 tw-text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
            </svg>
          </span>
        </a>
        <a (click)="duplicateTab()" ngbTooltip="Duplicate Dashboard" placement="top auto"
          class="actions-button actions-button--dark-bg" *ngIf="!sharedDashboardToken">
          <i class="fa-solid fa-copy fa-fw"></i>
        </a>
        <div class="d-inline" *ngIf="!sharedDashboardToken && !demoMode" mwlConfirmationPopover placement="top" 
          popoverTitle="Delete {{ tab.name }}?" popoverMessage="This cannot be undone" confirmText="Yes"
          focusButton="cancel" popoverClass="mwl-confirmation-popover delete-custom-dashboard"
          (confirm)="deleteTab(tab)">
          <a class="actions-button actions-button--dark-bg" ngbTooltip="Delete Dashboard">
            <i class="fas fa-trash fa-fw"></i>
          </a>
        </div>

        <span class="label" *ngIf="sharedDashboardToken">
          Shared dashboard: <b>{{ tab.name }}</b>
        </span>
        <span class="icon-divider"></span>
        <a class="section__header-export-pdf-icon actions-button actions-button--dark-bg" ngbTooltip="Download as PDF"
          placement="top auto" (click)="exportToPDF('dashboard-' + tab.id.toString())">
          <i class="fas fa-download"></i>
        </a>

        <span class="icon-divider" *ngIf="sharedDashboardToken"></span>
        <div *ngIf="sharedDashboardToken && canViewDashboard" class="tw-flex tw-grow tw-justify-between">
          <input #tabName
            type="text"
            class="tw-w-80 form-control"
            [value]="tab.name"
            [disabled]="isSavingSharedTab || tab.view_editable"
            name="name"
          >
          <div class="tw-flex tw-items-center">
            <i class="fas fa-sync fa-spin tw-h-fit" *ngIf="isSavingSharedTab"></i>

            <button *ngIf="tab.view_editable && !isSavingSharedTab" class="tw-pr-4 tw-flex tw-items-center"
              (click)="saveSharedDashboard(tabName.value, true)"
              [disabled]="isSavingSharedTab">
              <span class="actions-button">
                <i class="fa-solid fa-floppy-disk"></i>
            </span>
              <span class="tw-text-sm tw-text-cq-gray-600">Save to my dashboard</span>
            </button>

            <button *ngIf="!tab.view_editable && !isSavingSharedTab" [disabled]="isSavingSharedTab" class="tw-flex tw-items-center"
            (click)="saveSharedDashboard(tabName.value, false)">
              <span class="actions-button">
                <i class="fa-regular fa-copy"></i>
              </span>
              <span class="tw-text-sm tw-text-cq-gray-600" >Copy to my dashboard</span>
            </button>
          </div>
        </div>

        <ng-container *ngIf="!sharedDashboardToken">
          <span class="icon-divider" *ngIf="!tab.editable"></span>

          <div ngbDropdown class="d-inline-block">
            <a class="actions-button"  *ngIf="!tab.editable" ngbTooltip="Share" id="sortMenu" ngbDropdownToggle>
              <i class="fas fa-share-alt"></i>
            </a>
            
            <div class="filter-dropdown-menu" ngbDropdownMenu aria-labelledby="sortMenu">
              <button class="dropdown-item" (click)="openShareTab()">
                <i class="fas fa-share"></i> Send a copy to a colleague
              </button>
              <button class="dropdown-item" (click)="copyShareableLinkToClipboard(false)">
                <i class="fas fa-link"></i> Send a link (readonly)
              </button>
              <button class="dropdown-item" (click)="copyShareableLinkToClipboard(true)">
                <i class="fa-solid fa-users"></i> Send a link (edit access)
              </button>
              <button class="dropdown-item tw-flex tw-items-center" (click)="openSendToSlackTab()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="margin-right: 5px;" width="18" height="18"><path d="M94.12 315.1c0 25.9-21.16 47.06-47.06 47.06S0 341 0 315.1c0-25.9 21.16-47.06 47.06-47.06h47.06v47.06zm23.72 0c0-25.9 21.16-47.06 47.06-47.06s47.06 21.16 47.06 47.06v117.84c0 25.9-21.16 47.06-47.06 47.06s-47.06-21.16-47.06-47.06V315.1zm47.06-188.98c-25.9 0-47.06-21.16-47.06-47.06S139 32 164.9 32s47.06 21.16 47.06 47.06v47.06H164.9zm0 23.72c25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06H47.06C21.16 243.96 0 222.8 0 196.9s21.16-47.06 47.06-47.06H164.9zm188.98 47.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06h-47.06V196.9zm-23.72 0c0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06V79.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06V196.9zM283.1 385.88c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06v-47.06h47.06zm0-23.72c-25.9 0-47.06-21.16-47.06-47.06 0-25.9 21.16-47.06 47.06-47.06h117.84c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06H283.1z"/></svg>
                Share via Slack (readonly)
              </button>
              <button class="dropdown-item" (click)="scheduleDashboardSending()" *ngIf="appConfig.isFeatureFlagEnabled$('can_schedule_reports') | async">
                <i class="fas fa-calendar"></i>
                Send link on schedule
              </button>
            </div>
          </div>

          <span class="icon-divider"></span>
        </ng-container>
      </div>
    </div>
    <div class="edit-mode__actions" *ngIf="gridsterEditModeEnabled$ | async">
      <div class="edit-mode__cancel" ngbTooltip="Exit &amp; discard any changes" placement="top auto">
        <button class="edit-mode__cancel" (click)="cancelEditMode()">
          ⭠ Cancel
        </button>
      </div>
      <div class="edit-mode__wrapper">
        <div class="edit-mode__actions">
          <span class="edit-mode__helper-text" [ngbPopover]="addChartsHelpPopover" placement="top auto"
            popoverClass="edit-mode__helper-popover" triggers="mouseenter:mouseleave" aria-label="How to add charts">
            How do I add charts?
          </span>
          <button class="edit-mode__save" (click)="saveGridster()">Save</button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!sharedDashboardToken && !(gridsterEditModeEnabled$ | async)">
      <div class="override-filters" *ngIf="chartFilters$ | async as theChartFilters">
        <span class="label">Override Filters</span>
        <twng-chart-filters #chartFilters [filters]="theChartFilters" [isTempDashboard]="true"
          (onFiltersChanged)="onTempFiltersChanged($event)" [panel]="customDashboard" [style.width.%]="100"
          (resetChartFilters)="resetChartFilters()" [overrideFiltersSet]="overrideFiltersSet()">
        </twng-chart-filters>

        <div *ngIf="isOverridingFilters" class="nav__item--spinner">
          <i data-html2canvas-ignore class="fas fa-sync fa-spin"></i>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="edit-mode__sub-header" *ngIf="!sharedDashboardToken">
    <div class="edit-mode__tip">Tip</div>
    <div class="edit-mode__text">Drag, drop and resize any charts</div>
  </div>

  <div *ngIf="sharedDashboardToken && loaded$ | async" data-html2canvas-ignore
    class="tw-bg-cq-primary-50 tw-rounded-md tw-border tw-border-cq-primary-200 tw-self-center tw-w-[95%] tw-my-2 tw-p-4 tw-flex tw-items-center">
    <i class="fa-solid fa-circle-info fa-xl tw-text-cq-primary-600 tw-pr-2"></i> 
    <div>
      <p *ngIf="!tab.view_editable" class="tw-text-sm tw-text-cq-primary-600 tw-pl-8">This dashboard has been shared as view only. Make a copy to make changes only you will see. </p>
  
      <span *ngIf="tab.view_editable" class="tw-text-sm tw-text-cq-primary-600 tw-pl-8">This dashboard has been shared with edit permissions.</span>
      <p *ngIf="tab.view_editable" class="tw-text-sm tw-text-cq-primary-600 tw-pl-8">Save it to your dashboard to make changes that will affect all viewers.</p>
    </div>
  </div>

  <div #gridsterContainer class="dashboard__grid--gridster" [ngStyle]="{'height': getScrollHeight()}">
    <gridster [options]="grid.options" *ngIf="loaded$ | async; else spinner">
      <ng-container *ngIf="{ charts: charts$ | async } as chartsObj">
        <div *ngIf="!chartsObj.charts || chartsObj.charts.length === 0" class="alert alert-how-to-add">
          <span class="edit-mode__helper-text" [ngbPopover]="addChartsHelpPopover" placement="top auto"
            popoverClass="edit-mode__helper-popover" triggers="mouseenter:mouseleave" aria-label="How to add charts">
            How do I add charts?
          </span>
        </div>
        <!-- We first display all number boxes and then other charts -->
        <ng-container *ngFor="let chart of chartsObj.charts; trackBy: trackChartById">
          <twng-chart-wrapper *ngIf="grid" [chart]="chart" [chartFiltersEnabled]="chartFiltersEnabled" [grid]="grid"
            [editingGridster]="gridsterEditModeEnabled$ | async" gridItemType='gridster'
            [chartFiltersReadOnly]="!!sharedDashboardToken" [chartInitConfig]="chartInitConfig" [overrideFiltersSet]="overrideFiltersSet()">
          </twng-chart-wrapper>
        </ng-container>
      </ng-container>
    </gridster>
  </div>
</section>

<ng-template #editLayoutTooltip>
  <div>Edit Layout</div>
</ng-template>

<ng-template #addChartsHelpPopover>
  To add charts, go to Widget Library, then click the
  <i class="dot-menu-icon fas fa-ellipsis-v"></i> button,
  and pick a custom dashboard in the
  <span class="section__header">Send this to</span>
  section of the menu that pops up.
</ng-template>

<ng-template #spinner>
  <twng-spinner></twng-spinner>
</ng-template>
