import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'

import * as fromDashboard from '../../reducers'
import { DashboardChart } from '../../models/dashboard-chart'
import { DisableEditCustomStatBox, UpdateDashboardChart } from '../../actions/filters.actions'
import { Observable } from 'rxjs';
import { WidgetDataType } from '../../../wall/models/offer'
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { selectEditingCustomStatBoxId } from '../../reducers'

@Component({
  selector: 'twng-stat-custom-box-input',
  templateUrl: './stat-custom-box-input.component.html',
  styleUrls: [
    '../dashboard.shared.component.scss',
    './stat-custom-box-input.component.scss'
  ]
})
export class StatCustomBoxInputComponent implements OnInit {

  @ViewChild('inputCustomStatValue', { static: false })
    inputCustomStatValueRef: ElementRef<HTMLInputElement>

  @Input()
    chart: DashboardChart

  @Input()
    value: string

  @Input()
    chartInfo: string

  customStatBoxForm: UntypedFormGroup

  editingCustomStatBoxValue$: Observable<boolean>

  constructor(private store: Store<fromDashboard.State>) {
  }

  ngOnInit(): void {
    this.editingCustomStatBoxValue$ = this.store.select(selectEditingCustomStatBoxId).pipe(
      filter(() => !!this.chart),
      map((chartId: string) => chartId === this.chart.id),
      tap((editing: boolean) => {
        if (editing) {
          this.createForm()
        }
      }),
      shareReplay(1)
    )
  }

  disableEditName(): void {
    this.store.dispatch(new DisableEditCustomStatBox())
  }

  createForm(): void {
    this.customStatBoxForm = new UntypedFormGroup({
      customValue: new UntypedFormControl(this.value, [Validators.required]),
    })
    setTimeout(() => {
      this.inputCustomStatValueRef.nativeElement.focus()
    })
  }

  cleanValue(): void {
    const customValue = this.customStatBoxForm.controls.customValue.value.trim()
    this.customStatBoxForm.controls.customValue.setValue(customValue)
  }

  onSubmit(): void {
    this.cleanValue()

    // If form is invalid (customStatValue.length === 0),
    // then don't do anything
    if (this.customStatBoxForm.invalid) {
      this.disableEditName()
      return
    }

    // If the user has made a change, dispatch the update
    if (this.customStatBoxForm.dirty) {
      this.store.dispatch(new UpdateDashboardChart({
        dashboard_chart: {
          ...this.chart,
          custom_value: this.customStatBoxForm.value.customValue,
          data_type: WidgetDataType.CUSTOM
        },
      }))
    }

    this.disableEditName()
  }

}
