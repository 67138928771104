<div #widget class="widget line-chart" [class.widget--css]="gridItemType === 'css'"
  [class.widget--gridster]="gridItemType === 'gridster'" *ngIf="data">

  <twng-gridster-item-overlay *ngIf="editingGridster"></twng-gridster-item-overlay>

  <div class="header tw-border-none" [class.pointer-events--none]="editingGridster">
    <twng-chart-label [chart]="chart" [label]="label"
      [chartInfo]="(dataSources && dataSources[dataSource]) ? dataSources[dataSource].description: null">
    </twng-chart-label>

    <div class="header-actions" data-html2canvas-ignore>
      <button type="actions-button" class="actions-button actions-button--copy-to-clipboard" [ngbTooltip]="copyTooltip"
        triggers="mouseenter:mouseleave" aria-label="Copy to Clipboard" [disabled]="copyingToClipboard"
        (click)="copyChartToClipboard(widget, legendInCopiedImg, filtersInCopiedImg)">
        <i *ngIf="!copyingToClipboard" class="far fa-copy"></i>
        <i *ngIf="copyingToClipboard" class="fas fa-sync fa-spin"></i>
      </button>

      <twng-chart-drop-down-menu [chart]="chart" [label]="label" [chartType]="chartType" [chartStatus]="status"
        [chartFilters]="chartFilters" [dataSource]="dataSource" [dataSourceParameters]="dataSourceParameters"
        [hideFilters]="true" *ngIf="!chartFiltersReadOnly">
      </twng-chart-drop-down-menu>
    </div>
  </div>

  <div [ngClass]="widgetBodyClasses()" [class.pointer-events--none]="editingGridster">
    <div class="content-overflow js-content-overflow--horizontal full-height">
      <div class="ngx-responsive-wrapper full-height">
        <ngx-charts-line-chart *ngIf="chartType === 'line'" [id]="camelCase(label)" [view]="view" [results]="data"
          [customColors]="customColors" [showGridLines]="true" [xAxis]="true" [yAxis]="true"
          (select)="sliceClicked($event)" [animations]="false" [legend]="true"></ngx-charts-line-chart>

        <ngx-charts-area-chart *ngIf="chartType === 'area'" [id]="camelCase(label)" [view]="view" [results]="data"
          [customColors]="customColors" [showGridLines]="true" [xAxis]="true" [yAxis]="true"
          (select)="sliceClicked($event)" [animations]="false"></ngx-charts-area-chart>

        <ngx-charts-area-chart-stacked *ngIf="chartType === 'area-stacked'" [id]="camelCase(label)" [view]="view"
          [results]="data" [customColors]="customColors" [showGridLines]="true" [xAxis]="true" [yAxis]="true"
          [legend]="true" (select)="sliceClicked($event)" [animations]="false"></ngx-charts-area-chart-stacked>

        <ngx-charts-area-chart-normalized *ngIf="chartType === 'area-normalized'" [id]="camelCase(label)" [view]="view"
          [results]="data" [customColors]="customColors" [showGridLines]="true" [xAxis]="true" [yAxis]="true"
          [legend]="true" (select)="sliceClicked($event)" [legendPosition]="legendPosition" [animations]="false">
          <ng-template #seriesTooltipTemplate let-model="model">
            <div *ngFor="let m of model">
              <div>
                {{ m?.series }}:
                <span *ngIf="defined(m.value)">{{ m?.value }}</span>
                <span *ngIf="defined(m.original_value)"> ({{ m?.original_value }})</span>
              </div>
            </div>
          </ng-template>

        </ngx-charts-area-chart-normalized>

      </div>
    </div>
  </div>

  <div class="filters" [class.pointer-events--none]="editingGridster" *ngIf="chart && chartFilters"
    data-html2canvas-ignore>
    <twng-chart-filters [filters]="chartFilters" [filtersEnabled]="filtersEnabled" [chart]="chart"
      [chartFiltersReadOnly]="chartFiltersReadOnly" [overrideFiltersSet]="overrideFiltersSet">
    </twng-chart-filters>
  </div>
</div>

<ng-template #copyTooltip>
  <div>Copy to Clipboard</div>
  <i class="fas fa-flask"></i>&nbsp;
  <span>Chrome only</span>
</ng-template>