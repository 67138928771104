import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { JobApplication } from '../models/job-application'

import {
  JobApplicationActionTypes,
  JobApplicationActions,
} from '../actions/job-applications.actions'
import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { WallActionTypes, WallActions } from '../actions/wall.actions'

export type State = EntityState<JobApplication>

export function sortByLastActivity(
  a: JobApplication,
  b: JobApplication,
): number {
  return new Date(b.last_activity_at).getTime() - new Date(a.last_activity_at).getTime()
}

export const adapter: EntityAdapter<JobApplication> = createEntityAdapter<
JobApplication
>({
  sortComparer: sortByLastActivity,
})

export const initialState: State = adapter.getInitialState()

export function jobApplicationFromApiResponse(
  apiRepresentation,
): JobApplication {
  // convert to date objects
  return {
    ...apiRepresentation,
    //last_activity_at: new Date(apiRepresentation.last_activity_at),
    //applied_at: new Date(apiRepresentation.applied_at),
    waitingForSync: false,
    // ensure string ID
    id: `${apiRepresentation.id}`
  }
}

export function reducer(
  state = initialState,
  action: LoaderActions | JobApplicationActions | WallActions,
): State {
  switch (action.type) {
    case WallActionTypes.FetchWallDataPaginatedSuccessAction:
    case WallActionTypes.FetchWallDataPaginatedTabSuccessAction: {
      if (action.payload.job_applications) {
        const applications_with_date_objects = action.payload.job_applications.map(
          app => jobApplicationFromApiResponse(app),
        )
        return adapter.addMany(applications_with_date_objects, state)
      }
      break
    }

    case JobApplicationActionTypes.UpdateJobApplicationStage: {
      return adapter.updateOne(
        {
          id: action.payload.jobApplication.id,
          changes: {
            job_stage_id: action.payload.toStage.id,
            last_activity_at: new Date().toJSON(),
            waitingForSync: true,
          },
        },
        state,
      )
    }

    case JobApplicationActionTypes.UpdateJobApplicationStageSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.jobApplication.id,
          changes: {
            waitingForSync: false,
          },
        },
        state,
      )
    }

    case JobApplicationActionTypes.UpdateJobApplicationStageFailure: {
      return adapter.updateOne(
        {
          id: action.payload.jobApplication.id,
          // revert jobApplication to how it was at the start
          changes: action.payload.jobApplication,
        },
        state,
      )
    }

    case LoaderActionTypes.UpdateFromServer: {
      if (!action.payload.job_applications) {
        return state
      }

      const jobApps = action.payload.job_applications.map(appFromApi => ({
        ...jobApplicationFromApiResponse(appFromApi),
        changedOnServer: true,
      }))

      return adapter.upsertMany(jobApps, state)
    }

    case JobApplicationActionTypes.UpdateJobApplications: {
      return adapter.updateMany(action.payload, state)
    }
  }
  return state
}
