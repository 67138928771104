import { CacheService } from '../../../../wall/services/cache.service'
import { ChartFilters } from '../../../models/chart-filters'
import { Component } from '@angular/core'
import { ExternalUser } from '../../../../wall/models/external-user'
import { SearchTypeaheadDataManager, SingleChartFilterComponent } from './single-chart-filter.component'
import { SegmentService } from '../../../../core/services/segment.service'
import { Store } from '@ngrx/store'
import { first, map } from 'rxjs/operators'
import { forkJoin } from 'rxjs'
import { sortByName } from '../../../../wall/reducers/sort-comparers'

abstract class UserDataManagerBase extends SearchTypeaheadDataManager<ExternalUser> {
  constructor(protected cache: CacheService, searchPlaceholder: string) {
    super(searchPlaceholder)
  }
  protected loadAllData(): Promise<ExternalUser[]> {
    return this.cache.externalUsers$.pipe(first()).toPromise()
  }
}
export class UserDataManager extends UserDataManagerBase  {
  constructor(cache: CacheService) {
    super(cache, 'Users')
  }

  protected getIdsFromFilters(): string[] {
    const userIds = []
    this.filters.user_ids.forEach(id => {
      if (!this.allData.find(data => id === data.external_user_id)) {
        userIds.push(id)
      }
    })

    return [...userIds, ...this.filters.sourcing_user_external_ids]
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_external_users
  }

  protected loadAllData(): Promise<ExternalUser[]> {
    return forkJoin(
      [this.cache.sourcingUsers$.pipe(first()), this.cache.externalUsers$.pipe(first())]
    ).pipe(
      map(([sourcingUsers, externalUsers]) => {
        const sUsers = sourcingUsers.map(user => {
          const sameUser = externalUsers.find(u => u.id === user.external_user_id)

          if (sameUser) {
            externalUsers.splice(externalUsers.findIndex(item => item.id === sameUser.id), 1)
            return user
          }

          const currentUser = {...user}
          currentUser.name = `${user.name} [Only ${user.sourcing_user}]`
          return currentUser
        })

        const allUsers = [...sUsers, ...externalUsers]
        return allUsers.sort(sortByName)
      })
    ).toPromise()
  }
}
export class HiringManagerDataManager extends UserDataManagerBase {
  constructor(cache: CacheService) {
    super(cache, 'Hiring Managers')
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.hiring_manager_ids
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_hiring_managers
  }
}

export class CreditedToDataManager extends UserDataManagerBase {
  constructor(cache: CacheService) {
    super(cache, 'Application Credited To')
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.credited_to_ids
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_credited_to
  }
}

export class RecruiterDataManager extends UserDataManagerBase {
  constructor(cache: CacheService) {
    super(cache, 'Candidate’s Recruiter')
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.candidate_recruiter_ids
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_candidate_recruiter
  }
}

export class CoordinatorDataManager extends UserDataManagerBase {
  constructor(cache: CacheService) {
    super(cache, 'Candidate’s Coordinator')
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.candidate_coordinator_ids
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_candidate_coordinator
  }
}

export class JobSourcerDataManager extends UserDataManagerBase {
  constructor(cache: CacheService) {
    super(cache,  'Job’s Sourcer')
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.job_sourcer_external_ids
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_job_sourcers
  }
}

export class JobCoordinatorDataManager extends UserDataManagerBase {
  constructor(cache: CacheService) {
    super(cache,  'Job’s Primary Coordinator')
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.job_coordinator_external_ids
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_job_coordinators
  }
}

export class JobPrimaryRecruiterDataManager extends UserDataManagerBase {
  constructor(cache: CacheService) {
    super(cache, 'Job’s Primary Recruiter')
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.job_primary_recruiter_external_ids
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_job_primary_recruiters
  }
}

@Component({
  selector: 'twng-user-single-chart-filter',
  templateUrl: './single-chart-filter.component.html',
  styleUrls: ['./single-chart-filter.component.scss',
    './single-chart-filter-template/single-chart-filter-template.component.scss'
  ],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  providers: [{ provide: SingleChartFilterComponent, useExisting: UserSingleChartFilterComponent }]
})
export class UserSingleChartFilterComponent extends SingleChartFilterComponent {
  id = 'UserSingleChartFilterComponent';

  constructor(
    segmentService: SegmentService,
    store: Store,
    private cache: CacheService
  ) {
    super(segmentService, store, 'Users', 'User', 'fa-user')
  }

  getChangedValueForStoring(): Partial<ChartFilters> {
    const userIds = []
    const sourcingIds = []

    this.managers[0].tempSelectedIds.forEach(id => {
      const user = this.managers[0].getValueFromId(id)
      if (user.sourcing_user) {
        sourcingIds.push(id)
        if (user.external_user_id) {
          userIds.push(user.external_user_id)
        }
      } else {
        userIds.push(id)
      }
    })


    return {
      user_ids: userIds,
      sourcing_user_external_ids: sourcingIds,
      should_exclude_external_users: this.managers[0].shouldExcludeFilter,
      hiring_manager_ids: this.managers[1].tempSelectedIds,
      should_exclude_hiring_managers: this.managers[1].shouldExcludeFilter,
      candidate_recruiter_ids: this.managers[2].tempSelectedIds,
      should_exclude_candidate_recruiter: this.managers[2].shouldExcludeFilter,
      candidate_coordinator_ids: this.managers[3].tempSelectedIds,
      should_exclude_candidate_coordinator: this.managers[3].shouldExcludeFilter,
      credited_to_ids: this.managers[4].tempSelectedIds,
      should_exclude_credited_to: this.managers[4].shouldExcludeFilter,
      job_primary_recruiter_external_ids: this.managers[5].tempSelectedIds,
      should_exclude_job_primary_recruiters: this.managers[5].shouldExcludeFilter,
      job_coordinator_external_ids: this.managers[6].tempSelectedIds,
      should_exclude_job_coordinators: this.managers[6].shouldExcludeFilter,
      job_sourcer_external_ids: this.managers[7].tempSelectedIds,
      should_exclude_job_sourcers: this.managers[7].shouldExcludeFilter
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getDataManagers(): SearchTypeaheadDataManager<any>[] {
    return [
      new UserDataManager(this.cache),
      new HiringManagerDataManager(this.cache),
      new RecruiterDataManager(this.cache),
      new CoordinatorDataManager(this.cache),
      new CreditedToDataManager(this.cache),
      new JobPrimaryRecruiterDataManager(this.cache),
      new JobCoordinatorDataManager(this.cache),
      new JobSourcerDataManager(this.cache)
    ]
  }
}
