<div #widget class="widget horizontal-stacked-bar" *ngIf="slicedData"
  [ngClass]="{'widget--css': gridItemType === 'css', 'widget--gridster':gridItemType === 'gridster' }">
  <twng-gridster-item-overlay *ngIf="editingGridster"></twng-gridster-item-overlay>
  <div class="header tw-border-none" [class.pointer-events--none]="editingGridster" *ngIf="showHeader">
    <twng-chart-label [label]="label" [chart]="chart"
      [chartInfo]="(dataSources && dataSources[dataSource]) ? dataSources[dataSource].description: null">
    </twng-chart-label>
    <div class="header-actions" data-html2canvas-ignore>
      <twng-sort-chart-menu (sortByChanged)="sortChartBy = $event" [allChartData]="data"></twng-sort-chart-menu>
      <button type="actions-button" class="actions-button actions-button--copy-to-clipboard" [ngbTooltip]="copyTooltip"
        triggers="mouseenter:mouseleave" aria-label="Copy to Clipboard" [disabled]="copyingToClipboard"
        (click)="copyChartToClipboard(widget, legendInCopiedImg, filtersInCopiedImg)">
        <i *ngIf="!copyingToClipboard" class="far fa-copy"></i>
        <i *ngIf="copyingToClipboard" class="fas fa-sync fa-spin"></i>
      </button>
      <twng-chart-drop-down-menu [chart]="chart" [label]="label" [chartType]="chartType" [chartFilters]="chartFilters"
        [dataSource]="dataSource" [dataSourceParameters]="dataSourceParameters" [hideFilters]="true" [chartStatus]="status" *ngIf="!chartFiltersReadOnly">
      </twng-chart-drop-down-menu>
    </div>
  </div>

  <div [class.pointer-events--none]="editingGridster" class="body body--no-filters overflow-auto" [class.body--full]="!showHeader"
    twngSortChart #sortedChart=sortChart [chartData]="slicedData" [sortOption]="sortChartBy">
    <div class="content-overflow js-content-overflow--horizontal">
      <div class="ngx-responsive-wrapper" [style.height.px]="isVertical ? null : chartHeight" *ngIf="slicedData">
        <ng-container *ngIf="!normalized">
          <ngx-charts-bar-horizontal-stacked [id]="camelCase(label)" [view]="view" [results]="sortedChart.sortedData"
            [customColors]="customColors" [barPadding]="10" [showGridLines]="true" [showDataLabel]="true" [xAxis]="true"
            [yAxis]="true" (select)="sliceClicked($event)" *ngIf="!isVertical" [animations]="false" [legend]="true" [legendPosition]="legendBelow">
          </ngx-charts-bar-horizontal-stacked>
          <ngx-charts-bar-vertical-stacked [id]="camelCase(label)" [view]="view" [results]="sortedChart.sortedData"
            [customColors]="customColors" [barPadding]="10" [showGridLines]="true" [showDataLabel]="true" [xAxis]="true"
            [yAxis]="true" (select)="sliceClicked($event)" *ngIf="isVertical" [legend]="true" [legendPosition]="legendRight" [animations]="false">
          </ngx-charts-bar-vertical-stacked>
        </ng-container>
        <ngx-charts-bar-horizontal-normalized [id]="camelCase(label)" [view]="view" [results]="sortedChart.sortedData"
          [customColors]="customColors" [barPadding]="10" [showGridLines]="true" [xAxis]="true" [yAxis]="true"
          [xAxisTickFormatting]="normalizedDataXLabel" (select)="sliceClicked($event)" *ngIf="normalized" [animations]="false" [legend]="true" [legendPosition]="legendBelow">
          <ng-template #tooltipTemplate let-model="model">
            <div style="padding: 5px">
              {{ model.series }} - {{ model.label }}<br />
              <b>{{ model.value }}</b>
            </div>
          </ng-template>
        </ngx-charts-bar-horizontal-normalized>

        <ng-container *ngIf="!this.isVertical">
          <ng-container *ngTemplateOutlet="viewAll"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="this.isVertical">
    <ng-container *ngTemplateOutlet="viewAll"></ng-container>
  </ng-container>
  <div class="filters" [class.pointer-events--none]="editingGridster"
    *ngIf="chart && chartFilters && gridItemType === 'gridster'" data-html2canvas-ignore>
    <twng-chart-filters [filters]="chartFilters" [filtersEnabled]="filtersEnabled" [chart]="chart"
      [chartFiltersReadOnly]="chartFiltersReadOnly" [overrideFiltersSet]="overrideFiltersSet">
    </twng-chart-filters>
  </div>
</div>

<ng-template #copyTooltip>
  <div>Copy to Clipboard</div>
  <i class="fas fa-flask"></i>&nbsp;
  <span>Chrome only</span>
</ng-template>

<ng-template #viewAll>
  <div class="view-all" [ngClass]="{'view-all-shadow' : isVertical &&  gridItemType === 'css'}" *ngIf="displayBars < data.length">
    <span>Showing {{ displayBars }}/{{ data.length }} bars </span>
    <button class="button--primary" (click)="viewAllBars()">Show All</button>
  </div>
</ng-template>
