import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'

import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import { Note } from '../models/note'
import { NoteActionTypes, NoteActions } from '../actions/notes.actions'

export type State = EntityState<Note>

function sortByCreatedAt(a: Note, b: Note): number {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
}

export const adapter: EntityAdapter<Note> = createEntityAdapter<Note>({
  sortComparer: sortByCreatedAt,
})

export const initialState: State = adapter.getInitialState()

export function reducer(state = initialState, action: NoteActions | LoaderActions): State {
  switch (action.type) {
    case NoteActionTypes.FetchNotesSuccess: {
      const noteUpdates = action.payload.notes
      return adapter.upsertMany(noteUpdates, state)
    }

    case LoaderActionTypes.WallSharedSuccess: {
      if (action.payload.notes) {
        return adapter.upsertMany(action.payload.notes, state)
      }
      return state
    }

    case NoteActionTypes.AddNoteSuccess: {
      return adapter.upsertOne(action.payload, state)
    }

    default: {
      return state
    }
  }
}
