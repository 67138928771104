import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'

import { CoreTabActions, CoreTabActionsTabActionTypes } from '../../core/actions/tab.actions'
import { DashboardActionTypes, DashboardFilterActions } from '../actions/filters.actions'
import { DashboardTab } from '../models/dashboard-tab'
import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import { TabActions } from '../../wall/actions/tabs.actions'
import { TabType } from '../../core/models/tab.type'

export type State = EntityState<DashboardTab>

export function sortByPosition(a: DashboardTab, b: DashboardTab): number {
  return a.position - b.position
}

export const adapter: EntityAdapter<DashboardTab> = createEntityAdapter<DashboardTab>({
  sortComparer: sortByPosition,
})

export const initialState: State = adapter.getInitialState()

export const dashboardTabType: TabType = 'dashboard'

export function reducer(
  state = initialState,
  action: LoaderActions | DashboardFilterActions | TabActions | CoreTabActions,
): State {
  switch (action.type) {
    case LoaderActionTypes.DashboardLoadSuccess: {
      if (action.payload.dashboard_tabs) {
        return adapter.addMany(action.payload.dashboard_tabs, state)
      }
      return state
    }

    case DashboardActionTypes.CreateDashboardTabSuccess: {
      return adapter.addOne(action.payload, state)
    }

    case DashboardActionTypes.DashboardSendChartToCustomSuccess: {
      if (action.payload.dashboard_tab) {
        return adapter.upsertOne(action.payload.dashboard_tab, state)
      }
      return state
    }

    case DashboardActionTypes.UpdateDashboardTab: {
      return adapter.upsertOne(action.payload.updatedTab, state)
    }

    case DashboardActionTypes.UpdateDashboardTabSuccess: {
      return adapter.upsertOne(action.payload.dashboard_tab, state)
    }

    case DashboardActionTypes.UpdateDashboardTabFailure: {
      return adapter.upsertOne(action.payload.oldTab, state)
    }

    case DashboardActionTypes.DashboardSaveSharedTabSuccess: {
      return adapter.upsertOne(action.payload.dashboard_tab, state)
    }

    case DashboardActionTypes.RemoveDashboardTab: {
      return adapter.removeOne(action.payload.tab.id, state)
    }

    case DashboardActionTypes.RemoveDashboardTabFailure: {
      return adapter.addOne(action.payload.oldTab, state)
    }

    case LoaderActionTypes.LoadSharedDashboardTabSuccess: {
      return adapter.upsertOne(action.payload.tab, state)
    }

    // Reorder tabs
    case CoreTabActionsTabActionTypes.ReorderTabs: {
      if (action.payload.type !== dashboardTabType) {
        return state
      }
      return adapter.upsertMany(action.payload.tabs as DashboardTab[], state)
    }

    case CoreTabActionsTabActionTypes.ReorderTabsFailure: {
      if (action.payload.type !== dashboardTabType) {
        return state
      }
      return adapter.upsertMany(action.payload.oldTabs as DashboardTab[], state)
    }

    default: {
      return state
    }
  }
}
