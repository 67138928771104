import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { DropoffConversionRates } from '../../../wall/reducers/job-analytics.reducer'
import { ShowGenericTableModal } from '../../../core/actions/generic-table.actions'
import { Store } from '@ngrx/store'
import { WidgetDataType } from '../../../wall/models/offer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-dropoff-graph',
  templateUrl: './dropoff-graph.component.html',
  styleUrls: ['./dropoff-graph.component.scss']
})
export class DropoffGraphComponent implements OnInit {

  @Input()
    conversionRates: DropoffConversionRates

  @Input()
    eeocConversionRates: DropoffConversionRates

  @Input()
    stageName: string

  @Input()
  private jobName: string

  @Input()
  private timeInterviewing: number

  @Input()
    candidatesInterviewed: number

  @Input()
    yAxisMax: number

  @Input()
    isOfferAcceptenceRateStage = false

  constructor(
    private store: Store,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

  onActiveJobStageClick() {
    if (this.conversionRates && this.conversionRates.active_app_ids && this.conversionRates.active_app_ids.length > 0) {
      this.store.dispatch(new ShowGenericTableModal({
        chart: WidgetDataType.CANDIDATE,
        clickedGraphTitle: this.jobName,
        clickedSeries: this.stageName,
        clickedLabel: 'Active Candidates',
        data_ids: this.conversionRates.active_app_ids.map(v => v.toString()),
      }))
    }
  }

  onProcessedJobStageClick() {
    const passed = this.conversionRates.passed_through_app_ids || []
    const rejected = this.conversionRates.rejected_app_ids || []
    const processed = [...passed, ...rejected]
    if (processed.length > 0) {
      this.store.dispatch(new ShowGenericTableModal({
        chart: WidgetDataType.CANDIDATE,
        clickedGraphTitle: this.jobName,
        clickedSeries: this.stageName,
        clickedLabel: 'Processed Candidates',
        data_ids: processed.map(v => v.toString()),
      }))
    }
  }

  totalProcessed() {
    if (!this.conversionRates) {
      return 0
    }
    return (this.conversionRates.passed_through || 0) + (this.conversionRates.rejected || 0)
  }

  interviewTimeForStage() {
    if (!this.timeInterviewing) {
      return 0
    }
    return Math.round(this.timeInterviewing / 60 / 6) / 10
  }
}
