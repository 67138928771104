/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'

import { CandidateFilters, JobFilters, JobViewMode, WallSummaryMode } from '../reducers/layout.reducer'
import { SortingOptions } from '../models/sorting-options'

export enum LayoutActionTypes {
  UpdateSelectedTab = '[Layout] Update Selected Tab',
  UpdateJobViewMode = '[Layout] UpdateJobViewMode',
  UpdateCollapseAllJobs = '[Layout] UpdateCollapseAllJobs',
  UpdateAllJobsFilters = '[Layout] UpdateAllJobsFilters',
  UpdateCandidateFilters = '[Layout] UpdateCandidateFilters',
  ResetAllJobsFilters = '[Layout] ResetAllJobsFilters',
  UpdateAllJobsSortingOptions = '[Layout] UpdateAllJobsSortingOptions',
  UpdateExecutiveDashboardSortingOptions = '[Layout] UpdateExecutiveDashboardSortingOptions',
  UpdateAreAllJobsLoaded = '[Layout] UpdateAreAllJobsLoaded',
  UpdateRequestAllJobsToLoad = '[Layout] UpdateRequestAllJobsToLoad',
  UpdateListOfJobsInCurrentWall = '[Layout] UpdateListOfJobsInCurrentWall',
  UpdateViewSingleJobOnWall = '[Layout] UpdateViewSingleJobOnWall',
  UpdateWallSummaryMode = '[Layout] UpdateWallSummaryMode',
  UpdateCalendarWeek = '[Layout] UpdateCalendarWeek',
}

export class UpdateSelectedTab implements Action {
  readonly type = LayoutActionTypes.UpdateSelectedTab
  constructor(public payload: { tabId: number }) { }
}

export class UpdateAllJobsFilters implements Action {
  readonly type = LayoutActionTypes.UpdateAllJobsFilters
  constructor(public payload: Partial<JobFilters>) { }
}

export class ResetAllJobsFilters implements Action {
  readonly type = LayoutActionTypes.ResetAllJobsFilters
  constructor() { }
}

export class UpdateCandidateFilters implements Action {
  readonly type = LayoutActionTypes.UpdateCandidateFilters
  constructor(public payload: Partial<CandidateFilters>) { }
}

export class UpdateJobViewMode implements Action {
  readonly type = LayoutActionTypes.UpdateJobViewMode
  constructor(public payload: {jobId: string, viewMode: JobViewMode}) { }
}

export class UpdateCollapseAllJobs implements Action {
  readonly type = LayoutActionTypes.UpdateCollapseAllJobs
  constructor(public payload: { collapseAllJobs: boolean }) { }
}

export class UpdateAllJobsSortingOptions implements Action {
  readonly type = LayoutActionTypes.UpdateAllJobsSortingOptions
  constructor(public payload: SortingOptions) { }
}

export class UpdateExecutiveDashboardSortingOptions implements Action {
  readonly type = LayoutActionTypes.UpdateExecutiveDashboardSortingOptions
  constructor(public payload: SortingOptions) { }
}

export class UpdateAreAllJobsLoaded implements Action {
  readonly type = LayoutActionTypes.UpdateAreAllJobsLoaded
  constructor(public payload: boolean) { }
}

export class UpdateRequestAllJobsToLoad implements Action {
  readonly type = LayoutActionTypes.UpdateRequestAllJobsToLoad
  constructor(public payload = true) { }
}

export class UpdateListOfJobsInCurrentWall implements Action {
  readonly type = LayoutActionTypes.UpdateListOfJobsInCurrentWall
  constructor(public payload: string[]) { }
}

export class UpdateViewSingleJobOnWall implements Action {
  readonly type = LayoutActionTypes.UpdateViewSingleJobOnWall
  constructor(public payload: string | undefined = undefined) { }
}

export class UpdateWallSummaryMode implements Action {
  readonly type = LayoutActionTypes.UpdateWallSummaryMode
  constructor(public payload: WallSummaryMode) { }
}

export class UpdateCalendarWeek implements Action {
  readonly type = LayoutActionTypes.UpdateCalendarWeek
  constructor(public payload: string) { }
}

export type LayoutActions =
  | UpdateSelectedTab
  | UpdateJobViewMode
  | UpdateCollapseAllJobs
  | UpdateAllJobsFilters
  | ResetAllJobsFilters
  | UpdateCandidateFilters
  | UpdateAllJobsSortingOptions
  | UpdateExecutiveDashboardSortingOptions
  | UpdateAreAllJobsLoaded
  | UpdateRequestAllJobsToLoad
  | UpdateListOfJobsInCurrentWall
  | UpdateViewSingleJobOnWall
  | UpdateWallSummaryMode
  | UpdateCalendarWeek
