import { Component, Input, OnInit } from '@angular/core'
import { Observable, of } from 'rxjs'
import { PdfGlobalService } from '../../services/pdf-global.service'
import { concatMap, delay, skip, tap } from 'rxjs/operators'

@Component({
  selector: 'twng-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class PdfSpinnerOverlayComponent implements OnInit {
  exporting$: Observable<boolean>
  // used if exporting$ is null (for example, when this is not used as pdf
  // export overlay)
  isExporting: boolean

  constructor(private pdfGlobal: PdfGlobalService) {

  }

  @Input()
  set exporting(exporting: boolean) {
    if (this.exporting$) {
      this.pdfGlobal.globalPdfExporting$.next(exporting)
    } else {
      this.isExporting = exporting
    }
  }

  @Input()
    placeholder = 'Preparing your PDF...'

  @Input()
    isPdfSpinner = true

  @Input()
    positionAbsolute = false

  ngOnInit() {
    /**
     * Observable<boolean> that emits when the exporting to pdf status changed.
     * If the emitted value is false, add a delay to wait for gridster items
     * finishing to locate in the grid because they have a transition time.
     */
    if (this.isPdfSpinner) {
      this.exporting$ = this.pdfGlobal.globalPdfExporting$.pipe(
        skip(1),
        concatMap((exporting) => {
          const of$ = of(exporting)
          if (exporting) {
            return of$.pipe(
              tap(() => setTimeout(() => this.preventScroll()))
            )
          }
          return of$.pipe(
            delay(2000),
            tap(() => this.allowScroll()),
          )
        })
      )
    }
  }

  preventScroll() {
    document.body.classList.add('overflow--hidden')
    document.body.classList.add('exporting-pdf')
  }

  allowScroll() {
    document.body.classList.remove('overflow--hidden')
    document.body.classList.remove('exporting-pdf')
  }

}
