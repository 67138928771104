<twng-modal>
  <twng-modal-header>
    <twng-modal-title slot="header-title">
      EEOC filters
    </twng-modal-title>
  </twng-modal-header>
  <twng-modal-body>
    <twng-generic-filter placeholder="Gender" [options]="genderOptions"
      (selectedOptionsChange)="genderSelectionChanged($event)" [defaultSelectedOptions]="defaultSelectedGenders()">
    </twng-generic-filter>
    <twng-generic-filter placeholder="Race" [options]="raceOptions"
      (selectedOptionsChange)="raceSelectionChanged($event)" [defaultSelectedOptions]="defaultSelectedRaces()">
    </twng-generic-filter>
    <twng-generic-filter placeholder="Disability" [options]="disabilityOptions"
      (selectedOptionsChange)="disabilitySelectionChanged($event)"
      [defaultSelectedOptions]="defaultSelectedDisabilities()"></twng-generic-filter>
    <twng-generic-filter placeholder="Veteran" [options]="veteranOptions"
      (selectedOptionsChange)="veteranSelectionChanged($event)" [defaultSelectedOptions]="defaultSelectedVeterans()">
    </twng-generic-filter>
  </twng-modal-body>
  <twng-modal-footer>
    <button type="button" class="btn btn-primary" (click)="save()">
      Save
    </button>
  </twng-modal-footer>
</twng-modal>