
import { Pipe, PipeTransform } from '@angular/core'

import { empty } from '../utils/general-utils'

@Pipe({ name: 'empty' })
export class EmptyPipe implements PipeTransform {
  transform(value): boolean {
    return empty(value)
  }
}
