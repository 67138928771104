import * as fromCardDetailComponents from '../../card-details/components'
import { AllJobsFiltersComponent } from './all-jobs-filters.component'
import {
  AnalyticsDateRangeFiltersComponent
} from './analytics-date-range-filters/analytics-date-range-filters.component'
import { BadgeGroupComponent } from './badge-group.component'
import { CalendarComponent } from './calendar/calendar.component'
import { CandidateFiltersComponent } from './candidate-filters.component'
import { CandidateTagFiltersComponent } from './candidate-tag-filter.component'
import { CandidatesInStageModule } from '../../candidates-in-stage/candidates-in-stage.module'
import { CardComponent } from './card/card.component'
import { CardContainerComponent } from './card/card-container.component'
import { ClipboardModule } from 'ngx-clipboard'
import { CollapseJobsComponent } from './collapse-jobs.component'
import { CollapsedCalendarComponent } from './calendar/collapsed-calendar.component'
import { CollapsedChartComponent } from './wall-summary/collapsed-chart.component'
import {
  ConfigureHiringPredictionsComponent
} from './configure-hiring-predictions/configure-hiring-predictions.component'
import { ConfirmationPopoverModule } from 'angular-confirmation-popover'
import { CustomFieldsModule } from '../../custom-fields/custom-fields.module'
import { ComponentsModule as DashboardComponentsModule } from '../../dashboard/components'
import {
  EeocFiltersOnJobAnalyticsModalComponent
} from './eeoc-filters-on-job-analytics-modal/eeoc-filters-on-job-analytics-modal.component'
import { FullCalendarModule } from '@fullcalendar/angular'
import { InterviewCostComponent } from './interview-cost.component'
import { JobActionButtonsComponent } from './job/job-action-buttons/job-action-buttons.component'
import { JobAnalyticsComponent } from './job-analytics.component'
import { JobComponent } from './job.component'
import { JobNameFilterComponent } from './job-name-filter.component'
import { JobStageComponent } from './job-stage.component'
import {
  JobStagePredictionInputComponent
} from './configure-hiring-predictions/job-stage-prediction-input/job-stage-prediction-input.component'
import { JobStatusComponent } from './job-status/job-status.component'
import { JobsComponent } from './jobs.component'
import { JobsFiltersComponent } from './jobs-filters.component'
import { JobsSelectorComponent } from './jobs-selector.component'
import { NgModule } from '@angular/core'
import { NumJobsComponent } from './num-jobs.component'
import { SharedModule } from '../../shared/shared.module'
import { SortJobComponent } from './sort-job/sort-job.component'
import { SortJobsComponent } from './sort-jobs.component'
import { SortJobsModalComponent } from './sort-job/sort-jobs-modal.component'
import { TabBadgesComponent } from './tab-badges/tab-badges.component'
import { TabFormComponent } from './tab-form.component'
import { TimeAgoPipe } from '../../shared/pipes/time-ago.pipe'
import { WallComponent } from './wall.component'
import { WallSharedComponent } from './wall-shared/wall-shared.component'
import { WallSharedSingleJobComponent } from './wall-shared-single-job/wall-shared-single-job.component'
import { WallSummaryWrapperComponent } from './wall-summary/wall-summary-wrapper.component'

export const COMPONENTS = [
  CardContainerComponent,
  CardComponent,
  JobComponent,
  JobsComponent,
  JobNameFilterComponent,
  JobStageComponent,
  WallComponent,
  CandidateFiltersComponent,
  CandidateTagFiltersComponent,
  JobsFiltersComponent,
  JobsSelectorComponent,
  AllJobsFiltersComponent,
  CollapseJobsComponent,
  BadgeGroupComponent,
  TabFormComponent,
  SortJobsModalComponent,
  SortJobComponent,
  SortJobsComponent,
  JobAnalyticsComponent,
  NumJobsComponent,
  InterviewCostComponent,
  ConfigureHiringPredictionsComponent,
  JobStagePredictionInputComponent,
  AnalyticsDateRangeFiltersComponent,
  JobStatusComponent,
  WallSharedComponent,
  TabBadgesComponent,
  JobActionButtonsComponent,
  EeocFiltersOnJobAnalyticsModalComponent,
  WallSharedSingleJobComponent,
  CalendarComponent,
  WallSummaryWrapperComponent,
  CollapsedChartComponent,
  CollapsedCalendarComponent,
]

@NgModule({
  imports: [
    SharedModule,
    fromCardDetailComponents.ComponentsModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger',
      cancelButtonType: 'secondary',
    }),
    DashboardComponentsModule,
    CustomFieldsModule,
    ClipboardModule,
    CandidatesInStageModule,
    FullCalendarModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [TimeAgoPipe]
})
export class ComponentsModule { }
