<table class="table" *ngIf="data" >
  <thead  twngTableSortHandler [tableElements]="data.visible"
  class="fs-unmask tw-bg-gray-50 tw-border-b tw-border-solid tw-border-gray-200 tw-text-gray-600 tw-truncate" [class.red-header]="isWarningTable" appSticker>
    <tr>
      <th *ngIf="isWarningTable" class="!tw-bg-gray-50">Status</th>
      <th twngTableSort [sortByAttribute]="el.name" *ngFor="let el of data.schema" class="tw-py-4 !tw-bg-gray-50">
        {{ el.text }}
        <i *ngIf="el.tooltip" [ngbTooltip]="el.tooltip" class="fas fa-info-circle"></i>
      </th>
    </tr>
  </thead>
  <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200 tw-truncate">
    <tr *ngFor="let row of data.visible" [ngClass]="{'excluded': row.is_excluded?.value}">
      <td *ngIf="isWarningTable">This data is private or confidential</td>
      <td *ngFor="let el of data.schema" scope='col' class="tw-align-middle">
        <ng-container *ngIf="isArray(row[el.name]) && el.value_type !== 'multirow-text'">
          <twng-generic-table-single-column *ngFor="let value of row[el.name]" [schema]="el" [value]="value" 
            (dataChanged)="onDataChanged($event)">
          </twng-generic-table-single-column>
        </ng-container>
        <twng-generic-table-single-column
          *ngIf="!isArray(row[el.name]) || el.value_type === 'multirow-text'" 
          [schema]="el" 
          [value]="row[el.name]" 
          (dataChanged)="onDataChanged($event)">
        </twng-generic-table-single-column>
      </td>
    </tr>
  </tbody>
</table>
