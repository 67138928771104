import { CommonModule } from '@angular/common'
import { CustomFieldsFilterComponent } from './components/custom-fields-filter.component'
import { NgModule } from '@angular/core'
import { SelectedCustomFieldToFilterOptionPipe } from './pipes/selected-custom-field-to-stirng'
import { SharedModule } from '../shared/shared.module'
import { StoreModule } from '@ngrx/store'
import { customFieldsFeatureKey, reducer } from './reducers'

@NgModule({
  declarations: [
    CustomFieldsFilterComponent,
    SelectedCustomFieldToFilterOptionPipe,
  ],
  exports: [
    CustomFieldsFilterComponent,
    SelectedCustomFieldToFilterOptionPipe,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(customFieldsFeatureKey, reducer),
    SharedModule
  ]
})
export class CustomFieldsModule {
}
