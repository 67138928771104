import { ChangeDetectionStrategy, Component } from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-layout',
  template: `
    <twng-header></twng-header>
    <ng-content></ng-content>
  `,
})
export class LayoutComponent { }
