import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'

import { DashboardActionTypes, DashboardFilterActions } from '../actions/filters.actions'
import { DashboardChart } from '../models/dashboard-chart'
import { GridsterDashboardActionTypes, RollbackGridsterChart } from '../actions/gridster-dashboard.actions'
import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import { objValuesSafe } from '../../shared/utils/general-utils'


export interface State extends EntityState<DashboardChart> {
  renamingChartId: string,

  // Custom Box
  editingCustomStatBox: boolean,
  customStatBoxId: string
}

export function sortByPosition(a: DashboardChart, b: DashboardChart): number {
  return a.position - b.position
}

export const adapter: EntityAdapter<DashboardChart> = createEntityAdapter<DashboardChart>({
  sortComparer: sortByPosition,
})

export const initialState: State = adapter.getInitialState({
  renamingChartId: '',
  editingCustomStatBox: false,
  customStatBoxId: ''
})

export function reducer(
  state = initialState,
  action: LoaderActions | DashboardFilterActions | RollbackGridsterChart,
): State {
  switch (action.type) {
    case LoaderActionTypes.LoadDashboardTabSuccess: {
      if (action.payload.tab_charts) {
        return adapter.upsertMany(action.payload.tab_charts, state)
      }
      return state
    }

    case LoaderActionTypes.UpdateDashboardWidgetDataFromServer: {
      const data = action.payload?.data
      return adapter.updateOne({
        id: parseInt(action.payload.id, 10),
        changes: {
          data
        }
      }, state)
    }

    case LoaderActionTypes.UpdateWidgetStatusFromQueueKey: {
      const charts = objValuesSafe(state.entities).filter(c => c.data?.queue_key === action.payload.queue_key)
      let ret = state
      charts.forEach((chart) => {
        ret = adapter.updateOne({
          id: chart.id,
          changes: {
            data: action.payload
          }
        }, ret)
      })
      return ret
    }

    case LoaderActionTypes.LoadSharedDashboardTabSuccess: {
      if (action.payload.tab_charts) {
        return adapter.upsertMany(action.payload.tab_charts, state)
      }
      return state
    }

    case DashboardActionTypes.DashboardSendChartToCustomSuccess: {
      if (action.payload.dashboard_chart) {
        return adapter.upsertOne(action.payload.dashboard_chart, state)
      }
      return state
    }

    case DashboardActionTypes.DashboardRemoveChartSuccess: {
      if (action.payload.dashboard_chart) {
        return adapter.removeOne(action.payload.dashboard_chart.id, state)
      }
      return state
    }

    case DashboardActionTypes.UpdateDashboardChartSuccess: {
      if (action.payload.dashboard_chart) {
        return adapter.upsertOne(action.payload.dashboard_chart, state)
      }
      return state
    }

    /**
     * Creates a new instance of the chart so Gridster detects
     * a changes when change detection runs and the rollback action works.
     * When a chart is passed to the gridster item wrapper, a new GridsterItem
     * is created so a new chart instance recreates a new GridsterItem and the chart
     * will be placed in the original position.
     */
    case GridsterDashboardActionTypes.RollbackGridsterChart: {
      const data = state?.entities[action.payload.chartId]
      if (data) {
        const chart: DashboardChart = {
          ...data,
        }
        return adapter.upsertOne(chart, state)
      } else {
        return state
      }
    }

    case DashboardActionTypes.EnableEditDashboardChartName: {
      return {
        ...state,
        renamingChartId: action.payload.chartId
      }
    }

    case DashboardActionTypes.DisableEditDashboardChartName: {
      return {
        ...state,
        renamingChartId: ''
      }
    }

    case DashboardActionTypes.EnableEditCustomStatBox: {
      return {
        ...state,
        customStatBoxId: action.payload.chartId,
        editingCustomStatBox: true,
      }
    }

    case DashboardActionTypes.DisableEditCustomStatBox: {
      return {
        ...state,
        customStatBoxId: '',
        editingCustomStatBox: false,
      }
    }

    default: {
      return state
    }
  }
}
