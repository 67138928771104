<div *ngIf="{
        prospectJobs: prospectJobs$ | async,
        jobsWithProspects: jobsWithProspects$ | async
        } as asyncValues"
     class="prospects">
  <twng-prospect *ngFor="let job of asyncValues.prospectJobs"
                 [prospectJob]="job"
                 [prospectCandidates]="asyncValues.jobsWithProspects[job.id]"
                 (showCandidateCard)="openCandidateModal($event)">
  </twng-prospect>
</div>

