import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { ExecutiveDashboardEffects } from '../wall/effects/executive-dashboard.effects'
import { NgModule } from '@angular/core'

import { ComponentsModule } from './components'

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    EffectsModule.forFeature([ExecutiveDashboardEffects]),
  ],
  exports: [ComponentsModule]
})
export class ExecutiveDashboardModule {
}
