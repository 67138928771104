import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions';


export interface SharedDataState {
  loaded: boolean
}

const defaultState: SharedDataState = {
  loaded: false,
}

export function reducer(state = defaultState, action: LoaderActions) {
  switch (action.type) {
    case LoaderActionTypes.LoadSharedDataSuccess: {
      return {
        ...state,
        loaded: true,
      }
    }
  }
  return state
}
