import { CustomFieldFilterOption } from '../models/custom-field-filter-option'
import { CustomFieldProps } from '../models/custom-field'
import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import { SelectedCustomFieldsFilter } from '../models/selected-custom-fields-filter'

export const customFieldsFeatureKey = 'customFields'

export interface CustomFieldsState {
  customFields: CustomFieldProps[]
  candidateCustomFields: CustomFieldProps[]
  offerCustomFields: CustomFieldProps[]

  // Filter options for charts will be always the same for all charts.
  // Calculate them just once.
  filterOptions: CustomFieldFilterOption[]
  candidateFilterOptions: CustomFieldFilterOption[]
  offerFilterOptions: CustomFieldFilterOption[]
}

const CustomFieldFilterOptionDiscriminator = ': '

export const createCustomFieldFilterOptionId = (customFieldId: string, customOptionValue: string) =>
  `${customFieldId}${CustomFieldFilterOptionDiscriminator}${customOptionValue}`

export const createCustomFieldFilterOptionName = (customFieldName: string, customOptionValue: string) =>
  `${customFieldName}${CustomFieldFilterOptionDiscriminator}${customOptionValue}`

const mapCustomFieldToFilterOptions = (customFields: CustomFieldProps[]): CustomFieldFilterOption[] =>
  customFields.flatMap(customField =>
    customField.custom_field_options.map(customOption => ({
      id: createCustomFieldFilterOptionId(customField.id, customOption),
      name: createCustomFieldFilterOptionName(customField.name, customOption),
      excludedFromFilters: customField.excluded_from_filters,
      customFieldId: customField.id,
      customOptionValue: customOption
    })
    )
  )

const initialState: CustomFieldsState = {
  customFields: [],
  filterOptions: [],
  candidateCustomFields: [],
  offerCustomFields: [],
  candidateFilterOptions: [],
  offerFilterOptions: [],
}

export function reducer(
  state: CustomFieldsState = initialState,
  action: LoaderActions,
): CustomFieldsState {
  switch (action.type) {

    case LoaderActionTypes.LoadSharedDataSuccess: {
      const customFields = action.payload?.custom_fields
      const candidateCustomFields = action.payload?.candidate_custom_fields
      const offerCustomFields = action.payload?.offer_custom_fields
      const newState = JSON.parse(JSON.stringify(state)) as CustomFieldsState
      if (customFields) {
        newState.customFields = customFields
        newState.filterOptions = mapCustomFieldToFilterOptions(customFields)
      }
      if (candidateCustomFields) {
        newState.candidateCustomFields = candidateCustomFields
        newState.candidateFilterOptions = mapCustomFieldToFilterOptions(candidateCustomFields)
      }
      if (offerCustomFields) {
        newState.offerCustomFields = offerCustomFields
        newState.offerFilterOptions = mapCustomFieldToFilterOptions(offerCustomFields)
      }
      return newState
    }

    default: {
      return state
    }
  }
}


/**
 * Given a list of filter options like "custom_field_id:value", return a map
 * with "custom_field_id" as the key and an array of the values for this "custom_field_id".
 * For example, ["a:1", "a:2", "b:3"] => Map("a": ["1","2"], "b": ["3"])
 *
 * @param customFieldFilterOptions
 */
export const groupCustomFieldsFilterOptions =
  (customFieldFilterOptions: SelectedCustomFieldsFilter[]): Map<string, string[]> => {
    const customFieldsMap = new Map<string, string[]>();
    (customFieldFilterOptions || []).forEach((filterOption: SelectedCustomFieldsFilter) => {
      const customFieldId = filterOption.id
      const customOptionValue = filterOption.values
      customFieldsMap.set(customFieldId, customOptionValue)
    })
    return customFieldsMap
  }
