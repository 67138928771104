import { ChartFilters } from "../../../models/chart-filters"
import { DashboardChart } from "../../../models/dashboard-chart"
import { DashboardSetFilters, UpdateDashboardChart } from "../../../actions/filters.actions"
import { DashboardTypes } from "../../dashboard-types"
import { Directive, EventEmitter, Input, Output } from "@angular/core"
import { SearchTypeaheadDataManager } from "./single-chart-filter.component"
import { SegmentService } from "../../../../core/services/segment.service"
import { ShowSpinner } from "../../../actions/spinner.action"
import { Store } from "@ngrx/store"

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class CommonSingleChartHandler {
  @Input()
    filtersEnabled = true

  @Input()
    filters: ChartFilters

  @Input()
    isTempDashboard: boolean

  @Input()
    panel: DashboardTypes

  @Input()
    chart: DashboardChart

  @Output()
    onFiltersChanged = new EventEmitter<Partial<ChartFilters>>()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() openFilters = new EventEmitter<any>()

  @Input()
    chartFiltersReadOnly: boolean

  constructor(protected segmentService: SegmentService, protected store: Store) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clickOpenFilters(value: SearchTypeaheadDataManager<any>[]) {
    this.openFilters.emit({managers: value, componentName: this.id});
  }

  applyFilters(allFilters?) {
    let filters = allFilters
    if (!allFilters) {
      filters = this.getChangedValueForStoring()
    }

    if (this.isTempDashboard) {
      this.onFiltersChanged.emit(filters)
      return
    }

    const panel = this.panel
    const dashboard_chart = this.chart

    if (panel) {
      // dashboard filters (company/mine)
      this.segmentService.track("Set Dashboard Filters", { panel, filters })

      this.store.dispatch(new DashboardSetFilters({
        panel,
        filters,
      }))

      this.store.dispatch(new ShowSpinner())
    } else if (dashboard_chart) {
      // custom dashboard chart filters
      this.segmentService.track("Set Chart Filters", { chartName: dashboard_chart.name, filters })
      this.store.dispatch(new UpdateDashboardChart({
        dashboard_chart,
        filters,
      }))
    }
  }

  get tooltipText(): string[] {
    if (!this.filtersEnabled) {
      return ['Cannot change individual chart filters while override filters are enabled']
    } else {
      return this.getTooltipIfEditable()
    }
  }

  abstract getChangedValueForStoring(): Partial<ChartFilters>
  protected abstract getTooltipIfEditable(): string[]
  abstract id: string;
}
