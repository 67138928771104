<form [formGroup]="form" class="interview-cost form">
  <header class="stats__label">Total Interviewer Time Cost</header>
  <div class="stats__big-number-wrapper" ngbTooltip="Interview Hours multipled by Interview Hourly Cost">
    <div class="stats__big-number stats__big-number--sidebar">
      ${{ totalInterviewCost() }}
    </div>
  </div>

  <header class="stats__label">Interviewer Time Cost Per Hire</header>
  <div class="stats__big-number-wrapper" ngbTooltip="Cost of Total Interview Time divided by Hires">
    <div class="stats__big-number stats__big-number--sidebar">
      {{ costPerHire() }}
    </div>
  </div>

  <div class="stage__stat-label--small label--ih">
    Interview Hourly Cost
  </div>

  <div class="cost-wrapper" ngbTooltip="Estimated hourly cost of interviewers' time">
    <span class="stage__stat-label--small label--dollar">$</span>

    <input class="form-control-sm" aria-label="" type="text" autocomplete="off" max="9999" #dollarCostInput
      formControlName="dollarsPerHour" (change)="onSubmit()" (blur)="onSubmit()" [ngbPopover]="activePopover"
      placement="top auto" popoverClass="app-popover" triggers="focus" aria-label="Interview Hours Calculator" />
  </div>
</form>

<ng-template #activePopover>
  <div class="section__item-label" style="margin-bottom: 0.25rem">Interview Hours Calculator</div>
  <div class="section__item-value">Enter a value to automatically calculate</div>
</ng-template>