import { ActivityDayCategories, ActivityLabels,
  getActivityLevelName
} from '../../../wall/models/activity-day-categories';
import { AppConfigService } from '../../../wall/services/app-config.service';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimeAgoPipe } from '../../pipes/time-ago.pipe';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-activity-level',
  templateUrl: './activity-level.component.html',
  styleUrls: ['./activity-level.component.scss']
})
export class ActivityLevelComponent {

  @Input()
    activityDayCategories: ActivityDayCategories

  @Input()
    lastActivityDaysAgo: number

  @Input()
  set lastActivityAt(lastActivityAt: string) {
    this.lastActivityTime = new Date(lastActivityAt).toString().replace(/GMT-\d+/, '')
    this.timeAgo = this.timeAgoString(lastActivityAt)
  }

  @Input()
  set activityLevel(activityLevel: string | ActivityLabels) {
    this.activityLevelName = getActivityLevelName(activityLevel)
  }

  timeAgo = ''
  activityLevelName = ''
  lastActivityTime = ''

  constructor(private appConfig: AppConfigService,
    private timeAgoPipe: TimeAgoPipe) {
  }

  timeAgoString(timeString: string) {
    return this.timeAgoPipe.transform(timeString, this.lastActivityDaysAgo, this.appConfig.showTimeAgo())
  }

}
