import { ChangeDetectionStrategy, ChangeDetectorRef, Component,
  EventEmitter, Input, OnInit, Output
} from '@angular/core'
import { GenericTableSchema } from '../generic-table.component'
import { HttpClient } from '@angular/common/http'
import { apiHost, getHttpPostOptions, httpGetOptions } from '../../../http-options'

interface ToggleValue {
  method: 'PUT' | 'POST' | 'PATCH' | 'GET' | 'DELETE'
  value: boolean
  url: string
  body: string
}

@Component({
  selector: 'twng-generic-table-single-column',
  templateUrl: './generic-table-single-column.component.html',
  styleUrls: ['./generic-table-single-column.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericTableSingleColumnComponent implements OnInit {
  @Input()
    schema: GenericTableSchema
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() value: any
  @Output()
    dataChanged = new EventEmitter<ToggleValue>()

  isDateValue: boolean
  isGreenhouseLink: boolean
  isTeamableLink: boolean
  isMultirow: boolean
  isInfoOnly: boolean

  isNotEmpty: boolean

  isToggle: boolean
  togglePopover = "Click to exclude this candidate"

  get toggleValue(): ToggleValue {
    return this.value as ToggleValue
  }

  constructor(private http: HttpClient, private change: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.isDateValue = this.schema.value_type === 'date'
    this.isGreenhouseLink = this.schema.value_type === 'greenhouse_link'
    this.isTeamableLink = this.schema.value_type === 'teamable_link'
    this.isMultirow = this.schema.value_type === 'multirow-text'
    this.isInfoOnly = this.schema.value_type === 'info'
    this.isToggle = this.schema.value_type === 'toggle'
    this.isNotEmpty = this.value !== null && this.value !== undefined && this.value.length > 0
    if (this.isToggle) {
      this.getToggleText()
    }
  }

  getToggleText() {
    if (this.toggleValue.value) {
      this.togglePopover = "Click to include this candidate"
    } else {
      this.togglePopover = "Click to exclude this candidate"
    }
  }

  async toggle() {
    if (this.toggleValue.method === 'PUT' || this.toggleValue.method === 'PATCH'
      || this.toggleValue.method === 'POST') {
      const methodToFunction = {
        PUT: this.http.put,
        PATCH: this.http.patch,
        POST: this.http.post,
      }
      await methodToFunction[this.toggleValue.method].bind(this.http)(
        apiHost + this.toggleValue.url,
        this.toggleValue.body.replace('%value%', (!this.toggleValue.value).toString()),
        getHttpPostOptions(),
      ).toPromise()
    } else {
      const methodToFunction = {
        GET: this.http.get,
        DELETE: this.http.delete,
      }
      await methodToFunction[this.toggleValue.method].bind(this.http)(
        apiHost + this.toggleValue.url,
        httpGetOptions,
      ).toPromise()
    }

    this.toggleValue.value = !this.toggleValue.value
    this.dataChanged.emit(this.toggleValue)
    this.change.markForCheck()

    // reload iframe to get updated values
    const iframe = document.getElementById('goalsCharts') as HTMLIFrameElement
    if (iframe) {
      iframe.src += ''
    }

    this.getToggleText()
  }

}
