import { GenericTableInput, GenericTableSchema } from "./generic-table.component"

export const demoSchema: GenericTableSchema[] = [
  {
    name: "name",
    text: "Name",
    value_type: "string"
  },
  {
    name: "greenhouse_link",
    text: "Link",
    value_type: "greenhouse_link"
  },
  {
    name: "status",
    text: "Status",
    value_type: "string"
  },
  {
    name: "job_name",
    text: "Job Name",
    value_type: "string"
  },
  {
    name: "department_names",
    text: "Departments",
    value_type: "string"
  },
  {
    name: "recruiter_name",
    text: "Primary Recruiter",
    value_type: "string"
  },
  {
    name: "source_name",
    text: "Source",
    value_type: "string"
  },
  {
    name: "applied_at",
    text: "Applied",
    value_type: "date"
  },
  {
    name: "last_activity_at",
    text: "Last Activity",
    value_type: "date"
  }
]

export const demoCandidates: Record<string, string>[] = [
  {
    name: "Kizzy Hill",
    status: "Active",
    source_name: "Indeed - Sponsored",
    job_name: "Software Engineer",
    department_names: "Engineering",
    recruiter_name: "Olly Elwyn Barnett",
    applied_at: "2020-09-17T11:50:04.344-07:00",
    last_activity_at: "2020-09-17T11:50:04.297-07:00",
    greenhouse_link: "https://greenhouse.io/"
  },
  {
    name: "Garry Ireland",
    status: "Rejected",
    source_name: "Monster Organic",
    job_name: "Software Engineer",
    department_names: "Engineering",
    recruiter_name: "Brad Angie Dickenson",
    applied_at: "2020-09-17T11:50:03.364-07:00",
    last_activity_at: "2020-09-17T11:50:03.518-07:00",
    greenhouse_link: "https://greenhouse.io/"
  },
  {
    name: "Earl Melancon",
    status: "Active",
    source_name: "craigslist",
    job_name: "Software Engineer",
    department_names: "Engineering",
    recruiter_name: "Timmy Kayden Coupe",
    applied_at: "2020-09-17T11:49:42.739-07:00",
    last_activity_at: "2020-09-17T11:49:42.867-07:00",
    greenhouse_link: "https://greenhouse.io/"
  },
  {
    name: "Jesse Greco",
    status: "Rejected",
    source_name: "WayUp",
    job_name: "Software Engineer",
    department_names: "Engineering",
    recruiter_name: null,
    applied_at: "2020-09-17T11:50:01.699-07:00",
    last_activity_at: "2020-09-17T11:50:01.830-07:00",
    greenhouse_link: "https://greenhouse.io/"
  },
  {
    name: "Lotta Coinse",
    status: "Hired",
    source_name: "Spray n Pray Staffing ",
    job_name: "Chief Finance Officer",
    department_names: "Finance",
    recruiter_name: "Lewis Everette Garnett",
    applied_at: "2018-12-15T12:18:20.181-07:00",
    last_activity_at: "2020-02-14T14:11:09.431-07:00",
    greenhouse_link: "https://greenhouse.io/"
  }
]

export const demoResult: GenericTableInput = {
  name: "Candidates",
  schema: demoSchema,
  visible: demoCandidates,
  data_type: 'candidate',
  hidden: [],
  extra_counts: {},
}
