<div class="header">
  <div class="header__content">
    <div class="header__primary">
      <h1>{{job.name}}</h1>
    </div>
    <div class="header__secondary">
      <h2>
        <span class="categories">
      <span class="departments">
        <span *ngFor="let department of departments$ | async " class="department">
          {{ department.name }}
        </span>
      </span>

      <span *ngIf="present(job.department_ids) && present(job.office_ids)">
        |
      </span>

      <span class="offices">
        <span *ngFor="let office of offices$ | async" class="office">
          {{ office.name }}
        </span>
      </span>
    </span>
      </h2>
    </div>
  </div>

  <button class="header__close header__close--large" type="button" aria-label="Close"
          (click)="closeModal()">
      <span class="large" aria-hidden="true">
        <i class="fas fa-times"></i>
      </span>
  </button>
</div>

<div class="body">
  <ng-container *ngIf="showUpdateJobStatus">
    <h2 class="section__header tw-mb-2 fs-unmask">
      <i class="fas fa-list"></i>
      <span>Update Job Status</span>
    </h2>
    <div class="element-container status-container">
      <twng-update-job-status class="job-status-update" [job]="job" [jobStatusLabels]="jobStatusLabels">
      </twng-update-job-status>
    </div>
  </ng-container>
  <ng-container *ngIf="showNotes">
    <h2 class="section__header tw-mb-2 fs-unmask">
      <i class="fas fa-list"></i>
      <span>Job Notes</span>
    </h2>
    <div class="element-container history-container tw-rounded-md">
      <twng-notes-history [jobStatusUpdates]="jobStatusUpdates$ | async" [jobStatusLabels]="jobStatusLabels"></twng-notes-history>
    </div>
  </ng-container>
</div>
