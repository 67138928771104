import { GlobalActionTypes, GlobalActionsUnion } from './../actions/spinner.action'

export interface State {
  showSpinner: boolean;
}

const initialState: State = {
  showSpinner: false
};

export function reducer(
  state: State = initialState,
  action: GlobalActionsUnion
): State {
  switch (action.type) {
    case GlobalActionTypes.ShowSpinner: {
      return {
        ...state,
        showSpinner: true
      };
    }
    case GlobalActionTypes.HideSpinner: {
      return {
        ...state,
        showSpinner: false
      };
    }
    default:
      return state;
  }
}
