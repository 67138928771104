import {
  INTERVIEW_STATUS_AWAITING_FEEDBACK,
  INTERVIEW_STATUS_COMPLETED,
  INTERVIEW_STATUS_SCHEDULED,
  Interview
} from '../../wall/models/interview';
import { every as _every } from 'lodash-es';

export const interviewsScheduled = (interviews: Interview[]): boolean => (interviews || [])
  .some(interview => interview.status === INTERVIEW_STATUS_SCHEDULED)

export const interviewsHappened = (interviews: Interview[]): boolean => {
  const allInterviews = interviews || [];
  return (
    allInterviews.length > 0 &&
    _every(
      allInterviews,
      // Note: we currently consider an interview complete while awaiting feedback, since feedback is often left unsubmitted
      int =>
        int.status === INTERVIEW_STATUS_COMPLETED ||
        int.status === INTERVIEW_STATUS_AWAITING_FEEDBACK,
    )
  )
}
