/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store';
import { GridsterItem } from 'angular-gridster2';

export enum GridsterDashboardActionTypes {
  AddGridsterChartToUpdate = '[GridsterDashboard] AddGridsterChartToUpdate',
  RollbackAllGridsterCharts = '[GridsterDashboard] RollbackAllGridsterCharts',
  RollbackGridsterChart = '[GridsterDashboard] RollbackGridsterChart',
  PersistGridsterDashboardChanges = '[GridsterDashboard] PersistGridsterDashboardChanges',
  EnableGridsterEditMode = '[GridsterDashboard] EnableGridsterEditMode',
  DisableGridsterEditMode = '[GridsterDashboard] DisableGridsterEditMode',
  ResetGridsterDashboard = '[GridsterDashboard] ResetGridsterDashboard',
}

export class EnableGridsterEditMode implements Action {
  readonly type = GridsterDashboardActionTypes.EnableGridsterEditMode
}

export class DisableGridsterEditMode implements Action {
  readonly type = GridsterDashboardActionTypes.DisableGridsterEditMode
}

export interface GridsterChartToUpdate {
  gridsterItem: GridsterItem,
}

export class AddGridsterChartToUpdate implements Action {
  readonly type = GridsterDashboardActionTypes.AddGridsterChartToUpdate

  constructor(public payload: GridsterChartToUpdate) {
  }
}

export class RollbackAllGridsterCharts implements Action {
  readonly type = GridsterDashboardActionTypes.RollbackAllGridsterCharts
}

export class RollbackGridsterChart implements Action {
  readonly type = GridsterDashboardActionTypes.RollbackGridsterChart

  constructor(public payload: { chartId: string }) {
  }
}

export class PersistGridsterCharts implements Action {
  readonly type = GridsterDashboardActionTypes.PersistGridsterDashboardChanges
}

export class ResetGridsterDashboard implements Action {
  readonly type = GridsterDashboardActionTypes.ResetGridsterDashboard
}

export type GridsterDashboardActions =
  AddGridsterChartToUpdate
  | EnableGridsterEditMode
  | DisableGridsterEditMode
  | PersistGridsterCharts
  | ResetGridsterDashboard
  | RollbackAllGridsterCharts
  | RollbackGridsterChart
