import { Injectable } from '@angular/core';
import { PdfService } from '../../shared/services/pdf.service';
import { jsPDF } from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class CandidatesInStagePdfService extends PdfService {

  protected async generatePDF(elementSelector: string): Promise<void> {
    const pdfFile = await this.createPDFFile(elementSelector)
    await this.addTable(pdfFile, elementSelector);
    // Download the pdf in the browser
    await pdfFile.save(this.generateFileName('talentwall-candidates-in-stage'))
  }

  private async addTable(pdf: jsPDF, elementSelector: string) {
    const logoSizes = this.getLogoSectionDimensions()

    const candidatesTableCanvas = await this.generateCanvasFromHTML(elementSelector)
    const candidatesTableDimensions = this.getImageDimensions(elementSelector)

    // Add the job canvas element to the pdf
    pdf.addImage(
      candidatesTableCanvas,
      'PNG',
      0, // x position on the page
      logoSizes.logoReactHeight, // y position
      candidatesTableDimensions.width,
      candidatesTableDimensions.height,
      '', // alias
      'FAST'
    )
  }

}
