import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'

import { JobStage } from '../../../models/job-stage'
import { SingleJobStageProjectionInfo } from '../../../models/projected-hires'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-job-stage-prediction-input',
  templateUrl: './job-stage-prediction-input.component.html',
  styleUrls: ['./job-stage-prediction-input.component.scss']
})
export class JobStagePredictionInputComponent {

  @Input()
    parentForm: UntypedFormGroup

  @Input()
    jobStage: JobStage

  @Input()
    jobPrediction: SingleJobStageProjectionInfo

  @Input()
    actualConversionRate: string | number

  actualConversionRateFixed(): string {
    const conversionRate = typeof this.actualConversionRate === "string" ?
      parseFloat(this.actualConversionRate) : this.actualConversionRate
    return conversionRate?.toFixed(0)
  }

  typeOfPrediction(): string {
    let type = ""
    switch(this?.jobPrediction?.recommendation_source) {
      case "user_custom":
        type = "Custom value"
        break;
      case "job_stage":
        type = "Job stage"
        break;
      case "department":
        type = "Department"
        break
      case "account":
        type = "Company"
        break
      case "not_enough_data":
        type = "Not enough data"
        break
      case "department_office":
        type = "Department with same office"
        break
      default:
        type = "Unknown"
    }
    return type
  }
}
