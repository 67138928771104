import { Component, Input, OnInit } from '@angular/core'

import { ChartFilters } from '../models/chart-filters'
import { DashboardPdfService } from '../services/dashboard-pdf.service'
import { DashboardTypes } from './dashboard-types'
import { ExportMainDashboardService } from '../services/export-main-dashboard.service'
import { WidgetLibraryTabExtraLink } from '../reducers/analytics.reducer'

@Component({
  selector: 'twng-widget-library-tab-header',
  template: `
   <div class="dashboard__filters d-flex">
      <a class="section__header-export-pdf-icon actions-button actions-button--dark-bg"
        ngbTooltip="Download as PDF" (click)="exportToPDF(dashboardElementId)">
        <i data-html2canvas-ignore class="fas fa-download"></i>
      </a>

      <span *ngIf="panel === companyDashboard" data-html2canvas-ignore class="icon-divider fs-unmask"></span>

      <a class="actions-button actions-button--dark-bg" *ngIf="panel === companyDashboard">
        <i data-html2canvas-ignore ngbPopover="Click the three dots on a chart to send it to a custom dashboard. Each chart on a custom dashboard can have its own filters. Zoom out to see more at a time."
          class="fas fa-info-circle">
        </i>
      </a>

      <span data-html2canvas-ignore class="icon-divider tw-mr-4 fs-unmask"></span>

      <ng-container *ngIf='dashboardFilters'>
        <twng-chart-filters [panel]="panel" [filters]="dashboardFilters" [style.width.%]="100">
        </twng-chart-filters>
      </ng-container>

      <a [href]="extraLink.link" *ngIf="extraLink" style="margin-left: auto; margin-right: 2rem" target="_blank">
        {{extraLink.text}}
      </a>
    </div>`,
  styleUrls: ['./widget-library-tab-header.component.scss']
})
export class WidgetLibraryTabHeaderComponent implements OnInit {
  @Input()
    dashboardFilters: ChartFilters

  @Input()
    dashboardElementId: string

  @Input()
    panel = DashboardTypes.CompanyDashboard

  @Input()
    extraLink: WidgetLibraryTabExtraLink

  companyDashboard = DashboardTypes.CompanyDashboard

  constructor(
    private dashboardPdfService: DashboardPdfService,
    private exportMainDashboardService: ExportMainDashboardService,
  ) { }

  ngOnInit(): void { }

  // TODO: Do I need both of these export functions in here?
  exportToPDF(sectionId: string) {
    if (sectionId.startsWith("dashboard-")) {
      this.dashboardPdfService.exportToPDF(`#${sectionId}`)
        .catch((err) => console.error(err))
    } else {
      this.exportMainDashboardService.exportToPDF(`#${sectionId}`)
        .catch((err) => console.error(err))
    }
  }
}
