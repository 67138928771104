<div class="header fs-unmask">
  <div class="header__content">
    <div class="header__primary">
        <ng-content select="[slot=header-title]"></ng-content>
    </div>
    <div class="header__secondary">
      <ng-content select="[slot=header-subtitle]"></ng-content>
    </div>
  </div>

  <button class="header__close header__close--large" type="button" aria-label="Close" (click)="closeModal()">
      <span class="large" aria-hidden="true">
        <i class="fas fa-times"></i>
      </span>
  </button>
</div>
