import { HttpHeaders } from '@angular/common/http'
import { isHostedLocally } from '../shared/utils/general-utils'

export const httpGetOptions = {
  // needed to access Rails on a different port (which it runs on in development)
  withCredentials: isHostedLocally(),
}
function getCsrfToken() {
  const csrfTokenTag = document.querySelector('meta[name="csrf-token"]')
  const token = csrfTokenTag ? csrfTokenTag.getAttribute('content') : ''
  return token
}
export function getHttpPostOptions(content_type = 'application/json') {
  return {
    ...httpGetOptions,
    headers: new HttpHeaders({
      Accept: content_type,
      'Content-Type': content_type,
      'X-CSRF-Token': getCsrfToken(),
    }),
  }
}
export const apiHost = window.twng_demo ? '/demo-assets' : (isHostedLocally() ? 'http://localhost:3100' : '')
