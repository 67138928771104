import { ProspectCandidate } from '../models/prospects-candidate.model';
import { ProspectJobApplication } from '../models/prospects-job-application.model';
import { ProspectsActions, ProspectsActionsTypes } from '../actions/prospects.actions';

export const prospectsStateKey = 'prospects'

export interface ProspectsState {
  loadingProspects: boolean
  jobApplications: ProspectJobApplication[]
  candidates: ProspectCandidate[]
}

const initialState: ProspectsState = {
  loadingProspects: false,
  jobApplications: [],
  candidates: [],
}

export function reducer(
  state = initialState,
  action: ProspectsActions
): ProspectsState {
  switch (action.type) {

    case ProspectsActionsTypes.LoadProspects:
      return {
        ...state,
        loadingProspects: true,
      }

    case ProspectsActionsTypes.LoadProspectsSuccess:
      const { candidates } = action.payload
      return {
        ...state,
        loadingProspects: false,
        jobApplications: action.payload.job_applications,
        candidates: (candidates || []).filter(candidate => !!candidate),
      }
    default:
      return state
  }
}
