import { Dictionary } from '@ngrx/entity';
import { ExternalUser } from '../../wall/models/external-user';
import { Job } from '../../wall/models/job';
import { JobApplication } from '../../wall/models/job-application';
import { ProspectsState } from '../reducers';
import { atsId } from '../../wall/models/types';
import { createSelector } from '@ngrx/store';
import { getSource } from '../../shared/utils/job-application';
import { groupBy } from 'lodash-es';
import { selectExternalUserEntities, selectJobEntities } from '../../wall/reducers';
import { selectProspectsState } from './prospects.selectors';


export interface ProspectsCandidate {
  jobApplication: JobApplication
  name: string
  currentTitle: string
  jobId: atsId
  jobName: string
  source: string
  lastActivity: string
  prospectOwner: ExternalUser
}


const getJobName = (candidateId: atsId, jobEntities: Dictionary<Job>, jobApplication: JobApplication) => {
  const job = jobEntities[jobApplication.job_id]
  return (job && job.name) || ''
}

export const selectProspects = createSelector(
  selectProspectsState,
  selectJobEntities,
  selectExternalUserEntities,
  (state: ProspectsState, jobEntities: Dictionary<Job>, externalUsers: Dictionary<ExternalUser>):
  ProspectsCandidate[] => {
    const jobApplications = state.jobApplications
    const candidates = state.candidates
      .map((candidate) => {
        const jobApplication = jobApplications.find(jobApp => jobApp.candidate_id === candidate.id)
        if (!jobApplication) {
          // TODO: What should we do if jobApplication is not found?
          return null
        }

        return {
          name: `${candidate.first_name} ${candidate.last_name}`,
          currentTitle: candidate.current_title,
          jobApplication,
          jobId: jobApplication.job_id,
          jobName: getJobName(candidate.id, jobEntities, jobApplication),
          source: getSource(jobApplication),
          lastActivity: jobApplication.last_activity_at,
          prospectOwner: externalUsers[jobApplication.prospect_owner_id]
        }
      })
      .filter(c => !!c)

    return candidates
  }
)


export const selectGroupedProspectsByJobs = createSelector(
  selectProspects,
  (prospects: ProspectsCandidate[]): Dictionary<ProspectsCandidate[]> => groupBy(prospects, prospect => prospect.jobId)
)
