<div class="job-application tw-card tw-flex tw-flex-row tw-bg-white tw-border tw-rounded tw-shadow"
  [class.job-application-hired]="isHiredCandidate">
  <!-- while waiting for update to complete, show spinner -->
  <i *ngIf="jobApplication.waitingForSync" class="fas fa-sync fa-2x fa-spin"></i>

  <div *ngIf="activityDayCategories && !nearestFutureInterview()" class="tw-flex-none tw-rounded-full tw-w-2.5 tw-mr-2 activity-status-{{ activityLevel() }}">
  </div>

  <div class="tw-flex tw-flex-col tw-items-start tw-w-full">
    <div
      class="top tw-text-base tw-font-medium tw-text-gray-800 tw-leading-none tw-flex-grow tw-cursor-pointer tw-w-full tw-mt-1">
      
      <i *ngIf="jobApplication.starred" class="fas fa-fw fa-star" ngbTooltip="Starred"></i>

      <twng-rating-number style="vertical-align: text-top;" class="tw-pb-1"
        *ngIf="appConfig.enableApplicationRatings() && jobApplication.rating" [selected]="true" variant="small-card"
        [ratingNumber]="jobApplication.rating">
      </twng-rating-number>

      <span class="name fs-exclude">
        {{ candidate?.first_name }} {{ candidate?.last_name }}
      </span>

      <a *ngIf="appConfig.showGreenhouseOnSmallCard() || isHiredCandidate"
        (click)="$event.stopPropagation(); trackViewInAts()" class="atsLink" target="_blank" rel="noopener noreferrer"
        href="{{ urlService.jobApplicationUrl(jobApplication) | async }}" ngbTooltip="View in {{ atsName }}">
        <img class="ats-icon" src="{{ appConfig.atsIconUrl() }}" width="16" height="16" />
      </a>
      <span class="name hired-date fs-exclude tw-font-normal" *ngIf="isHiredCandidate">
        {{ hiredDate }}
      </span>
    </div>

    <i *ngIf="showCalendar()" [ngClass]="calendarClasses()" class="tw-py-1" [ngbTooltip]="calendarTooltip()"></i>

    <twng-tag-icons [tags]="candidate?.tags || []"></twng-tag-icons>

    <div class="bottom-row badge-group tw-w-full">
      <div>
        <span *ngIf="nearestFutureInterview() as soonestInterview; else lastActivity"
          class="badge activity-label activity-label-scheduled"
          [ngbTooltip]="soonestInterview.job_stage && soonestInterview.job_stage.name">
          <span class="fs-unmask">Sched:</span> {{ soonestInterview.start_time | date: 'd MMM' }}
        </span>

        <ng-container *ngIf="showRecruiterOnSmallCard$ | async">
          <span class="badge recruiter-name" *ngIf="recruiter$ | async as recruiter">
            {{ recruiter.name }}
          </span>
        </ng-container>
      </div>

      <ng-template #lastActivity>
        <span *ngIf="activityDayCategories"
          [ngbTooltip]="appConfig.labelFor('last_activity') + ': ' + lastActivityAt()"
          class="badge activity-label activity-label-{{ activityLevel() }} tw-py-1 tw-px-2 tw-rounded-full tw-font-medium tw-flex tw-items-center tw-text-sm tw-bg-cq-gray-100 tw-text-cq-gray-600">
          {{ timeAgo(jobApplication.last_activity_at, jobApplication.last_activity_days_ago) }} <span class="fs-unmask">ago</span>
        </span>
      </ng-template>

      <twng-source-type-icon *ngIf="sourceTypeVisible(jobApplication.source_type)"
        [sourceType]="jobApplication.source_type" [sourceTypeIcon]="sourceTypeIconMappings[jobApplication.source_type]">
      </twng-source-type-icon>
    </div>
  </div>
</div>
