import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-source-type-icon',
  styles: [`
      i {
        /* $tw--gray-900 */
        color: #434343;
      }
  `],
  template: `
    <i
      *ngIf="sourceTypeIcon"
      [ngClass]="fontClasses()"
      [ngbTooltip]="sourceType"
      data-html2canvas-ignore
    ></i>
  `,
})
export class SourceTypeIconComponent implements OnInit {
  @Input()
    sourceTypeIcon: string

  @Input()
    sourceType: string

  constructor() { }

  fontClasses() {
    return ['fas', 'fa-fw', `fa-${this.sourceTypeIcon}`]
  }

  ngOnInit() { }
}
