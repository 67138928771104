import { ComparatorReturnType } from './index';
import { Tab } from '../../../wall/models/tab';

/**
 * Sort tabs by position and if they have equal
 * position then sort by name
 *
 * @param tabs
 */
export const sortTabs = (tabs: Tab[]): Tab[] => tabs.sort((tabA, tabB): ComparatorReturnType => {
  if (tabA.position < tabB.position) {
    return -1
  }

  if (tabA.position > tabB.position) {
    return 1
  }

  // If equals, then sort by name
  return tabA.name.localeCompare(tabB.name) as ComparatorReturnType
})
