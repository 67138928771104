import { groupBy as _groupBy } from 'lodash-es'

import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store'

import * as fromAnalytics from './analytics.reducer'
import * as fromCandidatesInStage from '../../candidates-in-stage/reducers/canidates-in-stage.reducer'
import * as fromDashboard from './dashboard.reducer'
import * as fromRoot from '../../reducers'
import * as fromSpinner from './spinner.reducer'
import { DashboardTabWithCharts } from '../models/dashboard-tab'
import {
  adapter as dashboardChartAdapter, reducer as dashboardChartsReducer,
  State as dashboardChartsState
} from './dashboard-charts.reducer'
import {
  adapter as dashboardTabAdapter, reducer as dashboardTabsReducer, State as dashboardTabsState
} from './dashboard-tabs.reducer'
import {
  adapter as gridsterDashboardAdapter, reducer as gridsterDashboardReducer,
  State as gridsterDashboardState
} from './gridster-dashboard.reducer'

export interface DashboardState {
  analytics: fromAnalytics.AnalyticsState,
  spinner: fromSpinner.State,
  dashboard_tabs: dashboardTabsState,
  dashboard_charts: dashboardChartsState,
  gridster_dashboard: gridsterDashboardState,
  dashboard: fromDashboard.DashboardState,
  candidates_in_stage: fromCandidatesInStage.CandidatesInStageState
}

export interface State extends fromRoot.State {
  dashboard: DashboardState
}

export const reducers: ActionReducerMap<DashboardState> = {
  analytics: fromAnalytics.reducer,
  spinner: fromSpinner.reducer,
  dashboard_tabs: dashboardTabsReducer,
  dashboard_charts: dashboardChartsReducer,
  gridster_dashboard: gridsterDashboardReducer,
  dashboard: fromDashboard.reducer,
  candidates_in_stage: fromCandidatesInStage.reducer,
}

export const selectDashboardState = createFeatureSelector<DashboardState>(
  'dashboard',
)

export const selectAnalytics = createSelector(
  selectDashboardState,
  state => state.analytics,
)

export const selectChartTypes = createSelector(
  selectDashboardState,
  state => state.dashboard.chart_types,
)

export const selectDataSources = createSelector(
  selectDashboardState,
  state => state.dashboard.data_sources,
)

export const selectDashboardTabState = createSelector(
  selectDashboardState,
  state => state.dashboard_tabs
)

export const selectDashboardTemplatesState = createSelector(
  selectDashboardState,
  state => state.dashboard.dashboard_templates
)

export const selectDashboardChartState = createSelector(
  selectDashboardState,
  state => state.dashboard_charts
)

export const selectGridsterDashboardState = createSelector(
  selectDashboardState,
  state => state.gridster_dashboard
)

export const selectChartsInitConfig = createSelector(
  selectDashboardState,
  state => state.dashboard.initial_config
)

export const selectCustomDashboardOverrideFilters = createSelector(
  selectAnalytics,
  state => state?.custom_dashboard_filters,
)

export const {
  selectIds: selectDashboardTabIds,
  selectEntities: selectDashboardTabEntities,
  selectAll: selectAllDashboardTabs,
  selectTotal: selectDashboardTabTotal,
} = dashboardTabAdapter.getSelectors(selectDashboardTabState)

export const {
  selectIds: selectDashboardChartIds,
  selectEntities: selectDashboardChartEntities,
  selectAll: selectAllDashboardCharts,
  selectTotal: selectDashboardChartTotal,
} = dashboardChartAdapter.getSelectors(selectDashboardChartState)


export const selectHiredCandidates = createSelector(
  selectDashboardState,
  state => state.analytics.candidates_hired,
)

export const selectOutstandingOfferCandidates = createSelector(
  selectDashboardState,
  state => state.analytics.outstanding_offers,
)

export const selectSpinnerState = createSelector(
  selectDashboardState,
  state => state.spinner,
)

export const selectShowSpinner = createSelector(
  selectSpinnerState,
  state => state.showSpinner
)

export const selectWidgetLibraryTab = (tab: string) => createSelector(
  selectAnalytics,
  state => state.widget_library_tabs_data && state.widget_library_tabs_data[tab]
)

export const selectWidgetLibraryData = createSelector(
  selectAnalytics,
  state => state.widget_library_tabs_data
)

export const selectDashboardChartById = (id: number | string) => createSelector(
  selectDashboardChartEntities,
  entities => entities[id]
)

export const selectDashboardChartsByDashboardTabId = createSelector(
  selectAllDashboardCharts,
  dashboardCharts => _groupBy(dashboardCharts, chart => chart.tab_id)
)

export const selectAllDashboardTabsWithDashboardCharts = createSelector(
  selectAllDashboardTabs,
  selectDashboardChartsByDashboardTabId,
  (tabs, charts) => tabs.map(t => {

    let tabCharts = charts[t.id]
    if (t.editable && t.saved_as_editable_from_tab_id) {
      tabCharts = charts[t.saved_as_editable_from_tab_id] ? charts[t.saved_as_editable_from_tab_id] : charts[t.id]
    }

    return { ...t, charts: tabCharts }
  }) as DashboardTabWithCharts[]
)

// GRIDSTER DASHBOARD

export const {
  selectIds: selectGridsterDashboardIds,
  selectEntities: selectGridsterDashboardEntities,
  selectAll: selectAllGridsterDashboard,
  selectTotal: selectGridsterDashboardTotal,
} = gridsterDashboardAdapter.getSelectors(selectGridsterDashboardState)

export const selectGridsterDashboard = createSelector(
  selectDashboardState,
  state => state.gridster_dashboard
)

export const selectGridsterEditMode = createSelector(
  selectGridsterDashboard,
  state => state.editModeEnabled
)

export const selectRenamingChartId = createSelector(
  selectDashboardChartState,
  state => state.renamingChartId
)

export const selectEditingCustomStatBox = createSelector(
  selectDashboardChartState,
  state => state.editingCustomStatBox
)

export const selectEditingCustomStatBoxId = createSelector(
  selectDashboardChartState,
  state => state.customStatBoxId
)
