import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { GenericTableComponent } from './components/generic-table/generic-table.component'
import { GenericTableEffects } from './effects/generic-table.effects'
import { GenericTableModalComponent } from './components/generic-table/generic-table-modal.component'
import {
  GenericTableSingleColumnComponent
} from './components/generic-table/generic-table-single-column/generic-table-single-column.component'
import { HeaderComponent } from './components/header.component'
import { InitEffects } from './effects/init.effects'
import { LayoutComponent } from './components/layout.component'
import { NotReadyPageComponent } from '../shared/components/not-found-page/not-ready-page.component'
import { PageNotFoundComponent } from './components/page-not-found.component'
import { SharedModule } from '../shared/shared.module'
import { TabEffects } from './effects/tab.effects'

export const COMPONENTS = [
  LayoutComponent,
  PageNotFoundComponent,
  NotReadyPageComponent,
  HeaderComponent,
  GenericTableModalComponent,
  GenericTableComponent,
  GenericTableSingleColumnComponent,
]

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([InitEffects, TabEffects, GenericTableEffects]),
    SharedModule,
    RouterModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
