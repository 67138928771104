import { CacheService } from './cache.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Office } from '../models/office';
import { Store } from '@ngrx/store';
import { WallState } from '../reducers';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  constructor(private store: Store<WallState>,
    private cacheService: CacheService) {
  }

  getOffices$(officeIds: string[]): Observable<Office[]> {
    return this.cacheService.officeEntities$.pipe(
      map(officesDic => officeIds.map(officeId => officesDic[officeId])),
      map(offices => offices.filter(o => !!o))
    )
  }
}
