import { Observable, Subscription } from 'rxjs'
import { capitalize as _capitalize, includes as _includes, merge as _merge } from 'lodash-es'
import { filter, map } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../reducers'
import { Account } from '../models/account'
import { AppConfig, FeatureFlags } from '../models/app-config'
import { User } from '../models/user'
import { selectAppConfig } from '../../reducers'
import ResizeObserver from 'resize-observer-polyfill'

declare global {
  interface Window {
    twng_demo: boolean
    twng_account?: Account
    twng_user?: User
    twng_impersonating?: boolean
    location: Location
    twng_app_config: AppConfig
    rollbar_access_token: string
    sobrio_host_name: string
    buildVersion: string
    sent_frontend_filenames: {[fileType: string]: string}
    ResizeObserver: ResizeObserver
    Cookies: {
      get: (cookieName: string) => string
      set: (cookieName: string, newValue: string) => string
    }
  }
}

const defaultLabels = {
  last_activity: 'Last Activity',
  note: 'Note',
  notes: 'Notes',
  reject: 'Reject',
}

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  configSub: Subscription
  config: AppConfig

  constructor(private store: Store<fromWall.State>) {
    this.configSub = this.getConfig$().subscribe(config => {
      this.config = config
    })
  }

  getConfig$(): Observable<AppConfig> {
    return this.store.select(selectAppConfig)
      .pipe(
        filter(appConfig => !!appConfig)
      )
  }

  isFeatureFlagEnabled$(featureFlag: FeatureFlags): Observable<boolean> {
    return this.getConfig$().pipe(
      map(appConfig => !!appConfig.feature_flags[featureFlag])
    )
  }

  // returns 0-based first fiscal month
  firstFiscalMonth(): number {
    return this.config?.fiscal_start_month - 1
  }

  atsIconUrl(): string {
    return this.config?.ats_icon_url
  }

  atsName(): string | null {
    return this.config?.ats_name ?
      _capitalize(this.config.ats_name) : null
  }

  urlMethod() {
    return this.config?.url_method
  }

  sendRejectEmail() {
    return this.config?.send_reject_email
  }

  rejectionReasonType() {
    return this.config?.rejection_reason_type
  }

  jobUrlTemplate() {
    return this.config?.job_url_template
  }

  jobApplicationUrlTemplate() {
    return this.config?.job_application_url_template
  }

  jobApplicationScorecardUrlTemplate() {
    return this.config?.job_application_scorecard_url_template
  }

  specialFilters() {
    return this.config?.special_filters
  }

  helpCenterUrl() {
    return this.config?.help_center_url
  }

  canTransferCandidate(): boolean {
    return this.config?.feature_flags?.transfer_candidate
  }

  showGreenhouseOnSmallCard(): boolean {
    return this.config?.feature_flags?.show_ats_link_on_small_card
  }

  showJobIdOnWall(): boolean {
    return this.config?.feature_flags?.show_job_id_on_wall
  }

  canRejectCandidate(): boolean {
    return this.config?.feature_flags?.reject_candidate
  }

  viewNotesEnabled(): boolean {
    return this.config?.feature_flags?.view_notes
  }

  createNotesEnabled(): boolean {
    return this.config?.feature_flags?.create_notes
  }

  rejectedCandidatesAtStageEnabled(): boolean {
    return this.config?.feature_flags?.rejected_candidates_at_stage
  }

  enableApplicationRatings(): boolean {
    return this.config?.feature_flags?.application_ratings
  }

  execDashEnabled(): boolean {
    return this.config?.feature_flags?.hiring_management
  }

  canViewJobStatusOnWall(): boolean {
    return this.config?.feature_flags?.show_job_status_on_wall || this.config?.feature_flags?.hiring_management
  }

  jobPriorities(): string[] {
    const jobPriorities = Array.isArray(this.config.job_priorities) ? this.config.job_priorities : []
    return jobPriorities.filter((str) => {
      const priority = (str && str.trim()) || ''
      return priority !== 'null' && priority !== 'undefined'
    })
  }

  employmentTypes(): string[] {
    const employmentTypes = Array.isArray(this.config.employment_types) ? this.config.employment_types : []
    return employmentTypes.filter((str) => {
      const empType = (str && str.trim()) || ''
      return empType !== 'null' && empType !== 'undefined'
    })
  }

  activeJobFilters(): string[] {
    return this.config?.feature_flags?.active_job_filters
  }

  isJobFilterEnabled(jobFilter: string): boolean {
    return _includes(this.activeJobFilters(), jobFilter)
  }

  demoEnabled(): boolean {
    return !!window.twng_demo
  }

  labelFor(key: string): string {
    if (this.config) {
      const custom_labels = this.config.custom_labels || {}
      const labels = _merge(defaultLabels, custom_labels)
      return labels[key]
    } else {
      return defaultLabels[key]
    }
  }

  showTimeAgo(): boolean {
    if (this.config && this.config.feature_flags) {
      return this.config.feature_flags.activity_time_distance_ago
    } else {
      return true
    }
  }

  showMyDashboard(): boolean {
    return this.config?.feature_flags?.my_dashboard
  }

  showFilterByAnyTag = () => this.config?.feature_flags?.filter_by_any_tag

  dashboardExternalLinks(): Record<string, unknown> {
    if (this.config && this.config.dashboard_external_links) {
      const externalLinks = {}
      for (const key in this.config.dashboard_external_links) {
        if (key !== 'dashboard/candidates_in_stage') {
          externalLinks[key] = this.config.dashboard_external_links[key]
        }
      }
      return externalLinks
    }
  }

  enableCustomDashboards() {
    return this.config?.feature_flags?.custom_dashboards
  }

  canExportDashboardToPDF() {
    return this.config?.feature_flags?.export_dashboard_to_pdf
  }

  canExportWallToPDF() {
    return this.config?.feature_flags?.export_wall_to_pdf
  }

  canViewProspectsTab() {
    return this.config?.feature_flags?.view_prospects_tab
  }

  showInterviewPhases() {
    return this.config?.feature_flags?.interview_phases
  }

  showCandidatesInStage() {
    return this.config?.feature_flags?.candidates_in_stage
  }
}
