<twng-modal>
  <twng-modal-header>
    <twng-modal-title slot="header-title">{{ actionName() }}</twng-modal-title>
  </twng-modal-header>
  <form [formGroup]="dashboardForm" (ngSubmit)="submit()">
    <twng-modal-body>
      <div class="form-group">
        <label for="dashboard_name">Name *</label>
        <input type="text" id="dashboard_name" class="form-control" name="name"
               formControlName="name"
               maxlength="40" autocomplete="off" />
      </div>
      <div *ngIf="isNewTab() && !(dashboardTemplates | empty)">
        <div class="tw-mb-1 tw-mr-1">
          Choose a template to start, or start from a blank dashboard, adjust and share!
        </div>
        <div class="templates-container d-flex flex-wrap">
          <div class="template-box m-1 p-3 position-relative" [class.selected]="dashboardForm.value.template === template.key"
            *ngFor="let template of dashboardTemplates | keyvalue"
            (click)="dashboardForm.controls.template.setValue(template.key)">
            <i class="template-icon fa w-100 text-center tw-mb-4 tw-mt-4 d-block" [ngClass]="template.value.icon_class"></i>
            <div class="template-title font-weight-bold tw-mb-1">{{ template.value.name }}</div>
            <div class="template-description">{{ template.value.description }}</div>
            <i class="position-absolute check-icon fas fa-check-circle" *ngIf="dashboardForm.value.template === template.key"></i>
          </div>
        </div>
      </div>
    </twng-modal-body>
    <twng-modal-footer>
      <button type="submit" class="tw-mt-2 btn btn-primary">
        {{ actionName() }}
      </button>
    </twng-modal-footer>
  </form>
</twng-modal>
