import * as computedStyleToInlineStyle from 'computed-style-to-inline-style'
import { BehaviorSubject } from 'rxjs'
import { format } from 'date-fns'
import { jsPDF } from 'jspdf'
import domtoimage from 'dom-to-image'
import slugify from 'slugify'

import { Injectable } from '@angular/core'

interface Dimensions {
  width: number,
  height: number,
}

@Injectable({
  providedIn: 'root',
})
/**
 * TODO: This service must inherit from PdfService when export Wall to pdf is merged.
 * A lot of this code will be removed.
 * TODO: Add the green overlay while exporting when export Wall to pdf is merged.
 */
export class ExportMainDashboardService {

  static backgroundColor = '#f5f7fb'; // Same as Dashboard
  static logoColor = '#2988E2';

  public exporting$ = new BehaviorSubject<boolean>(false)

  getHeaderDate(): string {
    return format(new Date(), 'MM/dd/yyyy')
  }

  generateFileName(elementString): string {
    return slugify(`talentwall-${elementString}-${format(new Date(), 'yyyy-MM-dd')}.pdf`)
  }

  filterNode(node) {
    return (!node.attributes?.hasOwnProperty('data-html2canvas-ignore'))
  }

  private generateCanvasFromHTML(selector: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const canvasOptions = {
        bgcolor: ExportMainDashboardService.backgroundColor,
        width: window.innerWidth,
        filter: this.filterNode
      }

      const el = document.querySelector(selector) as HTMLElement
      computedStyleToInlineStyle(el, {
        recursive: true,
        properties: ["font-size", "font-family", "font-weight"]
      })

      domtoimage.toPng(el, canvasOptions)
        .then((canvas: string) => {
          if (!canvas) {
            return reject('No html element converted to canvas')
          }
          resolve(canvas)
        })
        .catch(reject)
    })
  }

  private getLogoSectionDimensions() {
    // Keep aspect ratio of the image 4.64
    const logoWidth = 121 // No margins
    const logoHeight = 26 // No margins

    const logoMarginLeft = 16 // from styles. To be left aligned with the titles of the charts
    const logoVerticalMargin = 16  // from styles

    const logoReactHeight = logoHeight + (logoVerticalMargin * 2) // Double for margin top and bottom

    return {
      logoWidth, // image width
      logoHeight, // image height
      logoMarginLeft,
      logoVerticalMargin,
      logoReactHeight,  // logo header height
    }
  }

  private addLogo(pdf: jsPDF) {
    const logoImg = document.createElement('img')
    logoImg.src = `${window.location.origin}/assets/TW_Logo-B-04.png`

    const logoSizes = this.getLogoSectionDimensions()

    // Create a rect with size:
    // width: full (same as pdf page)
    // height: logo.height + margin top and bottom
    pdf.setFillColor(ExportMainDashboardService.backgroundColor)
    pdf.rect(0, 0, pdf.internal.pageSize.width, logoSizes.logoReactHeight, 'F')

    pdf.addImage(logoImg, 'PNG',
      logoSizes.logoMarginLeft, // x position on the page
      logoSizes.logoVerticalMargin, // y position on the page
      logoSizes.logoWidth, // logo width
      logoSizes.logoHeight, // logo height
      '',
      'FAST'
    )
  }

  private addDate(pdf: jsPDF) {
    const todayStr = this.getHeaderDate()
    const logoSizes = this.getLogoSectionDimensions()

    const xPos = pdf.internal.pageSize.width - todayStr.length - logoSizes.logoMarginLeft
    const yPos = logoSizes.logoReactHeight / 2 // half of the logo height for align

    pdf.setTextColor(ExportMainDashboardService.logoColor) // Same as logo color
    pdf.setFontSize(10)
    pdf.text(todayStr, xPos, yPos, { align: 'right' })
  }

  private getA4FileDimensions(): Dimensions {
    const pdfA4Size = new jsPDF('portrait', 'pt', 'a4')
    return {
      width: pdfA4Size.internal.pageSize.getWidth(),
      height: pdfA4Size.internal.pageSize.getHeight(),
    }
  }

  /**
   * Return the image dimensions with the correct aspect ratio
   * for the pdf file width. The image will have the pdf file width.
   *
   * @param elementSelector CSS selector of the element to export
   */
  private getImageDimensions(elementSelector: string): Dimensions {
    const a4FileSize = this.getA4FileDimensions()

    const imageWidth = a4FileSize.width
    const originalEl = document.querySelector(elementSelector)
    const ratio = originalEl.clientHeight / originalEl.clientWidth
    const imageHeight = ratio * imageWidth

    return {
      width: imageWidth,
      height: imageHeight
    }
  }

  private createPDFFile(elementSelector: string): jsPDF {
    const logoSizes = this.getLogoSectionDimensions()
    const a4FileSize = this.getA4FileDimensions()
    const imageDimensions = this.getImageDimensions(elementSelector)

    // pdf height will be :
    // logo height +
    // image height +
    const estimatedHeight = imageDimensions.height + logoSizes.logoReactHeight

    // If the logo+image height is larger than a4 size, then set the filePage height to logo+image
    // This is required because the exported element can have very long height and don't fit in one standard a4 page
    const pdfHeight = estimatedHeight < a4FileSize.height ? a4FileSize.height : estimatedHeight

    const compress = true
    const pdf = new jsPDF('portrait', 'pt', [a4FileSize.width, pdfHeight], compress)

    // Fill all the page with the dashboard background color
    pdf.setFillColor(ExportMainDashboardService.backgroundColor)
    pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), 'F')

    return pdf
  }

  async exportToPDF(elementSelector: string) {
    try {
      this.exporting$.next(true)

      // Generate HTML to Canvas from dashboard
      const canvasImage = await this.generateCanvasFromHTML(elementSelector)

      const logoSizes = this.getLogoSectionDimensions()
      const pdf = this.createPDFFile(elementSelector)

      // Image dimension with aspect ratio
      const imageDimension = this.getImageDimensions(elementSelector)

      // Add TalentWall logo
      this.addLogo(pdf)
      this.addDate(pdf)

      // Add offersAndHires canvas element
      pdf.addImage(
        canvasImage,
        'PNG',
        0, // x position on the page
        logoSizes.logoReactHeight, // y position: below the logo rect
        imageDimension.width,
        imageDimension.height,
        '', // alias
        'FAST'
      )

      // Download the pdf in the browser
      pdf.save(this.generateFileName(elementSelector.replace(/[#\.]/, '')))
    } finally {
      this.exporting$.next(false)
    }
  }
}
