import { Department } from '../models/department'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { sortByName } from './sort-comparers'

export type State = EntityState<Department>

export const adapter: EntityAdapter<Department> = createEntityAdapter<
Department
>({
  sortComparer: sortByName,
})

export const initialState: State = adapter.getInitialState()

export function reducer(state = initialState, action: LoaderActions): State {
  switch (action.type) {
    case LoaderActionTypes.LoadSharedDataSuccess:
      return adapter.setAll(action.payload.departments, state)

    default:
      return state
  }
}
