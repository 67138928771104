<div class="rating-number"
     [ngClass]="{
     'rating-filter': variant ==='filter',
     'rating-card': variant ==='card',
     'small-card': variant ==='small-card'
     }">
   <span
     class="fa-stack"
     [class.selected]="selected">
    <i class="fas fa-square fa-stack-2x" [style.display]="variant === 'filter'? 'block' : 'none'"></i>
    <strong
      class=" fa-stack-1x"
      [ngbTooltip]=tooltipText>
     {{ratingNumber}}
     </strong>
  </span>
</div>
