import { InfiniteScrollModule } from 'ngx-infinite-scroll'

import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import {
  NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbModule,
  NgbNavModule, NgbPopoverModule, NgbTooltipModule, NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap'
import { RouterModule } from '@angular/router'
import { StoreModule } from '@ngrx/store'

import { ActivityLevelComponent } from './components/activity-level/activity-level.component'
import { ApiEffects } from './effects/api.effects'
import {
  CandidateConversionPopoverComponent
} from './components/dropoff-graph/candidate-conversion-popover/candidate-conversion-popover.component'
import { CollapsableSaveIconComponent } from './components/collapsable-save-icon/collapsable-save-icon.component';
import {
  CreditedToFilterComponent
} from './components/filters/credited-to-filter/credited-to-filter.component'
import { CustomNgxChartsModule } from './components/custom-ngx-charts/custom-ngx-charts.module'
import { DateFilterComponent } from './components/filters/date-filter/date-filter.component'
import { DebugModule } from '../debug/debug.module'
import {
  DepartmentFilterComponent
} from './components/filters/department-filter/department-filter.component'
import { DimensionMonitorDirective } from './directives/dimension-monitor.directive';
import { DraggableDirective } from '../dashboard/directives/drag-and-drop/draggable.directive'
import { DropoffGraphComponent } from './components/dropoff-graph/dropoff-graph.component'
import { DroppableDirective } from '../dashboard/directives/drag-and-drop/droppable.directive'
import { EditableInputComponent } from './components/editable-input/editable-input.component'
import {
  EmploymentTypeFilterComponent
} from './components/filters/employment-type-filter/employment-type-filter'
import {
  EmptyDropoffGraphComponent
} from './components/dropoff-graph/empty-dropoff-graph/empty-dropoff-graph.component'
import { EmptyPipe } from './pipes/empty.pipe'
import { FiltersLabelComponent } from './components/filters-label/filters-label.component'
import {
  GenericFilterComponent
} from './components/filters/generic-filter/generic-filter.component'
import { HighLightHtmlPipe } from './pipes/highlight-html.pipe'
import { HumanizePipe } from './pipes/humanize.pipe'
import { IframeComponent } from './components/iframe/iframe.component';
import {
  ImportedCustomFieldsTooltipComponent
} from './components/imported-custom-fields-tooltip/imported-custom-fields-tooltip.component'
import {
  InputFilterComponent
} from './components/filters/generic-filter/input-filter/input-filter.component'
import { JobFilterComponent } from './components/filters/job-filter/job-filter.component'
import { JobInfoHeaderComponent } from './components/job-info-header/job-info-header.component'
import {
  JobPrioritiesFilterComponent
} from './components/filters/job-priorities-filter/job-priorities-filter'
import {
  JobStagesFilterComponent
} from './components/filters/job-stages-filter/job-stages-filter.component'
import { JobStatusAndNotesHistoryDirective } from './components/job-status-and-notes-history.directive'
import {
  JobStatusAndNotesHistoryModalComponent
} from './components/job-status-and-notes-history-modal/job-status-and-notes-history-modal.component'
import { JobStatusColorDirective } from './components/update-job-status/job-status-color'
import { MentionDirective } from './directives/mention.directive'
import { MentionListComponent } from './components/mention-list.component'
import { ModalBodyComponent } from './components/modal/modal-body/modal-body.component'
import { ModalComponent } from './components/modal/modal.component'
import { ModalFooterComponent } from './components/modal/modal-footer/modal-footer.component'
import { ModalHeaderComponent } from './components/modal/modal-header/modal-header.component'
import { ModalSubTitleComponent } from './components/modal/modal-subtitle/modal-sub-title.component'
import { ModalTitleComponent } from './components/modal/modal-title/modal-title.component'
import { NameFilterComponent } from './components/name-filter.component'
import { NotesHistoryComponent } from './components/job-status-and-notes-history/notes-history.component'
import { OfficeFilterComponent } from './components/filters/office-filter/office-filter.component'
import {
  PdfSpinnerOverlayComponent
} from './components/pdf-export-overlay/spinner-overlay.component'
import { PortalComponent } from './components/portal/portal.component';
import { PortalTrackerComponent } from './components/portal-tracker/portal-tracker.component';
import { PortalTrackerTemplateDirective } from './components/portal-tracker-template.directive';
import { PresentPipe } from './pipes/present.pipe'
import { PrivateCandidateIconComponent } from './components/private-candidate-icon.component'
import { ProjectedHiresNumberComponent } from './components/projected-hires-number/projected-hires-number.component'
import {
  RatingNumberComponent
} from './components/filters/rating-filter/rating-number/rating-number.component'
import {
  RecruiterFilterComponent
} from './components/filters/recruiter-filter/recruiter-filter.component'
import { ReorderableInputComponent } from './components/reorderable-input/reorderable-input.component'
import { SelectComponent } from './components/select/select.component';
import { SendToSlackComponent } from './components/send-to-slack.component'
import { SetClientHeightDirective } from './directives/set-client-height.directive'
import { SetHeightToParentDirective } from './directives/set-height-to-parent.directive'
import {
  SetJobApplicationRatingDirective
} from './components/filters/rating-filter/set-job-application-rating.directive'
import {
  SetRatingFilterDirective
} from './components/filters/rating-filter/set-rating-filter.directive'
import { SiteNotificationComponent } from './components/site-notification/site-notification.component'
import {
  SlackDestinationSelectorComponent
} from './components/slack-destination-selector/slack-destination-selector.component';
import { SortTabComponent } from './components/sort-tabs/sort-tab.component'
import { SortTabsComponent } from './components/sort-tabs/sort-tabs.component'
import { SourceTypeIconComponent } from './components/source-type-icon.component'
import { SpinnerComponent } from './components/spinner/spinner.component'
import { StickerDirective } from './directives/sticker.directive';
import { TabShareComponent } from './components/tab-share.component'
import { TableSortDirective } from './directives/table-sort.directive'
import { TableSortHandlerDirective } from './directives/table-sort-handler.directive'
import { TabsComponent } from './components/tabs/tabs.component';
import { TagIconsComponent } from './components/tag-icons.component'
import { TimeAgoPipe } from './pipes/time-ago.pipe'
import { TrackFeatureEffects } from './effects/track-feature-effects.service'
import { TrackFeatureHighlightDirective } from './directives/track-feature-highlight.directive'
import { TypeaheadInputComponent } from './components/typeahead-input/typeahead-input.component'
import {
  UpdateJobStatusComponent
} from './components/update-job-status/update-job-status.component'
import { UserFilterComponent } from './components/filters/user-filter/user-filter.component'
import { VideoModalComponent } from './components/video-modal/video-modal.component'
import { reducers } from './state';


// See https://angular.io/guide/sharing-ngmodules

const modalComponents = [
  ModalComponent,
  ModalHeaderComponent,
  ModalTitleComponent,
  ModalSubTitleComponent,
  ModalBodyComponent,
  ModalFooterComponent,
] as const

const FILTERS_CMP = [
  GenericFilterComponent,
  InputFilterComponent,
  UserFilterComponent,
  OfficeFilterComponent,
  DepartmentFilterComponent,
  JobStagesFilterComponent,
  DateFilterComponent,
  JobPrioritiesFilterComponent,
  EmploymentTypeFilterComponent,
  RatingNumberComponent,
  SetRatingFilterDirective,
  SetJobApplicationRatingDirective,
  RecruiterFilterComponent,
  CreditedToFilterComponent,
  TypeaheadInputComponent,
  JobFilterComponent,
]

const declarationsExports = [
  ActivityLevelComponent,
  CollapsableSaveIconComponent,
  DimensionMonitorDirective,
  DraggableDirective,
  DropoffGraphComponent,
  DroppableDirective,
  EditableInputComponent,
  EmptyDropoffGraphComponent,
  EmptyPipe,
  FiltersLabelComponent,
  HighLightHtmlPipe,
  HumanizePipe,
  ImportedCustomFieldsTooltipComponent,
  JobInfoHeaderComponent,
  JobStatusAndNotesHistoryDirective,
  JobStatusColorDirective,
  MentionDirective,
  MentionListComponent,
  NameFilterComponent,
  NotesHistoryComponent,
  PdfSpinnerOverlayComponent,
  PortalComponent,
  PortalTrackerComponent,
  PortalTrackerTemplateDirective,
  PresentPipe,
  PrivateCandidateIconComponent,
  ProjectedHiresNumberComponent,
  ReorderableInputComponent,
  SelectComponent,
  SetClientHeightDirective,
  SetHeightToParentDirective,
  SiteNotificationComponent,
  SlackDestinationSelectorComponent,
  SortTabComponent,
  SortTabsComponent,
  SourceTypeIconComponent,
  SpinnerComponent,
  StickerDirective,
  TableSortDirective,
  TableSortHandlerDirective,
  TabsComponent,
  TagIconsComponent,
  TimeAgoPipe,
  TrackFeatureHighlightDirective,
  UpdateJobStatusComponent,
  VideoModalComponent,
  ...FILTERS_CMP,
  ...modalComponents
]

const modules = [
  CommonModule,
  DebugModule,
  FormsModule,
  InfiniteScrollModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbModule,
  NgbNavModule,
  NgbPopoverModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
  ReactiveFormsModule,
  RouterModule
]

@NgModule({
  imports: [
    ...modules,
    StoreModule.forFeature('shared', reducers),
    EffectsModule.forFeature([ApiEffects, TrackFeatureEffects])
  ],
  declarations: [
    TabShareComponent,
    SendToSlackComponent,
    JobStatusAndNotesHistoryModalComponent,
    CandidateConversionPopoverComponent,
    IframeComponent,
    ...declarationsExports
  ],
  exports: [
    CustomNgxChartsModule,
    ...modules,
    ...declarationsExports,
  ]
})
export class SharedModule { }
