import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { TagMapping } from '../models/tag-mapping'

import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'


export type State = EntityState<TagMapping>

export const adapter: EntityAdapter<TagMapping> = createEntityAdapter<
TagMapping
>({
  sortComparer(a: TagMapping, b: TagMapping): number {
    // sort by ID ascending
    return b.id - a.id
  },
})

export const initialState: State = adapter.getInitialState()

export function reducer(state = initialState, action: LoaderActions): State {
  switch (action.type) {
    case LoaderActionTypes.WallSharedSuccess: {
      return adapter.upsertMany(action.payload.tag_mappings || [], state)
    }

    default: {
      return state
    }
  }
}
