<div class="tw-px-6 tw-pt-2">
  <ul ngbNav class="nav-tabs tw-mb-4 tw-pl-10" #nav="ngbNav">
    <li *ngFor="let tab of data"
      [ngbNavItem]="tab.url"
      [disabled]="tab.requiredFeatureFlag && !(appConfig.isFeatureFlagEnabled$(tab.requiredFeatureFlag) | async)">
      <a ngbNavLink class="tw-text-inherit" [routerLink]="tab.url">{{ tab.text }}</a>
    </li>
  </ul>
  
  <router-outlet></router-outlet>
</div>