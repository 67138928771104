import { Actions, ofType } from '@ngrx/effects'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component,
  Input, OnDestroy, OnInit, ViewChild
} from '@angular/core'
import { ExecutiveDashboardActionsTypes, UpdatePerJobConfig,
  UpdatePerJobConfigFailure, UpdatePerJobConfigSuccess } from '../../../wall/actions/executive-dashboard.actions'
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { first } from 'rxjs/operators'
import { selectPerJobSpecificConfiguration } from '../../../wall/reducers'

@Component({
  selector: 'twng-configure-per-job-value',
  templateUrl: './configure-per-job-value.component.html',
  styleUrls: ['./configure-per-job-value.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurePerJobValueComponent implements OnInit, OnDestroy {
  @Input() formKeyName: string
  @Input() jobId: string

  // form is not created for each component because this component is shown a
  // lot in a single view. We create form only when necessary in order to
  // improve browser performance
  form: UntypedFormGroup
  demo = window.twng_demo

  @ViewChild(NgbDropdown)
    dropdown: NgbDropdown

  private sub = new Subscription()
  // in case we get value before form is initialized, we save it here
  private cachedValue: number

  constructor(
    private store: Store,
    private change: ChangeDetectorRef,
    private actions$: Actions,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.sub.add(this.store.select(
      selectPerJobSpecificConfiguration, { jobId: this.jobId }
    ).subscribe(jobConfig => {
      if (jobConfig) {
        if (this.form) {
          this.form.setValue({ [this.formKeyName]: jobConfig[this.formKeyName] })
          this.change.markForCheck()
        } else {
          this.cachedValue = jobConfig[this.formKeyName]
        }
      }
    }))
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  private createForm() {
    this.form = new UntypedFormGroup({
      [this.formKeyName]: new UntypedFormControl(this.cachedValue)
    })
    this.change.markForCheck()
  }

  dropdownOpenChange(isOpened: boolean) {
    if (isOpened && !this.form) {
      this.createForm()
    }
  }

  submit() {
    this.form.disable()
    this.sub.add(
      this.actions$.pipe(ofType(
        ExecutiveDashboardActionsTypes.UpdatePerJobConfigFailure,
        ExecutiveDashboardActionsTypes.UpdatePerJobConfigSuccess
      ), first()).subscribe((action: UpdatePerJobConfigSuccess | UpdatePerJobConfigFailure) => {
        this.form.enable()
        if (action.type === ExecutiveDashboardActionsTypes.UpdatePerJobConfigSuccess) {
          this.closeDropdown()
        } else {
          this.toastr.error('Error updating configuration per job')
        }
      })
    )
    this.store.dispatch(new UpdatePerJobConfig({ [this.jobId]: this.form.value }))
  }

  private closeDropdown() {
    this.dropdown.close()
  }
}
