
import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { SlackService } from '../services/slack.service'

@Component({
  selector: 'twng-send-to-slack',
  styles: [],
  templateUrl: 'send-to-slack.component.html',
})

export class SendToSlackComponent implements OnInit {
  constructor(
    private activeModal: NgbActiveModal,
    private slack: SlackService,
  ) {
  }

  textToSend: string
  allowTextEntering = true
  receiverId: string

  // retuns string if there is error, otherwise null
  onSendClicked: (receiver: string, text: string) => Promise<void>
  lastError: string
  isSending: boolean

  async ngOnInit() {
    if (!this.onSendClicked) {
      throw new Error("To use send-to-slack modal please set onSendClicked to an arrow function that handles send")
    }
  }

  async tabAction() {
    this.isSending = true
    delete this.lastError
    try {
      await this.onSendClicked(this.receiverId, this.textToSend)
      this.activeModal.close()
    } catch(err) {
      this.lastError = err.message || "An error has occured"
      this.isSending = false
    }
  }
}
