import { ChartFilters } from '../../../../models/chart-filters'
import { CommonSingleChartHandler } from '../common-single-chart-handler'
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'
import { SegmentService } from '../../../../../core/services/segment.service'
import { SingleChartFilterComponent } from '../single-chart-filter.component'
import { Store } from '@ngrx/store'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { shortDateString } from '../../../../../shared/utils/general-utils'

export const dateFilterForm = {
  dateFilterMode: new UntypedFormControl('dateFilterMode'),
  startDate: new UntypedFormControl('startDate'),
  endDate: new UntypedFormControl('endDate'),
};

@Component({
  selector: 'twng-date-chart-filter',
  templateUrl: './date-chart-filter.component.html',
  styleUrls: ['./date-chart-filter.component.scss',
    '../single-chart-filter-template/single-chart-filter-template.component.scss'
  ],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  providers: [{ provide: SingleChartFilterComponent, useExisting: DateChartFilterComponent }]
})
export class DateChartFilterComponent extends CommonSingleChartHandler implements OnChanges {
  id = 'DateChartFilterComponent';

  dataLoaded: boolean

  @Input() dateFilterForm: UntypedFormGroup

  constructor(
    segmentService: SegmentService,
    store: Store,
    private ngbDateParserFormatter: NgbDateParserFormatter,
  ) {
    super(segmentService, store)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && this.filters) {
      this.dataLoaded = true
      this.dateFilterForm.setValue({
        dateFilterMode: this.filters.date_mode,
        startDate: this.ngbDateParserFormatter.parse(this.filters.start_date),
        endDate: this.ngbDateParserFormatter.parse(this.filters.end_date)
      })
    }
  }

  getChangedValueForStoring(): Partial<ChartFilters> {
    const formValue = this.dateFilterForm.value
    return {
      date_mode: formValue.dateFilterMode,
      start_date: this.ngbDateParserFormatter.format(formValue.startDate),
      end_date: this.ngbDateParserFormatter.format(formValue.endDate),
    }
  }

  protected getTooltipIfEditable() {
    switch (this.filters.date_mode) {
      case 'custom':
        if (!this.filters.start_date || !this.filters.end_date) {
          return ['Date']
        } else {
          return [`${shortDateString(this.filters.start_date)} to ${shortDateString(this.filters.end_date)}`]
        }
      case 'ytd':
        return ['YTD']
      case 'qtd':
        return ['QTD']
      case 'mtd':
        return ['MTD']
      case 'wtd':
        return ['WTD']
      case 'last7days':
        return ['Last 7 Days']
      case 'last3months':
        return ['Last 3 Months']
      case 'last6months':
        return ['Last 6 Months']
      case 'last12months':
        return ['Last 12 Months']
      case 'lastQuarter':
        return ['Last Quarter']
      case 'lastBusinessWeek':
        return ['Last Business Week']
      case 'lastYear':
        return ['Last Year']
      case 'today':
        return ['Today']
      case 'allTime':
        return ['All Time']
    }
  }
}
