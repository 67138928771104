import { ToastrService } from 'ngx-toastr'
import { catchError, concatMap, map, retry, switchMap } from 'rxjs/operators'
import { of } from 'rxjs'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import {
  CandidatesInStageLoadPayload,
  CandidatesInStageTypes, FetchCandidatesInStage, FetchCandidatesInStageError,
  FetchCandidatesInStageSuccess, LoadInitialData, LoadInitialDataError, LoadInitialDataSuccess
} from '../actions/candidates-in-stage.actions'
import { apiHost, getHttpPostOptions, httpGetOptions } from '../../core/http-options'

@Injectable()
export class CandidatesInStageEffects {
  candidatesInStageUrl: string

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private toastr: ToastrService
  ) {

    this.candidatesInStageUrl = `${apiHost}/twng/candidates_in_stage.json`
  }

  getInitialData$ = createEffect(() => this.actions$.pipe(
    ofType<LoadInitialData>(CandidatesInStageTypes.LoadInitialData),
    concatMap(() => {
      if (this.demoMode) {
        return of(new LoadInitialDataSuccess(this.demoData))
      }

      return this.http.get<CandidatesInStageLoadPayload>(this.candidatesInStageUrl, httpGetOptions)
        .pipe(
          retry(1),
          map((data) => new LoadInitialDataSuccess(data)),
          catchError(data => {
            this.toastr.error('Failed to initialized Candidates in Stage')
            return of(new LoadInitialDataError(data))
          }),
        )
    })
  ))

  getCandidatesInStage$ = createEffect(() => this.actions$.pipe(
    ofType<FetchCandidatesInStage>(CandidatesInStageTypes.FetchCandidatesInStage),
    switchMap((action: FetchCandidatesInStage) => {
      if (this.demoMode) {
        return of(new FetchCandidatesInStageSuccess(this.demoData))
      }

      return this.http.post<CandidatesInStageLoadPayload>(
        this.candidatesInStageUrl,
        action.payload.filters,
        getHttpPostOptions()
      ).pipe(
        retry(1),
        map((data) => new FetchCandidatesInStageSuccess(data)),
        catchError(data => {
          this.toastr.error('Failed to get Candidates in Stage')
          return of(new FetchCandidatesInStageError(data))
        }),
      )
    })
  ))

  private demoMode = !!window.twng_demo
  private demoData: CandidatesInStageLoadPayload = {
    candidates: [{
      activity_level: "good",
      candidate_name: "Steve Holt",
      link: "https://app.greenhouse.io/people/30068770?application_id=39015909",
      job_name: "Job 1",
      job_stage_name: "Offer",
      last_activity_at: "2020-09-23T15:10:09-06:00",
      last_activity_days_ago: 2,
      next_interview_at: null,
      recruiter_id: "315873",
      coordinator_id: null,
      source_name: null,
      department_ids: [
        "24175"
      ],
      office_ids: [
        "17355"
      ]
    }, {
      activity_level: "good",
      candidate_name: "Ozzie Smith",
      link: "https://app.greenhouse.io/people/29723510?application_id=38653537",
      job_name: "Job 2",
      job_stage_name: "Offer",
      last_activity_at: "2020-09-15T10:55:45-06:00",
      last_activity_days_ago: 2,
      next_interview_at: null,
      recruiter_id: "356383",
      coordinator_id: null,
      source_name: null,
      department_ids: [
        "24178"
      ],
      office_ids: [
        "17304"
      ]
    }],
    filters: {
      department_ids: [],
      job_stage_names: ['Offer'],
      office_ids: [],
      user_ids: [],
    }
  }
}
