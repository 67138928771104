import { CacheService } from "../../wall/services/cache.service"
import { ChartFilters } from "../models/chart-filters"
import { ChartStatus } from "../models/dashboard-chart"
import { DataSources } from "../reducers/dashboard.reducer"
import { Directive, Input, OnDestroy, OnInit } from "@angular/core"
import { NgxChartsRecord } from "../reducers/analytics.reducer"
import { ShowGenericTableModal } from "../../core/actions/generic-table.actions"
import { Store } from "@ngrx/store"
import { Subscription, combineLatest } from "rxjs"
import { WidgetDataType } from "../../wall/models/offer"
import { findIdsInChartData } from "../../shared/utils/general-utils"
import {
  jobPerStatusColors,
  offerResponseColors,
  scorecardResultColors,
  timeInStageHiresColors
} from "./color-schemes"

interface SliceClickedEvent {
  label: string
  name: string
  value: number
  series?: string
  offer_ids?: number[]
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class ChartCommonComponent implements OnDestroy, OnInit {
  dataSourcesSub: Subscription
  dataSources: DataSources = {}

  @Input()
    customColors

  @Input()
    label: string

  @Input()
    chartFilters: ChartFilters

  @Input()
    filtersEnabled = true

  @Input()
    overrideFiltersSet: boolean

  constructor(
    protected store: Store,
    protected cacheService: CacheService,
  ) {
  }
  ngOnInit(): void {
    this.dataSourcesSub = combineLatest([
      this.cacheService.dataSources$, this.cacheService.activityColors$, this.cacheService.jobStatusColors$
    ]).subscribe(([dataSources, activityColors, jobStatusColors]) => {
      this.dataSources = dataSources

      if (dataSources[this.dataSource]?.color_scheme === 'activity') {
        this.customColors = activityColors
      }
      if (dataSources[this.dataSource]?.color_scheme === 'job_update_status') {
        this.customColors = jobStatusColors
      }
      if (dataSources[this.dataSource]?.color_scheme === 'scorecard_results') {
        this.customColors = scorecardResultColors
      }
      if (dataSources[this.dataSource]?.color_scheme === 'offer_responses') {
        this.customColors = offerResponseColors
      }
      if (dataSources[this.dataSource]?.color_scheme === 'job_per_status') {
        this.customColors = jobPerStatusColors
      }
      if (dataSources[this.dataSource]?.color_scheme === 'time_in_stage_hired_non_hired') {
        this.customColors = timeInStageHiresColors
      }
    })
  }
  @Input()
    candidateDataType: WidgetDataType

  private _status: ChartStatus
  @Input()
  set status(newStatus: ChartStatus) {
    this._status = newStatus
    this.onDataUpdated()
  }
  get status() {
    return this._status
  }

  protected onDataUpdated() {}

  get data() {
    return this.status?.data
  }

  @Input()
  set data(value: NgxChartsRecord[]) {
    this.status = {
      status: "done",
      data: value,
      queue_key: "INVALID",
      timestamp: new Date().toISOString(),
    }
  }

  @Input()
    dataSource: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sliceClicked(event: SliceClickedEvent | any) {
    if (this.candidateDataType && this.data) {
      const dataToSearch = event.series ?
        this.data.find(x => x.name === event.series).series :
        this.data
      const data = dataToSearch?.find(x => x.name === event.name)
      if (data) {
        const ids = findIdsInChartData(data)
        this.openModalWithIds(ids, data.name, event.series, data.modal_title_override)
      }
    }
  }

  protected openModalWithIds(ids: string[], clickedLabel: string, clickedSeries: string, titleOverride: string) {
    if (ids && ids.length > 0) {
      this.store.dispatch(new ShowGenericTableModal({
        chart: this.dataSource || this.candidateDataType,
        data_ids: ids,
        clickedGraphTitle: this.label,
        clickedLabel,
        clickedSeries,
        titleOverride,
      }))
    }
  }

  ngOnDestroy() {
    this.dataSourcesSub.unsubscribe()
  }
}
