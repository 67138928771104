import { Component, OnDestroy, OnInit } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { Router } from "@angular/router"

@Component({
  selector: "twng-edit-goals",
  templateUrl: "./edit-goals.component.html",
  styleUrls: [
    "./edit-goals.component.scss",
    "../../../shared/components/grid.scss",
  ],
})
export class EditGoalsComponent implements OnInit, OnDestroy {
  urlSafe: SafeResourceUrl

  constructor(
    public sanitizer: DomSanitizer,
    public router: Router
  ) { }

  ngOnInit() {
    const url = `//${window.sobrio_host_name}/accounts/${window.twng_account.id}/goals`
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    window.addEventListener('message', this.onMessageListener)
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.onMessageListener)
  }

  onMessageListener = (event) => {
    const { data, origin } = event

    if (origin.includes(window.sobrio_host_name)) {
      const parsedData = JSON.parse(data)
      if (parsedData.closeGoalsSettings) {
        this.goBack()
      }
    }
  }

  goBack() {
    this.router.navigate(['executive-tools/executive-dashboard'])
  }
}
