<div class="candidates-in-stage-tab">
  <div class="filter-wrapper" *ngIf="!(isLoadingInitialData$ | async); else spinner">
    <twng-candidates-in-stage-filters (filtersChanged)="filtersChanged($event)">
    </twng-candidates-in-stage-filters>
  </div>

  <div *ngIf="!(isFetchingCandidates$| async); else spinner">
    <div *ngIf="candidates$ | async as candidates" [id]="tableId">
      <div class="wrapper fs-unmask">
        <span class="count">{{ candidates.length }}</span>
        <span class="count-text">candidates</span>
        <span class="text">found</span>

        <span class="icon-divider data-html2canvas-ignore" data-html2canvas-ignore></span>
        <a class="section__header-export-pdf-icon actions-button actions-button--dark-bg" data-html2canvas-ignore
          ngbTooltip="Download as PDF" (click)="exportToPdf()">
          <i class="fas fa-download data-html2canvas-ignore"></i>
        </a>
      </div>

      <div class="table-wrapper">
        <table class="table table-striped">
          <thead class="fs-unmask" appSticker twngTableSortHandler [tableElements]="candidates">
            <tr>
              <th scope="col" twngTableSort sortByAttribute="candidate_name">Candidate Name</th>
              <th scope="col" twngTableSort sortByAttribute="job_stage_name">Stage</th>
              <th scope="col" twngTableSort sortByAttribute="role">Role</th>
              <th scope="col" twngTableSort sortByAttribute="last_activity_at" [comparator]="datesComparator">Last
                Activity</th>
              <th scope="col" twngTableSort sortByAttribute="firstDepartment">Department</th>
              <th scope="col" twngTableSort sortByAttribute="firstOffice">Location</th>
              <th scope="col" twngTableSort sortByAttribute="next_interview_at" [comparator]="datesComparator">Next
                Interview</th>
              <th scope="col" twngTableSort sortByAttribute="source_name">Source</th>
              <th scope="col">Link</th>
              <th scope="col" twngTableSort sortByAttribute="recruiter_name">Recruiter</th>
              <th scope="col" twngTableSort sortByAttribute="coordinatorName">Coordinator</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let candidate of candidates; trackBy: trackByCandidate" class="table-row">
              <td scope="row fs-exclude">{{ candidate.candidate_name }}</td>
              <td>{{ candidate.job_stage_name }}</td>
              <td>{{ candidate.job_name }}</td>
              <td>
                <twng-activity-level
                  [activityLevel]="candidate.activity_level"
                  [activityDayCategories]="activityDayCategories$ | async"
                  [lastActivityDaysAgo]="candidate.last_activity_days_ago"
                  [lastActivityAt]="candidate.last_activity_at">
                </twng-activity-level>
              </td>
              <td>
                <span *ngFor="let department of candidate.departments">
                  {{ department.name }}
                </span>
              </td>
              <td>
                <span *ngFor="let office of candidate.offices">
                  {{ office.name }}
                </span>
              </td>

              <td>{{ candidate.next_interview_at | date:'shortDate' }}</td>
              <td>{{ candidate.source_name }}</td>
              <td>
                <a *ngIf="candidate.link" class="atsLink fs-exclude" target="_blank" rel="noopener noreferrer"
                  [href]="candidate.link" [ngbTooltip]="viewInTooltipText">
                  <img [src]="greenHouseImgLink" width="32" height="32" class="tw-rounded-md" />
                </a>
              </td>
              <td>{{ (candidate.recruiter && candidate.recruiter.name) || '' }}</td>
              <td>{{ (candidate.coordinator && candidate.coordinator.name) || '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- TODO: Create a spinner component -->
<ng-template #spinner>
  <div class="my-5">
    <i class="mx-auto text-center d-block fas fa-sync fa-3x fa-spin"></i>
  </div>
</ng-template>

<twng-spinner-overlay [exporting]="exporting$ | async"></twng-spinner-overlay>
