import { Component, OnInit } from '@angular/core'

// This is an empty view that we use when we render Twng for the menu only
@Component({
  selector: 'twng-page-not-found',
  template: ``
})
export class PageNotFoundComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
