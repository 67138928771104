import { ExternalUser } from '../../wall/models/external-user'

function removeDeletedMentionsOfUserFromText(
  mentionedUser: ExternalUser,
  currentBody: string,
  currentPosition: number,
): string {
  const replacer = (match, offset) => {
    if (offset <= currentPosition && currentPosition <= offset + match.length) {
      return ''
    } else {
      return match
    }
  }
  // Find out which character was deleted
  // Take a name and delete one character, ex: John Doe -> John De
  // Loop through all of the characters and search for the remaining
  // characters in the current text area body, ex:
  // ohn Doe, Jhn Doe, Jon Doe, etc
  // The name includes the @ symbol
  let newBody
  const mentionedName = `@${mentionedUser.name}`

  for (
    let characterIndex = 0;
    characterIndex < mentionedName.length;
    characterIndex++
  ) {
    const currentMentionedNameWithoutCharacterAtI =
      mentionedName.slice(0, characterIndex) +
      mentionedName.slice(characterIndex + 1)
    const regex = new RegExp(currentMentionedNameWithoutCharacterAtI, 'g')
    newBody = currentBody.replace(regex, replacer)
    if (newBody !== currentBody) {
      break
    }
  }
  return newBody
}

export default function removeDeletedMentionsFromText(
  textAreaBody: string,
  currentPosition: number,
  mentionedUsers: Set<ExternalUser>,
): { newBody: string; removedMentionedUsers: Array<ExternalUser> } {
  // Custom replacer to only replace the mention if is found
  // in the correct position in the text
  const deletedMentionedUsers = new Set<ExternalUser>()
  const mentionedUsersArray = Array.from(mentionedUsers)
  let currentBody = textAreaBody
  // Loop through the mentioned users to see if the mention got deleted
  // the loop stops as soon as one user has changed the text
  for (const mentionedUser of mentionedUsersArray) {
    currentBody = removeDeletedMentionsOfUserFromText(
      mentionedUser,
      currentBody,
      currentPosition,
    )
    // If the text has changed mark as changed and add the user to deleted list
    if (currentBody !== textAreaBody) {
      if (!currentBody.includes(`@${mentionedUser.name}`)) {
        deletedMentionedUsers.add(mentionedUser)
      }
      break
    }
  }

  return {
    newBody: currentBody,
    removedMentionedUsers: Array.from(deletedMentionedUsers),
  }
}
