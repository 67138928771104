import { ToastrService } from 'ngx-toastr'
import { catchError, concatMap, map, retry, take } from 'rxjs/operators'
import { of } from 'rxjs'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import {
  CreateCategoryFailed,
  CreateCategorySuccess,
  LoadStageMappings,
  LoadStageMappingsFailed,
  LoadStageMappingsSuccess,
  LoadStageMappingsSuccessPayload,
  RemoveCategory,
  RemoveCategoryFailed,
  RemoveCategorySuccess,
  RenameJobStageCategoryFailed,
  RenameJobStageCategoryName,
  RenameJobStageCategoryNameSuccess,
  StageMappingsActionsTypes,
  UpdateCategory,
  UpdateCategoryFailed,
  UpdateCategoryPositions,
  UpdateCategoryPositionsFailed,
  UpdateCategoryPositionsSuccess,
  UpdateCategorySuccess
} from '../actions/stage-mappings.actions'
import { JobStageCategory } from '../models/job-stage-category'
import { StageMappingsService } from '../services/stage-mappings.service'
import { StageMappingsState } from '../reducers/stage-mappings.reducer'
import { apiHost, getHttpPostOptions, httpGetOptions } from '../../core/http-options'


@Injectable()
export class StageMappingsEffects {

  categoriesUrl = `${apiHost}/twng/job_stage_categories`

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private store: Store<StageMappingsState>,
    private stageMappingsService: StageMappingsService,
    private toastr: ToastrService
  ) {
  }

  getInitialData$ = createEffect(() => this.actions$.pipe(
    ofType<LoadStageMappings>(StageMappingsActionsTypes.LoadStageMappings),
    concatMap(() => {
      const url = `${apiHost}/twng/job_stage_categories.json`
      return this.http.get<LoadStageMappingsSuccessPayload>(url, httpGetOptions)
        .pipe(
          retry(1),
          map((response: LoadStageMappingsSuccessPayload) => new LoadStageMappingsSuccess(response)),
          catchError(data => {
            this.toastr.error('Failed to get phases')
            return of(new LoadStageMappingsFailed(data))
          }),
        )
    }),
  ))

  createJobStageCategory$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCategory>(StageMappingsActionsTypes.CreateCategory),
    concatMap((action: UpdateCategory) => (
      this.http.post<JobStageCategory>(
        this.categoriesUrl,
        { name: action.payload },
        getHttpPostOptions()
      )
        .pipe(
          map(category => new CreateCategorySuccess(category)),
          retry(1),
          catchError(data => {
            this.toastr.error('Failed to create the new phase')
            return of(new CreateCategoryFailed(data))
          }),
        )
    ))
  ))

  updateJobCategory$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCategory>(StageMappingsActionsTypes.UpdateCategory),
    concatMap((action: UpdateCategory) => (
      this.stageMappingsService.getJobsCategories([
        action.payload.jobStageCategoryIdFrom,
        action.payload.jobStageCategoryIdTo
      ]).pipe(
        take(1),
        concatMap((categories: JobStageCategory[]) => this.http.patch<JobStageCategory>(
          this.categoriesUrl,
          { job_stage_categories: categories },
          getHttpPostOptions()
        )
          .pipe(
            map(() => new UpdateCategorySuccess()
            ),
            retry(1),
            catchError(() => {
              this.toastr.error('Failed to update the phase')
              return of(new UpdateCategoryFailed(action.payload))
            }),
          )
        ),
      )
    )
    )
  ))

  updateJobCategoryPositions$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCategoryPositions>(StageMappingsActionsTypes.UpdateCategoryPositions),
    concatMap((action: UpdateCategoryPositions) => this.http.patch<UpdateCategoryPositions>(
      this.categoriesUrl,
      { job_stage_categories: action.payload.newJobStages },
      getHttpPostOptions()
    )
      .pipe(
        map(() => new UpdateCategoryPositionsSuccess(action.payload)),
        retry(1),
        catchError(() => {
          this.toastr.error('Failed to update the phase')
          return of(new UpdateCategoryPositionsFailed())
        }),
      )
    ),
  ))

  removeCategory$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveCategory>(StageMappingsActionsTypes.RemoveCategory),
    concatMap((action: RemoveCategory) => (
      this.http.delete<void>(
        `${this.categoriesUrl}/${action.payload.jobStageCategory.id}`,
        getHttpPostOptions()
      )
        .pipe(
          map(() => new RemoveCategorySuccess()),
          retry(1),
          catchError(() => {
            this.toastr.error('Failed to remove the phase')
            return of(new RemoveCategoryFailed(action.payload))
          }),
        )
    ),
    )
  ))

  renameCategory$ = createEffect(() => this.actions$.pipe(
    ofType<RenameJobStageCategoryName>(StageMappingsActionsTypes.RenameJobStageNameCategory),
    concatMap((action: RenameJobStageCategoryName) => (
      this.http.put<void>(
        `${this.categoriesUrl}/${action.payload.jobStageCategoryId}`,
        {
          id: action.payload.jobStageCategoryId,
          name: action.payload.jobStageCategoryName
        },
        getHttpPostOptions()
      )
        .pipe(
          map(() => new RenameJobStageCategoryNameSuccess()),
          retry(1),
          catchError(() => {
            this.toastr.error('Failed to rename the phase')
            return of(new RenameJobStageCategoryFailed(action.payload))
          }),
        )
    ),
    )
  ))
}
