import { Attachment } from './attachment'
import { SocialMediaLink } from './social-media-link'
import { atsId } from './types'

export interface ContactMethod {
  value: string
  type: string
}

export class Candidate {
  id: atsId
  name: string
  first_name: string
  last_name: string
  current_title: string
  current_company: string
  tags: string[]
  is_private: boolean
  email_addresses: string[]
  recruiter_id: atsId
  coordinator_id: atsId
  attachments: Attachment[]
  social_media_links: SocialMediaLink[]
  phone_numbers: ContactMethod[]
}

export const TYPE_RESUME = 'resume'
