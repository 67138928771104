import { Candidate } from './candidate'
import { atsId } from './types'

export const STATUS_ACCEPTED = 'accepted'
export const STATUS_UNRESOLVED = 'unresolved'
export const STATUS_REJECTED = 'rejected'
export const STATUS_DEPRECATED = 'deprecated'

export class Offer {
  id: atsId
  status: string
  job_application_id: atsId
  job_id: atsId
  candidate_id: atsId
  sent_at: string
  resolved_at: string
}

export class OfferWithCandidate extends Offer {
  candidate: Candidate
}

// TODO: move the following to another file
export enum WidgetDataType {
  OFFER = 'offer',
  HIRED = 'hired',
  STARTS = 'starts',

  OFFER_OUTSTANDING = 'offerOutstanding',
  OFFER_DECLINED = 'declined',
  CANDIDATE_WITH_OFFER = 'candidates-with-offer',
  CANDIDATE = 'candidates',
  INTERVIEWED = 'interviewed',
  INTERVIEW = 'interview',
  JOBS = 'jobs',
  JOB_OPENING = 'job-opening',
  SOURCING = 'sourcing',
  SCORECARD = 'scorecard',
  CUSTOM = 'custom'
}

export const widgetDataTypeName: Record<WidgetDataType, string> = {
  [WidgetDataType.OFFER]: "Offer",
  [WidgetDataType.HIRED]: "Hire",
  [WidgetDataType.STARTS]: "Start",
  [WidgetDataType.OFFER_OUTSTANDING]: "Outstanding offer",
  [WidgetDataType.OFFER_DECLINED]: "Declined offer",
  [WidgetDataType.CANDIDATE]: "Candidate",
  [WidgetDataType.CANDIDATE_WITH_OFFER]: "Candidate",
  [WidgetDataType.INTERVIEWED]: "Candidate",
  [WidgetDataType.INTERVIEW]: "Interview",
  [WidgetDataType.JOBS]: "Job",
  [WidgetDataType.JOB_OPENING]: "Job Opening",
  [WidgetDataType.SOURCING]: "Outreache", // Not a typo, needed for pluralization in #viewWidgetTypeTooltip
  [WidgetDataType.SCORECARD]: "Scorecard",
  [WidgetDataType.CUSTOM]: "Custom"
}

export function viewWidgetTypeTooltip(dataType: WidgetDataType) {
  return `View ${widgetDataTypeName[dataType]}s`
}
