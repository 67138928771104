import { NgModule } from "@angular/core"
import { NgxChartsComboComponent } from "./ngx-charts-combo/ngx-charts-combo.component"
import { NgxChartsComboSeriesVerticalComponent } from "./ngx-charts-combo/ngx-charts-combo-series-vertical.component"
import { NgxChartsModule } from "@swimlane/ngx-charts"

@NgModule({
  imports: [
    NgxChartsModule,
  ],
  declarations: [
    NgxChartsComboComponent,
    NgxChartsComboSeriesVerticalComponent,
  ],
  exports: [
    NgxChartsComboComponent,
  ],
})
export class CustomNgxChartsModule { }
