import * as fromWall from '../../../wall/reducers'
import { ChartComponent } from '../../models/chart-component'
import { ChartStatusCheckerService } from '../../../core/services/chart-status-checker.service'
import { Component, ContentChild, Input, Optional } from '@angular/core'
import { DashboardChart } from '../../models/dashboard-chart'
import { DashboardRemoveChart } from '../../actions/filters.actions'
import { Grid } from '../../models/gridster/grid'
import { GridsterComponent } from 'angular-gridster2'
import { GridsterItem } from 'angular-gridster2/lib/gridsterItem.interface'
import { Store } from '@ngrx/store'
import { TWGridsterItemComponentInterface } from '../../models/gridster/gridster-item'
import { customBoxDataType } from '../stats.component'
import { isNil, mapValues } from 'lodash-es'

@Component({
  selector: 'twng-gridster-item-wrapper',
  template: `
    <gridster-item [item]="gridsterItem" *ngIf="isInGridster"
      [style]="(dashboardChart?.is_custom_title && !isEditingGridster) ? 'background: transparent; box-shadow: none' : ''">
      <template [ngTemplateOutlet]="content" *ngIf="isDataReady"></template>
      <template [ngTemplateOutlet]="spinner" *ngIf="!isDataReady"></template>
    </gridster-item>
    <template [ngTemplateOutlet]="content" *ngIf="!isInGridster && isDataReady"></template>
    <template [ngTemplateOutlet]="spinner" *ngIf="!isInGridster && !isDataReady"></template>

    <ng-template #spinner>
      <div class="w-100 h-100 d-flex align-items-center justify-content-center flex-column">
        <div class="tw-mb-2">{{ dashboardChart?.name }}</div>
        <i class="fas fa-sync fa-3x fa-spin" *ngIf="dashboardChart?.data?.status !== 'error'"></i>
        <div class="tw-mt-2 tw-mb-2">{{ dashboardChart?.data?.status | titlecase }}</div>
        <div class="m-2 text-center text-danger">{{ dashboardChart?.data?.message }}</div>
        <div class="d-inline">
          <button
            class="btn btn-primary"
            (click)="refresh()"
            *ngIf="dashboardChart?.data?.status === 'error' && dashboardChart?.data?.queue_key"
          >
            Refresh
          </button>
          <button
            class="tw-ml-2 btn btn-danger"
            (click)="removeChart()"
            *ngIf="dashboardChart?.data?.status === 'error' && this.dashboardChart.id !== undefined"
          >
            Delete Chart
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #content>
      <ng-content *ngIf="isDataReady"></ng-content>
    </ng-template>
  `
})
export class GridsterItemWrapperComponent {
  static defaultItemConfiguration = {
    'free-text' : {
      defaultItemRows: 4,
      defaultItemCols: 24,
      minItemRows: 3,
      minItemCols: 3,
      maxItemRows: 7,
      maxItemCols: 24,
    },
    'number-box': {
      defaultItemRows: 4,
      defaultItemCols: 4,
      minItemRows: 3,
      minItemCols: 3,
      maxItemRows: 7,
      maxItemCols: (chart: DashboardChart) => chart.is_custom_title ? undefined : 7,
    },
    pie: {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 4,
      minItemCols: 5,
    },
    'bar-vertical': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 5,
      minItemCols: 5,
    },
    'bar-vertical-stacked': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 5,
      minItemCols: 5,
    },
    'bar-vertical-grouped': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 5,
      minItemCols: 5,
    },
    'bar-horizontal': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 5,
      minItemCols: 5,
    },
    'bar-horizontal-stacked': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 4,
      minItemCols: 5,
    },
    'bar-horizontal-stacked-normalized': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 4,
      minItemCols: 5,
    },
    'bar-horizontal-grouped': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 5,
      minItemCols: 5,
    },
    'bar-line-combo': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 4,
      minItemCols: 5,
    },
    line: {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 4,
      minItemCols: 5,
    },
    'radial-progressbar': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 4,
      minItemCols: 5,
    },
    grid: {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 4,
      minItemCols: 5,
    },
    area: {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 4,
      minItemCols: 5,
    },
    'area-stacked': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 4,
      minItemCols: 5,
    },
    'area-normalized': {
      defaultItemRows: 6,
      defaultItemCols: 7,
      minItemRows: 4,
      minItemCols: 5,
    },
    'table' : {
      defaultItemRows: 7,
      defaultItemCols: 24,
      minItemRows: 3,
      minItemCols: 3,
      maxItemRows: 7,
      maxItemCols: 24,
    },
  }

  gridsterItem: GridsterItem
  dashboardChart: DashboardChart

  /**
   * Original GridsterItem with the first position
   * assigned from the Grid itself. This is used
   * when the charts come from the backend without
   * any position and the grid has to give it a possible
   * position.
   */
  firstPossiblePosition: GridsterItem

  // If one of the parents to this component is GridsterComponent, it will be
  // filled. Otherwise it will be null. That is the way we know if we are in
  // gridster or not.
  constructor(
    @Optional() private gridsterComponent: GridsterComponent,
    private chartChecker: ChartStatusCheckerService,
    private store: Store<fromWall.State>
  ) {}

  get isInGridster() {
    return !!this.gridsterComponent && !!this.grid
  }

  @ContentChild('gridsterChart', { static: false }) chartCmp: ChartComponent

  @Input()
    grid: Grid

  @Input()
    isEditingGridster = false

  @Input()
  set chart(chart: DashboardChart) {
    this.dashboardChart = chart
    if (this.isInGridster && !this.gridsterItem) {
      this.gridsterItem = this.createGridsterItem(chart)
    }
  }

  get isDataReady() {
    return this.dashboardChart.data?.data ||
           this.dashboardChart.data_type === customBoxDataType ||
           this.dashboardChart.data?.status === "done"
  }

  /**
   * Sets the view prop on the Chart component
   *
   * @param view
   */
  setView(view: [number, number], originalContainer: [number, number]) {
    if (!this.chartCmp) {
      return
    }

    // StatsComponent doesn't have `setView` method because
    // it doesn't need it.
    this.chartCmp.setView?.(view, originalContainer)
  }

  dashboardChartHasPosition(): boolean {
    return !isNil(this.dashboardChart.position_x) && !isNil(this.dashboardChart.position_y)
  }

  /**
   * GridsterItem to send to the Grid.
   * When the dashboardChart comes from backend without any position,
   * the grid will assign one.
   * When the user cancels the edition of the grid and the dashboardChart still
   * doesn't have any position (the user never saved the element in the backend)
   * the original given position should be restore to not get a new one from the grid
   * with possible different positions.
   *
   * @param gridsterItem
   */
  assignPosition(gridsterItem: GridsterItem) {
    if (!this.dashboardChartHasPosition()) {
      // If there isn't a gridsterItem (first time the dashboardChart
      // is set in the component), get a position asking to the grid
      if (!this.gridsterItem) {
        // Ask the grid for the next available position for a new item
        // Save the position for next usages
        this.firstPossiblePosition = this.grid.options.api.getFirstPossiblePosition(gridsterItem)
        gridsterItem.x = this.firstPossiblePosition.x
        gridsterItem.y = this.firstPossiblePosition.y
      } else {
        // User cancels the edition and the dashboardChart doesn't
        // have a position so revert to original position
        // given by the grid
        // If there's already a gridsterItem created, then
        // assign the original position the grid set.
        gridsterItem.x = this.firstPossiblePosition.x
        gridsterItem.y = this.firstPossiblePosition.y
      }
    }
  }

  createGridsterItem(chart: DashboardChart): GridsterItem {
    const originalItemConfig = GridsterItemWrapperComponent.defaultItemConfiguration[chart.chart_type] || {}
    const defaultItemConfig = mapValues(originalItemConfig, val => {
      if (typeof(val) === 'string' || typeof(val) === 'number') {
        return val
      }
      if (val instanceof Function) {
        return val(chart)
      }
      throw new Error("Invalid value type " + val)
    })
    const gridsterItem = {
      id: chart.id,
      cols: chart.width || defaultItemConfig.defaultItemCols, // width
      rows: chart.height || defaultItemConfig.defaultItemRows, // height
      x: chart.position_x,
      y: chart.position_y,
      initCallback: (_, itemCmp: TWGridsterItemComponentInterface) => {
        itemCmp.parent = this
      },
      minItemCols: defaultItemConfig.minItemCols,
      minItemRows: defaultItemConfig.minItemRows,
      maxItemCols: defaultItemConfig.maxItemCols,
      maxItemRows: defaultItemConfig.maxItemRows,
    }
    this.assignPosition(gridsterItem)
    return gridsterItem
  }

  refresh() {
    this.chartChecker.refreshChartData(this.dashboardChart.data.queue_key)
  }

  removeChart() {
    this.store.dispatch(new DashboardRemoveChart({ dashboard_chart: this.dashboardChart }))
  }
}

