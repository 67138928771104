<header (click)="toggleReverse()">
  <div class="job-count tw-mr-2 fs-unmask">{{ jobAppsCountStr }}</div>
  <h2 [ngbTooltip]="jobStage.name" class="wall-job-stage-name tw-text-sm">{{ jobStage.name }}</h2>
  <div class="job-apps-total" [ngbTooltip]="jobStageTitle">
    <div class="job-apps-separator">&#8226;</div>
    <span>{{ conversionRate }}</span>
    <div *ngIf="rejectedCandidatesAtStageEnabled" data-html2canvas-ignore>
      <div class="job-apps-separator fs-unmask">&#8226;</div>
      <i class="fas fa-user-check job-stage-icon"></i>
    </div>
  </div>

  <div *ngIf="rejectedCandidatesAtStageEnabled" class="job-apps-total rejected fs-unmask"
    ngbTooltip="Rejected Candidates">
    {{ rejectedAtStageStr }}
    <i class="fas fa-user-times job-stage-icon"></i>
  </div>
</header>

<div *ngIf="!jobCollapsed" class="job-applications" appDroppable (dropping)="updateJobApplicationStage($event)">
  <twng-card-container *ngFor="let jobApp of jobApplications; trackBy: trackAppById" [jobApplication]="jobApp"
    [candidate]="jobApp.candidate" [jobStage]="jobStage" [activityDayCategories]="activityDayCategories"
    [interviewsAndJobEntities]="interviewsAndJobEntities"
    [sourceTypeIconMappings]="sourceTypeIconMappings"></twng-card-container>

  <button class="btn btn-light tw-mb-1 fs-unmask" *ngIf="jobApplicationsTotal > showQuantity" (click)="showMore()">
    <i class="fas fa-caret-down"></i>
    More
  </button>

  <button class="btn btn-light tw-mb-1 fs-unmask" *ngIf="showQuantity > INITIAL_SHOW_QUANTITY" (click)="showFewer()">
    <i class="fas fa-caret-up"></i>
    Fewer
  </button>
</div>