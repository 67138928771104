/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'
import { ClosedJobsInfo } from '../reducers/executive-dashboard.reducer'
import { ExecutiveDashboardConfiguration, ExecutiveDashboardResponse, ExecutiveDashboardTab,
  ExecutiveDashboardTabHideSection, PerJobConfiguration } from '../models/executive-dashboard'
import { JobFilters } from '../reducers/layout.reducer'
import { JobStatusUpdate } from '../models/job-status-update'
import { SortingOptionDirection, SortingOptionType } from '../models/sorting-options'
import { WidgetLibraryTabData } from '../../dashboard/reducers/analytics.reducer'

export enum ExecutiveDashboardActionsTypes {
  UpdateExecutiveDashboardConfig = '[Executive Dashboard] Update executive dashboard config',
  UpdateExecutiveDashboardConfigSuccess = '[Executive Dashboard] Update executive dashboard config success',
  UpdateExecutiveDashboardConfigFailure = '[Executive Dashboard] Update executive dashboard config failure',

  UpdateExecutiveDashboardTab = '[Executive Dashboard] Update executive dashboard tab',
  UpdateExecutiveDashboardTabSuccess = '[Executive Dashboard] Update executive dashboard tab success',
  UpdateExecutiveDashboardTabFailure = '[Executive Dashboard] Update executive dashboard tab failure',

  UpdatePerJobConfig = '[Executive Dashboard] Update per job config',
  UpdatePerJobConfigSuccess = '[Executive Dashboard] Update per job config success',
  UpdatePerJobConfigFailure = '[Executive Dashboard] Update per job config failure',

  DeleteExecutiveDashboardTab = '[Executive Dashboard] Delete tab',
  DeleteExecutiveDashboardTabSuccess = '[Executive Dashboard] Delete tab success',
  DeleteExecutiveDashboardTabFailure = '[Executive Dashboard] Delete tab failure',

  CreateExecutiveDashboardTab = '[Executive Dashboard] Create tab',
  CreateExecutiveDashboardTabSuccess = '[Executive Dashboard] Create tab success',
  CreateExecutiveDashboardTabFailure = '[Executive Dashboard] Create tab failure',

  ReorderExecutiveDashboardTabs = '[Executive Dashboard] Reorder tabs',
  ReorderExecutiveDashboardTabsSuccess = '[Executive Dashboard] Reorder tabs success',
  ReorderExecutiveDashboardTabsFailure = '[Executive Dashboard] Reorder tabs failure',

  FetchSharedExecutiveDashboardTab = '[Executive Dashboard] Fetch Shared executive dashboard tab',
  FetchSharedExecutiveDashboardTabSuccess = '[Executive Dashboard] Fetch Shared executive dashboard tab success',
  FetchSharedExecutiveDashboardTabFailure = '[Executive Dashboard] Fetch Shared executive dashboard tab failure',

  FetchExecutiveDashboard = '[Job] FetchExecutiveDashboard',
  FetchExecutiveDashboardSuccess = '[Job] FetchExecutiveDashboardSuccess',
  FetchExecutiveDashboardFailure = '[Job] FetchExecutiveDashboardFailure',

  FetchExecutiveDashboardCharts = '[Job] FetchExecutiveDashboardCharts',
  FetchExecutiveDashboardChartsSuccess = '[Job] FetchExecutiveDashboardChartsSuccess',
  FetchExecutiveDashboardChartsFailed = '[Job] FetchExecutiveDashboardChartsFailed',
  FetchExecutiveDashboardClosedJobs = '[Job] FetchExecutiveDashboardClosedJobs',
  FetchExecutiveDashboardClosedJobsSuccess = '[Job] FetchExecutiveDashboardClosedJobsSuccess',
  FetchExecutiveDashboardClosedJobsFailure = '[Job] FetchExecutiveDashboardClosedJobsFailure',

  SendJobStatusUpdate = '[Job] SendJobStatusUpdate',
  SendJobStatusUpdateSuccess = '[Job] SendJobStatusUpdateSuccess',
  SendJobStatusUpdateFailure = '[Job] SendJobStatusUpdateFailure',
  FetchJobStatuses = '[Job] FetchJobStatuses',
  FetchJobStatusesSuccess = '[Job] FetchJobStatusesSuccess',
  FetchJobStatusesFailure = '[Job] FetchJobStatusesFailure',

  FetchJobIdsForExecutiveDashboardTab = '[Job] FetchJobIdsForExecutiveDashboardTab',
  FetchJobIdsForExecutiveDashboardTabSuccess = '[Job] FetchJobIdsForExecutiveDashboardTabSuccess',
  FetchJobIdsForExecutiveDashboardTabFailure = '[Job] FetchJobIdsForExecutiveDashboardTabFailure',

  UpdateExecutiveDashboardTabHideSection = '[Executive Dashboard] Hide Section',
  UpdateExecutiveDashboardTabHideSectionSuccess = '[Executive Dashboard] Hide Section Success',
  UpdateExecutiveDashboardTabHideSectionFailure = '[Executive Dashboard] Hide Section Failure'
}

export class UpdateExecutiveDashboardConfig implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardConfig
  constructor(public payload: ExecutiveDashboardConfiguration) { }
}

export class UpdateExecutiveDashboardConfigSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardConfigSuccess
  constructor(public payload: ExecutiveDashboardConfiguration) { }
}

export class UpdateExecutiveDashboardConfigFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardConfigFailure
}

export class UpdateExecutiveDashboardTab implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTab
  constructor(public payload: ExecutiveDashboardTab) { }
}

export class UpdateExecutiveDashboardTabSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabSuccess
  constructor(public payload: ExecutiveDashboardTab) { }
}

export class UpdateExecutiveDashboardTabFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabFailure
}

export class FetchSharedExecutiveDashboardTab implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchSharedExecutiveDashboardTab
  constructor(public payload: { id: number, token: string }) { }
}

export class FetchSharedExecutiveDashboardTabSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchSharedExecutiveDashboardTabSuccess
  constructor(public payload: ExecutiveDashboardTab) { }
}

export class FetchSharedExecutiveDashboardTabFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchSharedExecutiveDashboardTabFailure
}

export class UpdatePerJobConfig implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdatePerJobConfig
  constructor(public payload: { [jobId: string]: PerJobConfiguration }) { }
}

export class UpdatePerJobConfigSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdatePerJobConfigSuccess
  constructor(public payload: { [jobId: string]: PerJobConfiguration }) { }
}

export class UpdatePerJobConfigFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdatePerJobConfigFailure
}

export class DeleteExecutiveDashboardTab implements Action {
  readonly type = ExecutiveDashboardActionsTypes.DeleteExecutiveDashboardTab
  constructor(public payload: ExecutiveDashboardTab) { }
}

export class DeleteExecutiveDashboardTabSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.DeleteExecutiveDashboardTabSuccess
  constructor(public payload: ExecutiveDashboardTab) { }
}

export class DeleteExecutiveDashboardTabFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.DeleteExecutiveDashboardTabFailure
  constructor(public payload: ExecutiveDashboardTab) { }
}

export class CreateExecutiveDashboardTab implements Action {
  readonly type = ExecutiveDashboardActionsTypes.CreateExecutiveDashboardTab
  constructor(public payload: ExecutiveDashboardTab) { }
}

export class CreateExecutiveDashboardTabSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.CreateExecutiveDashboardTabSuccess
  constructor(public payload: ExecutiveDashboardTab) { }
}

export class CreateExecutiveDashboardTabFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.CreateExecutiveDashboardTabFailure
  constructor() { }
}

export class ReorderExecutiveDashboardTabs implements Action {
  readonly type = ExecutiveDashboardActionsTypes.ReorderExecutiveDashboardTabs
  constructor(public payload: number[]) { }
}

export class ReorderExecutiveDashboardTabsSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.ReorderExecutiveDashboardTabsSuccess
  constructor(public payload: ExecutiveDashboardTab[]) { }
}

export class ReorderExecutiveDashboardTabsFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.ReorderExecutiveDashboardTabsFailure
  constructor() { }
}

export class FetchExecutiveDashboard implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchExecutiveDashboard
  constructor() { }
}

export interface ExecutiveDashboardPayload {
  executive_dashboard: ExecutiveDashboardResponse
}

export class FetchExecutiveDashboardSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchExecutiveDashboardSuccess
  constructor(public payload: ExecutiveDashboardPayload) { }
}

export class FetchExecutiveDashboardFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchExecutiveDashboardFailure
  constructor() { }
}

export class FetchExecutiveDashboardCharts implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchExecutiveDashboardCharts
  constructor(public payload: Partial<JobFilters>) { }
}

export interface ExecutiveDashChartPayload {
  analytics: {
    widget_library_tabs_data: {
      executive_dashboard: WidgetLibraryTabData
    }
  }
}

export class SendJobStatusUpdate implements Action {
  readonly type = ExecutiveDashboardActionsTypes.SendJobStatusUpdate
  constructor(public payload: { jobStatusUpdate: Partial<JobStatusUpdate> }) { }
}

export interface SendJobStatusUpdateSuccessPayload {
  job_status_update: JobStatusUpdate
}

export class SendJobStatusUpdateSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.SendJobStatusUpdateSuccess
  constructor(public payload: SendJobStatusUpdateSuccessPayload) { }
}

export class SendJobStatusUpdateFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.SendJobStatusUpdateFailure
  constructor() { }
}

export class FetchJobStatuses implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchJobStatuses
  constructor() { }
}

export interface FetchJobStatusesSuccessPayload {
  job_status_updates: JobStatusUpdate[]
}

export class FetchJobStatusesSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchJobStatusesSuccess
  constructor(public payload: FetchJobStatusesSuccessPayload) { }
}

export class FetchJobStatusesFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchJobStatusesFailure
  constructor() { }
}

export class FetchExecutiveDashboardChartsSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchExecutiveDashboardChartsSuccess
  constructor(public payload: ExecutiveDashChartPayload) { }
}

export class FetchExecutiveDashboardChartsFailed implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchExecutiveDashboardChartsFailed
  constructor() { }
}
export class FetchExecutiveDashboardClosedJobs implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchExecutiveDashboardClosedJobs
  constructor() { }
}

export class FetchExecutiveDashboardClosedJobsSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchExecutiveDashboardClosedJobsSuccess
  constructor(public payload: ClosedJobsInfo) { }
}

export class FetchExecutiveDashboardClosedJobsFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchExecutiveDashboardClosedJobsFailure
  constructor() { }
}

export interface FetchJobIdsForExecutiveDashboardTabRequest {
  tabId: number
  direction: SortingOptionDirection
  orderBy: SortingOptionType
  sharableToken?: string
}

export interface FetchJobIdsForExecutiveDashboardTabResponse {
  job_ids: string[]
}

export class FetchJobIdsForExecutiveDashboardTab implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchJobIdsForExecutiveDashboardTab
  constructor(public payload: FetchJobIdsForExecutiveDashboardTabRequest) { }
}

export class FetchJobIdsForExecutiveDashboardTabSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchJobIdsForExecutiveDashboardTabSuccess
  constructor(public payload: FetchJobIdsForExecutiveDashboardTabResponse) { }
}

export class FetchJobIdsForExecutiveDashboardTabFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.FetchJobIdsForExecutiveDashboardTabFailure
  constructor() { }
}

export class UpdateExecutiveDashboardTabHideSection implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabHideSection
  constructor(public payload: ExecutiveDashboardTabHideSection) { }
}

export class UpdateExecutiveDashboardTabHideSectionSuccess implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabHideSectionSuccess
  constructor(public payload: ExecutiveDashboardTab) { }
}

export class UpdateExecutiveDashboardTabHideSectionFailure implements Action {
  readonly type = ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabHideSectionFailure
  constructor() { }
}

export type ExecutiveDashboardActions =
  UpdateExecutiveDashboardConfig | UpdateExecutiveDashboardConfigSuccess | UpdateExecutiveDashboardConfigFailure |
  UpdatePerJobConfig | UpdatePerJobConfigSuccess | UpdatePerJobConfigFailure |
  DeleteExecutiveDashboardTab | DeleteExecutiveDashboardTabSuccess | DeleteExecutiveDashboardTabFailure |
  CreateExecutiveDashboardTab | CreateExecutiveDashboardTabSuccess | CreateExecutiveDashboardTabFailure |
  UpdateExecutiveDashboardTab | UpdateExecutiveDashboardTabSuccess | UpdateExecutiveDashboardTabFailure |
  ReorderExecutiveDashboardTabs | ReorderExecutiveDashboardTabsSuccess | ReorderExecutiveDashboardTabsFailure |
  FetchExecutiveDashboard | FetchExecutiveDashboardSuccess | FetchExecutiveDashboardFailure |
  FetchExecutiveDashboardCharts | FetchExecutiveDashboardChartsSuccess |
  FetchExecutiveDashboardChartsFailed |
  FetchExecutiveDashboardClosedJobs | FetchExecutiveDashboardClosedJobsSuccess |
  FetchExecutiveDashboardClosedJobsFailure |
  FetchJobStatuses | FetchJobStatusesSuccess | FetchJobStatusesFailure |
  SendJobStatusUpdate | SendJobStatusUpdateSuccess | SendJobStatusUpdateFailure |
  FetchSharedExecutiveDashboardTab | FetchSharedExecutiveDashboardTabSuccess | FetchSharedExecutiveDashboardTabFailure |
  FetchJobIdsForExecutiveDashboardTab | FetchJobIdsForExecutiveDashboardTabSuccess |
  FetchJobIdsForExecutiveDashboardTabFailure |
  UpdateExecutiveDashboardTabHideSection | UpdateExecutiveDashboardTabHideSectionSuccess |
  UpdateExecutiveDashboardTabHideSectionFailure
