export enum SortChartOption {
  Default = 'default',
  ByNameAsc = 'name-asc',
  ByNameDesc = 'name-desc',
  ByValueAsc = 'value-asc',
  ByValueDesc = 'value-desc',
  ByDepartmentHierarchyValueAsc = 'department-hierarchy-value-asc',
  ByDepartmentHierarchyValueDesc = 'department-hierarchy-value-desc',
  ByOfficeHierarchyValueAsc = 'office-hierarchy-value-asc',
  ByOfficeHierarchyValueDesc = 'office-hierarchy-value-desc',
}
