<div class="header__wrapper">
  <nav role="navigation" class="header__nav">
    <img src="/crosschq_logo.svg" width="140" class="tw-ml-6" />
    <ul class="nav__links" *ngIf="account && user; else elseBlock">
      <li class="nav__item" *ngIf="appConfig.canViewProspectsTab()">
        <a class="nav__link nav__link--hoverable"
           [routerLink]="routerLink('/prospects')"
           [routerLinkActive]="activeIfTwngRoute()"
           (click)="hardLink('/prospects')">
          <i class="nav__link-icon fas fa-magic"></i>
          <span class="nav__link-text fs-unmask">Prospects</span>
        </a>
      </li>

      <li class="nav__item wall-menu-item" *ngIf="account.active_import_id">
        <a class="nav__link nav__link--hoverable"
        [routerLink]="routerLink('/wall')"
        [routerLinkActive]="activeIfTwngRoute()"
        (click)="hardLink('/wall')">
          <img class="ats-icon tw-inline tw-mr-2.5" height="18" width="18" src="/assets/talentwall-logo--transparent.png">
          <span class="nav__link-text fs-unmask">TalentWall</span>
        </a>
      </li>

      <li class="nav__item" *ngIf="canViewDashboard && account.active_import_id"
          ngbTooltip="Check out our analytics!"
          trackFeatureHighlight="analytics">
        <a class="nav__link nav__link--hoverable"
          [routerLink]="routerLink('/dashboard')"
          [routerLinkActive]="activeIfTwngRoute()"
          (click)="hardLink('/dashboard')">
          <i class="nav__link-icon fas fa-chart-pie"></i>
          <span class="nav__link-text fs-unmask">Analytics</span>
        </a>
      </li>

      <li class="nav__item" *ngIf="canViewExecDashboard && account.active_import_id">
        <a class="nav__link nav__link--hoverable"
          [routerLink]="routerLink('/executive-tools/executive-dashboard')"
          [routerLinkActive]="activeIfTwngRoute()"
          (click)="hardLink('/executive-tools/executive-dashboard')">
          <i class="nav__link-icon fas fa-globe"></i>
          <span class="nav__link-text fs-unmask">Exec. Tools</span>
        </a>
      </li>
      <li class="nav__item" *ngIf="canViewExecDashboard">
        <a class="nav__link nav__link--hoverable"
          [routerLink]="routerLink('/capacity-planning')"
          [routerLinkActive]="activeIfTwngRoute()"
          (click)="hardLink('/capacity-planning')">
          <i class="nav__link-icon fa-solid fa-chart-line"></i>
          <span class="nav__link-text fs-unmask">Plan</span>
        </a>
      </li>

      <li class="nav__item">
        <div class="nav__link" href="#">
          <div ngbDropdown class="nav__dropdown--user">
            <span class="nav__user-email d-none d-xl-inline-block">{{ user.email }}</span>

            <button ngbDropdownToggle
                    class="remove-button-styling"
                    id="navUserDropdown"
                    [disabled]="demoMode"
                    >
              <div class="nav__user-dropdown-wrapper">
                <span class="nav__avatar fs-unmask">{{ userInitials(user) }}</span>
              </div>
            </button>

            <div ngbDropdownMenu aria-labelledby="navUserDropdown">
              <div class="nav__user-info" *ngIf="account">
                <div class="nav__user-org fs-unmask">{{ account.name }}</div>
              </div>
              <hr>
              <button *ngIf="showInterviewPhases()" ngbDropdownItem (click)="goToStageMappings()" class="fs-unmask">
                Interview Phases
              </button>
              <button *ngIf="user.admin" ngbDropdownItem (click)="goToAccount()" class="fs-unmask">Account Settings</button>
              <button ngbDropdownItem class="fs-unmask" (click)="goToSettings()">My Settings</button>
              <a ngbDropdownItem href={{getHelpCenterUrl()}} target="_blank" >Help Center</a>
              <hr>
              <button ngbDropdownItem (click)="signOut()" class="fs-unmask" >Sign Out</button>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <ng-template #elseBlock>
      <ul class="nav__links">
        <li class="nav__item">
          <a class="nav__link nav__link--hoverable" (click)="goToSignIn()">
            <span class="nav__link-text fs-unmask">Sign In</span>
          </a>
        </li>
      </ul>
    </ng-template>
  </nav>
</div>
