import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import {
  Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router
} from '@angular/router'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'

import { ChartStatusCheckerService } from './core/services/chart-status-checker.service'
import { FileFreshnessCheckerService } from './core/services/file-freshness-checker.service'
import { LoadAccount } from './core/actions/loader.actions'
import { SegmentService } from './core/services/segment.service'
import { State } from './reducers'
import { isHostedLocally } from './shared/utils/general-utils'
import { onTwngRoute } from './core/rails-utils'
import { selectSharedDataLoaded } from './shared/state/selectors'

@Component({
  selector: 'twng-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <twng-layout>
      <div *ngIf="loading || (isOnTwngRoute && !(dataLoaded$ | async))" class="mx-auto my-2 text-center">
        <i class="fas fa-sync fa-spin fa-3x"></i>
      </div>
      <router-outlet *ngIf="dataLoaded$ | async"></router-outlet>
    </twng-layout>
  `,
})
export class AppComponent implements OnInit {
  loading = false
  dataLoaded$: Observable<boolean>
  isOnTwngRoute = false

  constructor(
    private store: Store<State>,
    private router: Router,
    private segmentService: SegmentService,
    // we just need to import this somehwere in order to be activated
    _freshness: FileFreshnessCheckerService,
    _chartChecker: ChartStatusCheckerService,
  ) { }

  ngOnInit(): void {
    this.dataLoaded$ = this.store.select(selectSharedDataLoaded)
    // account will either get loaded here from window or from initial.json (in dev,demo)
    if (window.twng_account || window.twng_demo || isHostedLocally()) {
      this.store.dispatch(new LoadAccount({
        account: window.twng_account,
        user: window.twng_user,
        app_config: window.twng_app_config
      }))
    }

    this.router.events.subscribe((event: Event) => {
      this.isOnTwngRoute = onTwngRoute()
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true
          break
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
        case event instanceof NavigationEnd: {
          const e = event as NavigationEnd
          if (e.url === '/dashboard') {
            this.segmentService.track("View Analytics")
          }

          this.loading = false
          break
        }
        default: {
          break
        }
      }

    })
  }

}
