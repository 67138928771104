import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { Action } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import {
  JobActionTypes, UpdateJobUserSettings, UpdateJobUserSettingsFailure,
  UpdateJobUserSettingsSuccess
} from '../actions/jobs.actions'
import { JobUserSettings } from '../models/job-user-settings'
import { apiHost, getHttpPostOptions } from '../../core/http-options'

@Injectable()
export class JobEffects {

  updateJobUserSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateJobUserSettings>(JobActionTypes.UpdateJobUserSettings),
    map(action => action.payload),
    mergeMap(({ jobId, jobUserSettings }) =>
      this.http.put<JobUserSettings>(apiHost + '/twng/jobs/' + jobId + '/user_settings.json',
        jobUserSettings, getHttpPostOptions())
        .pipe(
          map(() =>
            new UpdateJobUserSettingsSuccess()
          ),
          catchError(() => of(new UpdateJobUserSettingsFailure())),
        )
    )
  ))

  constructor(
    private http: HttpClient,
    private actions$: Actions,
  ) { }
}
