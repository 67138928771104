import { Subscription } from 'rxjs'

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit
} from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../../wall/reducers'
import { ActivityEmail } from '../../wall/models/activity'
import { Candidate } from '../../wall/models/candidate'

@Component({
  selector: 'twng-activity-feed',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
   <div *ngIf="activities | present" class="section section--activity-feed">
      <h2 class="section__header tw-mb-2 fs-unmask">
        <i class="fas fa-list"></i>
        <span>Activity Feed</span>
      </h2>

      <div class="section__body">
        <div class="activity-feed-container tw-rounded-md">
          <div class="activity" *ngFor="let activity of activities">
            <div class="activity-created-at fs-unmask">{{ activity.created_at | date:'MMM d, y @ h:mma Z' }}</div>

            <div *ngIf="activity.type === 'email'">
              <div>
                <i class="fas fa-envelope tw-mr-2"></i>
                <span class="email-from">From: <span class="fs-exclude tw-text-sm">{{ activity.from }}</span></span>
                <span class="email-to">To: <span class="fs-exclude tw-text-sm">{{ activity.to }}</span></span>
              </div>
              <div class="email-subject">Subject: <span class="fs-exclude tw-text-sm">{{ activity.subject }}</span></div>
            </div>

            <div *ngIf="activity.type !== 'email'" class="fs-exclude tw-text-sm">
              <span> {{ activity.subject }}</span>
            </div>

            <div class="activity-body fs-exclude tw-text-sm">{{ activity.body }}</div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [
    './card-details-modal.component.scss',
  ],
  styles: [`
    .activity-feed-container {
      color: black;
      background-color: #eee;
      max-height: 250px;
      overflow-y: scroll;
      padding-top: 1rem;
    }
    .activity-created-at {
      font-size: 0.9rem;
      color: #333;
    }
    .activity {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      margin-bottom: 0.6rem;
      padding-bottom: 0.6rem;
      border-bottom: 1px solid #fff;
    }
    .email-from {
      margin-right: 1rem;
    }
    .fa-envelope {
      color: #2a9d2a;
    }
  `],
})
export class ActivityFeedComponent implements OnInit, OnDestroy {
  @Input()
    candidate: Candidate

  activitySub = new Subscription()
  activities: ActivityEmail[]

  constructor(
    private store: Store<fromWall.State>,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const selector = fromWall.getSelectActivitiesForCandidate(this.candidate.id)
    this.activitySub.add(this.store.select(selector).subscribe(activities => {
      this.activities = activities as ActivityEmail[]
      this.cd.markForCheck()
    }))
  }

  ngOnDestroy(): void {
    this.activitySub.unsubscribe()
  }
}
