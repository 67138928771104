import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { sleep } from '../../shared/utils/sleep';
import { toBlob } from 'html-to-image';

declare const ClipboardItem

@Injectable({
  providedIn: 'root'
})
export class JobAnalyticsClipboardService {

  isCopyingToClipboard$ = new BehaviorSubject<boolean>(false)

  async copyToClipboard(analyticsNode: HTMLElement): Promise<void> {
    try {
      this.isCopyingToClipboard$.next(true)

      // Copy on next tick so Angular can update HTML styles
      await sleep(0)

      await this._copyToClipboard(analyticsNode)
    } catch (e) {
      console.error(e)
    } finally {
      this.isCopyingToClipboard$.next(false)
    }
  }

  async _copyToClipboard(analyticsNode: HTMLElement): Promise<void> {
    const options = {
      quality: 0.5,
      height: 740, // TODO: Calculate this dynamically
      pixelRatio: 1,
    }

    return toBlob(analyticsNode, options)
      .then(function(blob) {
        console.log(blob)
        navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob
          })
        ])
      });
  }
}
