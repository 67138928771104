import { CacheService } from './cache.service';
import { Injectable } from '@angular/core';
import { JobStatusUpdate } from '../models/job-status-update';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private cacheService: CacheService) {
  }

  getJobStatusUpdate(jobId: string): Observable<JobStatusUpdate> {
    return this.cacheService.jobIdToStatusUpdates$.pipe(
      map((jobIdToStatusUpdates: { [jobId: string]: JobStatusUpdate[] }) => {
        const jobStatusUpdates = jobIdToStatusUpdates[jobId]
        return jobStatusUpdates?.length ? jobStatusUpdates[0] : null
      }),
      filter(jobIdToStatusUpdate => !!jobIdToStatusUpdate),
    )
  }
}
