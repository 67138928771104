import { AppConfigService } from '../../../../wall/services/app-config.service'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component,
  Input, OnChanges, OnDestroy, SimpleChanges
} from '@angular/core';
import { ExecutiveDashboardTab } from '../../../../wall/models/executive-dashboard'
import { Observable, Subscription } from 'rxjs'
import { Store } from '@ngrx/store'
import { UpdateExecutiveDashboardTab } from '../../../../wall/actions/executive-dashboard.actions'
import { getExecutiveDashboardTabById } from '../../../../wall/reducers'
import { map } from 'rxjs/operators'
import { selectJobStageCategories } from '../../../../stage-mappings/selectors/stage-mappings.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-toggle-stages-phases',
  templateUrl: './toggle-stages-phases.component.html',
  styleUrls: ['./toggle-stages-phases.component.scss']
})
export class ToggleStagesPhasesComponent implements OnChanges, OnDestroy {
  @Input()
    tabId: number

  demo = window.twng_demo

  radioChecked: boolean
  phasesEnabled$: Observable<boolean>
  phasesConfigured$: Observable<boolean>

  loading: boolean
  sub = new Subscription()

  constructor(
    private store: Store,
    config: AppConfigService,
    private cd: ChangeDetectorRef
  ) {
    this.phasesEnabled$ = config.isFeatureFlagEnabled$('interview_phases')
    this.phasesConfigured$ = this.store.select(selectJobStageCategories).pipe(
      // the formula for this is in account.rb, function interview_phases_configured?
      map(phases => phases.filter(phase => phase.job_stage_names.length > 0).length >= 2)
    )
  }

  private getTab$() {
    return this.store.select(getExecutiveDashboardTabById(this.tabId))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tabId?.currentValue !== changes.tabId?.previousValue) {
      this.sub.unsubscribe()
    }

    this.sub = this.getTab$().subscribe(tab => {
      this.radioChecked = tab?.view_job_phases
      this.loading = false
      this.cd.markForCheck()
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  async setNewValue(newValue: boolean) {
    this.loading = true
    this.store.dispatch(new UpdateExecutiveDashboardTab({
      view_job_phases: newValue,
      id: this.tabId,
    } as ExecutiveDashboardTab))
  }
}
