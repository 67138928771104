import { createSelector } from '@ngrx/store'

import { JobStageCategory } from '../models/job-stage-category'
import { StageMappingsState } from '../reducers/stage-mappings.reducer'
import { selectWallState } from '../../wall/reducers'

export const selectStageMappingsState = createSelector(
  selectWallState,
  state => state.stageMappings
)

export const selectIsLoadingCategories = createSelector(
  selectStageMappingsState,
  state => state.loadingCategories
)

export const selectJobStageCategories = createSelector(
  selectStageMappingsState,
  state => [...state.jobStageCategories].sort((a, b) => (a.id - b.id)) // Sort by Category id,
)

export const selectIsCreatingCategory = createSelector(
  selectStageMappingsState,
  state => state.creatingCategory
)

export const getAllSelectedJobStageNames = createSelector(
  selectStageMappingsState,
  (state): string[] => {
    const allJobStageNames = new Set<string>()
    state.jobStageCategories.forEach((jobStageCategory: JobStageCategory) =>
      jobStageCategory.job_stage_names.forEach(jobStageName => allJobStageNames.add(jobStageName))
    )
    return Array.from(allJobStageNames)
  }
)

export const selectJobStageNames = createSelector(
  selectStageMappingsState,
  getAllSelectedJobStageNames,
  (state: StageMappingsState, selectedJobStageNames: string[]) => state.jobStageNames
    .filter(jobStageName => !selectedJobStageNames.includes(jobStageName))
    .sort()
)

export const selectCategoryById = (categoryId: number) => createSelector(
  selectStageMappingsState,
  state => state.jobStageCategories.find(category => category.id === categoryId)
)

export const selectCategoriesByIds = (categoryIds: number[]) => createSelector(
  selectStageMappingsState,
  state => state.jobStageCategories.filter(category => categoryIds.includes(category.id))
)

export const selectIsSyncing = createSelector(
  selectStageMappingsState,
  state => state.updatingCategory || state.loadingCategories || state.creatingCategory || state.deletingCategory
)
