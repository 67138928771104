import {Directive, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import interact from 'interactjs';

@Directive({
  selector: '[appDroppable]'
})
export class DroppableDirective implements OnInit {

  @Input()
    options: unknown;

  @Output()
    dropping: EventEmitter<unknown> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    interact(this.elementRef.nativeElement)
      .dropzone(Object.assign({}, this.options || {}))
      .on('dropactivate', event => {
        event.relatedTarget.classList.add('dragging')
      })
      .on('dragenter', event => {
        event.target.parentElement.classList.add('is-dragging')
      })
      .on('dragleave', event => {
        event.target.parentElement.classList.remove('is-dragging')
      })
      .on('drop', () => {
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const model = (window as any).dragData;

          if (typeof (model) === 'object') {
            this.dropping.emit(model);
          }
        });

      })
      .on('dropdeactivate', event => {
        event.relatedTarget.classList.remove('dragging');
        event.target.parentElement.classList.remove('is-dragging');
      });
  }
}
