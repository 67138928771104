import { ContentChildren, Directive, HostListener, Input, QueryList } from '@angular/core'
import { TableSortDirective } from './table-sort.directive'

/**
 * Listens for any click on the table. If the clicked
 * element has the attribute for the sortByAttribute, then it will
 * tell that sortByAttribute directive (twngTableSort) to sort the table.
 * It also updates the status off all columns to active/inactive
 */
@Directive({
  selector: '[twngTableSortHandler]'
})
export class TableSortHandlerDirective {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() tableElements: any[]

  @ContentChildren(TableSortDirective, { descendants: true })
    tableSortDirectiveChildren: QueryList<TableSortDirective>

  @HostListener('click', ['$event']) onClick(event) {
    this.sortTable(event)
  }

  sortTable(event: MouseEvent) {
    const clickedColumnName = this.getClickedColumnName(event)

    if (!clickedColumnName) {
      console.log('Unable to find the clicked sortByAttribute')
    } else {
      this.setAllColumnsInactive()
      this.sortTableByColumn(clickedColumnName)
    }
  }

  getClickedColumnName(event: MouseEvent): string {
    const el: HTMLElement = event?.target as HTMLElement
    return el?.dataset.sortByAttribute
  }

  getTableSortDirective(columnName: string): TableSortDirective {
    return this.tableSortDirectiveChildren.find(tableSortDirective => tableSortDirective.sortByAttribute === columnName)
  }

  setAllColumnsInactive() {
    this.tableSortDirectiveChildren.forEach(tableSortDirective => tableSortDirective.isActive = false)
  }

  sortTableByColumn(columnName: string) {
    const activeTableSortDirective = this.getTableSortDirective(columnName)
    if (!activeTableSortDirective) {
      console.warn(`Unable find the element with column "${columnName}" to sort`)
      return
    }
    activeTableSortDirective.isActive = true
    activeTableSortDirective.sort(this.tableElements)
  }

}
