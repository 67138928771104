<div class="name fs-unmask" ngbTooltip="Press CTRL + ENTER to save" (keydown)="keyDown($event)">
  <form class="form d-flex" [formGroup]="customStatBoxForm" (ngSubmit)="onSubmit()" (blur)="cancel()">
    <textarea class="form-input" #inputCustomStatValue formControlName="customValue">
    </textarea>
    <div class="d-flex flex-column">
      <button type="button" class="button--large fs-unmask tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-cq-gray-600 tw-border tw-border-cq-gray-300 tw-bg-white hover:tw-bg-cq-gray-50 tw-hover:text-cq-gray-700 tw-text-sm tw-p-3" (click)="cancel()">Cancel</button>
      <button type="submit" class="button--primary button--large fs-unmask tw-mt-2 tw-normal-case tw-flex tw-justify-center tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-white tw-bg-cq-primary-600 tw-text-sm tw-p-3">Save</button>
    </div>
  </form>
</div>
