import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FetchSharedExecutiveDashboardTab } from '../../../wall/actions/executive-dashboard.actions';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { getExecutiveDashboardTabById, selectExecutiveDashboardLoaded } from '../../../wall/reducers';
import { loadExecutiveDashboardIfNecessary } from '../../../shared/utils/store.utils';
import { map } from 'rxjs/operators';

@Component({
  selector: 'twng-executive-dashboard-shared-tab-page',
  templateUrl: './executive-dashboard-shared-tab-page.component.html',
  styleUrls: ['./executive-dashboard-shared-tab-page.component.css']
})
export class ExecutiveDashboardSharedTabPageComponent implements OnInit {
  isReady$: Observable<boolean>

  constructor(
    private store: Store,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    loadExecutiveDashboardIfNecessary(this.store)
    const params = this.route.snapshot.paramMap
    const id = parseInt(params.get('tabId'), 10)
    const token = params.get('sharedKey')
    this.store.dispatch(new FetchSharedExecutiveDashboardTab({ id, token }))
    this.isReady$ = combineLatest([
      this.store.select(getExecutiveDashboardTabById(id)).pipe(map(Boolean)),
      this.store.select(selectExecutiveDashboardLoaded)
    ]).pipe(map(([tabReady, execDashReady]) => tabReady && execDashReady))
  }
}
