import { Candidate } from './candidate'
import { Job } from './job'
import { JobStage } from './job-stage'
import { RatingNumber } from './rating-number';
import { atsId } from './types'

export class JobApplication {
  id: atsId
  candidate_id: atsId
  job_id: atsId
  job_stage_id: atsId
  credited_to_external_user_id: atsId
  status: string
  last_activity_at: string
  last_activity_days_ago: number
  activity_level: 'good' | 'fair' | 'poor' | 'dead'
  hired_at: string
  applied_at: string
  source: string
  source_type: string
  starred: boolean
  candidate?: Candidate
  job?: Job
  stage?: JobStage
  url?: string
  rating: RatingNumber | null

  // ephemeral state
  waitingForSync: boolean
  changedOnServer?: boolean
}

export const JOB_APPLICATION_STATUS_ACTIVE = 'active'
export const JOB_APPLICATION_STATUS_HIRED = 'hired'
