import { ChartFilters } from "../models/chart-filters";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiHost, getHttpPostOptions } from "../../core/http-options";
import { map } from "rxjs/operators";

interface NewSharableTokenResponse {
  sharable_token: string
}

function idFiltersandEditableToKey(id: string, filters: ChartFilters, editable: boolean) {
  return JSON.stringify({ id, filters, editable })
}

@Injectable({
  providedIn: 'root'
})
export class CustomDashboardService {
  private tokenCache: { [idAndFilters: string]: string } = {}

  constructor(private http: HttpClient) { }

  async createNewSharableToken(dashboardTabId: string, filters: ChartFilters, editable: boolean): Promise<string> {
    const cacheKey = idFiltersandEditableToKey(dashboardTabId, filters, editable)
    const prevToken = this.tokenCache[cacheKey]
    if (prevToken) {
      return prevToken
    }
    const newToken = await this.http.post<NewSharableTokenResponse>(
      `${apiHost}/twng/dashboard/tabs/${dashboardTabId}/sharable_token`,
      { filters, editable: editable },
      getHttpPostOptions()
    ).pipe(map(response => response.sharable_token)).toPromise()
    this.tokenCache[cacheKey] = newToken
    return newToken
  }
}
