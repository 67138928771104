import * as computedStyleToInlineStyle from 'computed-style-to-inline-style'
import { BehaviorSubject } from 'rxjs'
import { format } from 'date-fns'
import { jsPDF, jsPDFOptions } from 'jspdf'
import domtoimage, { Options as CanvasOptions } from 'dom-to-image'
import slugify from 'slugify'


export type Orientation = 'landscape' | 'portrait' | 'p' | 'l'

export interface PdfDimensions {
  width: number,
  height: number,
}

export interface TwngPdfOptions {
  tabName?: string
}

export abstract class PdfService {

  static backgroundColor = 'white'; // Same as Dashboard
  static logoColor = '#2988E2';
  static pagePadding = 16

  public exporting$ = new BehaviorSubject<boolean>(false)

  protected abstract generatePDF(elementSelector?: string, pdfOptions?: TwngPdfOptions): Promise<void>

  /**
   * Hook method to re implement if something is needed to do
   * before generating the canvas from the html
   */
  protected beforeGeneratingCanvasFromHTML(): Promise<void> {
    return Promise.resolve()
  }

  /**
   * Hook method to re implement if something is needed to do
   * after generating the canvas from the html
   */
  protected afterGeneratingCanvasFromHTML(): Promise<void> {
    return Promise.resolve()
  }

  getHeaderDate(): string {
    return format(new Date(), 'MM/dd/yyyy')
  }

  generateFileName(elementString: string): string {
    return slugify(`${elementString}-${format(new Date(), 'yyyy-MM-dd')}.pdf`)
  }

  getLogoSectionDimensions() {
    // Keep aspect ratio of the image 4.64
    const logoWidth = 121 // No margins
    const logoHeight = 26 // No margins

    const logoMarginLeft = PdfService.pagePadding // from styles. To be left aligned with the titles of the charts
    const logoVerticalMargin = PdfService.pagePadding  // from styles

    const logoReactHeight = logoHeight + (logoVerticalMargin * 2) // Double for margin top and bottom

    return {
      logoWidth, // image width
      logoHeight, // image height
      logoMarginLeft,
      logoVerticalMargin,
      logoReactHeight,  // logo header height
    }
  }

  protected addLogo(pdf: jsPDF) {
    const logoImg = document.createElement('img')
    logoImg.src = `${window.location.origin}/assets/TW_Logo-B-04.png`

    const logoSizes = this.getLogoSectionDimensions()

    // Create a rect with size:
    // width: full (same as pdf page)
    // height: logo.height + margin top and bottom
    pdf.setFillColor(PdfService.backgroundColor)
    pdf.rect(0, 0, pdf.internal.pageSize.width, logoSizes.logoReactHeight, 'F')

    pdf.addImage(logoImg, 'PNG',
      logoSizes.logoMarginLeft, // x position on the page
      logoSizes.logoVerticalMargin, // y position on the page
      logoSizes.logoWidth, // logo width
      logoSizes.logoHeight, // logo height
      '',
      'FAST'
    )
  }

  protected addDate(pdf: jsPDF) {
    const todayStr = this.getHeaderDate()
    const logoSizes = this.getLogoSectionDimensions()

    const xPos = pdf.internal.pageSize.width - todayStr.length - logoSizes.logoMarginLeft
    const yPos = logoSizes.logoReactHeight / 2 // half of the logo height for align

    pdf.setTextColor(PdfService.logoColor) // Same as logo color
    pdf.setFontSize(10)
    pdf.text(todayStr, xPos, yPos, { align: 'right' })
  }

  protected getA4FileDimensions(pageOrientation?: Orientation): PdfDimensions {
    const orientation = pageOrientation || 'portrait'
    const pdfA4Size = new jsPDF(orientation, 'pt', 'a4')
    return {
      width: pdfA4Size.internal.pageSize.getWidth(),
      height: pdfA4Size.internal.pageSize.getHeight(),
    }
  }

  /**
   * Return the image dimensions with the correct aspect ratio
   * for the pdf file width. The image will have the pdf file width.
   *
   * @param elementSelector CSS selector of the element to export
   * @param orientation of the page.
   */
  getImageDimensions(elementSelector: string, orientation: Orientation = 'portrait'): PdfDimensions {
    const a4FileSize = this.getA4FileDimensions(orientation)
    const imageWidth = a4FileSize.width
    const originalEl = document.querySelector(elementSelector)
    const ratio = originalEl.scrollHeight / originalEl.scrollWidth
    const imageHeight = ratio * imageWidth

    return {
      width: imageWidth,
      height: imageHeight
    }
  }

  addPageHeader(jsPdf: jsPDF) {
    // Add TalentWall logo
    this.addLogo(jsPdf)
    this.addDate(jsPdf)
  }

  createPDFFile(elementSelector?: string, pdfOptions?: jsPDFOptions): jsPDF {
    const logoSizes = this.getLogoSectionDimensions()
    const a4FileSize = this.getA4FileDimensions(pdfOptions?.orientation)

    let estimatedHeight = 0 // For normal a4 size
    if (elementSelector) {
      const imageDimensions = this.getImageDimensions(elementSelector)
      // pdf height will be :
      // logo height +
      // image height +
      estimatedHeight = imageDimensions.height + logoSizes.logoReactHeight
    }

    // If the logo+image height is larger than a4 size, then set the filePage height to logo+image
    // This is required because the exported element can have very long height and don't fit in one standard a4 page
    const pdfHeight = estimatedHeight < a4FileSize.height ? a4FileSize.height : estimatedHeight

    const compress = true
    const options: jsPDFOptions = {
      orientation: 'portrait',
      unit: 'pt',
      format: [a4FileSize.width, pdfHeight],
      compress,
      ...pdfOptions,
    }
    const pdf = new jsPDF(options)

    this.stylePage(pdf)
    this.addPageHeader(pdf)

    return pdf
  }

  stylePage(jsPdf: jsPDF) {
    // Fill all the page with the dashboard background color
    jsPdf.setFillColor(PdfService.backgroundColor)
    jsPdf.rect(0, 0, jsPdf.internal.pageSize.getWidth(), jsPdf.internal.pageSize.getHeight(), 'F')
  }

  /**
   * Adds a page with the size of the PDF unless
   * the dimension are specified
   *
   * @param jsPdf
   * @param dimensions
   * @param pdfOptions
   */
  addPage(jsPdf: jsPDF, dimensions?: PdfDimensions, pdfOptions?: jsPDFOptions) {
    const pdfPageDimensions: PdfDimensions = dimensions || {
      width: jsPdf.internal.pageSize.getWidth(),
      height: jsPdf.internal.pageSize.getHeight()
    }

    const options = {
      format: [pdfPageDimensions.width, pdfPageDimensions.height],
      orientation: 'portrait',
      ...pdfOptions,
    }
    // @ts-ignore
    jsPdf.addPage(options)

    this.stylePage(jsPdf)
    this.addPageHeader(jsPdf)
  }

  getPositionXForCentering(pdf: jsPDF, imageDimensions: PdfDimensions, scaleFactor: number = 1): number {
    const imageWidth = imageDimensions.width * scaleFactor
    const pageWidth = pdf.internal.pageSize.getWidth()

    // If the imageWidth is bigger than the page width
    // then return 0 to put it at the begging at the page
    // so it will use thw whole width of the page
    if (imageWidth > pageWidth) {
      return 0
    }
    return Math.floor((pageWidth - imageWidth) / 2)
  }

  filterNode(node) {
    return (!node.attributes?.hasOwnProperty('data-html2canvas-ignore'))
  }

  protected generateCanvasFromHTML(selector: string, canvasOptions?: Partial<CanvasOptions>): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      const el = document.querySelector(selector) as HTMLElement

      const options = {
        bgcolor: PdfService.backgroundColor,
        width: window.innerWidth,
        filter: this.filterNode,
        ...canvasOptions,
      }

      computedStyleToInlineStyle(el, {
        recursive: true,
        properties: ["font-size", "font-family", "font-weight"]
      })

      await this.beforeGeneratingCanvasFromHTML()

      domtoimage.toPng(el, options)
        .then((canvas) => {
          if (!canvas) {
            return reject('No html element converted to canvas')
          }
          resolve(canvas)
        })
        .catch(reject)
        .finally(async () => {
          await this.afterGeneratingCanvasFromHTML()
        })
    })
  }

  protected async emitExporting() {
    this.exporting$.next(true)
    // Give time to run ChangeDetection and apply styles on outside components
    await new Promise(resolve => setTimeout(resolve, 1000))
  }

  async exportToPDF(elementSelector: string, pdfOptions?: TwngPdfOptions) {
    try {
      await this.emitExporting()
      await this.generatePDF(elementSelector, pdfOptions)
    } finally {
      this.exporting$.next(false)
    }
  }

  /**
   * Forces a reflow to apply the changed styles
   */
  flushStyles() {
    window.getComputedStyle(document.body)
  }

}
