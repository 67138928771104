import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProspectComponent } from './prospect/prospect.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [ProspectsComponent, ProspectComponent],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class ComponentsModule {
}
