import { Observable, of } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { catchError, delay, map, mergeMap } from 'rxjs/operators'

import { Action } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { DemoService } from '../services/demo.service'
import {
  JobApplicationActionTypes, UpdateJobApplicationStage, UpdateJobApplicationStageFailure,
  UpdateJobApplicationStageSuccess, UpdateJobApplications
} from '../actions/job-applications.actions'
import { LoaderActionTypes, UpdateFromServer } from '../../core/actions/loader.actions'
import { apiHost, getHttpPostOptions } from '../../core/http-options'

@Injectable()
export class JobApplicationEffects {
  // After a card updates and is marked changed, we need to unmark it,
  // so that the 'changed' yellow highlight will fade out

  // NOTE: We are no longer doing the yellow flash, and this can probably be removed,
  // along with any reference to changedOnServer.
  unmarkChanged$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateFromServer>(LoaderActionTypes.UpdateFromServer),
    delay(600),
    map(action => {
      // not all updates include job applications
      const jobApps = action.payload.job_applications || []
      return new UpdateJobApplications(
        jobApps.map(app => ({
          id: app.id,
          changes: { changedOnServer: false },
        })),
      )
    }),
  ))

  // Listen for UpdateJobApplicationStage
  updateJobApplicationStage$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateJobApplicationStage>(
      JobApplicationActionTypes.UpdateJobApplicationStage,
    ),
    map(action => action.payload),
    mergeMap(({ jobApplication, toStage, fromStage }) => {
      if (window.twng_demo) {
        const response = this.demoService.successfulResponse()
        return of(
          new UpdateJobApplicationStageSuccess({ response, jobApplication }),
        )
      }

      return this.http
        .patch(
          apiHost + `/twng/job_applications/${jobApplication.id}`,
          { target_job_stage_id: toStage.id },
          getHttpPostOptions(),
        )
        .pipe(
          map(response =>
            new UpdateJobApplicationStageSuccess({
              response,
              jobApplication,
            }),
          ),
          catchError(() => {
            this.toastr.error("Failed to Move Candidate")
            return of(
              new UpdateJobApplicationStageFailure({
                jobApplication,
                toStage,
                fromStage,
              }),
            )
          }),
        )
    }
    ),
  ))

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private toastr: ToastrService,
    private demoService: DemoService,
  ) { }
}
