import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input,
  OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

export interface SelectOption {
  id: string | number
  name: string
}

@Component({
  selector: 'twng-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: SelectComponent,
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
    options: SelectOption[]

  @Input()
    label: string

  disabled: boolean

  selectedOption: SelectOption
  private selectedId: string | number | undefined
  private previousSelectedId: string | number | undefined

  constructor(
    private cd: ChangeDetectorRef,
  ) { }

  setNewId(id: string | number): void {
    this.previousSelectedId = this.selectedId
    this.selectedId = id
    if (this.options) {
      this.selectedOption = this.options.find(option => option.id === id)
      if (!this.selectedOption && this.selectedId) {
        delete this.selectedId
      }
      if (this.previousSelectedId !== this.selectedId && this.onChangeListener) {
        this.onChangeListener(id)
      }
      this.cd.markForCheck()
    }
  }

  writeValue(id: string | number): void {
    this.setNewId(id)
  }
  private onChangeListener: (newValue: string | number) => void
  registerOnChange(fn: (newValue: string | number) => void): void {
    this.onChangeListener = fn
  }
  registerOnTouched(_fn): void {
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
    this.cd.markForCheck()
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && this.options) {
      this.writeValue(this.selectedId)
      this.cd.markForCheck()
    }
  }
}
