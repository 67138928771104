import { CategoriesListComponent } from './components/categories-list/categories-list.component';
import { CategoryComponent } from './components/category/category.component';
import { CommonModule } from '@angular/common';
import { DropContainerComponent } from './components/drop-container/drop-container.component';
import { EffectsModule } from '@ngrx/effects';
import { JobStageNameComponent } from './components/job-stage-name/job-stage-name.component';
import { JobStageNameListComponent } from './components/job-stage-name-list/job-stage-name-list.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ShowDeleteDirective } from './components/category/show-delete.directive';
import { StageMappingsComponent } from './components/stage-mappings/stage-mappings.component';
import { StageMappingsEffects } from './effects/stage-mappings.effects';


@NgModule({
  declarations: [
    StageMappingsComponent,
    JobStageNameListComponent,
    JobStageNameComponent,
    CategoriesListComponent,
    CategoryComponent,
    ShowDeleteDirective,
    DropContainerComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([StageMappingsEffects])
  ]
})
export class StageMappingsModule {
}
