<div>
  <div class="filters-modal-header d-flex justify-content-between m-3 tw-pb-4">
    <h1>Filter by</h1>
    <i class="fas fa-times" (click)="closeModal()"></i>
  </div>
  <div class="grid-container m-3" *ngIf="managers.length">
    <twng-date-chart-filter-form [dateFilterForm]="dateFilterForm" [filters]="filters" class="first-row">
    </twng-date-chart-filter-form>
    <div *ngFor="let manager of managers; index as i" class="filter-input-wrapper manager-{{i}}">
      <span class="section-title m-2" *ngIf="i === 0">Department</span>
      <span class="section-title m-2" *ngIf="i === 1">Office</span>
      <span class="section-title m-2" *ngIf="i === 2">Jobs</span>
      <span class="section-title m-2" *ngIf="i === 4">Users</span>
      <span class="section-title m-2" *ngIf="i === 12">Sources</span>
      <span class="section-title m-2" *ngIf="i === 13">Custom Fields</span>

      <twng-filter-search-typeahead [manager]="manager" [isModal]="true">
      </twng-filter-search-typeahead>
    </div>
  </div>

  <div class="filter-submit-wrapper d-flex justify-content-end">
    <span class="tw-text-cq-negative-500 tw-p-3 tw-mr-3" *ngIf="overrideFiltersSet">Note: this includes override filters.</span>
    <button class="button--large fs-unmask tw-mr-3 tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-cq-gray-600 tw-border tw-border-cq-gray-300 tw-bg-white hover:tw-bg-cq-gray-50 tw-hover:text-cq-gray-700 tw-text-sm tw-p-3" (click)="closeModal()">
      Cancel
    </button>
    <button type="submit" class="button--primary button--large fs-unmask tw-mr-3 tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-white tw-bg-cq-primary-600 tw-text-sm tw-p-3 tw-normal-case" (click)="apply()">
      Apply
    </button>
  </div>
</div>
