import { Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'

@Component({
  selector: 'twng-name-filter',
  template: `
    <div class="name-filter-wrapper">
      <input #nameFilter
        type="text"
        autocomplete="off"
        [placeholder]="placeholder"
        [value]="query"
        (keyup)="search$.emit(nameFilter.value)"
      />
      <button class="reset-search" (click)="resetSearch()">
        <i class="fas fa-times reset-search-icon"></i>
      </button>
    </div>
  `,
  styleUrls: ['./name-filter.component.scss'],
})
export class NameFilterComponent implements OnInit, OnDestroy {
  @Input()
    placeholder = ''

  @Input()
    initialQuery = ''

  @Input()
    updatedQuery: EventEmitter<string>

  @Output()
    searchedQueryChange = new EventEmitter()

  query = ''
  queryKeyUpSub: Subscription
  queryExternalChangeSub: Subscription
  updatedQuerySub: Subscription
  search$ = new EventEmitter<string>()

  constructor() { }

  ngOnInit(): void {
    this.query = this.initialQuery
    this.updatedQuerySub = this.updatedQuery.subscribe((query: string) => {
      this.query = query
    })

    // update the search field value immediately on keyup
    this.queryKeyUpSub = this.search$.subscribe((query: string) => {
      this.query = query
    })

    // but to keep the rendering smooth, debounce updating the output
    this.queryExternalChangeSub = this.search$
      .pipe(
        debounceTime(200),
        map(q => q.trim()),
        distinctUntilChanged(),
      )
      .subscribe(query => {
        this.searchedQueryChange.emit(query)
      })
  }

  ngOnDestroy(): void {
    if (this.queryKeyUpSub) {
      this.queryKeyUpSub.unsubscribe()
    }
    if (this.queryExternalChangeSub) {
      this.queryExternalChangeSub.unsubscribe()
    }
    if (this.updatedQuerySub) {
      this.updatedQuerySub.unsubscribe()
    }
  }

  resetSearch(): void {
    this.search$.emit('')
  }
}
