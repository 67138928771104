import { Observable, of } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'

import { Action } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import {
  CreateTab, CreateTabFailure, CreateTabSuccess, DeleteTab, DeleteTabFailure, DeleteTabSuccess,
  SharedTab, SharedTabFailure, SharedTabSuccess, TabActionTypes, UpdateTab, UpdateTabFailure,
  UpdateTabSuccess
} from '../actions/tabs.actions'
import { Tab } from '../models/tab'
import { WallInit } from '../../core/actions/loader.actions'
import { apiHost, getHttpPostOptions } from '../../core/http-options'

interface TabResponse {
  tab: Tab
}

interface SharedTabsResponse {
  success: string
  body: {
    current_user_new_tab_id: number
  }
}

@Injectable()
export class TabEffects {
  createTab$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<CreateTab>(TabActionTypes.CreateTab),
    map(action => action.payload),
    mergeMap(({ tab }) => {
      if (window.twng_demo) {
        const newTab = {
          ...tab,
          id: new Date().getSeconds(),
        }
        return of(new CreateTabSuccess({ tab: newTab }))
      }

      return this.http
        .post<TabResponse>(apiHost + '/twng/tabs', tab, getHttpPostOptions())
        .pipe(
          map(
            httpResponse =>
              new CreateTabSuccess({
                tab: httpResponse.tab, // the newly created tab
              }),
          ),
          catchError(httpResponse => {
            this.showTabMessageForResponse(httpResponse, 'create')
            return of(
              new CreateTabFailure({
                tab, // the original tab
              }),
            )
          }),
        )
    }
    ),
  ))

  shareTab$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<SharedTab>(TabActionTypes.SharedTab),
    map(action => action.payload),
    mergeMap(({ type, activeTab, user_ids, sharedWithUsers, filters }) => {
      if (window.twng_demo) {
        return of(new SharedTabSuccess({
          type,
          activeTab,
          user_ids,
          sharedWithUsers
        }))
      }

      let path
      if (type === 'wall') {
        path = '/twng/tabs/send_tab_to_user'
      } else if (type === 'dashboard') {
        path = '/twng/dashboard/tabs/send_tab_to_user'
      }

      return this.http
        .post<SharedTabsResponse>(apiHost + path,
        {
          tab_id: activeTab,
          user_ids,
          filters,
        }, getHttpPostOptions())
        .pipe(
          map(
            (response) => {
              const toastText = sharedWithUsers.length ? `Shared tab with: ${sharedWithUsers.join(', ')}` : "Dashboard duplicated"
              this.toastr.success(toastText)

              return new SharedTabSuccess({
                type,
                activeTab,
                user_ids,
                sharedWithUsers,
                current_user_new_tab_id: response.body.current_user_new_tab_id
              })
            }
          ),
          catchError(() => {
            this.toastr.error(`Failed to share tab with: ${sharedWithUsers.join(', ')}`)
            return of(
              new SharedTabFailure({
                type,
                activeTab,
                user_ids,
                sharedWithUsers,
              }),
            )
          }),
        )
    }),
  ))

  updateTab$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateTab>(TabActionTypes.UpdateTab),
    map(action => action.payload),
    mergeMap(({ tab, oldTab }) => {
      if (window.twng_demo) {
        return of(new UpdateTabSuccess({ tab }))
      }

      return this.http
        .put<TabResponse>(
        apiHost + `/twng/tabs/${tab.id}`,
        tab,
        getHttpPostOptions(),
      ).pipe(
        map(httpResponse => new UpdateTabSuccess({
          tab: httpResponse.tab,
        })),
        catchError(httpResponse => {
          this.showTabMessageForResponse(httpResponse, 'update')
          return of(
            new UpdateTabFailure({
              tab,
              oldTab, // the original tab
            }),
          )
        }),
      )
    }),
  ))


  reloadWallAfterCreate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateTabSuccess>(TabActionTypes.CreateTabSuccess),
    tap(action => this.router.navigateByUrl(`/wall/tabs/${action.payload.tab.id}`)),
    map(() => new WallInit()),
  ))

  reloadWallAfterUpdate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateTab>(TabActionTypes.UpdateTabSuccess),
    map(() => new WallInit()),
  ))

  deleteTab$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<DeleteTab>(TabActionTypes.DeleteTab),
    map(action => action.payload),
    mergeMap(({ tab }) => {
      if (window.twng_demo) {
        return of(new DeleteTabSuccess({ tab }))
      }

      return this.http
        .delete(apiHost + `/twng/tabs/${tab.id}`, getHttpPostOptions())
        .pipe(
          map(() => {
            this.router.navigate(['/wall'])
            this.toastr.success(`Removed ${tab.name}`)
            return new DeleteTabSuccess({ tab })
          }),
          catchError(httpResponse => {
            this.showTabMessageForResponse(httpResponse, 'delete')
            return of(new DeleteTabFailure({ tab }))
          }),
        )
    }),
  ))

  showTabMessageForResponse(response, action: string) {
    const message = response?.error?.error
    this.toastr.error(
      `An error occurred while attempting to ${action} a tab.`,
      message,
    )
  }

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private toastr: ToastrService,
    private router: Router,
  ) { }
}
