/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'
import { Candidate } from '../../wall/models/candidate'
import { atsId } from '../../wall/models/types'

export enum RejectCandidateUIActionTypes {
  UpdateSendEmailAt = '[RejectCandidate] UpdateSendEmailAt',
  UpdateSelectedEmailTemplate = '[RejectCandidate] UpdateSelectedEmailTemplate',
  UpdateSelectedRejectionReason = '[RejectCandidate] UpdateSelectedRejectionReason',
  UpdateSelectedRejectionReasonText = '[RejectCandidate] UpdateSelectedRejectionReasonText',
  RejectCandidate = '[RejectCandidate] RejectCandidate',
  RejectCandidateSuccess = '[RejectCandidate] RejectCandidateSuccess',
  RejectCandidateFailure = '[RejectCandidate] RejectCandidateFailure',
}

export class UpdateSendEmailAt implements Action {
  readonly type = RejectCandidateUIActionTypes.UpdateSendEmailAt
  constructor(
    public payload: { jobApplicationId: atsId; sendEmailAt: string },
  ) { }
}

export class UpdateSelectedEmailTemplate implements Action {
  readonly type = RejectCandidateUIActionTypes.UpdateSelectedEmailTemplate
  constructor(
    public payload: { jobApplicationId: atsId; emailTemplateId: atsId },
  ) { }
}

export class UpdateSelectedRejectionReason implements Action {
  readonly type = RejectCandidateUIActionTypes.UpdateSelectedRejectionReason
  constructor(
    public payload: {
      jobApplicationId: atsId
      rejectionReasonId: atsId
    },
  ) { }
}

export class UpdateSelectedRejectionReasonText implements Action {
  readonly type = RejectCandidateUIActionTypes.UpdateSelectedRejectionReasonText
  constructor(
    public payload: { jobApplicationId: atsId; rejectionReasonText: string },
  ) { }
}

export interface RejectCandidatePayload {
  jobApplicationId: string
  rejectionReasonId: string
  rejectionReasonText?: string
  emailTemplateId: string
  sendEmailAt: string
  candidate: Candidate
}

export class RejectCandidate implements Action {
  readonly type = RejectCandidateUIActionTypes.RejectCandidate
  constructor(
    public payload: RejectCandidatePayload,
  ) { }
}

export class RejectCandidateSuccess implements Action {
  readonly type = RejectCandidateUIActionTypes.RejectCandidateSuccess
  constructor(public payload: { requestData?: unknown; jobApplicationId: string }) { }
}

export class RejectCandidateFailure implements Action {
  readonly type = RejectCandidateUIActionTypes.RejectCandidateFailure
  constructor(public payload: { jobApplicationId: string; networkError: boolean }) { }
}

export type RejectCandidateUIActions =
  | UpdateSendEmailAt
  | UpdateSelectedEmailTemplate
  | UpdateSelectedRejectionReason
  | UpdateSelectedRejectionReasonText
  | RejectCandidate
  | RejectCandidateSuccess
  | RejectCandidateFailure
