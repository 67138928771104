import { CacheService } from '../../../../wall/services/cache.service'
import { ChartFilters } from '../../../models/chart-filters'
import { Component } from '@angular/core'
import { HierarchicalSearchTypeaheadDataManager } from './hierarchical-search-typeahead-data-manager'
import { OfficeWithChildren } from '../../../../wall/models/office'
import { SearchTypeaheadDataManager, SingleChartFilterComponent } from './single-chart-filter.component'
import { SegmentService } from '../../../../core/services/segment.service'
import { Store } from '@ngrx/store'
import { first } from 'rxjs/operators'

export class OfficeDataManager extends HierarchicalSearchTypeaheadDataManager<OfficeWithChildren> {
  constructor(private cache: CacheService) {
    super('Offices')
  }

  protected loadAllData(): Promise<OfficeWithChildren[]> {
    return this.cache.officesWithChildren$.pipe(first()).toPromise()
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.office_ids
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_offices
  }
}

@Component({
  selector: 'twng-office-single-chart-filter',
  templateUrl: './single-chart-filter.component.html',
  styleUrls: ['./single-chart-filter.component.scss',
    './single-chart-filter-template/single-chart-filter-template.component.scss'
  ],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  providers: [{ provide: SingleChartFilterComponent, useExisting: OfficeSingleChartFilterComponent }]
})
export class OfficeSingleChartFilterComponent extends SingleChartFilterComponent {
  id = 'OfficeSingleChartFilterComponent';

  constructor(
    segmentService: SegmentService,
    store: Store,
    private cache: CacheService
  ) {
    super(segmentService, store, 'Offices', 'Office', 'fa-building')
  }

  getChangedValueForStoring(): Partial<ChartFilters> {
    return {
      office_ids: this.managers[0].tempSelectedIds,
      should_exclude_offices: this.managers[0].shouldExcludeFilter
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getDataManagers(): SearchTypeaheadDataManager<any>[] {
    return [new OfficeDataManager(this.cache)]
  }
}
