import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DashboardChart } from '../../models/dashboard-chart'
import { Store } from '@ngrx/store'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { UpdateDashboardChart } from '../../actions/filters.actions'
import { WidgetDataType } from '../../../wall/models/offer'

@Component({
  selector: 'twng-free-text-custom-box-input',
  templateUrl: './free-text-custom-box-input.component.html',
  styleUrls: [
    '../dashboard.shared.component.scss',
    './free-text-custom-box-input.component.scss'
  ]
})
export class FreeTextCustomBoxInputComponent implements OnInit {

  @ViewChild('inputCustomStatValue', { static: false })
    inputCustomStatValueRef: ElementRef<HTMLInputElement>

  @Input()
    chart: DashboardChart

  @Input()
    value: string

  @Input()
    chartInfo: string

  @Output()
    stopEditing = new EventEmitter<void>()

  customStatBoxForm: UntypedFormGroup

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.createForm()
  }

  disableEditName(): void {
    this.stopEditing.emit()
  }

  createForm(): void {
    this.customStatBoxForm = new UntypedFormGroup({
      customValue: new UntypedFormControl(this.value, [Validators.required]),
    })
    setTimeout(() => {
      this.inputCustomStatValueRef.nativeElement.focus()
    })
  }

  cleanValue(): void {
    const customValue = this.customStatBoxForm.controls.customValue.value.trim()
    this.customStatBoxForm.controls.customValue.setValue(customValue)
  }

  onSubmit(): void {
    this.cleanValue()

    // If form is invalid (customStatValue.length === 0),
    // then don't do anything
    if (this.customStatBoxForm.invalid) {
      this.disableEditName()
      return
    }

    // If the user has made a change, dispatch the update
    if (this.customStatBoxForm.dirty) {
      this.store.dispatch(new UpdateDashboardChart({
        dashboard_chart: {
          ...this.chart,
          custom_value: this.customStatBoxForm.value.customValue,
          data_type: WidgetDataType.CUSTOM
        },
      }))
    }

    this.disableEditName()
  }

  cancel() {
    this.customStatBoxForm.controls.customValue.setValue(this.value)
    this.disableEditName()
  }

  keyDown(e: KeyboardEvent) {
    if ((e.ctrlKey || e.metaKey) && (e.key === "Enter")) {
      this.onSubmit()
    }
  }
}
