import { ExternalUser } from '../../wall/models/external-user'
import { NoteActionTypes, NoteActions } from '../../wall/actions/notes.actions'
import { NotesUIActionTypes, NotesUIActions } from '../actions/notes-ui.actions'
import { omitBy as _omitBy } from 'lodash-es'
import { atsId } from '../../wall/models/types'
import { updateStatePerJobApplication } from './shared'

export interface NotesUI {
  body: string
  isPublic: boolean
  submitEnabled: boolean
  mentionedUsers: ExternalUser[]
}

export interface State {
  // typescript complains if I use atsId instead of `string`
  [id: string]: NotesUI
}

const initialSubState: NotesUI = {
  body: '',
  isPublic: false,
  submitEnabled: true,
  mentionedUsers: [],
}

export function reducer(
  state = {},
  action: NotesUIActions | NoteActions,
): State {
  switch (action.type) {
    case NotesUIActionTypes.UpdateNotesUI: {
      const { body, candidate_id, isPublic, mentionedUsers } = action.payload

      const notesUIPayload = _omitBy(
        { body, isPublic, mentionedUsers },
        field => field === undefined,
      )

      return updateStatePerJobApplication(
        state,
        candidate_id,
        notesUIPayload,
        initialSubState,
      )
    }

    case NoteActionTypes.AddNote: {
      return updateStatePerJobApplication(
        state,
        action.payload.candidate_id,
        {
          submitEnabled: false,
        },
        initialSubState,
      )
    }

    // After a note is successfully added, reset the inputs
    case NoteActionTypes.AddNoteSuccess: {
      const candidate_id: atsId = action.payload.candidate_id
      return updateStatePerJobApplication(state, candidate_id, initialSubState)
    }

    default: {
      return state
    }
  }
}
