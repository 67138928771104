import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Component({
  selector: 'twng-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})

export class IframeComponent implements OnInit {
  urlSafe: SafeResourceUrl

  constructor(
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const params = this.route.snapshot.data
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(params.url);
  }
}
