import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import { atsId } from '../models/types'

export interface ConversionRatesRecord {
  active: number
  rejected: number
  hired: number
  passed_through: number
  converted_percent: number
  offers_rejected?: number
  offers_accepted_percent?: number
}
export type JobConversionRates = Record<atsId, ConversionRatesRecord>
export type AllConversionRates = Record<atsId, JobConversionRates>

export interface State {
  conversionRatesByStageByJob: AllConversionRates
}

const initialState: State = {
  conversionRatesByStageByJob: null
}

export function reducer(
  state = initialState,
  action: LoaderActions,
): State {
  switch (action.type) {
    case LoaderActionTypes.UpdateFromServer:
    case LoaderActionTypes.WallSharedSuccess: {
      if (action.payload.job_stage_conversion_stats) {
        return {
          ...state,
          conversionRatesByStageByJob: {
            ...state.conversionRatesByStageByJob,
            ...action.payload.job_stage_conversion_stats
          }
        }
      }
      return state
    }
  }

  return state
}
