import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild,
  Input, OnDestroy, OnInit
} from '@angular/core';
import { PortalLocation, PortalTrackerService, isLocationValid } from '../../services/portal-tracker.service'
import { PortalTrackerTemplateDirective } from '../portal-tracker-template.directive'
import { Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

// This component copies position and size of a portal and renders the children
// inside that space. Portals are "twng-portal" components. The position/size
// is also animated.
@Component({
  selector: 'twng-portal-tracker',
  templateUrl: './portal-tracker.component.html',
  styleUrls: ['./portal-tracker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalTrackerComponent implements OnInit, OnDestroy {

  @Input()
    portalName: string

  @ContentChild(PortalTrackerTemplateDirective) content: PortalTrackerTemplateDirective

  private sub = new Subscription()
  location: PortalLocation
  isValid: boolean

  constructor(
    private portalTracker: PortalTrackerService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if (!this.portalName) {
      throw new Error("portalName property is required for PortalTrackerComponent")
    }
    this.sub.add(
      this.portalTracker.trackPortal$(this.portalName)
        .pipe(debounceTime(10))
        .subscribe((location) => {
          this.location = location
          this.isValid = isLocationValid(location)
          this.cd.markForCheck()
        })
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
}
