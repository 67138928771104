<div ngbDropdown data-html2canvas-ignore placement="bottom-right bottom-left auto" [autoClose]="'outside'"
  #menuDropDown="ngbDropdown" [container]="'body'">

  <button ngbDropdownToggle id="widgetActionsMenu" ngbTooltip="Sort" class="app__dropdown-toggle">
    <i class="fas fa-sort-amount-up-alt"></i>
  </button>

  <div ngbDropdownMenu aria-labelledby="widgetActionsMenu" class="app__dropdown-menu">
    <section class="sort-chart-menu">
      <span class="section__header fs-unmask">Sort Chart</span>
      <div ngbDropdownItem *ngFor="let sortOption of sortOptions">
        <button class="section__row section__row--button" (click)="sort(sortOption.value); menuDropDown.close();">
          <div class="button-flex-wrapper">
            <span class="section__row-label fs-unmask"
              [class.selected-sort-option]="sortOption.value === selectedSortOption">
              {{sortOption.label}}
            </span>
          </div>
        </button>
      </div>

    </section>
  </div>
</div>
