import { isHostedLocally } from '../shared/utils/general-utils'

export const utils = {
  setLocation: (url: string) => {
    window.location.href = url
  },
}

export function outerRedirect(path) {
  const prefix = !isHostedLocally() ? '/' : 'http://localhost:3100/'
  utils.setLocation(`${prefix}${path}`)
}
