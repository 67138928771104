export enum SortingOptionType {
  Name = 'Name',
  Priority = 'Priority',
  EmploymentType = 'Employment Type',
  ColorStatus = 'Color Status',
  DaysOpen = 'Days Open',
  Openings = 'Openings',
  Hires = 'Hires',
  ProjectedHires = 'Projected Hires',
}

export enum SortingOptionDirection {
  Ascending = 'asc',
  Descending = 'desc'
}

export interface SortingOptions {
  type: SortingOptionType,
  direction: SortingOptionDirection,
}
