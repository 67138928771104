import { Observable } from 'rxjs'
import { compact as _compact, join as _join } from 'lodash-es'

import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { Account } from '../../wall/models/account'
import { ActivityDayCategories, getActivityLevelName } from '../../wall/models/activity-day-categories'
import { AppConfigService } from '../../wall/services/app-config.service'
import { Candidate, TYPE_RESUME } from '../../wall/models/candidate'
import { ExternalUser } from '../../wall/models/external-user'
import { InterviewLoaded } from '../../wall/models/interview'
import { Job } from '../../wall/models/job'
import { JobApplication } from '../../wall/models/job-application'
import { Office } from '../../wall/models/office'
import { RatingNumber } from '../../wall/models/rating-number'
import { SegmentService } from '../../core/services/segment.service'
import { TimeAgoPipe } from '../../shared/pipes/time-ago.pipe'
import { UrlService } from '../../wall/services/url.service'
import { User } from '../../wall/models/user'
import { getSource } from '../../shared/utils/job-application'

// Form to transfer a candidate from one application to another
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-card-details-modal',
  styleUrls: ['./card-details-modal.component.scss'],
  templateUrl: './card-details-modal.component.html'
})
export class CardDetailsModalComponent {
  @Input()
    account: Account
  @Input()
    user: User
  @Input()
    job: Job
  @Input()
    jobHiringManagers: ExternalUser[]
  @Input()
    jobOffices: Office[]
  @Input()
    jobApplication: JobApplication
  @Input()
    candidate: Candidate
  @Input()
    activityDayCategories: ActivityDayCategories

  @Input()
    recruiter: ExternalUser
  @Input()
    coordinator: ExternalUser
  @Input()
    creditedTo: ExternalUser

  @Input()
    interviews: InterviewLoaded[]
  @Input()
    otherApplications: JobApplication[]

  @Input()
    showTags = true

  isDemo = window.twng_demo
  activityLevel$: Observable<string>

  atsName: string
  ratingNumbers: RatingNumber[] = [1, 2, 3, 4, 5]

  constructor(
    public activeModal: NgbActiveModal,
    public urlService: UrlService,
    public appConfig: AppConfigService,
    public timeAgoPipe: TimeAgoPipe,
    private segmentService: SegmentService,
  ) {
    this.atsName = this.appConfig.atsName()
  }

  activityLevel() {
    return getActivityLevelName(this.jobApplication.activity_level)
  }

  resumes() {
    return this.candidate.attachments
      ? this.candidate.attachments.filter(a => a.type === TYPE_RESUME)
      : []
  }

  candidateEmails(): string[] {
    return this.candidate.email_addresses ? this.candidate.email_addresses : []
  }

  jobOfficeNames(): string {
    return this.jobOffices ?
      _join(this.jobOffices.map(office => office.name), ', ') : ""
  }

  jobName(): string {
    return _join(_compact([this.job.name, this.jobOfficeNames()]), ' - ')
  }

  hiringManagers(): string[] {
    return this.jobHiringManagers ? this.jobHiringManagers.map(hiringManager => hiringManager.name) : []
  }

  showActionsTab() {
    return (
      this.user &&
      !this.user.read_only &&
      (this.appConfig.canTransferCandidate() ||
        this.appConfig.canRejectCandidate())
    )
  }

  sourceString(jobApplication) {
    return getSource(jobApplication)
  }

  timeAgoString(timeString: string): string {
    return this.timeAgoPipe
      .transform(timeString, this.jobApplication.last_activity_days_ago, this.appConfig.showTimeAgo())
  }

  candidateTags(): string[] {
    if (this.candidate && this.candidate.tags) {
      return [...this.candidate.tags].sort()
    }
  }

  trackViewInAts() {
    this.segmentService.track('open greenhouse from large card')
  }
}
