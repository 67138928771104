import { ChartFilters } from '../../models/chart-filters'
import { ChartsInitialConfig } from '../../../core/actions/loader.actions'
import { Component, Input, OnInit } from '@angular/core'
import { DashboardChart } from '../../models/dashboard-chart'
import { Grid } from '../../models/gridster/grid'
import { LINE_CHART_TYPES } from '../line-chart.component'
import { customBoxDataType } from '../stats.component'

@Component({
  selector: 'twng-chart-wrapper',
  templateUrl: './chart-wrapper.component.html',
  styleUrls: []
})
export class ChartWrapperComponent implements OnInit {

  @Input()
    grid: Grid

  @Input()
    chart: DashboardChart

  @Input()
    chartFiltersOverride: ChartFilters

  @Input()
    chartFiltersReadOnly: boolean

  @Input()
    editingGridster: boolean

  @Input()
    chartFiltersEnabled = true

  @Input()
    gridItemType: 'gridster' | 'css' = "css"

  @Input()
    chartInitConfig: ChartsInitialConfig

  @Input()
    overrideFiltersSet: boolean

  lineChartTypes = LINE_CHART_TYPES
  CUSTOM_BOX_DATA_TYPE = customBoxDataType

  get chartFilters(): ChartFilters {
    return this.chartFiltersOverride ?? this.chart.filters
  }

  get isInCustomDashboard() {
    return this.gridItemType === 'gridster'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
