import { Observable } from 'rxjs'
import { format, formatDistanceToNow } from 'date-fns'
import { map } from 'rxjs/operators'

import {
  ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges
} from '@angular/core'
import { Dictionary } from '@ngrx/entity'
import { Store } from '@ngrx/store'

import * as fromWall from '../../../wall/reducers'
import { ActivityDayCategories, getActivityLevelName } from '../../models/activity-day-categories'
import { AppConfigService } from '../../services/app-config.service'
import { Candidate } from '../../models/candidate'
import { ExternalUser } from '../../models/external-user'

import { InterviewWithJobStage } from '../../models/interview'
import { JOB_APPLICATION_STATUS_HIRED, JobApplication } from '../../models/job-application'
import { JobStage } from '../../models/job-stage'
import { SegmentService } from '../../../core/services/segment.service'
import { UrlService } from '../../services/url.service'
import { interviewsHappened, interviewsScheduled } from '../../../shared/utils/interviews'

@Component({
  selector: 'twng-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./card.component.scss'],
  templateUrl: './card.component.html'
})
export class CardComponent implements OnInit, OnChanges {
  @Input()
    jobStage: JobStage
  @Input()
    jobApplication: JobApplication
  // candidate may be undefined
  @Input()
    candidate: Candidate | undefined
  @Input()
    activityDayCategories: ActivityDayCategories
  @Input()
    interviews: InterviewWithJobStage[] // NOTE: these are sorted interviews for the current stage only
  @Input()
    sourceTypeIconMappings

  recruiter$: Observable<ExternalUser>
  showRecruiterOnSmallCard$: Observable<boolean>

  isHiredCandidate = false
  hiredDate: string

  constructor(
    public appConfig: AppConfigService,
    public urlService: UrlService,
    private segmentService: SegmentService,
    private store: Store<fromWall.State>,
  ) { }

  interviewsForCurrentStage: InterviewWithJobStage[]
  atsName: string

  ngOnInit() {
    this.interviewsForCurrentStage = this.interviews.filter(
      int => int.job_stage_id === this.jobStage.id,
    )
    this.atsName = this.appConfig.atsName()
    this.recruiter$ = this.store.select(fromWall.selectExternalUserEntities).pipe(
      map((usersDic: Dictionary<ExternalUser>) => usersDic[this.candidate.recruiter_id]),
    )
    this.showRecruiterOnSmallCard$ = this.appConfig.getConfig$().pipe(
      map(appConfig => appConfig.feature_flags.show_recruiter_on_small_card)
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.jobApplication) {
      this.isHiredCandidate = this.jobApplication?.status === JOB_APPLICATION_STATUS_HIRED
      const formattedHiredDate = format(new Date(this.jobApplication.hired_at), 'MMM dd, yyyy')
      this.hiredDate = `Hired: ${formattedHiredDate}`
    }
  }

  activityLevel() {
    return getActivityLevelName(this.jobApplication.activity_level)
  }

  showCalendar() {
    return this.interviewsForCurrentStage.length > 0
  }

  nearestFutureInterview() {
    const now = new Date().getTime()
    // interviews are sorted by start_time, so first result is the nearest
    return this.interviews.find(
      int => new Date(int.start_time).getTime() > now,
    )
  }

  calendarClasses() {
    return {
      fas: true,
      'fa-fw': true,
      'interview-scheduled': true,
      // no check: one or more interview scheduled for this candidate that hasn't happened yet
      'fa-calendar': this.someInterviewsScheduled(),
      // icon checked: interviews have happened
      'fa-calendar-check': this.interviewsHappened(),
    }
  }

  calendarTooltip(): string {
    if (this.interviewsForCurrentStage.length === 0) {
      return ''
    } else if (this.interviewsHappened()) {
      return 'Interviews Complete'
    } else if (this.someInterviewsScheduled()) {
      return 'Interviews Scheduled'
    }
  }

  someInterviewsScheduled(): boolean {
    return interviewsScheduled(this.interviewsForCurrentStage)
  }

  interviewsHappened() {
    return interviewsHappened(this.interviewsForCurrentStage)
  }

  sourceTypeVisible(sourceType: string): boolean {
    return (
      !!this.sourceTypeIconMappings && this.sourceTypeIconMappings[sourceType]
    )
  }

  timeAgo(time: string, lastActivityDaysAgo: number): string {
    const result = formatDistanceToNow(new Date(time))
      .replace('about ', '')
      .replace('less than', '<')
    if (result.includes('day')) {
      if (lastActivityDaysAgo === 1) {
        return '1 day'
      }
      return `${lastActivityDaysAgo} days`
    }
    return result
  }

  lastActivityAt(): string {
    return new Date(this.jobApplication.last_activity_at).toString().replace(/GMT-\d+/, '')
  }

  trackViewInAts() {
    this.segmentService.track('open greenhouse from small card')
  }
}
