import { Observable, of } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { catchError, concatMap, first, map, mergeMap, switchMap, tap } from 'rxjs/operators'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'

import * as fromWall from '../../wall/reducers'
import { ActionCableService } from '../../wall/services/action-cable.service'
import { AnalyticsState } from '../reducers/analytics.reducer'
import {
  CreateDashboardTab, CreateDashboardTabFailure, CreateDashboardTabSuccess, DashboardActionTypes,
  DashboardFilterActions, DashboardRemoveChart, DashboardRemoveChartFailure,
  DashboardRemoveChartSuccess, DashboardSaveSharedTab, DashboardSaveSharedTabFailure,
  DashboardSaveSharedTabResponsePayload, DashboardSaveSharedTabSuccess, DashboardSendChartToCustom,
  DashboardSendChartToCustomFailure, DashboardSendChartToCustomSuccess, DashboardSetFilters, DashboardSetFiltersError,
  RemoveDashboardTab, RemoveDashboardTabFailure, RemoveDashboardTabSuccess, UpdateDashboardChart,
  UpdateDashboardChartFailure, UpdateDashboardChartSuccess, UpdateDashboardTab,
  UpdateDashboardTabFailure, UpdateDashboardTabSuccess
} from '../actions/filters.actions'
import { DashboardChart } from '../models/dashboard-chart'
import { DashboardLoadSuccess, FetchWidgetLibraryTab } from '../../core/actions/loader.actions'
import { DashboardTab } from '../models/dashboard-tab'
import { DashboardTypes } from '../components/dashboard-types'
import { HideSpinner } from '../actions/spinner.action'
import { apiHost, getHttpPostOptions } from '../../core/http-options'
import { cloneDeep } from 'lodash-es'
import { selectDashboardTabTotal } from '../reducers'
import { selectImmediatelySync } from '../../shared/utils/store.utils'

@Injectable()
export class ChartFilterEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private toastr: ToastrService,
    private store: Store<fromWall.State>,
    private router: Router,
    private actionCable: ActionCableService,
  ) { }

  saveSharedTab$ = createEffect(() => this.actions$.pipe(
    ofType<DashboardSaveSharedTab>(DashboardActionTypes.DashboardSaveSharedTab),
    switchMap(action => this.http.post<DashboardSaveSharedTabResponsePayload>(
      `${apiHost}/twng/dashboard/tabs/${action.payload.id}/${action.payload.sharableToken}/save.json`,
      { params: { name: action.payload.name, editable: action.payload.viewEditable } },
      getHttpPostOptions(),
    ).pipe(
      map(response => new DashboardSaveSharedTabSuccess(response)),
      catchError(() => of(new DashboardSaveSharedTabFailure())),
    ))
  ))

  setDashboardFilters$: Observable<DashboardFilterActions | DashboardLoadSuccess | HideSpinner> =
    createEffect(() => this.actions$.pipe(
      ofType<DashboardSetFilters>(DashboardActionTypes.DashboardSetFilters),
      switchMap(action => {
        const urls: Record<DashboardTypes, string> = {
          [DashboardTypes.CompanyDashboard]: `${apiHost}/twng/dashboard/set_filters.json`,
          [DashboardTypes.MyDashboard]: `${apiHost}/twng/dashboard/mine/set_filters.json`,
          [DashboardTypes.CustomDashboard]: `${apiHost}/twng/dashboard/set_custom_dashboard_filters.json`,
        }
        const url = urls[action.payload.panel]

        const filters = cloneDeep(action.payload.filters)
        if (filters && filters.date_mode) {
          if (!filters.start_date) {
            filters.start_date = null
          }
          if (!filters.end_date) {
            filters.end_date = null
          }
        }

        const call = filters ?
          this.http
            .put<Partial<AnalyticsState>>(
            url, filters,
            getHttpPostOptions(),
          ) :
          this.http.delete<Partial<AnalyticsState>>(url, getHttpPostOptions())
        return call
          .pipe(
            map(
              analyticsPayload => {
                this.fetchWidgetLibraryOnFilter()
                return new DashboardLoadSuccess({ analytics: analyticsPayload })
              }
            ),
            tap(() => {
              this.store.dispatch(new HideSpinner())
            }),
            catchError(errorResponse => {
              this.showErrorMessage(errorResponse)
              return of(new DashboardSetFiltersError({ panel: action.payload.panel }))
            }),
          )
      }),
    ))

  // When we are done updating filters, if we are on a widget library page, we need to re-fetch that page's data.
  fetchWidgetLibraryOnFilter() {
    const route = this.router.routerState
    const navParts = route.snapshot.url.split('/')
    const app = navParts[1]
    const mainNav = navParts[2]
    const subNav = navParts[3]

    console.log('router', app, mainNav, subNav)

    if (app === 'dashboard' && (mainNav === 'widget-library')) {
      this.store.dispatch(new FetchWidgetLibraryTab({ tabId: subNav }))
    }
  }

  sendChartToCustomDash$ = createEffect(() => this.actions$.pipe(
    ofType<DashboardSendChartToCustom>(DashboardActionTypes.DashboardSendChartToCustom),
    mergeMap(action => {
      const { dashboardTab, dataSource, chartType, chartName,
        dataSourceParameters, filters, isTitle, extra_params } = action.payload

      return this.actionCable.sessionString$.pipe(
        // wait for action cable to be connected before issuing the request
        first(sessionString => !!sessionString),
        concatMap(sessionString =>
          this.http.post<{ dashboard_chart: DashboardChart, tab?: DashboardTab }>(
            `${apiHost}/twng/dashboard/charts?user_session_string=${sessionString}`,
            {
              dashboard_tab_id: dashboardTab.id,
              data_source: dataSource,
              data_source_parameters: dataSourceParameters,
              chart_type: chartType,
              chart_name: chartName,
              chart_filters: filters,
              is_custom_title: isTitle,
              extra_params: extra_params || {}
            },
            getHttpPostOptions(),
          ).pipe(
            map(payload => {

              this.toastr.success(`Sent ${chartName} to ${dashboardTab.name ? dashboardTab.name : 'an analytics dashboard'}`)
              return new DashboardSendChartToCustomSuccess(payload)
            }),
            catchError(errorResponse => {
              this.showErrorMessage(errorResponse)
              return of(new DashboardSendChartToCustomFailure())
            }),
          )
        )
      )
    }
    )
  ))

  removeChartFromDashboard$ = createEffect(() => this.actions$.pipe(
    ofType<DashboardRemoveChart>(DashboardActionTypes.DashboardRemoveChart),
    mergeMap(action => {
      const { dashboard_chart } = action.payload
      const url = `${apiHost}/twng/dashboard/charts/${dashboard_chart.id}?dashboard_tab_id=${this.getCurrentDashboardTabId()}`

      if (window.twng_demo) {
        return of(new DashboardRemoveChartSuccess({ dashboard_chart }))
      }

      return this.http.delete(
        url,
        getHttpPostOptions(),
      ).pipe(
        map(
          () => {
            this.toastr.success(`Removed ${dashboard_chart.name} from Dashboard`)
            return new DashboardRemoveChartSuccess({ dashboard_chart })
          }
        ),
        catchError(errorResponse => {
          this.showErrorMessage(errorResponse)
          return of(new DashboardRemoveChartFailure({ dashboard_chart }))
        }),
      )
    }),
  ))

  updateDashboardChart$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateDashboardChart>(DashboardActionTypes.UpdateDashboardChart),
    mergeMap(action => {
      const { dashboard_chart, filters } = action.payload

      if (window.twng_demo) {
        return of(new UpdateDashboardChartSuccess({ dashboard_chart }))
      }

      return this.actionCable.sessionString$.pipe(first(v => !!v), concatMap(
        sessionString => this.http.patch<{ dashboard_chart: DashboardChart }>(
          `${apiHost}/twng/dashboard/charts/${dashboard_chart.id}?user_session_string=${sessionString}`,
          {
            data_source: dashboard_chart.data_source,
            chart_type: dashboard_chart.chart_type,
            chart_name: dashboard_chart.name,
            position_x: dashboard_chart.position_x,
            position_y: dashboard_chart.position_y,
            width: dashboard_chart.width,
            height: dashboard_chart.height,
            chart_filters: filters,
            custom_value: dashboard_chart.custom_value,
            dashboard_tab_id: this.getCurrentDashboardTabId()
          },
          getHttpPostOptions(),
        ).pipe(
          map(
            payload => {
              this.toastr.success(`Updated Chart: ${dashboard_chart.name}`)
              return new UpdateDashboardChartSuccess({ dashboard_chart: payload.dashboard_chart })
            }
          ),
          catchError(errorResponse => {
            this.showErrorMessage(errorResponse)
            return of(new UpdateDashboardChartFailure({ dashboard_chart }))
          }),
        )
      ))
    }),
  ))

  createDashboardTab$ = createEffect(() => this.actions$.pipe(
    ofType<CreateDashboardTab>(DashboardActionTypes.CreateDashboardTab),
    mergeMap(action => {
      const url = `${apiHost}/twng/dashboard/tabs/`

      if (window.twng_demo) {
        const position = selectImmediatelySync(this.store, selectDashboardTabTotal) + 1
        return of(new CreateDashboardTabSuccess(
          { ...action.payload, position, id: 7, default_template: false, sharable_token: '' }
        ))
      }

      return this.http.post<{ status: string, dashboard_tab: DashboardTab }>(
        url,
        action.payload,
        getHttpPostOptions(),
      ).pipe(
        map(
          (payload) => {
            this.toastr.success(`Dashboard Tab ${action.payload.name} created`)
            return new CreateDashboardTabSuccess(payload.dashboard_tab)
          }
        ),
        catchError(errorResponse => {
          this.showErrorMessage(errorResponse)
          return of(new CreateDashboardTabFailure())
        }),
      )
    }),
  ))

  onCustomDashboardTabSuccess: Observable<CreateDashboardTabSuccess> = createEffect(() => this.actions$.pipe(
    ofType<CreateDashboardTabSuccess>(DashboardActionTypes.CreateDashboardTabSuccess),
    tap(action => this.router.navigateByUrl(`/dashboard/dashboards/${action.payload.id}`)),
  ), { dispatch: false })

  updateDashboardTab$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateDashboardTab>(DashboardActionTypes.UpdateDashboardTab),
    mergeMap(action => {
      const updatedTab: DashboardTab = action.payload.updatedTab

      if (window.twng_demo) {
        return of(new UpdateDashboardTabSuccess({ dashboard_tab: updatedTab }))
      }

      const url = `${apiHost}/twng/dashboard/tabs/${updatedTab.id}`

      //   return of(1).pipe(delay(1000), mergeMap(throwError(new Error('oops!')))) // Simulate Error
      return this.http.patch<{ status: string, dashboard_tab: DashboardTab }>(
        url,
        {
          name: updatedTab.name
        },
        getHttpPostOptions()
      ).pipe(
        map(
          (dashboardTabResponse) => {
            this.toastr.success(`Updated ${dashboardTabResponse.dashboard_tab.name}`)
            return new UpdateDashboardTabSuccess({ dashboard_tab: dashboardTabResponse.dashboard_tab })
          }
        ),

        catchError(errorResponse => {
          this.showErrorMessage(errorResponse)
          return of(new UpdateDashboardTabFailure({ oldTab: action.payload.actualTab }))
        }),
      )
    }),
  ))

  removeDashboardTab$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveDashboardTab>(DashboardActionTypes.RemoveDashboardTab),
    mergeMap(action => {
      const dashboardTab: DashboardTab = action.payload.tab
      const url = `${apiHost}/twng/dashboard/tabs/${dashboardTab.id}`

      if (window.twng_demo) {
        this.toastr.success(`Removed ${dashboardTab.name}`)
        return of(new RemoveDashboardTabSuccess())
      }

      return this.http.delete<DashboardTab>(
        url,
        getHttpPostOptions(),
      ).pipe(
        map(
          () => {
            this.toastr.success(`Removed ${dashboardTab.name}`)
            this.router.navigate(["/dashboard/dashboards"])
            return new RemoveDashboardTabSuccess()
          }
        ),

        catchError(errorResponse => {
          this.showErrorMessage(errorResponse)
          return of(new RemoveDashboardTabFailure({ oldTab: action.payload.tab }))
        }),
      )
    }),
  ))

  getCurrentDashboardTabId() {
    const currentUrl = window.location.href
    const quinPathRegex = /\/(dashboard\/quin$)/

    if (currentUrl.match(quinPathRegex)) {
      return 'quin'
    } else {
      const dashboardIdRegex = /\/(dashboards\/\d+)/ // Match "dashboards/", followed by digits
      const dashboardAndId = currentUrl.match(dashboardIdRegex)[1] // Current Tab ID, might differ from the chart's tab ID if it's editable tab
      const idRegex = /\d+/ // Match one or more digits
      return dashboardAndId.match(idRegex)[0]
    }
  }

  showErrorMessage(errorResponse) {
    if (errorResponse.status === 422 && errorResponse.error.message) {
      this.toastr.error(`Error: ${errorResponse.error.message}`)
    } else {
      this.toastr.error("An error occurred")
    }
  }
}
