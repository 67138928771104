import * as fromWall from '../reducers'
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit
} from '@angular/core'
import { JobFilters } from '../reducers/layout.reducer'
import { Store, select } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { UpdateAllJobsFilters } from '../actions/layout.actions'

@Component({
  selector: 'twng-job-name-filter',
  template: `
    <div class="job-name-filter">
      <twng-name-filter
        placeholder="Job Name / Job ID / Requisition ID "
        [initialQuery]="currentJobNameQuery"
        [updatedQuery]="updatedQuery"
        (searchedQueryChange)="updateJobQuery($event)"
      ></twng-name-filter>
    </div>
  `,
  styles: [
    `
      :host {
        margin-right: 5px;
      }

      .job-name-filter {
        display: inline-flex;
        min-width: 270px;
        width: 100%;
        margin-right: 5px;
        margin-top: 5px;
      }
    `,
  ],
})
export class JobNameFilterComponent implements OnInit, OnDestroy {
  jobFilters: JobFilters
  filterSub: Subscription
  updatedQuery = new EventEmitter<string>()
  currentJobNameQuery = ''

  constructor(private store: Store<fromWall.State>) {}

  ngOnInit(): void {
    this.filterSub = this.store
      .pipe(select(fromWall.selectAllJobsFilters))
      .subscribe(jobFilters => {
        this.jobFilters = jobFilters
        this.updatedQuery.emit(jobFilters.matchingJobName)
        this.currentJobNameQuery = jobFilters.matchingJobName
      })
  }

  ngOnDestroy(): void {
    this.filterSub.unsubscribe()
  }

  updateJobQuery(query: string): void {
    this.store.dispatch(
      new UpdateAllJobsFilters({
        ...this.jobFilters,
        matchingJobName: query,
      }),
    )
  }
}
