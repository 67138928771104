import { JobApplication } from '../../wall/models/job-application';

export const getSource = (jobApplication: JobApplication): string => {
  if (jobApplication.source_type === jobApplication.source) {
    return jobApplication.source
  }
  if (jobApplication.source_type && jobApplication.source) {
    return `${jobApplication.source_type}: ${jobApplication.source}`
  }
  if (jobApplication.source_type) {
    return jobApplication.source_type
  }
  return jobApplication.source
}
