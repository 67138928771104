import { Account } from '../../wall/models/account'
import { AppConfig } from '../../wall/models/app-config'
import { LoaderActionTypes, LoaderActions } from '../actions/loader.actions'
import { User } from '../../wall/models/user'

export interface AccountState {
  account: Account
  user: User
  config: AppConfig
  wallLoadCompleted: boolean
  dashboardLoadCompleted: boolean
}

const initialState: AccountState = {
  account: null,
  user: null,
  config: null,
  wallLoadCompleted: false,
  dashboardLoadCompleted: false,
}

export function reducer(
  state: AccountState = initialState,
  action: LoaderActions,
): AccountState {
  switch (action.type) {
    case LoaderActionTypes.LoadAccount: {
      return {
        ...state,
        account: action.payload.account,
        user: action.payload.user,
        config: action.payload.app_config,
      }
    }
    case LoaderActionTypes.LoadSharedDataSuccess: {
      const appConfig = action.payload.app_config || state.config
      // if account is in the payload, set it and user; otherwise, don't change them
      if (action.payload.account) {
        return {
          ...state,
          account: action.payload.account,
          user: action.payload.user,
          config: appConfig,
        }
      } else {
        return {
          ...state,
          config: appConfig,
        }
      }
    }
    case LoaderActionTypes.WallSharedSuccess: {
      return {
        ...state,
        wallLoadCompleted: true
      }
    }
    case LoaderActionTypes.DashboardLoadSuccess: {
      return {
        ...state,
        dashboardLoadCompleted: true,
      }
    }
    case LoaderActionTypes.UpdateSharedUser: {
      return {
        ...state,
        user: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
