<twng-single-chart-filter-template [countBadgeValue]="countBadgeValue" [tooltipText]="tooltipText" 
  *ngIf="dataLoaded" (applyButtonClicked)="applyFilters()" [iconClass]="iconClass" [showAsIcon]="showAsIcon"
  [chartFiltersReadOnly]="chartFiltersReadOnly" (filterClicked)="clickOpenFilters(managers)">
  <ng-container title-display>
    <div class="value-list" *ngIf="filterValuesCompactNames.length" [innerHTML]="includeExcludeText">
    </div>
    <div class="value-list empty-value-list" *ngIf="filterValuesCompactNames.length === 0">
      {{ filterTitle }}
    </div>
  </ng-container>
</twng-single-chart-filter-template>
