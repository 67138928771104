import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-gridster-item-overlay',
  template: `
    <span class="editing-handle handle--top-left"></span>
    <span class="editing-handle handle--top-right"></span>
    <span class="editing-handle handle--bottom-left"></span>
    <span class="editing-handle handle--bottom-right"></span>
    <div class="hover-overlay"></div>
    <div class="draggable-icon">
      <i class="fas fa-arrows-alt"></i>
    </div>
  `,
  styleUrls: ['./gridster-item-overlay.component.scss']
})
export class GridsterItemOverlayComponent {
}
