<div class="job-action-btns tw-flex tw-items-start" data-html2canvas-ignore>
  <div class="job-action-btn-group" role="group" aria-label="Select job view">
    <button ngbTooltip="Copy link to clipboard" class="job-action-btn tw-flex tw-items-center tw-px-3 tw-py-2"
            (click)="copyLinkToClipboard()">
      <i class="fa-solid fa-link"></i>
    </button>

    <button ngbTooltip="Edit Job Status" class="job-action-btn tw-flex tw-items-center tw-px-3 tw-py-2 tw-text-xs"
            *ngIf="canViewJobStatus"
            (click)="editJobStatus.emit()">
      <i class="fa-solid fa-pencil tw-pr-1.5"></i> Status
    </button>

    <a target="_blank" rel="noopener noreferrer" class="button--ats" [href]="greenHouseUrl"
       ngbTooltip="View in {{ atsName }}" placement="top-right">
      <img src="{{ atsIconUrl }}" width="32" height="32" />
    </a>
  </div>

  <div class="btn-group" role="group" aria-label="Select job view">
    <button type="button" ngbTooltip="Cards" class="btn btn-primary card-menu-button tw-flex tw-items-center tw-px-3 tw-py-2"
            [class.active]="!jobCollapsed && !showAnalytics" (click)="onSelectCards()">
      <i class="fas fa-table"></i>
    </button>

    <button [class.active]="!jobCollapsed && showAnalytics" type="button"
            ngbTooltip="Analytics" class="btn btn-primary card-menu-button sheen tw-flex tw-items-center tw-px-3 tw-py-2"
            (click)="onSelectAnalytics()">
      <i class="fas fa-chart-area"></i>
    </button>

    <button type="button" ngbTooltip="Minimize" class="btn btn-primary card-menu-button tw-flex tw-items-center tw-px-3 tw-py-2"
            [class.active]="jobCollapsed" (click)="onCollapse() ">
      <i class="fas fa-minus"></i>
    </button>
  </div>
</div>
