import { Actions, createEffect, ofType } from "@ngrx/effects"
import {
  FetchAllProjectedHires,
  FetchAllProjectedHiresFailure,
  FetchAllProjectedHiresSuccess,
  ProjectedHiresActionTypes,
  UpdateHiringPredictions,
  UpdateHiringPredictionsFailure,
  UpdateHiringPredictionsSuccess
} from "../actions/projected-hires.actions"
import { apiHost, getHttpPostOptions, httpGetOptions } from "../../core/http-options"
import { buffer, catchError, concatMap, debounceTime, delay, map, retry } from "rxjs/operators"

import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ProjectedHiresResponse } from "../models/projected-hires"
import { of } from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class ProjectedHiresEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions,
  ) { }

  hiringPredictions$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateHiringPredictions>(ProjectedHiresActionTypes.UpdateHiringPredictions),
    concatMap((action: UpdateHiringPredictions) =>
      this.http.put<ProjectedHiresResponse>(
        `${apiHost}/twng/jobs/${action.payload.job.id}/hiring-predictions`,
        { hiring_predictions: action.payload.hiringPredictions },
        getHttpPostOptions()
      ).pipe(
        retry(1),
        map(response => new UpdateHiringPredictionsSuccess(response)),
        catchError((e) => {
          console.error(e)
          return of(new UpdateHiringPredictionsFailure())
        })
      )
    )
  ))

  // We use buffer here with debounce time to concat job ids and make one batch request instead one request per job id
  fetchAllProjectedHires$ = createEffect(() => this.actions$.pipe(
    ofType<FetchAllProjectedHires>(ProjectedHiresActionTypes.FetchAllProjectedHires),
    buffer(this.actions$.pipe(
      ofType<FetchAllProjectedHires>(ProjectedHiresActionTypes.FetchAllProjectedHires),
      debounceTime(50)
    )),
    concatMap(() =>
      this.http.get<ProjectedHiresResponse>(`${apiHost}/twng/hires/predicted.json`, httpGetOptions).pipe(
        retry(1),
        delay(100),
        map(response => new FetchAllProjectedHiresSuccess(response)),
        catchError(() => of(new FetchAllProjectedHiresFailure())),
      )
    )
  ))
}
