import { Department } from '../../wall/models/department'
import { ExecutiveDashboardState } from '../../wall/reducers/executive-dashboard.reducer'
import { Job } from '../../wall/models/job'
import { JobIdToStatusUpdate } from '../../wall/services/cache.service'
import { Office } from '../../wall/models/office'
import { SingleJobNamePayload } from '../../core/actions/loader.actions'
import { joinExistingStrings, present } from './general-utils'
import { sortJobUpdatesByCreatedAt } from './sorting/job-updates'

interface MinimanlJobDataForName {
  requisition_id: string | undefined
  name: string
  id: number | string
}

export function jobNameWithIds(job: MinimanlJobDataForName) {
  let idSuffix = job.requisition_id ? ("Req: " + job.requisition_id) : ("Job: " + job.id.toString())
  if (idSuffix) {
    idSuffix = "(" + idSuffix + ")"
  }
  return joinExistingStrings([job.name, idSuffix], ' ')
}

export function jobWithNameWithIds(job: SingleJobNamePayload) {
  const ret: SingleJobNamePayload = {
    ...job,
    name: jobNameWithIds(job),
  }
  return ret
}

export default function jobNameString(
  job: Job | SingleJobNamePayload ,
  departments: Department[],
  offices: Office[]
): string {
  const officeString = offices ? offices.filter(o => o).map(office => office.name).join(', ') : null
  const departmentString = departments ? departments.filter(d => d).map(department => department.name) : null
  const departmentAndOffices = [departmentString, officeString]
    .filter(s => present(s))
    .join(' – ')

  const departmentAndOfficesString = present(departmentAndOffices) ? `: ${departmentAndOffices}` : ''

  return `${jobNameWithIds(job)}${departmentAndOfficesString}`
}

export const mapJobIdsToStatusUpdates = (jobStatusUpdates: ExecutiveDashboardState): JobIdToStatusUpdate => {
  const sortedJobUpdates = [...jobStatusUpdates.job_status_updates].sort(sortJobUpdatesByCreatedAt)
  const jobUpdatesMap: JobIdToStatusUpdate = {}
  for (const update of sortedJobUpdates) {
    const jobId = update.job_id
    jobUpdatesMap[jobId] = jobUpdatesMap[jobId] || []
    jobUpdatesMap[jobId].push(update)
  }
  return jobUpdatesMap
}

export const daysOpen = (j: Job): number => {
  if (!j || !j.opened_at) {
    return null
  }
  const start_calc = new Date(j.opened_at)
  // if we don't have closed_at, use today date
  const end_calc = j.closed_at ? new Date(j.closed_at) : new Date()
  return Math.round((end_calc.getTime() - start_calc.getTime()) / (1000 * 3600 * 24))
}
