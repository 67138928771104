<div class="StageCategory">
  <div class="card category" appDraggable placement="custom-x" [model]="category" mainClass="StageCategory">
    <div class="card-body">
      <div class="card-title category__header">
        <span class="category__name" *ngIf="!editingName">{{category.name}}</span>
        <twng-editable-input *ngIf="editingName" [initialValue]="category.name"
          (valueSubmitted)="setCategoryName($event)">
        </twng-editable-input>

        <button class="actions-button" (click)="editName();">
          <i class="fas fa-edit"></i>
        </button>
        <button class="actions-button" (click)="emitRemoveCategory();">
          <i class="fas fa-trash"></i>
        </button>
        <span class="category__grip">
          <i class="fas fa-grip-vertical"></i>
        </span>
      </div>
      <twng-drop-container (dropping)="updateStageCategory($event)">
        <twng-job-stage-name *ngFor="let jobStageName of category.job_stage_names" [jobStageName]="jobStageName"
          [jobStageCategoryId]="category.id">
        </twng-job-stage-name>
        <div *ngIf="!category.job_stage_names.length" class="drop_categories">
          Drop Stages here
        </div>
      </twng-drop-container>
    </div>
  </div>
</div>