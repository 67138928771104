import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxChartsRecord } from '../reducers/analytics.reducer';
import { SortChartOption } from '../models/sort-chart-option';
import { isArray, reverse, some } from 'lodash-es';

// on reverseDirection = true, we still sort a parent before a child, so [1,2] will always precede [1,2,3]
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cmp = (a: string | number | any[], b: string | number | any[], reverseDirection = false) => {
  if (isArray(a) && isArray(b)) {
    for (let i = 0; i < Math.min(a.length, b.length); i++) {
      if (a[i] !== b[i]) {
        return reverseDirection ? cmp(b[i], a[i]) : cmp(a[i], b[i]);
      }
    }
    return cmp(a.length, b.length);
  } else if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
};

@Directive({
  selector: "[twngSortChart]",
  exportAs: "sortChart",
})
export class SortChartDirective implements OnChanges {
  @Input()
    sortOption: SortChartOption = SortChartOption.Default;

  /**
   * Original chart data from the Store/Backend
   */
  @Input()
    chartData: NgxChartsRecord[];

  /**
   * Sorted Chart Data to render the chart
   */
  sortedData: NgxChartsRecord[];

  ngOnChanges(changes: SimpleChanges) {
    const currentChartData = changes.chartData?.currentValue
    const previousChartData = changes.chartData?.previousValue

    if (changes.sortOption || JSON.stringify(currentChartData) !== JSON.stringify(previousChartData)) {
      if (this.hasHierarchyData("department")) {
        if(this.sortOption === undefined || this.sortOption === SortChartOption.Default) {
          this.sortedData = this.sortByValueHierarchy(
            this.chartData,
            "desc",
            "department"
          );
          return;
        }
      }

      if (this.hasHierarchyData("office")) {
        if (this.sortOption === undefined || this.sortOption === SortChartOption.Default) {
          this.sortedData = this.sortByValueHierarchy(
            this.chartData,
            "desc",
            "office"
          );
          return;
        }
      }

      this.sortData();
    }
  }

  hasHierarchyData(idName: string) {
    return some(this.chartData, data => data[`${idName}_id_path`])
  }

  sortData() {
    switch (this.sortOption) {
      case SortChartOption.ByNameAsc:
        this.sortedData = this.sortBy(this.chartData, "name");
        break;
      case SortChartOption.ByNameDesc:
        this.sortedData = this.sortBy(this.chartData, "name").reverse();
        break;
      case SortChartOption.ByValueAsc:
        this.sortedData = this.sortBy(this.chartData, "value");
        break;
      case SortChartOption.ByValueDesc:
        this.sortedData = this.sortBy(this.chartData, "value").reverse();
        break;
      case SortChartOption.ByDepartmentHierarchyValueAsc:
        this.sortedData = this.sortByValueHierarchy(
          this.chartData,
          "asc",
          "department"
        );
        break;
      case SortChartOption.ByDepartmentHierarchyValueDesc:
        this.sortedData = this.sortByValueHierarchy(
          this.chartData,
          "desc",
          "department"
        );
        break;
      case SortChartOption.ByOfficeHierarchyValueAsc:
        this.sortedData = this.sortByValueHierarchy(
          this.chartData,
          "asc",
          "office"
        );
        break;
      case SortChartOption.ByOfficeHierarchyValueDesc:
        this.sortedData = this.sortByValueHierarchy(
          this.chartData,
          "desc",
          "office"
        );
        break;
      case SortChartOption.Default:
      default:
        this.sortedData = this.chartData;
        break;
    }
  }

  /**
   * Sorts in Ascending mode an array using the "propToCmp" value.
   * Returns a new array. Does not modify input array.
   *
   * @param data
   * @param propToCmp
   */
  sortBy(
    data: NgxChartsRecord[],
    propToCmp: "name" | "value"
  ): NgxChartsRecord[] {
    return [...data].sort((obj1, obj2) => {
      if (propToCmp === 'name') {
        return cmp(obj1[propToCmp].toLocaleLowerCase(), obj2[propToCmp].toLocaleLowerCase())
      }

      if (obj1.series) {
        let valueSum1 = 0
        let valueSum2 = 0
        obj1.series.forEach(element => valueSum1 += element.value)
        obj2.series.forEach(element => valueSum2 += element.value)
        return cmp(valueSum1, valueSum2)
      }

      return cmp(obj1[propToCmp], obj2[propToCmp])
    });
  }

  sortByValueHierarchy(data: NgxChartsRecord[], dir: "asc" | "desc", idName: string) {
    const pathId = `${idName}_id_path`
    const valueAndNameById: { [id: string]: [number, string] } = data.reduce(
      (acc, record) => {
        if (record[pathId]) {
          let valueSumRecord = record.value || 0

          if (record.series) {
            record.series.forEach(element => valueSumRecord += element.value)
          }
          acc[record[pathId][0]] = [valueSumRecord, record.name.toLowerCase()];
        }
        return acc;
      },
      {}
    );
    return [...data].sort((a, b) => {
      if (!a[pathId]) {
        return this.returnSortValueCorrectly(a, b, dir);
      }
      if (!b[pathId]) {
        return this.returnSortValueCorrectly(a, b, dir);
      }

      const aValues = reverse(
        a[pathId].map((id) => valueAndNameById[id])
      );
      const bValues = reverse(
        b[pathId].map((id) => valueAndNameById[id])
      );

      return cmp(aValues, bValues, dir === "desc");
    });
  }

  // if methodOfComparison(a, b) return is less than 0, sort the value to a previous index.
  // otherwise if returned value is greater than zero, sort the value to next index
  returnSortValueCorrectly(
    a: NgxChartsRecord,
    b: NgxChartsRecord,
    dir: string
  ): number {
    const invertValue = dir === "desc" ? -1 : 1;
    let valueSumA = a.value || 0
    let valueSumB = b.value || 0

    if (a.series) {
      a.series.forEach(element => valueSumA += element.value)
      b.series.forEach(element => valueSumB += element.value)
    }

    if (valueSumA > valueSumB) {
      return 1 * invertValue;
    } else {
      return -1 * invertValue;
    }
  }
}
