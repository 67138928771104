import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { Action } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { DemoService } from '../../wall/services/demo.service'
import { UpdateJobApplications } from '../../wall/actions/job-applications.actions'
import {
  UpdateStarred, UpdateStarredActionTypes, UpdateStarredFailure, UpdateStarredSuccess
} from '../actions/update-starred.actions'
import { apiHost, getHttpPostOptions } from '../../core/http-options'

@Injectable()
export class UpdateStarredEffects {
  // Update the store immediately
  updateStarredInStore$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateStarred>(UpdateStarredActionTypes.UpdateStarred),
    map(
      action =>
        new UpdateJobApplications([
          {
            id: action.payload.jobApplication.id,
            changes: {
              ...action.payload.jobApplication,
            },
          },
        ]),
    ),
  ))

  // And trigger the API call
  updateStarredOnServer$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateStarred>(UpdateStarredActionTypes.UpdateStarred),
    mergeMap(({ payload: { jobApplication } }) => {
      if (window.twng_demo) {
        const requestData = this.demoService.successfulResponse()
        return of(new UpdateStarredSuccess({ requestData, jobApplication }))
      }

      return this.http
        .patch(
          apiHost + `/twng/job_applications/${jobApplication.id}/annotations`,
          {
            starred: jobApplication.starred,
            rating: jobApplication.rating,
          },
          getHttpPostOptions(),
        )
        .pipe(
          map(
            requestData =>
              new UpdateStarredSuccess({
                requestData,
                jobApplication,
              }),
          ),
          catchError(requestData => of(new UpdateStarredFailure({ requestData, jobApplication }))),
        )
    }),
  ))

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private demoService: DemoService,
  ) { }
}
