/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'
import { Candidate } from '../../wall/models/candidate'

export enum ToggleTagActionTypes {
  AddTag = '[ToggleTag] AddTag',
  AddTagSuccess = '[ToggleTag] AddTagSuccess',
  AddTagFailure = '[ToggleTag] AddTagFailure',
  RemoveTag = '[ToggleTag] RemoveTag',
  RemoveTagSuccess = '[ToggleTag] RemoveTagSuccess',
  RemoveTagFailure = '[ToggleTag] RemoveTagFailure',
}
export interface ToggleTagPayload {
  candidate: Candidate
  tagName: string
}

export class AddTag implements Action {
  readonly type = ToggleTagActionTypes.AddTag

  constructor(public payload: ToggleTagPayload) {}
}

export class AddTagSuccess implements Action {
  readonly type = ToggleTagActionTypes.AddTagSuccess

  constructor(public payload: ToggleTagPayload) {}
}
export class AddTagFailure implements Action {
  readonly type = ToggleTagActionTypes.AddTagFailure

  constructor(public payload: ToggleTagPayload) {}
}
export class RemoveTag implements Action {
  readonly type = ToggleTagActionTypes.RemoveTag

  constructor(public payload: ToggleTagPayload) {}
}
export class RemoveTagSuccess implements Action {
  readonly type = ToggleTagActionTypes.RemoveTagSuccess

  constructor(public payload: ToggleTagPayload) {}
}
export class RemoveTagFailure implements Action {
  readonly type = ToggleTagActionTypes.RemoveTagFailure

  constructor(public payload: ToggleTagPayload) {}
}

export type ToggleTypeActions =
  | AddTag
  | AddTagSuccess
  | AddTagFailure
  | RemoveTag
  | RemoveTagSuccess
  | RemoveTagFailure
