<div class="stats" [ngClass]="{ 'stats--custom': dataSource === CUSTOM_BOX_DATA_SOURCE }">
  <twng-gridster-item-overlay *ngIf="editingGridster"></twng-gridster-item-overlay>
  <header [class.pointer-events--none]="editingGridster">
    <twng-chart-label class="center" [chart]="chart" [label]="label" *ngIf="!chart?.is_custom_title"
      [chartInfo]="dataSources && dataSources[dataSource] && dataSources[dataSource].description"></twng-chart-label>
    <div class="header__actions-button" data-html2canvas-ignore>
      <twng-chart-drop-down-menu
        [chart]="isInGridster ? chart : null"
        [label]="label"
        chartType="number-box"
        [chartFilters]="chartFilters"
        [dataSource]="dataSource"
        [dataSourceParameters]="dataSourceParameters"
        [chartStatus]="chart?.data"
        [isCustomTitle]="chart?.is_custom_title"
        *ngIf="!chartFiltersReadOnly && !chart?.not_editable"
      ></twng-chart-drop-down-menu>
    </div>
  </header>

  <div class="stats__big-number-wrapper" [class.pointer-events--none]="editingGridster" [class.chart-as-title]="chart?.is_custom_title">
    <div placement="left bottom" [ngbTooltip]="setTooltip()" *ngIf="dataSource !== CUSTOM_BOX_DATA_SOURCE"
        [disableTooltip]="!clickable" [ngClass]="bigNumberClasses()" (click)="clickable && onClick()">
        {{ valueStr(value) }}
      <div class="stats__people-icon" *ngIf="clickable">
        <i class="fas" [ngClass]="dataType === 'jobs' ? 'fa-suitcase' : 'fa-user'"></i>
      </div>
    </div>

    <ng-container *ngIf="{editingCustomValue: (editingCustomValue$ | async) } as data">
      <hr *ngIf="chart?.is_custom_title" class="flex-grow-1 tw-mr-2"/>
      <div *ngIf="dataSource === CUSTOM_BOX_DATA_SOURCE" placement="left bottom"
            [ngbTooltip]="getTooltipText()"
            [disableTooltip]="data.editingCustomValue" [ngClass]="bigNumberClasses()" (click)="clickable && editValue()">
        <span *ngIf="!data.editingCustomValue" class="custom-value">{{ chart?.custom_value || value }}</span>
        <twng-stat-custom-box-input *ngIf="data.editingCustomValue" [value]="chart?.custom_value || stringValue"
                                    [chart]="chart"></twng-stat-custom-box-input>
      </div>
      <hr *ngIf="chart?.is_custom_title" class="flex-grow-1 tw-ml-2"/>
    </ng-container>
  </div>

  <footer *ngIf="gridItemType === 'css'" class="stats__footer--css" [class.pointer-events--none]="editingGridster">
    <div class="stats__item-header fs-unmask" *ngIf="bottomLine">
      <span class="stats__line"></span>
      <span class="stats__item-header-text">{{ bottomLine }}</span>
      <span class="stats__line"></span>
    </div>

    <div class="stats__items fs-unmask">
      <div class="stats__item">
          <span class="stats__item--label">{{ present(bottom) ? bottom : "&nbsp;" }}</span>
      </div>
    </div>
  </footer>

  <footer *ngIf="gridItemType === 'gridster' && chart?.data_type !== CUSTOM_BOX_DATA_TYPE" class="filters stats__footer--gridster"
          [class.pointer-events--none]="editingGridster" data-html2canvas-ignore>
    <twng-chart-filters
      [filters]="chartFilters"
      [filtersEnabled]="filtersEnabled"
      [chart]="chart"
      [chartFiltersReadOnly]="chartFiltersReadOnly"
      [overrideFiltersSet]="overrideFiltersSet"
    >
    </twng-chart-filters>
  </footer>
</div>
