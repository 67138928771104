import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'twng-editable-input',
  templateUrl: './editable-input.component.html',
  styleUrls: ['./editable-input.component.scss']
})
export class EditableInputComponent implements OnInit {

  @ViewChild('inputLabel')
    inputLabelRef: ElementRef

  @Input()
    initialValue: string

  @Output()
    valueSubmitted = new EventEmitter<string>()

  labelForm: UntypedFormGroup

  ngOnInit(): void {
    this.createForm()
  }

  createForm(): void {
    this.labelForm = new UntypedFormGroup({
      label: new UntypedFormControl(this.initialValue, [Validators.required]),
    })
    setTimeout(() => {
      this.inputLabelRef.nativeElement.focus()
    })
  }

  onSubmit(): void {
    this.valueSubmitted.emit(this.labelForm.value.label.trim())
  }

}
