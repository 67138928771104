import { AppConfigService } from '../../../../wall/services/app-config.service'
import { BehaviorSubject, Observable } from 'rxjs'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FilterOption } from '../generic-filter/filter-option.interface'
import { SingleJobNamePayload } from '../../../../core/actions/loader.actions'
import { State } from '../../../../reducers'
import { Store } from '@ngrx/store'
import { WallApiService } from '../../../../wall/services/wall-api.service'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { selectClosedJobsNamesWithIdNames, selectJobsNamesWithIdNames } from '../../../../wall/reducers'

@Component({
  selector: 'twng-job-filter',
  templateUrl: './job-filter.component.html',
  styleUrls: ['./job-filter.component.css']
})
export class JobFilterComponent implements OnInit {

  selectedJobs$: BehaviorSubject<SingleJobNamePayload[]> = new BehaviorSubject([])
  externalJobs$: Observable<SingleJobNamePayload[]>

  @Input()
    jobIds: string[] = []

  @Output()
    filterChange = new EventEmitter<FilterOption[]>()

  @Input()
    jobFilterType: 'active' | 'closed' | undefined

  @Input()
    disabled = false

  constructor(
    private store: Store<State>,
    private wallApi: WallApiService,
    private appConfig: AppConfigService,
  ) { }

  ngOnInit(): void {
    this.externalJobs$ = this.store.select(this.selectorByType(this.jobFilterType))
      .pipe(
        filter(v => !!v),
        tap(externalJobs => this.loadSelectedJobs(externalJobs, this.jobIds)),
        switchMap((externalJobs: SingleJobNamePayload[]) => this.selectedJobs$.pipe(
          map((selectedJobs: SingleJobNamePayload[]) => externalJobs.filter(externalJob => {
            const foundJob = selectedJobs.find(
              selectedJob => selectedJob.id === externalJob.id)
            // If foundJob, then return false to filter it
            return !foundJob
          }))
        )),
      )

    this.triggerFetchJobNames(this.jobFilterType)
  }

  triggerFetchJobNames(type: string) {
    if (type === 'active') {
      this.store.dispatch(this.wallApi.getFetchOpenJobNamesAction())
    }  else if (type === 'closed')  {
      this.store.dispatch(this.wallApi.getFetchClosedJobNamesAction())
    }
  }

  selectorByType(type: string) {
    if (type === 'active') {
      return selectJobsNamesWithIdNames
    } else if (type === 'closed') {
      return selectClosedJobsNamesWithIdNames
    }
  }

  loadSelectedJobs(externalJobs: SingleJobNamePayload[], jobIds: string[]): void {
    const jobs = externalJobs.filter(externalJob => (jobIds || []).includes(externalJob.id))
    setTimeout(() => this.selectedJobs$.next(jobs))
  }

  jobsSelected(externalJob: FilterOption) {
    const selectedJobs = this.selectedJobs$.value
    this.selectedJobs$.next([...selectedJobs, (externalJob as SingleJobNamePayload)])
    this.emitFilterValue()
  }

  removeJob(externalJob: FilterOption) {
    const selectedJobs = this.selectedJobs$.value.filter((job: SingleJobNamePayload) => job.id !== externalJob.id)
    this.selectedJobs$.next(selectedJobs)
    this.emitFilterValue()
  }

  emitFilterValue() {
    setTimeout(() => this.filterChange.emit(this.selectedJobs$.value))
  }

  reset() {
    this.selectedJobs$ = new BehaviorSubject([])
  }
}
