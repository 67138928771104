import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import { TrackFeatureActionsTypes, TrackFeaturesActions } from '../actions/track-features.actions'

export type FeatureKey = 'analytics'

const allFeatures: FeatureKey[] = ['analytics']

export interface TrackFeatureKeyState {
  feature: FeatureKey
  seen_at: string
}

export interface TrackFeaturesState {
  seenFeatures: TrackFeatureKeyState[]
}

const defaultState: TrackFeaturesState = {
  seenFeatures: []
}

function getAllFeaturesAsNotSeen(): TrackFeatureKeyState[] {
  return allFeatures.map(
    feature => ({
      feature,
      seen_at: null
    })
  )
}

export function reducer(state = defaultState, action: LoaderActions | TrackFeaturesActions) {
  switch (action.type) {

    case LoaderActionTypes.LoadSharedDataSuccess:
      if (window.twng_demo) {
        return {
          ...state,
          seenFeatures: JSON.parse(window.Cookies.get('seenFeatures') || 'null') ?? getAllFeaturesAsNotSeen()
        }
      }
      return {
        ...state,
        seenFeatures: action.payload.seen_features
      }

    case TrackFeatureActionsTypes.TrackFeature: {
      const featureKey = action.payload.featureKey
      const filteredState = state.seenFeatures.filter(({ feature }) => feature !== featureKey)
      const newSeenFeatures = [
        ...filteredState,
        {
          feature: featureKey,
          seen_at: new Date().toString(),
        }
      ]
      if (window.twng_demo) {
        window.Cookies.set('seenFeatures', JSON.stringify(newSeenFeatures))
      }
      return {
        ...state,
        seenFeatures: newSeenFeatures,
      }
    }

    // Don't reverse the action TrackFeature when it fails
    // because it was changed locally when the initial action was fired.
  }
  return state
}
