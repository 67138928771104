import { Subscription } from 'rxjs'

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromDashboard from '../reducers'
import { CacheService } from '../../wall/services/cache.service'
import { ChartFilters } from '../models/chart-filters'
import { DashboardChart } from '../models/dashboard-chart'
import { DataSources } from '../reducers/dashboard.reducer'
import { WidgetDataType, viewWidgetTypeTooltip } from '../../wall/models/offer'
import { atsId } from '../../wall/models/types'

export const customBoxDataType = 'custom'
export const customBoxDataSource = 'description-custom'

@Component({
  selector: 'twng-dash-description',
  templateUrl: './description.component.html',
  styleUrls: [
    './description.component.scss',
  ],
})
export class DescriptionComponent implements OnInit, OnDestroy {

  CUSTOM_BOX_DATA_TYPE = customBoxDataType
  CUSTOM_BOX_DATA_SOURCE = customBoxDataSource

  @Input()
    label: string

  @Input()
    value: string | number

  @Input()
    isInGridster = false

  @Input()
    dataSource: string

  @Input()
    dataSourceParameters: { [key: string]: string }

  @Input()
    chart: DashboardChart

  @Input()
    ids: atsId[]

  @Input()
    candidateDataType: WidgetDataType

  @Input()
    chartFilters: ChartFilters

  @Output()
    showCandidates = new EventEmitter()

  @Input()
    gridItemType: 'css' | 'gridster' = 'css'

  @Input()
    editingGridster: boolean

  @Input()
    filtersEnabled = true

  @Input()
    dataType: string

  @Input()
    chartFiltersReadOnly: boolean

  // draw as title (without background, border, etc)
  @Input()
    useTitleStyle: boolean

  get isInsideCustomDashboard() {
    return this.gridItemType === 'gridster'
  }

  get clickable(): boolean {
    return this.isInsideCustomDashboard
  }

  dataSources: DataSources = {}
  dataSourcesSub: Subscription

  editingCustomValue: boolean
  demoMode = !!window.twng_demo

  height = 100

  get stringValue(): string {
    return typeof this.value === 'string' ? this.value : this.value?.toString()
  }

  present(str: string): boolean {
    return str && str.trim() !== ""
  }

  constructor(
    private cacheService: CacheService,
    private store: Store<fromDashboard.State>
  ) { }

  ngOnInit(): void {
    this.dataSourcesSub = this.cacheService.dataSources$.subscribe(dataSources => {
      this.dataSources = dataSources
    })
  }

  ngOnDestroy(): void {
    this.dataSourcesSub.unsubscribe()
  }

  onClick() {
    this.showCandidates.emit()
  }

  setTooltip() {
    return viewWidgetTypeTooltip(this.candidateDataType)
  }

  editValue() {
    this.editingCustomValue = true
  }

  valueStr(value) {
    return value === undefined || value === null ? '?' : value
  }

  stopEditingValue() {
    this.editingCustomValue = false
  }
}
