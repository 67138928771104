import { Actions } from '@ngrx/effects'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { CustomFieldProps } from '../../../custom-fields/models/custom-field'
import { ExecutiveDashboardActionsTypes,
  UpdateExecutiveDashboardTab
} from '../../../wall/actions/executive-dashboard.actions'
import { ExecutiveDashboardTab } from '../../../wall/models/executive-dashboard'
import { FilterOption } from '../../../shared/components/filters/generic-filter/filter-option.interface'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, Subscription } from 'rxjs'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { cloneDeep, pick } from 'lodash-es'
import { map } from 'rxjs/operators'
import { objKeysSafe } from '../../../shared/utils/general-utils'
import { selectAllJobStageNames } from '../../../wall/reducers'
import { selectCustomFieldsState } from '../../../custom-fields/selectors'
import { toasterOnActionPromise } from '../../../shared/utils/store.utils'

@Component({
  selector: 'twng-edit-executive-dashboard-columns-modal',
  templateUrl: './edit-executive-dashboard-columns-modal.component.html',
  styleUrls: ['./edit-executive-dashboard-columns-modal.component.scss']
})
export class EditExecutiveDashboardColumnsModalComponent implements OnInit, OnDestroy {

  form = new UntypedFormGroup({
    show_days_open: new UntypedFormControl(),
    show_openings: new UntypedFormControl(),
    show_job_stages: new UntypedFormControl(),
    show_hires: new UntypedFormControl(),
    show_proj_hires: new UntypedFormControl(),
    show_notes: new UntypedFormControl(),
    show_custom_fields: new UntypedFormControl(),
    custom_fields_to_show: new UntypedFormControl(),
    excluded_job_stages: new UntypedFormControl(),
    show_recruiter: new UntypedFormControl(),
    show_hiring_manager: new UntypedFormControl(),
    show_job_id: new UntypedFormControl(),
    show_requisition_id: new UntypedFormControl(),
    show_opening_id: new UntypedFormControl(),
    show_office: new UntypedFormControl(),
    show_total_active_candidates: new UntypedFormControl(),
    show_opening_date: new UntypedFormControl(),
    show_candidates_processed: new UntypedFormControl(),
    show_offers_created: new UntypedFormControl(),
    show_new_candidates: new UntypedFormControl(),
    show_target_hire_days: new UntypedFormControl(),
    id: new UntypedFormControl(),
    name: new UntypedFormControl(),
  })

  isDemo = window.twng_demo

  private sub = new Subscription()

  customFields$: Observable<CustomFieldProps[]>

  jobStageNames$: Observable<FilterOption[]>

  // this needs to be initialized by caller
  tab: ExecutiveDashboardTab

  constructor(
    private activeModal: NgbActiveModal,
    private store: Store,
    private actions: Actions,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    const clone = cloneDeep(this.tab)
    delete clone.column_order
    this.form.setValue(pick(clone, objKeysSafe(this.form.controls)))

    this.customFields$ = this.store.select(selectCustomFieldsState).pipe(
      map(state => state.customFields)
    )

    this.jobStageNames$ = this.store.select(selectAllJobStageNames).pipe(
      map((selectedJobStageNames: string[]): FilterOption[] =>
        [...selectedJobStageNames].sort().map((jobStageName): FilterOption => ({
          id: jobStageName,
          name: jobStageName,
        }))),
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  close() {
    this.activeModal.close()
  }

  shouldShowJobStages() {
    return this.form.value.show_job_stages
  }

  shouldShowCustomFields() {
    return this.form.value.show_custom_fields
  }

  async save() {
    const promise = toasterOnActionPromise(
      [ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabSuccess],
      [ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabFailure],
      'Tab updated',
      'Error saving tab configuration',
      this.toastr,
      this.actions
    )
    this.form.disable()
    this.store.dispatch(new UpdateExecutiveDashboardTab({
      ...this.tab,
      ...this.form.value,
    }))
    try {
      await promise
      this.savedSuccessfully()
    } catch (err) {
      this.form.enable()
    }
  }

  private savedSuccessfully() {
    this.close()
  }
}
