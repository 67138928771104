import { EffectsModule } from '@ngrx/effects'
import { NgModule } from '@angular/core'
import { StoreModule } from '@ngrx/store'

import { ChartFilterEffects } from './effects/chart-filter.effects'
import { ComponentsModule } from './components'
import { GridsterDashboardEffects } from './effects/gridster-dashboard.effects'
import { SharedModule } from '../shared/shared.module'
import { reducers } from './reducers'

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature('dashboard', reducers),
    EffectsModule.forFeature([
      ChartFilterEffects, GridsterDashboardEffects
    ]),
    ComponentsModule,
  ],
  exports: [ComponentsModule]
})
export class DashboardModule {
  constructor() { }
}
