import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { AppConfigService } from '../../wall/services/app-config.service'
import { JobApplication } from '../../wall/models/job-application'
import { UrlService } from '../../wall/services/url.service'

@Component({
  selector: 'twng-other-applications',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      img {
        position: relative;
        top: -2px;
        margin: 0 0.25rem;
      }

      dd {
        margin-bottom: 0.75rem;
      }

      .stage-name {
        margin: 0.25rem 0;
        font-color: darkgray;
        font-size: 15px;
      }
    `,
  ],
  styleUrls: ['./card-detail-item.component.scss'],
  template: `
    <dl *ngIf="otherApplications | present" class="other-applications">
      <dt class="section__item-label">Other Active Applications</dt>
      <dd *ngFor="let otherApplication of otherApplications"
            class="section__item-value">
        <div class="tw-flex">
          <span class="fs-block">{{ otherApplication.job?.name }}</span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            ngbTooltip="View in {{ atsName }}"
            [href]="urlService.jobApplicationUrl(otherApplication) | async"
          >
            <img src="{{ appConfig.atsIconUrl() }}" width="18" height="18" class="tw-rounded-md" />
          </a>
        </div>
        <div class="stage-name fs-block">{{ otherApplication.stage?.name }}</div>
      </dd>
    </dl>
  `,
})
export class OtherApplicationsComponent {
  @Input()
    otherApplications: JobApplication[]

  atsName: string

  constructor(
    public urlService: UrlService,
    public appConfig: AppConfigService,
  ) {
    this.atsName = this.appConfig.atsName()
  }
}
