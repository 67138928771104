/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'
import { HttpErrorResponse } from '@angular/common/http'
import { JobStageCategory } from '../models/job-stage-category'
import { StageMappingsState } from '../reducers/stage-mappings.reducer'

export enum StageMappingsActionsTypes {
  LoadStageMappings = '[StageMappings] LoadStageMappings',
  LoadStageMappingsSuccess = '[StageMappings] LoadStageMappingsSuccess',
  LoadStageMappingsFailed = '[StageMappings] LoadStageMappingsFailed',

  CreateCategory = '[StageMappings] CreateCategory',
  CreateCategorySuccess = '[StageMappings] CreateCategorySuccess',
  CreateCategoryFailed = '[StageMappings] CreateCategoryFailed',

  RemoveCategory = '[StageMappings] RemoveCategory',
  RemoveCategorySuccess = '[StageMappings] RemoveCategorySuccess',
  RemoveCategoryFailed = '[StageMappings] RemoveCategoryFailed',

  UpdateCategory = '[StageMappings] UpdateCategory',
  UpdateCategorySuccess = '[StageMappings] UpdateCategorySuccess',
  UpdateCategoryFailed = '[StageMappings] UpdateCategoryFailed',

  UpdateCategoryPositions = '[StageMappings] UpdateCategoryPositions',
  UpdateCategoryPositionsSuccess = '[StageMappings] UpdateCategoryPositionsSuccess',
  UpdateCategoryPositionsFailed = '[StageMappings] UpdateCategoryPositionsFailed',

  RenameJobStageNameCategory = '[StageMappings] RenameJobStageNameCategory',
  RenameJobStageNameCategorySuccess = '[StageMappings] RenameJobStageNameCategorySuccess',
  RenameJobStageNameCategoryFailed = '[StageMappings] RenameJobStageNameCategoryFailed'
}

export class LoadStageMappings implements Action {
  readonly type = StageMappingsActionsTypes.LoadStageMappings
}

export interface LoadStageMappingsSuccessPayload {
  job_stage_names: string[]
  job_stage_categories: JobStageCategory[]
}

export class LoadStageMappingsSuccess implements Action {
  readonly type = StageMappingsActionsTypes.LoadStageMappingsSuccess

  constructor(public payload: LoadStageMappingsSuccessPayload) {
  }
}

export class LoadStageMappingsFailed implements Action {
  readonly type = StageMappingsActionsTypes.LoadStageMappingsFailed

  constructor(public payload: StageMappingsState) {
  }
}

// Create Category
export class CreateCategory implements Action {
  readonly type = StageMappingsActionsTypes.CreateCategory
  constructor(public payload: string) {}
}

export class CreateCategorySuccess implements Action {
  readonly type = StageMappingsActionsTypes.CreateCategorySuccess

  constructor(public payload: JobStageCategory) {
  }
}

export class CreateCategoryFailed implements Action {
  readonly type = StageMappingsActionsTypes.CreateCategoryFailed

  constructor(public payload: HttpErrorResponse) {
  }
}

// Remove Category
export class RemoveCategory implements Action {
  readonly type = StageMappingsActionsTypes.RemoveCategory

  constructor(public payload: { jobStageCategory: JobStageCategory }) {
  }
}

export class RemoveCategorySuccess implements Action {
  readonly type = StageMappingsActionsTypes.RemoveCategorySuccess
}

export class RemoveCategoryFailed implements Action {
  readonly type = StageMappingsActionsTypes.RemoveCategoryFailed

  constructor(public payload: { jobStageCategory: JobStageCategory }) {
  }
}

export interface UpdateJobStageCategoryPayload {
  jobStageCategoryIdFrom: number | null // From where is was moved (general list or category)
  jobStageCategoryIdTo: number // Where to drop the job stage name
  jobStageName: string
}

export interface UpdateJobStagePositionsPayload {
  newJobStages: JobStageCategory[]
}

export interface UpdateCategoryPositionPayload {
  draggedCategoryId: number
  replacePositionWithCategoryId: number
}

// Update Category
export class UpdateCategory implements Action {
  readonly type = StageMappingsActionsTypes.UpdateCategory

  constructor(public payload: UpdateJobStageCategoryPayload) {
  }
}

export class UpdateCategorySuccess implements Action {
  readonly type = StageMappingsActionsTypes.UpdateCategorySuccess
}

export class UpdateCategoryFailed implements Action {
  readonly type = StageMappingsActionsTypes.UpdateCategoryFailed

  constructor(public payload: UpdateJobStageCategoryPayload) {
  }
}

// Update Category positions
export class UpdateCategoryPositions implements Action {
  readonly type = StageMappingsActionsTypes.UpdateCategoryPositions

  constructor(public payload: UpdateJobStagePositionsPayload) {
  }
}

export class UpdateCategoryPositionsSuccess implements Action {
  readonly type = StageMappingsActionsTypes.UpdateCategoryPositionsSuccess
  constructor(public payload: UpdateJobStagePositionsPayload) {
  }
}

export class UpdateCategoryPositionsFailed implements Action {
  readonly type = StageMappingsActionsTypes.UpdateCategoryPositionsFailed

  constructor() {
  }
}

// Rename Job Stage Category Name
export interface RenameJobStageCategoryNamePayload {
  jobStageCategoryOldName: string
  jobStageCategoryName: string
  jobStageCategoryId: number
}

export class RenameJobStageCategoryName implements Action {
  readonly type = StageMappingsActionsTypes.RenameJobStageNameCategory

  constructor(public payload: RenameJobStageCategoryNamePayload) {
  }
}

export class RenameJobStageCategoryNameSuccess implements Action {
  readonly type = StageMappingsActionsTypes.RenameJobStageNameCategorySuccess
}

export class RenameJobStageCategoryFailed implements Action {
  readonly type = StageMappingsActionsTypes.RenameJobStageNameCategoryFailed

  constructor(public payload: RenameJobStageCategoryNamePayload) {
  }
}

export type StageMappingsActions = LoadStageMappings
| LoadStageMappingsSuccess
| LoadStageMappingsFailed
| CreateCategory
| UpdateCategory
| UpdateCategoryPositions
| CreateCategorySuccess
| CreateCategoryFailed
| UpdateCategorySuccess
| UpdateCategoryFailed
| UpdateCategoryPositionsSuccess
| UpdateCategoryPositionsFailed
| RemoveCategory
| RemoveCategorySuccess
| RemoveCategoryFailed
| RenameJobStageCategoryName
| RenameJobStageCategoryNameSuccess
| RenameJobStageCategoryFailed


