<div ngbDropdown *ngIf="sendChartEnabled && dataSource || chart" data-html2canvas-ignore
  placement="bottom-right bottom-left auto" [autoClose]="'outside'" #menuDropDown="ngbDropdown" [container]="'body'"
  (openChange)="dropdownOpenChanged($event)">

  <button ngbDropdownToggle id="widgetActionsMenu" ngbTooltip="Actions" class="app__dropdown-toggle">
    <i class="fas fa-ellipsis-v"></i>
  </button>

  <div ngbDropdownMenu aria-labelledby="widgetActionsMenu" class="app__dropdown-menu">

    <section *ngIf="chart" class="section--with-separator">
      <span class="section__header fs-unmask">Actions</span>

      <div ngbDropdownItem *ngIf="!chart.is_custom_title">
        <button class="section__row section__row--button" (click)="enableRenameChart(); menuDropDown.close();">
          <div class="button-flex-wrapper">
            <span class="section__row-icon">
              <svg class="up--1px" width="17" height="16" viewBox="0 0 17 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.861721 16C0.931522 16 1.00132 15.9914 1.07026 15.9741L4.51724 15.1124C4.66891 15.0745 4.80765 14.9961 4.91795 14.8858L15.5114 4.29235C15.8371 3.96661 16.0164 3.53401 16.0164 3.07384C16.0164 2.61367 15.8371 2.18107 15.5114 1.85533L14.1446 0.488609C13.4932 -0.16287 12.3591 -0.16287 11.7076 0.488609L1.11421 11.082C1.00396 11.1924 0.925649 11.3306 0.887574 11.4819L0.0258296 14.9289C-0.00600094 15.0559 -0.00844571 15.1886 0.018681 15.3167C0.0458077 15.4449 0.101792 15.5651 0.182379 15.6684C0.262966 15.7717 0.366034 15.8552 0.48375 15.9127C0.601466 15.9701 0.730731 16 0.861721 16ZM12.9261 1.70711L14.2929 3.07384L12.9261 4.44057L11.5594 3.07384L12.9261 1.70711ZM2.50162 12.1316L10.3409 4.29235L11.7076 5.65907L3.86748 13.4984L2.04576 13.9534L2.50162 12.1316Z"
                  fill="#767676" />
              </svg>
            </span>
            <span class="section__row-label fs-unmask">Rename Chart</span>
          </div>
        </button>
      </div>

      <div ngbDropdownItem>
        <button class="section__row section__row--button" (click)="removeChart()">
          <div class="button-flex-wrapper">
            <i class="fas fa-trash section__row-icon"></i>
            <span class="section__row-label fs-unmask">Delete Chart</span>
          </div>
        </button>
      </div>

    </section>
    <section *ngIf="dataTimestamp && dataSource !== 'number-box-custom' && (refreshingEnabled$ | async)" class="section--with-separator">
      <span class="section__header fs-unmask">Calculated {{ dataTimestamp | timeAgo }}</span>
      <!-- When we decide to enable refreshing for everyone, just remove the "&& (refreshingEnabled$ | async)" part -->
      <div ngbDropdownItem *ngIf="chartStatus?.queue_key && (refreshingEnabled$ | async)">
        <button class="section__row section__row--button" (click)="refreshData(); menuDropDown.close();">
          <div class="button-flex-wrapper">
            <i class="fas fa-sync section__row-icon"></i>
            <span class="section__row-label fs-unmask">Refresh Data</span>
          </div>
        </button>
      </div>
    </section>

    <section *ngIf="dataSource" class="section--with-separator">
      <span class="section__header">Send this to</span>

      <ng-container *ngIf="(dashboardTabs$ | async) as dashboardTabs">
        <twng-spinner *ngIf="!(isDashboardLoaded$ | async)"></twng-spinner>
        <div *ngFor="let dashboardTab of dashboardTabs" ngbDropdownItem>
          <button class="section__row section__row--button" (click)="sendChartTo(dashboardTab); menuDropDown.close();">
            <div class="button-flex-wrapper">
              <span class="section__row-icon rotate--270">
                <!--
                  https://materializecss.com/icons.html
                  https://www.materialui.co/icon/dashboard
                  @todo: make this reusable
                -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                </svg>
              </span>
              <span class="section__row-label">{{ dashboardTab.name }}</span>
            </div>
          </button>
        </div>

        <div ngbDropdownItem>
          <button class="section__row section__row--button" (click)="sendChartTo(null); menuDropDown.close();">
            <div class="button-flex-wrapper">
              <i class="fas fa-plus  section__row-icon"></i>
              <span class="section__row-label fs-unmask">New Dashboard</span>
            </div>
          </button>
        </div>
      </ng-container>
    </section>
  </div>
</div>