import { camelCase as _camelCase, kebabCase as _kebabCase } from 'lodash-es'

import {
  AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core'
import {
  BarHorizontal2DComponent, BarHorizontalComponent, BarVertical2DComponent,
  BarVerticalComponent, Color, LegendPosition, ScaleType
} from '@swimlane/ngx-charts'
import { Store } from '@ngrx/store'

import { CacheService } from '../../wall/services/cache.service'
import { ChartCommonComponent } from './chart-common.component'
import { ChartsInitialConfig } from '../../core/actions/loader.actions'
import { ClipboardService } from '../../core/services/clipboard.service'
import { DashboardChart } from '../models/dashboard-chart'
import { DefaultColorScheme } from './color-schemes'
import { DepartmentWithChildren } from '../../wall/models/department'
import { NgxChartsRecord } from '../reducers/analytics.reducer'
import { OfficeWithChildren } from '../../wall/models/office'
import { SortChartOption } from '../models/sort-chart-option'
import { User } from '../../wall/models/user'

@Component({
  selector: 'twng-dash-bar-chart',
  // OnLoad + Resize, the <ngx-charts-bar-horizontal> element uses the CSS height
  // attribute of its parent `.ngx-responsive-wrapper`, to set its own height.
  // This stretches the full control with its individual bar heights.
  // Since we want fixed bar heights for consistency across diff bar charts,
  // we'll calc the height dynamically based on # of bars.

  // Think of the ngx element <ngx-charts-bar-horizontal> as a big painting
  // inside a little picture frame (the scroll container, .js-content-overflow--[horizontal|vertical].

  // https://github.com/swimlane/ngx-charts/issues/150#issuecomment-303129628
  // https://github.com/swimlane/ngx-charts/issues/147
  templateUrl: './bar-chart.component.html',
  styleUrls: [
    './dashboard.shared.component.scss',
    './dashboard.component.scss',
    './dashboard.gridster.component.scss',

    './horizontal-bar.component.scss',
    './horizontal-bar.gridster.component.scss',

    './vertical-bar.component.scss',
    './vertical-bar.gridster.component.scss',
  ],
})
export class DashBarChartComponent extends ChartCommonComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input()
    isDataGrouped: boolean

  @Input()
    chartDirection: 'vertical' | 'horizontal'

  @Input()
    colorScheme: Color = DefaultColorScheme

  @Input()
    gridItemType: 'css' | 'gridster' = 'css'

  @Input()
    filtersInCopiedImg: boolean

  @Input()
    legendInCopiedImg: boolean

  @Input()
    chart: DashboardChart

  @Input()
    editingGridster: boolean

  @Input()
    dataSourceParameters: { [key: string]: string }

  @Input()
    chartFiltersReadOnly: boolean

  @Input()
    chartInitConfig: ChartsInitialConfig

  @ViewChild(BarHorizontalComponent, { static: false }) gridsterHorizontalBarChart: BarHorizontalComponent
  @ViewChild(BarHorizontal2DComponent, { static: false }) gridsterHorizontalBar2dChart: BarHorizontal2DComponent
  @ViewChild(BarVerticalComponent, { static: false }) gridsterVerticalBarChart: BarVerticalComponent
  @ViewChild(BarVertical2DComponent, { static: false }) gridsterVerticalBar2dChart: BarVertical2DComponent

  sortChartBy: SortChartOption

  view: [number, number]

  departments: DepartmentWithChildren[]
  offices: OfficeWithChildren[]
  users: User[]

  // @todo: extract to bar component
  barHeightPx = 40
  barWidthPx = 50

  copyingToClipboard = false

  componentRendered = false
  private componentDestroyed = false

  displayBars: number
  legendPosition = LegendPosition.Below
  scaleType = ScaleType.Ordinal

  get chartType(): string {
    return 'bar-' + this.chartDirection + (this.isDataGrouped ? '-grouped' : '')
  }

  constructor(
    private cd: ChangeDetectorRef,
    private clipboardService: ClipboardService,
    cacheService: CacheService,
    store: Store,
  ) {
    super(store, cacheService)
  }

  ngOnInit() {
    super.ngOnInit()
    if (!this.colorScheme) {
      throw new Error("Missing color scheme")
    }

    this.displayBars = this.initDisplayBars()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status && changes.status.currentValue) {
      this.displayBars = this.initDisplayBars()
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.componentDestroyed = true
  }

  get slicedData(): NgxChartsRecord[] {
    return this.data?.slice(0, this.displayBars)
  }

  ngAfterViewInit() {
    this.componentRendered = true
    setTimeout(() => {
      if (!this.componentDestroyed) {
        this.cd.detectChanges()
      }
    })
  }

  initDisplayBars() {
    return this.chartInitConfig ? this.chartInitConfig[`${this.chartDirection}_bars_number`] : this.data.length
  }

  /**
   * The charts render an svg element. Inside, the "g" element is not centered
   * to fit the svg container, so when exporting the chart, the overflow part
   * doesn't show up in the pdf. This affects all charts.
   *
   * @param view The size of the container of chart
   * @param originalContainer The original parent container of the chart
   */
  setView(view: [number, number], originalContainer: [number, number]) {
    this.view = view
    const svg: SVGElement = (
      // @ts-ignore
      this.gridsterHorizontalBarChart?.chartElement ||
      // @ts-ignore
      this.gridsterVerticalBarChart?.chartElement ||
      // @ts-ignore
      this.gridsterVerticalBar2dChart?.chartElement ||
      // @ts-ignore
      this.gridsterHorizontalBar2dChart?.chartElement
    )?.nativeElement?.querySelector('svg')
    if (svg) {
      const width = this.chartDirection === 'horizontal' ?
        originalContainer[0] :
        Math.min(this.barHeightPx * (this.slicedData.length+1) * 1.5, originalContainer[0])
      const height = this.chartDirection === 'vertical' ?
        originalContainer[1] :
        Math.min(this.barHeightPx * (this.slicedData.length+1), originalContainer[1])
      setTimeout(() => svg.setAttribute('width', width + 'px'))
      setTimeout(() => svg.setAttribute('height', height + 'px'))
      if (this.chartDirection === 'horizontal') {
        this.view[1] = height
      }
      if (this.chartDirection === 'vertical') {
        this.view[0] = width
      }

      if (this.chartDirection === 'vertical') {
        const g = svg.querySelector('g')
        if (g) {
          g.style.transform = 'translate(60px, 20px)'
        }
      }
    }
  }

  setBarPadding(numberOfBars) {
    const MAX_WIDTH = 50
    const MIN_WIDTH = 4
    return Math.max(MIN_WIDTH, MAX_WIDTH - MAX_WIDTH * numberOfBars / 13)
  }

  camelCase(val: string) {
    return _camelCase(val)
  }

  kebabCase(val: string) {
    return _kebabCase(val)
  }

  integersOnly(val: number) {
    if (val % 1 === 0) {
      return val.toLocaleString()
    } else {
      return ''
    }
  }

  copyChartToClipboard(chartEl: Element, legendInCopiedImg: boolean, filtersInCopiedImg: boolean) {
    this.copyingToClipboard = true

    this.clipboardService.copyChartToClipboard(chartEl, legendInCopiedImg, filtersInCopiedImg).finally(() => {
      this.copyingToClipboard = false
      this.cd.detectChanges()
    })
  }

  /*
    NGX charts use the CSS height attribute of its parent `.ngx-responsive-wrapper`,
    to set its own height on init + resize.
    This stretches the full control with its individual bar heights.
    Since we want fixed bar heights for consistency across diff bar charts,
    we'll calc the height dynamically based on # of bars.

    Think of the ngx element <ngx-charts-bar-horizontal> as a big painting
    inside a little picture frame (the scroll container, .js-content-overflow--[horizontal|vertical].

    https://github.com/swimlane/ngx-charts/issues/150#issuecomment-303129628
    https://github.com/swimlane/ngx-charts/issues/147
  */

  widgetBodyClasses() {
    return {
      body: true,
      'body--no-filters': !this.chart,
      'body--with-filters': this.chart
    }
  }

  viewAllBars = () => {
    this.displayBars = this.data.length
  }

  formatDataLabel = (value) =>  {
    return ['advanced-rate-by-source', 'hires-over-applications-by-source'].includes(this.dataSource) ? `${value}%` : value;
  }
}
