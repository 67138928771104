import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core'
import { JobStatusColor } from '../../../wall/models/job-status-update'

@Directive({
  selector: '[twng-job-status-color]',
})
export class JobStatusColorDirective implements OnChanges {
  @Input() status: JobStatusColor
  @Input() enabled: boolean

  currentClass = ''

  constructor(private elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status && this.enabled) {
      this.updateStatusClass()
    }
  }

  updateStatusClass() {
    if (this.currentClass) {
      this.elementRef.nativeElement.classList.remove(this.currentClass)
    }
    this.currentClass = this.statusUpdateClass
    if (this.currentClass) {
      this.elementRef.nativeElement.classList.add(this.currentClass)
    }
  }

  get statusUpdateClass(): string {
    switch (this.status) {
      case JobStatusColor.Green:
        return 'job-status-green'
      case JobStatusColor.Yellow:
        return 'job-status-yellow'
      case JobStatusColor.Red:
        return 'job-status-red'
      case JobStatusColor.OnHold:
        return 'job-status-on-hold'
      default:
        return ''
    }
  }

}
