import { storeFreeze } from 'ngrx-store-freeze'

import { ActionReducerMap, MetaReducer, createFeatureSelector, createSelector } from '@ngrx/store'

import { AccountState, reducer as accountReducer } from '../core/reducers/account.reducer'
import { environment } from '../../environments/environment'

// prevent state from being mutated

export interface State {
  // add content to State to make tslint be quiet -- also, we may want some layout state
  //layout?: any
  account: AccountState
}

export const reducers: ActionReducerMap<State> = {
  account: accountReducer,
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [storeFreeze]
  : []

export const getAccountState = createFeatureSelector<AccountState>(
  'account',
)

export const selectAccount = createSelector(
  getAccountState,
  (state: AccountState) => state.account,
)

export const selectAppConfig = createSelector(
  getAccountState,
  state => state.config,
)

export const selectWallLoadCompleted = createSelector(
  getAccountState,
  state => state.wallLoadCompleted,
)

export const selectDashboardLoadCompleted = createSelector(
  getAccountState,
  state => state.dashboardLoadCompleted,
)

// the current user
export const selectUser = createSelector(
  getAccountState,
  state => state.user,
)
