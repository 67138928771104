import Rollbar from 'rollbar'

import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken
} from '@angular/core'
import { environment } from '../environments/environment'
import { isHostedLocally } from './shared/utils/general-utils'

const rollbarConfig: Rollbar.Configuration = {
  accessToken: window.rollbar_access_token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: false,
  codeVersion: window.buildVersion,

  payload: {
    environment: (window.twng_demo) ? "Demo" :
      (environment.production ? "Non-development" : "Development"),
    client: {
      javascript: {
        source_map_enabled: true, // true by default
        // ---------------------------------------

        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: false,
        code_version: window.buildVersion,
      },
    },
    person: {
      id: window.twng_user?.id,
      accountId: window.twng_account?.id,
      accountName: window.twng_account?.name,
    },
    impersonating: !!window.twng_impersonating
  }
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar')

const errorMessagesToIgnore: string[] = [
  // this error is safe to ignore. It is a bug in actioncable library when
  // attempting to reconnect
  "Cannot read property 'received' of undefined",
]

const reportedErrors = new Set<string>()

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(err): void {
    console.warn("We caught an error")
    const errorString: string = err?.message || err.toString()
    if (!isHostedLocally()) {
      if (window.rollbar_access_token) {
        if (!errorMessagesToIgnore.includes(errorString)) {
          if (reportedErrors.has(errorString)) {
            console.warn("This error was already reported")
          } else {
            this.rollbar.error(err.originalError || err)
          }
        } else {
          console.warn("This error is not reported.")
        }
      } else {
        console.warn("Error reporting is disabled")
      }
    } else {
      if (!reportedErrors.has(errorString)) {
        console.error(err)
      }
    }
    reportedErrors.add(errorString)
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig)
}
