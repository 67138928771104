<div #widget class="widget tw-flex tw-flex-col tw-rounded-md">
  <twng-gridster-item-overlay *ngIf="editingGridster"></twng-gridster-item-overlay>
  <div class="header tw-border-none" [class.pointer-events--none]="editingGridster">
    <twng-chart-label [label]="label" [chart]="chart" class="tw-h-14"
      [chartInfo]="(dataSources && dataSources[dataSource]) ? dataSources[dataSource].description: null">
    </twng-chart-label>
    <div class="header-actions" data-html2canvas-ignore>
      <twng-chart-drop-down-menu [chart]="chart" [label]="label" [chartType]="chart.chart_type" [chartFilters]="chartFilters"
        [dataSource]="dataSource" [hideFilters]="true" [dataSourceParameters]="dataSourceParameters" [chartStatus]="status" >
      </twng-chart-drop-down-menu>
    </div>
  </div>
  <div class="table-container">
    <table>
      <tr>
        <th *ngFor="let name of tableData?.headers" [ngbTooltip]="getHeaderTooltip(name)"
        class="tw-p-2 tw-text-[13px] tw-text-cq-gray-600 tw-border tw-bg-gray-100 tw-border-cq-gray-200 tw-font-medium tw-truncate tw-uppercase tw-sticky tw-top-0 tw-text-center">
          {{ getColumnHeader(name) }}
        </th>
      </tr>
      <tr *ngFor="let row of tableData?.rows">
        <td *ngFor="let data of row" [ngbTooltip]="getTooltip(data)"
         class="tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-text-center tw-text-sm tw-text-cq-gray-600 tw-truncate tw-max-w-xs">
          {{ getRowData(data) }}
          <span *ngIf="arrayLength(data) > 1" class="tw-rounded-full tw-inline-flex	tw-justify-center tw-items-center tw-w-5 tw-h-5 tw-bg-cq-gray-600">
            <span class="tw-text-white tw-text-xs tw-cursor-default">{{ arrayLength(data) }}</span>
          </span>
        </td>
      </tr>
    </table>
  </div>
</div>
