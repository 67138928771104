import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'

import { EmailTemplate } from '../models/email-template'
import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { sortByName } from './sort-comparers'

export type State = EntityState<EmailTemplate>

export const adapter: EntityAdapter<EmailTemplate> = createEntityAdapter<
EmailTemplate
>({
  sortComparer: sortByName,
})

export const initialState: State = adapter.getInitialState()

export function reducer(state = initialState, action: LoaderActions): State {
  switch (action.type) {
    case LoaderActionTypes.WallSharedSuccess:
      return adapter.upsertMany(action.payload.email_templates || [], state)

    default:
      return state
  }
}
