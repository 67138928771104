

import { ChartsInitialConfig, DashboardTemplateList,
  LoaderActionTypes, LoaderActions
} from '../../core/actions/loader.actions'

export interface DataSource {
  description: string
  color_scheme: string
}

export type DataSources = Record<string, DataSource>

export interface DashboardState {
  data_sources: DataSources
  chart_types: unknown,
  dashboard_templates: DashboardTemplateList,
  initial_config: ChartsInitialConfig
}

const initialState: DashboardState = {
  data_sources: {},
  chart_types: {},
  dashboard_templates: {},
  initial_config: {
    horizontal_bars_number: 30, vertical_bars_number: 30
  }
}

export function reducer(
  state: DashboardState = initialState,
  action: LoaderActions,
): DashboardState {
  switch (action.type) {
    case LoaderActionTypes.DashboardLoadSuccess: {
      // update data sources and chart types, if included in the payload
      return {
        ...state,
        chart_types: action.payload.chart_types || state.chart_types,
        dashboard_templates: action.payload.dashboard_templates || state.dashboard_templates,
        initial_config: action.payload.initial_config || state.initial_config,
      }
    }
    case LoaderActionTypes.LoadSharedDataSuccess: {
      return {
        ...state,
        data_sources: action.payload.data_sources || state.data_sources,
      }
    }

    default: {
      return state
    }
  }
}
