import { CommonModule } from '@angular/common'
import { ConfirmationPopoverModule } from 'angular-confirmation-popover'
import { NgModule } from '@angular/core'

import * as wallComponents from '../../wall/components'
import { AddNewTabModalComponent } from './add-new-tab-modal/add-new-tab-modal.component'
import { ClipboardModule } from 'ngx-clipboard'
import { ConfigurePerJobValueComponent } from './configure-per-job-value/configure-per-job-value.component'
import { CustomFieldsModule } from '../../custom-fields/custom-fields.module'
import { ComponentsModule as DashboardComponentsModule } from '../../dashboard/components'
import {
  EditColumnColorComponent
} from './edit-executive-dashboard-columns-modal/edit-column-color/edit-column-color.component'
import {
  EditExecutiveDashboardColumnsModalComponent
} from './edit-executive-dashboard-columns-modal/edit-executive-dashboard-columns-modal.component'
import {
  EditExecutiveDashboardThresholdsModalComponent
} from './edit-executive-dashboard-thresholds-modal/edit-executive-dashboard-thresholds-modal.component'
import { EditGoalsComponent } from './edit-goals/edit-goals.component'
import { ExecDashSummaryRowComponent } from './exec-dash-summary-row/exec-dash-summary-row.component'
import { ExecutiveDashboardContentComponent } from './executive-dashboard-content/executive-dashboard-content.component'
import {
  ExecutiveDashboardJobComponent
} from './executive-dashboard-content/executive-dashboard-job/executive-dashboard-job.component'
import { ExecutiveDashboardPageComponent } from './executive-dashboard-page/executive-dashboard-page.component'
import {
  ExecutiveDashboardSharedTabPageComponent
} from './executive-dashboard-shared-tab-page/executive-dashboard-shared-tab-page.component'
import { FormsModule } from '@angular/forms'
import { HiringGoalChartsComponent } from './hiring-goal-charts/hiring-goal-charts.component'
import { HiringManagementComponent } from './hiring-management.component'
import { JobNameFilterComponent } from './job-name-filter/job-name-filter.component'
import { JobNameFilterPipe } from './job-name-filter/job-name-filter.pipe'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import {
  ReorderExecDashColumnsModalComponent
} from './reorder-exec-dash-columns-modal/reorder-exec-dash-columns-modal.component'
import { ReorderTabsModalComponent } from './reorder-tabs-modal/reorder-tabs-modal.component'
import { SharedModule } from '../../shared/shared.module';
import {
  ToggleStagesPhasesComponent
} from './executive-dashboard-content/toggle-stages-phases/toggle-stages-phases.component';

@NgModule({
  declarations: [
    ExecutiveDashboardContentComponent,
    HiringManagementComponent,
    JobNameFilterComponent,
    JobNameFilterPipe,
    EditExecutiveDashboardColumnsModalComponent,
    ReorderExecDashColumnsModalComponent,
    ExecDashSummaryRowComponent,
    ConfigurePerJobValueComponent,
    EditColumnColorComponent,
    EditExecutiveDashboardThresholdsModalComponent,
    EditGoalsComponent,
    AddNewTabModalComponent,
    ExecutiveDashboardPageComponent,
    HiringGoalChartsComponent,
    ReorderTabsModalComponent,
    ExecutiveDashboardSharedTabPageComponent,
    ToggleStagesPhasesComponent,
    ExecutiveDashboardJobComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    wallComponents.ComponentsModule,
    CustomFieldsModule,
    DashboardComponentsModule,
    NgxChartsModule,
    ClipboardModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger',
      cancelButtonType: 'secondary',
    }),
  ]
})
export class ComponentsModule {
}
