import { Action, Store } from '@ngrx/store'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'

import { Actions, ofType } from '@ngrx/effects'
import { Job } from '../../models/job'
import { JobConversionRates } from '../../reducers/job-stage-stats.reducer'
import { JobStage } from '../../models/job-stage'
import { Observable, Subscription } from 'rxjs'
import { ProjectedHiresActionTypes, UpdateHiringPredictionPayload, UpdateHiringPredictions
} from '../../actions/projected-hires.actions'
import { SingleJobProjectedHires } from '../../models/projected-hires'
import { getProjectedHiresForJob, getSelectConversionRatesForJob, selectJobStagesByJobId } from '../../reducers'
import { map, startWith } from 'rxjs/operators'
import { objKeysSafe } from '../../../shared/utils/general-utils'
import { selectImmediately } from '../../../shared/utils/store.utils'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-configure-hiring-predictions',
  templateUrl: './configure-hiring-predictions.component.html',
  styleUrls: ['./configure-hiring-predictions.component.scss']
})
export class ConfigureHiringPredictionsComponent implements OnInit, OnDestroy {
  @Input()
    job: Job

  jobStages: JobStage[]

  jobProjectedHires: SingleJobProjectedHires
  conversionRates: JobConversionRates
  form: UntypedFormGroup
  canConfigureHiringPredictions$: Observable<boolean>
  demoMode = !!window.twng_demo

  private subscriptions = new Subscription()

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private cd: ChangeDetectorRef,
    private actions: Actions
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.store.select(getProjectedHiresForJob(this.job.id)).subscribe(
        projectedHires => {
          this.jobProjectedHires = projectedHires
          if (this.jobProjectedHires) {
            this.cd.markForCheck()
          }
        }
      )
    )
    this.canConfigureHiringPredictions$ = this.actions
      .pipe(
        ofType(
          ProjectedHiresActionTypes.UpdateHiringPredictions,
          ProjectedHiresActionTypes.UpdateHiringPredictionsSuccess,
          ProjectedHiresActionTypes.UpdateHiringPredictionsFailure),
        map((action: Action) => action.type !== ProjectedHiresActionTypes.UpdateHiringPredictions),
        startWith(true),)
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  async initForm() {
    this.conversionRates = await selectImmediately(this.store, getSelectConversionRatesForJob(this.job.id))
    const allJobStages = await selectImmediately(this.store, selectJobStagesByJobId)
    this.form = this.fb.group({})
    if (allJobStages && allJobStages[this.job.id]) {
      this.jobStages = allJobStages[this.job.id]
      this.jobStages.forEach(jobStage => {
        this.form.addControl(
          jobStage.id,
          new UntypedFormControl(this.customValue(jobStage.id))
        )
      })
    }
  }

  customValue(jobStageId: string): string {
    if(this.jobProjectedHires.stage_projection_info[jobStageId].recommendation_source === 'user_custom') {
      return this.jobProjectedHires.stage_projection_info[jobStageId].recommended_percent?.toFixed(0)
    }
  }

  submit() {
    const formValues = this.form.value
    const updateHiringPredictionPayload: UpdateHiringPredictionPayload[] = objKeysSafe(formValues).map(jobStageId => ({
      job_stage_id: jobStageId,
      value: formValues[jobStageId]
    }))
    this.store.dispatch(
      new UpdateHiringPredictions({ hiringPredictions: updateHiringPredictionPayload, job: this.job })
    )
  }

  // When the dropdown is shown, start a new form
  // with actual values
  openChange(isDropdownOpen: boolean) {
    // It was closed
    if (!isDropdownOpen) {
      return
    }
    this.initForm()
  }

  ratesInRange() {
    const formValues = this.form.value
    return objKeysSafe(formValues).every(jobStageId => (
      (formValues[jobStageId] >= 1 && formValues[jobStageId] <= 100) || formValues[jobStageId] === null
    ))
  }

}
