import { ChangeDetectionStrategy, Component, EventEmitter,
  Input, OnChanges, Output, SimpleChanges
} from '@angular/core'
import { NgxChartsRecord } from '../../reducers/analytics.reducer';
import { SortChartOption } from '../../models/sort-chart-option';
import { some } from 'lodash-es';

interface SortOption {
  label: string,
  value: SortChartOption,
}

const defaultSortOptions: SortOption[] = [
  {
    label: 'Default',
    value: SortChartOption.Default
  },
  {
    label: 'By Name, Ascending',
    value: SortChartOption.ByNameAsc
  },
  {
    label: 'By Name, Descending',
    value: SortChartOption.ByNameDesc
  },
  {
    label: 'By Value, Ascending',
    value: SortChartOption.ByValueAsc
  },
  {
    label: 'By Value, Descending',
    value: SortChartOption.ByValueDesc
  }
]

const departmentSortOptions: SortOption[] = [
  {
    label: 'By Value (with subdepartments), Ascending',
    value: SortChartOption.ByDepartmentHierarchyValueAsc
  },
  {
    label: 'By Value (with subdepartments), Descending',
    value: SortChartOption.ByDepartmentHierarchyValueDesc
  }
]

const officeSortOptions: SortOption[] = [
  {
    label: 'By Value (with suboffices), Ascending',
    value: SortChartOption.ByOfficeHierarchyValueAsc
  },
  {
    label: 'By Value (with suboffices), Descending',
    value: SortChartOption.ByOfficeHierarchyValueDesc
  }
]

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-sort-chart-menu',
  templateUrl: './sort-chart-menu.component.html',
  styleUrls: ['./sort-chart-menu.component.scss']
})
export class SortChartMenuComponent implements OnChanges {
  @Output()
    sortByChanged = new EventEmitter<SortChartOption>()

  @Input()
    allChartData: NgxChartsRecord[]

  sortOptions: SortOption[] = defaultSortOptions

  selectedSortOption = SortChartOption.Default

  sort(sortChartOption: SortChartOption) {
    this.selectedSortOption = sortChartOption
    this.sortByChanged.emit(sortChartOption)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allChartData) {
      if (some(this.allChartData, data => data.department_id_path)) {
        this.sortOptions = [...defaultSortOptions, ...departmentSortOptions]
      } else if (some(this.allChartData, data => data.office_id_path)) {
        this.sortOptions = [...defaultSortOptions, ...officeSortOptions]
      } else {
        this.sortOptions = defaultSortOptions
      }
    }
  }
}
