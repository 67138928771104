import { DashboardChart } from "../models/dashboard-chart"
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core'
import { apiHost, getHttpPostOptions } from "../../core/http-options";

interface GPTAsk {
  response: string
  charts: DashboardChart[]
  debug: string
  is_valid: boolean
  chat_message_id: number
}

interface GPTHistory {
  id: number
  created_at: string
  message: string
}

@Injectable({
  providedIn: 'root',
})
export class GptService {

  constructor(private http: HttpClient) { }

  ask(body) {
    return this.http.post<GPTAsk>(
      `${apiHost}/twng/dashboard/gpt/ask`,
      body,
      getHttpPostOptions()
    )
  }

  history() {
    return this.http.get<GPTHistory[]>(
      `${apiHost}/twng/dashboard/gpt/history.json`,
      getHttpPostOptions()
    )
  }

  destroyHistoryQuestion(id) {
    return this.http.delete(
      `${apiHost}/twng/dashboard/gpt/destroy_question/${id}`,
      getHttpPostOptions()
    )
  }

  destroyAllHistory() {
    return this.http.delete(
      `${apiHost}/twng/dashboard/gpt/destroy_history`,
      getHttpPostOptions()
    )
  }
}
