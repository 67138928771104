import { AppConfigService } from '../../../services/app-config.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard'
import { Job } from '../../../models/job';
import { JobViewMode } from '../../../reducers/layout.reducer'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { selectImmediatelySync } from '../../../../shared/utils/store.utils'
import { selectJobViewModeByJobId } from '../../../reducers'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-job-action-buttons',
  templateUrl: './job-action-buttons.component.html',
  styleUrls: ['./job-action-buttons.component.scss']
})
export class JobActionButtonsComponent implements OnInit {

  @Input()
    job: Job

  @Input()
    jobCollapsed = false

  @Input()
    showAnalytics = false

  @Input()
    greenHouseUrl: string

  @Input()
    atsName: string

  @Input()
    atsIconUrl: string

  @Output()
    selectCards = new EventEmitter<void>()

  @Output()
    selectAnalytics = new EventEmitter<void>()

  @Output()
    collapse = new EventEmitter<void>()

  @Output()
    editJobStatus = new EventEmitter<void>()

  canViewJobStatus = false

  constructor(
    private appConfig: AppConfigService,
    private clipboard: ClipboardService,
    private toastr: ToastrService,
    private store: Store,
  ) {
  }

  ngOnInit() {
    this.canViewJobStatus = this.appConfig.canViewJobStatusOnWall()
  }

  onCollapse() {
    this.collapse.emit()
  }

  onSelectCards() {
    this.selectCards.emit()
  }

  onSelectAnalytics() {
    this.selectAnalytics.emit()
  }

  copyLinkToClipboard() {
    const jobId = this.job?.id
    const currentViewingMode = selectImmediatelySync(this.store, selectJobViewModeByJobId(jobId))
    const baseUrl = window.location.host + '/wall-shared-single-job/' + jobId
    this.clipboard.copy(baseUrl + '?viewMode=' + JobViewMode[currentViewingMode])
    this.toastr.success("Link to the job was copied to clipboard", this.job.name)
  }
}
