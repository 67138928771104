<div class="d-flex">
  <div class="d-flex align-items-center" [class.flex-wrap]="!chart" [class.justify-content-center]="!!chart" [class.expand-icons]="!!filters" [class.shrink]="shouldShrinkIcons">
    <twng-date-chart-filter [filtersEnabled]="filtersEnabled" [filters]="filters" [isTempDashboard]="isTempDashboard"
      (onFiltersChanged)="onFiltersChanged.emit($event)" [panel]="panel" [chart]="chart"
      [chartFiltersReadOnly]="chartFiltersReadOnly" (openFilters)="openFilters($event)" [dateFilterForm]="dateFilterForm">
    </twng-date-chart-filter>
  
    <twng-department-single-chart-filter [filtersEnabled]="filtersEnabled" [filters]="filters"
      [isTempDashboard]="isTempDashboard" (onFiltersChanged)="onFiltersChanged.emit($event)" [panel]="panel"
      [chart]="chart" [chartFiltersReadOnly]="chartFiltersReadOnly" (openFilters)="openFilters($event)">
    </twng-department-single-chart-filter>
  
    <twng-office-single-chart-filter [filtersEnabled]="filtersEnabled" [filters]="filters"
      [isTempDashboard]="isTempDashboard" (onFiltersChanged)="onFiltersChanged.emit($event)" [panel]="panel"
      [chart]="chart" [chartFiltersReadOnly]="chartFiltersReadOnly" (openFilters)="openFilters($event)">
    </twng-office-single-chart-filter>
  
    <twng-job-single-chart-filter [filtersEnabled]="filtersEnabled" [filters]="filters"
      [isTempDashboard]="isTempDashboard" (onFiltersChanged)="onFiltersChanged.emit($event)" [panel]="panel"
      [chart]="chart" [chartFiltersReadOnly]="chartFiltersReadOnly" (openFilters)="openFilters($event)">
    </twng-job-single-chart-filter>
  
    <twng-user-single-chart-filter [filtersEnabled]="filtersEnabled" [filters]="filters"
      [isTempDashboard]="isTempDashboard" (onFiltersChanged)="onFiltersChanged.emit($event)" [panel]="panel"
      [chart]="chart" [chartFiltersReadOnly]="chartFiltersReadOnly" (openFilters)="openFilters($event)">
    </twng-user-single-chart-filter>
  
    <twng-source-single-chart-filter [filtersEnabled]="filtersEnabled" [filters]="filters"
      [isTempDashboard]="isTempDashboard" (onFiltersChanged)="onFiltersChanged.emit($event)" [panel]="panel"
      [chart]="chart" [chartFiltersReadOnly]="chartFiltersReadOnly" (openFilters)="openFilters($event)">
    </twng-source-single-chart-filter>
  
    <twng-custom-fields-single-chart-filter [filtersEnabled]="filtersEnabled" [filters]="filters"
      [isTempDashboard]="isTempDashboard" (onFiltersChanged)="onFiltersChanged.emit($event)" [panel]="panel"
      [chart]="chart" [chartFiltersReadOnly]="chartFiltersReadOnly" (openFilters)="openFilters($event)">
    </twng-custom-fields-single-chart-filter>
  </div>

  <button class="fs-unmask btn btn-light tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-cq-gray-600 tw-border tw-border-cq-gray-300 tw-bg-[#FFFFFF] hover:tw-bg-cq-gray-50 tw-hover:text-cq-gray-700 tw-text-sm tw-py-2 tw-px-3 tw-whitespace-nowrap" *ngIf="showClearAll && !chart && !isTempDashboard" (click)="clearAll()">
    Clear all
  </button>

  <button *ngIf="overrideFiltersSet && isTempDashboard" class="btn btn-danger" ngbTooltip="Reset Chart Filters"
    placement="top auto" (click)="resetChartFilters.emit()" data-html2canvas-ignore>
    Clear Override Filters
  </button> 
</div>

<div *ngIf="showFilters && !chart" [ngClass]="{'pt-3' : !managers}" class="d-flex tw-items-center">
  <div class="d-flex flex-wrap" *ngIf="!showDateFilters">
    <ng-container *ngFor="let manager of managers; index as i">
      <div *ngIf="i === 5" style="width: 100%;"></div>
      <div class="filter-input-wrapper">
        <twng-filter-search-typeahead [manager]="manager">
        </twng-filter-search-typeahead>
      </div>
    </ng-container>
  </div>

  <twng-date-chart-filter-form *ngIf="showDateFilters" [dateFilterForm]="dateFilterForm" [filters]="filters" style="padding-top: 10px;">
  </twng-date-chart-filter-form>

  <div class="filter-submit-wrapper d-flex tw-items-center">
    <button *ngIf="showClearDate" class="button--link fs-unmask tw-pr-3" (click)="clearDate()">
      Clear
    </button>

    <button class="button--large fs-unmask tw-mr-3 tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-cq-gray-600 tw-border tw-border-cq-gray-300 tw-bg-white hover:tw-bg-cq-gray-50 tw-hover:text-cq-gray-700 tw-text-sm tw-p-3" (click)="cancel()">
      Cancel
    </button>
    <button type="submit" class="button--primary button--large fs-unmask tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-white tw-bg-cq-primary-600 tw-text-sm tw-p-3" (click)="apply()">
      Apply
    </button>
  </div>
</div>