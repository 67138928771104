import {
  FetchWallDataPaginated,
  FetchWallDataPaginatedFailure,
  FetchWallDataPaginatedSuccess,
  FetchWallDataPaginatedTab,
  FetchWallDataPaginatedTabSuccess,
  WallDataPaginatedResponse
} from "../actions/wall.actions"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { WallSearchingServiceDefinition } from "./wall-searching.service-definition"
import { apiHost, getHttpPostOptions, httpGetOptions } from "../../core/http-options"
import { candidateFromApiResponse } from "../reducers/candidate.reducer"
import { catchError, map, retry } from "rxjs/operators"
import { jobApplicationFromApiResponse } from "../reducers/job-application.reducer"
import { of } from "rxjs"

function transformForFrontendData(response: WallDataPaginatedResponse) {
  const ret: WallDataPaginatedResponse = {
    ...response,
    candidates: response.candidates.map(candidateFromApiResponse),
    job_applications: response.job_applications.map(jobApplicationFromApiResponse),
  }
  return ret
}

@Injectable({
  providedIn: 'root'
})
export class WallSearchingService implements WallSearchingServiceDefinition {

  constructor(private http: HttpClient) {}

  searchAllJobs(value: FetchWallDataPaginated) {
    return this.http.post(apiHost + '/twng/wall.json', value.payload, getHttpPostOptions())
      .pipe(
        retry(1),
        map((response: WallDataPaginatedResponse) =>
          new FetchWallDataPaginatedSuccess({
            ...transformForFrontendData(response),
            frontendFetchId: value.payload.frontendFetchId,
          })),
        catchError(() => of(new FetchWallDataPaginatedFailure()))
      ).toPromise()
  }

  searchJobsInTab(value: FetchWallDataPaginatedTab) {
    const httpCall = window.twng_demo ?
      this.http.get(apiHost + `/twng/wall/${value.payload.tab_id}.json`, httpGetOptions) :
      this.http.post(apiHost + '/twng/wall.json', value.payload, getHttpPostOptions())
    return httpCall.pipe(
      retry(1),
      map((response: WallDataPaginatedResponse) =>
        new FetchWallDataPaginatedTabSuccess({
          ...transformForFrontendData(response),
          frontendFetchId: value.payload.frontendFetchId,
        })),
      catchError(() => of(new FetchWallDataPaginatedFailure()))
    ).toPromise()
  }
}
