import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es'

@Injectable()
// Component state is a mechanism for restoring data when component is
// re-created. You should call ".store" before your component is destroyed and
// .tryGetAndDestroy before any you try to call any API, or render something.
//
// Usage is VERY SIMPLE: It is simply a hash, but once you get the value, it is
// destroyed afterwards.
//
// This component is purposely not provided globally (in root) because it is
// not a good idea for the store chace to live forever.
//
// This is useful on wall for example, when we already have some data and
// configuration saved for job analytics on wall and we want to restore that
// state when component is restored due to virtual scrolling (components are
// destroyed when they are not visible on screen).
export class ComponentStateService<CacheType> {
  private data: {[cacheKey: string]: CacheType} = {}

  constructor() { }

  // Store data with the cache key
  store(cacheKey: string, value: CacheType) {
    // console.log("Storing to cache:", cacheKey, value)
    this.data[cacheKey] = cloneDeep(value)
  }

  // Retreive data from cache and destroy it if it was in cache. If key was not
  // found, undefined is returned.
  tryGetAndDestroy(cacheKey: string): CacheType {
    const ret = this.data[cacheKey]
    // if (ret) {
    //   console.log("Retreiveing from cache:", cacheKey, ret)
    // }
    delete this.data[cacheKey]
    return ret
  }
}
