<div class="analytics-wrap summary-row" setClientHeight *ngIf="loaded; else spinner"
  [class.exporting-pdf]="exportingToPDF$ | async">

  <div class="tw-flex tw-justify-between" data-html2canvas-ignore>
    <h1 class="tw-text-2xl tw-mb-2 tw-mt-4">
      Stages Summary
    </h1>

    <div class="tw-flex">
      <twng-toggle-stages-phases [tabId]="tab.id" *ngIf="!isSharedTab" class="tw-mr-4"></twng-toggle-stages-phases>

      <twng-analytics-date-range-filters (dateRangeChanged)="dateFiltersChanged($event)"
        [initialDateFilters]="getSummaryDateFiltersForTab()" [showActive]="true" [disabled]="isSharedTab">
      </twng-analytics-date-range-filters>
    </div>
  </div>
  <hr class="tw-my-2 tw-py-1 tw-border-cq-gray-300 tw-w-full">

  <div class="analytics">
    <div class="stages">
      <twng-dropoff-graph *ngFor="let stageName of jobStageNames; index as i"
        [conversionRates]="summaryResponse.conversion_rates[stageName]" [stageName]="stageName"
        [jobName]="(tab.name || 'Open Jobs') + ' summary'"
        [timeInterviewing]="summaryResponse.time_interviewing[stageName]"
        [candidatesInterviewed]="summaryResponse.candidates_interviewed[stageName]"
        [isOfferAcceptenceRateStage]="i === jobStageNames.length - 1" [yAxisMax]="yAxisMax">
      </twng-dropoff-graph>
      <twng-empty-dropoff-graph [jobName]="(tab.name || 'Open Jobs') + ' summary'" [hiredOfferIds]="hiresIds"
        [areIdsJobApplicationIds]="false">
      </twng-empty-dropoff-graph>
    </div>
    <div class="sidebar sidebar--right">
      <div class="stats flex-row tw-items-start">
        <!-- Jobs -->
        <div class="big-num secondary-text" [class.clickable]="jobIds?.length" (click)="openJobsModal()">
          <div class="big-num-value">{{ jobIds?.length || 0 }}</div>
          <div class="big-num-label">Open Jobs</div>
        </div>
        <div class="icon-divider"></div>
        <!-- Openings -->
        <div class="big-num secondary-text" [class.clickable]="openingIds?.length" (click)="openOpeningsModal()">
          <div class='big-num-value'>{{ openingIds?.length || 0 }}</div>
          <div class='big-num-label'>Openings</div>
        </div>
      </div>
      <!-- Projected Hires -->
      <div class="stats">
        <header class="stats__label fs-unmask">
          <i class="fas fa-info-circle small-icon"
            ngbTooltip="This is the sum of all the projected hires for included jobs, not affected by date filters."></i>
          Projected Hires
        </header>
        <div class="stats__big-number-wrapper">
          <div class="stats__big-number stats__big-number--sidebar">
            <ng-container *ngIf="numProjectedHires; else spinnerProjectedHires">
              {{ numProjectedHires }}
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Hires -->
      <div class="stats">
        <header class="stats__label fs-unmask">
          <i class="fas fa-info-circle small-icon"
            ngbTooltip="Total number of hires where the candidate’s application date in Greenhouse is within the selected date range."></i>
          Hires
        </header>
        <div class="stats__big-number-wrapper">
          <div class="stats__big-number stats__big-number--sidebar"
            [class.stats__big-number--clickable]="hiresIds?.length" (click)="openHiresModal()">
            {{ hiresIds?.length || 0 }}
            <i class="fas fa-users small-icon"></i>
          </div>
        </div>
      </div>
      <!-- Time to hire -->
      <div class="stats">
        <header class="stats__label fs-unmask">
          <i class="fas fa-info-circle small-icon"
            ngbTooltip="Time to Hire is the average number of days between the date a candidate’s application was created and the offer acceptance date. The candidates represented here are those whose offer acceptance date falls in this date range selected."></i>
          {{ summaryResponse?.time_to_hire?.name }}
        </header>
        <div class="stats__big-number-wrapper">
          <div class="stats__big-number stats__big-number--sidebar"
            [class.stats__big-number--clickable]="summaryResponse?.time_to_hire?.value" (click)="openTimeToHireModal()">
            {{ summaryResponse?.time_to_hire?.value || 0 }}
            <i class="fas fa-clock small-icon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <twng-spinner></twng-spinner>
</ng-template>
<ng-template #spinnerProjectedHires>
  <i class="fas fa-sync fa-spin"></i>
</ng-template>