<div *ngIf="gridItemType === 'css' && data" #widget class="widget widget--css pie">
  <div class="header tw-border-none">
    <twng-chart-label [chart]="chart" [label]="label"
      [chartInfo]="(dataSources && dataSources[dataSource]) ? dataSources[dataSource].description: null">
    </twng-chart-label>

    <div class="header-actions" data-html2canvas-ignore>
      <button type="button" class="actions-button actions-button--copy-to-clipboard" [ngbTooltip]="copyTooltip"
        triggers="mouseenter:mouseleave" aria-label="Copy to Clipboard" [disabled]="copyingToClipboard"
        (click)="copyChartToClipboard(widget, legendInCopiedImg, filtersInCopiedImg)">
        <i *ngIf="!copyingToClipboard" class="far fa-copy"></i>
        <i *ngIf="copyingToClipboard" class="fas fa-sync fa-spin"></i>
      </button>

      <twng-chart-drop-down-menu [label]="label" [chart]="chart" [dataSource]="dataSource" [chartStatus]="status"
        [dataSourceParameters]="dataSourceParameters" [chartFilters]="chartFilters" [chartType]="'pie'"
        [hideFilters]="true" *ngIf="!chartFiltersReadOnly">
      </twng-chart-drop-down-menu>
    </div>
  </div>

  <div [ngClass]="widgetBodyClasses()">
    <div class="content-overflow js-content-overflow--vertical">
      <div class="ngx-responsive-wrapper">
        <ngx-charts-pie-chart *ngIf="customColors" [id]="camelCase(label)" [results]="data" [customColors]="customColors" 
          [labels]="true" [labelFormatting]="formatLabel" [legend]="true" [tooltipText]="formatToolTip" (select)="sliceClicked($event)" [animations]="false">
        </ngx-charts-pie-chart>

        <ngx-charts-pie-chart *ngIf="colorScheme && !customColors" [id]="camelCase(label)" [results]="data" [scheme]="colorScheme"
        [labels]="true" [labelFormatting]="formatLabel" [tooltipText]="formatToolTip" [legend]="true" (select)="sliceClicked($event)" [animations]="false">
        </ngx-charts-pie-chart>
      </div>
    </div>
  </div>

  <div class="filters" *ngIf="chart && chartFilters">
    <twng-chart-filters [filters]="chartFilters" [filtersEnabled]="filtersEnabled" [chart]="chart" [overrideFiltersSet]="overrideFiltersSet">
    </twng-chart-filters>
  </div>
</div>

<div *ngIf="gridItemType === 'gridster' && data" #widget class="widget widget--gridster pie--gridster">
  <twng-gridster-item-overlay *ngIf="editingGridster"></twng-gridster-item-overlay>

  <div class="header tw-border-none" [class.pointer-events--none]="editingGridster">
    <twng-chart-label [chart]="chart" [label]="label"
      [chartInfo]="(dataSources && dataSources[dataSource]) ? dataSources[dataSource].description: null">
    </twng-chart-label>

    <div class="header-actions" data-html2canvas-ignore>
      <button type="button" class="actions-button actions-button--copy-to-clipboard" [ngbTooltip]="copyTooltip"
        triggers="mouseenter:mouseleave" aria-label="Copy to Clipboard" [disabled]="copyingToClipboard"
        (click)="copyChartToClipboard(widget, legendInCopiedImg, filtersInCopiedImg)">
        <i *ngIf="!copyingToClipboard" class="far fa-copy"></i>
        <i *ngIf="copyingToClipboard" class="fas fa-sync fa-spin"></i>
      </button>

      <twng-chart-drop-down-menu [label]="label" [chart]="chart" [dataSource]="dataSource" [chartStatus]="status"
        [dataSourceParameters]="dataSourceParameters" [chartFilters]="chartFilters" chartType="pie" [hideFilters]="true"
        *ngIf="!chartFiltersReadOnly">
      </twng-chart-drop-down-menu>
    </div>
  </div>

  <div class="body" [class.pointer-events--none]="editingGridster">
    <ngx-charts-pie-chart *ngIf="customColors" [id]="camelCase(label)" [results]="data" [customColors]="customColors"
    [labels]="true" [labelFormatting]="formatLabel" [tooltipText]="formatToolTip" [view]="view" [legend]="true" (select)="sliceClicked($event)" [animations]="false">
    </ngx-charts-pie-chart>

    <ngx-charts-pie-chart *ngIf="colorScheme && !customColors" [id]="camelCase(label)" [results]="data" [scheme]="colorScheme"
    [labels]="true" [labelFormatting]="formatLabel" [tooltipText]="formatToolTip" [view]="view" [legend]="true" (select)="sliceClicked($event)" [animations]="false"> 
    </ngx-charts-pie-chart>
  </div>
  <div class="filters" [class.pointer-events--none]="editingGridster" *ngIf="chart && chartFilters"
    data-html2canvas-ignore>
    <twng-chart-filters [filters]="chartFilters" [filtersEnabled]="filtersEnabled" [chart]="chart"
      [chartFiltersReadOnly]="chartFiltersReadOnly" [overrideFiltersSet]="overrideFiltersSet"></twng-chart-filters>
  </div>
</div>

<ng-template #copyTooltip>
  <div>Copy to Clipboard</div>
  <i class="fas fa-flask"></i>&nbsp;
  <span>Chrome only</span>
</ng-template>
