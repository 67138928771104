/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSticker]'
})
export class StickerDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef<HTMLElement>) { }

  ngAfterViewInit(): void {
    if ((window as any).sticker) {
      (window as any).sticker(this.elementRef.nativeElement, true)
    }
  }

}
