import { DepartmentWithChildren } from '../../../wall/models/department'
import { OfficeWithChildren } from '../../../wall/models/office'
import { SourceWithChildren } from '../../../wall/models/source'

type ItemWithChildren = DepartmentWithChildren | OfficeWithChildren | SourceWithChildren

/**
 * Given a item (department or office), it will remove its children and grandchildren and so on recursively
 *
 * @param The list of all departments or offices
 * @param parent The parent department or office that contains the children to be removed
 * @return A new ItemWithChildren[]
 */
const removeChildrenItems = <T extends ItemWithChildren>(itemsWithChildren: T[], parent: T): T[] => {
  // Remove my children from the list
  let itemsWithoutMyChildren = itemsWithChildren.filter(item => item.parent_id !== parent.id)

  // if I have children
  if (parent.children && parent.children.length) {
    // Remove the children of my children from the list recursively
    parent.children.forEach((childItem) => {
      itemsWithoutMyChildren = removeChildrenItems(itemsWithoutMyChildren, childItem)
    })
  }
  return itemsWithoutMyChildren
}


/**
 * Given the list of items and the selected items, it removes the selected items
 * with its ALL children and grandchildren and so on returning a clean list
 * of items to select
 *
 * @param itemsList Entire list if Items ( DepartmentWithChildren | OfficeWithChildren)
 * @param selectedItems Items already selected
 */
export const filterSelectedItemsWithChildren =
  <T extends ItemWithChildren>(itemsList: T[], selectedItems: T[]): T[] => {
  // By default, all items are unselected
    let unselectedItems = itemsList || []

    // Remove the children (and grandchildren and so on) from the unselected items list
    selectedItems.forEach(item => {
      unselectedItems = removeChildrenItems(unselectedItems, item)
    })

    // Remove the selected items from the unselectedItems list
    unselectedItems = unselectedItems.filter(d => !selectedItems.includes(d))

    return unselectedItems
  }
