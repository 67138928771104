import { BehaviorSubject, throwError } from "rxjs"
import { HttpClient, HttpErrorResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { apiHost, getHttpPostOptions, httpGetOptions } from "../../core/http-options"
import { catchError, first } from "rxjs/operators"

export type SlackReceiver = SlackChannel | SlackUser

interface SlackBaseReceiver {
  id: string
  name: string
}

export interface SlackChannel extends SlackBaseReceiver {
  is_private: boolean
}

export interface SlackUser extends SlackBaseReceiver {
  image_24: string
}

export interface SlackResponse {
  channels: SlackChannel[]
  users: SlackUser[]
}

export enum SlackBlocks {
  SHARE_DASHBOARD
}

@Injectable({
  providedIn: 'root'
})
export class SlackService {
  private channelData$ = new BehaviorSubject<SlackResponse>(null)
  private isFetchingChannelData = false

  constructor(
    private http: HttpClient,
  ) {}

  private errorHandler = catchError(err => {
    if (err instanceof HttpErrorResponse) {
      return throwError(new Error(err.error?.error || err.message))
    }
  })

  private async startFetchingChannelDataIfNecessary() {
    if (!this.isFetchingChannelData) {
      this.isFetchingChannelData = true
      try {
        const data = await this.http.get<SlackResponse>(
          apiHost + '/twng/slack/channels', httpGetOptions
        ).toPromise()
        this.channelData$.next(data)
      } finally {
        this.isFetchingChannelData = false
      }
    }
  }

  getChannels() {
    this.startFetchingChannelDataIfNecessary()
    return this.channelData$.pipe(first(v => !!v)).toPromise()
  }

  shareDashboard(receiverId: string, dashboardId: number) {
    return this.http.post(
      apiHost + `/twng/dashboard/tabs/${dashboardId}/share_with_slack`, {
        channel: receiverId,
      },
      getHttpPostOptions(),
    )
      .pipe(this.errorHandler)
      .toPromise()
  }

  sendPlainMessage(receiverId: string, text: string) {
    return this.http.post(
      apiHost + `/twng/slack/post_message`, {
        text,
        channel: receiverId,
      },
      getHttpPostOptions(),
    )
      .pipe(this.errorHandler)
      .toPromise()
  }
}
