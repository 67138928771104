import {
  RejectCandidateUIActionTypes,
  RejectCandidateUIActions,
} from '../actions/reject-candidate-ui.actions'
import { atsId } from '../../wall/models/types'
import { updateStatePerJobApplication } from './shared'

export interface State {
  [id: string]: {
    selectedRejectionReasonId: atsId
    selectedEmailTemplateId: atsId
    selectedRejectionReasonText: string
    showError: boolean
    showNetworkError: boolean
    sendEmailAt: string | null
  }
}

const initialJobAppState = {
  selectedRejectionReasonId: null,
  selectedEmailTemplateId: null,
  showError: false,
  showNetworkError: false,
  sendEmailAt: null,
}

const initialState: State = {}

export function reducer(
  state = initialState,
  action: RejectCandidateUIActions,
): State {
  switch (action.type) {
    case RejectCandidateUIActionTypes.UpdateSendEmailAt: {
      const { jobApplicationId, sendEmailAt } = action.payload
      return updateStatePerJobApplication(state, jobApplicationId, {
        sendEmailAt,
      })
    }

    case RejectCandidateUIActionTypes.UpdateSelectedRejectionReason: {
      const { jobApplicationId, rejectionReasonId } = action.payload
      return updateStatePerJobApplication(state, jobApplicationId, {
        selectedRejectionReasonId: rejectionReasonId,
      })
    }

    case RejectCandidateUIActionTypes.UpdateSelectedRejectionReasonText: {
      const { jobApplicationId, rejectionReasonText } = action.payload
      return updateStatePerJobApplication(state, jobApplicationId, {
        selectedRejectionReasonText: rejectionReasonText,
      })
    }

    case RejectCandidateUIActionTypes.UpdateSelectedEmailTemplate: {
      const { jobApplicationId, emailTemplateId } = action.payload

      return updateStatePerJobApplication(state, jobApplicationId, {
        selectedEmailTemplateId: emailTemplateId,
      })
    }

    case RejectCandidateUIActionTypes.RejectCandidateSuccess: {
      const { jobApplicationId } = action.payload
      return updateStatePerJobApplication(
        state,
        jobApplicationId,
        initialJobAppState,
      )
    }

    case RejectCandidateUIActionTypes.RejectCandidateFailure: {
      const { jobApplicationId, networkError } = action.payload
      return updateStatePerJobApplication(state, jobApplicationId, {
        showError: !networkError,
        showNetworkError: networkError,
      })
    }

    default: {
      return state
    }
  }
}
