import { ToastrService } from 'ngx-toastr'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import {
  LoadProspectsFailure, LoadProspectsSuccess, Prospects, ProspectsActionsTypes
} from '../actions/prospects.actions'
import { apiHost, httpGetOptions } from '../../core/http-options'

@Injectable({ providedIn: 'root' })
export class ProspectsEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private toastr: ToastrService) {
  }

  loadProspects$ = createEffect(() => this.actions$.pipe(
    ofType(ProspectsActionsTypes.LoadProspects),
    mergeMap(() => {
      const url = `${apiHost}/twng/prospects.json`
      return this.http.get<Prospects>(url, httpGetOptions).pipe(
        map((prospects: Prospects) => new LoadProspectsSuccess(prospects)),
        catchError((e) => {
          this.toastr.error('An error occurred loading Prospects')
          return of(new LoadProspectsFailure(e))
        })
      )
    }),
  ))
}
