import { CacheService } from "../../../wall/services/cache.service"
import { ChartCommonComponent } from "../chart-common.component"
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { DashboardChart } from "../../models/dashboard-chart"
import { Store } from "@ngrx/store"

@Component({
  selector: 'twng-table-chart',
  templateUrl: './table-chart.component.html',
  styleUrls: [
    '../dashboard.shared.component.scss',
    '../dashboard.component.scss',
    '../dashboard.gridster.component.scss',
    './table-chart.component.scss',
  ],
})
export class TableChartComponent extends ChartCommonComponent implements OnInit, OnChanges {
  @Input()
    dataSource: string

  @Input()
    dataSourceParameters: { [key: string]: string }

  @Input()
    chart: DashboardChart

  @Input()
    chartType: string

  @Input()
    editingGridster: boolean

  @Input()
    gridItemType: 'css' | 'gridster' = 'css'

  copyingToClipboard = false

  tableData

  constructor(
    store: Store,
    cacheService: CacheService,
  ) {
    super(store, cacheService)
  }

  ngOnInit(): void {
    this.tableData = this.chart.data.data
    super.ngOnInit()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chart) {
      this.tableData = this.chart.data.data
    }
  }

  getColumnHeader(name: string) {
    if (name.includes("/")) {
      name = name.split("/")[1]
    }

    return name.replaceAll("_", " ")
  }

  arrayLength(value) {
    if (Array.isArray(value)) {
      return value.length
    }
    return 0
  }

  getRowData(data) {
    if (this.arrayLength(data)) {
      return data[0]
    } else if (data == undefined) {
      return ""
    } else if (typeof data === "object" && !Array.isArray(data)) {
      return data.values
    }

    return data
  }

  getTooltip(data) {
    if (this.arrayLength(data) > 1) {
      return data.join(", ")
    } else if (data == undefined) {
      return ""
    } else if (typeof data === "object" && !Array.isArray(data)) {
      return data.tooltip
    } else if (data.length > 35) {
      return data
    }
  }

  getHeaderTooltip(name) {
    if (name.includes("/")) {
      const text = name.split("/")[0]
      if (text === "phase") {
        return "Phase candidates and conversion rate"
      }
      if (text === "stage") {
        return "Stage candidates and conversion rate"
      }
      return `${text.charAt(0).toUpperCase()}${text.slice(1)}`
    }
  }
}
