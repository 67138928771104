/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'

import { Activity, ActivityEmail } from '../models/activity'
import { Candidate } from '../models/candidate'
import { ExternalUser } from '../models/external-user'
import { Note } from '../models/note'
import { atsId } from '../models/types'

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum NoteActionTypes {
  FetchNotes = '[Notes] FetchNotes',
  FetchNotesSuccess = '[Notes] FetchNotesSuccess',
  FetchNotesFailure = '[Notes] FetchNotesFailure',
  AddNote = '[Notes] AddNote',
  AddNoteSuccess = '[Notes] AddNoteSuccess',
  AddNoteFailure = '[Notes] AddNoteFailure',
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */

export interface FetchNotesPayload {
  candidate: Candidate
}

export class FetchNotes implements Action {
  readonly type = NoteActionTypes.FetchNotes
  constructor(public payload: FetchNotesPayload) { }
}

export interface NotesPayload {
  notes: Note[]
  activities: Activity[]
  emails: ActivityEmail[]
}

export interface FetchNotesSuccessPayload extends NotesPayload {
  candidate: Candidate
}

export class FetchNotesSuccess implements Action {
  readonly type = NoteActionTypes.FetchNotesSuccess
  constructor(public payload: FetchNotesSuccessPayload) { }
}

export class FetchNotesFailure implements Action {
  readonly type = NoteActionTypes.FetchNotesFailure
  constructor() { }
}

export interface AddNotePayload {
  candidate_id: atsId
  body: string
  isPublic: boolean
  mentionedUsers: Array<ExternalUser>
}

export interface AddNoteResponse {
  note: Note
}

export class AddNote implements Action {
  readonly type = NoteActionTypes.AddNote
  constructor(public payload: AddNotePayload) { }
}

export class AddNoteSuccess implements Action {
  readonly type = NoteActionTypes.AddNoteSuccess
  constructor(public payload: Note) { }
}

export class AddNoteFailure implements Action {
  readonly type = NoteActionTypes.AddNoteFailure
  constructor() { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type NoteActions =
  | FetchNotes
  | FetchNotesSuccess
  | FetchNotesFailure
  | AddNote
  | AddNoteSuccess
  | AddNoteFailure
