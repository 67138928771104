/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'
import { JobApplication } from '../../wall/models/job-application'

export enum UpdateStarredActionTypes {
  UpdateStarred = '[UpdateStarred] UpdateStarred',
  UpdateStarredSuccess = '[UpdateStarred] UpdateStarredSuccess',
  UpdateStarredFailure = '[UpdateStarred] UpdateStarredFailure',
}

export class UpdateStarred implements Action {
  readonly type = UpdateStarredActionTypes.UpdateStarred
  constructor(public payload: { jobApplication: JobApplication }) {}
}
export class UpdateStarredSuccess implements Action {
  readonly type = UpdateStarredActionTypes.UpdateStarredSuccess
  constructor(
    public payload: { requestData: unknown; jobApplication: JobApplication },
  ) {}
}
export class UpdateStarredFailure implements Action {
  readonly type = UpdateStarredActionTypes.UpdateStarredFailure
  constructor(
    public payload: { requestData: unknown; jobApplication: JobApplication },
  ) {}
}

export type StarredActions =
  | UpdateStarred
  | UpdateStarredSuccess
  | UpdateStarredFailure
