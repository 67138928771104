import { CardDetailsActionTypes, CardDetailsActions } from '../actions/card-details.actions'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import {
  LoaderActions,
} from '../../core/actions/loader.actions'
import { Scorecard } from '../models/scorecard'

export type State = EntityState<Scorecard>

export const adapter: EntityAdapter<Scorecard> = createEntityAdapter<Scorecard>(
  {},
)

export const initialState: State = adapter.getInitialState()

// TODO: remove this
export function reducer(state = initialState, action: LoaderActions | CardDetailsActions): State {
  switch (action.type) {
    case CardDetailsActionTypes.FetchCardDetailsSuccess: {
      const scorecards = action.payload.scorecards
      if (scorecards) {
        return adapter.upsertMany(scorecards, state)
      }
      return state
    }

    default: {
      return state
    }
  }
}
