import { AppConfigService } from '../../../wall/services/app-config.service'
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DESTINATION_SLACK, FREQUENCY_TYPE_DAILY, FREQUENCY_TYPE_MONTHLY,
  FREQUENCY_TYPE_WEEKLY, ScheduledReport, ScheduledReportFrequencyType } from '../scheduled-report.model'
import { DashboardTab } from '../../../dashboard/models/dashboard-tab'
import { NgbActiveModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap'
import { Observable, Subscription } from 'rxjs'
import { ScheduledReportService } from '../scheduled-report.service'
import { SelectOption } from '../../../shared/components/select/select.component'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { concatMapTo, first } from 'rxjs/operators'
import { daysOfWeek } from '../../../shared/utils/general-utils'
import { isEqual, range } from 'lodash-es'
import { loadDashboardIfNecessary } from '../../../shared/utils/store.utils'
import { selectAllDashboardTabs } from '../../../dashboard/reducers'
import { selectDashboardLoadCompleted } from '../../../reducers'

@Component({
  selector: 'twng-schedule-new-report-modal',
  templateUrl: './schedule-new-report-modal.component.html',
  styleUrls: ['./schedule-new-report-modal.component.css']
})
export class ScheduleNewReportModalComponent implements OnInit, OnDestroy {

  form = new UntypedFormGroup({
    destination: new UntypedFormControl(null, Validators.required),
    destination_type: new UntypedFormControl(null, Validators.required),
    frequency: new UntypedFormControl(null, Validators.required),
    hours: new UntypedFormControl(null, Validators.required),
    minutes: new UntypedFormControl(null, Validators.required),
    repetition_day: new UntypedFormControl(null, Validators.required),
    dashboard_tab_id: new UntypedFormControl(null, Validators.required),
    dashboard_tab_view_token: new UntypedFormControl(),
    text: new UntypedFormControl("Check out my TalentWall Analytics Dashboard!",
      [Validators.required, Validators.minLength(3)])
  })
  private editingId: number

  frequencyOptions: SelectOption[] = [{
    id: FREQUENCY_TYPE_DAILY,
    name: 'Daily',
  }, {
    id: FREQUENCY_TYPE_WEEKLY,
    name: 'Weekly',
  }, {
    id: FREQUENCY_TYPE_MONTHLY,
    name: 'Monthly',
  }]

  repetitionOptionsPerFrequency: Record<ScheduledReportFrequencyType, SelectOption[]> = {
    daily: [],
    weekly: range(7).map(val => ({name: daysOfWeek[val], id: val + 1})),
    monthly: range(31).map(val => ({name: (val + 1).toString(), id: val + 1})),
  }

  sub = new Subscription()
  dashboardTabSelector$: Observable<DashboardTab[]>

  constructor(
    private activeModal: NgbActiveModal,
    private store: Store,
    private scheduledReports: ScheduledReportService,
    private toastr: ToastrService,
    public appConfig: AppConfigService,
  ) { }

  ngOnInit(): void {
    this.sub.add(this.form.controls.frequency.valueChanges.subscribe(() => {
      this.form.controls.repetition_day.setValue(null)
      this.updateValidatorsForRepetitionDay()
    }))
    // first wait for dashboard to be loaded and then select all tabs
    this.dashboardTabSelector$ = this.store.select(selectDashboardLoadCompleted).pipe(
      first(Boolean),
      concatMapTo(this.store.select(selectAllDashboardTabs))
    )
    loadDashboardIfNecessary(this.store)
    if (!this.isEditing) {
      this.initForCreate()
    }
  }

  get isEditing() {
    return this.editingId !== undefined
  }

  editReport(reportToEdit: ScheduledReport) {
    this.editingId = reportToEdit.id
    const valueToSet = {
      destination: reportToEdit.destination,
      destination_type: reportToEdit.destination_type,
      frequency: reportToEdit.frequency,
      hours: reportToEdit.hours,
      minutes: reportToEdit.minutes,
      repetition_day: reportToEdit.repetition_day,
      dashboard_tab_id: reportToEdit.dashboard_tab.id,
      dashboard_tab_view_token: reportToEdit.dashboard_tab_view_token,
      text: reportToEdit.text,
    }
    if (!isEqual(Object.keys(this.form.controls).sort(), Object.keys(valueToSet).sort())) {
      throw new Error("Missing values in form")
    }
    this.time = {
      hour: reportToEdit.hours,
      minute: reportToEdit.minutes,
      second: 0,
    }
    this.form.setValue(valueToSet)
    this.updateValidatorsForRepetitionDay()
  }

  scheduleDashboardTab(dashboard: DashboardTab, viewToken?: string) {
    this.form.controls.dashboard_tab_id.setValue(dashboard.id)
    this.form.controls.dashboard_tab_id.disable()
    this.form.controls.dashboard_tab_view_token.setValue(viewToken)
  }

  private initForCreate() {
    this.form.controls.destination_type.setValue(DESTINATION_SLACK)
  }

  private updateValidatorsForRepetitionDay() {
    this.form.controls.repetition_day.setValidators(this.formValue.frequency === 'daily' ? [] : Validators.required)
    this.form.controls.repetition_day.updateValueAndValidity()
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  async save() {
    if (this.form.disabled || this.form.invalid) {
      this.toastr.error("Please fill all fields")
      return
    }
    try {
      const response = this.isEditing ?
        await this.scheduledReports.editReport(this.form.getRawValue(), this.editingId) :
        await this.scheduledReports.createReport(this.form.getRawValue())
      this.activeModal.close(response)
      this.toastr.success('Save success')
    } catch(err) {
      console.log("Error saving report", err)
      this.toastr.error('An error has occured', 'Please try again')
    }
  }

  time: NgbTimeStruct
  timeChanged() {
    if (this.time) {
      if (this.time.hour !== this.formValue.hours || this.time.minute !== this.formValue.minutes) {
        this.form.controls.hours.setValue(this.time.hour)
        this.form.controls.minutes.setValue(this.time.minute)
      }
    }
  }

  get formValue() {
    return this.form.value as ScheduledReport
  }
}
