import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

import { environment } from '../../../environments/environment'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { analytics: any, heap: any }
}

@Injectable({ providedIn: 'root' })
export class SegmentService {
  firstLoad = true

  constructor(private router: Router) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (this.firstLoad) {
          this.firstLoad = false
          // skip the first route load, as it is already tracked by Rails
          return
        }

        this.page()
      }
    })
  }

  page() {
    if (!environment.production) {
      console.log("segment.page")
    }
    if (window.analytics) {
      return window.analytics.page()
    }
  }

  track(name: string, attributes: Record<string, unknown> = {}) {
    if (!environment.production) {
      console.log("heap.track", name, attributes)
    }
    if (window.heap) {
      window.heap.track(name, attributes)
    }
  }
}
