import { BlueColorScheme, RedColorScheme } from "./color-schemes"
import { CacheService } from "../../wall/services/cache.service"
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core"
import { ChartCommonComponent } from "./chart-common.component"
import { ClipboardService } from "../../core/services/clipboard.service"
import { Color, LegendPosition } from "@swimlane/ngx-charts"
import { DashboardChart } from "../models/dashboard-chart"
import { Store } from "@ngrx/store"
import { WidgetDataType } from "../../wall/models/offer"
import { cloneDeep, omit } from "lodash-es"
import { findIdsInChartData } from "../../shared/utils/general-utils"

@Component({
  selector: 'twng-combo-chart',
  templateUrl: './combo-chart.component.html',
  styleUrls: [
    './dashboard.shared.component.scss',
    './dashboard.component.scss',
    './dashboard.gridster.component.scss',
    './combo-chart.component.scss',
  ],
})
export class ComboChartComponent extends ChartCommonComponent implements OnInit {
  @Input()
    candidateDataType: WidgetDataType

  @Input()
    filtersInCopiedImg: boolean

  @Input()
    legendInCopiedImg: boolean

  @Input()
    dataSource: string

  @Input()
    dataSourceParameters: { [key: string]: string }

  @Input()
    chart: DashboardChart

  @Input()
    chartType: string

  @Input()
    editingGridster: boolean

  @Input()
    gridItemType: 'css' | 'gridster' = 'css'

  @Input()
    chartFiltersReadOnly: boolean

  @Input()
    colorScheme: Color = BlueColorScheme

  @Input()
    lineColorScheme: Color = RedColorScheme

  bars: unknown[]
  lines: unknown[]

  view: [number, number]
  private width: number
  private height: number

  copyingToClipboard = false

  legendPosition: LegendPosition.Below

  constructor(
    private clipboardService: ClipboardService,
    private cd: ChangeDetectorRef,
    store: Store,
    cacheService: CacheService,
  ) {
    super(store, cacheService)
  }

  protected onDataUpdated(): void {
    super.onDataUpdated()
    if (this.data) {
      const dataClone = cloneDeep(this.data)
      this.bars = dataClone.filter(value => !value.series)
      this.lines = dataClone.filter(value => value.series)
    } else {
      delete this.bars
      delete this.lines
    }
  }

  copyChartToClipboard(chartEl: Element, legendInCopiedImg: boolean, filtersInCopiedImg: boolean) {
    this.copyingToClipboard = true
    this.clipboardService.copyChartToClipboard(chartEl, legendInCopiedImg, filtersInCopiedImg).finally(() => {
      this.copyingToClipboard = false
      this.cd.detectChanges()
    })
  }

  heightChanged(amount: number) {
    this.height = amount
    this.recalculateView()
  }

  widthChanged(amount: number) {
    this.width = amount
    this.recalculateView()
  }

  private recalculateView() {
    if (!this.height || !this.width) {
      delete this.view
    } else {
      this.view = [this.width, this.height - 50]
    }
  }

  sliceClicked(event): void {
    const ids = findIdsInChartData(event)
    if (ids) {
      this.openModalWithIds(ids, event.name, event.series, event.modal_title_override)
    } else {
      super.sliceClicked(omit(event, 'series'))
    }
  }
}
