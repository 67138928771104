<div class="stage-mappings">
  <div class="stage-mappings__header">
    <h2 class="stage-mappings__title">Configure Interview Phases</h2>
    <i *ngIf="isSyncing$ | async" class="fas fa-sync fa-spin syncing"></i>
  </div>
  <div class="loom-video">
    <a href="https://www.loom.com/share/263901d8444e4103a85186b3b0c3f705" target="_blank">
      Learn to configure Interview Phases
    </a>
  </div>
  <p class="tw-text-cq-warning-700 tw-bg-cq-warning-100 tw-flex tw-flex-col tw-p-2 tw-rounded-md tw-border tw-border-cq-warning-600 tw-w-fit">
    <span>Phase based widgets keeps a snapshot of the phase indices at the time the widget was added to the dashboard.</span>
    <span>If interview phases are later added, removed or reordered, then any phase-based widget needs to be removed and re-added back to their respective dashboards.</span>
  </p>

  <twng-categories-list [categories]="jobStageCategories" [isCreatingCategory]="creatingCategory$ | async"
    (createCategory)="createCategory()" (updateCategoryPosition)="updateCategoryPosition($event)"
    (commitUpdatingCategoryPositions)="commitUpdatingCategoryPositions()">
    <twng-category *ngFor="let category of jobStageCategories" [category]="category"
      (updateCategory)="updateCategory($event)" (removeCategory)="removeCategory($event)"
      (renameCategory)="renameCategory($event)">
    </twng-category>
  </twng-categories-list>

  <twng-job-stage-name-list [jonStageNames]="jobStageNames$ | async"
    (updateCategory)="updateCategory($event)"></twng-job-stage-name-list>
</div>