import { Observable, Subscription } from 'rxjs'
import { ToastrService } from 'ngx-toastr'

import {
  ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges
} from '@angular/core'
import { Store } from '@ngrx/store'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'

import * as fromDashboard from '../../reducers'
import * as fromWall from '../../../wall/reducers'
import { AppConfigService } from '../../../wall/services/app-config.service'
import { ChartFilters } from '../../models/chart-filters'
import { ChartStatus, DashboardChart } from '../../models/dashboard-chart'
import { ChartStatusCheckerService } from '../../../core/services/chart-status-checker.service'
import {
  DashboardRemoveChart, DashboardSendChartToCustom, DashboardSendChartToCustomPayload,
  EnableEditDashboardChartName, UpdateDashboardChart
} from '../../actions/filters.actions'
import { DashboardTab, DashboardTabWithCharts } from '../../models/dashboard-tab'
import { DateFilter } from '../../../shared/components/filters/date-filter/date-filter.interface'
import { SegmentService } from '../../../core/services/segment.service'
import { loadDashboardIfNecessary } from '../../../shared/utils/store.utils'
import { selectDashboardLoadCompleted } from '../../../reducers'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-chart-drop-down-menu',
  templateUrl: './chart-drop-down-menu.component.html',
  styleUrls: [
    './chart-drop-down-menu.component.scss',
  ]
})
export class ChartDropDownMenuComponent implements OnInit, OnChanges {
  demoMode = !!window.twng_demo

  sendChartEnabled: boolean

  // This is used if chart is inside a custom dashboard, where it can be edited
  // or deleted
  @Input()
    chart: DashboardChart

  // This is used to display extra info about data, such as when was it
  // calculated
  @Input()
    chartStatus: ChartStatus

  @Input()
    chartType: string

  @Input()
    dataSource: string

  @Input()
    dataSourceParameters: { [key: string]: string }

  @Input()
    isCustomTitle = false

  @Input()
    chartFilters: ChartFilters

  @Input()
    label: string

  @Input()
    hideFilters = false

  // currently is used only used time ago pipe and we refresh it every time
  // there is a chance it is changed (that is, when we open the dropdown). We
  // use this variable as a clone of original data essentialy because this
  // seems to be the most efficient way to refresh data going into a pipe
  dataTimestamp: Date

  dashboardTabs$: Observable<DashboardTabWithCharts[]>
  isDashboardLoaded$: Observable<boolean>
  refreshingEnabled$: Observable<boolean>

  filtersForm: UntypedFormGroup

  dashboardTabsSubs: Subscription

  tabs: DashboardTab[]

  dateFilter: DateFilter

  constructor(private store: Store<fromWall.State>,
    public appConfig: AppConfigService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private segmentService: SegmentService,
    private chartChecker: ChartStatusCheckerService,
  ) {
  }

  ngOnInit(): void {
    this.dashboardTabsSubs = this.store.select(fromDashboard.selectAllDashboardTabs)
      .subscribe((tabs: DashboardTab[]) => {
        this.tabs = tabs
      })

    if (this.chartFilters) {
      this.filtersForm = this.fb.group({
        dateMode: [this.chartFilters.date_mode],
        startDate: [this.chartFilters.start_date],
        endDate: [this.chartFilters.end_date],
        userIds: [this.chartFilters.user_ids],
        departmentIds: [this.chartFilters.department_ids],
        officeIds: [this.chartFilters.office_ids],
      })
    }
    this.sendChartEnabled = this.appConfig.enableCustomDashboards()
    this.dashboardTabs$ = this.store.select(fromDashboard.selectAllDashboardTabsWithDashboardCharts)
    this.isDashboardLoaded$ = this.store.select(selectDashboardLoadCompleted)
    this.refreshingEnabled$ = this.appConfig.isFeatureFlagEnabled$('chart_caching')
  }

  dropdownOpenChanged(newOpened: boolean) {
    if (newOpened) {
      this.dataTimestamp = this.chartStatus?.timestamp ? new Date(this.chartStatus?.timestamp) : null
      this.loadDashboardIfNecessary()
    }
  }

  private loadDashboardIfNecessary() {
    loadDashboardIfNecessary(this.store)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartFilters && changes.chartFilters.currentValue) {
      this.dateFilter = {
        dateMode: this.chartFilters.date_mode,
        startDate: this.chartFilters.start_date,
        endDate: this.chartFilters.end_date
      }
    }
  }

  createCustomDashboardPayload(dashboardTab: DashboardTab): DashboardSendChartToCustomPayload {
    return {
      dashboardTab: dashboardTab ||
        { id: null, name: 'a new Dashboard', position: this.getNewTabPositionIndex(), sharable_token: '' },
      dataSource: this.dataSource,
      dataSourceParameters: this.dataSourceParameters || this.chart?.data_source_parameters,
      chartType: this.chartType,
      chartName: this.label || "?",
      filters: this.chartFilters,
      isTitle: this.isCustomTitle,
      extra_params: this.chart?.extra_params || {}
    }
  }

  sendChartTo(dashboardTab: DashboardTab) {
    if (window.twng_demo) {
      this.toastr.success("In a live account, this copies the chart to Custom Dashboard, with the selected filters")
    } else {
      this.store.dispatch(
        new DashboardSendChartToCustom(this.createCustomDashboardPayload(dashboardTab)))
    }
  }

  removeChart() {
    this.store.dispatch(new DashboardRemoveChart({ dashboard_chart: this.chart }))
  }

  onFilterChange(filterName: string, filterValue: string | string[]) {
    this.filtersForm.controls[filterName].setValue(filterValue)
    this.filtersForm.controls[filterName].markAsDirty()
  }

  onDateFilterChange(dateFilter: DateFilter) {
    this.onFilterChange('dateMode', dateFilter.dateMode)
    this.onFilterChange('startDate', dateFilter.startDate)
    this.onFilterChange('endDate', dateFilter.endDate)
  }

  setChartFilters() {
    // Do not dispatch if filters didn't change
    if (this.filtersForm.pristine) {
      return
    }

    const filters = this.filtersForm.value
    // custom dashboard chart filters
    this.segmentService.track("Set Chart Filters", { chartName: this.chart.name, filters })

    this.store.dispatch(new UpdateDashboardChart({
      dashboard_chart: this.chart,
      filters,
    }))
  }

  // Tab Position starts with 0
  getNewTabPositionIndex() {
    return this.tabs.length
  }

  enableRenameChart() {
    this.store.dispatch(new EnableEditDashboardChartName({ chartId: this.chart.id }))
  }

  refreshData() {
    this.chartChecker.refreshChartData(this.chartStatus.queue_key)
  }
}
