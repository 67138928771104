<twng-modal>
  <twng-modal-header>
    <twng-modal-title slot="header-title">Send to Slack</twng-modal-title>
  </twng-modal-header>
  <twng-modal-body>
    <div>Receiver:</div>
    <twng-slack-destination-selector [(ngModel)]="receiverId"></twng-slack-destination-selector>
    <div class="tw-mt-4">Text to send:</div>
    <input type="text" class="form-control"
      [(ngModel)]="textToSend"
      *ngIf="allowTextEntering"
      placeholder="Enter text to send"
    >
  </twng-modal-body>
  <twng-modal-footer>
    <span class="text-danger">{{ lastError }}</span>
    <button type="submit" class="tw-mt-2 button--primary button--large tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-white tw-bg-cq-primary-600 tw-text-sm tw-p-3" (click)="tabAction()" [disabled]="!receiverId || isSending">
      {{ isSending ? 'Sending ...' : 'Send' }}
    </button>
  </twng-modal-footer>
</twng-modal>