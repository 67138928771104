import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment'
import { isNotAuthenticated, isUnauthorized } from '../http-utils';
import { outerRedirect } from '../rails-integration'

function redirectToLogin() {
  if (!document.URL.endsWith('users/sign_in') && !document.URL.endsWith('users/sign_in/')) {
    outerRedirect('users/sign_in')
  }
}

@Injectable({ providedIn: 'root' })
export class TwngHttpAuthorizedInterceptor implements HttpInterceptor {

  constructor(private toastrService: ToastrService) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('HTTP request error: ', req)
          if (isNotAuthenticated(error)) {
            this.toastrService.error('Your session has expired.')
            if (!environment.production) {
              redirectToLogin()
            } else {
              // Reloading the page will allow rails to store the current page and redirect to login
              location.reload()
            }
          } else if (isUnauthorized(error)) {
            this.toastrService.error('You don’t have permission to do that.')
          }
          return throwError(error);
        })
      )
  }

}
