<div class="filter-dropdown-row d-flex flex-column">
  <span class="tw-mx-4 time-text">Time period</span>
  <div ngbDropdown class="d-inline-block tw-m-2.5">
    <button class="btn btn-outline-secondary" ngbDropdownToggle id="time-period">
      <span>
        {{ getFormatDate(dateFilterForm.controls['startDate']?.value) | date:'longDate' }}
      </span>
      <span class="empty-value-list">
        -
      </span>
      <span>
        {{ getFormatDate(dateFilterForm.controls['endDate']?.value) | date:'longDate' }}
      </span>
    </button>

    <div ngbDropdownMenu aria-labelledby="time-period">
      <div *ngFor="let date of datesFilters">
        <button ngbDropdownItem (click)="selectDate(date.value)">{{date.buttonText}}</button>
      </div>
    </div>
  </div>

  <form [formGroup]="dateFilterForm" *ngIf="dateFilterForm.controls['dateFilterMode']?.value === 'custom'">
    <div>
      <label class="form-inline">
        <div class="menu__item-forms-wrapper datepicker__wrapper">
          <input type="text" class='form-control' (click)="startDateDatepicker.toggle()" ngbDatepicker
            placement="bottom-left bottom-right auto" #startDateDatepicker="ngbDatepicker"
            formControlName="startDate" autocomplete="off" (dateSelect)="updateControlsValue()" />
          <span class="date-form-seperator">to</span>
          <input type="text" class='form-control' placeholder="Today" (click)="endDateDatepicker.toggle()"
            ngbDatepicker #endDateDatepicker="ngbDatepicker" placement="bottom-left bottom-right auto"
            formControlName="endDate" autocomplete="off" (dateSelect)="updateControlsValue()" />
        </div>
      </label>
    </div>
  </form>
</div>
