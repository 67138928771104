import * as fromWall from '../../wall/reducers'
import { AddTag, RemoveTag } from '../actions/toggle-tag.actions'
import { Candidate } from '../../wall/models/candidate'
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core'
import { Observable } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { TagMapping } from '../../wall/models/tag-mapping'


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-toggle-tag-icons',
  template: `
    <ng-container *ngIf="tagMappings$ | async as tagMappings">
      <div *ngIf="tagMappings | present">
        <a *ngFor="let tm of tagMappings" [class]="activeClass(tm)" (click)="toggleTag(tm)">
          <i [class]="iconClass(tm.icon)"
            ngbTooltip="Tag: {{ tm.tag }}"
          ></i>
        </a>
      </div>
    </ng-container>
  `,
  styles: [
    `
      a {
        cursor: pointer;
        margin-right: 0.5rem;
      }

      a.unselected {
        color: #aaaaaa;
      }

      a.selected {
        color: #4582ec;
      }
    `,
  ],
})
export class TagToggleIconsComponent implements OnInit {
  @Input()
    candidate: Candidate
  tagMappings$: Observable<TagMapping[]>

  constructor(private store: Store<fromWall.State>) { }

  ngOnInit(): void {
    this.tagMappings$ = this.store.pipe(select(fromWall.selectAllTagMappings))
  }

  iconClass(tag: string): string {
    return `fas fa-${tag}`
  }

  activeClass(tm: TagMapping): string {
    if (this.candidate.tags.indexOf(tm.tag) !== -1) {
      return 'selected'
    } else {
      return 'unselected'
    }
  }

  toggleTag(tm: TagMapping): void {
    const params = { candidate: this.candidate, tagName: tm.tag }
    if (this.candidate.tags.indexOf(tm.tag) !== -1) {
      this.store.dispatch(new RemoveTag(params))
    } else {
      this.store.dispatch(new AddTag(params))
    }
  }
}
