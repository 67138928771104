import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'

import { State, selectAccount } from '../../reducers'

@Injectable({ providedIn: 'root' })
export class CanViewHiringManagementGuard  {
  constructor(private store: Store<State>) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(selectAccount),
      map(account =>
        account?.enable_exec_tools
      ),
      first(config => config)
    )
  }
}
