import { AppConfigService } from "../../wall/services/app-config.service"
import { Injectable } from "@angular/core"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { SendToSlackComponent } from "../components/send-to-slack.component"
import { SlackService } from "./slack.service"
import { apiHost } from "../../core/http-options"
import { observableImmediatelySync } from "../utils/store.utils"


@Injectable({
  providedIn: 'root'
})
export class SlackModalService {
  constructor(
    private slack: SlackService,
    private appConfig: AppConfigService,
    private modalService: NgbModal,
  ) {}


  async openSendToSlackTab(prefilledText: string | Promise<string>) {
    if (observableImmediatelySync(this.appConfig.isFeatureFlagEnabled$('slack_configured'))) {
      const modalRef = this.modalService.open(SendToSlackComponent)
      const modalInstance = modalRef.componentInstance as SendToSlackComponent
      modalInstance.onSendClicked = async (receiver: string, textToSend: string) => {
        await this.slack.sendPlainMessage(receiver, textToSend)
      }
      if (typeof prefilledText === 'string') {
        modalInstance.textToSend = prefilledText
      } else {
        prefilledText.then(txt => modalInstance.textToSend = txt)
      }
    } else {
      location.href = apiHost + '/slack/try_authorize'
    }
  }
}
