import { GridsterItem } from 'angular-gridster2'
import { combineLatest } from 'rxjs'
import { filter, mergeMap, switchMap, take } from 'rxjs/operators'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromDashboard from '../reducers/gridster-dashboard.reducer'
import { DashboardChart } from '../models/dashboard-chart'
import {
  GridsterDashboardActionTypes, ResetGridsterDashboard, RollbackGridsterChart
} from '../actions/gridster-dashboard.actions'
import { UpdateDashboardChart } from '../actions/filters.actions'
import {
  selectAllGridsterDashboard, selectDashboardChartEntities, selectGridsterDashboardIds,
  selectGridsterEditMode
} from '../reducers'

@Injectable()
export class GridsterDashboardEffects {

  constructor(
    private actions$: Actions,
    private store: Store<fromDashboard.State>) {
  }

  persistGridsterDashboardChanges = createEffect(() => this.actions$.pipe(
    ofType(GridsterDashboardActionTypes.PersistGridsterDashboardChanges),
    mergeMap(() => this.store.select(selectGridsterEditMode)
      .pipe(
        filter(editModeEnabled => !editModeEnabled),
        take(1),
        mergeMap(() => combineLatest([
          this.store.select(selectAllGridsterDashboard),
          this.store.select(selectDashboardChartEntities)
        ]
        ).pipe(
          take(1),
          switchMap(([gridsterItemsToUpdate, charts]) => {
            const updateChartsActions = gridsterItemsToUpdate.map((gridsterChart: GridsterItem) => {
              const chart = charts[gridsterChart.id]
              const chartToUpdate: DashboardChart = {
                ...chart,
                position_x: gridsterChart.x,
                position_y: gridsterChart.y,
                width: gridsterChart.cols,
                height: gridsterChart.rows,
              }
              return new UpdateDashboardChart({
                dashboard_chart: chartToUpdate,
              })
            })
            return [...updateChartsActions, new ResetGridsterDashboard()]
          })
        )),
      )),
  ))

  rollbackChartChanges$ = createEffect(() => this.actions$.pipe(
    ofType(GridsterDashboardActionTypes.RollbackAllGridsterCharts),
    mergeMap(() => this.store.select(selectGridsterDashboardIds)
      .pipe(
        take(1),
        mergeMap((gridsterChartsIds: string[]) =>
          gridsterChartsIds.map(id => new RollbackGridsterChart({ chartId: id }))),
      ),
    ),
  ))
}
