<ng-container *ngIf="componentRendered && data; else loadingChart">
  <div *ngIf="gridItemType === 'css'" #widget class="widget widget--css
              {{chartDirection}}-bar
              {{chartDirection}}-bar--{{kebabCase(label)}}
              ">
    <div class="header tw-border-none">
      <twng-chart-label [chart]="chart" [label]="label"
        [chartInfo]="(dataSources && dataSources[dataSource]) ? dataSources[dataSource].description: null">
      </twng-chart-label>

      <div class="header-actions" data-html2canvas-ignore>
        <twng-sort-chart-menu (sortByChanged)="sortChartBy = $event" [allChartData]="data"></twng-sort-chart-menu>
        <button type="button" class="actions-button actions-button--copy-to-clipboard" [ngbTooltip]="copyTooltip"
          triggers="mouseenter:mouseleave" aria-label="Copy to Clipboard" [disabled]="copyingToClipboard"
          (click)="copyChartToClipboard(widget, legendInCopiedImg, filtersInCopiedImg)">
          <i *ngIf="!copyingToClipboard" class="far fa-copy"></i>
          <i *ngIf="copyingToClipboard" class="fas fa-sync fa-spin"></i>
        </button>

        <twng-chart-drop-down-menu [chart]="chart" [label]="label" [chartType]="chartType" [chartFilters]="chartFilters"
          [dataSource]="dataSource" [dataSourceParameters]="dataSourceParameters" [hideFilters]="true"
          [chartStatus]="status" *ngIf="!chartFiltersReadOnly">
        </twng-chart-drop-down-menu>
      </div>
    </div>

    <div [ngClass]="widgetBodyClasses()" twngSortChart #sortedChart=sortChart [chartData]="slicedData"
      [sortOption]="sortChartBy">
      <div *ngIf="chartDirection==='vertical'" class="content-overflow js-content-overflow--vertical">
        <div class="ngx-responsive-wrapper">
          <ngx-charts-bar-vertical [id]="camelCase(label)" [results]="sortedChart.sortedData" [showGridLines]="true"
            [showDataLabel]="true" [scheme]="colorScheme" [showYAxisLabel]="true" [xAxis]="true"
            [tooltipDisabled]="false" [yAxis]="true" [noBarWhenZero]="true" [roundEdges]="false"
            [barPadding]="setBarPadding(slicedData.length)" [yAxisTickFormatting]="integersOnly" [legend]="true"
            [legendPosition]="legendPosition" [animations]="false" (select)="sliceClicked($event)" *ngIf="!isDataGrouped">
          </ngx-charts-bar-vertical>

          <ngx-charts-bar-vertical-2d [id]="camelCase(label)" [results]="sortedChart.sortedData" [showGridLines]="true"
            [showDataLabel]="true" [scheme]="colorScheme" [showYAxisLabel]="true" [xAxis]="true"
            [tooltipDisabled]="false" [yAxis]="true" [noBarWhenZero]="true" [roundEdges]="false" [barPadding]="2"
            [groupPadding]="2" [yAxisTickFormatting]="integersOnly" [legend]="true" [legendPosition]="legendPosition"
            [animations]="false" [scaleType]="scaleType" (select)="sliceClicked($event)" *ngIf="isDataGrouped">
          </ngx-charts-bar-vertical-2d>
        </div>
      </div>

      <div *ngIf="chartDirection === 'horizontal'" class="content-overflow js-content-overflow--horizontal">
        <div class="ngx-responsive-wrapper" [style.height.px]="barHeightPx * (slicedData.length+1)">
          <ngx-charts-bar-horizontal [id]="camelCase(label)" [results]="sortedChart.sortedData" [showGridLines]="true"
            [showDataLabel]="true" [scheme]="colorScheme" [tooltipDisabled]="false" [showYAxisLabel]="true"
            [xAxis]="true" [yAxis]="true" [roundEdges]="false" [barPadding]="16" [xAxisTickFormatting]="integersOnly"
            [legend]="true" [legendPosition]="legendPosition" [animations]="false" (select)="sliceClicked($event)"
            [dataLabelFormatting]="formatDataLabel" *ngIf="!isDataGrouped">
          </ngx-charts-bar-horizontal>

          <ngx-charts-bar-horizontal-2d [id]="camelCase(label)" [results]="sortedChart.sortedData" [showGridLines]="true"
            [showDataLabel]="true" [scheme]="colorScheme" [customColors]="customColors" [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true"
            [tooltipDisabled]="false" [yAxis]="true" [noBarWhenZero]="true" [roundEdges]="false" [barPadding]="2"
            [groupPadding]="4"  [legend]="true" [legendPosition]="legendPosition" [xAxisTickFormatting]="integersOnly"
            [animations]="false" [schemeType]="scaleType" (select)="sliceClicked($event)" *ngIf="isDataGrouped">
          </ngx-charts-bar-horizontal-2d>

          <ng-container *ngIf="chartDirection === 'horizontal'">
            <ng-container *ngTemplateOutlet="viewAll"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="chartDirection === 'vertical'">
      <ng-container *ngTemplateOutlet="viewAll"></ng-container>
    </ng-container>
    <div class="filters" *ngIf="chart && chartFilters">
      <twng-chart-filters [filters]="chartFilters" [filtersEnabled]="filtersEnabled" [chart]="chart" [overrideFiltersSet]="overrideFiltersSet">
      </twng-chart-filters>
    </div>
  </div>

  <div #widget *ngIf="gridItemType === 'gridster'" class="widget widget--gridster
              {{chartDirection}}-bar--gridster
              {{chartDirection}}-bar--{{kebabCase(label)}}">
    <twng-gridster-item-overlay *ngIf="editingGridster"></twng-gridster-item-overlay>

    <div class="header tw-border-none" [class.pointer-events--none]="editingGridster">
      <twng-chart-label [chart]="chart" [label]="label"
        [chartInfo]="(dataSources && dataSources[dataSource]) ? dataSources[dataSource].description: null">
      </twng-chart-label>
      <div class="header-actions" data-html2canvas-ignore>
        <twng-sort-chart-menu (sortByChanged)="sortChartBy = $event" [allChartData]="data"></twng-sort-chart-menu>
        <button type="button" class="actions-button actions-button--copy-to-clipboard" [ngbTooltip]="copyTooltip"
          triggers="mouseenter:mouseleave" aria-label="Copy to Clipboard" [disabled]="copyingToClipboard"
          (click)="copyChartToClipboard(widget, legendInCopiedImg, filtersInCopiedImg)">
          <i *ngIf="!copyingToClipboard" class="far fa-copy"></i>
          <i *ngIf="copyingToClipboard" class="fas fa-sync fa-spin"></i>
        </button>

        <twng-chart-drop-down-menu [chart]="chart" [label]="label" [chartType]="'bar-' + chartDirection"
          [chartFilters]="chartFilters" [dataSource]="dataSource" [hideFilters]="true" *ngIf="!chartFiltersReadOnly"
          [chartStatus]="status">
        </twng-chart-drop-down-menu>
      </div>
    </div>

    <div [style.overflow-y]="chartDirection === 'horizontal' ? 'auto' : 'none'"
      [style.overflow-x]="chartDirection === 'vertical' ? 'auto' : 'none'" [ngClass]="widgetBodyClasses()"
      [class.pointer-events--none]="editingGridster">
      <div twngSortChart #sortedChart=sortChart [chartData]="slicedData" [sortOption]="sortChartBy"
        [dimensionMonitor]="100" #divDimension="dimensionMonitor" style="width: 95%"
        [style.height]="chartDirection === 'horizontal' ? barHeightPx * (slicedData.length+1) + 'px' : '95%'">
        <ngx-charts-bar-vertical *ngIf="chartDirection === 'vertical' && !isDataGrouped" [id]="camelCase(label)"
          [results]="sortedChart.sortedData" [showGridLines]="true" [showDataLabel]="true" [scheme]="colorScheme"
          [showYAxisLabel]="true" [xAxis]="true" [tooltipDisabled]="false" [yAxis]="true" [noBarWhenZero]="true"
          [roundEdges]="false" [barPadding]="setBarPadding(slicedData.length)" [yAxisTickFormatting]="integersOnly"
          [legend]="true" [legendPosition]="legendPosition" (select)="sliceClicked($event)" [animations]="false">
        </ngx-charts-bar-vertical>

        <ngx-charts-bar-vertical-2d *ngIf="chartDirection === 'vertical' && isDataGrouped" [id]="camelCase(label)"
          [results]="sortedChart.sortedData" [showGridLines]="true" [showDataLabel]="true" [scheme]="colorScheme"
          [showYAxisLabel]="true" [xAxis]="true" [tooltipDisabled]="false" [yAxis]="true" [noBarWhenZero]="true"
          [roundEdges]="false" [barPadding]="2" [groupPadding]="2" [yAxisTickFormatting]="integersOnly" [legend]="true"
          [legendPosition]="legendPosition" (select)="sliceClicked($event)" [animations]="false">
        </ngx-charts-bar-vertical-2d>

        <ngx-charts-bar-horizontal-2d *ngIf="chartDirection === 'horizontal' && isDataGrouped" [id]="camelCase(label)"
          [results]="sortedChart.sortedData" [showGridLines]="true" [showDataLabel]="true" [scheme]="colorScheme" [customColors]="customColors"
          [showYAxisLabel]="true" [showXAxisLabel]="true" [xAxis]="true" [tooltipDisabled]="false" [yAxis]="true" [noBarWhenZero]="true"
          [roundEdges]="false" [barPadding]="2" [groupPadding]="4" [xAxisTickFormatting]="integersOnly" [legend]="true"
          [legendPosition]="legendPosition" (select)="sliceClicked($event)" [animations]="false">
        </ngx-charts-bar-horizontal-2d>

        <ngx-charts-bar-horizontal *ngIf="chartDirection === 'horizontal' && !isDataGrouped" [id]="camelCase(label)"
          [results]="sortedChart.sortedData" [showGridLines]="true" [showDataLabel]="true" [scheme]="colorScheme"
          [tooltipDisabled]="false" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true" [roundEdges]="false"
          [barPadding]="4" [xAxisTickFormatting]="integersOnly" [legend]="true" [legendPosition]="legendPosition"
          (select)="sliceClicked($event)" [maxYAxisTickLength]="(divDimension.currentWidth$ | async) / 35"
          [trimYAxisTicks]="true" [animations]="false" [dataLabelFormatting]="formatDataLabel">
        </ngx-charts-bar-horizontal>
      </div>

      <ng-container *ngIf="chartDirection === 'horizontal'">
        <ng-container *ngTemplateOutlet="viewAll"></ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="chartDirection === 'vertical'">
      <ng-container *ngTemplateOutlet="viewAll"></ng-container>
    </ng-container>
    <div class="filters" [class.pointer-events--none]="editingGridster" *ngIf="chart && chartFilters"
      data-html2canvas-ignore>
      <twng-chart-filters [filters]="chartFilters" [filtersEnabled]="filtersEnabled" [chart]="chart"
        [chartFiltersReadOnly]="chartFiltersReadOnly" [overrideFiltersSet]="overrideFiltersSet">
      </twng-chart-filters>
    </div>

  </div>
</ng-container>

<ng-template #loadingChart>
  <div class="chart-loading">
    <twng-spinner></twng-spinner>
  </div>
</ng-template>

<ng-template #copyTooltip>
  <div>Copy to Clipboard</div>
  <i class="fas fa-flask"></i>&nbsp;
  <span>Chrome only</span>
</ng-template>

<ng-template #viewAll>
  <div class="view-all" [ngClass]="{'view-all-shadow' : chartDirection === 'vertical' && gridItemType === 'css'}" *ngIf="displayBars < data.length">
    <span>Showing {{ displayBars }}/{{ data.length }} bars </span>
    <button class="button--primary" (click)="viewAllBars()">Show All</button>
  </div>
</ng-template>
