import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../reducers'
import { ActivityDayCategories } from '../models/activity-day-categories'
import { CacheService } from './cache.service'
import { Interview } from '../models/interview'
import { atsId } from '../models/types'

@Injectable({ providedIn: 'root' })
export class CandidatesService {

  constructor(private store: Store<fromWall.State>,
    private cacheService: CacheService) {
  }

  getActivityDayCategories(): Observable<ActivityDayCategories> {
    return this.store.select(fromWall.selectActivityDayCategories)
  }

  getInterviewsForJobApplication(jobApplicationId: atsId): Observable<Interview[]> {
    return this.cacheService.interviewsAndJobEntities$.pipe(
      map((interviewsAndJobEntities) => interviewsAndJobEntities.interviewsByJobAppId[jobApplicationId] || [])
    )
  }
}
