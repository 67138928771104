/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store';
import { FeatureKey } from '../reducers/track-features.reducer'

export enum TrackFeatureActionsTypes {
  TrackFeature = "[Track Feature] Track Feature Status",
  TrackFeatureSuccess = "[Track Feature] Track Feature Status Success",
  TrackFeatureFailed = "[Track Feature Track Feature Status Failed",
}

export interface TrackFeaturePayload {
  featureKey: FeatureKey;
}

export class TrackFeature implements Action {
  type = TrackFeatureActionsTypes.TrackFeature

  constructor(public payload: TrackFeaturePayload) {
  }
}

export class TrackFeatureSuccess implements Action {
  type = TrackFeatureActionsTypes.TrackFeatureSuccess

  constructor(public payload: TrackFeaturePayload) {
  }
}

export class TrackFeatureFailed implements Action {
  type = TrackFeatureActionsTypes.TrackFeatureFailed

  constructor(public payload: TrackFeaturePayload) {
  }
}

export type TrackFeaturesActions =
  TrackFeature |
  TrackFeatureSuccess |
  TrackFeatureFailed
