import { isEmpty as _isEmpty, size as _size, sortBy as _sortBy, values as _values } from 'lodash-es'

import { LayoutActionTypes, LayoutActions } from '../actions/layout.actions'
import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import { RatingNumber } from '../models/rating-number'
import {
  SelectedCustomFieldsFilter
} from '../../custom-fields/models/selected-custom-fields-filter'
import {
  SortingOptionDirection, SortingOptionType, SortingOptions
} from '../models/sorting-options'
import { Tab } from '../models/tab'
import { TabActionTypes, TabActions } from '../actions/tabs.actions'
import { WallActionTypes, WallActions } from '../actions/wall.actions'
import { atsId } from '../models/types'

export interface ExecutiveDashboardJobFilters {
  department_ids: atsId[] | null
  office_ids: atsId[] | null
  external_user_ids: atsId[] | null
  custom_fields: SelectedCustomFieldsFilter[]
  job_ids: atsId[] | null
}

export interface JobFilters extends ExecutiveDashboardJobFilters {
  job_priorities?: string[] | null
  employment_types?: string[] | null
  matchingJobName?: string | null
  custom_fields: SelectedCustomFieldsFilter[]
}

export interface CandidateFilters {
  // TODO: Move or remove showConfidentialJobs from CandidateFilters?
  showConfidentialJobs: boolean
  showPrivateCandidates: boolean
  starredOnly: boolean
  matchingActivityLevel: string[]
  matchingCandidateName: string
  matchingSourceFilter: string[]
  matchingTagFilters: {[tag: string]: boolean}
  myCandidatesOnly: boolean
  showInterviewScheduled: boolean,
  showInterviewCompleted: boolean,
  showNoInterview: boolean,
  rating: RatingNumber[],
  recruiters: string[] // recruiters_id
  creditedTo: string[] //  external_users_id
  candidateCustomFields: SelectedCustomFieldsFilter[]
}

export const anyFiltersSet = (candidateFilters: CandidateFilters): boolean => {
  const val = !!(
    candidateFilters.starredOnly ||
    !_isEmpty(candidateFilters.matchingActivityLevel) ||
    candidateFilters.matchingCandidateName ||
    !_isEmpty(candidateFilters.matchingSourceFilter) ||
    candidateFilters.myCandidatesOnly ||
    candidateFilters.showInterviewCompleted ||
    candidateFilters.showInterviewScheduled ||
    candidateFilters.showNoInterview ||
    !_isEmpty(candidateFilters.rating) ||
    // this is overly complicated
    // TODO: Change matchingTagFilters so it's an array or set of tags instead of a dict!
    _size(_values(candidateFilters.matchingTagFilters).filter(v => v)) > 0 ||
    !_isEmpty(candidateFilters.recruiters) ||
    !_isEmpty(candidateFilters.creditedTo)
  )
  // console.log('anyFilters set?', val)
  return val
}

export const INITIAL_CANDIDATE_FILTERS: CandidateFilters = {
  showConfidentialJobs: true,
  showPrivateCandidates: true,
  starredOnly: false,
  matchingSourceFilter: [],
  matchingTagFilters: {},
  matchingCandidateName: null,
  matchingActivityLevel: [],
  myCandidatesOnly: false,
  showInterviewScheduled: false,
  showInterviewCompleted: false,
  showNoInterview: false,
  rating: [],
  recruiters: [],
  creditedTo: [],
  candidateCustomFields: [],
}

export const INITIAL_ALL_JOBS_FILTERS: JobFilters = {
  department_ids: null,
  office_ids: null,
  external_user_ids: null,
  job_priorities: null,
  employment_types: null,
  matchingJobName: '',
  custom_fields: [],
  job_ids: null,
}

export enum JobViewMode {
  Wall, Analytics, Collapsed
}

export enum WallSummaryMode {
  Normal, Collapsed, CalendarFullscreen
}

export const wallSummaryModeItem = 'WallSummaryMode'

export interface State {
  activeTabId: number
  allJobsFilters: JobFilters
  allJobsSortingOptions: SortingOptions
  executiveDashboardFilters: JobFilters
  executiveDashboardSortingOptions: SortingOptions
  candidateFilters: CandidateFilters
  jobsViewModeByJobId: { [id: string]: JobViewMode }
  collapseAllJobs: boolean
  // Are all jobs loaded in currently visible wall view
  currentWallViewAllJobsLoaded: boolean
  // Set this to true to request all jobs to be loaded in wall view
  requestAllJobsToLoad: boolean
  // Used when generating pdf
  showSingleJobId: string | undefined
  currentWallViewListOfJobIds: string[]
  // ids of external users which can be considered for "Credited To" dropdown
  // (used only on wall)
  creditedToIds: string[]
  wallSummaryMode: WallSummaryMode
  calendarWeek: string
}

const initialState: State = {
  activeTabId: null,
  allJobsFilters: INITIAL_ALL_JOBS_FILTERS,
  allJobsSortingOptions: {
    type: SortingOptionType.Name,
    direction: SortingOptionDirection.Ascending,
  },
  executiveDashboardFilters: INITIAL_ALL_JOBS_FILTERS,
  executiveDashboardSortingOptions: {
    type: SortingOptionType.Name,
    direction: SortingOptionDirection.Ascending,
  },
  candidateFilters: INITIAL_CANDIDATE_FILTERS,
  jobsViewModeByJobId: {},
  collapseAllJobs: false,
  currentWallViewAllJobsLoaded: false,
  requestAllJobsToLoad: false,
  currentWallViewListOfJobIds: [],
  showSingleJobId: undefined,
  creditedToIds: [],
  wallSummaryMode: Number(localStorage.getItem(wallSummaryModeItem)) || WallSummaryMode.Normal,
  calendarWeek: undefined
}
// magic number indicating "All Jobs" tab
export const ALL_JOBS_TAB_ID = -1
export const CANDIDATES_BY_STAGE_TAB_ID = -2
export const CANDIDATES_BY_STAGE_URL_NAME = 'candidates-by-stage'

export const getDefaultTab = (tabs: Tab[]): Tab => _sortBy(tabs, ['position'])[0]
export const canChangeViewMode =
  (jobId, jobsViewModeyJobId): boolean =>
    jobsViewModeyJobId[jobId] ? jobsViewModeyJobId[jobId] === JobViewMode.Collapsed : true

export const setJobViewModeToAllJobs =
  (currentWallViewListOfJobIds, collapse, jobsViewModeByJobId): { [id: string]: JobViewMode } => {
    const jobsViewMode = {}
    currentWallViewListOfJobIds.map(jobId => {
      const canChange = canChangeViewMode(jobId, jobsViewModeByJobId)
      if(canChange) {
        jobsViewMode[jobId] = collapse ? JobViewMode.Collapsed : JobViewMode.Wall
      } else {
        jobsViewMode[jobId] = collapse ? JobViewMode.Collapsed : jobsViewModeByJobId[jobId]
      }
    });
    return jobsViewMode;
  }


export function reducer(
  state = initialState,
  action: LayoutActions | LoaderActions | TabActions | WallActions,
): State {
  switch (action.type) {
    case LoaderActionTypes.WallSharedSuccess: {
      // if active tab is already set, don't change it
      // (e.g. if we just reloaded after creating a tab)
      if (state.activeTabId || !action.payload.tabs) {
        return state
      }
      if (action.payload.tabs.length === 0) {
        return {
          ...state,
          activeTabId: ALL_JOBS_TAB_ID,
        }
      } else {
        const tab = getDefaultTab(action.payload.tabs)
        return {
          ...state,
          activeTabId: tab.id,
        }
      }
    }

    case LayoutActionTypes.UpdateSelectedTab: {
      return {
        ...state,
        activeTabId: action.payload.tabId,
      }
    }
    // when a new tab is created, switch to it
    case TabActionTypes.CreateTabSuccess: {
      return {
        ...state,
        activeTabId: action.payload.tab.id,
      }
    }

    case LayoutActionTypes.UpdateAllJobsFilters: {
      return {
        ...state,
        allJobsFilters: { ...state.allJobsFilters, ...action.payload },
      }
    }

    case LayoutActionTypes.UpdateCollapseAllJobs: {
      const jobsViewModeByJobIdUpdated =
        setJobViewModeToAllJobs(state.currentWallViewListOfJobIds,
          action.payload.collapseAllJobs, state.jobsViewModeByJobId)
      return {
        ...state,
        collapseAllJobs: action.payload.collapseAllJobs,
        jobsViewModeByJobId: jobsViewModeByJobIdUpdated
      }
    }

    case LayoutActionTypes.UpdateJobViewMode: {
      return {
        ...state,
        jobsViewModeByJobId: {
          ...state.jobsViewModeByJobId,
          [action.payload.jobId]: action.payload.viewMode,
        }
      }
    }

    case LayoutActionTypes.UpdateCandidateFilters: {
      return {
        ...state,
        candidateFilters: { ...state.candidateFilters, ...action.payload },
      }
    }

    case LayoutActionTypes.UpdateAllJobsSortingOptions: {
      return {
        ...state,
        allJobsSortingOptions: action.payload
      }
    }

    case LayoutActionTypes.UpdateExecutiveDashboardSortingOptions: {
      return {
        ...state,
        executiveDashboardSortingOptions: action.payload
      }
    }

    case LayoutActionTypes.UpdateAreAllJobsLoaded: {
      return {
        ...state,
        currentWallViewAllJobsLoaded: action.payload
      }
    }

    case LayoutActionTypes.UpdateRequestAllJobsToLoad: {
      return {
        ...state,
        requestAllJobsToLoad: action.payload
      }
    }

    case LayoutActionTypes.UpdateListOfJobsInCurrentWall: {
      return {
        ...state,
        currentWallViewListOfJobIds: action.payload
      }
    }

    case LayoutActionTypes.UpdateViewSingleJobOnWall: {
      return {
        ...state,
        showSingleJobId: action.payload
      }
    }

    case WallActionTypes.FetchWallDataPaginatedAction:
    case WallActionTypes.FetchWallDataPaginatedTabAction: {
      return {
        ...state,
        creditedToIds: action.payload.fetch_credited_to_ids ? [] : state.creditedToIds,
      }
    }

    case WallActionTypes.FetchWallDataPaginatedSuccessAction:
    case WallActionTypes.FetchWallDataPaginatedTabSuccessAction: {
      return {
        ...state,
        creditedToIds: action.payload.credited_to_ids || state.creditedToIds,
      }
    }

    case LayoutActionTypes.UpdateWallSummaryMode:
      return {
        ...state,
        wallSummaryMode: action.payload,
      }

    case LayoutActionTypes.UpdateCalendarWeek:
      return {
        ...state,
        calendarWeek: action.payload,
      }

    default: {
      return state
    }
  }
}
