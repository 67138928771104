import { Injectable } from '@angular/core'
import { PdfService, TwngPdfOptions } from '../../shared/services/pdf.service';

const headerSelector = '.export-pdf-row'

@Injectable({
  providedIn: 'root',
})
export class DashboardPdfService extends PdfService {

  private generatingCanvasHeader = false

  /**
   * Original height of the twng gridster container before
   * setting a bigger height
   */
  private originalGridsterContainerHeight: number

  /**
   * Parse the css property transform: translate3d(311px, 0px, 0px);
   * returning an object with the properties  x, y and z
   *
   * @param cssTransformValue value of the transform property
   */
  parseTransformCssProperty = (cssTransformValue: string): { x: number, y: number, z: number } => {
    if (!cssTransformValue) {
      return {
        x: 0, y: 0, z: 0
      }
    }
    const transform = cssTransformValue.split(/\w+\(|\);?/).filter(v => !!v).map(v => v.split(/,\s?/g))[0]
      .map(v => parseInt(v, 10))
    return {
      x: transform[0],
      y: transform[1],
      z: transform[2]
    };
  };

  /**
   * Returns the padding added for the position in the PDF file
   */
  getGridsterItemPaddingValues() {
    return {
      top: PdfService.pagePadding,
      left: PdfService.pagePadding * 2
    }
  }

  getGridsterContainer(): HTMLElement {
    return document.querySelector('.dashboard__grid--gridster') as HTMLElement
  }

  getGridsterItems(): NodeListOf<HTMLElement> {
    return document.body.querySelectorAll('gridster-item')
  }

  setGridsterContainerHeight() {
    const gridsterContainer = this.getGridsterContainer()
    // Save original height
    this.originalGridsterContainerHeight = gridsterContainer.clientHeight

    const gridsterScrollHeight = (document.querySelector('gridster') as HTMLElement).scrollHeight
    gridsterContainer.style.setProperty('height', gridsterScrollHeight + 'px')
  }

  restoreGridsterContainerHeight() {
    this.getGridsterContainer().style.setProperty('height', this.originalGridsterContainerHeight + 'px')
  }

  setGridsterItemsExportPosition() {
    this.getGridsterItems().forEach(gridsterItem => this.setGridsterItemPosition(gridsterItem))
    this.flushStyles()
  }

  restoreGridsterItemsOriginalPosition() {
    this.getGridsterItems().forEach(gridsterItem => this.restoreGridsterItemPosition(gridsterItem))
    this.flushStyles()
  }

  /**
   * html2canvas doesn't play well with transform :translate3d css props.
   * For this cases, put the element on absolute position reading from translate3d
   * and after finishing exporting, restore it.
   *
   * @param htmlElement
   */
  setGridsterItemPosition(htmlElement: HTMLElement) {
    const padding = this.getGridsterItemPaddingValues()
    const transformValue: string = htmlElement.style.getPropertyValue('transform')
    const parsedTransformValues = this.parseTransformCssProperty(transformValue)
    htmlElement.style.setProperty('position', 'absolute')
    htmlElement.style.setProperty('top', parsedTransformValues.y + padding.top + 'px')
    htmlElement.style.setProperty('left', parsedTransformValues.x + padding.left + 'px')
    htmlElement.style.removeProperty('transform')
  }

  restoreGridsterItemPosition(gridsterItem: HTMLElement) {
    // Subtracts the added padding values
    const padding = this.getGridsterItemPaddingValues()
    const top = parseInt(gridsterItem.style.getPropertyValue('top') || '0', 10) - padding.top
    const left = parseInt(gridsterItem.style.getPropertyValue('left') || '0', 10) - padding.left
    gridsterItem.style.setProperty('transform', `translate3d(${left}px, ${top}px, 0px)`)
    gridsterItem.style.removeProperty('position')
    gridsterItem.style.removeProperty('top')
    gridsterItem.style.removeProperty('left')
  }

  // Do not run before and after hooks when generating the header
  protected beforeGeneratingCanvasFromHTML(): Promise<void> {
    if (!this.generatingCanvasHeader) {
      this.setGridsterContainerHeight()
    }
    return Promise.resolve()
  }

  // Do not run before and after hooks when generating the header
  protected afterGeneratingCanvasFromHTML(): Promise<void> {
    if (!this.generatingCanvasHeader) {
      this.restoreGridsterContainerHeight()
    }
    return Promise.resolve()
  }

  protected async generateHeaderCanvas(): Promise<string> {
    this.generatingCanvasHeader = true;
    const canvasHeader = await this.generateCanvasFromHTML(headerSelector)
    this.generatingCanvasHeader = false;
    return canvasHeader
  }

  protected async generatePDF(elementSelector: string, pdfOptions: TwngPdfOptions): Promise<void> {
    // Generate HTML to Canvas from dashboard
    const canvasImage = await this.generateCanvasFromHTML(elementSelector)

    const logoSizes = this.getLogoSectionDimensions()
    const pdf = this.createPDFFile(elementSelector)

    let heightCounter = logoSizes.logoReactHeight

    // Add the header
    const canvasHeader = await this.generateHeaderCanvas()
    const headerDimensions = this.getImageDimensions(headerSelector)
    // Add the job canvas element to the pdf
    pdf.addImage(
      canvasHeader,
      'PNG',
      0, // x position on the page
      heightCounter, // y position
      headerDimensions.width,
      headerDimensions.height,
      '', // alias
      'FAST'
    )

    heightCounter += headerDimensions.height

    // Image dimension with aspect ratio
    const imageDimension = this.getImageDimensions(elementSelector)

    // Add offersAndHires canvas element
    pdf.addImage(
      canvasImage,
      'PNG',
      0, // x position on the page
      heightCounter, // y position: below the logo rect
      imageDimension.width,
      imageDimension.height,
      '', // alias
      'FAST'
    )

    // Download the pdf in the browser
    const tabName = (pdfOptions?.tabName || elementSelector.replace(/[#\.]/, '')).toLowerCase()
    pdf.save(this.generateFileName(`dashboard-${tabName}`))
  }

}
