import { ComparatorReturnType } from './index';

export const compareStringDates = (date1: string, date2: string): ComparatorReturnType => {
  const dateTime1 = new Date(date1).getTime()
  const dateTime2 = new Date(date2).getTime()

  if (dateTime1 < dateTime2) {
    return -1;
  }

  if (dateTime1 > dateTime2) {
    return 1;
  }

  // Dates are equals
  return 0;
}
