import { getAllRoutesPaths } from '../app.routes'
import { replaceAllInString } from '../shared/utils/general-utils'

let twngRoutes = null

const getTwngRoutes = () => {
  twngRoutes = twngRoutes || getAllRoutesPaths()
  return twngRoutes
}

/**
 * Returns true if the url is twng route
 */
const isTwngRoute = (pathnameToMatch: string) => {
  const ret = !!getTwngRoutes().find((route: string) => {
    // replace all :param to \w+
    let routeRegex = replaceAllInString(route, /(:\w+)/, '\\w+')
    // in order to avoid infinite loop, we convert / -> $ -> \/
    routeRegex = replaceAllInString(routeRegex, '/', '$')
    routeRegex = replaceAllInString(routeRegex, '$', '\\/')
    // current url must start with the route in order to match
    routeRegex = "^" + routeRegex
    return !!pathnameToMatch.match(routeRegex)
  })
  return ret
}

export function onTwngRoute(): boolean {
  // pathname is for example: /executive-tools/executive-dashboard
  return isTwngRoute(window.location.pathname)
}

/**
 * Removes the Rails Container when present
 * and it's rendering a twng route
 */
export const removeRailsContainer = () => {
  if (!isTwngRoute(window.location.pathname)) {
    return
  }

  const railsContainer = document.querySelector('body > .container')
  if (railsContainer) {
    railsContainer.remove()
  }
}
