import * as fromWall from '../reducers'
import { Store } from '@ngrx/store'
import { selectImmediatelySync } from '../../shared/utils/store.utils'

export class ActivityDayCategories {
  fair: number
  poor: number
  dead: number
}

export class ActivityLabels {
  good: string
  fair: string
  poor: string
  dead: string
}

export const ActivityLevels: PossibleActivityLevels[] = ['good', 'fair', 'poor', 'inactive']
export type PossibleActivityLevels = 'inactive' | 'poor' | 'fair' | 'good'

export function getActivityLevelName(activity: string | ActivityLabels): PossibleActivityLevels {
  return activity === 'dead' ? 'inactive' :  activity as PossibleActivityLevels
}

export function getTooltipTextForActivity(activityLevel: PossibleActivityLevels, store: Store) {
  const settings = selectImmediatelySync(store, fromWall.selectWallSettings).wallSettings
  const configuredLevels = settings.activity_day_original_categories
  const suffix = settings.activity_day_ignore_weekends ? "business days" : "days"
  switch (activityLevel) {
    case 'good': {
      return `Last activity less than ${configuredLevels.fair + 1} ${suffix} ago`
    }
    case 'fair': {
      return `Last activity between ${configuredLevels.fair + 1} and ${configuredLevels.poor} ${suffix} ago`
    }
    case 'poor': {
      return `Last activity between ${configuredLevels.poor + 1} and ${configuredLevels.dead} ${suffix} ago`
    }
    case 'inactive': {
      return `No activity in last ${configuredLevels.dead} ${suffix}`
    }
  }
}
