import { Observable, Subscription, combineLatest, merge } from 'rxjs'
import { first, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators'

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'

import * as fromWall from '../../wall/reducers'
import { Account } from '../../wall/models/account'
import { Actions, ofType } from '@ngrx/effects'
import { AnalyticsState, NgxChartsColor, offerTypes } from '../reducers/analytics.reducer'
import { AppConfigService } from '../../wall/services/app-config.service'
import { CacheService } from '../../wall/services/cache.service'
import { CallApi } from '../../shared/actions/api.actions'
import { Chart } from '../models/dashboard-chart'
import { ChartsInitialConfig, FetchWidgetLibraryTab,
  LoaderActionTypes, UpdateSharedUser } from '../../core/actions/loader.actions'
import { DashboardActionTypes } from '../actions/filters.actions'
import { DashboardFormComponent } from './dashboard-form/dashboard-form.component'
import { DashboardPdfService } from '../services/dashboard-pdf.service'
import { DashboardTab, DashboardTabWithCharts } from '../models/dashboard-tab'
import { DashboardTypes } from './dashboard-types'
import { DefaultColorScheme, TwColors } from './color-schemes'
import { ExportMainDashboardService } from '../services/export-main-dashboard.service'
import { ExternalUser } from '../../wall/models/external-user'
import { GenericTableModalActionTypes, ShowGenericTableModal } from '../../core/actions/generic-table.actions'
import { LINE_CHART_TYPES } from './line-chart.component'
import { SegmentService } from '../../core/services/segment.service'
import { SiteNotificationComponent } from '../../shared/components/site-notification/site-notification.component'
import { SortTabsComponent } from '../../shared/components/sort-tabs/sort-tabs.component'
import { User } from '../../wall/models/user'
import { VideoModalComponent } from '../../shared/components/video-modal/video-modal.component'
import { WidgetDataType } from '../../wall/models/offer'
import { cloneDeep } from 'lodash-es'
import { customBoxDataSource } from './stats.component'
import { loadDashboardIfNecessary } from '../../shared/utils/store.utils'
import { present, wait } from '../../shared/utils/general-utils'
import { selectAccount, selectDashboardLoadCompleted, selectUser } from '../../reducers'
import { selectAllDashboardTabsWithDashboardCharts, selectAnalytics, selectChartsInitConfig } from '../reducers'

@Component({
  selector: 'twng-dashboard',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dashboard.component.html',
  styleUrls: [
    './dashboard.layout.scss',
    './edit-mode.component.scss',
    './stats.component.scss',

    './dashboard.shared.component.scss',
    './dashboard.component.scss',
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  ORG_DASHBOARD_TAB_ID = 'org_dash_tab_id'
  MY_DASHBOARD_TAB_ID = 'my_dash_tab_id'

  CUSTOM_BOX_DATA_SOURCE = customBoxDataSource

  myDashboard = DashboardTypes.MyDashboard

  widgetDataTypes = WidgetDataType

  constructor(
    private store: Store<fromWall.State>,
    private cd: ChangeDetectorRef,
    private router: Router,
    public appConfig: AppConfigService,
    private modalService: NgbModal,
    public dashboardPdfService: DashboardPdfService,
    private exportMainDashboardService: ExportMainDashboardService,
    private segmentService: SegmentService,
    private cacheService: CacheService,
    private actions: Actions,
  ) { }

  /*
    The dimensions of the chart [width, height]. If left undefined,
    the chart will fit to the parent container size

    https://swimlane.gitbook.io/ngx-charts/examples/pie-charts/pie-chart
    https://github.com/swimlane/ngx-charts/issues/615
    https://github.com/swimlane/ngx-charts/issues/1096
  */
  analyticsData: AnalyticsState
  analyticsData$: Observable<AnalyticsState>

  activityLabelsSub: Subscription

  loaded$: Observable<boolean>

  demoMode = !!window.twng_demo

  twColors = TwColors
  defaultColorScheme = DefaultColorScheme

  activityColors: NgxChartsColor[]

  accountSub: Subscription
  account: Account

  dashboardTabs$: Observable<DashboardTabWithCharts[]>
  tabsCount = 0

  sortTabsModalRef: NgbModalRef

  exportingPdf$: Observable<boolean>

  routerSub: Subscription

  modalListenerSub: Subscription
  newTabListenerSub: Subscription

  mainNav: 'widget-library' | 'dashboards'
  subNav: string

  currentUser: User
  currentUserSub: Subscription
  currentExternalUser: ExternalUser

  chartInitConfigSub: Subscription
  chartInitConfig: ChartsInitialConfig

  lineChartTypes = LINE_CHART_TYPES

  async setPathParts(path: string) {
    const navParts = path.split('/')
    if (navParts.length > 2 && this.mainNav !== navParts[2] || this.subNav !== navParts[3]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.mainNav = navParts[2] as any
      // we have to wait on subNav because ngbTab doesn't correctly recognize
      // that we have pre-initialized it, resulting in tab not being displayed
      await wait(0)
      this.subNav = navParts[3]
    }
    this.cd.detectChanges()
  }

  get isMineTabEnabled() {
    return this.currentUser?.beta_features
  }

  ngOnInit(): void {
    // NavigationEnd is not fired on page load, so
    // we force it to take a first emit.
    const navigationEndEvent = true
    this.routerSub = this.router.events.pipe(startWith(navigationEndEvent)).pipe(
      switchMap((navigationEvent) => this.store.select(selectAnalytics).pipe(
        // We only need the state of selectAnalytics just
        // once to check the state of the tabs
        take(1),
        tap(async (analyticsData) => {
          if (navigationEvent === true || navigationEvent instanceof NavigationEnd) {
            await this.setPathParts(this.router.url)
            if (this.mainNav === 'widget-library' && this.subNav) {
              if (!this.isWidgetLibraryTabLoaded(analyticsData, this.subNav)) {
                this.store.dispatch(new FetchWidgetLibraryTab({ tabId: this.subNav }))
              }
            } else if (this.mainNav === 'dashboards' && !this.subNav) {
              if (this.isMineTabEnabled) {
                this.router.navigate([this.mineTabLink()])
              } else {
                this.dashboardTabs$.pipe(first(present)).subscribe(tabs => {
                  this.router.navigate([this.tabLink(tabs[0])])
                })
              }
            }
            if (this.mainNav === 'dashboards' && this.subNav === 'mine') {
              this.store.dispatch(new CallApi({
                apiName: 'MyDashboard',
                failureAction: LoaderActionTypes.DashboardLoadFailure,
                successAction: LoaderActionTypes.DashboardLoadSuccess,
                startAction: {
                  type: LoaderActionTypes.FetchMyDashboardTab,
                }
              }))
            }
          }
        }),
      ))
    ).subscribe()

    this.currentUserSub = combineLatest(
      [this.store.select(selectUser), this.store.select(fromWall.selectExternalUserEntities)]).subscribe(
      ([currentUser, externalUserEntities]) => {
        this.currentUser = currentUser
        this.currentExternalUser = externalUserEntities[currentUser?.external_user_id]

        const siteNotifications = currentUser.site_notifications.filter(sn => sn.location === "analytics")
        if (siteNotifications?.length) {
          const newTabModalRef = this.modalService.open(
            SiteNotificationComponent,
            { modalDialogClass: '!tw-w-1/2' }
          )
          newTabModalRef.componentInstance.siteNotification = siteNotifications[0]
          newTabModalRef.componentInstance.user = currentUser
        }
      })

    this.accountSub = this.store.select(selectAccount).subscribe(account => {
      this.account = account
    })

    this.analyticsData$ = this.store
      .select(selectAnalytics)
      .pipe(
        tap(analytics => {
          this.analyticsData = analytics
          this.cd.markForCheck()
        }),
        shareReplay(1),
      )

    this.activityLabelsSub = this.cacheService.activityColors$.subscribe(activityColors => {
      this.activityColors = activityColors
    })

    this.dashboardTabs$ = this.store.select(selectAllDashboardTabsWithDashboardCharts).pipe(
      tap(tabs => this.tabsCount = tabs.length)
    )

    this.loaded$ = this.store.select(selectDashboardLoadCompleted)
    this.exportingPdf$ = merge(this.dashboardPdfService.exporting$, this.exportMainDashboardService.exporting$)

    loadDashboardIfNecessary(this.store)

    this.modalListenerSub = this.actions.pipe(
      ofType(GenericTableModalActionTypes.DataChangedInModal)
    ).subscribe(() => {
      this.onDataInTableModalChanged()
    })

    this.chartInitConfigSub = this.store.select(selectChartsInitConfig).pipe(
      tap(state => this.chartInitConfig = state)
    ).subscribe()

    this.newTabListenerSub = this.actions.pipe(
      ofType(DashboardActionTypes.CreateDashboardTabSuccess,
        DashboardActionTypes.DashboardSendChartToCustomSuccess,
        DashboardActionTypes.UpdateDashboardTabSuccess)
    ).subscribe((data: any) => {
      this.updateUserDashboards(data.payload.dashboard_tab || data.payload)
    })
  }

  updateUserDashboards(newDash) {
    const userClone = cloneDeep(this.currentUser)
    const index = userClone.dashboard_tabs.findIndex(dash => dash.id === newDash.id)
    if (index > -1) {
      userClone.dashboard_tabs[index].name = newDash.name
    } else {
      userClone.dashboard_tabs.push({
        name: newDash.name,
        id: newDash.id
      })
    }

    this.store.dispatch(new UpdateSharedUser(userClone))
  }

  onDataInTableModalChanged() {
    if (this.mainNav === 'widget-library' && this.subNav) {
      this.store.dispatch(new FetchWidgetLibraryTab({ tabId: this.subNav, forceLoad: true }))
    }
  }

  ngOnDestroy(): void {
    this.accountSub.unsubscribe()
    this.activityLabelsSub.unsubscribe()
    this.currentUserSub.unsubscribe()
    this.routerSub.unsubscribe()
    this.modalListenerSub.unsubscribe()
    this.chartInitConfigSub?.unsubscribe()
    this.newTabListenerSub?.unsubscribe()
  }

  isWidgetLibraryTabLoaded(analyticsData: AnalyticsState, tabId: string): boolean {
    return !!(analyticsData?.widget_library_tabs_data?.[tabId])
  }

  phaseToOfferStr(chart, attr: offerTypes = "offer"): string {
    const applications = chart.applications
    const offers = chart[attr + "s"]
    let val: number | string = "N/A"

    if (offers !== 0) {
      val = Math.round(10 * applications / offers) / 10
    }

    return `${val} : 1`
  }

  phaseToHireStr(chart): string {
    return this.phaseToOfferStr(chart, "hire")
  }

  phaseToOfferCalcStr(chart, attr: offerTypes = "offer"): string {
    const applications = chart.applications
    const offers = chart[attr + "s"]
    let val: number | string = "N/A"

    if (offers !== 0) {
      val = Math.round(10 * applications / offers) / 10
    }

    return `(${applications} applications) / (${offers} ${attr}s) = ${val} (${chart.ratio}) (${chart.jobs} jobs)`
  }

  phaseToHireCalcStr(chart): string {
    return this.phaseToOfferCalcStr(chart, "hire")
  }

  orgDashTabTitle() {
    const companyName = this.account && present(this.account.name)
      ? this.account.name
      : 'Company'
    return `${companyName} Dashboard`
  }

  companyOfferAcceptance(): number {
    return this.analyticsData.hires_total.value === 0
      ? 0
      : 100 *
      (this.analyticsData.hires_total.value /
        (this.analyticsData.hires_total.value +
          this.analyticsData.offers_rejected.value))
  }

  myOfferAcceptance(): number {
    return (
      100 *
      (this.analyticsData.my_hires_total.value /
        (this.analyticsData.my_hires_total.value +
          this.analyticsData.my_offers_rejected.value))
    )
  }

  dashboardExternalLinks() {
    return this.appConfig.dashboardExternalLinks()
  }
  openMyOffersDeclinedModal() {
    this.store.dispatch(new ShowGenericTableModal({
      chart: 'my-offers-rejected',
      clickedGraphTitle: 'Candidates with Rejected Offers',
      data_ids: this.analyticsData.my_offers_rejected.offer_ids,
    }))
  }

  clickNewTab() {
    this.modalService.open(DashboardFormComponent)
  }

  clickReorderTabs(): void {
    this.segmentService.track('Sort Dashboard Tabs (open modal)')

    this.sortTabsModalRef = this.modalService.open(SortTabsComponent)
    this.sortTabsModalRef.componentInstance.tabs$ = this.dashboardTabs$
    this.sortTabsModalRef.componentInstance.tabsType = 'dashboard'
    this.sortTabsModalRef.result.then(() => {
      this.cd.markForCheck()
    }, () => { })
  }

  tabLink(tab: DashboardTab): string {
    return `/dashboard/dashboards/${tab.id}`
  }

  mineTabLink(): string {
    return `/dashboard/dashboards/mine`
  }

  dashboardWidgetClasses(chart) {
    return [
      'dashboard__grid-item',
      `dashboard__grid-item--span-${chart.span}`,
    ]
  }

  openVideoModal() {
    // WARNING: Avoid using this until we determine how to make iframe safe
    const modal = this.modalService.open(VideoModalComponent, { windowClass: 'modal-size-700' })
    const modalInstance = modal.componentInstance as VideoModalComponent
    modalInstance.title = 'How to Create a custom Dashboard'
    modalInstance.embedUrl = 'https://www.loom.com/embed/5557183294714644bb1ec4509174864c'
  }

  trackById(_index: number, obj: { id: string | number }) {
    return obj?.id
  }

  trackByDataSource(_index: number, chart: Chart) {
    return chart.data_source
  }

  trackByIndex(index: number) {
    return index
  }

  openQoHLink() {
    window.open('https://www.crosschq.com/product/analytics','_blank')
  }
}
