<div class="section section--notes notes-section" *ngIf="user.external_user_id || notes | present">
  <h2 class="section__header tw-mb-2 fs-unmask">
    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
      <path fill="#000000" d="M21,6V8H3V6H21M3,18H12V16H3V18M3,13H21V11H3V13Z" />
    </svg>
    <span>{{ appConfig.labelFor('notes') }}</span>
  </h2>

  <div class="section__body">
    <form *ngIf="user.external_user_id && appConfig.createNotesEnabled()" class="note__form" (submit)="addNote()">
      <div class="form-group">
        <textarea name="body" class="note__text-area form-control fs-exclude" required autosize
          (keyup)="onTextAreaKeyUp($event)" (change)="onBodyChange($event)" [ngModel]="body" [disabled]="!formEnabled"
          [appMention]="users" [mentionConfig]="{
          labelKey: 'name',
          mentionSelect: mentionSelect
        }" required></textarea>

        <div class="note__actions">
          <label class="note__checkbox-label checkbox">
            <input #publicCheckbox name="public" (change)="onPublicChange(publicCheckbox.checked)" type="checkbox"
              [checked]="public" [disabled]="!formEnabled" />
            <span class="note__checkbox-text fs-unmask">Visible to Interviewers</span>
          </label>
          <button type="submit" [disabled]="!formEnabled" class="btn btn-primary fs-unmask">
            Add {{ appConfig.labelFor('note') }}
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="notes | present" class="notes">
      <div *ngFor="let note of notes" class="note">
        <div class="note__text tw-rounded-md">
          <span class="fs-exclude">{{ note.body }}</span>
        </div>

        <div class="note__info fs-mask">
          <span class="note__author fs-exclude">
            {{ note.external_user?.name }}
          </span>
          <span class="note__date">
            {{ note.created_at | date:'MMM d, y @ h:mma' }}
          </span>
          <!--svg *ngIf="note.visibility === 'admin_only'"
             ngbTooltip="Admin Only"
             width="9"
             height="10"
             viewBox="0 0 9 10"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M4.43501 3.5104e-05C3.20727 -0.00589823 2.1293 0.740752 1.60852 1.90738C1.34067 2.55513 1.3566 3.44228 1.36902 4.21693H0V10H9V4.21693H7.51124V3.12014C7.51124 2.69015 7.42396 2.29326 7.2609 1.90738C6.78421 0.799485 5.66275 0.00596844 4.43501 3.5104e-05ZM4.43501 1.84146C5.1522 1.86077 5.67533 2.40774 5.70654 3.10916V4.21693H3.17371V3.12014C3.2414 2.35661 3.71781 1.82215 4.43501 1.84146Z" fill="#8B8A8A"/>
        </svg-->
        </div>
      </div>
    </div>
  </div>