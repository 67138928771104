
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { NgModule } from '@angular/core'
import { StoreModule } from '@ngrx/store'

import { ActionCableService } from './services/action-cable.service'
import { ComponentsModule } from './components'
import { JobAnalyticsEffects } from './effects/job-analytics.effects'
import { JobApplicationEffects } from './effects/job-application.effects'
import { JobEffects } from './effects/job.effects'
import { ProjectedHiresEffects } from './effects/projected-hires.effects'
import { SharedModule } from '../shared/shared.module'
import { TabEffects } from './effects/tab.effects'
import { WallEffects } from './effects/wall.effects'
import { reducers } from './reducers'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('wall', reducers),
    EffectsModule.forFeature([
      JobApplicationEffects,
      TabEffects,
      JobEffects,
      JobAnalyticsEffects,
      ProjectedHiresEffects,
      WallEffects
    ]),
  ],
  exports: [ComponentsModule],
})
export class WallModule {
  constructor(
    // Note: must instantiate services so that they connect
    private acs: ActionCableService,
  ) { }
}
