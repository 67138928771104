import { CommonModule } from '@angular/common'
import { LifecycleProbeComponent } from './components/lifecycle-probe/lifecycle-probe.component'
import { NgModule } from '@angular/core'
import { environment } from '../../environments/environment'

@NgModule({
  declarations: [
    LifecycleProbeComponent
  ],
  // don't export anything in production. This will give us errors
  // preemptively on build, so that we don't deploy something that
  // uses anything from this module
  exports: environment.production ? [] : [
    LifecycleProbeComponent
  ],
  imports: [
    CommonModule
  ]
})
export class DebugModule { }
