<ng-container *ngIf="basicDataLoaded$ | async; else spinner">
  <div class="executive-dashboard"
    [class.exporting-exec-dash-to-pdf]="exportingPdf$ | async">
    <div class="exec-dash-config-btn" data-html2canvas-ignore>
      <div class="d-inline" *ngIf="isSharedTab">
        <span>Shared Executive Dashboard tab: </span>
        <span><b>{{ tab.name }}</b></span>
      </div>

      <span class="icon-divider" *ngIf="isSharedTab"></span>

      <!-- edit columns -->
      <a class="actions-button" (click)="openColumnSettings()" ngbTooltip="Edit which columns are visible" *ngIf="!isSharedTab">
        <i class="fa-solid fa-pen-to-square fa-fw"></i>
      </a>
      <!-- reorder columns -->
      <a class="actions-button" (click)="openReorderColumns()" ngbTooltip="Reorder columns" *ngIf="!isSharedTab">
        <i class="fas fa-random fa-fw"></i>
      </a>
      <!-- delete tab -->
      <div mwlConfirmationPopover *ngIf="!tab.default_tab && isOneOfOpenJobsTab && !isSharedTab" class="d-inline"
        popoverTitle="Delete Tab" placement="right" popoverMessage="Do you want to delete the tab '{{ tab.name }}'?"
        (confirm)="deleteTab(tab)">
        <a class="actions-button" ngbTooltip="Delete Tab">
          <i class="fas fa-trash fa-fw"></i>
        </a>
      </div>

      <span class="icon-divider fs-unmask" *ngIf="!isSharedTab"></span>
      <!-- download -->
      <span class="" ngbDropdown placement="bottom" *ngIf="isOneOfOpenJobsTab">
        <a class="actions-button" ngbDropdownToggle ngbTooltip="Download tab as ...">
          <i class="fas fa-download fa-fw"></i>
        </a>
        <div ngbDropdownMenu class="min-width--auto">
          <button ngbDropdownItem (click)="exportToPDF()" [disabled]="!(allDataLoadedForDownload$ | async)">
            <i class="fas fa-file-pdf dropdown-item-icon"></i> {{(allDataLoadedForDownload$ | async) ? 'PDF' : 'Data not ready ...'}}
          </button>
          <button ngbDropdownItem (click)="downloadCSV()" [disabled]="!(allDataLoadedForDownload$ | async)">
            <i class="fas fa-file-csv dropdown-item-icon"></i> {{(allDataLoadedForDownload$ | async) ? 'CSV' : 'Data not ready ...'}}
          </button>
        </div>
      </span>

      <!-- save shared dashboard -->
      <ng-container *ngIf="isSharedTab && hasAccessToExecDash">
        <twng-collapsable-save-icon
          [disabled]="isSavingSharedTab"
          tooltip="Save this tab to your custom executive dashboards"
          [placeholder]="tab.name"
          (saveClicked)="saveSharedDashboard($event)"
          class="w-auto tw-ml-2 d-inline-flex"
          *ngIf="isSharedTab && hasAccessToExecDash">
        </twng-collapsable-save-icon>
      </ng-container>
      
      <!-- copy link to clipboard -->
      <ng-container *ngIf="!tab.default_tab && isOneOfOpenJobsTab && !isSharedTab">
        <span class="icon-divider fs-unmask"></span>

        <div ngbDropdown class="d-inline-block">
          <a class="actions-button" ngbTooltip="Share" id="sortMenu" ngbDropdownToggle>
            <i class="fas fa-share-alt"></i>
          </a>
          
          <div class="filter-dropdown-menu" ngbDropdownMenu aria-labelledby="sortMenu">
            <button class="dropdown-item" ngxClipboard [cbContent]="getClipboardLink()"
              (click)="copyLinkNotification()"
            >
              <i class="fas fa-link"></i> Send link to this tab to a colleague
            </button>
            <button class="dropdown-item tw-flex tw-items-center" (click)="openSendToSlackTab()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="margin-right: 5px;" width="18" height="18"><path d="M94.12 315.1c0 25.9-21.16 47.06-47.06 47.06S0 341 0 315.1c0-25.9 21.16-47.06 47.06-47.06h47.06v47.06zm23.72 0c0-25.9 21.16-47.06 47.06-47.06s47.06 21.16 47.06 47.06v117.84c0 25.9-21.16 47.06-47.06 47.06s-47.06-21.16-47.06-47.06V315.1zm47.06-188.98c-25.9 0-47.06-21.16-47.06-47.06S139 32 164.9 32s47.06 21.16 47.06 47.06v47.06H164.9zm0 23.72c25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06H47.06C21.16 243.96 0 222.8 0 196.9s21.16-47.06 47.06-47.06H164.9zm188.98 47.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06h-47.06V196.9zm-23.72 0c0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06V79.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06V196.9zM283.1 385.88c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06v-47.06h47.06zm0-23.72c-25.9 0-47.06-21.16-47.06-47.06 0-25.9 21.16-47.06 47.06-47.06h117.84c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06H283.1z"/></svg>
              Share to Slack
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <h2 *ngIf="exportingPdf$ | async" class="pdf-title">
      Executive Dashboard
      <h3 class="pdf-subtitle">
        {{ tab.name || "Open Jobs" }}
      </h3>
    </h2>
    <div class="job-filters">
      <twng-jobs-filters (filtersChange)="updateJobFilters($event)" [hideJobName]="true" [currentJobFilters]="tab" [disabled]="isSharedTab"
        [jobFilterType]="jobFilterType()" *ngIf="jobFiltersRefresh$ | async">
      </twng-jobs-filters>
    </div>


    <!-- charts -->
    <ng-container *ngIf="isOneOfOpenJobsTab">
      <div [class.tw-pb-4]="isHiddenSection(hiddenSections.Goals).value">
        <span *ngIf="!tab.default_tab" (click)="changeHiddenStatus(hiddenSections.Goals)"
            class="tw-px-8 tw-pb-2 tw-float-right tw-text-cq-gray-500 tw-font-medium tw-text-sm tw-cursor-pointer">
          {{ isHiddenSection(hiddenSections.Goals).text }} Goals <i class="tw-ml-1 fa-solid {{isHiddenSection(hiddenSections.Goals).icon}}"></i>
        </span>
        <twng-hiring-goal-charts *ngIf="!isHiddenSection(hiddenSections.Goals).value && analyticsData$ | async" [tab]="tab$ | async">
        </twng-hiring-goal-charts>
      </div>   

      <!-- summary -->
      <div class="summary-wrapper tw-relative tw-border tw-border-cq-gray-300 tw-shadow-sm tw-rounded-md tw-bg-white tw-flex tw-flex-col">
        <twng-exec-dash-summary-row [jobIds]="(jobIds$ | async) || []" [tab]="tab" [isSharedTab]="isSharedTab">
        </twng-exec-dash-summary-row>
      </div>

      <div class="dashboard__grid--css" *ngIf="analyticsData$ | async as analyticsData">
        <ng-container *ngFor="let chart of analyticsData.sections[0].charts">
          <twng-chart-wrapper [chart]="chart" [ngClass]="dashboardWidgetClasses()" [chartInitConfig]="chartInitConfig">
          </twng-chart-wrapper>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="jobIds$ | async as jobIds; else spinner">
      <div class="sort-jobs">
        <div class="row align-center no-gutters">
          <twng-sort-job [sortingOptions]="sortingOptions$ | async" [sortingOptionTypes]="sortingOptionsTypes"
            class="tw-mr-auto" (sortingChange)="sortingOptionsChanged($event)">
          </twng-sort-job>
        </div>
        <div class="num-visible-jobs">
          <twng-num-jobs [numJobs]="jobIds.length" [isOpenJobs]="isOneOfOpenJobsTab$ | async"></twng-num-jobs>
        </div>
      </div>
      <div class="infinite-scroll-wrapper" infiniteScroll (scrolled)="onScroll()">
        <div class="no-jobs-found" *ngIf="!jobIds.length">
          No jobs found
        </div>
        <div class="exec-dash-job-container"
          *ngFor="let jobId of jobIds | slice:0:numberOfVisibleJobs" [id]="jobId">
          <twng-executive-dashboard-job [jobId]="jobId" [exportingPdf]="exportingPdf$ | async" [tab]="tab$ | async">
          </twng-executive-dashboard-job>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #spinner>
  <div class="spinner">
    <twng-spinner></twng-spinner>
  </div>
</ng-template>

<twng-spinner-overlay [exporting]="exportingPdf$ | async"></twng-spinner-overlay>
