import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'twng-num-jobs',
  template: `
    <div [ngPlural]="numJobs" class="wrapper" *ngIf="numJobs !== null && numJobs !== undefined">
      <ng-template ngPluralCase="0">
        <span class="text">No jobs found matching the given criteria.</span>
      </ng-template>

      <ng-template ngPluralCase="1">
        <span class="count">1</span>
        <span class="count-text">{{ isOpenJobs ? 'open' : 'closed' }} job</span>
        <span class="text">visible with your permissions</span>
      </ng-template>

      <ng-template ngPluralCase="other">
        <span class="count">{{ numJobs }}</span>
        <span class="count-text">{{ isOpenJobs ? 'open' : 'closed' }} jobs</span>
        <span class="text">visible with your permissions</span>
      </ng-template>
    </div>
  `,
  styleUrls: [ './num-jobs.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumJobsComponent implements OnInit {
  @Input()
    numJobs: number | null

  @Input()
    isOpenJobs = true

  constructor() { }

  ngOnInit(): void { }
}
