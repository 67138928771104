import { ComparableSortingType, ComparatorFn, ComparatorReturnType, SortingDirection } from '../utils/sorting'
import { Directive, ElementRef, HostBinding, Input } from '@angular/core'


/**
 * This directive knows how to sort the table for
 * a specific field. It has a default comparator for
 * sorting, but it can receive a comparatorFn
 * to compare any type.
 * It also adds the arrow indicating the direction
 * of the sorting in case to be active
 */
@Directive({
  selector: '[twngTableSort]'
})
export class TableSortDirective {

  directionArrowEl: HTMLElement

  attributeToSort: string

  @Input()
  set sortByAttribute(attributeToSortBy: string) {
    this.attributeToSort = attributeToSortBy
    this.nativeElement.dataset.sortByAttribute = attributeToSortBy
  }

  get sortByAttribute(): string {
    return this.attributeToSort
  }

  @Input()
    direction: SortingDirection = SortingDirection.Asc

  @Input()
    comparator: ComparatorFn

  @HostBinding('class')
    elementClass = 'table-sort-pointer';

  constructor(private elementRef: ElementRef) {
  }

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement
  }

  /**
   * Default comparator.
   *
   * @param a value
   * @param b value
   */
  defaultComparator(a: ComparableSortingType, b: ComparableSortingType): ComparatorReturnType {
    if (a < b || !a) {
      return -1
    }

    if (a > b || !b) {
      return 1
    }

    // names must be equal
    return 0
  }

  /**
   * Sorts the elements array in place.
   * It doesn't create a new array instance.
   * This is by design.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sort(tableElements: any[]) {
    if (!tableElements) {
      return
    }

    const comparator: ComparatorFn = this.comparator || this.defaultComparator

    // Sorts in asc order by default
    tableElements.sort((a, b) => comparator(a[this.sortByAttribute], b[this.sortByAttribute]))

    if (this.direction === SortingDirection.Desc) {
      tableElements.reverse()
    }

    this.invertDirection()
  }

  invertDirection() {
    this.direction = this.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc
  }

  addArrow() {
    this.directionArrowEl = document.createElement('i')
    this.directionArrowEl.classList
      .add('fas', this.direction === SortingDirection.Asc ? 'fa-arrow-up' : 'fa-arrow-down')
    this.directionArrowEl.style.marginRight = '10px'
    this.nativeElement.insertBefore(this.directionArrowEl, this.nativeElement.firstChild)
  }

  removeArrow() {
    if (this.directionArrowEl) {
      this.nativeElement.removeChild(this.directionArrowEl)
      this.directionArrowEl = null
    }
  }

  set isActive(isActive: boolean) {
    if (isActive) {
      this.removeArrow() // Remove current Arrow if exists
      this.addArrow()
    } else {
      this.removeArrow()
    }
  }

}
