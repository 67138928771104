import { Observable, Subscription, combineLatest, firstValueFrom } from 'rxjs'

import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy,
  OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../reducers'
import { Actions, ofType } from '@ngrx/effects'
import { ComponentStateService } from '../../core/services/component-state.service'
import {
  FetchJobById, FetchWallDataPaginatedSuccess, FetchWallDataPaginatedTabSuccess, WallDataPaginatedAllJobsFilter,
  WallDataPaginatedBasicFilter, WallDataPaginatedResult, WallDataPaginatedTabFilter, isTabWallFilter
} from '../actions/wall.actions'
import { InfiniteScrollDirective } from 'ngx-infinite-scroll'
import { JOBS_PER_PAGE, WallApiService } from '../services/wall-api.service'
import { JobApplication } from '../models/job-application'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { NotificationService, NotificationType } from '../../shared/services/notification.service'
import { Tab } from '../models/tab'
import { UpdateAreAllJobsLoaded, UpdateListOfJobsInCurrentWall,
  UpdateRequestAllJobsToLoad
} from '../actions/layout.actions'
import { filter, first } from 'rxjs/operators'
import { isApiLoadedById } from '../../shared/state/selectors'
import { selectImmediatelySync } from '../../shared/utils/store.utils'

@Component({
  selector: 'twng-jobs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  providers: [ComponentStateService]
})
export class JobsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  perPage = JOBS_PER_PAGE
  numberOfVisibleJobs = JOBS_PER_PAGE

  @Input()
  private filters: WallDataPaginatedTabFilter | WallDataPaginatedAllJobsFilter

  @Input()
    showJobHeaders = true

  @Input()
  // Turn this OFF if there are potentially multiple "twng-jobs" components in
  // one page (such as exec dashboard, where we can have wall view for each job)
  private manageAllJobsLoadedInState = true

  @Input()
  // Whether or not we want to clone footer from index.html into bottom of this
  // page
    showFooter = false

  @ViewChild('footer') footer: ElementRef<HTMLDivElement>

  private lastTotalRecords: number

  @Output()
    numTotalJobs = new EventEmitter<number | undefined>()

  @Output()
    tabInfoAvailable = new EventEmitter<Tab>()

  @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

  isCurrentApiCallLoaded$: Observable<boolean>
  // We may unsubscribe from these during lifetime
  private sub = new Subscription()
  // We unsubscribe from this only on destroy
  private entireLifetimeSub = new Subscription()

  private _jobIds: string[]
  get jobIds() {
    return this._jobIds
  }
  set jobIds(value: string[]) {
    this._jobIds = value
    if (this.manageAllJobsLoadedInState) {
      // need to clone _jobIds because store converts this array instance into
      // immutable
      this.store.dispatch(new UpdateListOfJobsInCurrentWall([...this._jobIds]))
    }
  }

  singleJobId: string | undefined

  private visibleJobApplicationIds: string[]
  jobApplicationIdsPerJob: {[jobId: string]: string[]}

  private page: number
  private allJobsLoaded: boolean

  constructor(
    private store: Store<fromWall.State>,
    private wallApi: WallApiService,
    private actions: Actions,
    private cd: ChangeDetectorRef,
    private notifications: NotificationService,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    if (this.manageAllJobsLoadedInState) {
      this.entireLifetimeSub.add(
        this.store.select(fromWall.selectRequestAllJobsToLoadInCurrentWall)
          .pipe(filter(v => v))
          .subscribe(
            // just start to fetch next page, and it will continue until all jobs are loaded
            () => {
              this.getAllJobData()
              this.fetchNextPage()
            }
          )
      )
      this.entireLifetimeSub.add(
        this.store.select(fromWall.selectCurrentJobIdToViewInWall)
          .subscribe(
            id => {
              this.singleJobId = id
              this.cd.detectChanges()
            }
          )
      )
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters) {
      this.resetPageFetching()
    }
  }

  private get originalFooter(): HTMLDivElement {
    return document.getElementById("page-footer") as HTMLDivElement
  }

  private isFetchingSingleJob(): boolean {
    return !!this.singleJobId || (
      this.filters && !isTabWallFilter(this.filters) &&
      this.filters.job_filter?.job_external_ids?.length === 1
    )
  }

  ngAfterViewInit() {
    // we must do this in timeout because boostrap tab has animated fading
    setTimeout(() => {
      if (this.showFooter && this.originalFooter) {
        this.footer.nativeElement.innerHTML = this.originalFooter.innerHTML
        this.originalFooter.style.display = "none"
      }
    }, 500)
  }

  onScroll(): void {
    if (this.numberOfVisibleJobs <= this.jobIds.length) {
      this.numberOfVisibleJobs += 3
      this.fetchNextPage()
    }
  }

  private resetPageFetching() {
    this.page = -1
    this.jobIds = []
    this.visibleJobApplicationIds = []
    this.jobApplicationIdsPerJob = {}
    this.allJobsLoaded = false
    delete this.lastTotalRecords
    this.numTotalJobs.emit(undefined)
    delete this.isCurrentApiCallLoaded$
    this.closeToast()
    if (this.manageAllJobsLoadedInState) {
      this.store.dispatch(new UpdateAreAllJobsLoaded(false))
      this.store.dispatch(new UpdateRequestAllJobsToLoad(false))
    }
    this.sub.unsubscribe()
    this.sub = new Subscription()
    this.fetchNextPage()
  }

  private async isCurrentlyLoaded() {
    if (!this.isCurrentApiCallLoaded$) {
      return true
    }
    return firstValueFrom(this.isCurrentApiCallLoaded$)
  }

  private async fetchNextPage() {
    if (!await this.isCurrentlyLoaded() || !this.filters || this.allJobsLoaded) {
      return
    }
    this.page++
    const shouldFetchCreditedTo = this.page === 0 && !this.isFetchingSingleJob()
    const action = isTabWallFilter(this.filters) ?
      this.wallApi.getFetchTabJobsPaginatedAction(this.filters, this.page, shouldFetchCreditedTo) :
      this.wallApi.getFetchJobsPaginatedAction(this.filters, this.page, shouldFetchCreditedTo)
    const payload = action.payload.startAction.payload as WallDataPaginatedBasicFilter
    const fetchId = action.payload.id
    payload.frontendFetchId = fetchId
    this.isCurrentApiCallLoaded$ = isApiLoadedById(this.store, fetchId)
    const result$ = this.actions.pipe(
      ofType<FetchWallDataPaginatedSuccess | FetchWallDataPaginatedTabSuccess>(action.payload.successAction),
    )
    this.sub.add(combineLatest([
      result$.pipe(first(result => result.payload.frontendFetchId === fetchId)),
      this.isCurrentApiCallLoaded$.pipe(first(Boolean)), // pass only truthy values
    ]).pipe(
      first(),
    ).subscribe(([resultAction]) => {
      this.handleNewPage(resultAction.payload)
    }))
    this.store.dispatch(action)
    this.cd.detectChanges()
  }

  private handleNewPage(result: WallDataPaginatedResult) {
    if (result.tab) {
      this.tabInfoAvailable.emit(result.tab)
    }
    this.lastTotalRecords = result.total_records
    this.numTotalJobs.emit(this.lastTotalRecords)

    this.appendData(result.job_ids, result.job_applications)

    // if not all jobs loaded, check if we have a task of loading all jobs
    const requestAll = selectImmediatelySync(this.store, fromWall.selectRequestAllJobsToLoadInCurrentWall)
    this.allJobsLoaded = this.jobIds.length >= this.lastTotalRecords
    if (this.manageAllJobsLoadedInState) {
      if (this.allJobsLoaded) {
        this.store.dispatch(new UpdateAreAllJobsLoaded(true))
      } else {
        // fetch next page if we are requested to load all jobs
        if (requestAll) {
          this.fetchNextPage()
        }
      }
    }
    if (requestAll) {
      this.updateToast()
    }
    if (this.allJobsLoaded) {
      this.closeToast()
    }
    if (requestAll) {
      this.getAllJobData()
    }
  }

  private updateToast() {
    this.notifications.updateNotification(NotificationType.DownloadingJobs, "Download progress",
      this.lastTotalRecords, this.jobIds.length)
  }

  private closeToast() {
    this.notifications.closeNotification(NotificationType.DownloadingJobs)
  }

  private getAllJobData() {
    for (const jid of this.jobIds) {
      this.store.dispatch(new FetchJobById(jid))
    }
  }

  private appendData(jobIds: string[], jobApplications: JobApplication[]) {
    this.jobIds = this.jobIds.concat(jobIds)
    this.visibleJobApplicationIds = this.visibleJobApplicationIds.concat(jobApplications.map(ja => ja.id))
    jobIds.forEach(jobId => {
      this.jobApplicationIdsPerJob[jobId] = jobApplications.filter(ja => ja.job_id === jobId).map(ja => ja.id)
    })
    this.cd.detectChanges()
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
    this.entireLifetimeSub.unsubscribe()
    this.closeToast()
    if (this.showFooter && this.originalFooter) {
      this.originalFooter.style.display = null
    }
  }
}
