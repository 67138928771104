import { ActionReducerMap } from '@ngrx/store'

import * as fromApi from '../reducers/api.reducers'
import { SharedDataState, reducer as sharedDataReducer } from '../reducers/shared-data.reducers';
import { TrackFeaturesState, reducer as trackFeaturesReducer } from '../reducers/track-features.reducer';

export interface SharedState {
  api: fromApi.State
  sharedData: SharedDataState,
  trackFeatures: TrackFeaturesState
}

export const reducers: ActionReducerMap<SharedState> = {
  api: fromApi.reducer,
  sharedData: sharedDataReducer,
  trackFeatures: trackFeaturesReducer,
}
