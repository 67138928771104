import { ActivatedRoute, Router } from '@angular/router'
import {
  ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2
} from '@angular/core'
import {
  NgbModal, NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'

import * as fromWall from '../reducers'
import { Account } from '../models/account'
import { AppConfigService } from '../services/app-config.service'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Location } from '@angular/common';
import { SiteNotificationComponent } from '../../shared/components/site-notification/site-notification.component'
import { User } from '../models/user'
import {selectAccount, selectUser} from '../../reducers'

@Component({
  selector: 'twng-wall',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.scss'],
})
export class WallComponent implements OnInit, OnDestroy {
  user: User
  userSub = new Subscription()
  account: Account
  accountSub: Subscription

  urlSafe: SafeResourceUrl
  appRoot = document.getElementsByClassName('app-root')[0]
  ngContainer = document.getElementsByClassName('ng-container')[0]
  iframeSubs = new Subscription()

  constructor(
    private store: Store<fromWall.State>,
    private modalService: NgbModal,
    public appConfig: AppConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private renderer: Renderer2,
    public sanitizer: DomSanitizer
  ) { }

  tabModalOptions: NgbModalOptions = {
    backdrop: 'static',
  }

  ngOnInit() {
    this.accountSub = this.store.select(selectAccount).subscribe(account => {
      this.account = account
    })

    this.userSub.add(this.store.select(selectUser).subscribe(user => {
      this.user = user

      window.addEventListener('message', this.onMessageListener)

      this.manageUrl()
      this.renderer.addClass(document.documentElement, 'tw-h-full')
      this.renderer.addClass(document.documentElement, 'hide-scrollbar')
      this.renderer.addClass(document.body, 'tw-h-full')
      this.renderer.addClass(document.body, 'hide-scrollbar')

      if (this.appRoot && this.ngContainer) {
        this.renderer.addClass(this.appRoot, 'tw-h-full')
        this.renderer.addClass(this.ngContainer, 'tw-h-full')
      }

      const siteNotifications = this.user.site_notifications.filter(sn => sn.location === "wall")
      if (siteNotifications?.length) {
        const newTabModalRef = this.modalService.open(
          SiteNotificationComponent,
          { modalDialogClass: '!tw-w-1/2', ...this.tabModalOptions },
        )
        newTabModalRef.componentInstance.siteNotification = siteNotifications[0]
        newTabModalRef.componentInstance.user = user
      }
    }))
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe()
    this.accountSub.unsubscribe()
    this.iframeSubs.unsubscribe()
    window.removeEventListener('message', this.onMessageListener)
    this.renderer.removeClass(document.documentElement, 'tw-h-full')
    this.renderer.removeClass(document.documentElement, 'hide-scrollbar')
    this.renderer.removeClass(document.body, 'tw-h-full')
    this.renderer.removeClass(document.body, 'hide-scrollbar')

    if (this.appRoot && this.ngContainer) {
      this.renderer.removeClass(this.appRoot, 'tw-h-full')
      this.renderer.removeClass(this.ngContainer, 'tw-h-full')
    }
  }

  manageUrl() {
    let tabsUrl = ''
    if (this.router.url.includes('tabs')) {

      tabsUrl = `/tabs${this.router.url.split('tabs')[1]}`
    }
    this.iframeSubs.add(this.route.url.subscribe(url => {
      if (url[0] && url[0].path.includes('wall')) {
        this.location.replaceState(`/wall${tabsUrl}`)
      }
    }))

    const url = `//${window.sobrio_host_name}/${this.account.allegro_slug}/wall${tabsUrl}`
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  onMessageListener = (event) => {
    const { data, origin } = event

    if (origin.includes(window.sobrio_host_name)) {
      const parsedData = JSON.parse(data)

      if (parsedData.wallTabId) {
        this.location.replaceState(`/wall/tabs/${parsedData.wallTabId}`)
      }
      if (parsedData.wallTabId === null) {
        this.location.replaceState(`/wall`)
      }
    }
  }
}
