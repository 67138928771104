<div aria-labelledby="widgetActionsMenu">
  <form (ngSubmit)="submit(updateStatusForm.value)" #updateStatusForm="ngForm">
    <section class="section--with-separator tw-mt-3">
      <div class="form-group" *ngIf="{status: (jobLastStatusUpdate$ | async)?.status} as data">
        <label>
          Status
        </label>
        <select required [ngModel]="getJobStatusLabel(data.status)" (ngModelChange)="selectedStatus = $event" 
          name="status" class="form-select select-status">
          <option *ngFor="let option of possibleOptions">{{ option }}</option>
        </select>
      </div>

      <div class="form-group note-group">
        <label>
          Note
        </label>
        <textarea [(ngModel)]="noteInput" name="note" class="form-control note"
                  maxlength="500"></textarea>
      </div>
    </section>

    <section class="section--with-separator section--content">
      <div>
        <div class="section__row">
            <span ngbTooltip="Cannot update in demo mode" [disableTooltip]="!demoMode">
              <button type="submit" class="btn btn-primary fs-unmask"
                      [disabled]="demoMode">
                Apply
              </button>
            </span>
        </div>
      </div>
    </section>
  </form>
</div>
