import { DashboardTab } from "../../dashboard/models/dashboard-tab"

export const DESTINATION_SLACK = "slack"
export type ScheduledReportDestinationType = "slack"

export const FREQUENCY_TYPE_DAILY = "daily"
export const FREQUENCY_TYPE_WEEKLY = "weekly"
export const FREQUENCY_TYPE_MONTHLY = "monthly"
export type ScheduledReportFrequencyType = "daily" | "weekly" | "monthly"

export interface ScheduledReport {
  id: number
  destination: string
  destination_type: ScheduledReportDestinationType
  frequency: ScheduledReportFrequencyType
  hours: number
  minutes: number
  last_error: string | null
  last_sent: string | null
  next_scheduled_time: string
  repetition_day: number
  dashboard_tab: DashboardTab
  dashboard_tab_view_token: string | null
  scheduled: boolean
  text: string
}
