<div class="analytics-wrap" [class.exporting-to-pdf]="exportingToPdf">
  <div class="analytics-wrap__header" data-html2canvas-ignore>
    <div class="analytics-top-actions">
      <button (click)="copy()" type="button" class="btn btn-light btn-sm btn-copy-to-clipboard button-margin-right"
        [ngbTooltip]="'Copy to clipboard'" triggers="mouseenter:mouseleave" aria-label="Copy to Clipboard">
        <i class="far fa-copy"></i>
      </button>

      <button (click)="openEEOCModal()" type="button" class="btn btn-light btn-sm button-margin-right"
        [ngbTooltip]="'Show EEOC filters'" triggers="mouseenter:mouseleave" aria-label="Show EEOC filters"
        *ngIf="appConfig.config.feature_flags.enable_eeoc_and_demographic">
        <i class="far fa-user"></i>
      </button>
    </div>

    <twng-analytics-date-range-filters
      (dateRangeChanged)="dateFiltersChanged($event)" [initialDateFilters]="dateFilters">
    </twng-analytics-date-range-filters>
  </div>

  <div *ngIf="!jobAnalytics" class="spinner tw-mt-4 tw-mb-2" data-html2canvas-ignore>
    <i class="fas fa-sync fa-spin fa-3x"></i>
  </div>

  <div [class.analytics-copy-container]="isCopyingToClipboard$ | async" #analyticsContainer>
    <div *ngIf="isCopyingToClipboard$ | async" class="job-header-copy-to-clipboard">
      <twng-job-info-header [job]="job" [showJobId]="false" [showUpdateStatusButton]="false">
      </twng-job-info-header>
    </div>
    <div class="analytics" *ngIf="job && jobStages && jobAnalytics">
      <div class="sidebar sidebar--left">
        <div class="stats">
          <div class="stage__big-nums">
            <div class="stage__big-num stage__big-number--clickable"
              (click)="appsModal(jobAnalytics?.total_active?.job_application_ids, 'Active candidates')">
              <header class="stats__label fs-unmask">Total Active</header>
              <div class="stats__value">{{ jobAnalytics?.total_active?.count || 0}}</div>
            </div>
            <div class="icon-divider"></div>
            <div class="stage__big-num stage__big-number--clickable"
              (click)="appsModal(jobAnalytics?.total_applications_processed?.job_application_ids, 'Processed candidates')">
              <header class="stats__label fs-unmask">Total Processed</header>
              <div class="stats__value">{{ jobAnalytics?.total_applications_processed?.count || 0}}</div>
            </div>
          </div>
        </div>

        <div class="stats stats--ih">
          <header class="stats__label fs-unmask">Interview Hours</header>
          <div class="stats__big-number-wrapper" ngbTooltip="Total time spent interviewing for this role (all stages)">
            <div class="stats__big-number stats__big-number--sidebar">
              {{ totalInterviewTimeString() }}
            </div>
          </div>

          <footer>
            <twng-interview-cost [interviewTime]="totalInterviewTimeString()"
              [job]="job" [interviewHourlyCost]="jobAnalytics?.interview_hourly_cost"
              [hires]="jobAnalytics?.total_hires.count">
            </twng-interview-cost>
          </footer>
        </div>
      </div>

      <div class="stages ctc">
        <twng-dropoff-graph
          [conversionRates]="jobAnalytics.dropoff_rates[stage.id]"
          [eeocConversionRates]="eeocJobAnalytics?.dropoff_rates[stage.id]"
          [stageName]="stage.name"
          [jobName]="job.name"
          [timeInterviewing]="jobAnalytics.time_interviewing[stage.id]?.value"
          [candidatesInterviewed]="jobAnalytics.candidates_interviewed[stage.id]?.value"
          [yAxisMax]="yAxisMax"
          *ngFor="let stage of jobStages; trackBy: trackStageById; let i = index;">
        </twng-dropoff-graph>

        <twng-empty-dropoff-graph [hiredOfferIds]="getHiresAppIds()" [jobName]="job.name">
        </twng-empty-dropoff-graph>
      </div>

      <div class="sidebar sidebar--right">
        <div class="stats stats--to">
          <header class="stats__label fs-unmask">Total Offers</header>
          <div class="stats__big-number-wrapper">
            <div class="stats__big-number stats__big-number--sidebar stats__big-number--clickable"
              placement="left bottom" (click)="offerModal(jobAnalytics.total_offers.offer_ids)">
              {{ jobAnalytics?.total_offers?.count || 0 }}
              <div class="stats__big-number-icon">
                <i class="fas fa-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="stats stats--to">
          <header class="stats__label fs-unmask">Declined Offers</header>
          <div class="stats__big-number-wrapper">
            <div class="stats__big-number stats__big-number--sidebar stats__big-number--clickable"
              placement="left bottom" (click)="offerModal(jobAnalytics.declined_offers.offer_ids)">
              {{ jobAnalytics?.declined_offers?.count || 0 }}
              <div class="stats__big-number-icon">
                <i class="fas fa-user"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="stats stats--ph">
          <header class="stats__label stats__label--ph fs-unmask">
            <span>Projected Hires in Pipeline</span>
          </header>

          <div class="stats__big-number-wrapper">
            <div class="stats__big-number stats__big-number--sidebar stats__big-number--ph"
              [style.left]="job.status === 'open' ? '18px' : 'initial'">
              <twng-projected-hires-number [jobId]="job.id"></twng-projected-hires-number>
              <div class="stats__big-number-icon">
                <twng-configure-hiring-predictions *ngIf="jobStages && jobAnalytics && job.status === 'open'"
                  class="projected-hires-container" [job]="job">
                </twng-configure-hiring-predictions>
              </div>
            </div>
          </div>
        </div>

        <div class="stats stats--staff">
          <header class="stats__label fs-unmask">Hiring Team</header>
          <div class="stats__big-number-wrapper">
            <div class="stats__big-number stats__big-number--sidebar">
              <div class="more" [ngbPopover]="hiringTeamPopover" placement="top auto" popoverClass="hiring-team-popover"
                triggers="mouseenter:mouseleave" aria-label="Hiring Team">
                <i class="team-icon fas fa-users" data-html2canvas-ignore></i>
                <span class="badge--number">{{ hiringTeamSize() }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <twng-spinner-overlay
    placeholder="Copying job analytics to clipboard"
    [isPdfSpinner]="false"
    [exporting]="isCopyingToClipboard$ | async"
    [positionAbsolute]="true"
  >
  </twng-spinner-overlay>
</div>

<ng-template #hiringTeamPopover>
  <div class="section__body fs-mask" *ngIf="userEntities$ | async as userEntities">
    <div *ngIf="hiringTeamSize() === 0" class="hiring-team-empty">
      No one has been assigned to the hiring team for this job.
    </div>

    <ng-container *ngFor="let userId of job.recruiter_ids">
      <dl *ngIf="userEntities[userId]">
        <dt class="section__item-label fs-unmask">Recruiter</dt>
        <dd class="section__item-value">{{ userEntities[userId]?.name }}</dd>
      </dl>
    </ng-container>

    <ng-container *ngFor="let userId of job.coordinator_ids">
      <dl *ngIf="userEntities[userId]">
        <dt class="section__item-label fs-unmask">Coordinator</dt>
        <dd class="section__item-value">{{ userEntities[userId]?.name }}</dd>
      </dl>
    </ng-container>

    <ng-container *ngFor="let userId of job.hiring_manager_ids">
      <dl *ngIf="userEntities[userId]">
        <dt class="section__item-label fs-unmask">Hiring Manager</dt>
        <dd class="section__item-value">{{ userEntities[userId]?.name }}</dd>
      </dl>
    </ng-container>

    <ng-container *ngFor="let userId of job.sourcer_ids">
      <dl *ngIf="userEntities[userId]">
        <dt class="section__item-label fs-unmask">Sourcer</dt>
        <dd class="section__item-value">{{ userEntities[userId]?.name }}</dd>
      </dl>
    </ng-container>
  </div>
</ng-template>

