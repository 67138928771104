import { ApiActionTypes, ApiActions, ApiStatus, SetApiStatus } from "../actions/api.actions"

export interface SingleApiState {
  status: ApiStatus,
  id: number
}

export interface State {
  [apiName: string]: SingleApiState
}

const defaultState: State = {}

export function reducer(state = defaultState, action: ApiActions) {
  switch (action.type) {
    case ApiActionTypes.SetApiStatus: {
      const setApiAction = action as SetApiStatus
      const newValue: SingleApiState = {
        status: setApiAction.payload.newApiStatus,
        id: action.payload.id
      }
      return {
        ...state,
        [setApiAction.payload.apiName]: newValue
      }
    }
  }
  return state
}
