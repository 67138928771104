import { Observable, combineLatest } from 'rxjs'
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators'

import { Dictionary } from '@ngrx/entity'
import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'

import * as fromWall from '../reducers'
import { ActivityLabels } from '../models/activity-day-categories'
import { Candidate } from '../models/candidate'
import { DataSources } from '../../dashboard/reducers/dashboard.reducer'
import { Department, DepartmentWithChildren } from '../models/department'
import { ExecutiveDashboardState } from '../reducers/executive-dashboard.reducer'
import { ExternalUser } from '../models/external-user'
import { FetchJobAnalytics } from '../actions/jobs.actions'
import { Interview } from '../models/interview'
import { Job } from '../models/job'
import { JobAnalytics } from '../reducers/job-analytics.reducer'
import { JobApplication } from '../models/job-application'
import { JobStage } from '../models/job-stage'
import { JobStatus, JobStatusUpdate } from '../models/job-status-update'
import { Office, OfficeWithChildren } from '../models/office'
import { SourceTypeIconMappings } from '../models/wall-settings'
import { SourceWithChildren } from '../models/source'
import { TwColors } from '../../dashboard/components/color-schemes'
import { User } from '../models/user'
import { mapJobIdsToStatusUpdates } from '../../shared/utils/job-utils'
import { selectDataSources } from '../../dashboard/reducers'

export interface InterviewsAndJobEntities {
  interviewsByJobAppId: Dictionary<Interview[]>,
  jobStageEntities: Dictionary<JobStage>,
}

export interface JobIdToStatusUpdate { [id: string]: JobStatusUpdate[] }

@Injectable({ providedIn: 'root' })
export class CacheService {
  officeEntities$: Observable<Dictionary<Office>>
  departmentEntities$: Observable<Dictionary<Department>>
  externalUsers$: Observable<ExternalUser[]>
  sourcingUsers$: Observable<ExternalUser[]>
  externalUsersById$: Observable<Dictionary<ExternalUser>>
  activeExternalUsers$: Observable<ExternalUser[]>

  departmentsWithChildren$: Observable<DepartmentWithChildren[]>
  officesWithChildren$: Observable<OfficeWithChildren[]>
  sourcesWithChildren$: Observable<SourceWithChildren[]>

  candidatesEntities$: Observable<Dictionary<Candidate>>
  interviewsAndJobEntities$: Observable<InterviewsAndJobEntities>
  sourceTypeIconMappings$: Observable<SourceTypeIconMappings>

  userEntities$: Observable<Dictionary<User>>

  dataSources$: Observable<DataSources>
  activityColors$: Observable<{ name: string, value: string }[]>
  jobStatusColors$: Observable<{ name: string, value: string }[]>

  recruiters$: Observable<ExternalUser[]>
  creditedToUsers$: Observable<ExternalUser[]>

  executiveDashboard$: Observable<ExecutiveDashboardState>
  jobIdToStatusUpdates$: Observable<JobIdToStatusUpdate>

  jobIdsToJobStages$: Observable<{ [jobId: string]: JobStage[] }>
  closedJobIdsToJobStages$: Observable<{ [jobId: string]: JobStage[] }>
  jobIdsToJobAnalytics$: Observable<{ [jobId: string]: JobAnalytics }>

  jobIdsToHiredJobApplication$: Observable<{ [jobAppId: string]: JobApplication[] }>

  constructor(private store: Store<fromWall.State>) {
    this.officeEntities$ = this.store
      .select(fromWall.selectOfficeEntities)
      .pipe(
        distinctUntilChanged(),
        shareReplay(1)
      )

    this.departmentEntities$ = this.store
      .select(fromWall.selectDepartmentEntities)
      .pipe(
        distinctUntilChanged(),
        shareReplay(1)
      )

    this.departmentsWithChildren$ = this.store.pipe(
      select(fromWall.selectDepartmentsWithChildren),
      distinctUntilChanged(),
      shareReplay(1)
    )

    this.officesWithChildren$ = this.store.pipe(
      select(fromWall.selectOfficesWithChildren),
      distinctUntilChanged(),
      shareReplay(1)
    )

    this.sourcesWithChildren$ = this.store.pipe(
      select(fromWall.selectSourcesWithChildren),
      distinctUntilChanged(),
      shareReplay(1)
    )

    this.externalUsers$ = this.store.pipe(
      select(fromWall.selectAllExternalUsers),
      distinctUntilChanged(),
      shareReplay(1),
    )

    this.sourcingUsers$ = this.store.pipe(
      select(fromWall.selectAllSourcingUsers),
      distinctUntilChanged(),
      shareReplay(1),
    )

    this.externalUsersById$ = this.externalUsers$.pipe(
      distinctUntilChanged(),
      map(users => Object.fromEntries(users.map(user => [user.id, user]))),
      shareReplay(1)
    )

    this.activeExternalUsers$ = this.store.pipe(
      select(fromWall.selectActiveExternalUsers),
      distinctUntilChanged(),
      shareReplay(1),
    )

    this.userEntities$ = this.store
      .select(fromWall.selectUsersEntities)
      .pipe(
        distinctUntilChanged(),
        shareReplay(1)
      )

    this.candidatesEntities$ = this.store
      .select(fromWall.selectCandidateEntities)
      .pipe(
        distinctUntilChanged(),
        shareReplay(1)
      )

    this.interviewsAndJobEntities$ = combineLatest([
      this.store.select(fromWall.selectInterviewsByJobApplicationId).pipe(distinctUntilChanged()),
      this.store.select(fromWall.selectJobStageEntities)]).pipe(distinctUntilChanged())
      .pipe(
        map(([interviewsByJobAppId, jobStageEntities]) => ({
          interviewsByJobAppId,
          jobStageEntities
        })),
        shareReplay(1),
      )

    this.sourceTypeIconMappings$ = this.store.select(fromWall.selectSourceTypeIconMappings)
      .pipe(
        distinctUntilChanged(),
        shareReplay(1)
      )

    this.dataSources$ = this.store.select(selectDataSources).
      pipe(
        distinctUntilChanged(),
        shareReplay(1)
      )

    this.activityColors$ = this.store.select(fromWall.selectActivityLabels).
      pipe(
        map((activityLabels: ActivityLabels) => {
          if (activityLabels) {
            return [
              { name: activityLabels.good, value: TwColors.green },
              { name: activityLabels.fair, value: TwColors.yellow },
              { name: activityLabels.poor, value: TwColors.red },
              { name: activityLabels.dead, value: TwColors.gray },
            ]
          }
        }),
        distinctUntilChanged(),
        shareReplay(1)
      )

    this.jobStatusColors$ = this.store.select(fromWall.selectJobStatusLabels).
      pipe(
        map((jobStatusLabels: JobStatus) => {
          if (jobStatusLabels) {
            return [
              { name: jobStatusLabels.Green, value: TwColors.green },
              { name: jobStatusLabels.Yellow, value: TwColors.yellow },
              { name: jobStatusLabels.Red, value: TwColors.red },
              { name: jobStatusLabels['On Hold'], value: TwColors.darkGray },
              { name: jobStatusLabels['No Status'], value: TwColors.white },
            ]
          }
        }),
        distinctUntilChanged(),
        shareReplay(1)
      )

    this.recruiters$ = this
      .store
      .select(fromWall.selectExternalUserEntities)
      .pipe(
        map(externalUsers =>
          Object.values(externalUsers)
            .filter(external_user => external_user.recruiter)
            .sort((u1, u2) => u1.name.localeCompare(u2.name))
        )
      )

    this.creditedToUsers$ = this.store
      .select(fromWall.selectCreditedToIds)
      .pipe(
        switchMap((ids: string[]) =>
          this.store.select(fromWall.selectExternalUserEntities).pipe(
            map(externalUsers =>
              ids.map(recruiterId => externalUsers[recruiterId])
                // some recruiters may no longer / not yet exist
                .filter(r => r)
                .sort((u1, u2) => u1.name.localeCompare(u2.name))
            ),
          )
        ),
        shareReplay(1)
      )

    this.jobIdToStatusUpdates$ = this.store.select(fromWall.executiveDashboardSelector).pipe(
      map((jobStatusUpdates: ExecutiveDashboardState) => mapJobIdsToStatusUpdates((jobStatusUpdates))),
      shareReplay(1)
    )

    this.executiveDashboard$ = this.store.select(fromWall.executiveDashboardSelector).pipe(
      shareReplay(1)
    )

    const closedJobStagesSelector = this.store.select(
      (state) => {
        const jobStages = state.wall.executiveDashboard.closed_jobs_info?.job_stages || []
        return [...jobStages].sort((a, b) => a.position - b.position)
      }
    )
    const openJobStagesSelector = this.store.select(
      fromWall.selectAllJobStages
    )
    const jobStageMapper = map((jobStages: JobStage[]) => {
      const mapResult = {}
      for (const jobStage of jobStages) {
        mapResult[jobStage.job_id] = mapResult[jobStage.job_id] || []
        mapResult[jobStage.job_id].push(jobStage)
      }
      return mapResult
    })
    this.jobIdsToJobStages$ = openJobStagesSelector.pipe(
      jobStageMapper,
      shareReplay(1)
    )
    this.closedJobIdsToJobStages$ = closedJobStagesSelector.pipe(
      jobStageMapper,
      shareReplay(1)
    )

    this.jobIdsToJobAnalytics$ = this.store.select(fromWall.selectAllJobs).pipe(
      map((jobs: Job[]) => {
        const mapResult = {}
        for (const job of jobs) {
          mapResult[job.id] = new FetchJobAnalytics({ job }) || {}
        }
        return mapResult
      }),
      shareReplay(1)
    )

    this.jobIdsToHiredJobApplication$ = this.store.select(fromWall.selectHiredJobApplications).pipe(
      map((hiredJobApplications: JobApplication[]) => {
        const jobIdsMap = {}
        hiredJobApplications.forEach((jobApp: JobApplication) => {
          const jobId = jobApp.job_id
          jobIdsMap[jobId] = jobIdsMap[jobId] || []
          jobIdsMap[jobId].push(jobApp)
        })
        return jobIdsMap
      }),
      shareReplay(1)
    )
  }

}
