import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output
} from '@angular/core'

import { FilterOption } from './filter-option.interface'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-generic-filter',
  templateUrl: './generic-filter.component.html',
  styleUrls: ['./generic-filter.component.scss']
})
export class GenericFilterComponent implements OnInit {

  @Input()
    placeholder: string

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('options')
  set inputOptions(options: FilterOption[]) {
    this.options = options
    this.filteredOptions = options ? options.filter(option => !this.isOptionSelected(option)) : []
  }

  @Output()
    optionSelected = new EventEmitter<FilterOption>()

  @Output()
    optionRemoved = new EventEmitter<FilterOption>()

  @Output()
    selectedOptionsChange = new EventEmitter<FilterOption[]>()

  options: FilterOption[]

  // Current values to display
  filteredOptions: FilterOption[] = []

  // Options selected
  @Input()
    defaultSelectedOptions: FilterOption[] | string[] = []

  selectedOptions: FilterOption[] = []

  ngOnInit(): void {
    for (const option of this.defaultSelectedOptions) {
      if (typeof option === "string") {
        this.selectOption(this.getOptionById(option), false)
      } else {
        this.selectOption(option)
      }
    }
  }

  selectOption(selectedOption: FilterOption, shouldEmit = true) {
    this.selectedOptions.push(selectedOption)
    this.filteredOptions = this.filteredOptions.filter(option => selectedOption.id !== option.id)
    if (shouldEmit) {
      this.optionSelected.emit(selectedOption)
      this.selectedOptionsChange.emit(this.selectedOptions)
    }
  }

  removeOption(optionToRemove: FilterOption) {
    this.filteredOptions.push(optionToRemove)
    this.selectedOptions = this.selectedOptions.filter(option => optionToRemove.id !== option.id)
    this.optionRemoved.emit(optionToRemove)
    this.selectedOptionsChange.emit(this.selectedOptions)
  }

  isOptionSelected(option: FilterOption) {
    return !!this.selectedOptions.find(selectedOption => selectedOption.id === option.id)
  }

  private getOptionById(id: string): FilterOption {
    return this.options.find(option => option.id === id)
  }

  reset() {
    if (this.defaultSelectedOptions?.length) {
      if (typeof this.defaultSelectedOptions[0] === "string") {
        this.selectedOptions = (this.defaultSelectedOptions as string[]).map((value) => this.getOptionById(value))
      } else {
        this.selectedOptions = this.defaultSelectedOptions as FilterOption[]
      }
    } else {
      this.selectedOptions = []
    }
    this.selectedOptionsChange.emit(this.selectedOptions)
  }

}
