import { CandidateTag } from '../models/candidate-tag'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { sortByName } from './sort-comparers'

export type State = EntityState<CandidateTag>

export const adapter: EntityAdapter<CandidateTag> = createEntityAdapter<
CandidateTag
>({
  sortComparer: sortByName,
})

export const initialState: State = adapter.getInitialState()

export function reducer(state = initialState, action: LoaderActions): State {
  switch (action.type) {
    case LoaderActionTypes.LoadSharedDataSuccess: {
      return adapter.setAll(action.payload.candidate_tags || [], state)
    }

    case LoaderActionTypes.UpdateFromServer: {
      if (!action.payload.candidate_tags) {
        return state
      }
      const candidate_tags = action.payload.candidate_tags
      return adapter.upsertMany(candidate_tags, state)
    }
    default:
      return state
  }
}

