import { camelCase as _camelCase } from 'lodash-es'

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core'
import { Color, PieChartComponent } from '@swimlane/ngx-charts'
import { Store } from '@ngrx/store'

import { CacheService } from '../../wall/services/cache.service'
import { ChartCommonComponent } from './chart-common.component'
import { ClipboardService } from '../../core/services/clipboard.service'
import { DashboardChart } from '../models/dashboard-chart'
import { DefaultColorScheme } from './color-schemes'

function formatLabel(label: string) {
  const matchingRow = this.data.find(row => row.data && row.data.name === label)
  const totalCount = this.data.reduce((acc, b) => acc + b.value, 0)
  const percent = ((matchingRow.value/totalCount) * 100).toFixed(0)
  const matchingLabel = `${percent}%`

  return matchingRow ? matchingLabel : label
}

function formatToolTip(tooltip) {
  const totalCount = this.data.reduce((acc, b) => acc + b.value, 0)
  const percent = ((tooltip.value/totalCount) * 100).toFixed(0)

  return `${tooltip.data.name}: ${tooltip.value} (${percent}%)`
}
@Component({
  selector: 'twng-dash-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: [
    './dashboard.shared.component.scss',
    './dashboard.component.scss',
    './dashboard.gridster.component.scss',

    './pie-chart.component.scss',
    './pie-chart.gridster.component.scss',
  ],
})
export class DashPieChartComponent extends ChartCommonComponent implements OnInit {

  constructor(
    private cd: ChangeDetectorRef,
    private clipboardService: ClipboardService,
    cacheService: CacheService,
    store: Store,
  ) {
    super(store, cacheService)
  }

  @Input()
    colorScheme: Color = DefaultColorScheme

  @Input()
    gridItemType: 'css' | 'gridster' = 'css'

  @Input()
    legendInCopiedImg: boolean

  @Input()
    filtersInCopiedImg: boolean

  @Input()
    chart: DashboardChart

  @Input()
    dataSourceParameters: { [key: string]: string }

  @Input()
    dataSource: string

  @Input()
    editingGridster: boolean

  @Input()
    chartFiltersReadOnly: boolean

  @ViewChild(PieChartComponent, { static: false }) gridsterPieChart: PieChartComponent

  view: [number, number]

  formatLabel = formatLabel
  formatToolTip = formatToolTip

  copyingToClipboard = false

  static trackResizeEvents() {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, false)

    const el = window.document // This can be your element on which to trigger the event
    el.dispatchEvent(event)
  }

  ngOnInit(): void {
    // This is needed because there's a race condition
    // with the charts rendering and display:grid
    setTimeout(() => DashPieChartComponent.trackResizeEvents())
    super.ngOnInit()
  }

  camelCase(val: string) {
    return _camelCase(val)
  }

  copyChartToClipboard(chartEl: Element, legendInCopiedImg: boolean, filtersInCopiedImg: boolean) {
    this.copyingToClipboard = true
    this.clipboardService.copyChartToClipboard(chartEl, legendInCopiedImg, filtersInCopiedImg).finally(() => {
      this.copyingToClipboard = false
      this.cd.detectChanges()
    })
  }

  widgetBodyClasses() {
    return {
      body: true,
      'body--no-filters': !this.chart,
      'body--with-filters': this.chart
    }
  }

  setView(view: [number, number], originalContainer: [number, number]) {
    this.view = [originalContainer[0], originalContainer[1]]

    // @ts-ignore
    const svg = this.gridsterPieChart?.chartElement?.nativeElement?.querySelector('svg')

    // To make sure that the widgets in custom dashboards change if the user resizes the chart
    if (svg) {
      setTimeout(() => svg.setAttribute('width', this.view[0]))
      setTimeout(() => svg.setAttribute('height', this.view[1]))
    }
  }
}
