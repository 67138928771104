import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Job } from '../models/job'
import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { WallActionTypes, WallActions } from '../actions/wall.actions'
export type State = EntityState<Job>

export function sortByPosition(a: Job, b: Job): number {
  return a.position - b.position
}

// TODO Why sort by position? Why not by name, which is usually the default?
export const adapter: EntityAdapter<Job> = createEntityAdapter<Job>({
  sortComparer: sortByPosition,
})

export const initialState: State = adapter.getInitialState()

export function jobFromApiResponse(apiRepresentation): Job {
  return {
    ...apiRepresentation,
    id: `${apiRepresentation.id}`,
  }
}

export function reducer(state = initialState, action: LoaderActions | WallActions): State {
  switch (action.type) {
    case LoaderActionTypes.UpdateFromServer:
    case WallActionTypes.FetchJobsByIdsSuccessAction: {
      if (!action.payload.jobs) {
        return state
      }

      const jobs = action.payload.jobs.map(apiJob => jobFromApiResponse(apiJob))
      return adapter.upsertMany(jobs, state)
    }
  }
  return state
}
