import * as fromWall from '../../../wall/reducers'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable } from 'rxjs'
import { ReorderTabs } from '../../../core/actions/tab.actions'
import { Store } from '@ngrx/store'
import { Tab } from '../../../wall/models/tab'
import { TabType } from '../../../core/models/tab.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-sort-tabs',
  styleUrls: ['./sort-tabs.component.scss'],
  templateUrl: './sort-tabs.component.html'
})
export class SortTabsComponent {
  @Input()
    tabs$: Observable<Tab[]>

  @Input()
    tabsType: TabType

  constructor(
    public activeModal: NgbActiveModal,
    public store: Store<fromWall.State>,
  ) {
  }

  updateTabPosition(event, tabs) {
    const reorderedTabs = [...tabs].filter(t => t.id !== event.tab.id)
    reorderedTabs.splice(event.newPosition, 0, event.tab)
    const tabsWithUpdatedPosition = reorderedTabs.map((t, i) => ({
      ...t,
      position: i,
    }))

    this.store.dispatch(
      new ReorderTabs({
        type: this.tabsType,
        tabs: tabsWithUpdatedPosition,
        oldTabs: tabs,
      }),
    )
  }

  closeModal(): void {
    this.activeModal.close('sorted tabs')
  }
}
