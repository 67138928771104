import { Interviewer, InterviewerLoaded } from './interviewer'
import { JobStage } from './job-stage'
import { atsId } from './types'

export const INTERVIEW_STATUS_SCHEDULED = 'scheduled'
export const INTERVIEW_STATUS_COMPLETED = 'complete'
export const INTERVIEW_STATUS_AWAITING_FEEDBACK = 'awaiting_feedback'
export type InterviewStatus = 'scheduled' | 'complete' | 'awaiting_feedback'

export class Interview {
  job_application_id: atsId
  id: atsId
  name: string
  start_time: string
  end_time: string
  status: InterviewStatus
  interviewers: Interviewer[]
  job_stage_id: atsId
}

// these are trimmed down interviews w/ some extra fields so we can store a ton of them for the calendar
export class InterviewCalendarEvent {
  id: atsId
  name: string
  start_time: Date
  end_time: Date
  status: InterviewStatus
  job: string
  stage: string
  candidate: string
  interviewers: CalendarInterviewer[]
  interviewer_responses: InterviewerResponses
  mine: boolean
  // for rolled up events
  subEvents: InterviewCalendarEvent[]
  count: number
  job_application_id: string
}

export class InterviewWithJobStage extends Interview {
  job_stage: JobStage
}

export class InterviewLoaded extends InterviewWithJobStage {
  interviewers: InterviewerLoaded[]
}

export interface CalendarInterviewer {
  name: string;
  status: string;
  scorecard: Scorecard
}
export interface Scorecard {
  id: number,
  recommendation: string
}

export interface InterviewerResponses {
  needs_action: number;
  declined: number;
  tentative: number;
  accepted: number;
}
