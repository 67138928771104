import { Injectable, OnDestroy } from "@angular/core"
import { PdfGlobalService } from "./pdf-global.service"

@Injectable()
export class PdfComponentTrackerService implements OnDestroy {
  private isReady = false

  constructor(private pdf: PdfGlobalService) {
    this.pdf.componentIsNotReady()
  }

  componentIsReady() {
    if (!this.isReady) {
      this.pdf.componentIsReady()
      this.isReady = true
    }
  }

  componentIsNotReady() {
    if (this.isReady) {
      this.pdf.componentIsNotReady()
      this.isReady = false
    }
  }

  ngOnDestroy() {
    this.componentIsReady()
  }
}
