import { Directive, HostListener, Input } from '@angular/core';
import { JobApplication } from '../../../../wall/models/job-application';
import { RatingNumber } from '../../../../wall/models/rating-number';
import { Store } from '@ngrx/store';
import { UpdateStarred } from '../../../../card-details/actions/update-starred.actions';

@Directive({
  selector: '[setJobApplicationRating]'
})
export class SetJobApplicationRatingDirective {

  @HostListener('click')
  setPriorityFilter() {
    this.store.dispatch(new UpdateStarred({
      jobApplication: {
        ...this.jobApplication,
        rating: this.ratingNumber,
      }
    }))
  }

  @Input()
    ratingNumber: RatingNumber

  @Input()
    selected = false

  @Input()
    jobApplication: JobApplication

  constructor(private store: Store) {
  }

}
