import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'

import { Activity } from '../models/activity'
import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import { NoteActionTypes, NoteActions } from '../actions/notes.actions'

export type State = EntityState<Activity>

function sortByCreatedAt(a: Activity, b: Activity): number {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
}

export const adapter: EntityAdapter<Activity> = createEntityAdapter<Activity>({
  sortComparer: sortByCreatedAt,
})

export const initialState: State = adapter.getInitialState()

function activityFromApi(activity, candidate) {
  return {
    ...activity,
    type: 'activity',
    candidate_id: candidate.id,
  }
}

function emailFromApi(activity_email, candidate) {
  return {
    ...activity_email,
    type: 'email',
    candidate_id: candidate.id,
  }
}

export function reducer(state = initialState, action: NoteActions | LoaderActions): State {
  switch (action.type) {
    case NoteActionTypes.FetchNotesSuccess: {
      const activities = (action.payload.activities || []).map(a => activityFromApi(a, action.payload.candidate))
      const emails = (action.payload.emails || []).map(em => emailFromApi(em, action.payload.candidate))

      return adapter.upsertMany(activities.concat(emails), state)
    }

    case LoaderActionTypes.WallSharedSuccess: {
      if (action.payload.activities) {
        return adapter.upsertMany(action.payload.activities, state)
      }
      return state
    }

    default: {
      return state
    }
  }
}
