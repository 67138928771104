<form (ngSubmit)="save()" class="tw-inline-flex tw-items-center">
  <input
    type="text"
    [(ngModel)]="text"
    class="collapsable-element form-control"
    [class.uncollapsed]="text !== null"
    [placeholder]="placeholder"
    [disabled]="disabled"
    name="name"
  >
  <button class="actions-button" [ngbTooltip]="tooltip" placement="top auto"
    [disabled]="disabled" style="color: black">
    <i class="fas fa-save" *ngIf="!disabled"></i>
    <i class="fas fa-sync fa-spin" *ngIf="disabled"></i>
  </button>
</form>