import { ConfirmationPopoverModule } from 'angular-confirmation-popover'

import { NgModule } from '@angular/core'

import { ActivityFeedComponent } from './activity-feed.component'
import { AttachmentIconComponent } from './attachment-icon.component'
import { CardDetailItemComponent } from './card-detail-item.component'
import { CardDetailsModalComponent } from './card-details-modal.component'
import { CardDetailsModalContainerComponent } from './card-details-modal-container.component'
import { InterviewsComponent } from './interviews.component'
import { NotePanelComponent } from './note-panel.component'
import { OtherApplicationsComponent } from './other-applications.component'
import { OverlayComponent } from './overlay.component'
import { RejectCandidateComponent } from './reject-candidate.component'
import { SharedModule } from '../../shared/shared.module'
import { SocialMediaIconComponent } from './social-media-icon.component'
import { StarToggleComponent } from './star-toggle.component'
import { StarTogglePresenterComponent } from './star-toggle-presenter.component'
import { TagToggleIconsComponent } from './tag-toggle-icons.component'
import { TransferCandidateComponent } from './transfer-candidate.component'

export const COMPONENTS = [
  CardDetailItemComponent,
  CardDetailsModalContainerComponent,
  CardDetailsModalComponent,
  InterviewsComponent,
  NotePanelComponent,
  TransferCandidateComponent,
  RejectCandidateComponent,
  SocialMediaIconComponent,
  AttachmentIconComponent,
  StarToggleComponent,
  StarTogglePresenterComponent,
  OverlayComponent,
  OtherApplicationsComponent,
  TagToggleIconsComponent,
  ActivityFeedComponent,
]

@NgModule({
  imports: [
    SharedModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger',
      cancelButtonType: 'secondary',
    }),
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ComponentsModule { }
