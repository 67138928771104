/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartFilters } from '../../../models/chart-filters'
import { CommonSingleChartHandler } from './common-single-chart-handler'
import { Directive, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap'
import { RefreshAnalytics } from '../../../actions/filters.actions'
import { SegmentService } from '../../../../core/services/segment.service'
import { Store } from '@ngrx/store'
import { Subject, merge, of } from 'rxjs'
import { cloneDeep, compact, escapeRegExp, flatten, isEqual, keyBy } from 'lodash-es'
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators'
import { last } from 'lodash-es'

export interface SingleValueInFilter {
  name: string
  id: string
  tooltip?: string
  parent_id?: string
}

interface EventTargetWithValue extends EventTarget {
  value: string
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class SingleChartFilterComponent extends CommonSingleChartHandler implements OnChanges, OnInit {
  demoMode = window.twng_demo
  filterLoaded: boolean
  private allDataLoaded: boolean
  private wasInitEverCalled: boolean

  constructor(
    segmentService: SegmentService,
    store: Store,
    public dataTypePlural: string,
    public filterTitle: string,
    public iconClass: string,
  ) {
    super(segmentService, store)
  }

  managers: SearchTypeaheadDataManager<any>[]

  private initFilterData() {
    this.wasInitEverCalled = true
    this.managers.forEach(manager => manager.filterDataAvailable(this.filters))
  }

  private async loadAllData() {
    const allPromises = this.managers.map(manager => manager.initialize())
    return Promise.all(allPromises)
  }

  async ngOnInit() {
    this.managers = this.getDataManagers()
    await this.loadAllData()
    this.allDataLoaded = true
    // if we don't do this, when going from custom dashboard to widget
    // library, widgets are not visible until we hover over something
    // inside dashboard
    this.store.dispatch(new RefreshAnalytics())
    if (this.filterLoaded) {
      this.initFilterData()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && this.filters) {
      if (this.allDataLoaded && (
        !this.wasInitEverCalled || !isEqual(changes.filters.currentValue, changes.filters.previousValue)
      )) {
        this.initFilterData()
      }
      this.filterLoaded = true
    }
  }

  //////////////////////////////////
  // DATA FUNCTIONS
  //////////////////////////////////
  get filterValuesCompactNames(): string[] {
    const names = flatten(this.managers.map(
      manager => manager.filterValuesCompactNames
    ))
    return names
  }

  //////////////////////////////////
  // VISUAL RELATED FUNCTIONS
  //////////////////////////////////
  get showAsIcon(): boolean {
    return !!this.chart
  }

  get countBadgeValue(): string {
    const namesLength = this.filterValuesCompactNames.length
    if (namesLength > 2 || (this.showAsIcon && namesLength > 0)) {
      return namesLength.toString()
    }
  }

  get dataLoaded(): boolean {
    return this.filterLoaded && this.allDataLoaded
  }

  protected getTooltipIfEditable(): string[] {
    let allNames = []
    this.managers.forEach(
      manager => {
        const filterType = manager.getExclusionParamFromFilters() ? 'Excluded' : 'Selected'
        const name = manager.searchPlaceholder
        const selection = manager.filterValuesCompactNames
        if (this.managers.length === 1) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          selection.length ?
            allNames.push(`${filterType} ${name}: ${selection.join(', ')}`) :
            allNames.push(`${filterType} ${name}: All`)
        } else {
          let count = 0
          this.managers.forEach(m => count += m.filterValuesCompactNames.length)
          if (!count) {
            allNames = [`${filterType} ${this.dataTypePlural}: All`]
          } else if (selection.length) {
            allNames.push(`${filterType} ${name}: ${selection.join(', ')}`)
          }
        }
      }
    )

    return allNames
  }

  get includeExcludeText(): string {
    const spanIncludes = '<span class="value-includes">Includes</span>'
    const spanExcludes = '<span class="value-includes">Excludes</span>'
    let text;
    if (this.managers.length === 1) {
      const span = this.managers[0].getExclusionParamFromFilters() ? spanExcludes : spanIncludes
      const names = this.filterValuesCompactNames.slice(0, 2).join(', ')
      text = `${span} ${names}`
    } else {
      const excludes: SearchTypeaheadDataManager<any>[] = []
      const includes: SearchTypeaheadDataManager<any>[] = []
      const withFilteredData: SearchTypeaheadDataManager<any>[] = []
      this.managers.forEach((manager: SearchTypeaheadDataManager<any>) => {
        if (manager.filterValuesCompactNames.length) {
          withFilteredData.push(manager)
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          manager.getExclusionParamFromFilters() ? excludes.push(manager) : includes.push(manager)
        }
      })

      if (excludes?.length === withFilteredData?.length ) {
        text = `${spanExcludes} ${this.filterValuesCompactNames.slice(0, 2).join(', ')}`
      } else if (includes?.length === withFilteredData?.length ) {
        text = `${spanIncludes} ${this.filterValuesCompactNames.slice(0, 2).join(', ')}`
      } else {
        text = `${spanIncludes} ${includes[0].filterValuesCompactNames[0]} - ${spanExcludes} ${excludes[0].filterValuesCompactNames[0]}`
      }
    }

    return `<span>${text}</span>`
  }

  //////////////////////////////////
  // ABSTRACT FUNCTIONS
  //////////////////////////////////
  protected abstract getDataManagers(): SearchTypeaheadDataManager<any>[]
}

export abstract class SearchTypeaheadDataManager<T extends SingleValueInFilter> {
  allData: T[]
  protected allDataPerId: { [key: string]: T }
  protected tempSelectedValues: T[] = []
  protected filters: ChartFilters
  allDataLoaded: boolean
  filterDataLoaded: boolean
  shouldExcludeFilter = false
  name: string
  isInitialSearch = true
  maxFilteredResults = 50
  filteredResultsLength: number
  sourceTypes: string[]

  constructor(public searchPlaceholder: string) { }

  click$ = new Subject<string>()
  focus$ = new Subject<string>()
  protected typeahead: NgbDropdown

  private initTempSelectedValues() {
    this.tempSelectedValues = compact(this.filterValues)
  }

  async initialize() {
    this.allData = await this.loadAllData()
    this.allDataPerId = keyBy(this.allData, v => v.id)
    this.allDataLoaded = true
    if (this.filterDataLoaded) {
      this.initTempSelectedValues()
    }
  }

  initializeViewData(typeahead: NgbDropdown) {
    this.typeahead = typeahead
  }

  filterDataAvailable(filterData: ChartFilters) {
    this.filters = filterData
    this.shouldExcludeFilter = this.getExclusionParamFromFilters()
    if (this.searchPlaceholder === 'Sources') {
      this.sourceTypes = cloneDeep(filterData.source_types) || []
    }
    this.filterDataLoaded = true
    if (this.allDataLoaded) {
      this.initTempSelectedValues()
    }
  }

  //////////////////////////////////
  // UTILITY FUNCTIONS
  //////////////////////////////////
  protected get allIds(): string[] {
    return this.allData.map(v => v.id) as string[]
  }
  protected get filterIds(): string[] {
    return this.getIdsFromFilters() || []
  }
  protected get filterValues(): T[] {
    return this.filterIds
      .map((id: string) => this.getValueFromId(id))
  }
  protected get filterValuesCompact(): T[] {
    return this.getCompactedIds(this.filterIds)
      .map((id: string) => this.getValueFromId(id))
  }
  get filterValuesCompactNames(): string[] {
    return this.filterValuesCompact.map(v => v?.name || "MISSING VALUE")
  }
  get tempSelectedIds() {
    return this.tempSelectedValues.map(v => v.id)
  }
  protected get tempSelectedIdsCompact(): string[] {
    return this
      .getCompactedIds(this.tempSelectedIds)
  }
  get tempSelectedValuesCompact(): T[] {
    return this.tempSelectedIdsCompact
      .map(id => this.getValueFromId(id))
  }

  get dataLoaded(): boolean {
    return this.filterDataLoaded && this.allDataLoaded && !!this.tempSelectedValues
  }

  getValueFromId(id: string) {
    return this.allDataPerId[id]
  }

  private getValuesFromIds(ids: string[]): T[] {
    return ids.map(id => this.getValueFromId(id))
  }

  setFilterExclusion(value: boolean) {
    this.shouldExcludeFilter = value
  }

  setSourceType(value: string) {
    this.sourceTypes.push(value)
  }

  removeSourceType(value: string) {
    const index = this.sourceTypes.indexOf(value)

    if (index !== -1) {
      this.sourceTypes.splice(index, 1)
    }
  }

  //////////////////////////////////
  // VISUAL RELATED FUNCTIONS
  //////////////////////////////////

  selectValueFromTypeahead(value: T) {
    this.selectValue(value)
  }

  unselectId(id: string) {
    this.getExpandedIds(id).forEach(expId => this.unselectSingleId(expId))
  }

  searchValues = (text: string) => {
    const debouncedText$ = of(text).pipe(distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !(this.typeahead && this.typeahead.isOpen())),
    )
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      debounceTime(250),
      map((term: string) => {
        const searchable = this.getSearchableValues()
        const pattern = escapeRegExp(term)

        if (!term) {
          this.filteredResultsLength = searchable.length
          this.isInitialSearch = !!searchable.length
          return searchable.slice(0, this.maxFilteredResults)
        } else {
          const filtered = []
          searchable.forEach(v => {
            if (new RegExp(pattern, 'i').test(v.name)) {
              if (v.parent_id) {
                v.tooltip = this.getHierarchyTooltip(v)
              }
              filtered.push(v)
            }
          })
          this.filteredResultsLength = filtered.length
          this.isInitialSearch = false
          return filtered.slice(0, this.maxFilteredResults)
        }
      })
    )
  }

  keypress($event: KeyboardEvent) {
    if ($event.target) {
      const target = $event.target as EventTargetWithValue

      // backspace in an empty input
      if (target && target.value === '' && $event.code === "Backspace") {
        const lastItem = last(this.tempSelectedValuesCompact)
        if (lastItem) {
          this.unselectId(lastItem.id)
        }
      }
    }
  }

  selectAll() {
    let searchable: T[] = this.getSearchableValues()
    while (searchable.length > 0) {
      this.selectValue(searchable[0])
      searchable = this.getSearchableValues()
    }
  }

  clearAll() {
    this.setFilterExclusion(false)
    while (this.tempSelectedValuesCompact.length > 0) {
      this.unselectId(this.tempSelectedValuesCompact[0].id)
    }
  }

  clearSelection() {
    this.setFilterExclusion(this.getExclusionParamFromFilters())
    this.tempSelectedValuesCompact.forEach(selected => {
      this.unselectId(selected.id)
    })

    this.getIdsFromFilters().forEach(filterId => {
      this.selectValue(this.allDataPerId[filterId])
    })
  }

  getHierarchyTooltip(element) {
    let tooltip = ''
    let parent = this.allData.find((data) => data.id === element.parent_id)

    while (parent) {
      tooltip = `${parent.name} ${tooltip ? '>' : ''} ${tooltip}`
      parent =  this.allData.find((data) => data.id === parent.parent_id)
    }

    return tooltip
  }

  //////////////////////////////////
  // DATA FUNCTIONS
  //////////////////////////////////

  // Get compact version of all ids, from supplied ids. For example, if data is
  // hierarchical, you may want to print only selected parent if all of its
  // children are selected
  protected getCompactedIds(ids: string[]): string[] {
    return ids
  }
  // Reverse operation from getCompactedIds
  protected getExpandedIds(id: string): string[] {
    return [id]
  }
  protected getSearchableValues(): T[] {
    return this.allData.filter(v => !this.tempSelectedIds.includes(v.id))
  }

  // unselect specific id. DON'T USE THIS FUNCTION DIRECTLY, use
  // unselectExpandedIds instead
  protected unselectSingleId(id: string) {
    const idx = this.tempSelectedIds.indexOf(id)
    if (idx !== -1) {
      this.tempSelectedValues = [
        ...this.tempSelectedValues.slice(0, idx),
        ...this.tempSelectedValues.slice(idx + 1)
      ]
    }
  }

  protected selectValue(value: T) {
    const ids = this.getExpandedIds(value.id)
    const values = this.getValuesFromIds(ids)
    values.forEach(val => this.selectSingleValue(val))
  }

  // select specific value. DON'T USE THIS FUNCTION DIRECTLY, use selectValue
  // instead
  protected selectSingleValue(value: T) {
    if (!value || this.tempSelectedIds.includes(value.id)) {
      return
    }
    this.tempSelectedValues.push(value)
  }

  //////////////////////////////////
  // ABSTRACT FUNCTIONS
  //////////////////////////////////
  protected abstract loadAllData(): Promise<T[]>
  protected abstract getIdsFromFilters(): string[]
  abstract getExclusionParamFromFilters(): boolean
}
