import * as fromWall from '../../../../wall/reducers'
import { AppConfigService } from '../../../../wall/services/app-config.service'
import { CacheService } from '../../../../wall/services/cache.service'
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ConversionRatesRecord } from '../../../../wall/reducers/job-stage-stats.reducer'
import { CustomField } from '../../../../custom-fields/models/custom-field'
import { DaysOpenColors, ExecutiveDashboardConfiguration, ExecutiveDashboardTab,
  PerJobConfiguration, isOneOfOpenJobsTab } from '../../../../wall/models/executive-dashboard'
import { DepartmentService } from '../../../../wall/services/department.service'
import { ExecutiveDashboardService, TargetHireDateResultType } from '../../../services/executive-dashboard.service'
import { FetchJobById, WallDataPaginatedAllJobsFilter } from '../../../../wall/actions/wall.actions'
import { Job } from '../../../../wall/models/job'
import { JobStage } from '../../../../wall/models/job-stage'
import { JobStatus, JobStatusUpdate } from '../../../../wall/models/job-status-update'
import { Observable, Subscription } from 'rxjs'
import { OfficeService } from '../../../../wall/services/office.service'
import { Store } from '@ngrx/store'
import { UrlService } from '../../../../wall/services/url.service'
import { WallApiService } from '../../../../wall/services/wall-api.service'
import { atsId } from '../../../../wall/models/types'
import { daysDifference } from '../../../../shared/utils/general-utils'
import { daysOpen } from '../../../../shared/utils/job-utils'
import { getCustomFieldsAsMap } from '../../../../custom-fields/selectors'
import { map, tap } from 'rxjs/operators'
import { selectClosedJobStageConversionRates, selectExecutiveDashboardClosedJobsById,
  selectExecutiveDashboardConfig, selectExecutiveDashboardDaysOpenColors, selectJobEntities,
  selectJobStageConversionRates, selectOffersPerClosedJobExecDash, selectOffersPerJobExecDash,
  selectPerJobSpecificConfiguration, selectRecentCandidatesPerJobExecDash
} from '../../../../wall/reducers'

interface TargetHireDateResult {
  date: Date
  text: string
  tooltip: string
  cssClasses: string | string[]
}

@Component({
  selector: 'twng-executive-dashboard-job',
  templateUrl: './executive-dashboard-job.component.html',
  styleUrls: [
    './executive-dashboard-job.component.scss',
    '../../../../shared/components/job-info-header/job-info-header.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExecutiveDashboardJobComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
    jobId: string

  @Input()
    tab: ExecutiveDashboardTab

  @Input()
    exportingPdf: boolean

  job$: Observable<Job>
  jobStatusUpdates$: Observable<JobStatusUpdate[]>
  jobConfiguration$: Observable<PerJobConfiguration>
  offersCreated$: Observable<number>
  numRecentCandidates$: Observable<number>
  jobStages$: Observable<JobStage[]>
  conversionRates$: Observable<Record<atsId, ConversionRatesRecord>>
  customFieldMap$: Observable<Map<string, CustomField>>
  config$: Observable<ExecutiveDashboardConfiguration>
  daysOpenColors$: Observable<DaysOpenColors>
  jobStatusLabels$: Observable<JobStatus>

  singleJobFilter: WallDataPaginatedAllJobsFilter

  isOneOfOpenJobsTab: boolean
  shouldShowWall: boolean
  shouldShowAnalytics: boolean

  private sub = new Subscription()

  constructor(
    private store: Store,
    private wallApi: WallApiService,
    public cache: CacheService,
    public appConfig: AppConfigService,
    public departmentService: DepartmentService,
    public officeService: OfficeService,
    public urlService: UrlService,
  ) { }

  ngOnInit() {
    this.config$ = this.store.select(selectExecutiveDashboardConfig)
    this.daysOpenColors$ = this.store.select(selectExecutiveDashboardDaysOpenColors)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.jobStatusLabels$ = this.store.select(fromWall.selectJobStatusLabels)
    if (changes.jobId) {
      this.refreshJob$()
      this.jobStatusUpdates$ = this.cache.jobIdToStatusUpdates$.pipe(map(val => val[this.jobId]))
      this.jobConfiguration$ = this.store.select(selectPerJobSpecificConfiguration, { jobId: this.jobId })
      this.numRecentCandidates$ = this.store.select(selectRecentCandidatesPerJobExecDash).pipe(map(v => v[this.jobId]))
      this.refreshJobStages$()
      this.refreshOffersCreated$()
      this.refreshConversionRates$()
      this.customFieldMap$ = this.store.select(getCustomFieldsAsMap)
      this.singleJobFilter = this.wallApi.getJobFilterForSingleJob(this.jobId)
    }
    if (changes.tab) {
      this.isOneOfOpenJobsTab = isOneOfOpenJobsTab(this.tab)
      this.refreshJob$()
      this.refreshJobStages$()
      this.refreshOffersCreated$()
      this.refreshConversionRates$()
    }
  }

  getLastValidNote(JobStatusUpdates: JobStatusUpdate[]) {
    return JobStatusUpdates.find(jobStatus => jobStatus.note)?.note
  }

  private refreshOffersCreated$() {
    this.offersCreated$ = this.store.select(
      this.isOneOfOpenJobsTab ? selectOffersPerJobExecDash : selectOffersPerClosedJobExecDash
    ).pipe(map(offers => offers[this.jobId]))
  }

  private refreshConversionRates$() {
    this.conversionRates$ = this.store.select(
      this.isOneOfOpenJobsTab ? selectJobStageConversionRates : selectClosedJobStageConversionRates
    ).pipe(map(v => v[this.jobId]))
  }

  private refreshJobStages$() {
    this.jobStages$ = (this.isOneOfOpenJobsTab ? this.cache.jobIdsToJobStages$ : this.cache.closedJobIdsToJobStages$)
      .pipe(
        map(v => v[this.jobId])
      )
  }

  private hasRequestedJob = false
  private refreshJob$() {
    this.job$ = this.store.select(
      this.isOneOfOpenJobsTab ? selectJobEntities : selectExecutiveDashboardClosedJobsById
    ).pipe(
      map(jobs => jobs[this.jobId]),
      tap(job => {
        if (!job && this.isOneOfOpenJobsTab && !this.hasRequestedJob) {
          this.hasRequestedJob = true
          this.store.dispatch(new FetchJobById(this.jobId))
        }
      })
    )
  }

  enabledJobStatusOnWall() {
    return this.appConfig.canViewJobStatusOnWall()
  }

  shouldShowJobStage(jobStageName: string) {
    return !this.tab.excluded_job_stages.includes(jobStageName)
  }

  getDaysOpenClass(job: Job, config: ExecutiveDashboardConfiguration, daysOpenColors: DaysOpenColors) {
    if (config.days_open_exclude_jobs_with_more_openings && job.total_job_openings > 1) {
      return undefined
    }
    if (daysOpenColors?.gray && daysOpenColors.gray.includes(parseInt(job.id, 10))) {
      return undefined
    }
    if (daysOpenColors?.green && daysOpenColors.green.includes(parseInt(job.id, 10))) {
      return "column-green"
    }
    if (daysOpenColors?.yellow && daysOpenColors.yellow.includes(parseInt(job.id, 10))) {
      return "column-yellow"
    }
    if (daysOpenColors?.red && daysOpenColors.red.includes(parseInt(job.id, 10))) {
      return "column-red"
    }
  }

  getGenericColorClass(value: number, useGreen: boolean,
    yellowThreshold: number | null, redThreshold: number | null, isInverted = false) {
    if (value !== null && !isNaN(value)) {
      if (redThreshold !== null && ((!isInverted && value > redThreshold) || (isInverted && value < redThreshold))) {
        return "column-red"
      } else if (yellowThreshold !== null && ((!isInverted && value > yellowThreshold)
      || (isInverted && value < yellowThreshold))) {
        return "column-yellow"
      } else if (useGreen && (yellowThreshold !== null || redThreshold !== null)) {
        return "column-green"
      }
    }
  }

  hasProcessedCandidates(conversionRates: ConversionRatesRecord) {
    return conversionRates?.passed_through > 0 || conversionRates?.rejected > 0
  }

  getTotalActiveCandidates(conversionRates: Record<string, ConversionRatesRecord>) {
    return ExecutiveDashboardService.getTotalActiveCandidates(conversionRates)
  }

  getTotalProcessedCandidates(conversionRates: Record<string, ConversionRatesRecord>) {
    return ExecutiveDashboardService.getTotalProcessedCandidates(conversionRates)
  }

  getJobStageYellowThreshold(jobStageName: string, config: ExecutiveDashboardConfiguration) {
    return (config.use_individual_job_stage_thresholds && config.job_stage_thresholds[jobStageName]?.yellow) ?
      config.job_stage_thresholds[jobStageName].yellow :
      config.conversion_rates_yellow_threshold
  }

  getJobStageRedThreshold(jobStageName: string, config: ExecutiveDashboardConfiguration) {
    return (config.use_individual_job_stage_thresholds && config.job_stage_thresholds[jobStageName]?.red) ?
      config.job_stage_thresholds[jobStageName].red :
      config.conversion_rates_red_threshold
  }

  getTargetHireDays(job: Job, jobConfig: PerJobConfiguration, config: ExecutiveDashboardConfiguration)
    : TargetHireDateResult {
    const result = ExecutiveDashboardService.getTargetHireDays(job, jobConfig, config)
    switch(result.source) {
      case TargetHireDateResultType.NOT_CONFIGURED:
        return {
          cssClasses: 'not-configured-rates',
          date: null,
          text: '?',
          tooltip:
           'To configure the target start date, either configure it for all jobs in Executive ' +
           'Dashboard Configuration or configure it specifically for this job by clicking on wrench icon below',
        }
      case TargetHireDateResultType.FROM_PER_JOB_CONFIG:
        return {
          cssClasses: this.getGenericColorClass(
            daysDifference(new Date(), result.date),
            config.target_hire_date_use_green_instead_of_gray,
            config.target_hire_date_yellow_threshold,
            config.target_hire_date_red_threshold,
            true,
          ),
          date: result.date,
          text: null,
          tooltip: null,
        }
      case TargetHireDateResultType.FROM_GLOBAL_CONFIG:
        return {
          cssClasses: ['not-configured-rates', this.getGenericColorClass(
            daysDifference(new Date(), result.date),
            config.target_hire_date_use_green_instead_of_gray,
            config.target_hire_date_yellow_threshold,
            config.target_hire_date_red_threshold,
            true,
          ) || ''],
          date: result.date,
          text: null,
          tooltip: `Configured as ${config.target_hire_days_after_opening} days from opening date in ` +
          `Executive Dashboard Configuration. You can change target start date for this job ` +
          `specifically by clicking on wrench icon below`,
        }
    }
  }

  customFieldAsArray(customFieldValue: null | string | string[]) {
    return ExecutiveDashboardService.customFieldAsArray(customFieldValue)
  }

  daysOpen(j: Job): number {
    return daysOpen(j)
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
