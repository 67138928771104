import { APP_BASE_HREF, CommonModule } from '@angular/common'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { CardDetailsModule } from './card-details/card-details.module'
import { CoreModule } from './core/core.module'
import { CustomFieldsModule } from './custom-fields/custom-fields.module'
import { DashboardModule } from './dashboard/dashboard.module'
import { EffectsModule } from '@ngrx/effects'
import { ErrorHandler, NgModule } from '@angular/core'
import { ExecutiveDashboardModule } from './executive-dashboard/executive-dashboard.module'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NavigationEnd, Router, RouterModule } from '@angular/router'
import { NgbPopoverConfig, NgbPopoverModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component'
import { ProspectsModule } from './prospects/prospects.module'
import { RollbarErrorHandler, RollbarService, rollbarFactory } from './rollbar'
import { SharedModule } from './shared/shared.module'
import { StageMappingsModule } from './stage-mappings/stage-mappings.module'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { StoreModule } from '@ngrx/store'
import { ToastrModule } from 'ngx-toastr'
import { TwngHttpAuthorizedInterceptor } from './core/services/twng-http-unauthorized-interceptor.service'
import { UserSettingsModule } from './user-settings/user-settings.module'
import { WallModule } from './wall/wall.module'
import { appRoutes } from './app.routes'
import { environment } from '../environments/environment'
import { filter } from 'rxjs/operators'
import { metaReducers, reducers } from './reducers'
import { removeRailsContainer } from './core/rails-utils'

const runtimeChecks = {
  strictStateImmutability: environment.enableNgrxStoreRuntimeChecks,
  strictActionImmutability: environment.enableNgrxStoreRuntimeChecks,
  strictStateSerializability: environment.enableNgrxStoreRuntimeChecks,
  strictActionSerializability: environment.enableNgrxStoreRuntimeChecks,
  strictActionWithinNgZone: environment.enableNgrxStoreRuntimeChecks,
  strictActionTypeUniqueness: environment.enableNgrxStoreRuntimeChecks
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TwngHttpAuthorizedInterceptor, multi: true },
]

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    WallModule,
    DashboardModule,
    ProspectsModule,
    ExecutiveDashboardModule,
    StageMappingsModule,
    CardDetailsModule,
    UserSettingsModule,
    CustomFieldsModule,
    HttpClientModule,
    SharedModule,
    EffectsModule.forRoot([]),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5_000,
      maxOpened: 6,
      autoDismiss: true,
      newestOnTop: true,
      preventDuplicates: false,
    }),
    StoreModule.forRoot(reducers, { metaReducers, runtimeChecks }),
    CoreModule.forRoot(),
    RouterModule.forRoot(appRoutes, {}),
    StoreDevtoolsModule.instrument({ maxAge: 100 }),
    NgbTooltipModule,
    NgbPopoverModule
  ],
  declarations: [AppComponent, NotFoundPageComponent],
  providers: [
    NgbTooltipConfig,
    { provide: APP_BASE_HREF, useValue: '/' },
    httpInterceptorProviders,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // Configure tooltips for the entire application
  // Note: must `provide` NgbTooltipConfig for this config to be used.
  constructor(
    ngbTooltipConfig: NgbTooltipConfig,
    ngbPopoverConfig: NgbPopoverConfig,
    router: Router
  ) {
    ngbTooltipConfig.container = 'body'
    ngbPopoverConfig.container = 'body'

    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        // Remove rails container when is rendering an angular route on navigationEnd
        removeRailsContainer()
      })
  }
}
