import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'

import { State, selectAppConfig } from '../../reducers'

@Injectable({ providedIn: 'root' })
export class CanViewDashboardGuard  {
  constructor(private store: Store<State>) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(selectAppConfig),
      map(appConfig => appConfig && appConfig.feature_flags.dashboard),
      first(config => config)
    )
  }
}
