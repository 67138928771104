import { Subscription } from 'rxjs'

import { Dictionary } from '@ngrx/entity'
import { Injectable, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../../wall/reducers'
import { AddNotePayload } from '../actions/notes.actions'
import { JobApplication } from '../models/job-application'
import { Note } from '../models/note'
import { UpdateFromServer } from '../../core/actions/loader.actions'
import { User } from '../models/user'
import { atsId } from '../models/types'
import { selectUser } from '../../reducers'

@Injectable({ providedIn: 'root' })
export class DemoService implements OnDestroy {
  jobApplicationEntities: Dictionary<JobApplication>
  user: User

  jobApplicationsSubscription: Subscription
  userSubscription: Subscription
  constructor(private store: Store<fromWall.State>) {
    this.jobApplicationsSubscription = this.store
      .select(fromWall.selectJobApplicationEntities)
      .subscribe(jobApplicationEntities => {
        this.jobApplicationEntities = jobApplicationEntities
      })

    this.userSubscription = this.store.select(selectUser).subscribe(user => {
      this.user = user
    })
  }

  ngOnDestroy(): void {
    if (this.jobApplicationsSubscription) {
      this.jobApplicationsSubscription.unsubscribe()
    }
  }

  successfulResponse() {
    return { status: 200, body: { status: 'ok' } }
  }

  newNoteFromPayload(payload: AddNotePayload): Note {
    return {
      id: new Date().getSeconds().toString(),
      candidate_id: payload.candidate_id,
      body: payload.body,
      user_id: this.user.external_user_id,
      visibility: 'public',
      created_at: new Date().toJSON(),
    }
  }

  updateInStore(jobApplication: JobApplication): void {
    const dataToUpdate = {
      job_applications: [jobApplication],
    }
    this.store.dispatch(new UpdateFromServer(dataToUpdate))
  }

  rejectJobApplication(jobApplicationId: atsId): void {
    const jobApplication = {
      ...this.jobApplicationEntities[jobApplicationId],
      status: 'rejected',
      last_activity_at: new Date().toJSON(),
    }
    this.updateInStore(jobApplication)
  }

  transferJobApplication(
    jobApplicationId: atsId,
    jobStageId: atsId,
    jobId: atsId,
  ): void {
    const jobApplication = {
      ...this.jobApplicationEntities[jobApplicationId],
      job_id: jobId,
      job_stage_id: jobStageId,
      last_activity_at: new Date().toJSON(),
    }
    this.updateInStore(jobApplication)
  }
}
