import { Injectable } from "@angular/core"
import { ToastrService } from "ngx-toastr"

export enum NotificationType {
  GeneratingPDF, DownloadingJobs, DownloadingTableRows
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notificationIds: Partial<Record<NotificationType, number>> = {}
  constructor(private toastr: ToastrService) {}

  updateNotification(type: NotificationType, text?: string, currentProgress?: number, maxProgress?: number) {
    if (!this.notificationIds[type]) {
      this.notificationIds[type] = this.toastr.info(undefined, undefined, {
        disableTimeOut: true,
        closeButton: false,
        tapToDismiss: false,
      }).toastId
    }
    const id = this.notificationIds[type]
    const toast = this.toastr.toasts.find(testToast => testToast.toastId === id)
    if (text) {
      toast.toastRef.componentInstance.title = text
    }
    if (maxProgress) {
      toast.toastRef.componentInstance.message = (currentProgress / maxProgress * 100).toFixed(1) + "%"
    }
  }

  closeNotification(type: NotificationType) {
    const id = this.notificationIds[type]
    if (id !== null) {
      delete this.notificationIds[type]
      this.toastr.clear(id)
    }
  }
}
