import { ActivatedRoute, Router } from '@angular/router'
import { AppConfigService } from '../../../wall/services/app-config.service'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { FeatureFlags } from '../../../wall/models/app-config'
import { NgbNav } from '@ng-bootstrap/ng-bootstrap'
import { Subscription } from 'rxjs'
import { wait } from '../../utils/general-utils'

export interface TabDescriptor {
  url: string
  text: string
  requiredFeatureFlag?: FeatureFlags
}

@Component({
  selector: 'twng-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit, OnDestroy {
  data: TabDescriptor[]

  @ViewChild('nav', {static: true})
    nav: NgbNav

  private sub = new Subscription()

  constructor(
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    public appConfig: AppConfigService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    // when route data is changed (only on init)
    this.sub.add(this.route.data.subscribe(data => {
      this.data = []
      for (const [key, value] of Object.entries(data)) {
        this.data[key] = value
      }
    }))
    // when route is changed, but this component is still not destroyed
    this.sub.add(this.route.url.subscribe(async () => {
      if (!this.data.find(tab => tab.url === location.pathname)) {
        this.router.navigateByUrl(this.data[0].url)
      }
      // make sure correct tab is selected after route is changed
      await wait(0)
      this.nav.select(location.pathname)
      this.cd.markForCheck()
    }))
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
}
