import { Observable, of } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { Action } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { DemoService } from '../../wall/services/demo.service'
import {
  RejectCandidate, RejectCandidateFailure, RejectCandidateSuccess, RejectCandidateUIActionTypes
} from '../actions/reject-candidate-ui.actions'
import { apiHost, getHttpPostOptions } from '../../core/http-options'

@Injectable()
export class RejectCandidateEffects {
  RejectCandidate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<RejectCandidate>(RejectCandidateUIActionTypes.RejectCandidate),
    map(action => action.payload),
    mergeMap(
      ({
        jobApplicationId,
        rejectionReasonId,
        rejectionReasonText,
        emailTemplateId,
        sendEmailAt,
        candidate,
      }) => {
        if (window.twng_demo) {
          this.demoService.rejectJobApplication(jobApplicationId)
          const requestData = this.demoService.successfulResponse()
          return of(new RejectCandidateSuccess({ requestData, jobApplicationId }))
        }

        return this.http
          .post(
            apiHost + `/twng/job_applications/${jobApplicationId}/reject`,
            {
              rejection_reason_id: rejectionReasonId,
              rejection_email_template_id: emailTemplateId,
              rejection_reason_text: rejectionReasonText,
              send_email_at: sendEmailAt,
            },
            getHttpPostOptions(),
          )
          .pipe(
            map(
              requestData => {
                this.toastr.success(`Rejected ${candidate.first_name} ${candidate.last_name}`)
                return new RejectCandidateSuccess({
                  requestData,
                  jobApplicationId,
                })
              }
            ),
            catchError(requestData => {
              console.log("Error rejecting", jobApplicationId, rejectionReasonId,
                emailTemplateId, sendEmailAt, requestData)
              const networkError = requestData.status === 0
              this.toastr.error(`Error rejecting ${candidate.first_name} ${candidate.last_name}`)
              return of(
                new RejectCandidateFailure({
                  jobApplicationId,
                  networkError,
                }),
              )
            }),
          )
      }),
  ))

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private toastr: ToastrService,
    private demoService: DemoService,
  ) { }
}

