import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../../../../dashboard/reducers'
import { FilterOption } from '../generic-filter/filter-option.interface'
import { GenericFilterComponent } from '../generic-filter/generic-filter.component'
import { selectAllJobStageNames } from '../../../../wall/reducers'

@Component({
  selector: 'twng-job-stages-filter',
  templateUrl: './job-stages-filter.component.html',
  styleUrls: ['./job-stages-filter.component.scss']
})
export class JobStagesFilterComponent implements OnInit {

  jobStageNames$: Observable<FilterOption[]>

  @Input()
    jobStageNames: FilterOption[] | string[] = [{ name: 'Offer', id: 'Offer'} ]

  @ViewChild('jobStagesFilter') jobStagesFilter: GenericFilterComponent

  @Output()
    filterChange = new EventEmitter<FilterOption[]>()

  constructor(private store: Store<fromWall.State>) { }

  ngOnInit(): void {

    this.jobStageNames$ = this.store.select(selectAllJobStageNames)
      .pipe(
        map((selectedJobStageNames: string[]) => [...selectedJobStageNames].sort()),
        map((selectedJobStageNames: string[]): FilterOption[] =>
          selectedJobStageNames.map((jobStageName): FilterOption => ({
            id: jobStageName,
            name: jobStageName,
          }))),
      )
  }

  selectedOptionsChange(jobStageNameOptions: FilterOption[]) {
    this.filterChange.emit(jobStageNameOptions)
  }

  reset() {
    this.jobStagesFilter.reset()
  }

  resetTo(newDefaults: GenericFilterComponent['defaultSelectedOptions']) {
    this.jobStagesFilter.defaultSelectedOptions = newDefaults
    this.reset()
  }
}
