import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core'

import { AppConfigService } from '../services/app-config.service'
import {
  FilterOption
} from '../../shared/components/filters/generic-filter/filter-option.interface'
import { JobFilters } from '../reducers/layout.reducer'
import {
  SelectedCustomFieldsFilter
} from '../../custom-fields/models/selected-custom-fields-filter'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-jobs-filters',
  styles: [
    `
      :host {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        font-size: 0.9em;
      }

      .job-filter {
        margin-right: 5px;
        margin-top: 5px;
        min-width: 270px;
        width: auto;
        background: white;
        display: block;
        flex-grow: 1;
      }

      twng-job-name-filter {
        flex-grow: 1;
      }
    `,
  ],
  template: `
    <twng-department-filter
      *ngIf="appConfig.isJobFilterEnabled('departments')"
      class="job-filter"
      [disabled]="disabled"
      [departmentIds]="currentJobFilters.department_ids"
      (filterChange)="onFilterChange('department_ids', $event)">
    </twng-department-filter>

    <twng-office-filter
      *ngIf="appConfig.isJobFilterEnabled('offices')"
      class="job-filter"
      [disabled]="disabled"
      [officeIds]="currentJobFilters.office_ids"
      (filterChange)="onFilterChange('office_ids', $event)">
    </twng-office-filter>

    <twng-job-filter
      *ngIf="jobFilterType"
      class="job-filter"
      [disabled]="disabled"
      [jobIds]="currentJobFilters.job_ids"
      [jobFilterType]="jobFilterType"
      (filterChange)="onFilterChange('job_ids', $event)">
    </twng-job-filter>

    <twng-user-filter
      *ngIf="appConfig.isJobFilterEnabled('users')"
      class="job-filter"
      [disabled]="disabled"
      [userIds]="currentJobFilters.external_user_ids"
      (filterChange)="onFilterChange('external_user_ids', $event)">
    </twng-user-filter>

    <twng-custom-fields-filters
      class="job-filter"
      placeholder="Job Custom Fields"
      [disabled]="disabled"
      [selectedCustomFieldFilterOption]="currentJobFilters.custom_fields"
      (filterChange)="onCustomFieldsChanged($event)"
    ></twng-custom-fields-filters>

    <twng-job-name-filter *ngIf="!hideJobName"></twng-job-name-filter>
  `,
})
export class JobsFiltersComponent implements OnChanges {

  currentFilters: JobFilters = {
    department_ids: [],
    external_user_ids: [],
    office_ids: [],
    job_priorities: [],
    employment_types: [],
    custom_fields: [],
    job_ids: [],
  }

  /**
   * Job Filters selected on All jobs or a tab
   */
  @Input()
    currentJobFilters: JobFilters = this.currentFilters

  @Input()
    hideJobName = false

  @Input()
    jobFilterType = undefined

  @Input()
    disabled = false

  @Output()
    filtersChange = new EventEmitter()

  constructor(public appConfig: AppConfigService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.currentJobFilters?.firstChange) {
      this.currentFilters = {
        ...this.currentFilters,
        ...this.currentJobFilters
      }
    }
  }

  onFilterChange(filterName: string, filters: FilterOption[]) {
    this.currentFilters = {
      ...this.currentFilters,
      [filterName]: filters.map(filterOption => filterOption.id)
    }
    this.emitJobFiltersChanged()
  }

  onCustomFieldsChanged(selectedCustomFieldsFilter: SelectedCustomFieldsFilter[]) {
    this.currentFilters = {
      ...this.currentFilters,
      custom_fields: selectedCustomFieldsFilter
    }
    this.emitJobFiltersChanged()
  }

  emitJobFiltersChanged() {
    setTimeout(() => this.filtersChange.emit(this.currentFilters))
  }
}
