import { Action, Store } from "@ngrx/store"
import { ActionCableService } from "../services/action-cable.service"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { ClosedJobsInfo } from "../reducers/executive-dashboard.reducer"
import { CreateExecutiveDashboardTab, CreateExecutiveDashboardTabFailure, CreateExecutiveDashboardTabSuccess,
  DeleteExecutiveDashboardTab, DeleteExecutiveDashboardTabFailure, DeleteExecutiveDashboardTabSuccess,
  ExecutiveDashChartPayload, ExecutiveDashboardActionsTypes, ExecutiveDashboardPayload, FetchExecutiveDashboard,
  FetchExecutiveDashboardCharts, FetchExecutiveDashboardChartsFailed, FetchExecutiveDashboardChartsSuccess,
  FetchExecutiveDashboardClosedJobs, FetchExecutiveDashboardClosedJobsFailure, FetchExecutiveDashboardClosedJobsSuccess,
  FetchExecutiveDashboardFailure, FetchExecutiveDashboardSuccess, FetchJobIdsForExecutiveDashboardTab,
  FetchJobIdsForExecutiveDashboardTabResponse, FetchJobIdsForExecutiveDashboardTabSuccess, FetchJobStatuses,
  FetchJobStatusesFailure, FetchJobStatusesSuccess, FetchJobStatusesSuccessPayload, FetchSharedExecutiveDashboardTab,
  FetchSharedExecutiveDashboardTabFailure, FetchSharedExecutiveDashboardTabSuccess, ReorderExecutiveDashboardTabs,
  ReorderExecutiveDashboardTabsFailure, ReorderExecutiveDashboardTabsSuccess, SendJobStatusUpdate,
  SendJobStatusUpdateFailure, SendJobStatusUpdateSuccess,
  UpdateExecutiveDashboardConfig, UpdateExecutiveDashboardConfigFailure,
  UpdateExecutiveDashboardConfigSuccess, UpdateExecutiveDashboardTab, UpdateExecutiveDashboardTabFailure,
  UpdateExecutiveDashboardTabHideSection, UpdateExecutiveDashboardTabHideSectionFailure,
  UpdateExecutiveDashboardTabHideSectionSuccess, UpdateExecutiveDashboardTabSuccess, UpdatePerJobConfig,
  UpdatePerJobConfigFailure, UpdatePerJobConfigSuccess
} from "../actions/executive-dashboard.actions"
import { EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID } from "../reducers/executive-dashboard-closed-job-tab.data"
import { ExecutiveDashboardConfiguration, ExecutiveDashboardTab, PerJobConfiguration,
  isOneOfOpenJobsTab } from "../models/executive-dashboard"
import { FetchJobById } from "../actions/wall.actions"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { JobFilters } from "../reducers/layout.reducer"
import { JobStatusUpdate } from "../models/job-status-update"
import { Observable, of } from "rxjs"
import { Router } from "@angular/router"
import { SegmentService } from "../../core/services/segment.service"
import { ToastrService } from "ngx-toastr"
import { apiHost, getHttpPostOptions, httpGetOptions } from "../../core/http-options"
import { catchError, concatMap, first, map, mergeMap, retry, switchMap, tap } from "rxjs/operators"
import { getExecutiveDashboardTabById } from "../reducers"
import { selectImmediatelySync } from "../../shared/utils/store.utils"

@Injectable()
export class ExecutiveDashboardEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private toastr: ToastrService,
    private router: Router,
    private actionCable: ActionCableService,
    private segmentService: SegmentService,
    private store: Store,
  ) { }

  private executiveDashboardFiltersBody(filters: Partial<JobFilters>) {
    return {
      filter: {
        department_external_ids: (filters.department_ids || []).map(v => +v),
        office_external_ids: (filters.office_ids || []).map(v => +v),
        job_external_ids: (filters.job_ids || []).map(v => +v),
        external_user_external_ids: (filters.external_user_ids || []).map(v => +v),
        custom_fields: (filters.custom_fields || []).map(v => v),
      }
    }
  }

  private getExecutiveDashboardChartRequest(action: FetchExecutiveDashboardCharts) {
    if (window.twng_demo) {
      return this.http.get<ExecutiveDashChartPayload>(
        `${apiHost}/twng/executive_dashboard/charts.json`,
        httpGetOptions,
      )
    }
    return this.actionCable.sessionString$.pipe(
      first(sessionString => !!sessionString),
      switchMap(sessionString => this.http.post<ExecutiveDashChartPayload>(
        `${apiHost}/twng/executive_dashboard/charts.json?user_session_string=${sessionString}`,
        this.executiveDashboardFiltersBody(action.payload),
        getHttpPostOptions()
      ))
    )
  }

  fetchExecutiveDashboardCharts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<FetchExecutiveDashboardCharts>(ExecutiveDashboardActionsTypes.FetchExecutiveDashboardCharts),
    concatMap((action: FetchExecutiveDashboardCharts) => {
      const req = this.getExecutiveDashboardChartRequest(action)
      return req.pipe(
        retry(1),
        map(response => new FetchExecutiveDashboardChartsSuccess(response)),
        catchError((e) => {
          console.error(e)
          this.toastr.error('Error occured fetching the dashboard charts')
          return of(new FetchExecutiveDashboardChartsFailed())
        })
      )
    })
  ))

  updateExecutiveDashboardConfig$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateExecutiveDashboardConfig>(ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardConfig),
    concatMap((action: UpdateExecutiveDashboardConfig) =>
      this.http.put<ExecutiveDashboardConfiguration>(
        `${apiHost}/twng/executive_dashboard/config.json`,
        { params: action.payload },
        getHttpPostOptions()
      ).pipe(
        retry(1),
        map(response => new UpdateExecutiveDashboardConfigSuccess(response)),
        catchError(() => of(new UpdateExecutiveDashboardConfigFailure()))
      )
    )
  ))

  updateExecutiveDashboardTab$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateExecutiveDashboardTab>(ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTab),
    concatMap((action: UpdateExecutiveDashboardTab) => {
      if (action.payload.id === EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID || window.twng_demo) {
        return of(new UpdateExecutiveDashboardTabSuccess(action.payload))
      } else {
        return this.http.put<ExecutiveDashboardTab>(
          `${apiHost}/twng/executive_dashboard/executive_dashboard_tabs/${action.payload.id}.json`,
          { tab: action.payload },
          getHttpPostOptions()
        ).pipe(
          retry(1),
          map(response => new UpdateExecutiveDashboardTabSuccess(response)),
          catchError(() => of(new UpdateExecutiveDashboardTabFailure()))
        )
      }
    })
  ))

  updateExecutiveDashboardTabHideSection$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateExecutiveDashboardTabHideSection>
    (ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabHideSection),
    concatMap((action: UpdateExecutiveDashboardTabHideSection) => {
      const url = `${apiHost}/twng/executive_dashboard/executive_dashboard_tabs/${action.payload.tabId}/hidden_section.json`
      return this.http.put<ExecutiveDashboardTab>(url,
        { tab: { hide_section: action.payload.hide_section } },
        getHttpPostOptions()
      ).pipe(
        retry(1),
        map((response) => new UpdateExecutiveDashboardTabHideSectionSuccess(response)),
        catchError(() => {
          this.toastr.error(`Error occured ${action.payload.hide_section.hidden ? 'hiding' : 'showing' } the section`)
          return of(new UpdateExecutiveDashboardTabHideSectionFailure())
        })
      )
    })
  ))

  updatePerJobConfig$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdatePerJobConfig>(ExecutiveDashboardActionsTypes.UpdatePerJobConfig),
    concatMap((action: UpdatePerJobConfig) =>
      this.http.put<{ [jobId: string]: PerJobConfiguration }>(
        `${apiHost}/twng/executive_dashboard/per_job_config`,
        { per_job_params: action.payload },
        getHttpPostOptions()
      ).pipe(
        retry(1),
        map(response => new UpdatePerJobConfigSuccess(response)),
        catchError(() => of(new UpdatePerJobConfigFailure()))
      )
    )
  ))

  deleteExecDashTab$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<DeleteExecutiveDashboardTab>(ExecutiveDashboardActionsTypes.DeleteExecutiveDashboardTab),
    map(action => action.payload),
    mergeMap((tab) => {
      if (window.twng_demo) {
        return of(new DeleteExecutiveDashboardTabSuccess(tab))
      }

      this.router.navigate(['/executive-tools/executive-dashboard'])

      return this.http
        .delete(apiHost + `/twng/executive_dashboard/executive_dashboard_tabs/${tab.id}`, getHttpPostOptions())
        .pipe(
          map(() => {
            this.toastr.success(`Removed ${tab.name}`)
            return new DeleteExecutiveDashboardTabSuccess(tab)
          }),
          catchError(() => {
            this.toastr.error(`Failed to remove ${tab.name}`)
            return of(new DeleteExecutiveDashboardTabFailure(tab))
          }),
        )
    }),
  ))

  createExecutiveDashboardTab$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<CreateExecutiveDashboardTab>(ExecutiveDashboardActionsTypes.CreateExecutiveDashboardTab),
    concatMap((action: CreateExecutiveDashboardTab) => this.http.post<ExecutiveDashboardTab>(
      `${apiHost}/twng/executive_dashboard/executive_dashboard_tabs`,
      { tab: action.payload },
      getHttpPostOptions()
    ).pipe(
      retry(1),
      map(response => new CreateExecutiveDashboardTabSuccess(response)),
      catchError(() => of(new CreateExecutiveDashboardTabFailure()))
    )
    )
  ))

  FetchSharedExecutiveDashboardTab$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<FetchSharedExecutiveDashboardTab>(ExecutiveDashboardActionsTypes.FetchSharedExecutiveDashboardTab),
    concatMap((action: FetchSharedExecutiveDashboardTab) => this.http.get<ExecutiveDashboardTab>(
      `${apiHost}/twng/executive_dashboard/executive_dashboard_tabs/${action.payload.id}/${action.payload.token}`,
      httpGetOptions
    ).pipe(
      retry(1),
      map(response => new FetchSharedExecutiveDashboardTabSuccess(response)),
      catchError(() => of(new FetchSharedExecutiveDashboardTabFailure()))
    )
    )
  ))

  reorderExecutiveDashboardTabs$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ReorderExecutiveDashboardTabs>(ExecutiveDashboardActionsTypes.ReorderExecutiveDashboardTabs),
    concatMap(action => this.http.put<ExecutiveDashboardTab[]>(
      `${apiHost}/twng/executive_dashboard/executive_dashboard_tabs/order`,
      { params: { order: action.payload } },
      getHttpPostOptions()
    ).pipe(
      retry(1),
      map(response => new ReorderExecutiveDashboardTabsSuccess(response)),
      catchError(() => of(new ReorderExecutiveDashboardTabsFailure()))
    ))
  ))

  sendJobStatusUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<SendJobStatusUpdate>(ExecutiveDashboardActionsTypes.SendJobStatusUpdate),
    map(action => action.payload),
    mergeMap(({ jobStatusUpdate }) =>
      this.http.post<{ job_status_update: JobStatusUpdate }>(`${apiHost}/twng/jobs/${jobStatusUpdate.job_id}/status.json`,
        jobStatusUpdate, getHttpPostOptions())
        .pipe(
          map(response =>
            new SendJobStatusUpdateSuccess(response)
          ),
          tap(() => this.segmentService.track('Update Job Status/Note', {
            color: jobStatusUpdate.status,
            added_note: !!jobStatusUpdate.note
          })),
          catchError(() => of(new SendJobStatusUpdateFailure())),
        )
    )
  ))

  fetchExecutiveDashboard$ = createEffect(() => this.actions$.pipe(
    ofType<FetchExecutiveDashboard>(ExecutiveDashboardActionsTypes.FetchExecutiveDashboard),
    mergeMap(() =>
      this.http.get<ExecutiveDashboardPayload>(apiHost + '/twng/executive_dashboard.json', httpGetOptions)
        .pipe(
          map(response =>
            new FetchExecutiveDashboardSuccess(response)
          ),
          catchError(() => of(new FetchExecutiveDashboardFailure())),
        )
    )
  ))

  fetchExecutiveDashboardClosedJobs$ = createEffect(() =>  this.actions$.pipe(
    ofType<FetchExecutiveDashboardClosedJobs>(ExecutiveDashboardActionsTypes.FetchExecutiveDashboardClosedJobs),
    mergeMap(() =>
      this.http.get<ClosedJobsInfo>(apiHost + '/twng/executive_dashboard/closed_jobs.json', httpGetOptions)
        .pipe(
          map(response =>
            new FetchExecutiveDashboardClosedJobsSuccess(response)
          ),
          catchError(() => of(new FetchExecutiveDashboardClosedJobsFailure()))
        )
    )
  ))

  fetchJobStatusUpdates$ = createEffect(() => this.actions$.pipe(
    ofType<FetchJobStatuses>(ExecutiveDashboardActionsTypes.FetchJobStatuses),
    switchMap(() =>
      this.http.get<FetchJobStatusesSuccessPayload>(apiHost + '/twng/executive_dashboard/job_status_updates.json',
        httpGetOptions)
        .pipe(
          map(response => new FetchJobStatusesSuccess(response)),
          catchError(() => of(new FetchJobStatusesFailure()))
        )
    )
  ))
  fetchJobIdsForExecutiveDashboardTab$ = createEffect(() => this.actions$.pipe(
    ofType<FetchJobIdsForExecutiveDashboardTab>(ExecutiveDashboardActionsTypes.FetchJobIdsForExecutiveDashboardTab),
    switchMap(request => {
      if (isOneOfOpenJobsTab(request.payload.tabId)) {
        return this.fetchJobIdsForTab(request)
      } else {
        return this.fetchJobIdsForClosedTab(request)
      }
    })
  ))

  private fetchJobIdsForTab(request: FetchJobIdsForExecutiveDashboardTab):
  Observable<FetchJobById | FetchJobIdsForExecutiveDashboardTabSuccess> {
    let suffix = `/${request.payload.tabId}/jobs/${request.payload.orderBy.toLowerCase().replace(" ", "_")}/${request.payload.direction}`
    if (request.payload.sharableToken) {
      suffix += '/' + request.payload.sharableToken
    }
    suffix += ".json"
    return this.http.get<FetchJobIdsForExecutiveDashboardTabResponse>(
      apiHost + `/twng/executive_dashboard/executive_dashboard_tabs` + suffix,
      httpGetOptions
    ).pipe(
      mergeMap(response => of<[FetchJobIdsForExecutiveDashboardTabSuccess, ...FetchJobById[]]>(
        new FetchJobIdsForExecutiveDashboardTabSuccess(response),
        ...response.job_ids.map(id => new FetchJobById(id)),
      ))
    )
  }

  private fetchJobIdsForClosedTab(request: FetchJobIdsForExecutiveDashboardTab):
  Observable<FetchJobById | FetchJobIdsForExecutiveDashboardTabSuccess>  {
    const tab = selectImmediatelySync(this.store, getExecutiveDashboardTabById(request.payload.tabId))
    const suffix = `/closed_jobs/jobs/${request.payload.orderBy.toLowerCase().replace(" ", "_")}/${request.payload.direction}.json`
    const finalUrl = apiHost + `/twng/executive_dashboard/executive_dashboard_tabs` + suffix
    const apiCall = window.twng_demo ?
      this.http.get<FetchJobIdsForExecutiveDashboardTabResponse>(finalUrl, httpGetOptions) :
      this.http.post<FetchJobIdsForExecutiveDashboardTabResponse>(finalUrl,
        this.executiveDashboardFiltersBody(tab), getHttpPostOptions())
    return apiCall.pipe(
      mergeMap(response => of<[FetchJobIdsForExecutiveDashboardTabSuccess, ...FetchJobById[]]>(
        new FetchJobIdsForExecutiveDashboardTabSuccess(response),
        ...response.job_ids.map(id => new FetchJobById(id)),
      ))
    )
  }
}
