<div appDroppable class="categories-list" (dropping)="updateCategory($event)">
  <div class="categories-list__container tw-py-6">
    <ng-content></ng-content>
  </div>
  <button type="button" class="categories-list__btn-add btn btn-primary" (click)="emitCreateCategory()">
    <i class="fas " [ngClass]="{
    'fa-plus': !isCreatingCategory,
    'fa-sync fa-spin': isCreatingCategory
    }"></i>
  </button>
</div>