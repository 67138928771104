import { EffectsModule } from '@ngrx/effects'
import { NgModule } from '@angular/core'
import { StoreModule } from '@ngrx/store'

import { CardDetailsEffects } from '../wall/effects/card-details.effects'


import { ComponentsModule } from './components'
import { NoteEffects } from '../wall/effects/note.effects'
import { RejectCandidateEffects } from './effects/reject-candidate.effects'
import { SharedModule } from '../shared/shared.module'
import { ToggleTagEffects } from './effects/toggle-tag.effects'
import { TransferCandidateEffects } from './effects/transfer-candidate.effects'
import { UpdateStarredEffects } from './effects/update-starred.effects'
import { reducers } from './reducers'

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature('cardDetails', reducers),
    EffectsModule.forFeature(
      [
        TransferCandidateEffects,
        RejectCandidateEffects,
        UpdateStarredEffects,
        ToggleTagEffects,
        NoteEffects,
        CardDetailsEffects,
      ]
    ),
  ],
  exports: [ComponentsModule],
})
export class CardDetailsModule { }
