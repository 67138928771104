import { BehaviorSubject } from 'rxjs'
import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[dimensionMonitor]',
  exportAs: 'dimensionMonitor',
})
export class DimensionMonitorDirective implements OnDestroy, OnInit {
  // interval amount in ms
  @Input()
    dimensionMonitor: number | string

  @Output()
    widthChanged = new EventEmitter<number>()

  @Output()
    heightChanged = new EventEmitter<number>()

  private intervalId: NodeJS.Timeout
  currentWidth$ = new BehaviorSubject(0)
  currentHeight$ = new BehaviorSubject(0)

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private zone: NgZone,
  ) {
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.intervalId = setInterval(() => {
        if (this.element.nativeElement.clientWidth !== this.currentWidth$.value) {
          this.zone.run(() => {
            this.currentWidth$.next(this.element.nativeElement.clientWidth)
            this.widthChanged.emit(this.currentWidth$.value)
          })
        }
        if (this.element.nativeElement.clientHeight !== this.currentHeight$.value) {
          this.zone.run(() => {
            this.currentHeight$.next(this.element.nativeElement.clientHeight)
            this.heightChanged.emit(this.currentHeight$.value)
          })
        }
      }, this.getTimeoutAmount())
    })
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId)
  }

  private getTimeoutAmount(): number {
    if (typeof(this.dimensionMonitor) === "string") {
      const ret = parseInt(this.dimensionMonitor, 10)
      return isNaN(ret) ? 100 : ret
    } else {
      return this.dimensionMonitor
    }
  }
}
