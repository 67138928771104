<div class="input-filter multiselect__wrapper">
  <span class="multiselect__tag" *ngFor="let option of selectedOptions">
    <span class="tag__text">{{option.name}}</span>
    <span class="tag__close-icon-wrapper">
      <i class="tag__close-icon fas fa-times" (click)="removeSelectedOption(option)"></i>
    </span>
  </span>
  <input #inputFilter type="text" autocomplete="off" placeholder="{{placeholder}}" [ngbTypeahead]="search"
    [resultFormatter]="optionsListFormatter" (selectItem)="selectItem($event)" [resultTemplate]="rt"
    [inputFormatter]="inputFormatter" (focus)="focus$.next(inputFilter.value)"
    (click)="click$.next(inputFilter.value)" #instance="ngbTypeahead" [disabled]="disabled" />
</div>

<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.name" [term]="t" [style.padding-left]="(r.depth || 0) * 10 + 'px'"></ngb-highlight>
</ng-template>