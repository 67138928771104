import { Component, EventEmitter, Input, Output } from '@angular/core'

export const classNameSelected = 'selected-border'

@Component({
  selector: 'twng-single-chart-filter-template',
  templateUrl: './single-chart-filter-template.component.html',
  styleUrls: ['./single-chart-filter-template.component.scss']
})
export class SingleChartFilterTemplateComponent {
  @Input()
    countBadgeValue: string

  @Input()
    tooltipText: string[]

  @Output()
    applyButtonClicked = new EventEmitter<void>()

  @Output()
    filterClicked = new EventEmitter<void>()

  @Input()
    iconClass: string | string[]

  @Input()
    showAsIcon: boolean

  @Input()
    chartFiltersReadOnly: boolean

  demoMode = window.twng_demo

  constructor() { }

  onFilterClicked(event) {
    const path = event.path || (event.composedPath && event.composedPath());
    const el = document.getElementsByClassName(classNameSelected)
    el[0]?.classList.remove(classNameSelected)
    const ev = path.find(p => p.classList.contains('single-chart-filter-container'))
    ev?.classList.add(classNameSelected)

    this.filterClicked.emit()
  }

}
