<div class="ToggleStagesPhases d-flex justify-content-center fill-parent align-items-center" *ngIf="phasesEnabled$ | async" style="margin-top: 1rem; margin-bottom: 0.5rem">
  <div class="btn-group btn-group-toggle"  role="group">
    <input type="radio" [value]="false" [checked]="!radioChecked" name="togglePhases" class="btn-check tw-hidden" 
      id="stagesRadio" autocomplete="off" (change)="setNewValue(false)" [disabled]="demo"> 
    <label class="btn btn-primary card-menu-button" for="stagesRadio">Stages</label>

    <input type="radio" [value]="true" [checked]="radioChecked" name="togglePhases" class="btn-check tw-hidden"
      id="phasesRadio" autocomplete="off" (change)="setNewValue(true)" [disabled]="demo"> 
    <label class="btn btn-primary card-menu-button" for="phasesRadio">Phases</label>
  </div>
  <i class="fas fa-sync fa-spin" *ngIf="loading" style="margin-left: 10px;"></i>
  <div class="text-danger" style="margin-left: 10px; max-width: 25em;" *ngIf="!(phasesConfigured$ | async)">
    Your interview phases might not be configured.
    <br>
    Please configure them in "Interview Phases" next to account settings.
  </div>
</div>
