/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http'
import { ProspectCandidate } from '../models/prospects-candidate.model';
import { ProspectJobApplication } from '../models/prospects-job-application.model';
import { atsId } from '../../wall/models/types';

export enum ProspectsActionsTypes {
  LoadProspects = '[Prospects] Load Prospects',
  LoadProspectsSuccess = '[Prospects] Load Prospects Success',
  LoadProspectsFailure = '[Prospects] Load Prospects Failure',

  OpenCandidateCardModal = '[Prospects] OpenCandidateCardModal'
}

export class LoadProspects implements Action {
  readonly type = ProspectsActionsTypes.LoadProspects
}

export interface Prospects {
  job_applications: ProspectJobApplication[],
  candidates: ProspectCandidate[]
}

export class LoadProspectsSuccess implements Action {
  readonly type = ProspectsActionsTypes.LoadProspectsSuccess

  constructor(public payload: Prospects) {
  }
}

export class LoadProspectsFailure implements Action {
  readonly type = ProspectsActionsTypes.LoadProspectsFailure

  constructor(public payload: HttpErrorResponse) {
  }
}

export class OpenCandidateCardModal implements Action {
  readonly type = ProspectsActionsTypes.OpenCandidateCardModal

  constructor(public payload: { jobApplicationId: atsId }) {
  }
}

export type ProspectsActions =
  LoadProspects
  | LoadProspectsSuccess
  | LoadProspectsFailure
  | OpenCandidateCardModal

