import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import {
  AddNote, AddNoteFailure, AddNoteResponse, AddNoteSuccess, FetchNotes, FetchNotesFailure,
  FetchNotesSuccess, NoteActionTypes, NoteActions, NotesPayload
} from '../actions/notes.actions'
import { DemoService } from '../services/demo.service'
import { apiHost, getHttpPostOptions, httpGetOptions } from '../../core/http-options'

@Injectable()
export class NoteEffects {
  // Listen for FetchNotes
  fetchNotes$: Observable<NoteActions> = createEffect(() => this.actions$.pipe(
    ofType<FetchNotes>(NoteActionTypes.FetchNotes),
    mergeMap(action => {
      const { candidate } = action.payload

      if (window.twng_demo) {
        return of(
          new FetchNotesSuccess({
            notes: [],
            candidate,
            activities: [],
            emails: [],
          }),
        )
      }

      return this.http
        .get<NotesPayload>(
        `${apiHost}/twng/candidates/${candidate.id}/notes.json`,
        httpGetOptions,
      )
        .pipe(
          map(
            notesPayload =>
              new FetchNotesSuccess({ ...notesPayload, candidate }),
          ),
          catchError(() => of(new FetchNotesFailure())),
        )
    }),
  ))

  addNote$: Observable<NoteActions> = createEffect(() => this.actions$.pipe(
    ofType<AddNote>(NoteActionTypes.AddNote),
    mergeMap(action => {
      if (window.twng_demo) {
        return of(
          new AddNoteSuccess(this.demoService.newNoteFromPayload(action.payload)),
        )
      }

      return this.http
        .post<AddNoteResponse>(
        `${apiHost}/twng/candidates/${
          action.payload.candidate_id
        }/notes.json`,
        {
          note: {
            public: action.payload.isPublic,
            body: action.payload.body,
          },
          mentioned_user_ids: action.payload.mentionedUsers.map(
            user => user.id,
          ),
        },
        getHttpPostOptions(),
      )
        .pipe(
          map(response => new AddNoteSuccess(response.note)),
          catchError(() => of(new AddNoteFailure())),
        )
    }),
  ))

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private demoService: DemoService
  ) { }
}
