import { Observable } from 'rxjs'

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { CandidatesInStageBackendFilters,
  FetchCandidatesInStage, LoadInitialData
} from '../actions/candidates-in-stage.actions'
import { CandidatesInStageState } from '../reducers/canidates-in-stage.reducer'
import { FullCandidateInStage } from '../models/candidates-in-stage'
import {
  selectCandidatesInStageTable, selectFetchingCandidates, selectLoadingInitialData
} from '../selectors/candidates-in-stage.selectors'

@Injectable({
  providedIn: 'root'
})
export class CandidatesInStageService {

  constructor(private store: Store<CandidatesInStageState>) {
  }

  loadInitialData() {
    this.store.dispatch(new LoadInitialData())
  }

  isLoadingInitialData(): Observable<boolean> {
    return this.store.select(selectLoadingInitialData)
  }

  isFetchingCandidates(): Observable<boolean> {
    return this.store.select(selectFetchingCandidates)
  }

  getCandidates(): Observable<FullCandidateInStage[]> {
    return this.store.select(selectCandidatesInStageTable)
  }

  fetchCandidatesInStage(filters: CandidatesInStageBackendFilters) {
    this.store.dispatch(new FetchCandidatesInStage({ filters }))
  }

}

