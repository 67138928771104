import { camelCase as _camelCase, cloneDeep } from 'lodash-es'

import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'

import { CacheService } from '../../wall/services/cache.service'
import { ChartCommonComponent } from './chart-common.component'
import { ClipboardService } from '../../core/services/clipboard.service'
import { DashboardChart } from '../models/dashboard-chart'
import { LegendPosition } from '@swimlane/ngx-charts'
import { Store } from '@ngrx/store'

export const LINE_CHART_TYPES = [
  'line',
  'area',
  'area-stacked',
  'area-normalized',
]

@Component({
  selector: 'twng-dash-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: [
    './dashboard.shared.component.scss',
    './dashboard.component.scss',
    './dashboard.gridster.component.scss',
    './line-chart.component.scss'
  ],
})
export class DashLineChartComponent extends ChartCommonComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
    editingGridster: boolean

  @Input()
    label: string

  @Input()
    customColors

  @Input()
    filtersInCopiedImg: boolean

  @Input()
    legendInCopiedImg: boolean

  @Input()
    dataSource: string

  @Input()
    dataSourceParameters: { [key: string]: string }

  @Input()
    chart: DashboardChart

  @Input()
    chartType = 'line' // Posible Chart Types for this component: 'line' | 'area' | 'area-stacked' | 'area-normalized'

  @Input()
    gridItemType: 'css' | 'gridster' = 'css'

  @Input()
    chartFiltersReadOnly: boolean

  view: [number, number]

  copyingToClipboard = false
  legendPosition = LegendPosition.Right

  constructor(
    private clipboardService: ClipboardService,
    private cd: ChangeDetectorRef,
    protected cacheService: CacheService,
    store: Store,
  ) {
    super(store, cacheService)
  }

  copyChartToClipboard(chartEl: Element, legendInCopiedImg: boolean, filtersInCopiedImg: boolean): void {
    this.copyingToClipboard = true
    this.clipboardService.copyChartToClipboard(chartEl, legendInCopiedImg, filtersInCopiedImg).finally(() => {
      this.copyingToClipboard = false
      this.cd.detectChanges()
    })
  }

  camelCase(val: string): string {
    return _camelCase(val)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status && this.data) {
      // In case of time-series data with multiple series (such as % race over
      // time) there might be a case where a series has a few "time gaps"
      // (missing X values). If the first series is a series with time gaps,
      // library will just push them from left to right. When new series come
      // with different X values (for example, it contains X values that
      // previous series was missing), it will be pushed last in X axis. We
      // also usually have a series with no time-gaps. The solution is to put
      // series with no time-gaps first, and then it will properly fill whole
      // x axis in order.
      // Example:
      // Series 1: ['Jan', 'Mar']
      // Series 2: ['Feb', 'Apr']
      // Series 3: ['Jan', 'Feb', 'Mar', 'Apr']
      // If we add to graph in order 1,2,3, we will have following situation on
      // x axis:
      // ['Jan', 'Mar', 'Feb', 'Apr']
      // However, if we apply them in order 3,2,1, we will have the following
      // situation on x axis:
      // ['Jan', 'Feb', 'Mar', 'Apr']
      if (this.chartType === 'area-normalized' || this.chartType === 'area-stacked') {
        this.status = cloneDeep(this.status)
        this.status.data = this.status.data.sort((a, b) => b.series.length - a.series.length)
      }
    }
  }

  defined(val): boolean {
    return val !== null && val !== undefined
  }

  widgetBodyClasses() {
    return {
      body: true,
      'body--no-filters': !this.chart,
      'body--with-filters': this.chart
    }
  }
}
