/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'

import { ChartFilters } from '../models/chart-filters'
import { DashboardChart } from '../models/dashboard-chart'
import { DashboardTab } from '../models/dashboard-tab'

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum DashboardActionTypes {
  DashboardSetFilters = '[Dashboard] DashboardSetFilters',
  DashboardSetFiltersError = '[Dashboard] DashboardSetFiltersError',
  DashboardSendChartToCustom = '[Dashboard] DashboardSendChartToCustom',
  DashboardSendChartToCustomSuccess = '[Dashboard] DashboardSendChartToCustomSuccess',
  DashboardSendChartToCustomFailure = '[Dashboard] DashboardSendChartToCustomFailure',
  DashboardSaveSharedTab = '[Dashboard] DashboardSaveSharedTab',
  DashboardSaveSharedTabSuccess = '[Dashboard] DashboardSaveSharedTabSuccess',
  DashboardSaveSharedTabFailure = '[Dashboard] DashboardSaveSharedTabFailure',
  DashboardRemoveChart = '[Dashboard] DashboardRemoveChart',
  DashboardRemoveChartSuccess = '[Dashboard] DashboardRemoveChartSuccess',
  DashboardRemoveChartFailure = '[Dashboard] DashboardRemoveChartFailure',

  UpdateDashboardChart = '[Dashboard] UpdateDashboardChart',
  UpdateDashboardChartSuccess = '[Dashboard] UpdateDashboardChartSuccess',
  UpdateDashboardChartFailure = '[Dashboard] UpdateDashboardChartFailure',

  CreateDashboardTab = '[Dashboard] CreateDashboardTab',
  CreateDashboardTabSuccess = '[Dashboard] CreateDashboardTabSuccess',
  CreateDashboardTabFailure = '[Dashboard] CreateDashboardTabFailure',

  UpdateDashboardTab = '[Dashboard] UpdateDashboardTab',
  UpdateDashboardTabSuccess = '[Dashboard] UpdateDashboardTabSuccess',
  UpdateDashboardTabFailure = '[Dashboard] UpdateDashboardTabFailure',

  RemoveDashboardTab = '[Dashboard] RemoveDashboardTab',
  RemoveDashboardTabSuccess = '[Dashboard] RemoveDashboardTabSuccess',
  RemoveDashboardTabFailure = '[Dashboard] RemoveDashboardTabFailure',

  EnableEditDashboardChartName = '[Dashboard] EnableEditDashboardChartName',
  DisableEditDashboardChartName = '[Dashboard] DisableEditDashboardChartName',

  EnableEditCustomStatBox = '[Dashboard] EnableEditCustomStatBox',
  DisableEditCustomStatBox = '[Dashboard] DisableEditCustomStatBox',

  RefreshAnalytics = '[Dashboard] RefreshAnalytics',
}

export interface SetFiltersPayload {
  panel: string,
  filters: Partial<ChartFilters>,
}

export class DashboardSetFilters implements Action {
  readonly type = DashboardActionTypes.DashboardSetFilters
  constructor(public payload: SetFiltersPayload) { }
}

export class DashboardSetFiltersError implements Action {
  readonly type = DashboardActionTypes.DashboardSetFiltersError
  constructor(public payload: { panel: string }) { }
}

export interface DashboardSendChartToCustomPayload {
  dashboardTab: Partial<DashboardTab>
  chartType: string
  dataSource: string
  dataSourceParameters: { [key: string]: string }
  filters: ChartFilters
  chartName: string
  isTitle: boolean
  extra_params: { [key: string]: number[] | string[] }
}

// If payload.dashboardTab.id is null, then
// a new Custom Dashboard will be created.
export class DashboardSendChartToCustom implements Action {
  readonly type = DashboardActionTypes.DashboardSendChartToCustom
  constructor(public payload: DashboardSendChartToCustomPayload) { }
}

export class DashboardSendChartToCustomSuccess implements Action {
  readonly type = DashboardActionTypes.DashboardSendChartToCustomSuccess
  constructor(public payload: { dashboard_chart: DashboardChart, dashboard_tab?: DashboardTab }) { }
}

export class DashboardSendChartToCustomFailure implements Action {
  readonly type = DashboardActionTypes.DashboardSendChartToCustomFailure
  constructor() { }
}


export interface DashboardRemoveChartPayload {
  dashboard_chart: DashboardChart
}

export interface DashboardSaveSharedTabPayload {
  name: string
  sharableToken: string
  id: number
  viewEditable: boolean
}

export interface DashboardSaveSharedTabResponsePayload {
  dashboard_tab: DashboardTab
}

export class DashboardRemoveChart implements Action {
  readonly type = DashboardActionTypes.DashboardRemoveChart
  constructor(public payload: DashboardRemoveChartPayload) { }
}

export class DashboardRemoveChartSuccess implements Action {
  readonly type = DashboardActionTypes.DashboardRemoveChartSuccess
  constructor(public payload: DashboardRemoveChartPayload) { }
}

export class DashboardRemoveChartFailure implements Action {
  readonly type = DashboardActionTypes.DashboardRemoveChartFailure
  constructor(public payload: DashboardRemoveChartPayload) { }
}

export class DashboardSaveSharedTab implements Action {
  readonly type = DashboardActionTypes.DashboardSaveSharedTab
  constructor(public payload: DashboardSaveSharedTabPayload) { }
}

export class DashboardSaveSharedTabSuccess implements Action {
  readonly type = DashboardActionTypes.DashboardSaveSharedTabSuccess
  constructor(public payload: DashboardSaveSharedTabResponsePayload) { }
}

export class DashboardSaveSharedTabFailure implements Action {
  readonly type = DashboardActionTypes.DashboardSaveSharedTabFailure
  constructor() { }
}



export interface UpdateDashboardChartPayload {
  dashboard_chart: DashboardChart,
  filters?: Partial<ChartFilters>
}

export class UpdateDashboardChart implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardChart
  constructor(public payload: UpdateDashboardChartPayload) { }
}

export class UpdateDashboardChartSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardChartSuccess
  constructor(public payload: { dashboard_chart: DashboardChart }) { }
}

export class UpdateDashboardChartFailure implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardChartFailure
  constructor(public payload: { dashboard_chart: DashboardChart }) { }
}

export interface CreateDashboardTabPayload {
  name: string
  template?: string
}

export class CreateDashboardTab implements Action {
  readonly type = DashboardActionTypes.CreateDashboardTab
  constructor(public payload: CreateDashboardTabPayload) { }
}

export class CreateDashboardTabSuccess implements Action {
  readonly type = DashboardActionTypes.CreateDashboardTabSuccess
  constructor(public payload: DashboardTab) { }
}

export class CreateDashboardTabFailure implements Action {
  readonly type = DashboardActionTypes.CreateDashboardTabFailure
  constructor() { }
}

export interface UpdateDashboardTabPayload {
  actualTab: DashboardTab
  updatedTab: DashboardTab
}
export class UpdateDashboardTab implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardTab
  constructor(public payload: UpdateDashboardTabPayload) { }
}

export interface UpdateDashboardTabSuccessPayload {
  dashboard_tab: DashboardTab
}

export class UpdateDashboardTabSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardTabSuccess
  constructor(public payload: UpdateDashboardTabSuccessPayload) { }
}

export interface UpdateDashboardTabFailurePayload {
  oldTab: DashboardTab
}

export class UpdateDashboardTabFailure implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardTabFailure
  constructor(public payload: UpdateDashboardTabFailurePayload) { }
}


export interface RemoveDashboardTabPayload {
  tab: DashboardTab
}

export class RemoveDashboardTab implements Action {
  readonly type = DashboardActionTypes.RemoveDashboardTab
  constructor(public payload: RemoveDashboardTabPayload) { }
}

export class RemoveDashboardTabSuccess implements Action {
  readonly type = DashboardActionTypes.RemoveDashboardTabSuccess
  constructor() { }
}

export interface RemoveDashboardTabFailurePayload {
  oldTab: DashboardTab
}

export class RemoveDashboardTabFailure implements Action {
  readonly type = DashboardActionTypes.RemoveDashboardTabFailure
  constructor(public payload: RemoveDashboardTabFailurePayload) { }
}

export class EnableEditDashboardChartName implements Action {
  readonly type = DashboardActionTypes.EnableEditDashboardChartName
  constructor(public payload: { chartId: string }) { }
}

export class DisableEditDashboardChartName implements Action {
  readonly type = DashboardActionTypes.DisableEditDashboardChartName
}

export class EnableEditCustomStatBox implements Action {
  readonly type = DashboardActionTypes.EnableEditCustomStatBox
  constructor(public payload: { chartId: string }) { }
}

export class DisableEditCustomStatBox implements Action {
  readonly type = DashboardActionTypes.DisableEditCustomStatBox
}

export class RefreshAnalytics implements Action {
  readonly type = DashboardActionTypes.RefreshAnalytics
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type DashboardFilterActions =
  | DashboardSetFilters
  | DashboardSetFiltersError
  | DashboardSendChartToCustom
  | DashboardSendChartToCustomSuccess
  | DashboardSendChartToCustomFailure
  | DashboardRemoveChart
  | DashboardRemoveChartSuccess
  | DashboardRemoveChartFailure
  | DashboardSaveSharedTab
  | DashboardSaveSharedTabSuccess
  | DashboardSaveSharedTabFailure
  | UpdateDashboardChart
  | UpdateDashboardChartFailure
  | UpdateDashboardChartSuccess
  | CreateDashboardTab
  | CreateDashboardTabSuccess
  | CreateDashboardTabFailure
  | UpdateDashboardTab
  | UpdateDashboardTabSuccess
  | UpdateDashboardTabFailure
  | RemoveDashboardTab
  | RemoveDashboardTabSuccess
  | RemoveDashboardTabFailure
  | EnableEditDashboardChartName
  | DisableEditDashboardChartName
  | EnableEditCustomStatBox
  | DisableEditCustomStatBox
  | RefreshAnalytics

