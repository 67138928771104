import { ChartFilters } from "../../../models/chart-filters"
import { Component } from "@angular/core"
import { SearchTypeaheadDataManager, SingleChartFilterComponent } from "./single-chart-filter.component"
import { SegmentService } from "../../../../core/services/segment.service"
import { SourceWithChildren } from "../../../../wall/models/source"
import { Store } from "@ngrx/store"
import { first, map, tap } from "rxjs/operators"
import { selectSourcesWithChildren } from "../../../../wall/reducers"

const parentIdStart = '__parent__'

type TypeaheadSourceWithChildren =
  Omit<SourceWithChildren, 'id' | 'children'> & { id: string, children: TypeaheadSourceWithChildren[] }

function fillParentsForSources(sources: TypeaheadSourceWithChildren[]) {
  const parentList: TypeaheadSourceWithChildren[] = []
  sources.forEach(item => {
    let parent = parentList.find(i => i.name === item.parent_id && i.depth === 0)
    if (!parent) {
      // since source ids are numbers and its parents' ids are strings, we
      // cast it to any so that other features work properly, such search,
      // removing child entries from search when parent is selected, etc.
      parent = { name: item.parent_id, id: parentIdStart + item.parent_id, parent_id: null, children: [], depth: 0 }
      parentList.push(parent)
    }
    item.depth = 1
    parent.children.push(item)
    parentList.push(item)
  })
  return parentList
}

function sortSources(sources: TypeaheadSourceWithChildren[]) {
  sources.sort((a, b) => (a.parent_id || a.name).localeCompare(b.parent_id || b.name))
}

export class SourceSearchTypeaheadDataManager extends SearchTypeaheadDataManager<TypeaheadSourceWithChildren> {
  constructor(private store: Store) {
    super('Sources')
  }

  protected loadAllData(): Promise<TypeaheadSourceWithChildren[]> {
    return this.store.select(selectSourcesWithChildren)
      .pipe(
        first(),
        map(sources => fillParentsForSources(sources)),
        map(sources => sources.map(source => {
          const ret: TypeaheadSourceWithChildren = {
            ...source,
            id: source.id !== null ? source.id.toString() : null,
            children: source.children?.map(child => ({
              ...child,
              id: child.id.toString()
            })),
          }
          return ret
        })),
        tap(sources => sortSources(sources)),
      )
      .toPromise()
  }

  protected getIdsFromFilters(): string[] {
    return this.filters.source_ids.map(id => id.toString())
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_sources
  }

  protected getExpandedIds(id: string): string[] {
    const value = this.getValueFromId(id)
    if (!value) {
      return []
    }
    if (value.children) {
      return value.children.map(child => child.id)
    }
    return super.getExpandedIds(id)
  }

  unselectId(id) {
    if (id.includes(parentIdStart)) {
      const parentName = id.split(parentIdStart)[1]
      this.removeSourceType(parentName)
    }

    super.unselectId(id)
  }

  selectValueFromTypeahead(value) {
    super.selectValueFromTypeahead(value)

    const isParent = !value.parent_id && this.tempSelectedIdsCompact.includes(value.id)
    const isLastChild = value.parent_id && this.tempSelectedIdsCompact.includes(`${parentIdStart}${value.parent_id}`)

    if (isParent && this.sourceTypes.indexOf(value.name) === -1) {
      this.setSourceType(value.name)
    }

    if (isLastChild && this.sourceTypes.indexOf(value.parent_id) === -1) {
      this.setSourceType(value.parent_id)
    }
  }

  protected getCompactedIds(ids: string[]): string[] {
    const usedChildren: { [parentId: string]: string[] } = {}
    ids.forEach(id => {
      const source = this.getValueFromId(id)

      if (!source) {
        throw Error("Error finding source.")
      }
      if (source.depth !== 1) {
        throw Error("Tried to compact a parent source. This must not happen!")
      }
      usedChildren[source.parent_id] = usedChildren[source.parent_id] || []
      usedChildren[source.parent_id].push(id)
    })
    const ret: string[] = []
    Object.entries(usedChildren).forEach(([parentId, childrenIds]) => {
      const source = this.getValueFromId(parentIdStart + parentId)
      if (source.children.length === childrenIds.length) {
        ret.push(source.id)
      } else {
        ret.push(...childrenIds)
      }
    })
    return ret
  }

  protected getSearchableValues(): TypeaheadSourceWithChildren[] {
    return super.getSearchableValues()
      .filter(v => !this.tempSelectedIdsCompact.includes(v.id))
  }
}

@Component({
  selector: 'twng-source-single-chart-filter',
  templateUrl: './single-chart-filter.component.html',
  styleUrls: ['./single-chart-filter.component.scss',
    './single-chart-filter-template/single-chart-filter-template.component.scss'
  ],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  providers: [{ provide: SingleChartFilterComponent, useExisting: SourceSingleChartFilterComponent }]
})
export class SourceSingleChartFilterComponent extends SingleChartFilterComponent {
  id = 'SourceSingleChartFilterComponent';

  constructor(segmentService: SegmentService, store: Store) {
    super(segmentService, store, 'Sources', 'Sources', 'fa-search')
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getDataManagers(): SearchTypeaheadDataManager<any>[] {
    return [new SourceSearchTypeaheadDataManager(this.store)]
  }
  getChangedValueForStoring(): Partial<ChartFilters> {
    return {
      source_ids: this.managers[0].tempSelectedIds.map(id => parseInt(id, 10)),
      should_exclude_sources: this.managers[0].shouldExcludeFilter,
      source_types: this.managers[0].sourceTypes
    }
  }

}
