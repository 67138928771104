import { Component, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { FilterOption } from '../filters/generic-filter/filter-option.interface'

type TypeaheadIdType = string | number

@Component({
  selector: 'twng-typeahead-input',
  templateUrl: './typeahead-input.component.html',
  styleUrls: ['./typeahead-input.component.css']
})
export class TypeaheadInputComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input()
    disabled = false

  @Input()
    placeholder = 'Custom fields'

  @Input()
    filterItems: FilterOption[] = []

  shownFilterItems: FilterOption[] = []
  selectedFilterItems: FilterOption[] = []
  private value: TypeaheadIdType[]

  constructor(
    @Self() @Optional() private ngControl: NgControl,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterItems']) {
      this.refreshAllFilterOptions()
    }
  }

  private refreshAllFilterOptions() {
    this.setNewValue(this.value)
  }

  private get filteredValue(): TypeaheadIdType[] {
    return (this.selectedFilterItems || []).map(item => item.id)
  }

  private setNewValue(newValue: TypeaheadIdType[]) {
    this.value = newValue
    this.selectedFilterItems = this.filterItems.filter(
      item => newValue.includes(item.id)
    )
    this.shownFilterItems = this.filterItems.filter(
      item => !this.selectedFilterItems.includes(item)
    )
    this.onChange(this.filteredValue)
  }

  writeValue(obj: TypeaheadIdType[]): void {
    this.setNewValue(obj)
  }

  registerOnChange(fn: (_newValue: TypeaheadIdType[]) => void): void {
    this.onChange = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }
  private onChange(_newValue: TypeaheadIdType[]) { }
  private onTouched() { }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  selectFilterOption(option: FilterOption) {
    this.setNewValue([...this.value, option.id])
  }

  removeFilterOption(option: FilterOption) {
    this.setNewValue(this.value.filter(
      item => item !== option.id
    ))
  }
}
