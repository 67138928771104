import { Component, Input, OnInit, SecurityContext } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { HttpClient } from '@angular/common/http'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { SiteNotification } from '../../../wall/models/site-notification'
import { Store } from '@ngrx/store'
import { UpdateSharedUser } from '../../../core/actions/loader.actions'
import { User } from '../../../wall/models/user'
import { apiHost, getHttpPostOptions } from '../../../core/http-options'
import { cloneDeep } from 'lodash-es'
import { firstValueFrom } from 'rxjs'

@Component({
  selector: 'twng-site-notification',
  templateUrl: './site-notification.component.html',
  styleUrls: ['./site-notification.component.scss'],
})
export class SiteNotificationComponent implements OnInit {

  @Input() siteNotification: SiteNotification
  @Input() user: User

  body = ''

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private activeModal: NgbActiveModal,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.body = this.sanitizer.sanitize(SecurityContext.HTML, this.siteNotification.body)
  }

  deleteNotification() {
    const url = `${apiHost}/twng/site_notifications/${this.siteNotification.id}`
    firstValueFrom(this.http.delete(url, getHttpPostOptions()))
    const userClone = cloneDeep(this.user)
    const index = userClone.site_notifications.findIndex(notification => notification.id === this.siteNotification.id)
    if (index > -1) {
      userClone.site_notifications.splice(index, 1)
    }

    this.store.dispatch(new UpdateSharedUser(userClone))
    this.activeModal.dismiss()
  }
}
