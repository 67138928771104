

import { Chart, DashboardChart } from '../models/dashboard-chart'
import { ChartFilters } from '../models/chart-filters'
import { DashboardActionTypes, DashboardFilterActions } from '../actions/filters.actions'
import { DashboardTypes } from '../components/dashboard-types'
import { ExecutiveDashboardActions,
  ExecutiveDashboardActionsTypes
} from '../../wall/actions/executive-dashboard.actions'
import { JobActions } from '../../wall/actions/jobs.actions'
import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import { OfferedCandidate } from '../models/offered-candidate'
import { atsId } from '../../wall/models/types'
import { cloneDeep, isEqual, set } from 'lodash-es'
import { objKeysSafe } from '../../shared/utils/general-utils'

export interface NgxChartsColor {
  name: string
  value: string
}

export interface NgxChartsRecord {
  // scalar types may not include name in the record, as it's already included in the chart
  name?: string
  value: number
  label?: string
  tooltip?: string
  modal_title_override?: string
  // offer widgets may include offer IDs to make them clickable
  offer_ids?: atsId[]
  job_application_ids?: atsId[]
  job_ids?: atsId[]
  outreach_ids?: string[]
  scorecard_ids?: atsId[]
  job_opening_ids?: atsId[]
  interview_ids?: atsId[]
  department_id_path?: atsId[]
  office_id_path?: atsId[]
  demographic_answer_ids?: atsId[]
  series?: NgxChartsRecord[]
}

export interface NgxChartsSeries {
  name: string
  series: NgxChartsRecord[]
}

export interface WidgetLibraryTab {
  id: string
  name: string
  beta: boolean
}

export interface WidgetLibraryTabExtraLink {
  text: string
  link: string
}

export interface WidgetLibraryTabData {
  extra_link: WidgetLibraryTabExtraLink
  sections: WidgetLibraryTabSection[]
  is_qoh?: boolean
}

export interface WidgetLibraryTabSection {
  charts: DashboardChart[]
}

export interface AnalyticsState {
  loaded: boolean

  widget_library_tabs: WidgetLibraryTab[],
  widget_library_tabs_data: { [key: string]: WidgetLibraryTabData },
  // widgets that were received through websocket before request was finished
  widget_library_tabs_pending_data: Chart[],

  my_activity: NgxChartsRecord[]
  recruiter_activity: unknown
  office_activity: unknown
  overall_activity: unknown
  department_activity: unknown

  company_dashboard_filters: ChartFilters
  my_dashboard_filters: ChartFilters
  custom_dashboard_filters: ChartFilters

  applications_by_source: NgxChartsRecord[]
  my_applications_by_source: NgxChartsRecord[]
  hires_by_source: NgxChartsRecord[]
  my_hires_by_source: NgxChartsRecord[]
  hires_by_department: NgxChartsRecord[]
  my_hires_by_department: NgxChartsRecord[]
  offers_total: NgxChartsRecord
  hires_total: NgxChartsRecord
  starts_total: NgxChartsRecord
  offers_rejected: NgxChartsRecord
  offer_acceptance_rate: NgxChartsRecord
  my_hires_total: NgxChartsRecord
  my_offers_rejected: NgxChartsRecord
  time_to_hire: NgxChartsRecord
  time_to_start: NgxChartsRecord
  time_to_fill: NgxChartsRecord

  my_time_to_hire: NgxChartsRecord
  offers_outstanding: NgxChartsRecord
  my_offers_outstanding: NgxChartsRecord
  time_to_hire_by_department: NgxChartsRecord[]
  time_to_hire_by_location: NgxChartsRecord[]
  my_time_to_hire_by_location: NgxChartsRecord[]
  hires_per_month: NgxChartsRecord[]
  starts_per_month: NgxChartsRecord[]
  my_hires_per_month: NgxChartsRecord[]

  candidates_hired: OfferedCandidate[]
  candidates_offered: OfferedCandidate[]
  candidates_declined_offer: OfferedCandidate[]
  time_to_hire_by_user: NgxChartsRecord[]
  offers_by_source: NgxChartsRecord[]
  my_offers_by_source: NgxChartsRecord[]
  hires_by_source_name: NgxChartsRecord[]
  my_hires_by_source_name: NgxChartsRecord[]
  offers_by_source_name: NgxChartsRecord[]
  my_offers_by_source_name: NgxChartsRecord[]
  outstanding_offers: OfferedCandidate[]
  time_to_offer: NgxChartsRecord
  my_time_to_offer: NgxChartsRecord
  my_time_to_start: NgxChartsRecord
  my_time_to_fill: NgxChartsRecord
  applications_by_source_name: NgxChartsRecord[]
  my_applications_by_source_name: NgxChartsRecord[]
  // rejection_reasons_by_department: any
  // my_rejection_reasons_by_department: any
  applications_by_rejection_reason: NgxChartsRecord[]
  my_applications_by_rejection_reason: NgxChartsRecord[]
  my_submitted_scorecards: NgxChartsRecord[]

  open_jobs: NgxChartsRecord
  open_jobs_per_department: NgxChartsRecord[]
  active_apps_for_open_jobs: NgxChartsRecord

  my_open_jobs: NgxChartsRecord
  offers_by_recruiter: NgxChartsRecord[]
  hires_by_recruiter: NgxChartsRecord[]
  submitted_scorecards_per_recruiter: NgxChartsRecord[]
  outstanding_scorecards_per_user: NgxChartsRecord[]

  submitted_scorecards_total: NgxChartsRecord

  job_stage_categories_to_offer: Chart[]
  job_stage_categories_to_hire: Chart[]

  job_stage_categories_to_offer_by_recruiter: Chart[]
  job_stage_categories_to_hire_by_recruiter: Chart[]

  job_stage_categories_to_average_candidates_interviewed_per_week_per_recruiter: Chart[]

  candidates_interviewed: NgxChartsRecord
  candidates_interviewed_per_week: NgxChartsRecord[]
  candidates_interviewed_per_week_per_job_stage_category: Chart[]
  candidates_interviewed_per_day: NgxChartsRecord[]
  candidates_interviewed_per_day_per_job_stage_category: Chart[]

  applications_by_gender: NgxChartsRecord[]
  applications_by_race: NgxChartsRecord[]
  applications_by_veteran_status: NgxChartsRecord[]
  applications_by_disability_status: NgxChartsRecord[]

  gender_by_month: NgxChartsSeries
  race_by_month: NgxChartsSeries
}

export type offerTypes = "offer" | "hire"


const initialState: AnalyticsState = {
  loaded: false,
  widget_library_tabs: null,
  widget_library_tabs_data: null,
  widget_library_tabs_pending_data: [],

  my_activity: null,
  recruiter_activity: null,
  office_activity: null,
  overall_activity: null,
  applications_by_source: null,
  my_applications_by_source: null,
  hires_by_source: null,
  my_hires_by_source: null,
  hires_by_department: null,
  my_hires_by_department: null,
  department_activity: null,
  offers_total: null,
  hires_total: null,
  starts_total: null,
  my_hires_total: null,
  offers_rejected: null,
  my_offers_rejected: null,
  offers_outstanding: null,
  offer_acceptance_rate: null,
  my_offers_outstanding: null,
  time_to_hire_by_department: null,
  time_to_hire_by_location: null,
  my_time_to_hire_by_location: null,
  time_to_hire: null,
  my_time_to_hire: null,
  time_to_start: null,
  my_time_to_start: null,
  time_to_fill: null,
  my_time_to_fill: null,
  hires_per_month: null,
  starts_per_month: null,
  my_hires_per_month: null,
  company_dashboard_filters: null,
  my_dashboard_filters: null,
  custom_dashboard_filters: null,
  time_to_hire_by_user: null,
  offers_by_source: null,
  my_offers_by_source: null,
  hires_by_source_name: null,
  my_hires_by_source_name: null,
  offers_by_source_name: null,
  my_offers_by_source_name: null,
  candidates_hired: null,
  candidates_offered: null,
  candidates_declined_offer: null,
  outstanding_offers: null,
  time_to_offer: null,
  my_time_to_offer: null,
  applications_by_source_name: null,
  my_applications_by_source_name: null,
  applications_by_rejection_reason: null,
  my_applications_by_rejection_reason: null,
  my_submitted_scorecards: null,
  open_jobs: null,
  open_jobs_per_department: null,
  active_apps_for_open_jobs: null,

  my_open_jobs: null,
  hires_by_recruiter: null,
  offers_by_recruiter: null,

  submitted_scorecards_per_recruiter: null,
  outstanding_scorecards_per_user: null,
  submitted_scorecards_total: null,

  job_stage_categories_to_offer: null,
  job_stage_categories_to_hire: null,

  job_stage_categories_to_offer_by_recruiter: null,
  job_stage_categories_to_hire_by_recruiter: null,
  job_stage_categories_to_average_candidates_interviewed_per_week_per_recruiter: null,

  candidates_interviewed: null,
  candidates_interviewed_per_week: null,
  candidates_interviewed_per_week_per_job_stage_category: null,
  candidates_interviewed_per_day: null,
  candidates_interviewed_per_day_per_job_stage_category: null,

  applications_by_gender: null,
  applications_by_race: null,
  applications_by_veteran_status: null,
  applications_by_disability_status: null,

  gender_by_month: null,
  race_by_month: null,
}

// updates data for the given chart. If chart is not found we return null,
// otherwise we return the whole new state.
function mergeWidgetData(state: AnalyticsState, chart: Chart): AnalyticsState | null {
  // first we find this widget in current state
  let foundTab: string
  let foundSection: number
  let foundIndex: number
  for (const tab of objKeysSafe(state.widget_library_tabs_data)) {
    for (let sectionNumber = 0;
      sectionNumber < state.widget_library_tabs_data[tab]?.sections?.length; sectionNumber++) {
      const section = state.widget_library_tabs_data[tab].sections[sectionNumber]
      const idx = section.charts.findIndex(chartFromState =>
        chartFromState.data_source === chart.data_source &&
        isEqual(chart.data_source_parameters, chartFromState.data_source_parameters)
      )
      if (idx !== -1) {
        foundIndex = idx
        foundSection = sectionNumber
        foundTab = tab
        break
      }
    }
    if (foundTab) {
      break
    }
  }
  // then we update the chart if we found it in state
  if (foundTab) {
    // merge new chart into state
    const stateClone = cloneDeep(state)
    set(stateClone,
      `widget_library_tabs_data[${foundTab}]` +
      `.sections[${foundSection}]` +
      `.charts[${foundIndex}]`,
      chart
    )
    return stateClone
  } else {
    return null
  }
}

function mergePendingWidgets(state: AnalyticsState): AnalyticsState {
  // in case we received some widget data (via websockets) before request
  // for basic dashboard data has finished, we will have received data in
  // "widget_library_tabs_pending_data" array
  const newPendingWidgets = state.widget_library_tabs_pending_data.filter(
    chart => {
      const merged = mergeWidgetData(state, chart)
      if (merged) {
        state = merged
      }
      // remove this entry from widget_library_tabs_pending_data only if we
      // found an entry for this widget in state. Otherwise, we should
      // merge this widget sometime else, maybe it belongs to a different
      // tab
      return !!merged
    }
  )
  return {
    ...state,
    widget_library_tabs_pending_data: newPendingWidgets
  }
}

export function reducer(
  state: AnalyticsState = initialState,
  action: LoaderActions | DashboardFilterActions | JobActions | ExecutiveDashboardActions,
): AnalyticsState {
  switch (action.type) {
    case LoaderActionTypes.DashboardLoadSuccess: {
      const updatedState = {
        ...state,
        ...action.payload.analytics,
        // do a deeper merge on widget_library_tabs_data
        widget_library_tabs_data: {
          ...state.widget_library_tabs_data,
          ...action.payload.analytics.widget_library_tabs_data,
        },
        loaded: true,
      }
      return mergePendingWidgets(updatedState)
    }

    case LoaderActionTypes.FetchWidgetLibraryTab: {
      if (action.payload.forceLoad) {
        return {
          ...state,
          widget_library_tabs_data: {
            ...state.widget_library_tabs_data,
            // remove data for this tab, essentially allowing for reload
            [action.payload.tabId]: null,
          },
        }
      } else {
        return state
      }
    }

    case LoaderActionTypes.UpdateWidgetDataFromServer: {
      const newState = mergeWidgetData(state, action.payload)
      if (newState) {
        return newState
      } else {
        // in case we don't have all data for this tab yet, we put this chart
        // as pending
        return {
          ...state,
          widget_library_tabs_pending_data: [...state.widget_library_tabs_pending_data, action.payload]
        }
      }
    }

    case LoaderActionTypes.UpdateWidgetStatusFromQueueKey: {
      let ret = state
      if (action.payload) {
        for (const tab of objKeysSafe(state.widget_library_tabs_data)) {
          for (let sectionNumber = 0;
            sectionNumber < state.widget_library_tabs_data[tab]?.sections?.length; sectionNumber++) {
            const section = state.widget_library_tabs_data[tab].sections[sectionNumber]
            const idx = section.charts
              .findIndex(chartFromState => chartFromState?.data?.queue_key === action.payload.queue_key)
            if (idx !== -1) {
              if (ret === state) {
                ret = cloneDeep(ret)
              }
              set(ret,
                `widget_library_tabs_data[${tab}]` +
                `.sections[${sectionNumber}]` +
                `.charts[${idx}]` +
                `.data`,
                action.payload
              )
            }
          }
        }
      }
      return ret
    }

    case DashboardActionTypes.DashboardSetFilters: {
      /**
       * If filters changed on WidgetLibrary Tabs, then
       * Remove all tabs data and load them again with
       * updated data (filters applied).
       */
      if (action.payload.panel === DashboardTypes.CompanyDashboard) {
        return {
          ...state,
          widget_library_tabs_data: null,
        }
      }
      return state
    }

    case ExecutiveDashboardActionsTypes.FetchExecutiveDashboardCharts: {
      if (state.widget_library_tabs_data?.executive_dashboard) {
        const updatedState: AnalyticsState = {
          ...state,
          widget_library_tabs_data: {
            ...state.widget_library_tabs_data,
            executive_dashboard: null,
          }
        }
        return mergePendingWidgets(updatedState)
      } else {
        return state
      }
    }

    case DashboardActionTypes.RefreshAnalytics: {
      return {
        ...state
      }
    }

    case ExecutiveDashboardActionsTypes.FetchExecutiveDashboardChartsSuccess: {
      const updatedState: AnalyticsState = {
        ...state,
        widget_library_tabs_data: {
          ...state.widget_library_tabs_data,
          executive_dashboard: action.payload.analytics.widget_library_tabs_data.executive_dashboard,
        },
      }
      return mergePendingWidgets(updatedState)
    }

    default: {
      return state
    }
  }
}
