import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component,
  ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { JobDataManagerNames } from '../job-single-chart-filter.component'
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap'
import { Observable, ReplaySubject, Subscription, fromEvent } from 'rxjs'
import { SearchTypeaheadDataManager } from '../single-chart-filter.component'
import { filter, switchMap, tap } from 'rxjs/operators'

@Component({
  selector: 'twng-filter-search-typeahead',
  templateUrl: './filter-search-typeahead.component.html',
  styleUrls: ['./filter-search-typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSearchTypeaheadComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild('drop', { static: false, read: NgbDropdown })
    drop: NgbDropdown

  @ViewChild('filter') searchInput: ElementRef

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() manager: SearchTypeaheadDataManager<any>
  @Input() isModal = false

  searchText = ''
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterData$: Observable<any[]>
  subject = new ReplaySubject<void>()
  selectedData = []
  subs = new Subscription()
  indexCounter = 0
  elementLength = 0
  showInput = true
  radioChecked = false
  initialOpen = true
  filtersRadioName: string

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.filterData$ = this.subject.pipe(switchMap(() => this.manager.searchValues(this.searchText)))

    if (this.isModal) {
      this.filtersRadioName = `filtersRadio${this.manager?.searchPlaceholder.replaceAll(' ', '')}Modal`

      setTimeout(() => {
        this.showInput = !this.manager.tempSelectedValuesCompact?.length
        this.radioChecked = this.manager?.shouldExcludeFilter
        this.cd.markForCheck()
      });
    } else {
      this.filtersRadioName = `filtersRadio${this.manager.searchPlaceholder.replaceAll(' ', '')}`
      this.showInput = !this.manager.tempSelectedValuesCompact?.length
      this.radioChecked = this.manager?.shouldExcludeFilter
    }
  }

  ngAfterViewInit() {
    this.manager.initializeViewData(this.drop)

    this.subs.add(fromEvent(this.searchInput.nativeElement, 'click').pipe(
      tap(() => {
        this.filterValues()
        this.initialOpen = false
        const elements = document.querySelectorAll('.filter-dropdown.show .filter-result.dropdown-item');

        if (elements.length) {
          const activeEl = Array.from(elements).find(el => el.classList.contains('active'))
          activeEl?.classList.remove('active')
          elements[0]?.classList.add('active')
          this.indexCounter = 0
        }
      })
    ).subscribe())

    this.subs.add(fromEvent(this.searchInput.nativeElement, 'focusout').pipe(
      tap(() => {
        setTimeout(() => {
          if (this.searchInput.nativeElement !== document.activeElement) {
            if (this.indexCounter) {
              const elements = document.querySelectorAll('.filter-dropdown.show .filter-result.dropdown-item');
              elements[this.indexCounter]?.classList.remove('active')
              elements[0]?.classList.add('active')
            }

            this.initialOpen = true
            this.indexCounter = 0
            this.closeDrop()
            this.showInput = !this.manager.tempSelectedValuesCompact?.length && !this.isOpen()
            this.cd.markForCheck()
          }
        }, 200);
      })
    ).subscribe())

    this.subs.add(fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        tap(async (event) => {
          const keyboardEvent = event as KeyboardEvent
          if (keyboardEvent.code === 'Space' && this.searchText.length === 1) {
            this.searchText = this.searchText.trim()
          }

          if (keyboardEvent.key !== 'Enter' && keyboardEvent.key !== 'Tab' && keyboardEvent.key !== 'Escape') {
            this.openDrop()
          }

          const allElements = document.querySelectorAll('.filter-dropdown.show .filter-result.dropdown-item')
          if (!this.initialOpen) {
            const activeEls = Array.from(allElements).filter(el => el.classList.contains('active'))
            activeEls.forEach(el => el.classList.remove('active'))
          }
          if (allElements.length !== this.elementLength) {
            this.indexCounter = 0
          }
          this.elementLength = allElements.length

          if (keyboardEvent.key === 'ArrowDown') {
            if ((this.indexCounter + 1) > (allElements.length - 1)) {
              this.indexCounter = !allElements.length ? 0  : allElements.length - 1
            } else {
              this.indexCounter = this.initialOpen ? 0 : this.indexCounter + 1
            }
            this.initialOpen = false
          }

          if (keyboardEvent.key === 'ArrowUp') {
            this.indexCounter = (this.indexCounter - 1) < 0 ? 0 : this.indexCounter - 1
          }
          allElements[this.indexCounter]?.classList.add('active')

          if (keyboardEvent.key === 'Enter') {
            if (this.isOpen()) {
              const innerText = (allElements[this.indexCounter]?.childNodes[0] as HTMLElement)?.innerText
              const selectedElement = this.manager.allData.find(data => data.name === innerText)
              this.onClick(selectedElement)
              allElements[this.indexCounter]?.classList.remove('active')
              this.indexCounter = 0
              allElements[this.indexCounter]?.classList.add('active')
            } else {
              this.openDrop()
            }
          }

          if (keyboardEvent.key === 'Escape') {
            allElements[this.indexCounter]?.classList.remove('active')
            this.closeDrop()
            this.indexCounter = 0
            this.initialOpen = true
            allElements[0]?.classList.add('active')
          }
        })
      ).subscribe())
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  filterValues() {
    this.subject.next()

    if (this.searchText) {
      this.selectedData = this.manager.tempSelectedValuesCompact.filter(value =>
        value.name.toLowerCase().includes(this.searchText.toLowerCase()))
    } else {
      this.selectedData = this.manager.dataLoaded ? this.manager.tempSelectedValuesCompact : []
    }
    this.cd.detectChanges()
  }

  onClick(result) {
    this.manager.selectValueFromTypeahead(result)
    this.manager.click$.next(result)
    this.searchText = ''
    this.searchInput.nativeElement.focus()
    this.filterValues()
  }

  openDrop() {
    this.showInput = true
    this.filterValues()
    this.drop.open()
  }

  closeDrop() {
    this.drop.close()
  }

  isOpen() {
    return this.drop ? this.drop.isOpen() : false
  }

  onOpenChange(isOpen) {
    if (!isOpen) {
      this.indexCounter = 0
    }
  }

  clearTyping() {
    this.searchText = ''
    this.filterValues()
    this.searchInput.nativeElement.focus()
  }

  setFilterExclusion(value: boolean) {
    this.manager.setFilterExclusion(value)
    this.radioChecked = value

    // Make both job filters to the same shouldExcludeFilter value since in the backend they are handled as one
    const isClosedJobsFilter = this.manager.name?.includes(JobDataManagerNames.closed)
    const isOpenJobsFilter = this.manager.name?.includes(JobDataManagerNames.open)
    let el
    if (isOpenJobsFilter) {
      if (this.manager.shouldExcludeFilter) {
        el = document.getElementById('Closed_Jobs-exclude') as HTMLInputElement
      } else {
        el = document.getElementById('Closed_Jobs-include') as HTMLInputElement
      }
      el.click()
    } else if (isClosedJobsFilter) {
      if (this.manager.shouldExcludeFilter) {
        el = document.getElementById('Open_Jobs-exclude') as HTMLInputElement
      } else {
        el = document.getElementById('Open_Jobs-include') as HTMLInputElement
      }
      el.click()
    }

    this.searchInput.nativeElement.focus()
  }

  getRadioId(type) {
    return `${this.manager.searchPlaceholder.split(' ').join('_')}-${type}`
  }

  clearAllSelected() {
    this.manager.clearAll()
    this.searchInput.nativeElement.focus()
    this.radioChecked = this.manager?.shouldExcludeFilter
    this.filterValues()
  }

  clearSelected(id) {
    this.manager.unselectId(id)
    this.searchInput.nativeElement.focus()
    this.filterValues()
  }
}
