<div>
  <div *ngIf="job.confidential" class="my-3 alert alert-warning">
    Applications for confidential jobs cannot be modified from
    TalentWall.
  </div>
  <div class="header header--{{ activityLevel() }}">
    <div class="header__content">
      <div class="header__primary">
        <twng-private-candidate-icon *ngIf="candidate.is_private" class="tw-mr-2" [candidate]="candidate">
        </twng-private-candidate-icon>
        <h1 class="fs-exclude">{{ candidate.first_name }} {{ candidate.last_name }}</h1>
        <twng-star-toggle [jobApplication]="jobApplication"></twng-star-toggle>
        <!-- <i class="header__icon far fa-gem " ngbTooltip="Toggle Travel"></i>
        <i class="header__icon fas fa-flag " ngbTooltip="Toggle Visa Assistance"></i> -->
        <twng-toggle-tag-icons *ngIf="showTags" [candidate]="candidate"></twng-toggle-tag-icons>
        <div class="tw-flex" *ngIf="appConfig.enableApplicationRatings()">
          <twng-rating-number *ngFor="let ratingNumber of ratingNumbers" [ratingNumber]="ratingNumber"
            setJobApplicationRating variant="card" [selected]="jobApplication.rating === ratingNumber"
            [tooltipText]="'Set rating ' + ratingNumber" [jobApplication]="jobApplication">
          </twng-rating-number>
        </div>
      </div>

      <div class="header__secondary">
        <h3>Interviewing for</h3>
        <h2>
          <twng-card-detail-item title="" [value]="jobName()"></twng-card-detail-item>
        </h2>
      </div>

      <div class="header__activity-badge">
        <twng-activity-level
          [activityLevel]="jobApplication.activity_level"
          [lastActivityAt]="jobApplication.last_activity_at"
          [lastActivityDaysAgo]="jobApplication.last_activity_days_ago"
          [activityDayCategories]="activityDayCategories">
        </twng-activity-level>
      </div>
    </div>

    <button class="header__close header__close--large" type="button" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      <span class="large" aria-hidden="true">
        <i class="fas fa-times"></i>
      </span>
    </button>
  </div>

  <div class="body">
    <div class="sections">
      <div class="section section--candidate">
        <h2 class="section__header">
          <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.8571 0H1.71429C0.767857 0 0 0.767857 0 1.71429V14.2857C0 15.2321 0.767857 16 1.71429 16H18.8571C19.8036 16 20.5714 15.2321 20.5714 14.2857V1.71429C20.5714 0.767857 19.8036 0 18.8571 0ZM18.8571 14.2857H1.71429V1.71429H18.8571V14.2857ZM7.42857 8C8.68929 8 9.71428 6.975 9.71428 5.71429C9.71428 4.45357 8.68929 3.42857 7.42857 3.42857C6.16786 3.42857 5.14286 4.45357 5.14286 5.71429C5.14286 6.975 6.16786 8 7.42857 8ZM4.22857 12.5714H10.6286C11.0714 12.5714 11.4286 12.2643 11.4286 11.8857V11.2C11.4286 10.0643 10.3536 9.14286 9.02857 9.14286C8.64286 9.14286 8.36071 9.42857 7.42857 9.42857C6.46786 9.42857 6.23571 9.14286 5.82857 9.14286C4.50357 9.14286 3.42857 10.0643 3.42857 11.2V11.8857C3.42857 12.2643 3.78571 12.5714 4.22857 12.5714ZM12.8571 10.2857H16.8571C17.0143 10.2857 17.1429 10.1571 17.1429 10V9.42857C17.1429 9.27143 17.0143 9.14286 16.8571 9.14286H12.8571C12.7 9.14286 12.5714 9.27143 12.5714 9.42857V10C12.5714 10.1571 12.7 10.2857 12.8571 10.2857ZM12.8571 8H16.8571C17.0143 8 17.1429 7.87143 17.1429 7.71429V7.14286C17.1429 6.98571 17.0143 6.85714 16.8571 6.85714H12.8571C12.7 6.85714 12.5714 6.98571 12.5714 7.14286V7.71429C12.5714 7.87143 12.7 8 12.8571 8ZM12.8571 5.71429H16.8571C17.0143 5.71429 17.1429 5.58571 17.1429 5.42857V4.85714C17.1429 4.7 17.0143 4.57143 16.8571 4.57143H12.8571C12.7 4.57143 12.5714 4.7 12.5714 4.85714V5.42857C12.5714 5.58571 12.7 5.71429 12.8571 5.71429Z"
              fill="#767676" />
          </svg>
          <span class="remove-extra-font-space">
            Candidate Info
          </span>
        </h2>
        <div class="section__body">
          <div class="section__item">
            <twng-card-detail-item title="Current Title" [value]="candidate.current_title"></twng-card-detail-item>
          </div>
          <div class="section__item">
            <twng-card-detail-item title="Current Company" [value]="candidate.current_company"></twng-card-detail-item>
          </div>
          <div class="section__item">
            <twng-card-detail-item title="Tags" [value]="candidateTags()"></twng-card-detail-item>
          </div>
          <div class="section__item">
            <twng-card-detail-item [value]="candidateEmails()" format="email"></twng-card-detail-item>
          </div>
          <div class="section__item">
            <twng-card-detail-item [value]="candidate.phone_numbers" format="phone"></twng-card-detail-item>
          </div>
          <div class="section__item">
            <div class="section__item-value fs-exclude">
              <twng-social-media-icon *ngFor="let link of candidate.social_media_links" [link]="link">
              </twng-social-media-icon>
              <twng-attachment-icon *ngFor="let attachment of resumes()" [attachment]="attachment"
                [googlePreview]="account && account.preview_attachments_with_google"></twng-attachment-icon>
            </div>
          </div>
          <twng-other-applications [otherApplications]="isDemo ? otherApplications.slice(0, 2) : otherApplications">
          </twng-other-applications>
        </div>
      </div>

      <div class="section section--application">
        <h2 class="section__header">
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 16H4V14H12V16ZM12 10H4V12H12V10ZM14 1H12V3H14V18H2V3H4V1H2C1.46957 1 0.960859 1.21071 0.585786 1.58579C0.210714 1.96086 0 2.46957 0 3V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V3C16 2.46957 15.7893 1.96086 15.4142 1.58579C15.0391 1.21071 14.5304 1 14 1ZM10 3V2C10 1.46957 9.78929 0.960859 9.41421 0.585786C9.03914 0.210714 8.53043 0 8 0C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2V3C5.46957 3 4.96086 3.21071 4.58579 3.58579C4.21071 3.96086 4 4.46957 4 5V6H12V5C12 4.46957 11.7893 3.96086 11.4142 3.58579C11.0391 3.21071 10.5304 3 10 3Z"
              fill="#767676" />
          </svg>
          <span>
            Application
          </span>
        </h2>
        <div class="section__body">
          <div class="section__item">
            <twng-card-detail-item title="Hiring Managers" [value]="hiringManagers()"></twng-card-detail-item>
          </div>
          <div class="section__item">
            <twng-card-detail-item title="Time in Process" [value]="jobApplication.applied_at | timeAgo">
            </twng-card-detail-item>
          </div>
          <div class="section__item">
            <twng-card-detail-item [title]="appConfig.labelFor('last_activity')" 
              [value]="timeAgoString(jobApplication.last_activity_at)"></twng-card-detail-item>
          </div>
          <div class="section__item">
            <twng-card-detail-item title="Source" [value]="sourceString(jobApplication)"></twng-card-detail-item>
          </div>
          <div class="section__item">
            <twng-card-detail-item title="Credited To" [value]="creditedTo && creditedTo.name"></twng-card-detail-item>
          </div>
        </div>
      </div>
      <div class="section section--actions">
        <div class="section__body">
          <a class="action-item action-item--link action-item--ats tw-flex tw-flex-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-cq-gray-600 tw-border tw-border-cq-gray-300 tw-bg-[#FFFFFF] hover:tw-text-white tw-text-sm" 
            target="_blank" rel="noopener noreferrer"
            (click)="$event.stopPropagation(); trackViewInAts()"
            href="{{ urlService.jobApplicationUrl(jobApplication) | async }}">
            <div class="action-item__content-wrapper">
              <span class="action-item__icon-wrapper">
                <img class="ats-icon" src="{{ appConfig.atsIconUrl() }}" width="20" height="20" />
              </span>
              <span class="action-item__text fs-unblock">View In {{ atsName }}</span>
            </div>
          </a>

          <div *ngIf="!user.read_only && appConfig.canTransferCandidate() && !job.confidential" ngbDropdown
            (openChange)="candidateTransferForm.onVisible()" placement="bottom-right">
            <button ngbDropdownToggle id="transferMenu" 
            class="action-item action-item--button action-item--transfer tw-flex tw-flex-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-cq-gray-600 tw-border tw-border-cq-gray-300 tw-bg-[#FFFFFF] hover:tw-text-white tw-text-sm">
              <div class="action-item__content-wrapper">
                <span class="action-item__icon-wrapper">
                  <i class="fas fa-arrows-alt-h"></i>
                </span>
                <span class="action-item__text fs-unblock">Transfer</span>
              </div>
            </button>

            <div ngbDropdownMenu class="dropdown-menu--transfer" aria-labelledby="transferMenu">
              <twng-transfer-candidate-form [jobApplication]="jobApplication" [candidate]="candidate" [job]="job" #candidateTransferForm>
              </twng-transfer-candidate-form>
            </div>
          </div>

          <div *ngIf="!user.read_only && appConfig.canRejectCandidate() && !job.confidential" ngbDropdown
            placement="bottom-right">
            <button ngbDropdownToggle id="rejectMenu" class="action-item action-item--button action-item--reject tw-flex tw-flex-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-cq-gray-600 tw-border tw-border-cq-gray-300 tw-bg-[#FFFFFF] hover:tw-text-white tw-text-sm">
              <div class="action-item__content-wrapper">
                <span class="action-item__icon-wrapper">
                  <i class="fas fa-ban"></i>
                </span>
                <span class="action-item__text fs-unblock">{{ appConfig.labelFor('reject') }}</span>
              </div>
            </button>

            <div ngbDropdownMenu class="dropdown-menu--reject" aria-labelledby="rejectMenu">
              <twng-reject-candidate-form [jobApplication]="jobApplication" [candidate]="candidate">
              </twng-reject-candidate-form>
            </div>
          </div>

          <div class="section section--staffing" *ngIf="recruiter || coordinator">
            <h2 class="section__header fs-unblock">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM13.61 6.34C14.68 6.34 15.54 7.2 15.54 8.27C15.54 9.34 14.68 10.2 13.61 10.2C12.54 10.2 11.68 9.34 11.68 8.27C11.67 7.2 12.54 6.34 13.61 6.34ZM7.61 4.76C8.91 4.76 9.97 5.82 9.97 7.12C9.97 8.42 8.91 9.48 7.61 9.48C6.31 9.48 5.25 8.42 5.25 7.12C5.25 5.81 6.3 4.76 7.61 4.76ZM7.61 13.89V17.64C5.21 16.89 3.31 15.04 2.47 12.68C3.52 11.56 6.14 10.99 7.61 10.99C8.14 10.99 8.81 11.07 9.51 11.21C7.87 12.08 7.61 13.23 7.61 13.89ZM10 18C9.73 18 9.47 17.99 9.21 17.96V13.89C9.21 12.47 12.15 11.76 13.61 11.76C14.68 11.76 16.53 12.15 17.45 12.91C16.28 15.88 13.39 18 10 18Z"
                  fill="#767676" />
              </svg>
              <span>
                Staffing Team
              </span>
            </h2>

            <div class="section__body">
              <twng-card-detail-item title="Recruiter" [value]="recruiter && recruiter.name"></twng-card-detail-item>
              <twng-card-detail-item title="Coordinator" [value]="coordinator && coordinator.name">
              </twng-card-detail-item>
            </div>
          </div>
        </div>
      </div>
    </div>

    <twng-interviews [interviews]="interviews" [jobApplication]="jobApplication"></twng-interviews>
    <twng-activity-feed *ngIf="appConfig.viewNotesEnabled()" [candidate]="candidate"></twng-activity-feed>
    <twng-note-panel class="tw-mt-2" *ngIf="appConfig.viewNotesEnabled()" [candidate]="candidate" [user]="user">
    </twng-note-panel>
  </div>
</div>
