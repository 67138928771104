import { ConversionRatesRecord } from '../../wall/reducers/job-stage-stats.reducer'
import { ExecutiveDashboardConfiguration, PerJobConfiguration } from '../../wall/models/executive-dashboard'
import { Injectable } from '@angular/core'
import { Job } from '../../wall/models/job'
import { JobStage } from '../../wall/models/job-stage'
import { WidgetLibraryTabData } from '../../dashboard/reducers/analytics.reducer'
import { difference, sortBy } from 'lodash-es'
import { objKeysSafe } from '../../shared/utils/general-utils'

export enum TargetHireDateResultType {
  NOT_CONFIGURED,
  FROM_PER_JOB_CONFIG,
  FROM_GLOBAL_CONFIG,
}

@Injectable({
  providedIn: 'root'
})
export class ExecutiveDashboardService {

  constructor() { }

  static getInvolvedJobStages(jobs: Job[] = [],
    jobStagesPerJob: { [jobId: string]: JobStage[] }, excluded_job_stages: string[]): string[] {
    const ret: string[] = []
    for (const job of jobs) {
      const sortedStages = sortBy(jobStagesPerJob[job.id], ["position"])
      for (let i = 0; i < sortedStages.length; i++) {
        const jobStage = sortedStages[i]
        if (!ret.includes(jobStage.name)) {
          // put this stage after previous one if possible
          const previousIndex = ret.indexOf(sortedStages[i - 1]?.name)
          if (previousIndex >= 0) {
            ret.splice(previousIndex + 1, 0, jobStage.name)
          } else {
            ret.push(jobStage.name)
          }
        }
      }
    }
    return difference(ret, excluded_job_stages)
  }

  static getHiringGoalChart(tab: WidgetLibraryTabData, sectionIndex: number, widgetIndex: number) {
    return tab?.sections[sectionIndex] &&
      tab.sections[sectionIndex]?.charts &&
      tab.sections[sectionIndex].charts[widgetIndex]
  }

  static getHiringGoalChartData(tab: WidgetLibraryTabData, sectionIndex: number, widgetIndex: number) {
    return tab?.sections[sectionIndex] &&
      tab.sections[sectionIndex]?.charts &&
      tab.sections[sectionIndex].charts[widgetIndex] &&
      tab.sections[sectionIndex].charts[widgetIndex]?.data?.data
  }

  static getHiringGoalChartDataFirst(tab: WidgetLibraryTabData, sectionIndex: number, widgetIndex: number) {
    const ret = ExecutiveDashboardService.getHiringGoalChartData(tab, sectionIndex, widgetIndex)
    return ret && ret[0]
  }

  static getHiringGoalChartName(tab: WidgetLibraryTabData, sectionIndex: number, widgetIndex: number) {
    return tab?.sections[sectionIndex] &&
      tab.sections[sectionIndex]?.charts &&
      tab.sections[sectionIndex].charts[widgetIndex] &&
      tab.sections[sectionIndex].charts[widgetIndex]?.name
  }

  static getTotalActiveCandidates(conversionRates: Record<string, ConversionRatesRecord>) {
    let ret = 0
    for (const cr in conversionRates) {
      if (conversionRates[cr]?.active) {
        ret += conversionRates[cr]?.active
      }
    }
    return ret
  }

  static getTotalProcessedCandidates(conversionRates: Record<string, ConversionRatesRecord>) {
    let ret = 0
    for (const cr of objKeysSafe(conversionRates)) {
      if (conversionRates[cr]?.hired) {
        ret += conversionRates[cr]?.hired
      }
      if (conversionRates[cr]?.rejected) {
        ret += conversionRates[cr]?.rejected
      }
    }
    return ret
  }

  static getTargetHireDays(job: Job, jobConfig: PerJobConfiguration, config: ExecutiveDashboardConfiguration) {
    if (config.target_hire_days_after_opening === null || !config) {
      return {
        source: TargetHireDateResultType.NOT_CONFIGURED,
        date: null,
      }
    }
    const opened = new Date(job.opened_at)
    if (
      jobConfig &&
      jobConfig.target_hire_days_after_opening !== null
    ) {
      opened.setDate(opened.getDate() + jobConfig.target_hire_days_after_opening)
      return {
        source: TargetHireDateResultType.FROM_PER_JOB_CONFIG,
        date: opened,
      }
    }
    opened.setDate(opened.getDate() + config.target_hire_days_after_opening)
    return {
      source: opened ? TargetHireDateResultType.FROM_GLOBAL_CONFIG : TargetHireDateResultType.NOT_CONFIGURED,
      date: opened,
    }
  }

  static customFieldAsArray(customFieldValue: null | string | string[]) {
    if (customFieldValue) {
      if (Array.isArray(customFieldValue)) {
        return customFieldValue
      } else {
        return [customFieldValue]
      }
    } else {
      return []
    }
  }
}
