import { SearchTypeaheadDataManager, SingleValueInFilter } from './single-chart-filter.component'

export interface SingleValueInFilterWithChildren extends SingleValueInFilter {
  children: SingleValueInFilterWithChildren[]
  depth: number
}

export abstract class HierarchicalSearchTypeaheadDataManager<T extends SingleValueInFilterWithChildren>
  extends SearchTypeaheadDataManager<T> {
  private removeChildren(values: T[], toRemove: SingleValueInFilterWithChildren) {
    const idx = values.findIndex(d => d.id === toRemove.id)
    if (idx !== -1) {
      values.splice(idx, 1)
    }
    if (toRemove.children) {
      toRemove.children.forEach(child => this.removeChildren(values, child))
    }
  }
  protected getSearchableValues(): T[] {
    const values = [...this.allData]
    this.tempSelectedValues.forEach(d => this.removeChildren(values, d))
    return values
  }
  protected unselectChildren(value: SingleValueInFilterWithChildren) {
    if (value.children) {
      value.children.forEach(child => this.unselectSingleId(child.id))
      value.children.forEach(child => this.unselectChildren(child))
    }
  }
  protected selectSingleValue(value: T) {
    super.selectSingleValue(value)
    this.unselectChildren(value)
  }
}
