import { BehaviorSubject, Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { CacheService } from '../../../../wall/services/cache.service'
import { ExternalUser } from '../../../../wall/models/external-user'
import { FilterOption } from '../generic-filter/filter-option.interface'

@Component({
  selector: 'twng-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss'],
})
export class UserFilterComponent implements OnInit {

  selectedUsers$: BehaviorSubject<ExternalUser[]> = new BehaviorSubject([])
  externalUsers$: Observable<ExternalUser[]>

  @Input()
    userIds: string[] = []

  @Output()
    filterChange = new EventEmitter<FilterOption[]>()

  @Input()
    disabled = false

  constructor(private cacheService: CacheService) {
  }

  ngOnInit(): void {
    this.externalUsers$ = this.cacheService.activeExternalUsers$
      .pipe(
        tap((externalUsers: ExternalUser[]) => this.loadSelectedUsers(externalUsers, this.userIds)),
        switchMap((externalUsers: ExternalUser[]) => this.selectedUsers$.pipe(
          map((selectedUsers: ExternalUser[]) => externalUsers.filter(externalUser => {
            const foundUser = selectedUsers.find(
              selectedUser => selectedUser.id === externalUser.id)
            // If foundUser, then return false to filter it
            return !foundUser
          }))
        )),
      )
  }

  loadSelectedUsers(externalUsers: ExternalUser[], userIds: string[]): void {
    const users = externalUsers.filter(externalUser => (userIds || []).includes(externalUser.id))
    setTimeout(() => this.selectedUsers$.next(users))
  }

  userSelected(externalUser: FilterOption) {
    const selectedUsers = this.selectedUsers$.value
    this.selectedUsers$.next([...selectedUsers, (externalUser as ExternalUser)])
    this.emitFilterValue()
  }

  removeUser(externalUser: FilterOption) {
    const selectedUsers = this.selectedUsers$.value.filter((user: ExternalUser) => user.id !== externalUser.id)
    this.selectedUsers$.next(selectedUsers)
    this.emitFilterValue()
  }

  emitFilterValue() {
    setTimeout(() => this.filterChange.emit(this.selectedUsers$.value))
  }

  reset() {
    while (this.selectedUsers$.value.length) {
      this.removeUser(this.selectedUsers$.value[0])
    }
  }
}
