/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'
import { atsId } from '../../wall/models/types'

export enum TransferCandidateUIActionTypes {
  UpdateSelectedJob = '[TransferCandidate] UpdateSelectedJob',
  UpdateSelectedJobStage = '[TransferCandidate] UpdateSelectedJobStage',
  TransferCandidate = '[TransferCandidate] TransferCandidate',
  TransferCandidateSuccess = '[TransferCandidate] TransferCandidateSuccess',
  TransferCandidateFailure = '[TransferCandidate] TransferCandidateFailure',
}

export class UpdateSelectedJob implements Action {
  readonly type = TransferCandidateUIActionTypes.UpdateSelectedJob
  constructor(public payload: { jobApplicationId: atsId; jobId: atsId }) { }
}

export class UpdateSelectedJobStage implements Action {
  readonly type = TransferCandidateUIActionTypes.UpdateSelectedJobStage
  constructor(
    public payload: { jobApplicationId: atsId; jobStageId: atsId },
  ) { }
}

export class TransferCandidate implements Action {
  readonly type = TransferCandidateUIActionTypes.TransferCandidate
  constructor(
    public payload: {
      jobApplicationId: atsId
      jobId: atsId
      jobStageId: atsId
    },
  ) { }
}

export class TransferCandidateSuccess implements Action {
  readonly type = TransferCandidateUIActionTypes.TransferCandidateSuccess
  constructor(public payload: { requestData: unknown; jobApplicationId: atsId }) { }
}

export class TransferCandidateFailure implements Action {
  readonly type = TransferCandidateUIActionTypes.TransferCandidateFailure
  constructor(public payload: { jobApplicationId: atsId }) { }
}

export type TransferCandidateUIActions =
  | UpdateSelectedJob
  | UpdateSelectedJobStage
  | TransferCandidate
  | TransferCandidateSuccess
  | TransferCandidateFailure
