import { Action } from '@ngrx/store'
import { Candidate } from '../models/candidate'
import { Interview } from '../models/interview'
import { Job } from '../models/job'
import { JobApplication } from '../models/job-application'
import { JobStage } from '../models/job-stage'
import { PossibleActivityLevels } from '../models/activity-day-categories'
import { SelectedCustomFieldsFilter } from '../../custom-fields/models/selected-custom-fields-filter'
import { Tab } from '../models/tab'

export enum WallActionTypes {
  FetchWallDataPaginatedAction = '[Wall] FetchWallDataPaginated',
  FetchWallDataPaginatedSuccessAction = '[Wall] FetchWallDataPaginatedSuccess',
  FetchWallDataPaginatedFailureAction = '[Wall] FetchWallDataPaginatedFailure',
  FetchWallDataPaginatedTabAction = '[Wall] FetchWallDataPaginatedTab',
  FetchWallDataPaginatedTabSuccessAction = '[Wall] FetchWallDataPaginatedTabSuccess',
  FetchWallDataPaginatedTabFailureAction = '[Wall] FetchWallDataPaginatedTabFailure',
  FetchJobByIdAction = '[Wall] FetchJobById',
  FetchJobsByIdsSuccessAction = '[Wall] FetchJobsByIdsSuccess',
  FetchJobsByIdsFailureAction = '[Wall] FetchJobsByIdsFailure',
}

export interface WallDataPaginatedCandidateFilter {
  show_starred_only: boolean
  show_my_candidates_only: boolean
  // next 3 filters work together
  show_without_interviews: boolean
  show_with_completed_interviews: boolean
  show_with_scheduled_interviews: boolean
  //
  matching_sources: string[] // these are source_type-s (source_type column in sources table)
  matching_tags: string[]
  matching_candidate_name: string
  matching_activity_levels: PossibleActivityLevels[] // 'inactive' | 'poor' | 'fair' | 'good'
  matching_ratings: number[]
  matching_recruiters: string[] // ids
  matching_credited_to: string[] // ids
  custom_fields: SelectedCustomFieldsFilter[]
}

export interface WallDataPaginatedJobFilter {
  department_external_ids: string[]
  office_external_ids: string[]
  external_user_external_ids: string[]
  job_external_ids: string[]
  job_priorities: string[]
  employment_types: string[],
  matching_job_name: string,
  custom_fields: SelectedCustomFieldsFilter[]
}

export type PossibleJobSortingTypes = 'name' | 'priority' | 'employment_type'
export type PossibleJobSortingDirections = 'asc' | 'desc'

export interface WallDataPaginatedBasicFilter {
  candidate_filter: WallDataPaginatedCandidateFilter
  frontendFetchId?: number
}

export interface WallDataPaginatedAllJobsFilter extends WallDataPaginatedBasicFilter {
  job_filter: WallDataPaginatedJobFilter
  sort_by: PossibleJobSortingTypes
  sort_dir: PossibleJobSortingDirections
}

export interface WallDataPaginatedTabFilter extends WallDataPaginatedBasicFilter {
  tab_id: string
  tab_sharable_token?: string,
  sort_by?: PossibleJobSortingTypes,
  sort_dir?: PossibleJobSortingDirections,
}

export interface WallDataPaginatedFilterExtraInfo {
  page: number
  per_page: number
  show_jobs_without_apps: boolean
  fetch_credited_to_ids: boolean
}

export type WallDataPaginatedFilterAllJobsPayload = WallDataPaginatedFilterExtraInfo & WallDataPaginatedAllJobsFilter
export type WallDataPaginatedFilterTabPayload = WallDataPaginatedFilterExtraInfo & WallDataPaginatedTabFilter

export class FetchWallDataPaginated implements Action {
  readonly type = WallActionTypes.FetchWallDataPaginatedAction
  constructor(public payload: WallDataPaginatedFilterAllJobsPayload) {}
}

export class FetchWallDataPaginatedTab implements Action {
  readonly type = WallActionTypes.FetchWallDataPaginatedTabAction
  constructor(public payload: WallDataPaginatedFilterTabPayload) {}
}

export interface WallDataPaginatedResponse {
  total_records: number
  job_ids: string[]
  candidates: Candidate[]
  job_applications: JobApplication[]
  interviews: Interview[]
  tab?: Tab
  credited_to_ids: string[] | undefined
}

export interface JobsByIdsResponse {
  jobs: Job[]
  job_stages: JobStage[]
}

export interface WallDataPaginatedResult extends WallDataPaginatedResponse {
  frontendFetchId: number
}

export class FetchWallDataPaginatedSuccess implements Action {
  readonly type = WallActionTypes.FetchWallDataPaginatedSuccessAction
  constructor(public payload: WallDataPaginatedResult) {}
}

export class FetchWallDataPaginatedFailure implements Action {
  readonly type = WallActionTypes.FetchWallDataPaginatedFailureAction
}

export class FetchWallDataPaginatedTabSuccess implements Action {
  readonly type = WallActionTypes.FetchWallDataPaginatedTabSuccessAction
  constructor(public payload: WallDataPaginatedResult) {}
}

export class FetchJobById implements Action {
  readonly type = WallActionTypes.FetchJobByIdAction
  constructor(public payload: string) {}
}

export class FetchJobsByIdsSuccess implements Action {
  readonly type = WallActionTypes.FetchJobsByIdsSuccessAction
  constructor(public payload: JobsByIdsResponse) {}
}

export class FetchJobsByIdsFailure implements Action {
  readonly type = WallActionTypes.FetchJobsByIdsFailureAction
}

export class FetchWallDataPaginatedTabFailure implements Action {
  readonly type = WallActionTypes.FetchWallDataPaginatedTabFailureAction
}

export type WallActions =
  FetchWallDataPaginated | FetchWallDataPaginatedSuccess | FetchWallDataPaginatedFailure |
  FetchWallDataPaginatedTab | FetchWallDataPaginatedTabSuccess | FetchWallDataPaginatedTabFailure |
  FetchJobById | FetchJobsByIdsSuccess | FetchJobsByIdsFailure

export function isTabWallFilter(filter: WallDataPaginatedTabFilter | WallDataPaginatedAllJobsFilter):
 filter is WallDataPaginatedTabFilter {
  return (filter as WallDataPaginatedTabFilter).tab_id !== null
}
