import { AfterViewInit, ChangeDetectorRef, Component, ElementRef,
  EventEmitter, Input, OnChanges, OnDestroy, Optional, Output, QueryList,
  Renderer2, SimpleChanges, ViewChildren }
  from '@angular/core'
import { ChartFilters } from '../../models/chart-filters'
import { ChartFiltersModalComponent } from './chart-filters-modal/chart-filters-modal.component'
import { DashboardChart } from '../../models/dashboard-chart'
import { DashboardTypes } from '../dashboard-types'
import { GridsterComponent } from 'angular-gridster2'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import {
  SearchTypeaheadDataManager, SingleChartFilterComponent
} from './single-chart-filter/single-chart-filter.component'
import { Subscription, timer } from 'rxjs'
import { UntypedFormGroup } from '@angular/forms'
import {
  classNameSelected
} from './single-chart-filter/single-chart-filter-template/single-chart-filter-template.component'
import { dateFilterForm } from './single-chart-filter/date-chart-filter/date-chart-filter.component'

@Component({
  selector: 'twng-chart-filters',
  templateUrl: './chart-filters.component.html',
  styleUrls: ['./chart-filters.component.scss']
})
export class ChartFiltersComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input()
    panel: DashboardTypes

  @Input()
    chart: DashboardChart

  @Input()
    filters: ChartFilters

  @Input()
    isTempDashboard = false

  @Input()
    filtersEnabled = true

  @Output()
    onFiltersChanged = new EventEmitter<Partial<ChartFilters>>()

  @Input()
    chartFiltersReadOnly = false

  @Input()
    overrideFiltersSet = false

  @Output()
    resetChartFilters = new EventEmitter()

  @ViewChildren(SingleChartFilterComponent)
  private children!: QueryList<SingleChartFilterComponent>;

  shouldShrinkIcons = false

  subs = new Subscription()

  showFilters: boolean
  showDateFilters: boolean
  showClearAll: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  managers: SearchTypeaheadDataManager<any>[]
  currentChild: string

  dateFilterForm = new UntypedFormGroup(dateFilterForm)

  constructor(
    @Optional() private gridster: GridsterComponent,
    private cd: ChangeDetectorRef,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private modalService: NgbModal
  ) {
  }

  private get element(): HTMLElement {
    return this.elRef.nativeElement
  }

  get showClearDate(): boolean {
    return this.panel === DashboardTypes.CustomDashboard
      && !!this.filters.start_date && this.showDateFilters
  }

  ngAfterViewInit(): void {
    if (this.gridster) {
      this.subs.add(timer(0, 500).subscribe(() => this.refreshDesiredWidth()))
      this.renderer.setStyle(this.element, 'width', '100%')
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.showClearAll = this.hasFilters(changes.filters?.currentValue)
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  private refreshDesiredWidth() {
    // We enter "shrinked mode" if grid item (from gridster) containing this
    // widget has size less then certain size of pixels. This can happen when
    // page is zoomed in at certain level (or OS has increased the size of
    // everything)
    const oldShrink = this.shouldShrinkIcons
    const gridsterHeight = this.element.closest('gridster-item')?.clientHeight
    this.shouldShrinkIcons = gridsterHeight <= 145
    if (oldShrink !== this.shouldShrinkIcons) {
      this.cd.markForCheck()
    }
  }

  openFilters({managers, componentName}) {
    if (!this.chartFiltersReadOnly) {
      if (this.chart) {
        const modal = this.modalService.open(ChartFiltersModalComponent, { windowClass: 'modal-size' })
        const modalInstance = modal.componentInstance as ChartFiltersModalComponent
        modalInstance.filters = this.chart.filters
        modalInstance.filterLoaded = true
        modalInstance.chart = this.chart
        modalInstance.overrideFiltersSet = this.overrideFiltersSet
      } else {
        this.managers = managers
        this.currentChild = componentName
        this.showFilters = true
        this.showDateFilters = componentName === 'DateChartFilterComponent'
      }
    }
  }

  apply() {
    let filters = {}
    this.children.forEach(child => {
      filters = {...child.getChangedValueForStoring(), ...filters}
    })

    this.children.last.applyFilters(filters)
    this.closeFilters()
  }

  closeFilters() {
    const el = document.getElementsByClassName(classNameSelected)
    el[0]?.classList.remove(classNameSelected)
    this.showFilters = false
    this.showDateFilters = false
  }

  cancel() {
    this.closeFilters()
    const selectedChild = this.children.find(child => child.id === this.currentChild)
    selectedChild.managers?.forEach(manager => {
      manager.clearSelection()
    })
  }

  clearAll() {
    let filters = {}
    this.children.forEach(child => {
      child.managers?.forEach(manager => {
        manager.clearAll()
      })

      filters = {...child.getChangedValueForStoring(), ...filters}
    })

    this.children.last.applyFilters(filters)
    this.closeFilters()
  }

  hasFilters(data) {
    for (const key in data) {
      if (Array.isArray(data[key]) && data[key].length) {
        return true
      }
    }
    return false
  }

  clearDate() {
    this.dateFilterForm.setValue({
      dateFilterMode: 'custom',
      startDate: null,
      endDate: null
    })
    this.apply()
  }
}
