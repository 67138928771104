import { Observable } from 'rxjs'

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import {
  CreateCategory, LoadStageMappings, RemoveCategory, RenameJobStageCategoryName,
  RenameJobStageCategoryNamePayload, UpdateCategory, UpdateCategoryPositions, UpdateJobStageCategoryPayload
} from '../actions/stage-mappings.actions'
import { JobStageCategory } from '../models/job-stage-category'
import { StageMappingsState } from '../reducers/stage-mappings.reducer'
import { cloneDeep, max, without } from 'lodash-es'
import { map } from 'rxjs/operators'
import {
  selectCategoriesByIds, selectCategoryById, selectIsCreatingCategory, selectIsLoadingCategories,
  selectIsSyncing, selectJobStageCategories, selectJobStageNames
} from '../selectors/stage-mappings.selectors'
import { selectImmediatelySync } from '../../shared/utils/store.utils'

@Injectable({ providedIn: 'root' })
export class StageMappingsService {
  constructor(private store: Store<StageMappingsState>) { }

  loadCategories() {
    this.store.dispatch(new LoadStageMappings())
  }

  getIsLoadingCategories(): Observable<boolean> {
    return this.store.select(selectIsLoadingCategories)
  }

  getJobStageCategories(): Observable<JobStageCategory[]> {
    return this.store.select(selectJobStageCategories)
  }

  getJobStageNames(): Observable<string[]> {
    return this.store.select(selectJobStageNames).pipe(map(names => without(names, "Offer")))
  }

  getIsCreatingCategory(): Observable<boolean> {
    return this.store.select(selectIsCreatingCategory)
  }

  getCategoryById(id: number): Observable<JobStageCategory> {
    return this.store.select(selectCategoryById(id))
  }

  getJobsCategories(categoriesIds: number[]): Observable<JobStageCategory[]> {
    return this.store.select(selectCategoriesByIds(categoriesIds))
  }

  createCategory() {
    const jscs = selectImmediatelySync(this.store, selectJobStageCategories)
    // find all job stage categories with name "New Phase x" where x is a number
    const matchingJscs = jscs.filter(jsc => jsc.name.match(/^New Phase \d+$/))
    const matchingJscNumbers = matchingJscs.map(jsc => parseInt(jsc.name.split(' ')[2], 10))
    // find largest number in those names
    const largestNumberFound = max(matchingJscNumbers) || 0
    this.store.dispatch(new CreateCategory("New Phase " + (largestNumberFound + 1)))
  }

  updateCategory(updateJobStageCategoryPayload: UpdateJobStageCategoryPayload) {
    this.store.dispatch(new UpdateCategory(updateJobStageCategoryPayload))
  }

  removeCategory(jobStageCategory: JobStageCategory) {
    this.store.dispatch(new RemoveCategory({ jobStageCategory }))
  }

  renameCategory(renameJobStageCategoryNamePayload: RenameJobStageCategoryNamePayload) {
    this.store.dispatch(new RenameJobStageCategoryName(renameJobStageCategoryNamePayload))
  }

  getIsSyncing(): Observable<boolean> {
    return this.store.select(selectIsSyncing)
  }

  commitJobStagePositions(newStageCategories: JobStageCategory[]) {
    // We don't want to modify original array that is in state
    const clone = cloneDeep(newStageCategories)
    // we need sorted version of newStageCategories, and .sort() modifies the array.
    // We don't want to modify array from state
    const newStageCategoriesSorted = cloneDeep(newStageCategories).sort((a, b) => a.position - b.position)
    for (let i = 0; i < clone.length; i++) {
      clone[i].id = newStageCategoriesSorted[i].id
    }

    this.store.dispatch(new UpdateCategoryPositions({
      newJobStages: clone
    }))
  }
}
