import { ChartFilters } from '../../../models/chart-filters'
import { Component } from '@angular/core'
import { CustomFieldProps } from '../../../../custom-fields/models/custom-field'
import { CustomFieldsService, PossibleCustomFieldSource
} from '../../../../custom-fields/services/custom-fields.service'
import { Observable } from 'rxjs'
import { SearchTypeaheadDataManager, SingleChartFilterComponent,
  SingleValueInFilter
} from './single-chart-filter.component'
import { SegmentService } from '../../../../core/services/segment.service'
import { SelectedCustomFieldsFilter } from '../../../../custom-fields/models/selected-custom-fields-filter'
import { Store } from '@ngrx/store'
import { first, map } from 'rxjs/operators'
import { flatten, groupBy } from 'lodash-es'
import { objValuesSafe } from '../../../../shared/utils/general-utils'
import { selectCandidateTagEntities } from '../../../../wall/reducers'

////////////////////////////////////////////
/// CUSTOM FIELDS
////////////////////////////////////////////
interface CustomFieldForTypeahead extends SingleValueInFilter {
  customFieldKey: string
  customFieldValue: string
}

type PossibleCustomField = 'custom_fields' | 'candidate_custom_fields' | 'offer_custom_fields'

function convertCustomFieldsForTypeahead(customFields: Observable<CustomFieldProps[]>):
Promise<CustomFieldForTypeahead[]> {
  return customFields.pipe(
    first(),
    map(fields => flatten(
      fields.map(field => field.custom_field_options.map(
        option => {
          const ret: CustomFieldForTypeahead = {
            id: field.id + '|' + option,
            name: field.name + ': ' + option,
            customFieldKey: field.id,
            customFieldValue: option
          }
          return ret
        }
      ))
    ))
  ).
    toPromise()
}

function convertChartFilterCFsToIds(chartFilters: ChartFilters, key: PossibleCustomField): string[] {
  return flatten(chartFilters[key].map(
    cf => cf.values.map(val => cf.id + '|' + val)
  ))
}

export abstract class CustomFieldDataManager extends SearchTypeaheadDataManager<CustomFieldForTypeahead> {

  constructor(private customFields: CustomFieldsService, placeholderText: string) {
    super(placeholderText)
  }

  protected loadAllData(): Promise<CustomFieldForTypeahead[]> {
    return convertCustomFieldsForTypeahead(
      this.customFields.getCustomFields(this.getCustomFieldSource())
    )
  }
  protected getIdsFromFilters(): string[] {
    return convertChartFilterCFsToIds(this.filters, this.getKeyInFilters())
  }

  getSelectedValues() {
    return this.tempSelectedValues
  }

  abstract getCustomFieldSource(): PossibleCustomFieldSource
  abstract getKeyInFilters(): PossibleCustomField
}

export class JobCustomFieldDataManager extends CustomFieldDataManager {
  constructor(customFields: CustomFieldsService) {
    super(customFields, 'Job Custom Fields')
  }
  getCustomFieldSource(): PossibleCustomFieldSource {
    return 'job'
  }
  getKeyInFilters(): PossibleCustomField {
    return 'custom_fields'
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_custom_fields
  }
}

export class CandidateCustomFieldDataManager extends CustomFieldDataManager {
  constructor(customFields: CustomFieldsService) {
    super(customFields, 'Candidate Custom Fields')
  }
  getCustomFieldSource(): PossibleCustomFieldSource {
    return 'candidate'
  }
  getKeyInFilters(): PossibleCustomField {
    return 'candidate_custom_fields'
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_candidate_custom_fields
  }
}

export class OfferCustomFieldDataManager extends CustomFieldDataManager {
  constructor(customFields: CustomFieldsService) {
    super(customFields, 'Offer Custom Fields')
  }
  getCustomFieldSource(): PossibleCustomFieldSource {
    return 'offer'
  }
  getKeyInFilters(): PossibleCustomField {
    return 'offer_custom_fields'
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_offer_custom_fields
  }
}

export function dataForStoringFromManager(manager: CustomFieldDataManager): SelectedCustomFieldsFilter[] {
  return Object.entries(
    groupBy(
      manager.getSelectedValues(),
      cf => cf.customFieldKey
    )
  ).map(([key, values]) => {
    const ret: SelectedCustomFieldsFilter = {
      id: key,
      values: values.map(val => val.customFieldValue)
    }
    return ret
  })
}

////////////////////////////////////////////
/// CANDIDATE TAGS
////////////////////////////////////////////
export class CandidateTagsDataManager extends SearchTypeaheadDataManager<SingleValueInFilter> {
  constructor(private store: Store) {
    super('Candidate Tags')
  }

  protected async loadAllData(): Promise<SingleValueInFilter[]> {
    const entities = await this.store.select(selectCandidateTagEntities).pipe(first(v => !!v)).toPromise()
    const values = objValuesSafe(entities)
    return values.map(data => ({
      id: data.name,
      name: data.name,
    }))
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.candidate_tags
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_candidate_tags
  }
}

////////////////////////////////////////////
/// IMPLEMENTATION
////////////////////////////////////////////

@Component({
  selector: 'twng-custom-fields-single-chart-filter',
  templateUrl: './single-chart-filter.component.html',
  styleUrls: ['./single-chart-filter.component.scss',
    './single-chart-filter-template/single-chart-filter-template.component.scss'
  ],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  providers: [{ provide: SingleChartFilterComponent, useExisting: CustomFieldSingleChartFilterComponent }]
})
export class CustomFieldSingleChartFilterComponent extends SingleChartFilterComponent {
  id = 'CustomFieldSingleChartFilterComponent';

  constructor(
    segmentService: SegmentService,
    store: Store,
    private customFields: CustomFieldsService
  ) {
    super(segmentService, store, 'Custom Options', 'Custom Options', 'fa-tools')
  }

  getChangedValueForStoring(): Partial<ChartFilters> {
    return {
      custom_fields: dataForStoringFromManager(this.getCFManager(0)),
      should_exclude_custom_fields: this.managers[0].shouldExcludeFilter,
      candidate_custom_fields: dataForStoringFromManager(this.getCFManager(1)),
      should_exclude_candidate_custom_fields: this.managers[1].shouldExcludeFilter,
      offer_custom_fields: dataForStoringFromManager(this.getCFManager(2)),
      should_exclude_offer_custom_fields: this.managers[2].shouldExcludeFilter,
      candidate_tags: this.managers[3].tempSelectedIds,
      should_exclude_candidate_tags:  this.managers[3].shouldExcludeFilter
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getDataManagers(): SearchTypeaheadDataManager<any>[] {
    return [
      new JobCustomFieldDataManager(this.customFields),
      new CandidateCustomFieldDataManager(this.customFields),
      new OfferCustomFieldDataManager(this.customFields),
      new CandidateTagsDataManager(this.store),
    ]
  }

  private getCFManager(index: number): CustomFieldDataManager {
    const mgr = this.managers[index]
    if (!(mgr instanceof CustomFieldDataManager)) {
      throw new Error("getCFManager called with wrong index")
    } else {
      return mgr
    }
  }
}
