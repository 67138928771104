import { CandidatesInStageComponent } from './components/candidates-in-stage.component';
import { CandidatesInStageEffects } from './effects/candidates-in-stage.effects';
import {
  CandidatesInStageFiltersComponent
} from './components/candidates-in-stage-filters/candidates-in-stage-filters.component';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    CandidatesInStageComponent,
    CandidatesInStageFiltersComponent,
  ],
  exports: [CandidatesInStageComponent],
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([
      CandidatesInStageEffects
    ])
  ]
})
export class CandidatesInStageModule {
}
