<div ngbDropdown container="body" placement="left" (openChange)="dropdownOpenChange($event)" *ngIf="!demo"
  #dropdown="ngbDropdown">
  <button class="btn app__dropdown-toggle" ngbDropdownToggle data-html2canvas-ignore>
    <i class="fas fa-wrench"></i>
  </button>
  <div ngbDropdownMenu class="app__dropdown-menu">
    <ng-container *ngIf="form">
      <h3>Target hire date</h3>
      <h4>As number of days after opening</h4>
      <p>This value overrides global value that is found in Executive Dashboard Configuration</p>
      <form class="d-flex" [formGroup]="form" (submit)="submit()">
        <input class="form-control" placeholder="Ex. 14" [formControlName]="formKeyName" type="number" min="0"
          autofocus>
        <button class="btn btn-primary" [disabled]="form.disabled">Save</button>
      </form>
    </ng-container>
  </div>
</div>
