import { Pipe, PipeTransform } from '@angular/core'
import { formatDistanceToNow } from 'date-fns'

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(timeString: string | Date, lastActivityDaysAgo?: number, addSuffix = true): string {
    const time = new Date(timeString)
    const result = formatDistanceToNow(time, { addSuffix })
    if (result.includes('day') && lastActivityDaysAgo) {
      if (lastActivityDaysAgo === 1) {
        return '1 day ago'
      }
      return result.replace(/\d+/, lastActivityDaysAgo.toString())
    }
    return result
  }
}
