import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { JobStageCategory } from '../../models/job-stage-category'
import { UpdateCategoryPositionPayload } from '../../actions/stage-mappings.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent {

  @Input() categories: JobStageCategory[]

  @Input()
    isCreatingCategory: boolean

  @Output()
    createCategory = new EventEmitter<void>()

  @Output()
    updateCategoryPosition = new EventEmitter<UpdateCategoryPositionPayload>()

  @Output()
    commitUpdatingCategoryPositions = new EventEmitter()

  emitCreateCategory() {
    this.createCategory.emit()
  }

  updateCategory(event) {
    const replaceCategory = this.categories.find((category) => category.position === event.newPosition);

    if (replaceCategory) {
      this.updateCategoryPosition.emit({
        draggedCategoryId: event.id,
        replacePositionWithCategoryId: replaceCategory.id
      })
      this.commitUpdatingCategoryPositions.emit()
    }
  }
}
