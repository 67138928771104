import { ApiStatus } from "../actions/api.actions"
import { SharedState } from "."
import { SingleApiState } from "../reducers/api.reducers"
import { Store, createFeatureSelector, createSelector } from "@ngrx/store"
import { map } from "rxjs/operators"
import { objValuesSafe } from "../utils/general-utils"

export const selectSharedState = createFeatureSelector<SharedState>('shared')
export const selectApi = createSelector(
  selectSharedState,
  state => state.api
)

export function getSelectorForSpecificApi(apiName: string) {
  return createSelector(
    selectApi,
    state => state[apiName] || {} as SingleApiState
  )
}

export function getSelectorForSpecificApiId(apiId: number) {
  return createSelector(
    selectApi,
    state => objValuesSafe(state).find(apiState => apiState.id === apiId)
  )
}

function isApiStateLoaded(state: SingleApiState) {
  return state?.status === ApiStatus.Success || state?.status === ApiStatus.Ignored
}

export function isApiLoaded(store: Store, apiName: string) {
  return store.select(
    getSelectorForSpecificApi(apiName)
  ).pipe(
    map(state => isApiStateLoaded(state))
  )
}

export function isApiLoadedById(store: Store, id: number) {
  return store.select(
    getSelectorForSpecificApiId(id)
  ).pipe(
    map(state => isApiStateLoaded(state))
  )
}

export const selectSharedDataLoaded = createSelector(
  selectSharedState,
  (sharedState) => sharedState.sharedData.loaded
);
