<div #widget class="widget combo-chart" *ngIf="data"
  [ngClass]="{'widget--css': gridItemType === 'css', 'widget--gridster':gridItemType === 'gridster' }">
  <twng-gridster-item-overlay *ngIf="editingGridster"></twng-gridster-item-overlay>
  <div class="header tw-border-none" [class.pointer-events--none]="editingGridster">
    <twng-chart-label [label]="label" [chart]="chart"
      [chartInfo]="(dataSources && dataSources[dataSource]) ? dataSources[dataSource].description: null">
    </twng-chart-label>
    <div class="header-actions" data-html2canvas-ignore>
      <button type="actions-button" class="actions-button actions-button--copy-to-clipboard" [ngbTooltip]="copyTooltip"
        triggers="mouseenter:mouseleave" aria-label="Copy to Clipboard" [disabled]="copyingToClipboard"
        (click)="copyChartToClipboard(widget, legendInCopiedImg, filtersInCopiedImg)">
        <i *ngIf="!copyingToClipboard" class="far fa-copy"></i>
        <i *ngIf="copyingToClipboard" class="fas fa-sync fa-spin"></i>
      </button>
      <twng-chart-drop-down-menu [chart]="chart" [label]="label" [chartType]="chartType" [chartFilters]="chartFilters"
        [dataSource]="dataSource" [hideFilters]="true" [dataSourceParameters]="dataSourceParameters" [chartStatus]="status" *ngIf="!chartFiltersReadOnly">
      </twng-chart-drop-down-menu>
    </div>
  </div>

  <div [class.pointer-events--none]="editingGridster" class="body body--no-filters">
    <div class="content-overflow js-content-overflow--horizontal">
      <div class="ngx-responsive-wrapper" dimensionMonitor
          (heightChanged)="heightChanged($event)" (widthChanged)="widthChanged($event)">
        <ngx-charts-combo *ngIf="bars && view" [view]="view" [results]="bars" [lineChart]="lines"
          [scheme]="colorScheme" [colorSchemeLine]="lineColorScheme" [yAxisLabel]="label"
          [customColors]="customColors" [showGridLines]="true" [xAxis]="true" [yAxis]="true"
          (select)="sliceClicked($event)" [legend]="true" [legendPosition]="legendPosition" [animations]="false">
        </ngx-charts-combo>
      </div>
    </div>
  </div>

  <div class="filters" [class.pointer-events--none]="editingGridster"
    *ngIf="chart && chartFilters && gridItemType === 'gridster'" data-html2canvas-ignore>
    <twng-chart-filters [filters]="chartFilters" [filtersEnabled]="filtersEnabled" [chart]="chart"
      [chartFiltersReadOnly]="chartFiltersReadOnly" [overrideFiltersSet]="overrideFiltersSet">
    </twng-chart-filters>
  </div>
</div>

<ng-template #copyTooltip>
  <div>Copy to Clipboard</div>
  <i class="fas fa-flask"></i>&nbsp;
  <span>Chrome only</span>
</ng-template>
