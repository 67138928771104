import { Subscription } from 'rxjs'

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'

import { SortingOptionDirection, SortingOptionType, SortingOptions } from '../models/sorting-options'

@Component({
  selector: 'twng-sort-job',
  template: `
    <form class="form-inline input-group-sm tw-flex" [formGroup]="sortingForm">
      <span>Sort by</span>
      <select class="twng-select tw-cursor-pointer" formControlName="sortingOptionType">
        <option *ngFor="let sortType of (sortingOptionTypes || defaultSortingOptionTypes)" value="{{sortType}}">{{ sortType }}</option>
      </select>
      <div class="input-group tw-w-fit">
        <button class="btn btn-light btn-sm" (click)="changeSortingDirection()">
          <i class="fas fa-caret-{{isSortingDirectionAscending() ? 'down' : 'up'}}"></i>
        </button>
      </div>
    </form>
  `,
  styles: [
    `
      select {
        width: 120px !important;
        outline: none;
        margin-right: 4px;
      }

      span {
        margin: 0.3rem;
        font-size: 13px;
        font-weight: 400;
        /* $tw--gray-700 */
        color: #767676;
        text-transform: uppercase;
        user-select: none;
      }

    `,
  ],
})
export class SortJobsComponent implements OnInit, OnDestroy {

  defaultSortingOptionTypes: SortingOptionType[] =
    [ SortingOptionType.Name, SortingOptionType.Priority, SortingOptionType.EmploymentType]

  sortingForm: UntypedFormGroup
  sortingFormSub: Subscription

  @Input()
    sortingOptions: SortingOptions

  @Input()
    sortingOptionTypes: SortingOptionType[]

  @Output()
    sortingChange = new EventEmitter<SortingOptions>()

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.sortingForm = this.formBuilder.group({
      sortingOptionType: this.sortingOptions.type,
      sortingOptionDirection: this.sortingOptions.direction,
    })

    this.sortingFormSub = this.sortingForm.valueChanges
      .subscribe(formValues => this.sortingChange.emit({
        type: formValues.sortingOptionType,
        direction: formValues.sortingOptionDirection
      }))
  }

  get formSortingDirection(): SortingOptionDirection {
    return this.sortingForm.value.sortingOptionDirection
  }

  changeSortingDirection() {
    const sortingDirection =
      this.formSortingDirection === SortingOptionDirection.Ascending ?
        SortingOptionDirection.Descending :
        SortingOptionDirection.Ascending
    this.sortingForm.controls.sortingOptionDirection.setValue(sortingDirection)
  }

  isSortingDirectionAscending() {
    return this.formSortingDirection === SortingOptionDirection.Ascending
  }

  ngOnDestroy(): void {
    this.sortingFormSub.unsubscribe()
  }
}
