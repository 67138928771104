import { EMPTY, combineLatest } from 'rxjs'
import { catchError, concatMap, map, take, tap } from 'rxjs/operators'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { CandidatesService } from '../../wall/services/candidates.service'
import {
  CardDetailsModalContainerComponent
} from '../../card-details/components/card-details-modal-container.component'
import { OpenCandidateCardModal, ProspectsActionsTypes } from '../actions/prospects.actions'
import { ProspectCandidate } from '../models/prospects-candidate.model'
import { ProspectJobApplication } from '../models/prospects-job-application.model'
import { ProspectsService } from '../services/prospects.service'
import { atsId } from '../../wall/models/types'

@Injectable({ providedIn: 'root' })
export class CandidateModalEffects {
  modalRef: NgbModalRef

  constructor(
    private actions$: Actions,
    private modalService: NgbModal,
    private prospectsService: ProspectsService,
    private candidatesService: CandidatesService) {
  }

  showCandidateModal$ = createEffect(() => this.actions$.pipe(
    ofType(ProspectsActionsTypes.OpenCandidateCardModal),
    map((action: OpenCandidateCardModal) => action.payload.jobApplicationId),
    concatMap((prospectJobApplicationId: atsId) =>
      this.prospectsService.getJobApplicationById(prospectJobApplicationId).pipe(
        tap(prospectJobApplication => {
          if (!prospectJobApplication) {
            throw new Error(`NO prospectJobApplication with ID: ${prospectJobApplicationId}`)
          }
        }),
        concatMap((prospectJobApplication: ProspectJobApplication) => combineLatest([
          this.prospectsService.getCandidateById(prospectJobApplication.candidate_id),
          this.candidatesService.getActivityDayCategories(),
          this.candidatesService.getInterviewsForJobApplication(prospectJobApplication.id),
        ]).pipe(
          tap(([prospectCandidate, activityDayCategories, interviews]) => {
            this.openDetailsModal(prospectCandidate, prospectJobApplication, activityDayCategories, interviews)
          }),
          catchError((e) => {
            console.error(e)
            return EMPTY
          })
        )
        ),
        take(1),
      )),
  ), { dispatch: false })

  private openDetailsModal(
    prospectsCandidate: ProspectCandidate,
    prospectJobApplication: ProspectJobApplication,
    activityDayCategories,
    interviewsAndJobEntities
  ): void {
    this.modalRef = this.modalService.open(CardDetailsModalContainerComponent, {
      size: 'lg',
    })

    this.modalRef.componentInstance.candidate = prospectsCandidate
    this.modalRef.componentInstance.jobApplication = prospectJobApplication
    this.modalRef.componentInstance.activityDayCategories = activityDayCategories
    this.modalRef.componentInstance.interviews = interviewsAndJobEntities
    this.modalRef.componentInstance.showTags = false

    this.modalRef.result
      .then(() => {
        this.modalRef = null
      })
      .catch(() => {
        this.modalRef = null
      })
  }
}
