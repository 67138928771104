import { ActivatedRoute } from "@angular/router"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { DashboardPdfService } from "../../services/dashboard-pdf.service"
import { DashboardTabWithCharts } from "../../models/dashboard-tab"
import { ExportMainDashboardService } from "../../services/export-main-dashboard.service"
import { Observable, Subscription, merge } from "rxjs"
import { Store } from "@ngrx/store"
import { selectAllDashboardTabsWithDashboardCharts } from "../../reducers"

@Component({
  selector: 'twng-shared-custom-dashboard',
  templateUrl: './shared-custom-dashboard.component.html'
})
export class SharedCustomDashboardComponent implements OnInit, OnDestroy {

  tab: DashboardTabWithCharts
  private tabId: number
  private subscriptions = new Subscription()
  exportingPdf$: Observable<boolean>

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    public dashboardPdfService: DashboardPdfService,
    private exportMainDashboardService: ExportMainDashboardService,
  ) {

  }

  get sharedToken() {
    return this.route.snapshot.params.sharedKey
  }

  ngOnInit(): void {
    this.exportingPdf$ = merge(this.dashboardPdfService.exporting$, this.exportMainDashboardService.exporting$)
    this.tabId = parseInt(this.route.snapshot.params.tabId, 10)
    this.tab = {
      charts: [],
      id: this.tabId,
      sharable_token: this.sharedToken,
      name: '',
      position: -1,
    }

    this.subscriptions.add(this.store.select(selectAllDashboardTabsWithDashboardCharts).subscribe(tabs => {
      this.tab = tabs.find(tab => tab.id === this.tabId) || this.tab
    }))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
    // Since there are many hacks done in order to insert shared dashboard tab
    // into store, many bugs appear when we leave this page. The easiest way to
    // solve those problems is to refresh browser.
    setTimeout(() => {
      location.reload()
    }, 50)
  }
}
