import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  TrackFeature,
  TrackFeatureActionsTypes,
  TrackFeatureFailed,
  TrackFeatureSuccess
} from '../actions/track-features.actions'
import { apiHost, getHttpPostOptions } from '../../core/http-options'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class TrackFeatureEffects {

  constructor(private actions$: Actions,
    private http: HttpClient) {
  }

  trackFeature$ = createEffect(() => this.actions$.pipe(
    ofType<TrackFeature>(TrackFeatureActionsTypes.TrackFeature),
    mergeMap((action) => {
      if (window.twng_demo) {
        return of()
      }
      if (window.twng_impersonating) {
        return of(new TrackFeatureSuccess({featureKey: action.payload.featureKey}))
      }
      const url = `${apiHost}/twng/user_seen_feature`
      return this.http
        .put<void>(
        url,
        {
          params: {
            feature: action.payload.featureKey
          }
        },
        getHttpPostOptions(),
      )
        .pipe(
          map(() => new TrackFeatureSuccess(action.payload)),
          catchError(() => of(new TrackFeatureFailed(action.payload)))
        )
    })
  ))
}
