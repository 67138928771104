import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'

import { Candidate } from '../models/candidate'
import { CardDetailsActionTypes, CardDetailsActions } from '../actions/card-details.actions'
import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import {
  ToggleTagActionTypes, ToggleTypeActions
} from '../../card-details/actions/toggle-tag.actions'
import { WallActionTypes, WallActions } from '../actions/wall.actions'

export type State = EntityState<Candidate>

export const adapter: EntityAdapter<Candidate> = createEntityAdapter<
Candidate
>()

export const initialState: State = adapter.getInitialState()

export function candidateFromApiResponse(apiRepresentation): Candidate {
  // convert to date objects
  return {
    ...apiRepresentation,
    name: `${apiRepresentation.first_name} ${apiRepresentation.last_name}`,
    // ensure string ID
    id: `${apiRepresentation.id}`
  }
}

export function reducer(
  state = initialState,
  action: LoaderActions | CardDetailsActions | ToggleTypeActions | WallActions,
): State {
  switch (action.type) {
    case WallActionTypes.FetchWallDataPaginatedSuccessAction:
    case WallActionTypes.FetchWallDataPaginatedTabSuccessAction: {
      const candidates = (action.payload.candidates || []).map(candidate =>
        candidateFromApiResponse(candidate),
      )
      return adapter.addMany(candidates, state)
    }

    // NOTE: this makes interviews not show the first time for most candidates (e.g. Ivanna)
    case CardDetailsActionTypes.FetchCardDetailsSuccess: {
      const candidate = candidateFromApiResponse(action.payload.candidate)
      return adapter.upsertOne(candidate, state)
    }

    case LoaderActionTypes.UpdateFromServer: {
      if (!action.payload.candidates) {
        return state
      }
      const candidates = action.payload.candidates.map(candidate =>
        candidateFromApiResponse(candidate),
      )
      return adapter.upsertMany(candidates, state)
    }

    case ToggleTagActionTypes.AddTag: {
      const candidate = action.payload.candidate
      const tag = action.payload.tagName
      return adapter.updateOne(
        {
          id: candidate.id,
          changes: {
            tags: action.payload.candidate.tags.concat([tag]),
          },
        },
        state,
      )
    }

    case ToggleTagActionTypes.AddTagFailure: {
      const candidate = action.payload.candidate
      const tag = action.payload.tagName
      return adapter.updateOne(
        {
          id: candidate.id,
          changes: {
            tags: action.payload.candidate.tags.filter(t => t !== tag),
          },
        },
        state,
      )
    }

    case ToggleTagActionTypes.RemoveTag: {
      const candidate = action.payload.candidate
      const tag = action.payload.tagName
      return adapter.updateOne(
        {
          id: candidate.id,
          changes: {
            tags: action.payload.candidate.tags.filter(t => t !== tag),
          },
        },
        state,
      )
    }

    case ToggleTagActionTypes.RemoveTagFailure: {
      const candidate = action.payload.candidate
      const tag = action.payload.tagName
      return adapter.updateOne(
        {
          id: candidate.id,
          changes: {
            tags: action.payload.candidate.tags.concat([tag]),
          },
        },
        state,
      )
    }

    default:
      return state
  }
}
