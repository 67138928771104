/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'
import { ExternalUser } from '../../wall/models/external-user'
import { atsId } from '../../wall/models/types'

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum NotesUIActionTypes {
  UpdateNotesUI = '[NotesUI] UpdateNotesUI',
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */

export class UpdateNotesUI implements Action {
  readonly type = NotesUIActionTypes.UpdateNotesUI
  constructor(
    public payload: {
      candidate_id: atsId
      body?: string
      isPublic?: boolean
      mentionedUsers?: ExternalUser[]
    },
  ) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type NotesUIActions = UpdateNotesUI
