import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { escapeRegExp } from 'lodash-es'

@Pipe({ name: 'highlightHtml' })
export class HighLightHtmlPipe implements PipeTransform {
  transform(text: string, search): string {
    if (!search) {
      return text
    }
    const pattern = escapeRegExp(search)
    const regex = new RegExp(pattern, 'gi')

    return text.replace(regex, (match) => `<span class="highlight">${match}</span>`)
  }
}
