import { Observable, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { CustomField, CustomFieldProps } from '../models/custom-field'
import { CustomFieldFilterOption } from '../models/custom-field-filter-option'
import { CustomFieldsState, createCustomFieldFilterOptionId, createCustomFieldFilterOptionName } from '../reducers'
import { SelectedCustomFieldsFilter } from '../models/selected-custom-fields-filter'
import { getCandidateCustomFields, getOfferCustomFields, getUnexcludedCustomFields,
  selectCandidateFilterOptions, selectOfferFilterOptions, selectUnexcludedFilterOptions
} from '../selectors'
import { objKeysSafe } from '../../shared/utils/general-utils'

export type PossibleCustomFieldSource = 'job' | 'candidate' | 'offer'

@Injectable({ providedIn: 'root' })
export class CustomFieldsService {

  constructor(private store: Store<CustomFieldsState>) {
  }

  getCustomFields(type: PossibleCustomFieldSource = 'job'): Observable<CustomFieldProps[]> {
    if (type === 'job') {
      return this.store.select(getUnexcludedCustomFields)
    } else if (type === 'candidate') {
      return this.store.select(getCandidateCustomFields)
    } else if (type === 'offer') {
      return this.store.select(getOfferCustomFields)
    } else {
      throw new Error('Unimplemented custom field type: ' + type)
    }
  }

  getAllCustomFields(): Observable<CustomFieldProps[]> {
    return combineLatest([this.getCustomFields('job'), this.getCustomFields('candidate')]).pipe(
      map(([cf, ccf]) => [...cf, ...ccf])
    )
  }

  getFilterOptions(type: PossibleCustomFieldSource = 'job'): Observable<CustomFieldFilterOption[]> {
    if (type === 'job') {
      return this.store.select(selectUnexcludedFilterOptions)
    } else if (type === 'candidate') {
      return this.store.select(selectCandidateFilterOptions)
    } else if (type === 'offer') {
      return this.store.select(selectOfferFilterOptions)
    } else {
      throw new Error('Unimplemented custom field type: ' + type)
    }
  }

  /**
   * Given the selected CustomFieldsFilterOptions selected on the dashboard, transform it
   * to the filter object to be saved in the backend.
   *
   * @param customFieldFilterOption
   */
  mapToDashboardCustomFieldsFilter(customFieldFilterOption: CustomFieldFilterOption[]): SelectedCustomFieldsFilter[] {
    const dashboardCustomFieldsFiltersMap = customFieldFilterOption
      .reduce((accum, filterOption: CustomFieldFilterOption) => {
        const id = filterOption.customFieldId
        accum[id] = accum[id] || []
        accum[id].push(filterOption.customOptionValue)
        return accum
      }, {})

    return objKeysSafe(dashboardCustomFieldsFiltersMap).map((customFieldId): SelectedCustomFieldsFilter => ({
      id: customFieldId,
      values: dashboardCustomFieldsFiltersMap[customFieldId]
    }))
  }

  private findCustomField(customFields: CustomFieldProps[], customFieldId: string): CustomFieldProps {
    return customFields.find(filterOption => filterOption.id === customFieldId)
  }

  mapToCustomFieldFilterOptions(selectedCustomFieldsFilters: SelectedCustomFieldsFilter[]):
  Observable<CustomFieldFilterOption[]> {
    return this.getAllCustomFields().pipe(
      map((customFieldsFilterOptions) => selectedCustomFieldsFilters.flatMap(dashboardCustomFieldFilter => {
        const customFieldFilterOption =
          this.findCustomField(customFieldsFilterOptions, dashboardCustomFieldFilter.id)

        if (!customFieldFilterOption) {
          return null
        }

        return dashboardCustomFieldFilter.values
          // Check if saved option value still exists in the current Custom Field Options
          .filter(value => customFieldFilterOption.custom_field_options.indexOf(value) !== -1)
          // Map to CustomFieldFilterOption
          .map((value): CustomFieldFilterOption => ({
            id: createCustomFieldFilterOptionId(customFieldFilterOption.id, value),
            name: createCustomFieldFilterOptionName(customFieldFilterOption.name, value),
            customFieldId: customFieldFilterOption.id,
            customOptionValue: value,
            excludedFromFilters: customFieldFilterOption.excluded_from_filters
          }))
      })),
      // Filter null values por possibles not found custom fields
      map(customFieldsFilterOptions => customFieldsFilterOptions
        .filter(customFieldFilterOption => !!customFieldFilterOption)),
    )
  }

  /**
   * Maps SelectedCustomFieldsFilter (from a Tab or select) to a valid display string
   *
   * @param selectedCustomFieldsFilters
   */
  mapSelectedFilterOptionsToStrings(selectedCustomFieldsFilters: SelectedCustomFieldsFilter[]): Observable<string[]> {
    return this.getAllCustomFields().pipe(
      map((customFields: CustomField[]) => selectedCustomFieldsFilters.flatMap(selectedCustomField => {
        const customField = customFields.find(cf => cf.is(selectedCustomField.id))
        if (customField) {
          return selectedCustomField.values.map(value => createCustomFieldFilterOptionName(customField.name, value))
        } else {
          return ""
        }
      })),
    )

  }

}
