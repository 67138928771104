import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { PdfComponentTrackerService } from '../../services/pdf-component-tracker.service'
import { ProjectHiresColors } from '../../../wall/models/executive-dashboard'
import { RecommendationSource, SingleJobProjectedHires } from '../../../wall/models/projected-hires'
import { Store } from '@ngrx/store'
import { countBy, values } from 'lodash-es'
import { getProjectedHiresForJob, selectExecutiveDashboardConfig } from '../../../wall/reducers'
import { loadProjectedHiresIfNecessary } from '../../utils/store.utils'
import { objKeysSafe } from '../../utils/general-utils'

enum ProjectionType {
  AllCustom, AllRecommended, MixCustomRecommended
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-projected-hires-number',
  templateUrl: './projected-hires-number.component.html',
  styleUrls: ['./projected-hires-number.component.scss'],
  providers: [PdfComponentTrackerService],
})
export class ProjectedHiresNumberComponent implements OnInit, OnDestroy {

  @Input() jobId: string

  projectedHiresNumber: string
  projectedHires$: Observable<SingleJobProjectedHires>

  projectionType: ProjectionType

  subs = new Subscription()
  projectHiresValueColors: ProjectHiresColors
  private subscription = new Subscription()

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private pdf: PdfComponentTrackerService,
  ) { }

  ngOnInit(): void {
    loadProjectedHiresIfNecessary(this.store)
    this.subs.add(this.store.select(selectExecutiveDashboardConfig)
      .subscribe(config => {
        this.projectHiresValueColors = {
          redMaxValue: config.projected_hires_red_threshold,
          yellowMaxValue: config.projected_hires_yellow_threshold,
          useGreen: config.projected_hires_use_green_instead_of_gray
        }
      }))

    this.projectedHires$ = this.store.select(getProjectedHiresForJob(this.jobId))
    this.subscription.add(
      this.projectedHires$.subscribe(data => {
        if (data?.final_projected_hires !== null && data?.final_projected_hires !== undefined) {
          this.pdf.componentIsReady()
          if (!isNaN(data.final_projected_hires)) {
            this.projectedHiresNumber = data.final_projected_hires.toFixed(2)
            this.projectionType = this.getTypeConfiguration(data)
          } else {
            this.projectedHiresNumber = "?"
          }
          this.cd.markForCheck()
        }
      })
    )
  }

  allRecommendedRates() {
    if (this.projectionType === ProjectionType.AllRecommended) {
      return true
    } {
      return false
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  getProjectHiresColorClass() {
    const colors = this.projectHiresValueColors
    const projectHiresValue = parseFloat(this.projectedHiresNumber)

    if (colors?.yellowMaxValue && projectHiresValue > colors.yellowMaxValue) {
      if(colors?.useGreen) {
        return 'column-green'
      } else {
        return ''
      }
    }

    if (colors?.yellowMaxValue && projectHiresValue <= colors.yellowMaxValue
      && projectHiresValue > colors.redMaxValue ) {
      return "column-yellow"
    }

    if (colors?.redMaxValue && projectHiresValue <= colors.redMaxValue) {
      return "column-red"
    }
  }

  private getTypeConfiguration(projectedHiresValue: SingleJobProjectedHires): ProjectionType {
    const jobStages = values(projectedHiresValue.stage_projection_info)
    const typeCounts = countBy(jobStages, 'recommendation_source')
    const customType: RecommendationSource = 'user_custom'
    if (typeCounts[customType] === 0 || typeCounts[customType] === undefined) {
      return ProjectionType.AllRecommended
    } else if (typeCounts[customType] === objKeysSafe(projectedHiresValue.stage_projection_info).length) {
      return ProjectionType.AllCustom
    } else {
      return ProjectionType.MixCustomRecommended
    }
  }
}
