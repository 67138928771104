import { Action, Store } from "@ngrx/store"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { ApiActionTypes, ApiMatcher, ApiStatus, CallApi,
  SetApiStatus, isOverwritableApiStatus
} from "../actions/api.actions"
import { Injectable } from "@angular/core"
import { first, map, mergeMap } from "rxjs/operators"
import { getSelectorForSpecificApi, selectApi } from "../state/selectors"
import { merge, of } from "rxjs"
import { objKeysSafe } from "../utils/general-utils"
import { selectImmediatelySync } from "../utils/store.utils"

interface ApiCallReturn {
  originalAction: CallApi,
  successOrFailureAction?: Action,
  apiStatusOverride?: ApiStatus,
}

@Injectable({
  providedIn: 'root'
})
export class ApiEffects {
  constructor(private actions$: Actions, private store: Store) { }

  callApi$ = createEffect(() => this.actions$.pipe(
    ofType(ApiActionTypes.CallApi),
    mergeMap((action: CallApi) => {
      const canProceed = this.canApiProceed(action)
      if (canProceed) {
        this.dispatchApiStart(action)
        const successObservable = this.actions$.pipe(ofType(action.payload.successAction))
        const failureObservable = this.actions$.pipe(ofType(action.payload.failureAction))
        return merge(successObservable, failureObservable).pipe(
          first(),
          map(successOrFailureAction => {
            const ret: ApiCallReturn = {
              originalAction: action,
              successOrFailureAction
            }
            return ret
          })
        )
      } else {
        if (typeof (canProceed) === 'string') {
          const ret: ApiCallReturn = {
            originalAction: action,
            apiStatusOverride: canProceed
          }
          return of(ret)
        } else {
          return of()
        }
      }
    }),
    map((payload: ApiCallReturn | undefined) => {
      if (payload) {
        let newApiStatus = payload.apiStatusOverride
        if (!newApiStatus) {
          newApiStatus = payload.successOrFailureAction.type === payload.originalAction.payload.successAction ?
            ApiStatus.Success : ApiStatus.Failed
        }
        return new SetApiStatus({
          apiName: payload.originalAction.payload.apiName,
          newApiStatus,
          id: payload.originalAction.payload.id,
        })
      }  else {
        return
      }
    })
  ))

  // returns true/false, or it returns ApiStatus which will override current ApiStatus for this api
  private canApiProceed(action: CallApi): boolean | ApiStatus {
    if (action.payload.doAlways) {
      return true
    }
    const currentState = selectImmediatelySync(this.store, getSelectorForSpecificApi(action.payload.apiName))
    if (!isOverwritableApiStatus(currentState?.status)) {
      return false
    }
    // check if we should ignore this call based on other calls' statuses
    if (action.payload.ignoreIf) {
      const otherApiCalls = this.matchApiCalls(action.payload.ignoreIf)
      for (const otherApiCall of otherApiCalls) {
        const state = selectImmediatelySync(this.store, getSelectorForSpecificApi(otherApiCall))
        if (!isOverwritableApiStatus(state.status)) {
          return ApiStatus.Ignored
        }
      }
    }
    return true
  }

  private dispatchApiStart(action: CallApi) {
    this.store.dispatch(new SetApiStatus({
      apiName: action.payload.apiName,
      newApiStatus: ApiStatus.Sent,
      id: action.payload.id,
    }))
    this.store.dispatch(action.payload.startAction)
  }

  private matchApiCalls(match: ApiMatcher): string[] {
    const stringMatchers: string[] = []
    const regexMatchers: RegExp[] = []
    if (!Array.isArray(match)) {
      match = [match]
    }
    for (const matcher of match) {
      if (typeof matcher === 'string') {
        stringMatchers.push(matcher)
      } else {
        regexMatchers.push(matcher)
      }
    }

    const ret: string[] = []
    const state = selectImmediatelySync(this.store, selectApi)
    const keys = objKeysSafe(state)
    for (const key of keys) {
      let shouldContinue = false
      for (const stringMatcher of stringMatchers) {
        if (stringMatcher !== key) {
          shouldContinue = true
          continue
        }
      }
      for (const regexMatcher of regexMatchers) {
        if (!key.match(regexMatcher)) {
          shouldContinue = true
          continue
        }
      }
      if (shouldContinue) {
        continue
      }
      ret.push(key)
    }
    return ret
  }
}
