import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Tab } from '../models/tab'
import { TabActionTypes, TabActions } from '../actions/tabs.actions'

import { CoreTabActions, CoreTabActionsTabActionTypes } from '../../core/actions/tab.actions'
import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { TabType } from '../../core/models/tab.type'

export type State = EntityState<Tab>

export function sortByPosition(a: Tab, b: Tab): number {
  return a.position - b.position
}

export const adapter: EntityAdapter<Tab> = createEntityAdapter<Tab>({
  // Sort by name until we allow reordering by position
  sortComparer: sortByPosition,
})

export const initialState: State = adapter.getInitialState()

const wallTabType: TabType = 'wall'

export function reducer(
  state = initialState,
  action: LoaderActions | TabActions | CoreTabActions,
): State {
  switch (action.type) {
    case LoaderActionTypes.WallSharedSuccess: {
      if (action.payload.tabs) {
        return adapter.setAll(action.payload.tabs, state)
      }
      return state
    }

    case TabActionTypes.CreateTabSuccess: {
      return adapter.addOne(action.payload.tab, state)
    }

    case TabActionTypes.CreateTabFailure: {
      return adapter.removeOne(action.payload.tab.id, state)
    }

    case CoreTabActionsTabActionTypes.ReorderTabs: {
      if (action.payload.type !== wallTabType) {
        return state
      }
      return adapter.upsertMany(action.payload.tabs as Tab[], state)
    }

    case CoreTabActionsTabActionTypes.ReorderTabsFailure: {
      if (action.payload.type !== wallTabType) {
        return state
      }
      return adapter.upsertMany(action.payload.oldTabs as Tab[], state)
    }

    case TabActionTypes.UpdateTab: {
      return adapter.upsertOne(action.payload.tab, state)
    }

    case TabActionTypes.UpdateTabFailure: {
      return adapter.upsertOne(action.payload.oldTab, state)
    }

    case TabActionTypes.DeleteTabSuccess: {
      return adapter.removeOne(action.payload.tab.id, state)
    }

    default: {
      return state
    }
  }
}
