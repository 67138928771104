import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core'

import { CandidatesInStageBackendFilters } from '../../actions/candidates-in-stage.actions'
import {
  DepartmentFilterComponent
} from '../../../shared/components/filters/department-filter/department-filter.component';
import { FilterOption } from '../../../shared/components/filters/generic-filter/filter-option.interface';
import {
  JobStagesFilterComponent
} from '../../../shared/components/filters/job-stages-filter/job-stages-filter.component';
import { OfficeFilterComponent } from '../../../shared/components/filters/office-filter/office-filter.component';
import { Store } from '@ngrx/store'
import { UserFilterComponent } from '../../../shared/components/filters/user-filter/user-filter.component';
import { cloneDeep } from 'lodash-es'
import { selectCandidatesInStage } from '../../selectors/candidates-in-stage.selectors'
import { selectImmediatelySync } from '../../../shared/utils/store.utils'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-candidates-in-stage-filters',
  templateUrl: './candidates-in-stage-filters.component.html',
  styleUrls: ['./candidates-in-stage-filters.component.scss']
})
export class CandidatesInStageFiltersComponent {

  filters: CandidatesInStageBackendFilters
  defaultFilterValues: CandidatesInStageBackendFilters

  @ViewChild('jobStagesFilter') jobStageFilter: JobStagesFilterComponent
  @ViewChild('departmentsFilter') departmentFilter: DepartmentFilterComponent
  @ViewChild('officesFilter') officeFilter: OfficeFilterComponent
  @ViewChild('usersFilter') userFilter: UserFilterComponent

  @Output()
    filtersChanged = new EventEmitter<CandidatesInStageBackendFilters>()

  constructor(store: Store) {
    this.defaultFilterValues = selectImmediatelySync(store, selectCandidatesInStage).filters
    this.createFilters()
  }

  createFilters() {
    this.filters = cloneDeep(this.defaultFilterValues)
  }

  filterChange(filterName: keyof CandidatesInStageBackendFilters, filterOptions: FilterOption[]) {
    this.filters[filterName] = filterOptions.map(value => value.id as string)
  }

  submit() {
    this.emitChange()
  }

  reset() {
    this.createFilters()
    this.jobStageFilter.resetTo(['Offer'])
    this.departmentFilter.reset()
    this.officeFilter.reset()
    this.userFilter.reset()
    setTimeout(() => this.emitChange(), 50)
  }

  emitChange() {
    this.filtersChanged.emit({ ...this.filters })
  }

}
