export enum SortingDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type ComparableSortingType = string | number | boolean
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ComparatorFn = (a: any, b: any) => -1 | 0 | 1

export type ComparatorReturnType = ReturnType<ComparatorFn>
