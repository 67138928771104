<div class="candidates-in-stage-filters">
  <form class="form-row">
    <div class="col">
      <twng-job-stages-filter
        #jobStagesFilter
        [jobStageNames]="defaultFilterValues.job_stage_names"
        (filterChange)="filterChange('job_stage_names', $event)">
      </twng-job-stages-filter>
    </div>
    <div class="col">
      <twng-department-filter
        #departmentsFilter
        [departmentIds]="defaultFilterValues.department_ids"
        (filterChange)="filterChange('department_ids', $event)">
      </twng-department-filter>
    </div>
    <div class="col">
      <twng-office-filter
        #officesFilter
        [officeIds]="defaultFilterValues.office_ids"
        (filterChange)="filterChange('office_ids', $event)">
      </twng-office-filter>
    </div>
    <div class="col">
      <twng-user-filter
        #usersFilter
        [userIds]="defaultFilterValues.user_ids"
        (filterChange)="filterChange('user_ids', $event)">
      </twng-user-filter>
    </div>
  </form>
  <div class="candidates-in-stage-filters__buttons tw-flex tw-justify-end">
    <button
      type="button"
      class="btn btn-light tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-cq-gray-600 tw-border tw-border-cq-gray-300 tw-bg-[#FFFFFF] hover:tw-bg-cq-gray-50 tw-hover:text-cq-gray-700 tw-text-sm tw-p-3"
      (click)="reset()">
      Reset
    </button>
    <button
      type="submit"
      class="button--primary button--small tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-white tw-bg-cq-primary-600 tw-text-sm tw-p-3"
      (click)="submit()">
      Update
    </button>
  </div>
</div>
