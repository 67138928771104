import { Observable, Subscription, combineLatest, merge, timer } from 'rxjs'
import { delay, distinctUntilChanged, first, map, mapTo, switchMap, switchMapTo, tap } from 'rxjs/operators'

import { Actions, ofType } from '@ngrx/effects'
import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { Store } from '@ngrx/store'

import { ActivatedRoute, Router } from '@angular/router'
import { CacheService } from '../../../wall/services/cache.service'
import { ChartsInitialConfig } from '../../../core/actions/loader.actions'
import { ComponentStateService } from '../../../core/services/component-state.service'
import { CreateExecutiveDashboardTab, CreateExecutiveDashboardTabSuccess,
  DeleteExecutiveDashboardTab, ExecutiveDashboardActionsTypes, FetchExecutiveDashboardCharts,
  FetchExecutiveDashboardClosedJobs, FetchJobIdsForExecutiveDashboardTab,
  FetchJobStatuses, SendJobStatusUpdateSuccess, UpdateExecutiveDashboardTab,
  UpdateExecutiveDashboardTabHideSection} from '../../../wall/actions/executive-dashboard.actions'
import { DepartmentService } from '../../../wall/services/department.service'
import {
  EditExecutiveDashboardColumnsModalComponent
} from '../edit-executive-dashboard-columns-modal/edit-executive-dashboard-columns-modal.component'
import { ExecutiveDashboardJobFilters, } from '../../../wall/reducers/layout.reducer'
import { ExecutiveDashboardService } from '../../services/executive-dashboard.service'
import {
  ExecutiveDashboardTab,
  HiddenSections,
  getTabFromUrlParam$,
  isOneOfOpenJobsTab as isOneOfOpenJobsTabFunc
} from '../../../wall/models/executive-dashboard'
import { ExportExecDashToPdfService } from '../../services/export-exec-dash-to-pdf.service'
import { Job } from '../../../wall/models/job'
import { LoadStageMappings } from '../../../stage-mappings/actions/stage-mappings.actions'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { OfficeService } from '../../../wall/services/office.service'
import {
  ReorderExecDashColumnsModalComponent
} from '../reorder-exec-dash-columns-modal/reorder-exec-dash-columns-modal.component'
import { SegmentService } from '../../../core/services/segment.service'
import { SlackModalService } from '../../../shared/services/slack-modal.service'
import { SortingOptionType, SortingOptions } from '../../../wall/models/sorting-options'
import { ToastrService } from 'ngx-toastr'
import { UpdateExecutiveDashboardSortingOptions } from '../../../wall/actions/layout.actions'
import { WidgetLibraryTabData } from '../../../dashboard/reducers/analytics.reducer'
import { daysOpen } from '../../../shared/utils/job-utils'
import { exportCsv, objKeysSafe } from '../../../shared/utils/general-utils'
import { format } from 'date-fns'
import {
  getAllJobProjectedHires,
  getProjectedHiresNumberForJob,
  selectAllJobs,
  selectClosedJobStageConversionRates,
  selectExecutiveDashboardClosedJobs,
  selectExecutiveDashboardConfig,
  selectExecutiveDashboardLastFetchedJobIds,
  selectExecutiveDashboardLoaded,
  selectExecutiveDashboardSortingOptions,
  selectJobIds,
  selectJobStageConversionRates,
  selectOffersPerClosedJobExecDash,
  selectOffersPerJobExecDash,
  selectPerJobConfiguration,
  selectRecentCandidatesPerJobExecDash,
} from '../../../wall/reducers'
import { getCustomFieldsAsMap } from '../../../custom-fields/selectors'
import { initWallIfNecessary, observableImmediatelySync,
  selectImmediatelySync, toasterOnAction
} from '../../../shared/utils/store.utils'
import { intersection, isEqual, omit } from 'lodash-es'
import { selectAppConfig, selectUser, selectWallLoadCompleted
} from '../../../reducers'
import { selectChartsInitConfig, selectWidgetLibraryTab } from '../../../dashboard/reducers'

const VISIBLE_JOBS_PER_PAGE = 10

@Component({
  selector: 'twng-executive-dashboard-content',
  templateUrl: './executive-dashboard-content.component.html',
  styleUrls: [
    './executive-dashboard-content.component.scss',
    '../../../dashboard/components/dashboard.layout.scss',
  ],
  providers: [ComponentStateService],
})
export class ExecutiveDashboardContentComponent implements OnInit, OnDestroy {

  @ViewChildren('.job-table', { read: ElementRef }) tables: QueryList<HTMLElement>

  constructor(
    private store: Store,
    private actions: Actions,
    private modal: NgbModal,
    private exportExecDashToPdfService: ExportExecDashToPdfService,
    private segmentService: SegmentService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private cache: CacheService,
    private departments: DepartmentService,
    private offices: OfficeService,
    private slackModal: SlackModalService,
  ) {
  }

  isSavingSharedTab: boolean

  @Input()
    isSharedTab = false

  isDemo = !!window.twng_demo
  analyticsData$: Observable<WidgetLibraryTabData>
  subs = new Subscription()
  jobIds$: Observable<string[] | null>
  isAdmin: boolean
  hasAccessToExecDash: boolean
  sortingOptions$: Observable<SortingOptions>
  numberOfVisibleJobs = VISIBLE_JOBS_PER_PAGE

  sortingOptionsTypes: SortingOptionType[] = window.twng_demo ? [
    SortingOptionType.Name,
  ] : [
    SortingOptionType.Name,
    SortingOptionType.ColorStatus,
    SortingOptionType.DaysOpen,
    SortingOptionType.Openings,
    SortingOptionType.Hires,
  ]

  basicDataLoaded$: Observable<boolean>
  allDataLoadedForDownload$: Observable<boolean>

  // Pass this function to HTML, so it can be called from there
  exportingPdf$: Observable<boolean>

  isOneOfOpenJobsTab$: Observable<boolean>
  isOneOfOpenJobsTab: boolean

  tabId$: Observable<string>
  tab$: Observable<ExecutiveDashboardTab>
  tab: ExecutiveDashboardTab

  jobFiltersRefresh$: Observable<boolean>

  chartInitConfig: ChartsInitialConfig

  hidden: HiddenSections[]
  hiddenSections = HiddenSections

  // emitted whenever tab is changed in a way that might cause different job
  // results
  private jobsPotentiallyChangedInTab$: Observable<ExecutiveDashboardTab>

  onScroll(): void {
    if (this.numberOfVisibleJobs < observableImmediatelySync(this.jobIds$).length) {
      this.numberOfVisibleJobs += VISIBLE_JOBS_PER_PAGE
    }
  }

  ngOnInit() {
    this.tabId$ = this.route.paramMap.pipe(
      map(params => params.get('tabId')),
      distinctUntilChanged(),
    )
    this.isOneOfOpenJobsTab$ = this.tabId$.pipe(
      map(tabId => isOneOfOpenJobsTabFunc(tabId)),
      distinctUntilChanged(),
    )
    this.subs.add(this.isOneOfOpenJobsTab$.subscribe(
      val => this.isOneOfOpenJobsTab = val
    ))
    this.tab$ = this.tabId$.pipe(
      switchMap(tabId => getTabFromUrlParam$(tabId, this.store))
    )

    this.subs.add(
      this.tab$.subscribe((tab) => {
        const { hidden_section_ids, ...updatedTab } = tab;

        if (!isEqual(this.tab, updatedTab)) {
          this.tab = updatedTab
        }

        this.hidden = hidden_section_ids ? [...hidden_section_ids] : []
        if (!this.tab) {
          this.redirectToNotFound()
        }
      })
    )

    this.jobFiltersRefresh$ = merge(
      this.tabId$.pipe(mapTo(false)),
      this.tabId$.pipe(switchMapTo(timer(0)), mapTo(true))
    )

    this.jobsPotentiallyChangedInTab$ = this.tab$.pipe(
      // ignore certain properties of tabs when refreshing
      distinctUntilChanged((a, b) => {
        const keysToIgnore: (keyof ExecutiveDashboardTab)[] = [
          'column_order', 'default_tab', 'name', 'position', 'view_job_phases',
          'sharable_token', 'custom_fields_to_show',
          'summary_custom_end_date', 'summary_custom_start_date',
          'summary_date_mode', 'excluded_job_stages',
        ]
        if (isOneOfOpenJobsTabFunc(a) && isOneOfOpenJobsTabFunc(b)) {
          keysToIgnore.push('id')
        }
        keysToIgnore.push(...objKeysSafe(a).filter(key => key.startsWith('show_')) as (keyof ExecutiveDashboardTab)[])
        return isEqual(omit(a, keysToIgnore), omit(b, keysToIgnore))
      })
    )

    // refresh charts
    this.subs.add(this.jobsPotentiallyChangedInTab$.subscribe(() => {
      this.refreshCharts()
      this.numberOfVisibleJobs = VISIBLE_JOBS_PER_PAGE
    }))

    // fetch closed jobs
    this.subs.add(
      this.isOneOfOpenJobsTab$.pipe(
        // fetch closed jobs only once while this page is visible
        first(v => !v)
      ).subscribe(() =>
        this.store.dispatch(new FetchExecutiveDashboardClosedJobs())
      )
    )

    // fetch job ids
    this.subs.add(
      combineLatest([this.store.select(selectExecutiveDashboardSortingOptions), this.jobsPotentiallyChangedInTab$])
        .subscribe(
          ([sortingOptions, tab]) => {
            if (tab) {
              this.store.dispatch(new FetchJobIdsForExecutiveDashboardTab({
                tabId: tab.id,
                direction: sortingOptions.direction,
                orderBy: sortingOptions.type,
                sharableToken: this.isSharedTab ? tab.sharable_token : undefined,
              }))
            }
          }
        )
    )
    this.jobIds$ = this.store.select(selectExecutiveDashboardLastFetchedJobIds)

    this.basicDataLoaded$ = this.tab$.pipe(
      switchMap(tab => {
        const isOneOfOpenJobsTab = isOneOfOpenJobsTabFunc(tab)
        if (isOneOfOpenJobsTab) {
          return combineLatest([
            this.store.select(selectWallLoadCompleted),
            this.store.select(selectExecutiveDashboardLoaded),
          ]).pipe(
            map(([wallLoadCompleted, execDashboardLoaded]) =>
              wallLoadCompleted && execDashboardLoaded && tab !== null
            )
          )
        } else {
          return this.store.select(
            selectExecutiveDashboardClosedJobs
          ).pipe(
            map(v => !!v && tab !== null)
          )
        }
      }))

    this.allDataLoadedForDownload$ = combineLatest([
      this.basicDataLoaded$, this.store.select(getAllJobProjectedHires), this.jobIds$, this.store.select(selectJobIds)
    ]).pipe(map(([allDataLoaded, projectedHires, jobIds, downloadedJobIds]) => {
      if (!jobIds) {
        return false
      }
      const loadedJobProjectedHires = objKeysSafe(projectedHires)
      const allProjectedHiresLoaded = intersection(jobIds, loadedJobProjectedHires).length === jobIds.length
      const allJobsLoaded = intersection(jobIds, downloadedJobIds as string[]).length === jobIds.length
      return allDataLoaded && allProjectedHiresLoaded && allJobsLoaded
    }))

    this.subs.add(
      this.store.select(selectUser).pipe(first()).subscribe(user => {
        this.isAdmin = user.admin
      })
    )

    this.analyticsData$ = this.store
      .select(selectWidgetLibraryTab('executive_dashboard'))

    this.sortingOptions$ = this.store.select(selectExecutiveDashboardSortingOptions)

    initWallIfNecessary(this.store)

    this.subs.add(this.actions.pipe(
      ofType<SendJobStatusUpdateSuccess>(ExecutiveDashboardActionsTypes.SendJobStatusUpdateSuccess)
    ).subscribe(
      () => this.refreshCharts()
    ))

    this.exportingPdf$ = this.exportExecDashToPdfService.exporting$.asObservable()

    this.store.dispatch(new LoadStageMappings())
    this.store.dispatch(new FetchJobStatuses())

    this.hasAccessToExecDash = selectImmediatelySync(this.store, selectAppConfig).feature_flags.can_view_exec_dashboard

    this.subs.add(this.store.select(selectChartsInitConfig).pipe(
      tap(state => this.chartInitConfig = state)
    ).subscribe())
  }

  updateJobFilters(jobFilters: ExecutiveDashboardJobFilters): void {
    this.store.dispatch(new UpdateExecutiveDashboardTab({
      ...this.tab,
      ...jobFilters
    }))
  }

  private refreshCharts() {
    this.store.dispatch(new FetchExecutiveDashboardCharts(this.tab))
  }

  private redirectToNotFound() {
    this.router.navigate(['executive-tools', 'executive-dashboard', 'not_found'])
  }

  sortingOptionsChanged(sortingOption: SortingOptions) {
    this.store.dispatch(new UpdateExecutiveDashboardSortingOptions(sortingOption))
    // restore scroll to how it was before we initiated the request
    const scrollElement = document.scrollingElement
    const previousScrollAmount = scrollElement.scrollTop
    this.subs.add(this.jobIds$.pipe(
      first(Boolean),
      delay(100),
    ).subscribe(() => {
      // does the scroll in browser
      scrollElement.scrollTop = previousScrollAmount
    }))
  }

  trackJobById(_index: number, job: Job) {
    return job ? job.id : null
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  openColumnSettings() {
    const modalRef = this.modal.open(EditExecutiveDashboardColumnsModalComponent)
    const modalInstance = modalRef.componentInstance as EditExecutiveDashboardColumnsModalComponent
    modalInstance.tab = this.tab
  }

  openReorderColumns() {
    const modalRef = this.modal.open(ReorderExecDashColumnsModalComponent)
    const modalInstance = modalRef.componentInstance as ReorderExecDashColumnsModalComponent
    modalInstance.tab = this.tab
  }

  dashboardWidgetClasses() {
    return [
      'dashboard__grid-item',
      `dashboard__grid-item--span-2`,
    ]
  }

  async exportToPDF() {
    const previousNumberOfVisibleJobs = this.numberOfVisibleJobs
    try {
      this.numberOfVisibleJobs = observableImmediatelySync(this.jobIds$).length
      this.segmentService.track('Export Executive Dashboard to PDF')
      await this.exportExecDashToPdfService.exportExecDashboardToPDF(this.tab.name)
      this.numberOfVisibleJobs = previousNumberOfVisibleJobs
    } catch (e) {
      console.error(e)
    }
  }

  jobFilterType() {
    if (this.isOneOfOpenJobsTab) {
      return 'active'
    } else {
      return 'closed'
    }
  }

  deleteTab(tab: ExecutiveDashboardTab): void {
    this.store.dispatch(
      new DeleteExecutiveDashboardTab(tab),
    )
  }

  downloadCSV() {
    const config = selectImmediatelySync(this.store, selectExecutiveDashboardConfig)
    const jobStageConversionRates = selectImmediatelySync(this.store,
      this.isOneOfOpenJobsTab ? selectJobStageConversionRates : selectClosedJobStageConversionRates
    )
    const jobStages = observableImmediatelySync(this.isOneOfOpenJobsTab ?
      this.cache.jobIdsToJobStages$ : this.cache.closedJobIdsToJobStages$
    )
    const jobIdToStatusUpdates = observableImmediatelySync(this.cache.jobIdToStatusUpdates$)
    const jobIdToOffers = selectImmediatelySync(
      this.store, this.isOneOfOpenJobsTab ? selectOffersPerJobExecDash : selectOffersPerClosedJobExecDash
    )
    const jobIdToRecentCandidates = selectImmediatelySync(this.store, selectRecentCandidatesPerJobExecDash)
    const externalUsers = observableImmediatelySync(this.cache.externalUsersById$)
    const perJobConfig = selectImmediatelySync(this.store, selectPerJobConfiguration)
    const customFieldMap = selectImmediatelySync(this.store, getCustomFieldsAsMap)

    const allExecDashData = []
    const currentJobIds = observableImmediatelySync(this.jobIds$)
    const jobs = selectImmediatelySync(this.store, selectAllJobs).filter(job => currentJobIds.includes(job.id))
    const involvedJobStages =
      ExecutiveDashboardService.getInvolvedJobStages(jobs, jobStages, this.tab.excluded_job_stages)
    for (const job of jobs) {
      const execDashRow = {}
      // job name
      execDashRow["Job Name"] = job.name

      // departments
      const departments = observableImmediatelySync(
        this.departments.getDepartments$(job.department_ids)
      )
      execDashRow["Department"] = (departments || []).map(o => o.name).join(', ')

      // job status
      if (this.isOneOfOpenJobsTab) {
        execDashRow["Status"] = ((jobIdToStatusUpdates[job.id] || [])[0] || {}).status || "No status"
      }

      // columns
      for (const col of this.tab.column_order) {

        // days open
        if (this.tab.show_days_open && col.value === "days_open") {
          execDashRow["Days Open"] = daysOpen(job)
        }

        // openings
        if (this.isOneOfOpenJobsTab && this.tab.show_openings && col.value === "openings") {
          execDashRow["Openings"] = job.job_openings.length
        }

        // hires
        if (this.tab.show_hires && col.value === "hires") {
          execDashRow["Hires"] = job.num_hires || "0"
        }

        // projected hires
        if (this.tab.show_proj_hires && col.value === "proj_hires") {
          const projHires = selectImmediatelySync(this.store, getProjectedHiresNumberForJob(job.id))
          if (projHires === null) {
            execDashRow["Projected Hires"] = ""
          } else {
            execDashRow["Projected Hires"] = projHires
          }
        }

        // notes
        if (this.tab.show_notes && col.value === "notes") {
          execDashRow["Notes"] = ((jobIdToStatusUpdates[job.id] || [])[0] || {}).note || ''
        }

        // recruiter
        if (this.tab.show_recruiter && col.value === "recruiter") {
          execDashRow["Recruiter"] = externalUsers[job.recruiter_ids[0]]?.name || ""
        }

        // hiring manager
        if (this.tab.show_hiring_manager && col.value === "hiring_manager") {
          execDashRow["Hiring Manager"] = externalUsers[job.hiring_manager_ids[0]]?.name || ""
        }

        // job id
        if (this.tab.show_job_id && col.value === "job_id") {
          execDashRow["Job ID"] = job.id
        }

        // job opening ids
        if (this.isOneOfOpenJobsTab && this.tab.show_opening_id && col.value === "opening_id") {
          execDashRow["Opening ID"] = job.job_openings.map(jo => jo.opening_id || "?").join(", ") || ""
        }

        // offices
        if (this.tab.show_office && col.value === "office") {
          const offices = observableImmediatelySync(this.offices.getOffices$(job.office_ids))
          execDashRow["Office"] = (offices || []).map(o => o.name).join(', ')
        }

        // total active candidates
        if (this.tab.show_total_active_candidates && col.value === "total_active_candidates") {
          execDashRow["Total Active Candidates"] =
            ExecutiveDashboardService.getTotalActiveCandidates(jobStageConversionRates[job.id])
        }

        // opening date
        if (this.tab.show_opening_date && col.value === "opening_date") {
          execDashRow["Opening Date"] = job.opened_at || ""
        }

        // processed candidates
        if (this.tab.show_candidates_processed && col.value === "candidates_processed") {
          execDashRow["Candidates Processed"] =
            ExecutiveDashboardService.getTotalProcessedCandidates(jobStageConversionRates[job.id])
        }

        // offers
        if (this.tab.show_offers_created && col.value === "offers_created") {
          execDashRow["Offers Created"] = jobIdToOffers[job.id] || "0"
        }

        // recent candidates
        if (this.isOneOfOpenJobsTab && this.tab.show_new_candidates && col.value === "new_candidates") {
          execDashRow["New Candidates"] = jobIdToRecentCandidates[job.id] || "0"
        }

        // target hire days
        if (this.tab.show_target_hire_days && col.value === "target_hire_days") {
          const thd = ExecutiveDashboardService.getTargetHireDays(job, perJobConfig[job.id], config)
          execDashRow["Target Hire Days"] = thd.date || ""
        }

        // requisition id
        if (this.tab.show_requisition_id && col.value === "requisition_id") {
          execDashRow["Requisition ID"] = job.requisition_id || ""
        }

        // stages (active + conversion rates)
        if (this.tab.show_job_stages && col.value === "job_stages") {
          for (const js of involvedJobStages) {
            const stage = jobStages[job.id].find(j => j.name === js)
            if (stage) {
              if (this.isOneOfOpenJobsTab) {
                execDashRow[js + " - Active Candidates"] = jobStageConversionRates[job.id][stage.id].active || 0
              }
              execDashRow[js + " - Conversion Rates"] =
                (jobStageConversionRates[job.id][stage.id].converted_percent || 0) + "%"
            }
          }
        }

        if (this.tab.show_custom_fields && col.type === 'custom_field') {
          for (const cf of this.tab.custom_fields_to_show) {
            const cfName = customFieldMap.get(cf).name
            if (job.custom_fields[cf]) {
              execDashRow[cfName] = ExecutiveDashboardService.customFieldAsArray(job.custom_fields[cf]).join(", ")
            } else {
              execDashRow[cfName] = ""
            }
          }

        }
      }
      allExecDashData.push(execDashRow)
    }
    let tabName = this.tab.name
    if (!this.isOneOfOpenJobsTab) {
      tabName = "Closed Jobs"
    } else if (!this.tab.name) {
      tabName = this.isOneOfOpenJobsTab ? "Open Jobs" : "Closed Jobs"
    }
    exportCsv(allExecDashData,
      "Executive dashboard - " +
      tabName + ' - ' +
      format(new Date(), 'yyyy-MM-dd') +
      '.csv',
      objKeysSafe(allExecDashData[0] || {})
    )
  }

  getClipboardLink() {
    return window.location.host + '/shared-executive-tab/' + this.tab.id + '/' + this.tab.sharable_token
  }

  copyLinkNotification() {
    this.toastr.success(`Copied to clipboard`)
    this.segmentService.track('Copy Executive Dashboard Tab link to clipboard')
  }

  async saveSharedDashboard(name: string) {
    this.isSavingSharedTab = true

    this.store.dispatch(new CreateExecutiveDashboardTab({
      ...this.tab,
      name: name || this.tab.name,
      hidden_section_ids: this.hidden
    }))
    try {
      const newTab = await toasterOnAction(
        [ExecutiveDashboardActionsTypes.CreateExecutiveDashboardTabSuccess],
        [ExecutiveDashboardActionsTypes.CreateExecutiveDashboardTabFailure],
        "Tab is created successfully",
        "Error saving tab",
        this.toastr,
        this.actions,
      )
      const newTabAction = newTab as CreateExecutiveDashboardTabSuccess
      location.href = "/executive-tools/executive-dashboard/" + newTabAction.payload.id
    } catch(err) {
    }
    this.isSavingSharedTab = false
  }

  openSendToSlackTab() {
    this.slackModal.openSendToSlackTab(
      `Checkout my TalentWall Executive Dashboard: ${this.getClipboardLink()}`
    )
  }

  isHiddenSection(section: HiddenSections) {
    const isHidden = this.hidden.includes(section)
    return {
      text: isHidden ? 'Show' : 'Hide' ,
      icon: isHidden ? 'fa-eye' : 'fa-eye-slash',
      value: isHidden && !this.tab.default_tab
    }
  }

  changeHiddenStatus(section: HiddenSections) {
    if (!this.isSharedTab) {
      this.store.dispatch(new UpdateExecutiveDashboardTabHideSection({
        tabId: this.tab.id,
        hide_section: {
          id: section,
          hidden: !this.hidden.includes(section)
        }
      }))
    } else {
      const index = this.hidden.indexOf(section)
      if (index > -1) {
        this.hidden.splice(index, 1)
      } else {
        this.hidden.push(section)
      }
    }
  }
}
