import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'twng-collapsable-save-icon',
  templateUrl: './collapsable-save-icon.component.html',
  styleUrls: ['./collapsable-save-icon.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsableSaveIconComponent implements OnInit {
  text = ''

  @Input()
    placeholder: string

  @Input()
    tooltip: string

  @Input()
    disabled: boolean

  @Output()
    saveClicked = new EventEmitter<string>()

  constructor() { }

  ngOnInit(): void {
    this.text = this.placeholder
  }

  save() {
    this.saveClicked.emit(this.text)
  }
}
