import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { User } from '../models/user'

export type State = EntityState<User>

export const adapter: EntityAdapter<User> = createEntityAdapter<User>()

export const initialState: State = adapter.getInitialState()

export function reducer(state = initialState, action: LoaderActions): State {
  switch (action.type) {
    case LoaderActionTypes.LoadSharedDataSuccess: {
      const localUsers = action.payload.users
      return adapter.setAll(localUsers, state)
    }

    default:
      return state
  }
}
