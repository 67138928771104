import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core'
import { JobStatus, JobStatusColor, JobStatusUpdate } from '../../../wall/models/job-status-update'

interface HistoryChange {
  label: string
  created_at: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-notes-history',
  templateUrl: './notes-history.component.html',
  styleUrls: ['./notes-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotesHistoryComponent implements OnChanges {

  @Input()
    jobStatusUpdates: JobStatusUpdate[]
  @Input()
    jobStatusLabels: JobStatus

  historyChanges: HistoryChange[] = []

  ngOnChanges(changes: SimpleChanges) {
    const jobStatusUpdates = changes.jobStatusUpdates?.currentValue
    if (jobStatusUpdates) {
      this.prepareUpdates()
    }
  }

  prepareUpdates() {
    // this.jobStatusUpdates always comes sorted from latest to oldest
    const sortedUpdates = [...this.jobStatusUpdates || []].reverse()

    this.historyChanges = sortedUpdates
      .map((jobStatusUpdate: JobStatusUpdate, index: number): HistoryChange => {
        let label = ''
        if (index === 0) {
          label = this.createFirstChangeText(jobStatusUpdate)
        } else {
          const prevJobStatusUpdate = sortedUpdates[index - 1]
          label = this.createChangeText(prevJobStatusUpdate, jobStatusUpdate)
        }
        return {
          label,
          created_at: jobStatusUpdate.created_at
        }
      })
      .filter((updates: HistoryChange) => !!updates.label.length)
      .reverse()
  }

  createFirstChangeText(jobStatusUpdate: JobStatusUpdate) {
    const statusChangedText = `${jobStatusUpdate.user_name} updated the status to ${this.getStatusText(jobStatusUpdate)}`
    const addedNoteText = this.getAddedNoteText(jobStatusUpdate)

    if (jobStatusUpdate.status && jobStatusUpdate.note) {
      return `${statusChangedText} and ${addedNoteText}`
    }
    if (jobStatusUpdate.status) {
      return statusChangedText
    }
    if (jobStatusUpdate.note) {
      return `${jobStatusUpdate.user_name} ${addedNoteText}`
    }
    return ''
  }

  getAddedNoteText(jobStatusUpdate: JobStatusUpdate): string {
    return `added note: <span class="history-item--note">${jobStatusUpdate.note}</span>`
  }

  createChangeText(prevJobStatusUpdate: JobStatusUpdate, currentJobStatusUpdate: JobStatusUpdate): string {
    const changedStatus = prevJobStatusUpdate.status !== currentJobStatusUpdate.status
    const clearedStatus = changedStatus && !currentJobStatusUpdate.status

    const clearedStatusText = `${currentJobStatusUpdate.user_name} cleared status`
    const addedNoteText = this.getAddedNoteText(currentJobStatusUpdate)
    const statusChangedText =
      `${currentJobStatusUpdate.user_name} updated the status from ${this.getStatusText(prevJobStatusUpdate)} to ${this.getStatusText(currentJobStatusUpdate)}`

    if (clearedStatus && currentJobStatusUpdate.note) {
      return `${clearedStatusText} and ${addedNoteText}`
    }
    if (clearedStatus) {
      return `${clearedStatusText}`
    }
    if (changedStatus && currentJobStatusUpdate.note) {
      return `${statusChangedText} and ${addedNoteText}`
    }
    if (changedStatus) {
      return statusChangedText
    }
    if (currentJobStatusUpdate.note) {
      return `${currentJobStatusUpdate.user_name} ${addedNoteText}`
    }
    return ''
  }

  getStatusText(jobStatusUpdate: JobStatusUpdate): string {
    switch (jobStatusUpdate.status) {
      case JobStatusColor.Green:
        return `<span class="job-status-change history-item__job-status-green">${this.jobStatusLabels[jobStatusUpdate.status]}</span>`
      case JobStatusColor.Yellow:
        return `<span class="job-status-change history-item__job-status-yellow">${this.jobStatusLabels[jobStatusUpdate.status]}</span>`
      case JobStatusColor.Red:
        return `<span class="job-status-change history-item__job-status-red">${this.jobStatusLabels[jobStatusUpdate.status]}</span>`
      case JobStatusColor.OnHold:
        return `<span class="job-status-change history-item__job-status-on-hold">${this.jobStatusLabels[jobStatusUpdate.status]}</span>`
      default:
        return 'none'
    }
  }

}
