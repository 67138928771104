import { CommonModule } from '@angular/common';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover'
import { NgModule } from '@angular/core';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap'
import {
  ScheduleNewReportModalComponent
} from './scheduled-reports-page/schedule-new-report-modal/schedule-new-report-modal.component';
import {
  ScheduledReportRowComponent
} from './scheduled-reports-page/scheduled-report-row/scheduled-report-row.component';
import { ScheduledReportsPageComponent } from './scheduled-reports-page/scheduled-reports-page.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    ScheduledReportsPageComponent,
    ScheduleNewReportModalComponent,
    ScheduledReportRowComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbTimepickerModule,
    ConfirmationPopoverModule,
  ]
})
export class UserSettingsModule { }
