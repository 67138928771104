/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'

import { Account } from '../../wall/models/account'
import { Activity } from '../../wall/models/activity'
import { AllConversionRates } from '../../wall/reducers/job-stage-stats.reducer'
import { AllJobAnalytics } from '../../wall/reducers/job-analytics.reducer'
import { AnalyticsState } from '../../dashboard/reducers/analytics.reducer'
import { AppConfig } from '../../wall/models/app-config'
import { Candidate } from '../../wall/models/candidate'
import { CandidateTag } from '../../wall/models/candidate-tag'
import { Chart, ChartStatus, DashboardChart } from '../../dashboard/models/dashboard-chart'
import { CustomFieldProps } from '../../custom-fields/models/custom-field'
import { DashboardTab } from '../../dashboard/models/dashboard-tab'
import { DataSources } from '../../dashboard/reducers/dashboard.reducer'
import { Department } from '../../wall/models/department'
import { EmailTemplate } from '../../wall/models/email-template'
import { ExternalUser } from '../../wall/models/external-user'
import { Interview } from '../../wall/models/interview'
import { Job } from '../../wall/models/job'
import { JobApplication } from '../../wall/models/job-application'
import { JobOpening } from '../../wall/models/job-opening'
import { JobStage } from '../../wall/models/job-stage'
import { Note } from '../../wall/models/note'
import { Office } from '../../wall/models/office'
import { RejectionReason } from '../../wall/models/rejection-reason'
import { Source } from '../../wall/models/source'
import { Tab } from '../../wall/models/tab'
import { TagMapping } from '../../wall/models/tag-mapping'
import { TrackFeatureKeyState } from '../../shared/reducers/track-features.reducer'
import { User } from '../../wall/models/user'
import { WallSettings } from '../../wall/models/wall-settings'

export enum LoaderActionTypes {
  WallInit = '[Loader] WallInit',
  WallDemoDataUrl = '[Loader] WallDemoDataUrl',
  WallSharedSuccess = '[Loader] WallSharedSuccess',
  WallSharedFailure = '[Loader] WallSharedFailure',

  LoadSharedData = '[Loader] LoadSharedData',
  LoadSharedDataSuccess = '[Loader] LoadSharedDataSuccess',
  LoadSharedDataFailure = '[Loader] LoadSharedDataFailure',
  UpdateSharedUser = '[Loader] UpdateSharedUser',

  LoadDashboardTab = '[Loader] LoadDashboardTab',
  LoadDashboardTabSuccess = '[Loader] LoadDashboardTabSuccess',
  LoadDashboardTabFailure = '[Loader] LoadDashboardTabFailure',
  UpdateDashboardTabFilters = '[Loader] UpdateDashboardTabFilters',

  LoadSharedDashboardTab = '[Loader] LoadSharedDashboardTab',
  LoadSharedDashboardTabSuccess = '[Loader] LoadSharedDashboardTabSuccess',
  LoadSharedDashboardTabFailure = '[Loader] LoadSharedDashboardTabFailure',

  DashboardLoad = '[Loader] DashboardLoad',
  DashboardLoadSuccess = '[Loader] DashboardLoadSuccess',
  DashboardLoadFailure = '[Loader] DashboardLoadFailure',
  DeleteFromServer = '[Loader] DeleteFromServer',
  UpdateFromServer = '[Loader] UpdateFromServer',
  UpdateWidgetDataFromServer = '[Loader] UpdateWidgetDataFromServer',
  UpdateDashboardWidgetDataFromServer = '[Loader] UpdateDashboardWidgetDataFromServer',
  UpdateWidgetStatusFromQueueKey = '[Loader] UpdateWidgetStatusFromQueueKey',

  FetchWidgetLibraryTab = '[Loader] FetchWidgetLibraryTab',
  FetchMyDashboardTab = '[Loader] FetchMyDashboardTab',

  LoadAccount = '[Loader] LoadAccount',

  SignOut = '[Loader] SignOut',
  SignOutSuccess = '[Loader] SignOutSuccess',

  LoadClosedJobNames = '[Loader] LoadClosedJobNames',
  LoadClosedJobNamesSuccess = '[Loader] LoadClosedJobNamesSuccess',
  LoadClosedJobNamesFailure = '[Loader] LoadClosedJobNamesFailure',
  LoadOpenJobNames = '[Loader] LoadOpenJobNames',
  LoadOpenJobNamesSuccess = '[Loader] LoadOpenJobNamesSuccess',
  LoadOpenJobNamesFailure = '[Loader] LoadOpenJobNamesFailure',
}

export interface WallLoaderPayload {
  tag_mappings: TagMapping[]
  job_stage_conversion_stats: AllConversionRates
  tabs: Tab[]
  email_templates: EmailTemplate[]
  notes?: Note[]
  now: number // this is used for demo: we freeze cards' times so that they don't look old
  activities?: Activity[]
  job_analytics?: AllJobAnalytics
}

// currently used only in update over websocket
export interface WallLoaderPayloadWithJobInfo extends WallLoaderPayload {
  jobs: Job[]
  job_stages: JobStage[]
}

export interface WallCandidatePayload {
  candidates: Candidate[]
  job_applications: JobApplication[]
  interviews: Interview[]
  allCandidates: boolean
  tab: Tab | null
  job_openings: JobOpening[]
}

export interface SharedDataPayload {
  account: Account
  app_config: AppConfig
  candidate_tags: CandidateTag[]
  custom_fields: CustomFieldProps[]
  candidate_custom_fields: CustomFieldProps[]
  offer_custom_fields: CustomFieldProps[]
  departments: Department[]
  external_users: ExternalUser[]
  sources: Source[]
  job_stage_names: string[]
  offices: Office[]
  rejection_reasons: RejectionReason[]
  user: User
  users: User[]
  wall_settings: WallSettings
  data_sources: DataSources
  seen_features: TrackFeatureKeyState[]
  sourcing_users: ExternalUser[]
}

export interface SingleDashboardTemplate {
  name: string
  description: string
  icon_class: string
}
export interface DashboardTemplateList {
  [templateKey: string]: SingleDashboardTemplate
}

export interface DashboardLoaderPayload {
  dashboard_tabs?: DashboardTab[],
  chart_types?: unknown,
  analytics: Partial<AnalyticsState>,
  dashboard_templates?: DashboardTemplateList,
  initial_config?: ChartsInitialConfig
}

export interface ChartsInitialConfig {
  horizontal_bars_number: number,
  vertical_bars_number: number
}

export interface SingleJobNamePayload {
  id: string,
  name: string,
  requisition_id: string,
  office_ids: string[],
  department_ids: string[],
}

export interface ClosedJobNamesPayload {
  closed_jobs: SingleJobNamePayload[]
}

export interface OpenJobNamesPayload {
  open_jobs: SingleJobNamePayload[]
}

export interface LoadWallCandidatesPayload {
  tabId: string,
  sharedKey?: string,
}

export interface LoadDashboardTabPayload {
  tabId: number
}

export interface LoadSharedDashboardTabPayload {
  tabId: string,
  sharable_token: string
}

export interface DashboardTabPayload {
  tab_charts: DashboardChart[],
}

export interface SharableDashboardTabPayload extends DashboardTabPayload {
  tab: DashboardTab
}

export interface DeletionPayload {
  [loader: string]: string;
}

export class WallInit implements Action {
  readonly type = LoaderActionTypes.WallInit
  constructor() { }
}

export interface LoadAllWallCandidatesPayload {
  account: Account
}

export class SignOut implements Action {
  readonly type = LoaderActionTypes.SignOut
  constructor() { }
}

export class SignOutSuccess implements Action {
  readonly type = LoaderActionTypes.SignOutSuccess
  constructor(public payload) { }
}

export class WallDemoDataUrl implements Action {
  readonly type = LoaderActionTypes.WallDemoDataUrl
  constructor(public payload: string) { }
}

export class WallSharedSuccess implements Action {
  readonly type = LoaderActionTypes.WallSharedSuccess
  constructor(public payload: WallLoaderPayload) { }
}

export class WallSharedFailure implements Action {
  readonly type = LoaderActionTypes.WallSharedFailure
  constructor() { }
}

export class LoadSharedDataSuccess implements Action {
  readonly type = LoaderActionTypes.LoadSharedDataSuccess
  constructor(public payload: SharedDataPayload) { }
}

export class LoadSharedDataFailure implements Action {
  readonly type = LoaderActionTypes.LoadSharedDataFailure
  constructor() { }
}

export class LoadSharedData implements Action {
  readonly type = LoaderActionTypes.LoadSharedData
  constructor() { }
}

export class UpdateSharedUser implements Action {
  readonly type = LoaderActionTypes.UpdateSharedUser
  constructor(public payload: User) { }
}

export class LoadClosedJobNamesSuccess implements Action {
  readonly type = LoaderActionTypes.LoadClosedJobNamesSuccess
  constructor(public payload: ClosedJobNamesPayload) { }
}

export class LoadClosedJobNamesFailure implements Action {
  readonly type = LoaderActionTypes.LoadClosedJobNamesFailure
  constructor() { }
}

export class LoadClosedJobNames implements Action {
  readonly type = LoaderActionTypes.LoadClosedJobNames
  constructor() { }
}

export class LoadOpenJobNamesSuccess implements Action {
  readonly type = LoaderActionTypes.LoadOpenJobNamesSuccess
  constructor(public payload: OpenJobNamesPayload) { }
}

export class LoadOpenJobNamesFailure implements Action {
  readonly type = LoaderActionTypes.LoadOpenJobNamesFailure
  constructor() { }
}

export class LoadOpenJobNames implements Action {
  readonly type = LoaderActionTypes.LoadOpenJobNames
  constructor() { }
}

export class LoadDashboardTabSuccess implements Action {
  readonly type = LoaderActionTypes.LoadDashboardTabSuccess
  constructor(public payload: DashboardTabPayload) { }
}

export class LoadDashboardTabFailure implements Action {
  readonly type = LoaderActionTypes.LoadDashboardTabFailure
  constructor() { }
}

export class LoadDashboardTab implements Action {
  readonly type = LoaderActionTypes.LoadDashboardTab
  constructor(public payload: LoadDashboardTabPayload) { }
}

export class LoadSharedDashboardTabSuccess implements Action {
  readonly type = LoaderActionTypes.LoadSharedDashboardTabSuccess
  constructor(public payload: SharableDashboardTabPayload) { }
}

export class LoadSharedDashboardTabFailure implements Action {
  readonly type = LoaderActionTypes.LoadSharedDashboardTabFailure
  constructor(public payload: {status: number}) { }
}

export class LoadSharedDashboardTab implements Action {
  readonly type = LoaderActionTypes.LoadSharedDashboardTab
  constructor(public payload: LoadSharedDashboardTabPayload) { }
}

export class FetchWidgetLibraryTab implements Action {
  readonly type = LoaderActionTypes.FetchWidgetLibraryTab
  constructor(public payload: { tabId: string, forceLoad?: boolean }) { }
}

export class FetchMyDashboardTab implements Action {
  readonly type = LoaderActionTypes.FetchMyDashboardTab
  constructor() { }
}

export class DashboardLoad implements Action {
  readonly type = LoaderActionTypes.DashboardLoad
  constructor() { }
}

export class DashboardLoadSuccess implements Action {
  readonly type = LoaderActionTypes.DashboardLoadSuccess
  constructor(public payload: DashboardLoaderPayload) { }
}

export class DashboardLoadFailure implements Action {
  readonly type = LoaderActionTypes.DashboardLoadFailure
  constructor() { }
}

export class DeleteFromServer implements Action {
  readonly type = LoaderActionTypes.DeleteFromServer
  constructor(public payload: DeletionPayload) {}
}

export class UpdateFromServer implements Action {
  readonly type = LoaderActionTypes.UpdateFromServer
  // We use candidate tags from SharedDataPayload in candidate-tags.reducer.ts
  constructor(public payload: Partial<WallLoaderPayloadWithJobInfo & SharedDataPayload & WallCandidatePayload>) { }
}

export class UpdateWidgetDataFromServer implements Action {
  readonly type = LoaderActionTypes.UpdateWidgetDataFromServer
  constructor(public payload: Chart) { }
}

export class UpdateDashboardWidgetDataFromServer implements Action {
  readonly type = LoaderActionTypes.UpdateDashboardWidgetDataFromServer
  constructor(public payload: DashboardChart) { }
}

export class UpdateWidgetStatusFromQueueKey implements Action {
  readonly type = LoaderActionTypes.UpdateWidgetStatusFromQueueKey
  constructor(public payload: ChartStatus) { }
}

export class LoadAccount implements Action {
  readonly type = LoaderActionTypes.LoadAccount
  constructor(public payload: { user: User, account: Account, app_config: AppConfig }) { }
}

export type LoaderActions = WallInit | WallSharedSuccess | WallSharedFailure | DeleteFromServer | UpdateFromServer  |
LoadAccount | DashboardLoadSuccess | DashboardLoadFailure |
LoadSharedData | LoadSharedDataSuccess | LoadSharedDataFailure |
LoadDashboardTab | LoadDashboardTabSuccess | LoadDashboardTabFailure |
LoadClosedJobNames | LoadClosedJobNamesSuccess | LoadClosedJobNamesFailure |
LoadOpenJobNames | LoadOpenJobNamesSuccess | LoadOpenJobNamesFailure |
LoadSharedDashboardTab | LoadSharedDashboardTabSuccess | LoadSharedDashboardTabFailure |
FetchWidgetLibraryTab | UpdateWidgetDataFromServer | UpdateDashboardWidgetDataFromServer |
UpdateWidgetStatusFromQueueKey | FetchMyDashboardTab | UpdateSharedUser
