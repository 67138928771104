import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ScheduledReport } from "./scheduled-report.model"
import { apiHost, getHttpPostOptions, httpGetOptions } from "../../core/http-options"

@Injectable({
  providedIn: 'root'
})
export class ScheduledReportService {
  constructor(private http: HttpClient) {}

  getReports() {
    return this.http.get<ScheduledReport[]>(
      apiHost + '/twng/scheduled_reports.json', httpGetOptions
    ).toPromise()
  }

  createReport(scheduledReport) {
    return this.http.post<ScheduledReport>(
      apiHost + '/twng/scheduled_reports',
      { scheduled_report: scheduledReport },
      getHttpPostOptions(),
    ).toPromise()
  }

  editReport(scheduledReport, id: number) {
    return this.http.put<ScheduledReport>(
      apiHost + '/twng/scheduled_reports/' + id,
      { scheduled_report: scheduledReport },
      getHttpPostOptions(),
    ).toPromise()
  }

  deleteReport(id: number) {
    return this.http.delete<void>(
      apiHost + '/twng/scheduled_reports/' + id,
      getHttpPostOptions(),
    ).toPromise()
  }

  sendReportNow(id: number) {
    return this.http.post<ScheduledReport>(
      apiHost + '/twng/scheduled_reports/' + id + '/send_now',
      null,
      getHttpPostOptions(),
    ).toPromise()
  }

  rescheduleReport(id: number) {
    return this.http.post<ScheduledReport>(
      apiHost + '/twng/scheduled_reports/' + id + '/reschedule',
      null,
      getHttpPostOptions(),
    ).toPromise()
  }
}
