import { Action } from '@ngrx/store'
import { WidgetDataType } from '../../wall/models/offer'
import { atsId } from '../../wall/models/types'

export enum GenericTableModalActionTypes {
  ShowModal = `[GenericTable Modal] ShowModal`,
  DataChangedInModal = `[GenericTable Modal] DataChangedInModal`
}

export interface ShowGenericTableModalPayload {
  data_ids: atsId[]
  chart: string | WidgetDataType
  clickedGraphTitle: string
  clickedSeries?: string
  clickedLabel?: string
  titleOverride?: string
}

export function genericTableModalTitle(payload: ShowGenericTableModalPayload) {
  if (payload.titleOverride) {
    return payload.titleOverride
  } else {
    return payload.clickedGraphTitle
  }
}

export function getChartTitleInfo(payload: ShowGenericTableModalPayload, separator: string) {
  return [payload.clickedSeries, payload.clickedLabel].
    filter(val => !!val).
    join(separator)
}

export class ShowGenericTableModal implements Action {
  readonly type = GenericTableModalActionTypes.ShowModal
  constructor(public payload: ShowGenericTableModalPayload) { }
}

export class NotifyDataChangedInModal implements Action {
  readonly type = GenericTableModalActionTypes.DataChangedInModal
}

export type GenericTableModalActions = ShowGenericTableModal
