import { Actions, createEffect, ofType } from "@ngrx/effects"
import { EMPTY, timer } from "rxjs"
import {
  FetchJobById,
  FetchJobsByIdsSuccess,
  JobsByIdsResponse,
  WallActionTypes
} from "../actions/wall.actions"
import { HttpClient } from "@angular/common/http"
import { Injectable, NgZone } from "@angular/core"
import { Store } from "@ngrx/store"
import { WallSearchingService } from "./wall-searching.service"
import { WallSearchingServiceDefinition } from "./wall-searching.service-definition"
import { apiHost, getHttpPostOptions, httpGetOptions } from "../../core/http-options"
import { buffer, catchError, concatMap, debounceTime, exhaustMap, finalize, retry } from "rxjs/operators"
import { difference, without } from "lodash-es"
import { selectImmediatelySync } from "../../shared/utils/store.utils"
import { selectJobIds } from "../reducers"
@Injectable()
export class WallEffects {
  private wallSearching: WallSearchingServiceDefinition

  constructor(
    private actions: Actions,
    private http: HttpClient,
    private store: Store,
    private zone: NgZone,
    providedWallSearching: WallSearchingService,
  ) {
    this.wallSearching = providedWallSearching
    this.listenToJobRequests()
    this.checkJobsQueue()
  }

  getWallDataPaginated$ = createEffect(() =>
    this.actions.pipe(
      ofType(WallActionTypes.FetchWallDataPaginatedAction),
      concatMap(value => this.wallSearching.searchAllJobs(value))
    )
  )

  getWallTabDataPaginated$ = createEffect(() =>
    this.actions.pipe(
      ofType(WallActionTypes.FetchWallDataPaginatedTabAction),
      concatMap(value => this.wallSearching.searchJobsInTab(value))
    )
  )

  private jobsQueueForFetching: string[] = []
  private MAX_JOBS_PER_REQUEST = 50

  private listenToJobRequests() {
    this.actions.pipe(
      ofType<FetchJobById>(WallActionTypes.FetchJobByIdAction),
      // collect all requests within 100ms into array of requests
      buffer(this.actions.pipe(
        ofType(WallActionTypes.FetchJobByIdAction),
        debounceTime(100)
      )),
    ).subscribe(reqs => {
      // all requested ids in one place. NOTE: may contain duplicates
      const allIdRequests = reqs
        .filter(Boolean)
        .map(req => req.payload.toString())
      // which jobs are already loaded
      const alreadyLoadedJobIds = (selectImmediatelySync(this.store, selectJobIds) as Array<number | string>)
        .map(v => v.toString())
      // which jobs we want to add to queue
      const notLoadedJobIds = difference(allIdRequests, alreadyLoadedJobIds)
      // since we are inserting ids in 1st position of queue array, we want to
      // add them last (so that they end up in 1st position after this)
      notLoadedJobIds.reverse()
      notLoadedJobIds.forEach(id => {
        // we want to remove any previous attempts of this id
        if (this.jobsQueueForFetching.includes(id)) {
          this.jobsQueueForFetching = without(this.jobsQueueForFetching, id)
        }
        // put this id at beginning of queue, effectively requesting this id to
        // be processed first in next request. That way we prioritize more
        // recent requests first
        this.jobsQueueForFetching = [id, ...this.jobsQueueForFetching]
      })
    })
  }
  private checkJobsQueue() {
    // we don't want this timer to trigger refresh of angular views
    this.zone.runOutsideAngular(
      () => {
        // check every 100ms if there is anything in queue
        timer(1000, 100).pipe(
          // exhaust map will block any timer emissions until ongoing http request is completed
          exhaustMap(() => {
            if (!this.jobsQueueForFetching.length) {
              return EMPTY
            }
            return this.zone.run(() => {
              // take first few IDs and request their data
              const idsInChunk = this.jobsQueueForFetching.slice(0, this.MAX_JOBS_PER_REQUEST)
              // console.log("Requesting", idsInChunk)
              const apiCall = window.twng_demo ?
                this.http.get<JobsByIdsResponse>(apiHost + '/twng/jobs.json', httpGetOptions) :
                this.http.post<JobsByIdsResponse>(
                  apiHost + '/twng/jobs.json', { job_ids: idsInChunk }, getHttpPostOptions()
                )
              return apiCall.pipe(
                retry(1),
                finalize(() => {
                  this.jobsQueueForFetching = difference(this.jobsQueueForFetching, idsInChunk)
                }),
                catchError(() => {
                  console.warn("Error fetching jobs", idsInChunk)
                  return EMPTY
                })
              )
            })
          })
        ).subscribe(result => {
          if (result) {
            this.zone.run(() => {
              this.store.dispatch(new FetchJobsByIdsSuccess(result))
            })
          }
        })
      }
    )
  }
}
