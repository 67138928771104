import { CandidateInStage, FullCandidateInStage } from '../models/candidates-in-stage'
import { CandidatesInStageState } from '../reducers/canidates-in-stage.reducer'
import { DashboardState, selectDashboardState } from '../../dashboard/reducers';
import { Department } from '../../wall/models/department'
import { Dictionary } from '@ngrx/entity'
import { ExternalUser } from '../../wall/models/external-user'
import { Office } from '../../wall/models/office'
import { createSelector } from '@ngrx/store'
import { selectDepartmentEntities, selectExternalUserEntities, selectOfficeEntities } from '../../wall/reducers'

export const selectCandidatesInStage = createSelector(
  selectDashboardState,
  (state: DashboardState): CandidatesInStageState => state.candidates_in_stage
)

export const selectLoadingInitialData = createSelector(
  selectCandidatesInStage,
  state => state.loadingInitialData
)

export const getCandidates = createSelector(
  selectCandidatesInStage,
  state => state.candidates
)

export const selectFetchingCandidates = createSelector(
  selectCandidatesInStage,
  state => state.fetchingCandidates
)

export const selectCandidatesInStageTable = createSelector(
  getCandidates,
  selectDepartmentEntities,
  selectOfficeEntities,
  selectExternalUserEntities,
  (
    candidatesInStage: CandidateInStage[],
    departmentEntities: Dictionary<Department>,
    officeEntities: Dictionary<Office>,
    externalUsersEntities: Dictionary<ExternalUser>
  ): FullCandidateInStage[] => candidatesInStage.map(candidate => ({
    ...candidate,
    departments: candidate.department_ids.map(departmentId => departmentEntities[departmentId]),
    offices: candidate.office_ids.map(officeId => officeEntities[officeId]),
    recruiter: externalUsersEntities[candidate.recruiter_id],
    coordinator: externalUsersEntities[candidate.coordinator_id],
  }))
)
