import { Directive, Input } from '@angular/core';
import { Job } from '../../wall/models/job';
import {
  JobStatusAndNotesHistoryModalComponent
} from './job-status-and-notes-history-modal/job-status-and-notes-history-modal.component'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[jobStatusAndNotes]',
  exportAs: 'jobStatusAndNotes'
})
export class JobStatusAndNotesHistoryDirective {

  @Input()
    job: Job

  @Input()
    showNotes = true

  @Input()
    showUpdateJobStatus = true

  modalRef: NgbModalRef

  constructor(private modalService: NgbModal) {
  }

  openModal() {
    if (!this.modalRef) {
      this.modalRef = this.modalService.open(JobStatusAndNotesHistoryModalComponent, {
        size: 'sm',
      })

      this.modalRef.componentInstance.job = this.job
      this.modalRef.componentInstance.showNotes = this.showNotes
      this.modalRef.componentInstance.showUpdateJobStatus = this.showUpdateJobStatus

      this.modalRef.result
        .then(() => {
          this.modalRef = null
        })
        .catch(() => {
          this.modalRef = null
        })
    }
  }

}
