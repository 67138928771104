<ng-container *ngIf="chart; else spinner">
  <twng-gridster-item-wrapper [grid]="grid" [chart]="chart" *ngIf="chart.chart_type === 'free-text'"
    [isEditingGridster]="editingGridster"
  >
    <twng-dash-description *ngIf="chart.data?.data && !isInCustomDashboard"
      [label]="chart.name" [editingGridster]="editingGridster"
      [dataSource]="chart.data_source" [dataSourceParameters]="chart.data_source_parameters"
      [candidateDataType]="chart.data_type" [value]="chart.data?.data ? chart.data?.data[0]?.value : null"
      [chart]="chart" #gridsterChart
      [gridItemType]="gridItemType" [isInGridster]="isInCustomDashboard"
      [chartFiltersReadOnly]="chartFiltersReadOnly">
    </twng-dash-description>

    <twng-dash-description *ngIf="isInCustomDashboard"
      [editingGridster]="editingGridster" [dataSource]="chart.data_source"
      [chart]="chart" #gridsterChart value="?" [gridItemType]="gridItemType"
      [filtersEnabled]="chartFiltersEnabled" [dataSourceParameters]="chart.data_source_parameters"
      [chartFiltersReadOnly]="chartFiltersReadOnly" [isInGridster]="isInCustomDashboard">
    </twng-dash-description>
  </twng-gridster-item-wrapper>

  <twng-gridster-item-wrapper [grid]="grid" [chart]="chart" *ngIf="chart.chart_type === 'number-box'"
    [isEditingGridster]="editingGridster"
  >
    <twng-dash-stats *ngIf="chart.data?.data && (chart?.data_type !== CUSTOM_BOX_DATA_TYPE || !isInCustomDashboard)"
      [label]="chart.name" [editingGridster]="editingGridster"
      [dataSource]="chart.data_source" [dataSourceParameters]="chart.data_source_parameters"
      [candidateDataType]="chart.data_type" [value]="chart.data?.data ? chart.data?.data[0]?.value : null"
      [chartFilters]="chartFilters" [chart]="chart" #gridsterChart
      [gridItemType]="gridItemType" [filtersEnabled]="chartFiltersEnabled" [isInGridster]="isInCustomDashboard"
      [chartFiltersReadOnly]="chartFiltersReadOnly" [overrideFiltersSet]="overrideFiltersSet">
    </twng-dash-stats>

    <twng-dash-stats *ngIf="chart?.data_type === CUSTOM_BOX_DATA_TYPE && isInCustomDashboard"
      [label]="chart.name" [editingGridster]="editingGridster" [dataSource]="chart.data_source"
      [chart]="chart" #gridsterChart value="?" [gridItemType]="gridItemType"
      [filtersEnabled]="chartFiltersEnabled" [dataSourceParameters]="chart.data_source_parameters"
      [chartFiltersReadOnly]="chartFiltersReadOnly" [isInGridster]="isInCustomDashboard" [overrideFiltersSet]="overrideFiltersSet">
    </twng-dash-stats>
  </twng-gridster-item-wrapper>

  <twng-gridster-item-wrapper [grid]="grid" [chart]="chart" *ngIf="chart.chart_type === 'pie'"
    [isEditingGridster]="editingGridster"
  >
    <twng-dash-pie-chart [label]="chart.name" [status]="chart.data" [dataSource]="chart.data_source"
      [editingGridster]="editingGridster" [chartFilters]="chartFilters" [chart]="isInCustomDashboard ? chart : null"
      [filtersInCopiedImg]="true" [gridItemType]="gridItemType" [candidateDataType]="chart.data_type" #gridsterChart
      [filtersEnabled]="chartFiltersEnabled" [dataSourceParameters]="chart.data_source_parameters"
      [chartFiltersReadOnly]="chartFiltersReadOnly" [overrideFiltersSet]="overrideFiltersSet">
    </twng-dash-pie-chart>
  </twng-gridster-item-wrapper>

  <twng-gridster-item-wrapper [grid]="grid" [chart]="chart" *ngIf="chart.chart_type === 'bar-horizontal'"
    [isEditingGridster]="editingGridster"
  >
    <twng-dash-bar-chart [editingGridster]="editingGridster" chartDirection="horizontal" [label]="chart.name"
      [chartFilters]="chartFilters" [status]="chart.data" [chart]="isInCustomDashboard ? chart : null"
      [dataSource]="chart.data_source" [filtersInCopiedImg]="true" [gridItemType]="gridItemType"
      [candidateDataType]="chart.data_type" #gridsterChart [filtersEnabled]="chartFiltersEnabled"
      [dataSourceParameters]="chart.data_source_parameters" [chartFiltersReadOnly]="chartFiltersReadOnly"
      [chartInitConfig]="chartInitConfig" [overrideFiltersSet]="overrideFiltersSet">
    </twng-dash-bar-chart>
  </twng-gridster-item-wrapper>

  <twng-gridster-item-wrapper [grid]="grid" [chart]="chart" [isEditingGridster]="editingGridster"
    *ngIf="chart.chart_type === 'bar-horizontal-grouped'">
    <twng-dash-bar-chart [editingGridster]="editingGridster" chartDirection="horizontal" [label]="chart.name"
      [chartFilters]="chartFilters" [status]="chart.data" [chart]="isInCustomDashboard ? chart : null"
      [dataSource]="chart.data_source" [filtersInCopiedImg]="true" [gridItemType]="gridItemType"
      [candidateDataType]="chart.data_type" #gridsterChart [filtersEnabled]="chartFiltersEnabled"
      [isDataGrouped]="true"
      [dataSourceParameters]="chart.data_source_parameters" [chartFiltersReadOnly]="chartFiltersReadOnly"
      [chartInitConfig]="chartInitConfig" [overrideFiltersSet]="overrideFiltersSet">
    </twng-dash-bar-chart>
  </twng-gridster-item-wrapper>

  <twng-gridster-item-wrapper [grid]="grid" [chart]="chart" [isEditingGridster]="editingGridster"
    *ngIf="chart.chart_type?.startsWith('bar-vertical') && chart.chart_type !== 'bar-vertical-stacked'">
    <twng-dash-bar-chart [editingGridster]="editingGridster" chartDirection="vertical" [label]="chart.name"
      [chartFilters]="chartFilters" [status]="chart.data" [chart]="isInCustomDashboard ? chart : null"
      [dataSource]="chart.data_source" [filtersInCopiedImg]="true" [gridItemType]="gridItemType"
      [candidateDataType]="chart.data_type" #gridsterChart [filtersEnabled]="chartFiltersEnabled"
      [isDataGrouped]='chart.chart_type === "bar-vertical-grouped"'
      [dataSourceParameters]="chart.data_source_parameters" [chartFiltersReadOnly]="chartFiltersReadOnly"
      [chartInitConfig]="chartInitConfig" [overrideFiltersSet]="overrideFiltersSet">
    </twng-dash-bar-chart>
  </twng-gridster-item-wrapper>

  <twng-gridster-item-wrapper *ngIf="lineChartTypes.includes(chart.chart_type)" [grid]="grid"
    [chart]="chart" [isEditingGridster]="editingGridster">
    <twng-dash-line-chart [editingGridster]="editingGridster" [gridItemType]="gridItemType" #gridsterChart
      [label]="chart.name" [status]="chart.data" [dataSource]="chart.data_source" [chartFilters]="chartFilters"
      [chart]="isInCustomDashboard ? chart : null" [filtersInCopiedImg]="true" [chartType]="chart.chart_type"
      [filtersEnabled]="chartFiltersEnabled" [dataSourceParameters]="chart.data_source_parameters"
      [candidateDataType]="chart.data_type" [chartFiltersReadOnly]="chartFiltersReadOnly"
      [overrideFiltersSet]="overrideFiltersSet">
    </twng-dash-line-chart>
  </twng-gridster-item-wrapper>

  <twng-gridster-item-wrapper [grid]="grid" [chart]="chart" [isEditingGridster]="editingGridster"
    *ngIf="(chart.chart_type === 'bar-horizontal-stacked' || chart.chart_type === 'bar-vertical-stacked')"
  >
    <twng-dash-stacked-bar-chart [label]="chart.name" [status]="chart.data" [chart]="isInCustomDashboard ? chart : null"
      [dataSource]="chart.data_source" #gridsterChart [editingGridster]="editingGridster" [gridItemType]="gridItemType"
      [filtersInCopiedImg]="true" [candidateDataType]="chart.data_type" [chartFiltersReadOnly]="chartFiltersReadOnly"
      [dataSourceParameters]="chart.data_source_parameters"
      [isVertical]="chart.chart_type?.includes('vertical')" [filtersEnabled]="chartFiltersEnabled"
      [chartFilters]="chartFilters" [chartInitConfig]="chartInitConfig"
      [overrideFiltersSet]="overrideFiltersSet">
    </twng-dash-stacked-bar-chart>
  </twng-gridster-item-wrapper>

  <twng-gridster-item-wrapper [grid]="grid" [chart]="chart" [isEditingGridster]="editingGridster"
    *ngIf="chart.chart_type === 'bar-line-combo'"
  >
    <twng-combo-chart [label]="chart.name" [status]="chart.data" [chart]="isInCustomDashboard ? chart : null"
      [dataSource]="chart.data_source" #gridsterChart [editingGridster]="editingGridster" [gridItemType]="gridItemType"
      [filtersInCopiedImg]="true" [candidateDataType]="chart.data_type" [chartFiltersReadOnly]="chartFiltersReadOnly"
      [dataSourceParameters]="chart.data_source_parameters"
      [filtersEnabled]="chartFiltersEnabled" [chartFilters]="chartFilters" [chartType]="chart.chart_type"
      [overrideFiltersSet]="overrideFiltersSet">
    </twng-combo-chart>
  </twng-gridster-item-wrapper>

  <twng-gridster-item-wrapper [grid]="grid" [chart]="chart" *ngIf="chart.chart_type === 'table'"
    [isEditingGridster]="editingGridster"
  >
    <twng-table-chart [label]="chart.name" [status]="chart.data" [dataSource]="chart.data_source"
      [editingGridster]="editingGridster" [chartFilters]="chartFilters" [chart]="isInCustomDashboard ? chart : null"
      [gridItemType]="gridItemType" [candidateDataType]="chart.data_type" #gridsterChart
      [filtersEnabled]="chartFiltersEnabled" [dataSourceParameters]="chart.data_source_parameters"
      [overrideFiltersSet]="overrideFiltersSet">
    </twng-table-chart>
  </twng-gridster-item-wrapper>
</ng-container>

<ng-template #spinner>
  <twng-spinner></twng-spinner>
</ng-template>
