import { Candidate } from '../../wall/models/candidate'
import { ChangeDetectionStrategy } from '@angular/core'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'twng-private-candidate-icon',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
      i {
        font-size: 0.9rem;
        color: #2988E2;
      }
  `],
  template: `
    <i
      *ngIf="candidate.is_private"
      class="fas fa-lock"
      ngbTooltip="Private Candidate"
    ></i>
  `,
})
export class PrivateCandidateIconComponent {
  @Input()
    candidate: Candidate
}
