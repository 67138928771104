import {
  groupBy as _groupBy,
  keyBy as _keyBy, sortBy
} from 'lodash-es'
import arrayToTree from 'array-to-tree'

import {
  ActionReducerMap, MemoizedSelector, createFeatureSelector, createSelector
} from '@ngrx/store'

import * as fromActivity from './activity.reducer'
import * as fromCandidate from './candidate.reducer'
import * as fromCandidateTags from './candidate-tags.reducer'
import * as fromDepartment from './department.reducer'
import * as fromEmailTemplate from './email-template.reducer'
import * as fromExecutiveDashboard from './executive-dashboard.reducer'
import * as fromExternalUser from './external-users.reducer'
import * as fromInterview from './interview.reducer'
import * as fromJob from './job.reducer'
import * as fromJobAnalytics from '../../wall/reducers/job-analytics.reducer'
import * as fromJobApplication from './job-application.reducer'
import * as fromJobNames from './job-names.reducer'
import * as fromJobStage from './job-stage.reducer'
import * as fromJobStageStats from './job-stage-stats.reducer'
import * as fromLayout from './layout.reducer'
import * as fromNote from './note.reducer'
import * as fromOffice from './office.reducer'
import * as fromProjectedHires from './projected-hires.reducer'
import * as fromRejectionReason from './rejection-reason.reducer'
import * as fromRoot from '../../reducers'
import * as fromScorecard from './scorecard.reducer'
import * as fromSource from './source.reducer'
import * as fromSourcingUser from './sourcing-users.reducer'
import * as fromStageMappings from '../../stage-mappings/reducers/stage-mappings.reducer'
import * as fromTab from './tab.reducer'
import * as fromTagMapping from './tag-mapping.reducer'
import * as fromUsers from './users.reducer'
import * as fromWallSettings from './wall-settings.reducer'
import { DepartmentWithChildren } from '../models/department'
import { EMAIL_TEMPLATE_KIND_REJETION } from '../models/email-template'
import { EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID } from './executive-dashboard-closed-job-tab.data'
import { ExecutiveDashboardState } from './executive-dashboard.reducer'
import {
  JOB_APPLICATION_STATUS_ACTIVE, JOB_APPLICATION_STATUS_HIRED
} from '../models/job-application'
import { JOB_STATUS_ACTIVE, Job } from '../models/job'
import { OfficeWithChildren } from '../models/office'
import { SingleJobProjectedHires } from '../models/projected-hires'
import { SortingOptions
} from '../models/sorting-options'
import { SourceWithChildren } from '../models/source'
import { Tab } from '../models/tab'
import { arrayToTreeWithDepth, flattenChildren, objKeysSafe } from '../../shared/utils/general-utils'
import { jobWithNameWithIds } from '../../shared/utils/job-utils'
import { sortTabs } from '../../shared/utils/sorting/tab-utils'

export interface WallState {
  jobApplications: fromJobApplication.State
  jobs: fromJob.State
  jobNames: fromJobNames.State
  candidates: fromCandidate.State
  tagMappings: fromTagMapping.State
  jobStages: fromJobStage.State
  wallSettings: fromWallSettings.State
  offices: fromOffice.State
  departments: fromDepartment.State,
  sources: fromSource.State,
  externalUsers: fromExternalUser.State
  tabs: fromTab.State
  layout: fromLayout.State
  jobStageStats: fromJobStageStats.State
  interviews: fromInterview.State
  scorecards: fromScorecard.State
  notes: fromNote.State
  activities: fromActivity.State
  rejectionReasons: fromRejectionReason.State
  emailTemplates: fromEmailTemplate.State
  candidateTags: fromCandidateTags.State
  users: fromUsers.State,
  jobAnalytics: fromJobAnalytics.State,
  stageMappings: fromStageMappings.StageMappingsState,
  executiveDashboard: fromExecutiveDashboard.ExecutiveDashboardState,
  projectedHires: fromProjectedHires.State,
  sourcingUsers: fromSourcingUser.State
}

export interface State extends fromRoot.State {
  wall: WallState
}

export const reducers: ActionReducerMap<WallState> = {
  activities: fromActivity.reducer,
  jobApplications: fromJobApplication.reducer,
  jobs: fromJob.reducer,
  jobNames: fromJobNames.reducer,
  candidates: fromCandidate.reducer,
  tagMappings: fromTagMapping.reducer,
  jobStages: fromJobStage.reducer,
  wallSettings: fromWallSettings.reducer,
  offices: fromOffice.reducer,
  departments: fromDepartment.reducer,
  sources: fromSource.reducer,
  externalUsers: fromExternalUser.reducer,
  tabs: fromTab.reducer,
  layout: fromLayout.reducer,
  jobStageStats: fromJobStageStats.reducer,
  interviews: fromInterview.reducer,
  scorecards: fromScorecard.reducer,
  notes: fromNote.reducer,
  rejectionReasons: fromRejectionReason.reducer,
  emailTemplates: fromEmailTemplate.reducer,
  candidateTags: fromCandidateTags.reducer,
  users: fromUsers.reducer,
  jobAnalytics: fromJobAnalytics.reducer,
  stageMappings: fromStageMappings.reducer,
  executiveDashboard: fromExecutiveDashboard.reducer,
  projectedHires: fromProjectedHires.reducer,
  sourcingUsers: fromSourcingUser.reducer
}

export const selectWallState = createFeatureSelector<WallState>('wall')

/************************************
 * Begin reducer slice selectors
 ************************************/
export const selectJobEntitiesState = createSelector(
  selectWallState,
  state => state.jobs,
)

export const selectJobApplicationEntitiesState = createSelector(
  selectWallState,
  state => state.jobApplications,
)

export const selectCandidateEntitiesState = createSelector(
  selectWallState,
  state => state.candidates,
)

export const selectTagMappingEntitiesState = createSelector(
  selectWallState,
  state => state.tagMappings,
)

export const selectJobStageEntitiesState = createSelector(
  selectWallState,
  state => state.jobStages,
)

export const selectAllJobStageNames = createSelector(
  selectWallState,
  state => state.jobStages.allNames
)

export const selectDepartmentEntitiesState = createSelector(
  selectWallState,
  state => state.departments,
)

export const selectSourceEntitiesState = createSelector(
  selectWallState,
  state => state.sources
)

export const selectExternalUserEntitiesState = createSelector(
  selectWallState,
  state => state.externalUsers,
)

export const selectSourcingUserEntitiesState = createSelector(
  selectWallState,
  state => state.sourcingUsers,
)

export const selectOfficeEntitiesState = createSelector(
  selectWallState,
  state => state.offices,
)

export const selectTabEntitiesState = createSelector(
  selectWallState,
  state => state.tabs,
)

export const selectInterviewEntitiesState = createSelector(
  selectWallState,
  state => state.interviews,
)

export const selectScorecardEntitiesState = createSelector(
  selectWallState,
  state => state.scorecards,
)

export const selectNoteEntitiesState = createSelector(
  selectWallState,
  state => state.notes,
)

export const selectActivityEntitiesState = createSelector(
  selectWallState,
  state => state.activities,
)

export const selectWallSettings = createSelector(
  selectWallState,
  state => state.wallSettings,
)

export const selectLayout = createSelector(
  selectWallState,
  state => state.layout,
)

export const selectWallSummaryMode = createSelector(
  selectLayout,
  state => state.wallSummaryMode,
)

export const selectCalendarWeek = createSelector(
  selectLayout,
  state => state.calendarWeek,
)

export const selectEmailTemplateEntitiesStore = createSelector(
  selectWallState,
  state => state.emailTemplates,
)

export const selectRejectionReasonEntitiesStore = createSelector(
  selectWallState,
  state => state.rejectionReasons,
)

export const selectExecutiveDashboardState = createSelector(
  selectWallState,
  state => state.executiveDashboard
)

export const selectExecutiveDashboardConfig = createSelector(
  selectExecutiveDashboardState,
  state => state.executive_dashboard_configuration
)

export const selectExecutiveDashboardTabs = createSelector(
  selectExecutiveDashboardConfig,
  state => sortBy(state.tabs || [], 'position')
)

export const selectExecutiveDashboardDaysOpenColors = createSelector(
  selectExecutiveDashboardState,
  state => state.job_days_open_colors
)

export const selectPerJobConfiguration = createSelector(
  selectExecutiveDashboardState,
  state => state.per_job_configuration
)

export const selectPerJobSpecificConfiguration = createSelector(
  selectExecutiveDashboardState,
  (state: ExecutiveDashboardState, props) => state.per_job_configuration[props.jobId]
)

export const selectCandidateFilters = createSelector(
  selectLayout,
  state => state.candidateFilters,
)

export const selectCandidateTagFilters = createSelector(
  selectLayout,
  (state): Array<string> =>
    objKeysSafe(state.candidateFilters.matchingTagFilters)
      .filter(tag => state.candidateFilters.matchingTagFilters[tag]),
)

export const selectCandidateTagEntitiesState = createSelector(
  selectWallState,
  state => state.candidateTags,
)

export const selectUsersState = createSelector(
  selectWallState,
  state => state.users,
)

export const selectJobStageStats = createSelector(
  selectWallState,
  state => state.jobStageStats,
)

export const selectJobStageConversionRates = createSelector(
  selectJobStageStats,
  state => state.conversionRatesByStageByJob
)

export const getSelectConversionRatesForJob = (jobId) => createSelector(
  selectJobStageConversionRates,
  rates => rates ? rates[jobId] : null
)

export const selectJobAnalyticsState = createSelector(
  selectWallState,
  state => state.jobAnalytics
)

export const selectJobAnalytics = createSelector(
  selectJobAnalyticsState,
  state => state.jobAnalytics
)

export const selectEeocFilters = createSelector(
  selectJobAnalyticsState,
  state => state.eeocFilters
)

export const getSelectJobAnalytics = (jobId) => createSelector(
  selectJobAnalytics,
  state => state[`${jobId}`]
)

export const getSelectEeocJobAnalytics = (jobId) => createSelector(
  selectJobAnalyticsState,
  state => state.eeocFilteredJobAnalytics[jobId]
)

export const getJobNamesFromWall = createSelector(
  selectWallState,
  state => state.jobNames
)

export const getClosedJobNamesFromWall = createSelector(
  getJobNamesFromWall,
  state => state.closed_jobs
)

export const getOpenJobNamesFromWall = createSelector(
  getJobNamesFromWall,
  state => state.open_jobs
)

export const selectProjectedHires = createSelector(
  selectWallState,
  state => state.projectedHires
)

/************************************
 * End reducer slice selectors
 ************************************/

/************************************
 * Begin adapter selectors
 ************************************/
export const {
  selectIds: selectJobIds,
  selectEntities: selectJobEntities,
  selectAll: selectAllJobs,
  selectTotal: selectJobTotal,
} = fromJob.adapter.getSelectors(selectJobEntitiesState)

export const {
  selectIds: selectJobApplicationIds,
  selectEntities: selectJobApplicationEntities,
  selectAll: selectAllJobApplications,
  selectTotal: selectJobApplicationTotal,
} = fromJobApplication.adapter.getSelectors(selectJobApplicationEntitiesState)

export const {
  selectIds: selectCandidateIds,
  selectEntities: selectCandidateEntities,
  // NOTE: This should probably not be used -- use entities instead for performance reasons
  // selectAll: selectAllCandidates,
  selectTotal: selectCandidateTotal,
} = fromCandidate.adapter.getSelectors(selectCandidateEntitiesState)

export const {
  selectIds: selectTagMappingIds,
  selectEntities: selectTagMappingEntities,
  selectAll: selectAllTagMappings,
  selectTotal: selectTagMappingTotal,
} = fromTagMapping.adapter.getSelectors(selectTagMappingEntitiesState)

export const {
  selectIds: selectJobStageIds,
  selectEntities: selectJobStageEntities,
  selectAll: selectAllJobStages,
  selectTotal: selectJobStageTotal,
} = fromJobStage.adapter.getSelectors(selectJobStageEntitiesState)

export const {
  selectIds: selectOfficeIds,
  selectEntities: selectOfficeEntities,
  selectAll: selectAllOffices,
  selectTotal: selectOfficeTotal,
} = fromOffice.adapter.getSelectors(selectOfficeEntitiesState)

export const {
  selectIds: selectDepartmentIds,
  selectEntities: selectDepartmentEntities,
  selectAll: selectAllDepartments,
  selectTotal: selectDepartmentTotal,
} = fromDepartment.adapter.getSelectors(selectDepartmentEntitiesState)

export const {
  selectIds: selectSourceIds,
  selectEntities: selectSourceEntities,
  selectAll: selectAllSources,
  selectTotal: selectSourceTotal,
} = fromSource.adapter.getSelectors(selectSourceEntitiesState)

export const {
  selectIds: selectExternalUserIds,
  selectEntities: selectExternalUserEntities,
  selectAll: selectAllExternalUsers,
  selectTotal: selectExternalUserTotal,
} = fromExternalUser.adapter.getSelectors(selectExternalUserEntitiesState)

export const {
  selectIds: selectSourcingUserIds,
  selectEntities: selectSourcingUserEntities,
  selectAll: selectAllSourcingUsers,
  selectTotal: selectSourcingUserTotal,
} = fromExternalUser.adapter.getSelectors(selectSourcingUserEntitiesState)

export const selectActiveExternalUsers =
  createSelector(selectAllExternalUsers, eus => eus.filter(eu => !eu.disabled))

export const {
  selectIds: selectTabIds,
  selectEntities: selectTabEntities,
  selectAll: selectAllTabs,
  selectTotal: selectTabTotal,
} = fromTab.adapter.getSelectors(selectTabEntitiesState)

export const {
  selectIds: selectInterviewIds,
  selectEntities: selectInterviewEntities,
  selectAll: selectAllInterviews,
  selectTotal: selectInterviewTotal,
} = fromInterview.adapter.getSelectors(selectInterviewEntitiesState)

export const {
  selectIds: selectScorecardIds,
  selectEntities: selectScorecardEntities,
  selectAll: selectAllScorecards,
  selectTotal: selectScorecardTotal,
} = fromScorecard.adapter.getSelectors(selectScorecardEntitiesState)

export const {
  selectIds: selectNoteIds,
  selectEntities: selectNoteEntities,
  selectAll: selectAllNotes,
  selectTotal: selectNoteTotal,
} = fromNote.adapter.getSelectors(selectNoteEntitiesState)

export const {
  selectIds: selectActivityIds,
  selectEntities: selectActivityEntities,
  selectAll: selectAllActivities,
  selectTotal: selectActivityTotal,
} = fromActivity.adapter.getSelectors(selectActivityEntitiesState)

export const {
  selectIds: selectRejectionReasonIds,
  selectEntities: selectRejectionReasonEntities,
  selectAll: selectAllRejectionReasons,
  selectTotal: selectRejectionReasonTotal,
} = fromRejectionReason.adapter.getSelectors(selectRejectionReasonEntitiesStore)

export const {
  selectIds: selectEmailTemplateIds,
  selectEntities: selectEmailTemplateEntities,
  selectAll: selectAllEmailTemplates,
  selectTotal: selectEmailTemlpateTotal,
} = fromEmailTemplate.adapter.getSelectors(selectEmailTemplateEntitiesStore)


export const {
  selectIds: selectCandidateTagIds,
  selectEntities: selectCandidateTagEntities,
  selectAll: selectAllCandidateTags,
  selectTotal: selectCandidateTagTotal,
} = fromCandidateTags.adapter.getSelectors(selectCandidateTagEntitiesState)

export const {
  selectIds: selectUsersIds,
  selectEntities: selectUsersEntities,
  selectAll: selectAllUsers,
  selectTotal: selectUsersTotal,
} = fromUsers.adapter.getSelectors(selectUsersState)

export const getSelectActivitiesForCandidate = (candidateId) =>
  createSelector(selectAllActivities, activities => activities.filter(a => a.candidate_id === candidateId))

export const selectExecutiveDashboardClosedJobs =
   (state: State) => state.wall.executiveDashboard?.closed_jobs_info?.jobs
export const selectExecutiveDashboardClosedJobsById = createSelector(
  selectExecutiveDashboardClosedJobs,
  jobs => Object.fromEntries(jobs?.map(job => [job.id, job]) || [])
)

/************************************
 * End adapter selectors
 ************************************/

/************************************
 * Begin simple derivative selectors
 ************************************/

export const getAllJobProjectedHires =
  createSelector(selectProjectedHires, (state) => state.projectedHires)

export const getProjectedHiresForJob = (jobId: string) =>
  createSelector(getAllJobProjectedHires, selectExecutiveDashboardClosedJobs,
    (state, closedJobs) => {
      if (closedJobs && closedJobs.find(job => job.id === jobId)) {
        const ret: SingleJobProjectedHires = {
          final_projected_hires: NaN,
          stage_projection_info: {},
        }
        return ret
      }
      return state[jobId]
    })

export const getProjectedHiresNumberForJob = (jobId: string) =>
  createSelector(
    getProjectedHiresForJob(jobId),
    state => state?.final_projected_hires
  )

export const getNeedsToFetchProjectedHires =
    createSelector(selectProjectedHires, (state) => !state.didFetch && !state.isFetching)

export const selectJobsNamesWithIdNames = createSelector(
  getOpenJobNamesFromWall,
  (jobNames) => jobNames.map(jobName => jobWithNameWithIds(jobName)),
)

export const selectClosedJobsNamesWithIdNames = createSelector(
  getClosedJobNamesFromWall,
  (jobNames) => jobNames.map(closedJob => jobWithNameWithIds(closedJob))
)

export const selectJobsForFilterState = createSelector(
  selectJobsNamesWithIdNames,
  jobNames => jobNames as Job[]
)

export const selectClosedJobsForFilterState = createSelector(
  selectClosedJobsNamesWithIdNames,
  fromRoot.selectAppConfig,
  jobNames => jobNames as Job[]
)

export const selectActiveTabId = createSelector(
  selectLayout,
  state => state.activeTabId,
)

export const selectAllJobsFilters = createSelector(
  selectLayout,
  state => state.allJobsFilters,
)

export const selectJobsViewModeByJobId = createSelector(
  selectLayout,
  state => state.jobsViewModeByJobId,
)

export const selectJobViewModeByJobId = (jobId) => createSelector(
  selectLayout,
  state => state.jobsViewModeByJobId[jobId] || fromLayout.JobViewMode.Wall,
)

export const selectCreditedToIds = createSelector(
  selectLayout,
  state => state.creditedToIds,
)

export const selectCurrentJobIdToViewInWall = createSelector(
  selectLayout,
  state => state.showSingleJobId,
)

export const selectCollapseAllJobs = createSelector(
  selectLayout,
  state => state.collapseAllJobs,
)

export const selectActiveJobs = createSelector(
  selectAllJobs,
  jobs => jobs.filter(job => job.status === JOB_STATUS_ACTIVE),
)

export const selectActiveJobApplications = createSelector(
  selectAllJobApplications,
  jobApplications =>
    jobApplications.filter(jobApp => jobApp.status === JOB_APPLICATION_STATUS_ACTIVE)
)

export const selectHiredJobApplications = createSelector(
  selectAllJobApplications,
  jobApplications =>
    jobApplications.filter(jobApp => jobApp.status === JOB_APPLICATION_STATUS_HIRED)
)

// WallSettings
export const selectActivityDayCategories = createSelector(
  selectWallSettings,
  wallSettings => wallSettings && wallSettings.wallSettings && wallSettings.wallSettings.activity_day_categories,
)

export const selectActivityLabels = createSelector(
  selectWallSettings,
  wallSettings => wallSettings && wallSettings.wallSettings && wallSettings.wallSettings.activity_day_labels
)

export const selectJobStatusLabels = createSelector(
  selectWallSettings,
  wallSettings => wallSettings && wallSettings.wallSettings && wallSettings.wallSettings.job_status_settings
)

export const selectSourceTypeIconMappings = createSelector(
  selectWallSettings,
  wallSettings =>
    wallSettings.wallSettings &&
    wallSettings.wallSettings.source_type_icon_mappings,
)

export const selectAtsUrl = createSelector(
  selectWallSettings,
  wallSettings => wallSettings?.wallSettings?.base_url,
)

// EmailTemplates
export const selectRejectionEmailTemplates = createSelector(
  selectAllEmailTemplates,
  emailTemplates =>
    emailTemplates.filter(et => et.kind === EMAIL_TEMPLATE_KIND_REJETION),
)

export const selectJobStagesByJobId = createSelector(
  selectAllJobStages,
  jobStages => _groupBy(jobStages, stage => stage.job_id),
)

export const selectInterviewsByJobApplicationId = createSelector(
  selectAllInterviews,
  interviews => _groupBy(interviews, interview => interview.job_application_id),
)

export const selectAllJobsLoadedInCurrentWall = createSelector(
  selectLayout,
  state => state.currentWallViewAllJobsLoaded
)

export const selectRequestAllJobsToLoadInCurrentWall = createSelector(
  selectLayout,
  state => state.requestAllJobsToLoad
)

export const selectVisibleJobIdsInCurrentWall = createSelector(
  selectLayout,
  state => state.currentWallViewListOfJobIds
)

export const selectVisibleJobsInCurrentWall = createSelector(
  selectVisibleJobIdsInCurrentWall,
  selectJobEntities,
  (jobIds, jobs) => jobIds.map(id => jobs[id])
)

export const selectDepartmentsTree = createSelector(
  selectAllDepartments,
  departments => arrayToTreeWithDepth(departments) as DepartmentWithChildren[],
)

export const selectOfficesTree = createSelector(
  selectAllOffices,
  offices => arrayToTree(offices) as OfficeWithChildren[],
)

export const selectSourcesTree = createSelector(
  selectAllSources,
  sources => arrayToTree(sources) as SourceWithChildren[],
)

export const selectDepartmentsWithChildren = createSelector(
  selectDepartmentsTree,
  departmentsTree => flattenChildren(departmentsTree),
)
export const selectDepartmentsWithChildrenById = createSelector(
  selectDepartmentsWithChildren,
  departmentsWithChildren => _keyBy(departmentsWithChildren, 'id'),
)

export const selectOfficesWithChildren = createSelector(
  selectOfficesTree,
  officesTree => flattenChildren(officesTree),
)
export const selectOfficesWithChildrenById = createSelector(
  selectOfficesWithChildren,
  officesWithChildren => _keyBy(officesWithChildren, 'id'),
)

export const selectSourcesWithChildren = createSelector(
  selectSourcesTree,
  sourcesTree => flattenChildren(sourcesTree),
)
export const selectSourcesWithChildrenById = createSelector(
  selectSourcesWithChildren,
  sourcesWithChildren => _keyBy(sourcesWithChildren, 'id'),
)

export const selectAllJobsSortingOptions: MemoizedSelector<State, SortingOptions> = createSelector(
  selectLayout,
  state => state.allJobsSortingOptions
)

export const selectExecutiveDashboardSortingOptions: MemoizedSelector<State, SortingOptions> = createSelector(
  selectLayout,
  state => state.executiveDashboardSortingOptions
)

export const selectExecutiveDashboard = createSelector(
  selectWallState,
  (state: WallState) => state.executiveDashboard
)

export const selectExecutiveDashboardLastFetchedJobIds = createSelector(
  selectExecutiveDashboard,
  state => state.last_fetched_tab_job_ids,
)

export const selectExecutiveDashboardLoaded = createSelector(
  selectWallState,
  (state: WallState) => state.executiveDashboard.loaded
)

export const selectClosedJobStageConversionRates = createSelector(
  selectExecutiveDashboard,
  state => state?.closed_jobs_info?.job_stage_conversion_stats
)

export const executiveDashboardSelector = createSelector(
  selectWallState,
  state => state.executiveDashboard,
)

export const selectOffersPerJobExecDash = createSelector(
  selectExecutiveDashboard,
  state => state?.offers_per_job || {}
)

export const selectOffersPerClosedJobExecDash = createSelector(
  selectExecutiveDashboard,
  state => state?.closed_jobs_info?.offers_per_job || {}
)

export const selectRecentCandidatesPerJobExecDash = createSelector(
  selectExecutiveDashboard,
  state => state?.recent_candidates_per_job || {}
)

export const selectSortedWallTabs = createSelector(
  selectAllTabs,
  (tabs: Tab[]): Tab[] => sortTabs(tabs)
)
/************************************
 * End simple derivative selectors
 ************************************/

/**************************************
 * Begin compound derivative selectors
 **************************************/

export const selectActiveTab = createSelector(
  selectTabEntities,
  selectActiveTabId,
  (tabEntities, activeTabId) => tabEntities[activeTabId],
)

export const selectAllJobsFiltersAndSortingOptions = createSelector(
  selectAllJobsFilters,
  selectAllJobsSortingOptions,
  (filters, sortingOptions) => ({
    filters,
    sortingOptions,
  })
)

export const getExecutiveDashboardClosedJobsTab = createSelector(
  selectExecutiveDashboardState,
  state => state.closed_jobs_tab,
)

export const getExecutiveDashboardTabById = (id: number) =>
  id === EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID ?
    getExecutiveDashboardClosedJobsTab :
    createSelector(
      selectExecutiveDashboardTabs,
      selectExecutiveDashboard,
      (tabs, execDash) => {
        if (execDash.shared_tab?.id === id) {
          return execDash.shared_tab
        }
        return tabs.find(tab => tab.id === id)
      }
    )

export const getDefaultExecutiveDashboardTab = () =>
  createSelector(
    selectExecutiveDashboardTabs,
    (tabs) => tabs.find(tab => tab.default_tab)
  )

/**************************************
 * End compound derivative selectors
 **************************************/
