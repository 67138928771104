import * as fromNotesUI from './notes-ui.reducer'
import * as fromRejectCandidateUI from './reject-candidate-ui.reducer'
import * as fromRoot from '../../reducers'
import * as fromTransferCandidateUI from './transfer-candidate-ui.reducer'
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store'

export interface CardDetailsState {
  notesUI: fromNotesUI.State
  transferCandidateUI: fromTransferCandidateUI.State
  rejectCandidateUI: fromRejectCandidateUI.State
}

export const reducers: ActionReducerMap<CardDetailsState> = {
  notesUI: fromNotesUI.reducer,
  transferCandidateUI: fromTransferCandidateUI.reducer,
  rejectCandidateUI: fromRejectCandidateUI.reducer,
}

export interface State extends fromRoot.State {
  cardDetails: CardDetailsState
}

export const selectCardDetailsState = createFeatureSelector<CardDetailsState>(
  'cardDetails',
)

export const selectNotesUI = createSelector(
  selectCardDetailsState,
  state => state.notesUI,
)

export const selectTransferCandidateUI = createSelector(
  selectCardDetailsState,
  state => state.transferCandidateUI,
)

export const selectRejectCandidateUI = createSelector(
  selectCardDetailsState,
  state => state.rejectCandidateUI,
)

/************************************
 * Begin simple derivative selectors
 ************************************/
