import { Observable, Subject, merge } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators'

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap'

import { FilterOption } from '../filter-option.interface'

@Component({
  selector: 'twng-input-filter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
})
export class InputFilterComponent {

  @ViewChild('instance', { static: true }) instance: NgbTypeahead
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @Input()
    placeholder: string

  private _selectedOptions: FilterOption[] = []

  @Input()
  get selectedOptions() {
    return this._selectedOptions
  }
  set selectedOptions(newSelectedOptions: FilterOption[]) {
    this._selectedOptions = newSelectedOptions
    // remove options with null value
    for (let i=this._selectedOptions.length-1; i >= 0; i--) {
      if (!this._selectedOptions[i]) {
        this._selectedOptions.splice(i, 1)
      }
    }
  }

  @Input()
    filterItems: FilterOption[] = []

  @Input()
    disabled = false

  @Output()
    optionSelected = new EventEmitter<FilterOption>()

  @Output()
    removeOption = new EventEmitter<FilterOption>()

  optionsListFormatter = (result: FilterOption) => result.name

  inputFormatter = (_result: FilterOption): string => ''

  /**
   * Filters the list returning the first 10 items
   *
   * @param term
   */
  filterList(term: string): FilterOption[] {
    const cleanTerm = term.toLowerCase()
    return this.filterItems.filter((v: FilterOption) => v.name.toLowerCase().indexOf(cleanTerm) > -1).slice(0, 10)
  }

  search = (text$: Observable<string>): Observable<readonly unknown[]> => {
    const debouncedText$ = text$.pipe(
      debounceTime(250),
      distinctUntilChanged(),
    )
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.filterItems : this.filterList(term))),
    )
  }

  selectItem(selectItemEvent: NgbTypeaheadSelectItemEvent) {
    if (!this.disabled) {
      this.optionSelected.emit(selectItemEvent.item)
    }
  }

  removeSelectedOption(selectedOption: FilterOption) {
    if (!this.disabled) {
      this.removeOption.emit(selectedOption)
    }
  }
}
