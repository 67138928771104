import { Observable, of } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { Action } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import {
  CoreTabActionsTabActionTypes, ReorderTabs, ReorderTabsFailure, ReorderTabsSuccess
} from '../actions/tab.actions'
import { Tab } from '../../wall/models/tab'
import { TabType } from '../models/tab.type'
import { apiHost, getHttpPostOptions } from '../http-options'
import { dashboardTabType } from '../../dashboard/reducers/dashboard-tabs.reducer'

interface TabsResponse {
  tabs: Tab[]
}

@Injectable()
export class TabEffects {

  private getReorderEndpoint(type: TabType): string {
    if (type === dashboardTabType) {
      return `${apiHost}/twng/dashboard/tabs/reorder`
    }
    return `${apiHost}/twng/tabs/reorder`
  }

  reorderTabs$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ReorderTabs>(CoreTabActionsTabActionTypes.ReorderTabs),
    map(action => action.payload),
    mergeMap(({ type, tabs, oldTabs }) => {
      if (window.twng_demo) {
        return of(new ReorderTabsSuccess({ type, tabs, oldTabs }))
      }

      const tabsPayload = tabs.reduce((acc, tab) => {
        acc[tab.id] = tab.position
        return acc
      }, {})

      return this.http
        .post<TabsResponse>(
        this.getReorderEndpoint(type),
        { tabs: tabsPayload },
        getHttpPostOptions(),
      )
        .pipe(
          map(() =>
            new ReorderTabsSuccess({ type, tabs, oldTabs }),
          ),
          catchError(httpResponse => {
            this.showTabMessageForResponse(httpResponse, 'reorder')
            return of(new ReorderTabsFailure({ type, tabs, oldTabs }))
          }),
        )
    }),
  ))

  showTabMessageForResponse(httpResponse, action: string) {
    this.toastr.error(
      `An error occurred while attempting to ${action} a tab.`,
    )
  }

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private toastr: ToastrService,
  ) {
  }
}
