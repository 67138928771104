import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'

import * as fromDashboard from '../../reducers'
import { DashboardChart } from '../../models/dashboard-chart'
import { DisableEditDashboardChartName, UpdateDashboardChart } from '../../actions/filters.actions'
import { Observable } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { selectRenamingChartId } from '../../reducers'

@Component({
  selector: 'twng-chart-label',
  templateUrl: './chart-label.component.html',
  styleUrls: [
    '../dashboard.shared.component.scss',
    './chart-label.component.scss'
  ]
})
export class ChartLabelComponent implements OnInit {

  @ViewChild('inputLabel', { static: false })
    inputLabelRef: ElementRef

  @Input()
    chart: DashboardChart

  @Input()
    label: string

  @Input()
    chartInfo: string

  labelForm: UntypedFormGroup

  editingChartNameId$: Observable<boolean>

  constructor(private store: Store<fromDashboard.State>) {
  }

  ngOnInit(): void {
    this.editingChartNameId$ = this.store.select(selectRenamingChartId).pipe(
      filter(() => !!this.chart),
      map((chartId: string) => chartId === this.chart.id),
      tap((editing: boolean) => {
        if (editing) {
          this.createForm()
        }
      }),
      shareReplay(1)
    )
  }

  disableEditName(): void {
    this.store.dispatch(new DisableEditDashboardChartName())
  }

  createForm(): void {
    this.labelForm = new UntypedFormGroup({
      label: new UntypedFormControl(this.chart.name, [Validators.required]),
    })
    setTimeout(() => {
      this.inputLabelRef?.nativeElement.focus()
    }, 50)
  }

  cleanValue(): void {
    const cleanLabel = this.labelForm.controls.label.value.trim()
    this.labelForm.controls.label.setValue(cleanLabel)
  }

  onSubmit(): void {
    this.cleanValue()

    // If form is invalid (label.length === 0),
    // then don't do anything
    if (this.labelForm.invalid) {
      this.disableEditName()
      return
    }

    // If the user has made a change, dispatch the update
    if (this.labelForm.dirty) {
      this.store.dispatch(new UpdateDashboardChart({
        dashboard_chart: {
          ...this.chart,
          name: this.labelForm.value.label,
        },
      }))
    }

    this.disableEditName()
  }

}
