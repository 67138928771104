<td>
  {{ scheduledReport.dashboard_tab.name }}
  <em *ngIf="scheduledReport.dashboard_tab_view_token"> (With override filters)</em>
</td>
<td>{{ scheduledReport.frequency | titlecase }}</td>
<td>{{ scheduledReport.frequency === 'weekly' ? dayOfWeek[scheduledReport.repetition_day - 1] : scheduledReport.repetition_day }}</td>
<td>{{ scheduledReport.hours | number:'2.0-0' }}:{{ scheduledReport.minutes | number:'2.0-0' }}</td>
<td [ngSwitch]="scheduledReport.destination_type">
  <ng-container *ngSwitchCase="'slack'">
    <ng-container *ngIf="slackUser || slackChannel; else spinner">
      <i class="fas fa-hashtag" *ngIf="slackChannel && !slackChannel.is_private"></i>
      <i class="fas fa-lock" *ngIf="slackChannel && slackChannel.is_private"></i>
      <img width="24" height="24" *ngIf="slackUser" [src]="slackUser.image_24">
      {{ slackChannel?.name || slackUser?.name }}
    </ng-container>
  </ng-container>
</td>
<td>{{ scheduledReport.last_sent ? (scheduledReport.last_sent | timeAgo) : "never" }}</td>
<td>{{ scheduledReport.next_scheduled_time ? (scheduledReport.next_scheduled_time | timeAgo) : "" }}</td>
<td class="text-danger">{{ scheduledReport.last_error }}</td>
<td>
  <twng-spinner size="small" *ngIf="sending"></twng-spinner>
  <button class="btn btn-secondary tw-mr-2" (click)="sendNow()" *ngIf="!sending">Send now</button>
  <button class="btn btn-secondary tw-mr-2" (click)="editReport()">Edit</button>
  <button class="btn btn-danger" mwlConfirmationPopover popoverTitle="Delete Scheduled Report"
    placement="top" popoverMessage="Do you want to delete this report ?" (confirm)="del()"
  >
    Delete
  </button>
  <button class="btn btn-secondary" (click)="reschedule()"
    *ngIf="!scheduledReport.scheduled && !rescheduling">
    Reactivate
  </button>
  <twng-spinner size="small" *ngIf="rescheduling"></twng-spinner>
</td>

<ng-template #spinner>
  <twng-spinner size="small"></twng-spinner>
</ng-template>