import { camelCase as _camelCase } from 'lodash-es'

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'

import { CacheService } from '../../wall/services/cache.service'
import { ChartCommonComponent } from './chart-common.component'
import { ChartsInitialConfig } from '../../core/actions/loader.actions'
import { ClipboardService } from '../../core/services/clipboard.service'
import { DashboardChart } from '../models/dashboard-chart'
import { LegendPosition } from '@swimlane/ngx-charts'
import { NgxChartsRecord } from '../reducers/analytics.reducer'
import { SortChartOption } from '../models/sort-chart-option'
import { Store } from '@ngrx/store'
import { WidgetDataType } from '../../wall/models/offer'

function normalizedDataLabel(label: string) {
  return label
}

function normalizedDataXLabel(x: number) {
  return x + "%"
}

@Component({
  selector: 'twng-dash-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: [
    './dashboard.shared.component.scss',
    './dashboard.component.scss',

    './horizontal-bar.component.scss',
    './horizontal-stacked-bar.component.scss',
  ],
})
export class DashStackedBarChartComponent extends ChartCommonComponent implements OnInit, OnDestroy {

  @Input()
    isVertical = false

  @Input()
    candidateDataType: WidgetDataType

  @Input()
    filtersInCopiedImg: boolean

  @Input()
    legendInCopiedImg: boolean

  @Input()
    dataSource: string

  @Input()
    dataSourceParameters: { [key: string]: string }

  @Input()
    chart: DashboardChart

  @Input()
    editingGridster: boolean

  @Input()
    gridItemType: 'css' | 'gridster' = 'css'

  @Input()
    chartFiltersReadOnly: boolean

  @Input()
    showHeader = true

  @Input()
    normalized = false

  @Input()
    chartInitConfig: ChartsInitialConfig

  view: [number, number]

  normalizedDataLabel = normalizedDataLabel
  normalizedDataXLabel = normalizedDataXLabel

  // @todo: extract to bar component
  barHeightPx = 40

  copyingToClipboard = false

  displayBars: number

  sortChartBy: SortChartOption

  legendRight = LegendPosition.Right
  legendBelow = LegendPosition.Below

  get chartHeight() {
    return this.barHeightPx * (this.slicedData?.length +  1)
  }

  get chartType() {
    return this.isVertical ? "bar-vertical-stacked" : "bar-horizontal-stacked"
  }

  constructor(
    private clipboardService: ClipboardService,
    private cd: ChangeDetectorRef,
    store: Store,
    cacheService: CacheService,
  ) {
    super(store, cacheService)
  }

  ngOnInit() {
    super.ngOnInit()
    window.addEventListener('resize', () => this.setView(this.view))
    this.sortChartBy = this.isVertical ? SortChartOption.Default : SortChartOption.ByValueDesc

    const chartDirection = this.isVertical ? "vertical" : "horizontal"
    this.displayBars = this.chartInitConfig ? this.chartInitConfig[`${chartDirection}_bars_number`] : this.data.length
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    window.removeEventListener('resize', () => this.setView(this.view))
  }

  get slicedData(): NgxChartsRecord[] {
    return this.data?.slice(0, this.displayBars)
  }

  copyChartToClipboard(chartEl: Element, legendInCopiedImg: boolean, filtersInCopiedImg: boolean) {
    this.copyingToClipboard = true
    this.clipboardService.copyChartToClipboard(chartEl, legendInCopiedImg, filtersInCopiedImg).finally(() => {
      this.copyingToClipboard = false
      this.cd.detectChanges()
    })
  }

  camelCase(val: string) {
    return _camelCase(val)
  }

  setView(view: [number, number], _originalContainer?: [number, number]) {
    if (!this.isVertical) {
      const width = view?.length ? view[0] : undefined
      this.view = [width, this.chartHeight]
    } else {
      this.view = view
    }
  }

  viewAllBars = () => {
    this.displayBars = this.data.length
    window.dispatchEvent(new Event('resize'));
  }
}
