import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RatingNumber } from '../../../../../wall/models/rating-number';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-rating-number',
  templateUrl: './rating-number.component.html',
  styleUrls: ['./rating-number.component.scss']
})
export class RatingNumberComponent {

  @Input()
    ratingNumber: RatingNumber

  @Input()
    selected = false

  @Input()
    variant: 'filter' | 'card' | 'small-card' = 'filter'

  @Input()
    tooltipText: string

}
