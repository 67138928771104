import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'

import * as fromWall from '../reducers'
import { AppConfigService } from './app-config.service'
import { Job } from '../models/job'
import { JobApplication } from '../models/job-application'
import { OfferedCandidate } from '../../dashboard/models/offered-candidate'
import { REPLACE_METHOD, STATIC_METHOD } from '../models/app-config'

@Injectable({ providedIn: 'root' })
export class UrlService {
  url$: Observable<string>

  constructor(
    private store: Store<fromWall.State>,
    private appConfig: AppConfigService,
  ) {
    this.url$ = this.store.pipe(
      select(fromWall.selectAtsUrl)
    )
  }

  jobUrl(job: Job): Observable<string> {
    switch (this.appConfig.urlMethod()) {
      case REPLACE_METHOD:
        return this.url$.pipe(
          map(url =>
            this.appConfig
              .jobUrlTemplate()
              .replace('${BASE_URL}', url)
              .replace('${JOB_ID}', job.id.toString()),
          ),
        )
      case STATIC_METHOD:
        return of(job.url)
    }
  }

  jobApplicationUrl(jobApplication: JobApplication): Observable<string> {
    switch (this.appConfig.urlMethod()) {
      case REPLACE_METHOD:
        return this.candidateApplicationUrl(jobApplication.candidate_id, jobApplication.id)
      case STATIC_METHOD:
        return of(jobApplication.url)
    }
  }

  candidateApplicationUrl(candidateId, jobApplicationId) {
    return this.url$.pipe(
      map(url =>
        this.appConfig
          .jobApplicationUrlTemplate()
          .replace('${BASE_URL}', url)
          .replace(
            '${CANDIDATE_ID}',
            candidateId.toString(),
          )
          .replace('${JOB_APPLICATION_ID}', jobApplicationId.toString()),
      ),
    )
  }

  hiresApplicationUrl(hireApplication: OfferedCandidate): Observable<string> {
    switch (this.appConfig.urlMethod()) {
      case REPLACE_METHOD:
        return this.candidateApplicationUrl(hireApplication.candidate_id, hireApplication.job_application_id)
      case STATIC_METHOD:
        return of(hireApplication.url)
    }
  }

  applicationStorecardsUrl(jobApplication: JobApplication): Observable<string> {
    return this.url$.pipe(
      map(url => {
        const str_template = this.appConfig.jobApplicationScorecardUrlTemplate()
        if (url && str_template) {
          return str_template
            .replace('${BASE_URL}', url)
            .replace('${CANDIDATE_ID}', jobApplication.candidate_id.toString())
            .replace('${JOB_APPLICATION_ID}', jobApplication.id.toString())
        }
      }),
    )
  }
}
