<ng-container *ngIf="receivers">
  <input type="text" class="form-control"
    [(ngModel)]="selectedReceiver"
    (ngModelChange)="newReceiverSelected($event)"
    [ngbTypeahead]="search"
    [inputFormatter]="formatter"
    [resultTemplate]="rt"
    [editable]='false'
    *ngIf="!selectedReceiver"
    placeholder="Please select user/channel that will receive this item"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    [disabled]="disabled"/>
  <div *ngIf="selectedReceiver" class="full-w">
    <span style="margin-right: 1rem;">Sending to:</span>
    <span *ngIf="selectedReceiver">
      <template [ngTemplateOutlet]="rt" [ngTemplateOutletContext]="{result: selectedReceiver}"></template>
    </span>
    <button type="button" class="btn btn-secondary float-right"
      (click)="writeValue(null)" [disabled]="disabled">
      Select different receiver
    </button>
  </div>
</ng-container>
<div *ngIf="!receivers">
  Slack data is loading ...
  <twng-spinner size='small'></twng-spinner>
</div>

<ng-template #rt let-r="result" let-t="term">
  <div style="display: inline-block; width: 24px; height: 24px">
    <!-- Shown if this is not user (it is channel) -->
    <ng-container *ngIf="!r.image_24">
      <i class="fas fa-hashtag" *ngIf="!r.is_private"></i>
      <i class="fas fa-lock" *ngIf="r.is_private"></i>
    </ng-container>
    <!-- Shown if this is user -->
    <img width="24" height="24" [src]="r.image_24" *ngIf="r.image_24">
  </div>
  <ngb-highlight style="margin-left: 1rem" [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>