/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivatedRouteSnapshot } from '@angular/router'
import { ConversionRatesRecord } from '../../wall/reducers/job-stage-stats.reducer'
import { DropoffAnalytics } from '../../wall/reducers/job-analytics.reducer'
import { FilterOption } from '../components/filters/generic-filter/filter-option.interface'
import { NgxChartsRecord } from '../../dashboard/reducers/analytics.reducer'
import { Type } from '@angular/core'
import { cloneDeep, flatten, keyBy, values } from 'lodash-es'
import { json2csv } from 'json-2-csv'
import { map } from 'rxjs/operators'
import { sortByName } from '../../wall/reducers/sort-comparers'

export function objKeysSafe(obj: Record<string, any> | undefined | null) {
  return Object.keys(obj || {})
}

export function objValuesSafe<T>(obj: Record<string, T> | undefined | null) {
  return Object.values(obj || {})
}

export function present<T>(lengthyObj: T) {
  return objKeysSafe(lengthyObj).length > 0
}

export function empty<T>(lengthyObj: T) {
  return !present(lengthyObj)
}

// a function for debugging, you can send it to pipe, etc
export function spit(obj: any, message: string = null) {
  if (message) {
    console.log(message, obj)
  } else {
    console.log(obj)
  }

  return obj
}

export function spitInPipe(message: string = null) {
  return map(x => spit(x, message))
}

export interface Parent {
  id: any
  children: Parent[]
}

// TODO: type this
export function arrayToTreeWithDepth(list: any): any {
  const byIds = keyBy(cloneDeep(list), x => x.id)
  const roots = []
  values(byIds).forEach(x => {
    const parent = byIds[x.parent_id]
    if (parent) {
      if (!parent.children) {
        parent.children = []
      }
      parent.children.push(x)
    } else {
      roots.push(x)
    }
  })
  const markDepth = (item, depth) => {
    item.depth = depth
    if (item.children) {
      item.children.forEach(child => markDepth(child, depth+1))
    }
  }
  const deepSort = (toSort) => {
    toSort.sort(sortByName)
    toSort.forEach((child) => {
      if(child.children) {
        deepSort(child.children)
      }
    })
  }
  deepSort(roots)
  roots.forEach(x => markDepth(x, 0))
  return roots
}

export function flattenChildren(arrayWithChildren: Parent[], depth = 0) {
  if (!arrayWithChildren) {
    return []
  }
  return flatten(arrayWithChildren.map(obj => [
    { ...obj, depth },
    ...flattenChildren(obj.children, depth + 1)
  ])).filter(o => o)
}

export function resourceAndDescendantIds(resourcesWithChildren: Parent[]) {
  if (!resourcesWithChildren) {
    return []
  }

  return flatten(resourcesWithChildren.filter(r => r).map(resource => [
    resource.id,
    ...resourceAndDescendantIds(resource.children),
  ]))
}

export function findIdsInChartData(data: NgxChartsRecord) {
  return data.job_application_ids || data.job_ids || data.offer_ids || data.scorecard_ids || data.job_opening_ids ||
    data.interview_ids || data.demographic_answer_ids || data.outreach_ids
}

export function saveBlob(blob: Blob, filename: string) {
  const anchor = document.createElement('a')

  anchor.download = filename
  anchor.href = (window.webkitURL || window.URL).createObjectURL(blob)
  anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':')
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor);
  (window.webkitURL || window.URL).revokeObjectURL(anchor.href)
}

export function exportCsv(data: any[], filename: string, keys: string[]) {

  const options = {
    keys,
    emptyFieldValue: '',
    // expandArrayObjects: true,
  }

  const callback = (err, csv) => {
    if (err) {
      console.log("error converting to csv", err, csv)
      return
    }
    const blob = new Blob([csv], { type: 'text/plain' })
    saveBlob(blob, filename)
  }
  json2csv(data, callback, options)
}

// We should use this instead of !environment.production because we want to be
// able to test the project locally under production environment sometimes.
export function isHostedLocally() {
  return document.URL.startsWith('http://localhost') || document.URL.startsWith('http://127.0.0.1')
}

export function daysDifference(date1: Date, date2: Date) {
  const diffTime = date2.getTime() - date1.getTime()
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export function weightedMean(arrValues: number[], arrWeights: number[]) {

  const result = arrValues.map((value, i) => {

    const weight = arrWeights[i]
    const sum = value * weight

    return [sum, weight]
  }).reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0])

  return result[0] / result[1]
}

export function joinExistingStrings(strings: string[], delimeter: string) {
  return (strings || []).filter(s => !!s).join(delimeter)
}

// returns true if they are same
export function jsonCompare(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b)
}

export function replaceAllInString(str: string, search: string | RegExp, replaceWith: string) {
  let lastStr: string
  do {
    lastStr = str
    str = str.replace(search, replaceWith)
  } while (lastStr !== str)
  return str
}

export function randomString(length: number) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function shortDateString(date: Date | string) {
  // Date string example "2021-01-01" === 10 characteres
  if (typeof (date) === 'string' && date.length === 10) {
    // Date without timezone is parsed to wrong day
    const parsedString = date.substring(0,10).split('-')
    return(parsedString[1] + '/' + parsedString[2] + '/' + parsedString[0])
  } else {
    date = new Date(date)
  }
  return (date.getMonth() + 1) + '/' + date.getDate() + '/' + (date.getFullYear() - 2000)
}

export function enumToFilterOptions(enumObj: Record<string, any>): FilterOption[] {
  return objKeysSafe(enumObj)
    .filter(enumText => isNaN(parseInt(enumText, 10)))
    .map(
      (enumText) => ({
        id: enumObj[enumText],
        name: enumText
      })
    )
}

let globalCounterId = 0
export function getUniqueId() {
  return globalCounterId++
}

// recursively find activated route which contains component that you pass as "componentClass"
export function findActivatedRouteWithComponent(route: ActivatedRouteSnapshot, componentClass: Type<any>):
ActivatedRouteSnapshot {
  if (route.component === componentClass) {
    return route
  }
  for (const child of route.children) {
    const ret = findActivatedRouteWithComponent(child, componentClass)
    if (ret) {
      return ret
    }
  }
}

export function getMonthFullName(month0indexed: number) {
  return ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ][month0indexed]
}

export function getCurrentMonthFullName() {
  return getMonthFullName(new Date().getUTCMonth())
}

export function fillMissingKeys<T>(obj: Record<string, T>, allDesiredKeys: string[], fillValue: T) {
  const objKeys = objKeysSafe(obj)
  for (const key of allDesiredKeys) {
    if (!objKeys.includes(key)) {
      obj[key] = cloneDeep(fillValue)
    }
  }
}

export function totalProcessed(conversionRate: ConversionRatesRecord) {
  if (!conversionRate) {
    return 0
  }
  return (conversionRate.passed_through || 0) + (conversionRate.rejected || 0)
}

export function numOr100(num: number) {
  return isNaN(num) ? 100 : num
}

export function calcJobAnalyticsMaxHeight(analytics: DropoffAnalytics) {
  if (analytics) {
    return Math.max(
      ...objValuesSafe(analytics).map(rates =>
        (rates.active || 0) +
        (rates.passed_through || 0) +
        (rates.rejected || 0)
      )
    )
  } else {
    return 0
  }
}

export function widthOfChildren(el: HTMLElement) {
  let width = 0
  for (let i=0; i<el.children.length; i++) {
    width += el.children.item(i).clientWidth
  }
  return width
}

// Waits specified amount of ms. If 0 is passed, it will wait for next browser
// rendering frame. NOTE: In rare instances, setTimeout(..., 0) will execute
// before requestAnimationFrame, but that can only happen on really slow
// graphics hardware
export function wait(ms: number) {
  return new Promise((resolve) => {
    if (ms === 0) {
      requestAnimationFrame(resolve)
    } else {
      setTimeout(resolve, ms)
    }
  })
}

export const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

export function quarterYear(firstFiscalMonth = 0, date: Date = new Date()): number {
  const currentMonth = date.getMonth()
  return currentMonth >= firstFiscalMonth ? date.getFullYear() : date.getFullYear() - 1
}

export function allQuartersStart(firstFiscalMonth, date = new Date()) {
  const quarters = [1, 2, 3, 4]
  let quartersStarts = {}
  quarters.forEach((q, i) => {
    let month = firstFiscalMonth + (i*3)
    let year = date.getFullYear()

    if(month > 12) {
      month -= 12
    }
    if (date.getMonth() >= firstFiscalMonth){
      year += 1
    }
    if (month >= firstFiscalMonth){
      year -= 1
    }

    quartersStarts =  { ...quartersStarts, [q]: new Date(year, month, 1)}
  })

  return quartersStarts
}

export function currentQuarter(firstFiscalMonth, date = new Date()) {
  const quarterIndex = [0, 1, 2, 3]
  const monthsByQuarter = quarterIndex.map(q => {
    const currentMonth = (firstFiscalMonth + 1) + (q * 3)
    return [currentMonth, currentMonth + 1, currentMonth + 2].map(v =>((v - 1) % 12) + 1 )
  })

  const currentQuarterArray = monthsByQuarter.find(months => months.includes(date.getMonth() + 1))
  return monthsByQuarter.indexOf(currentQuarterArray) + 1
}

export function startOfQuarter(firstFiscalMonth, date = new Date()) {
  return allQuartersStart(firstFiscalMonth)[currentQuarter(firstFiscalMonth, date)]
}

export function previousQuarterStart(firstFiscalMonth, date = new Date()) {
  const currentQuart = currentQuarter(firstFiscalMonth, date)
  if (currentQuart === 1) {
    date.setFullYear(date.getFullYear() - 1)
    return allQuartersStart(firstFiscalMonth, date)[4]
  } else {
    return allQuartersStart(firstFiscalMonth, date)[currentQuart - 1]
  }
}
