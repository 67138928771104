import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-job-stage-name',
  templateUrl: './job-stage-name.component.html',
  styleUrls: ['./job-stage-name.component.scss']
})
export class JobStageNameComponent implements OnInit {

  @Input()
    jobStageCategoryId: number = null

  @Input()
    jobStageName: string

  dragModel: {
    jobStageName: string,
    jobStageCategoryId: number
  }

  constructor() {
  }

  ngOnInit(): void {
    this.dragModel = {
      jobStageName: this.jobStageName,
      jobStageCategoryId: this.jobStageCategoryId
    }
  }
}
