import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { GridsterDashboardActionTypes, GridsterDashboardActions } from '../actions/gridster-dashboard.actions';
import { GridsterItem } from 'angular-gridster2';

export interface State extends EntityState<GridsterItem> {
  editModeEnabled: boolean
}

export const adapter: EntityAdapter<GridsterItem> = createEntityAdapter<GridsterItem>({})

export const initialState: State = adapter.getInitialState({
  editModeEnabled: false,
})

export function reducer(
  state = initialState,
  action: GridsterDashboardActions,
): State {
  switch (action.type) {

    case GridsterDashboardActionTypes.EnableGridsterEditMode: {
      return {
        ...state,
        editModeEnabled: true,
      }
    }

    case GridsterDashboardActionTypes.DisableGridsterEditMode: {
      return {
        ...state,
        editModeEnabled: false,
      }
    }

    case GridsterDashboardActionTypes.AddGridsterChartToUpdate: {
      if (action.payload.gridsterItem) {
        return adapter.upsertOne(action.payload.gridsterItem, state)
      }
      return state;
    }

    case GridsterDashboardActionTypes.RollbackGridsterChart: {
      const chartId = action.payload.chartId
      return {
        ...adapter.removeOne(chartId, state),
      }
    }

    case GridsterDashboardActionTypes.ResetGridsterDashboard: {
      return initialState
    }

    default: {
      return state
    }
  }
}
