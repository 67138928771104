import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-drop-container',
  templateUrl: './drop-container.component.html',
  styleUrls: ['./drop-container.component.scss']
})
export class DropContainerComponent {

  @Input()
    extraClass: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() dropping: EventEmitter<any> = new EventEmitter()

  onDrop(event) {
    this.dropping.emit(event)
  }

}
