<!-- setClientHeight is needed here because when exporting to pdf,
     the html2canvas lib fails if the element doesn't have a height. -->
<article setClientHeight class="job-container collapsed" [class.show]="!jobCollapsed"
         id="job-{{jobId}}" *ngIf="job && {
           jobStages: jobStages$ | async
         } as data; else loading">
  <div class="job tw-border tw-box-border tw-rounded-md tw-shadow-sm tw-p-4 tw-relative">

    <div *ngIf="showHeader" class="job-header">
      <div class="job-header-left">
        <twng-job-info-header [wallStyling]="true" [job]="job"
                              [showJobId]="showJobIdOnWall$ | async"
                              [showHiringManagers]="showHiringManagersOnWall$ | async" 
                              [showTagsInBottom]="showTagsInBottom"
                              class="job-info-header">
        </twng-job-info-header>
      </div>
      <div class="job-header-right">
        <twng-job-action-buttons
          [job]="job"
          [jobCollapsed]="jobCollapsed"
          [showAnalytics]="showAnalytics"
          (collapse)="selectCollapse()"
          (selectAnalytics)="selectAnalytics()"
          (selectCards)="selectWall()"
          [greenHouseUrl]="urlService.jobUrl(job) | async"
          [atsName]="atsName"
          [atsIconUrl]="atsIconUrl"
          jobStatusAndNotes
          #jobStatusAndNotesDirective=jobStatusAndNotes
          (editJobStatus)="jobStatusAndNotesDirective.openModal()"
        ></twng-job-action-buttons>
      </div>
    </div>

    <!--    JOB CARDS & ANALYTICS   -->
    <div *ngIf="!showAnalytics" class="stage-container">
      <div class="col job-stage tw-flex tw-flex-col tw-w-full tw-m-1 tw-drop-stage tw-text-sm" 
        *ngFor="let stage of data.jobStages; trackBy: trackStageById">
        <twng-job-stage [job]="job" [jobStage]="stage" [jobCollapsed]="jobCollapsed"
                        [visibleJobApplications]="visibleJobApplications"
                        [activityDayCategories]="activityDayCategories"
                        [interviewsAndJobEntities]="interviewsAndJobEntities"
                        [sourceTypeIconMappings]="sourceTypeIconMappings"
                        [jobStageConversionRates]="jobConversionRates && jobConversionRates[stage.id]"
                        [jobApplicationsTotal]="stage.job_applications_total"></twng-job-stage>
      </div>
      <ng-container *ngIf="showHiredCandidates$ | async">
        <div class="col job-stage job-stage-hired tw-bg-cq-gray-50 tw-flex tw-flex-col tw-w-full tw-m-1 tw-drop-stage tw-text-sm" *ngIf="hiredJobApplications?.length > 0">
          <div class="hired-job-stage">
            <!-- We have to use num_hires <= hiredJobApp because there is a case in Staging where the JobApp.status: hired but it has no Offer associated. -->
            <!-- Without offer num_hires does not take it into account. Leaving it as it is because should not happen with real data. -->
            <div *ngIf="job.num_hires <= hiredJobApplications.length" class="job-count tw-mr-2 fs-unmask">{{ hiredJobApplications.length }}</div>
            <div *ngIf="job.num_hires > hiredJobApplications.length" class="job-count tw-mr-2 fs-unmask">{{ hiredJobApplications.length }}/{{job.num_hires}}</div>
            <h2 ngbTooltip="Hired" class="wall-job-stage-name tw-text-sm">Hired</h2>
          </div>
          <div class="hired-candidates-list" *ngIf="!jobCollapsed">
            <ng-container *ngFor="let jobApplication of hiredJobApplications">
              <twng-card *ngIf="jobApplication?.candidate"
                       [candidate]="jobApplication.candidate"
                       [jobApplication]="jobApplication" [interviews]="[]">
              </twng-card>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <twng-job-analytics *ngIf="showAnalytics && job && !jobCollapsed" [job]="job"
                        [jobStages]="data.jobStages">
    </twng-job-analytics>
  </div>
</article>

<ng-template #loading>
  <twng-spinner></twng-spinner>
  <div style="height: 400px; width: 1px"></div>
</ng-template>
