<div ngbDropdown class="w-100">
  <button type="button" ngbDropdownToggle [disabled]="disabled || !options"
    class="btn w-100 d-flex align-items-center justify-content-between flex-wrap
           tw-pr-4 position-relative"
  >
    <div class="tw-mr-1" *ngIf="label">{{label}}:
      <span class="tw-ml-1" *ngIf="options">
        {{ selectedOption ? selectedOption.name : 'Please select' }}
      </span>
    </div>
    <i class="fas fa-sync fa-spin" *ngIf="!options"></i>
  </button>
  <div ngbDropdownMenu class="h-auto">
    <button ngbDropdownItem type="button"
      *ngFor="let option of options" (click)="setNewId(option.id)"
    >
      {{ option.name }}
    </button>
  </div>
</div>