import { Actions, ofType } from '@ngrx/effects'
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { ExecutiveDashboardActionsTypes, UpdateExecutiveDashboardTab,
  UpdateExecutiveDashboardTabFailure, UpdateExecutiveDashboardTabSuccess
} from '../../../wall/actions/executive-dashboard.actions'
import { ExecutiveDashboardColumnOrderInfo, ExecutiveDashboardTab } from '../../../wall/models/executive-dashboard'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ReorderableEntry } from '../../../shared/components/reorderable-input/reorderable-input.component'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { cloneDeep } from 'lodash-es'
import { first } from 'rxjs/operators'
import { getCustomFieldsAsMap } from '../../../custom-fields/selectors'
import { selectImmediately } from '../../../shared/utils/store.utils'

@Component({
  selector: 'twng-reorder-exec-dash-columns-modal',
  templateUrl: './reorder-exec-dash-columns-modal.component.html',
  styleUrls: ['./reorder-exec-dash-columns-modal.component.css']
})
export class ReorderExecDashColumnsModalComponent implements OnInit {

  isDemo = window.twng_demo
  form: UntypedFormGroup
  tab: ExecutiveDashboardTab

  allEntries: ReorderableEntry<ExecutiveDashboardColumnOrderInfo>[] = [{
    display: 'Days Open',
    value: {
      type: 'regular',
      value: 'days_open',
    },
  }, {
    display: 'Number of Openings',
    value: {
      type: 'regular',
      value: 'openings',
    },
  }, {
    display: 'Job Stages (these are moved as a group)',
    value: {
      type: 'regular',
      value: 'job_stages',
    },
    classes: ['job-stages'],
  }, {
    display: 'Hires',
    value: {
      type: 'regular',
      value: 'hires',
    },
  }, {
    display: 'Projected hires',
    value: {
      type: 'regular',
      value: 'proj_hires',
    },
  }, {
    display: 'Notes',
    value: {
      type: 'regular',
      value: 'notes',
    },
  }, {
    display: 'Recruiter',
    value: {
      type: 'regular',
      value: 'recruiter',
    }
  }, {
    display: 'Hiring manager',
    value: {
      type: 'regular',
      value: 'hiring_manager',
    }
  }, {
    display: 'Job ID',
    value: {
      type: 'regular',
      value: 'job_id'
    }
  }, {
    display: 'Opening ID',
    value: {
      type: 'regular',
      value: 'opening_id'
    }
  }, {
    display: 'Office',
    value: {
      type: 'regular',
      value: 'office'
    }
  }, {
    display: 'Total active candidates',
    value: {
      type: 'regular',
      value: 'total_active_candidates'
    }
  }, {
    display: 'Opening date',
    value: {
      type: 'regular',
      value: 'opening_date'
    }
  }, {
    display: 'Candidates processed',
    value: {
      type: 'regular',
      value: 'candidates_processed'
    }
  }, {
    display: 'Offers created',
    value: {
      type: 'regular',
      value: 'offers_created'
    }
  }, {
    display: 'New candidates',
    value: {
      type: 'regular',
      value: 'new_candidates'
    }
  }, {
    display: 'Target hire date',
    value: {
      type: 'regular',
      value: 'target_hire_days'
    }
  }, {
    display: 'Requisition ID',
    value: {
      type: 'regular',
      value: 'requisition_id'
    }
  }]

  constructor(
    private store: Store,
    private actions: Actions,
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private changeDetector: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {
    const clone = cloneDeep(this.tab)
    this.tab = clone
    this.removeHidenColumns()
    await this.addCustomFieldColumns()
    this.form = new UntypedFormGroup({
      column_order: new UntypedFormControl(this.tab.column_order)
    })
  }

  private removeHidenColumns() {
    for (const key in this.tab) {
      if (key.startsWith('show_') && !this.tab[key]) {
        this.allEntries = this.allEntries.filter(
          entry => entry.value.value !== key.substr('show_'.length)
        )
      }
    }
  }

  private async addCustomFieldColumns() {
    if (!this.tab.show_custom_fields) {
      return
    }
    const cfs = await selectImmediately(this.store, getCustomFieldsAsMap)
    for (const value of this.tab.column_order) {
      if (value.type === 'custom_field') {
        const customField = cfs.get(value.value)
        if (customField) {
          this.allEntries.push({
            display: customField.name + ' (Custom Field)',
            value,
          })
        }
      }
    }
  }

  close() {
    this.activeModal.close()
  }

  submit() {
    this.form.disable()
    this.actions.pipe(
      ofType(
        ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabFailure,
        ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabSuccess
      ),
      first()
    ).subscribe((action: UpdateExecutiveDashboardTabSuccess | UpdateExecutiveDashboardTabFailure) => {
      if (action.type === ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabSuccess) {
        this.waitForRefreshExecDash()
      } else {
        this.form.enable()
        this.toastr.error("Error saving tab")
      }
    })
    this.store.dispatch(new UpdateExecutiveDashboardTab({
      ...this.tab,
      column_order: this.form.value.column_order
    }))
  }

  private async waitForRefreshExecDash() {
    this.close()
  }

  updateColumsOrder(event) {
    const selectedEntry = this.allEntries.find(e => e.value.value === event.value)
    const reorderedEntries = [...this.allEntries].filter(e => e.value.value !== event.value)
    reorderedEntries.splice(event.newPosition, 0, selectedEntry)
    this.allEntries = reorderedEntries

    this.form.controls.column_order.setValue(reorderedEntries.map(e => e.value))
    this.changeDetector.markForCheck()
  }
}
