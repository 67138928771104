import { DateModeFilter } from '../../shared/components/filters/date-filter/date-filter.interface'
import { EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID } from '../reducers/executive-dashboard-closed-job-tab.data'
import { ExecutiveDashboardJobFilters } from '../reducers/layout.reducer'
import { ExternalUser } from './external-user'
import { Store } from '@ngrx/store'
import { getDefaultExecutiveDashboardTab, getExecutiveDashboardClosedJobsTab,
  getExecutiveDashboardTabById
} from '../reducers'

export interface ExecutiveDashboardColumnOrderInfo {
  value: string
  type: "regular" | "custom_field"
}

export interface PerJobConfiguration {
  target_hire_days_after_opening: number
}

export interface SingleJobStageThreshold {
  red: number | null
  yellow: number | null
}

export interface JobStageThresholds {
  [job_stage_name: string]: SingleJobStageThreshold
}

export interface ExecutiveDashboardGoal {
  yearly_goal: number,
  quarterly_goals: number[],
  monthly_goals: number[]
}

export interface ExecutiveDashboardConfiguration extends PerJobConfiguration {
  conversion_rates_use_green_instead_of_gray: boolean
  conversion_rates_yellow_threshold: number | null
  conversion_rates_red_threshold: number | null

  // days_open_green_threshold: number | null
  projected_hires_use_green_instead_of_gray: boolean
  projected_hires_yellow_threshold: number | null
  projected_hires_red_threshold: number | null
  days_open_use_green_instead_of_gray: boolean
  days_open_yellow_threshold: number | null
  days_open_red_threshold: number | null
  days_open_exclude_jobs_with_more_openings: boolean
  target_hire_date_use_green_instead_of_gray: boolean
  target_hire_date_yellow_threshold: number | null
  target_hire_date_red_threshold: number | null

  use_individual_job_stage_thresholds: number | null
  job_stage_thresholds: JobStageThresholds | null

  tabs: ExecutiveDashboardTab[]
}

export interface ExecutiveDashboardTab extends ExecutiveDashboardJobFilters {
  name: string
  show_days_open: boolean
  show_openings: boolean
  show_recruiter: boolean
  show_hiring_manager: boolean
  show_job_id: boolean
  show_requisition_id: boolean
  show_office: boolean
  show_total_active_candidates: boolean
  show_candidates_processed: boolean
  show_opening_id: boolean
  show_opening_date: boolean
  show_offers_created: boolean
  show_new_candidates: boolean
  show_target_hire_days: boolean
  show_job_stages: boolean
  show_hires: boolean
  show_proj_hires: boolean
  show_notes: boolean
  show_custom_fields: boolean
  custom_fields_to_show: string[]
  excluded_job_stages: string[]
  column_order: ExecutiveDashboardColumnOrderInfo[]
  default_tab: boolean
  id: number
  position: number
  sharable_token: string
  view_job_phases: boolean
  summary_date_mode: DateModeFilter
  summary_custom_start_date: string
  summary_custom_end_date: string
  hidden_section_ids?: HiddenSections[]
  job_priorities?: string[] | null
  employment_types?: string[] | null
}

export interface ExecutiveDashboardTabHideSection {
  hide_section: {
    id: HiddenSections
    hidden: boolean
  }
  tabId?: number
}

export interface DaysOpenColors {
  gray?: number[]
  green?: number[]
  yellow?: number[]
  red?: number[]
}

export interface ProjectHiresColors {
  useGreen?: boolean
  yellowMaxValue?: number
  redMaxValue?: number
}

export interface ExecutiveDashboardResponse {
  offers_per_job: {
    [jobId: string]: number
  }
  recent_candidates_per_job: {
    [jobId: string]: number
  }
  executive_dashboard_configuration: ExecutiveDashboardConfiguration,
  job_days_open_colors: DaysOpenColors,
  recruiters: ExternalUser[],
  per_job_configuration: {
    [jobId: string]: PerJobConfiguration
  }
}

export enum HiddenSections {
  Goals = 'goals',
  StagesSummary = 'stagesSummary',
  Charts = 'charts',
  Jobs = 'jobs'
}

export function isOneOfOpenJobsTab(tabId: string | number | ExecutiveDashboardTab) {
  if (typeof (tabId) === 'string') {
    return tabId !== 'closed_jobs'
  } else if (typeof (tabId) === 'number') {
    return tabId !== EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID
  } else {
    return tabId?.id !== EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID
  }
}

// use this function with switchMap
export function getTabFromUrlParam$(tabId: string, store: Store) {
  if (isOneOfOpenJobsTab(tabId)) {
    return store.select(
      tabId === 'open_jobs' ?
        getDefaultExecutiveDashboardTab() :
        getExecutiveDashboardTabById(parseInt(tabId, 10))
    )
  } else {
    return store.select(getExecutiveDashboardClosedJobsTab)
  }
}
