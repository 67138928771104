export interface CustomFieldProps {
  id: string
  custom_field_options: string[]
  excluded_from_filters: boolean
  name: string
  value_type: string
  private: boolean
  priority: number
}

export enum CustomFieldType {
  MULTI_SELECT = 'multi_select',
  SINGLE_SELECT = 'single_select',
  YES_NO = 'yes_no',
  USER = 'user',
}

export const CustomFieldTypeNames: { [key in CustomFieldType]: string } = {
  single_select: 'Single Select',
  multi_select: 'Multi Select',
  yes_no: 'Yes/No',
  user: 'User'
}

export abstract class CustomField implements CustomFieldProps {

  constructor(props: CustomFieldProps) {
    Object.assign(this, props)
  }

  custom_field_options: string[]
  id: string
  excluded_from_filters: boolean
  name: string
  priority: number
  private: boolean
  value_type: string

  is(customFieldId: string): boolean {
    return this.id === customFieldId
  }

  /**
   *
   * @param options array of the values from the selected options
   * @param value configured, for example in job.custom_field
   */
  abstract matchValue(options: string[], value: string | string[] | null): boolean

}

export class MultiSelectCustomField extends CustomField {

  static TYPE: CustomFieldType = CustomFieldType.MULTI_SELECT

  /**
   * Check if all selected CustomField options
   * are included in the job.custom_field
   *
   * @param options
   * @param value
   */
  matchValue(options: string[], value: string[] | null): boolean {
    const values = value || []
    return options.every(opt => values.includes(opt))
  }

}

export class SingleSelectCustomField extends CustomField {

  static TYPE: CustomFieldType = CustomFieldType.SINGLE_SELECT

  /**
   * Check if
   *
   * @param options
   * @param value
   */
  matchValue(options: string[], value: string[] | null): boolean {
    const values = value || []
    return options.some(opt => values.includes(opt))
  }

}

export class UserCustomField extends CustomField {

  static TYPE: CustomFieldType = CustomFieldType.USER

  /**
   * Check if
   *
   * @param options
   * @param value
   */
  matchValue(options: string[], value: string[] | null): boolean {
    const values = value || []
    return options.some(opt => values.includes(opt))
  }
}

export class BooleanCustomField extends CustomField {

  static TYPE: CustomFieldType = CustomFieldType.YES_NO

  matchValue(options: string[], value: string | null): boolean {
    return options[0] === value
  }

}
