<div class="px-5">
  <button class="btn btn-primary d-block tw-mb-3" (click)="openNewReportModal()">Schedule New Report</button>
  <ng-container [ngSwitch]="data?.length">
    <table *ngSwitchDefault class="w-100 table">
      <thead>
        <tr>
          <th>Dashboard tab</th>
          <th>Frequency</th>
          <th>Day</th>
          <th>Time</th>
          <th>Receiver</th>
          <th>Last sent</th>
          <th>Next sending</th>
          <th>Last error</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <twng-scheduled-report-row *ngFor="let report of data"
          [scheduledReport]="report"
          (deleted)="deleteReport(report.id)"
          (updated)="updateReport($event)">
        </twng-scheduled-report-row>
      </tbody>
    </table>
    <twng-spinner *ngSwitchCase="undefined"></twng-spinner>
    <em *ngSwitchCase="0" class="d-block">
      There are no scheduled reports. Please add them.
    </em>
  </ng-container>
  <em class="d-block">This page automatically refreshes every 60 seconds</em>
</div>