<twng-modal>
  <twng-modal-header>
    <twng-modal-title slot="header-title">
      Reorder Columns In Executive Dashboard
    </twng-modal-title>
  </twng-modal-header>
  <twng-modal-body>
    <form appDroppable (dropping)="updateColumsOrder($event)" *ngIf="form" [formGroup]="form" (submit)="submit()">
      <twng-reorderable-input [allEntries]="allEntries" formControlName="column_order"
        *ngIf="allEntries.length > 0; else noEntries">
      </twng-reorderable-input>
    </form>
  </twng-modal-body>
  <twng-modal-footer *ngIf="allEntries.length > 0">
    <span class="text-danger" *ngIf="isDemo">
      Cannot save configuration in demo mode.
    </span>
    <button class="btn btn-primary" [disabled]="form?.disabled || isDemo" (click)="submit()">Save</button>
  </twng-modal-footer>
</twng-modal>

<ng-template #noEntries>
  All columns are hidden. Please enable them through the executive dashboard configuration window (<i
    class="fas fa-cog"></i> icon)
</ng-template>