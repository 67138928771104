import { BehaviorSubject, Observable, Subscription } from "rxjs"
import { CacheService } from "../../../wall/services/cache.service"
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input,
  OnChanges, OnDestroy, OnInit, SimpleChanges
} from "@angular/core"
import { DateFilter } from "../../../shared/components/filters/date-filter/date-filter.interface"
import { DropoffConversionRates } from "../../../wall/reducers/job-analytics.reducer"
import { ExecutiveDashboardService } from "../../services/executive-dashboard.service"
import { ExecutiveDashboardTab, isOneOfOpenJobsTab } from "../../../wall/models/executive-dashboard"
import { HttpClient } from "@angular/common/http"
import { Job } from "../../../wall/models/job"
import { JobStage } from "../../../wall/models/job-stage"
import { NgxChartsRecord } from "../../../dashboard/reducers/analytics.reducer"
import { PdfGlobalService } from "../../../shared/services/pdf-global.service"
import { ProjectedHiresResponse } from "../../../wall/models/projected-hires"
import { ShowGenericTableModal } from "../../../core/actions/generic-table.actions"
import { StageMappingsState } from "../../../stage-mappings/reducers/stage-mappings.reducer"
import { Store } from '@ngrx/store'
import { UpdateExecutiveDashboardTab } from "../../../wall/actions/executive-dashboard.actions"
import { WidgetDataType } from "../../../wall/models/offer"
import { apiHost, httpGetOptions } from "../../../core/http-options"
import { calcJobAnalyticsMaxHeight, jsonCompare, objValuesSafe } from "../../../shared/utils/general-utils"
import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators"
import { flatten, isEqual } from "lodash-es"
import {
  getAllJobProjectedHires, selectAllJobs
} from "../../../wall/reducers"
import { loadProjectedHiresIfNecessary } from "../../../shared/utils/store.utils"
import { selectStageMappingsState } from "../../../stage-mappings/selectors/stage-mappings.selectors"

interface TabSummaryResponse {
  conversion_rates: {[jobStageName: string]: DropoffConversionRates}
  time_interviewing: {[jobStageName: string]: number },
  candidates_interviewed: {[jobStageName: string]: number },
  time_to_hire: NgxChartsRecord,
}

@Component({
  selector: 'twng-exec-dash-summary-row',
  templateUrl: './exec-dash-summary-row.component.html',
  styleUrls: [
    '../../../wall/components/job-analytics.component.scss',
    '../../../shared/components/dropoff-graph/dropoff-graph.component.scss',
    './exec-dash-summary-row.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExecDashSummaryRowComponent implements OnInit, OnDestroy, OnChanges {

  private subscriptions = new Subscription()

  openingIds: string[]
  hiresIds: string[]
  numProjectedHires: string
  allJobStageNames: string[]
  jobStageNames: string[]

  loaded: boolean

  openJobsTab$ = new BehaviorSubject(true)

  @Input()
    jobIds: string[]
  private jobIds$ = new BehaviorSubject<string[]>([])

  @Input()
    tab: ExecutiveDashboardTab

  @Input()
    isSharedTab: boolean

  summaryResponse: TabSummaryResponse
  private summarySubscription = new Subscription()

  exportingToPDF$: Observable<boolean>

  private projectedHires: ProjectedHiresResponse
  private jobStagesPerJob: { [jobId: string]: JobStage[] }
  private phases: StageMappingsState
  private jobs: Job[]
  yAxisMax = 0

  constructor(
    private store: Store,
    private cacheService: CacheService,
    private change: ChangeDetectorRef,
    private http: HttpClient,
    pdf: PdfGlobalService,
  ) {
    this.exportingToPDF$ = pdf.globalPdfExporting$
  }

  private assignVariable<T>(value: T, variable: string) {
    if (this[variable] !== value && !jsonCompare(value, this[variable])) {
      Object.assign(this, { [variable]: value })
      this.refresh()
    }
  }

  private registerRefresher<T>(data: Observable<T>, variable: string) {
    this.subscriptions.add(data.subscribe(value => this.assignVariable(value, variable)))
  }

  private registerRefresherOpenClosedJobs<T>(dataOnOpenJobs: Observable<T>,
    dataOnClosedJobs: Observable<T>, variable: string) {
    this.subscriptions.add(
      this.openJobsTab$.pipe(
        distinctUntilChanged(),
        switchMap(isOpenJobs => isOpenJobs ? dataOnOpenJobs : dataOnClosedJobs)
      ).subscribe(value => this.assignVariable(value, variable))
    )
  }

  async ngOnInit() {

    loadProjectedHiresIfNecessary(this.store)
    this.registerRefresher(this.store.select(getAllJobProjectedHires), 'projectedHires')
    this.registerRefresherOpenClosedJobs(
      this.cacheService.jobIdsToJobStages$,
      this.cacheService.closedJobIdsToJobStages$,
      'jobStagesPerJob'
    )

    this.subscriptions.add(this.jobIds$.pipe(
      switchMap(jobIds =>
        this.store.select(selectAllJobs).pipe(
          map(jobs => jobs.filter(job => jobIds.includes(job.id))),
          distinctUntilChanged((a, b) =>
            isEqual(a, b)
          )
        )
      )
    ).subscribe(jobs => {
      if (this.jobIds.length !== jobs.length) {
        if (this.jobs !== null) {
          this.jobs = null
          this.refresh()
        }
      } else {
        this.jobs = jobs
        this.refresh()
      }
    }))

    await this.store.select(selectStageMappingsState).pipe(
      tap((data) => {
        this.phases = data
        this.getJobStageNames()
        this.fetchSummary()
      }),
    ).toPromise()
  }

  private fetchSummary() {
    this.summarySubscription.unsubscribe()
    let url = apiHost + '/twng/executive_dashboard/executive_dashboard_tabs/' + this.tab.id + '/summary'
    if (this.isSharedTab) {
      url += '/' + this.tab.sharable_token
    }
    url += '.json'
    this.summarySubscription = this.http.get<TabSummaryResponse>(url, httpGetOptions)
      .subscribe(response => {
        this.summaryResponse = response
        const summaryResponseKeys = Object.keys(this.summaryResponse.conversion_rates)
        this.jobStageNames = this.allJobStageNames?.filter(name => summaryResponseKeys.includes(name))
        this.refresh()
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.jobIds) {
      this.jobIds$.next(this.jobIds)
    }
    if (changes.tab) {
      this.openJobsTab$.next(isOneOfOpenJobsTab(this.tab))
      this.fetchSummary()
      this.refresh()
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
    this.summarySubscription.unsubscribe()
  }

  private refresh() {
    if (
      !this.jobs ||
      !this.projectedHires ||
      !this.jobStagesPerJob ||
      !this.summaryResponse ||
      !this.tab
    ) {
      this.loaded = false
      return
    }
    this.loaded = true

    this.hiresIds = this.getHiresIds()
    this.openingIds = this.getOpeningIds()
    this.getJobStageNames()
    this.numProjectedHires = this.getProjectedHires()
    this.yAxisMax = calcJobAnalyticsMaxHeight(this.summaryResponse.conversion_rates)

    this.change.markForCheck()
  }

  private getHiresIds() {
    return flatten(objValuesSafe(this.summaryResponse.conversion_rates).map(cr => cr.hired_app_ids))
      .filter(Boolean)
      .map(v => v.toString())
  }

  private getOpeningIds() {
    return flatten(this.jobs.map(job => job.job_openings.map(jo => jo.id)))
  }

  private getJobStageNames() {
    if (this.tab.view_job_phases) {
      this.allJobStageNames = this.phases.jobStageCategories.map(c => c.name).concat("Offer")
    } else {
      this.jobStageNames =
      ExecutiveDashboardService.getInvolvedJobStages(this.jobs, this.jobStagesPerJob, this.tab.excluded_job_stages)
    }
  }

  private getProjectedHires() {
    let sum = 0
    let loadedJobs = 0
    for (const job of this.jobs) {
      if (this.projectedHires[job.id]) {
        loadedJobs++
        if (this.projectedHires[job.id].final_projected_hires === null) {
          console.warn("A NULL VALUE WAS FOUND FOR PROJ. HIRES")
          continue
        }
        const value = parseFloat(this.projectedHires[job.id].final_projected_hires.toString())
        if (typeof(this.projectedHires[job.id].final_projected_hires) === 'string') {
          console.warn("A STRING VALUE WAS FOUND FOR PROJ. HIRES (we expected number)", job.id,
            value, this.projectedHires[job.id])
          continue
        }
        sum += value
      }
    }
    if (loadedJobs >= this.jobs.length) {
      return sum.toFixed(1)
    }
  }

  dateFiltersChanged(newDateFilters: DateFilter) {
    this.store.dispatch(new UpdateExecutiveDashboardTab({
      summary_date_mode: newDateFilters.dateMode,
      summary_custom_start_date: newDateFilters.startDate,
      summary_custom_end_date: newDateFilters.endDate,
      id: this.tab.id,
    } as ExecutiveDashboardTab))
    delete this.summaryResponse
    this.refresh()
  }

  getSummaryDateFiltersForTab(): DateFilter {
    return {
      dateMode: this.tab.summary_date_mode,
      endDate: this.tab.summary_custom_end_date,
      startDate: this.tab.summary_custom_start_date,
    }
  }

  //////////////////////////////////////
  /// modals
  //////////////////////////////////////
  openJobsModal() {
    this.store.dispatch(new ShowGenericTableModal({
      chart: WidgetDataType.JOBS,
      clickedGraphTitle: 'Jobs',
      clickedLabel: this.tab.name || 'Open Jobs',
      data_ids: this.jobs.map(job => job.id)
    }))
  }

  openOpeningsModal() {
    this.store.dispatch(new ShowGenericTableModal({
      chart: WidgetDataType.JOB_OPENING,
      clickedGraphTitle: 'Job Openings',
      clickedLabel: this.tab.name || 'Open Jobs',
      data_ids: this.openingIds,
    }))
  }

  openHiresModal() {
    this.store.dispatch(new ShowGenericTableModal({
      chart: WidgetDataType.HIRED,
      clickedGraphTitle: 'Hires',
      clickedLabel: this.tab.name || 'Open Jobs',
      data_ids: this.hiresIds
    }))
  }

  openTimeToHireModal() {
    this.store.dispatch(new ShowGenericTableModal({
      chart: 'average-days-to-hire',
      clickedGraphTitle: this.summaryResponse.time_to_hire.name,
      clickedLabel: this.tab.name || 'Open Jobs',
      data_ids: this.summaryResponse.time_to_hire.offer_ids,
    }))
  }
}
