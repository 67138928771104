import * as timekeeper from 'timekeeper'
import { EMPTY, Observable, combineLatest, of } from 'rxjs'
import { catchError, concatMap, distinctUntilKeyChanged, filter,
  first, map, mergeMap, retry, tap
} from 'rxjs/operators'

import { Action } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { ActionCableService } from '../../wall/services/action-cable.service'
import {
  ClosedJobNamesPayload,
  DashboardLoadFailure,
  DashboardLoadSuccess,
  DashboardLoaderPayload,
  DashboardTabPayload,
  FetchWidgetLibraryTab,
  LoadAccount,
  LoadClosedJobNamesFailure,
  LoadClosedJobNamesSuccess,
  LoadDashboardTab,
  LoadDashboardTabFailure,
  LoadDashboardTabSuccess,
  LoadOpenJobNamesFailure,
  LoadOpenJobNamesSuccess,
  LoadSharedDashboardTab,
  LoadSharedDashboardTabFailure,
  LoadSharedDashboardTabSuccess,
  LoadSharedDataFailure,
  LoadSharedDataSuccess,
  LoaderActionTypes,
  OpenJobNamesPayload,
  SharableDashboardTabPayload,
  SharedDataPayload,
  SignOutSuccess,
  WallCandidatePayload,
  WallLoaderPayload,
  WallSharedFailure,
  WallSharedSuccess
} from '../actions/loader.actions'
import { FeatureFlags } from '../../wall/models/app-config'
import { ToastrService } from 'ngx-toastr'
import { apiHost, getHttpPostOptions, httpGetOptions } from '../http-options'
import { isHostedLocally } from '../../shared/utils/general-utils'
import { onTwngRoute } from '../rails-utils'
import { outerRedirect } from '../rails-integration'

// TODO: Eventually, may want to split account init from wall init
@Injectable()
export class InitEffects {
  // After account load, load shared data
  initSharedData$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LoaderActionTypes.LoadAccount),
    mergeMap((action: LoadAccount) => {
      const account = action.payload.account

      // if there is an account, but there is no active import, do not try to fetch data
      if (!isHostedLocally() && (!account || !account.active_import_id)) {
        return EMPTY
      }

      // do not load data when we are just using Twng for the header
      if (!this.onTwngRoute()) {
        return EMPTY
      }

      return this.fetchSharedData()
    })
  ))

  initDashboard$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LoaderActionTypes.DashboardLoad),
    mergeMap(() => this.fetchDashboardInitialData())
  ))

  loadMyDashboard$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LoaderActionTypes.FetchMyDashboardTab),
    mergeMap(() => this.fetchMyDashboardTab())
  ))

  fetchWidgetLibraryTab$: Observable<Action> = createEffect(() => combineLatest([
    this.actions$.pipe(
      ofType<FetchWidgetLibraryTab>(LoaderActionTypes.FetchWidgetLibraryTab)
    ),
    this.actionCable.sessionString$
  ]).pipe(
    // wait for action cable to be connected before issuing the request
    filter(([_action, sessionString]) => !!sessionString),
    distinctUntilKeyChanged(0),
    mergeMap(([action, sessionString]) => {
      const tabId = action.payload.tabId
      if (window.twng_demo) {
        return EMPTY
      }
      return this.fetchWidgetLibraryTab(tabId, sessionString)
    })
  ))

  fetchDashboardTab$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadDashboardTab>(LoaderActionTypes.LoadDashboardTab)
  ).pipe(
    mergeMap((action) => this.fetchDashboardTab(action.payload.tabId))
  ))

  fetchSharedDashboardTab$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LoaderActionTypes.LoadSharedDashboardTab),
    mergeMap((action: LoadSharedDashboardTab) =>
      this.fetchSharedDashboardTab(action.payload.tabId, action.payload.sharable_token)
    )
  ))

  loadWall$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LoaderActionTypes.WallInit),
    mergeMap(() => this.fetchWallSharedData())
  ))

  loadSharedData$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LoaderActionTypes.LoadSharedData),
    mergeMap(() => this.fetchSharedData())
  ))

  signOut$ = createEffect(() => this.actions$.pipe(
    ofType(LoaderActionTypes.SignOut),
    mergeMap(() =>
      this.http.delete(apiHost + `/users/sign_out`, getHttpPostOptions()).pipe(
        retry(1),
        map(() => {
          outerRedirect('users/sign_in')
          return new SignOutSuccess('users/sign_in')
        })
      )
    )
  ))

  loadClosedJobNames$ = createEffect(() => this.actions$.pipe(
    ofType(LoaderActionTypes.LoadClosedJobNames),
    mergeMap(() =>
      this.http.get<ClosedJobNamesPayload>(apiHost + '/twng/closed_job_names.json', httpGetOptions).pipe(
        map(data => new LoadClosedJobNamesSuccess(data)),
        catchError(() => of(new LoadClosedJobNamesFailure())),
      )
    )
  ))

  loadOpenJobNames$ = createEffect(() => this.actions$.pipe(
    ofType(LoaderActionTypes.LoadOpenJobNames),
    mergeMap(() =>
      this.http.get<OpenJobNamesPayload>(apiHost + '/twng/open_job_names.json', httpGetOptions).pipe(
        map(data => new LoadOpenJobNamesSuccess(data)),
        catchError(() => of(new LoadOpenJobNamesFailure())),
      )
    )
  ))

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private actionCable: ActionCableService,
    private toastr: ToastrService,
  ) { }

  onTwngRoute(): boolean {
    return onTwngRoute()
  }

  fetchSharedData(): Observable<LoadSharedDataSuccess | LoadSharedDataFailure> {
    return this.http
      .get<SharedDataPayload>(apiHost + '/twng/shared.json', httpGetOptions).pipe(
      retry(1),
      tap(data => this.printDataCountsShared(data)),
      map(data => this.transformSharedDataForDemoIfNecessary(data)),
      map(data => new LoadSharedDataSuccess(data)),
      catchError(() => of(new LoadSharedDataFailure())),
    )
  }

  private transformSharedDataForDemoIfNecessary(data: SharedDataPayload): SharedDataPayload {
    if (window.twng_demo) {
      // global Cookies object is available in demo mode
      // manually add features in case of demo account, and we have feature list in cookie
      const featuresStr: string = window.Cookies.get('features')
      if (featuresStr) {
        const features = featuresStr.split(',')
        const allowedFeatures: FeatureFlags[] = ['hiring_management']
        console.log('Detected additional features', features)
        for (const feature of features) {
          if (allowedFeatures.includes(feature as FeatureFlags)) {
            data.app_config.feature_flags[feature] = true
          } else {
            console.warn("Unpermitted demo feature requested:", feature)
          }
        }
      }
    }
    return data
  }

  fetchWallSharedData() {
    const path = apiHost + '/twng/shared_wall.json'
    return this.http
      .get<WallLoaderPayload>(path, httpGetOptions).pipe(
      map(data => {
        this.printDataCountsWallInitial(data)
        // In demo mode, set the current time from `now` so that the activity dates don't get stale
        if (window.twng_demo) {
          const now = new Date(data.now)
          timekeeper.travel(now)
        }
        return new WallSharedSuccess({ ...data })
      }),
      catchError(() => {
        this.toastr.error('Please reload this page in your browser', 'Wall failed to load', {
          disableTimeOut: true,
          closeButton: true,
          tapToDismiss: false,
        })
        return of(new WallSharedFailure())
      }),
    )
  }

  fetchDashboardTab(tabId: number) {
    return this.actionCable.sessionString$.pipe(
      // wait for action cable to be connected before issuing the request
      first(sessionString => !!sessionString),
      concatMap(sessionString =>
        this.http.get<DashboardTabPayload>(
          apiHost + '/twng/dashboard/tabs/' + tabId + '.json?user_session_string=' + sessionString,
          httpGetOptions
        ).pipe(
          retry(1),
          map(response => new LoadDashboardTabSuccess(response)),
          catchError(() => of(new LoadDashboardTabFailure())),
        )
      )
    )
  }

  fetchSharedDashboardTab(tabId: string, sharable_token: string) {
    return this.actionCable.sessionString$.pipe(
      // wait for action cable to be connected before issuing the request
      first(sessionString => !!sessionString),
      concatMap(sessionString =>
        this.http.get<SharableDashboardTabPayload>(
          `${apiHost}/twng/dashboard/tabs/${tabId}/${sharable_token}.json?user_session_string=${sessionString}`,
          httpGetOptions
        ).pipe(
          retry(1),
          map(response => new LoadSharedDashboardTabSuccess(response)),
          catchError(data => of(new LoadSharedDashboardTabFailure({status: data.status}))),
        )
      )
    )
  }

  printDataCountsWallInitial(data: WallLoaderPayload) {
    const len = (arr) => arr?.length

    console.log(
      "t", len(data.tabs),
    )

  }

  printDataCountsWall(data: WallCandidatePayload) {
    const len = (arr) => arr?.length

    console.log(
      "c", len(data.candidates),
      "ja", len(data.job_applications),
      "in", len(data.interviews),
    )
  }

  printDataCountsShared(data: SharedDataPayload) {
    const len = (arr) => arr?.length

    console.log(
      "ct", len(data.candidate_tags),
      "d", len(data.departments),
      "o", len(data.offices),
      "eu", len(data.external_users),
      "us", len(data.users),
      "cf", len(data.custom_fields)
    )

  }

  printDataCountsWallCandidates(data: WallCandidatePayload) {
    const len = (arr) => arr?.length
    console.log(
      "c", len(data.candidates),
      "i", len(data.interviews),
      "o", len(data.job_applications),
    )
  }

  fetchDashboardInitialData() {
    const path = apiHost + '/twng/dashboard.json'
    return this.fetchDashboardData(path)
  }

  fetchMyDashboardTab() {
    const path = apiHost + '/twng/dashboard/mine.json'
    return this.fetchDashboardData(path)
  }

  fetchWidgetLibraryTab(tabName: string, sessionString: string) {
    if (window.twng_demo) {
      return
    }
    let path = apiHost + `/twng/dashboard/widget-library/${tabName}.json`
    if (sessionString) {
      path += '?user_session_string=' + sessionString
    }
    return this.fetchDashboardData(path)
  }

  fetchDashboardData(url) {
    return this.http
      .get<DashboardLoaderPayload>(url, httpGetOptions).pipe(
      retry(1),
      map(data => new DashboardLoadSuccess(data)),
      catchError(() => of(new DashboardLoadFailure())),
    )
  }

}
