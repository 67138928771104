import { EMPTY, Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { AddTagSuccess, RemoveTagSuccess, ToggleTagActionTypes } from '../../card-details/actions/toggle-tag.actions';
import { Candidate } from '../models/candidate';
import {
  CardDetailsActionTypes, CardDetailsActions, FetchCardDetails, FetchCardDetailsFailure,
  FetchCardDetailsPayload, FetchCardDetailsSuccess
} from '../actions/card-details.actions'
import { FetchNotes } from '../actions/notes.actions';
import { apiHost, httpGetOptions } from '../../core/http-options'

@Injectable()
export class CardDetailsEffects {
  fetchCardDetails$: Observable<CardDetailsActions> = createEffect(() => this.actions$.pipe(
    ofType<FetchCardDetails>(CardDetailsActionTypes.FetchCardDetails),
    mergeMap(action => {
      const { jobApplication } = action.payload

      if (window.twng_demo) {
        return EMPTY
      }

      return this.http
        .get<FetchCardDetailsPayload>(
        `${apiHost}/twng/job_applications/${jobApplication.id}.json`,
        httpGetOptions,
      )
        .pipe(
          map(payload => new FetchCardDetailsSuccess(payload)),
          catchError(() => of(new FetchCardDetailsFailure())),
        )
    }),
  ))

  onTagChangeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddTagSuccess|RemoveTagSuccess>(ToggleTagActionTypes.AddTagSuccess, ToggleTagActionTypes.RemoveTagSuccess),
    map(action => action.payload.candidate),
    map((candidate: Candidate) => new FetchNotes({candidate}))
  ))

  constructor(private http: HttpClient, private actions$: Actions) { }
}
