
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges
} from '@angular/core'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { ActivityDayCategories } from '../../models/activity-day-categories'
import { Candidate } from '../../models/candidate'
import {
  CardDetailsModalContainerComponent
} from '../../../card-details/components/card-details-modal-container.component'
import { InterviewWithJobStage } from '../../models/interview'
import { InterviewsAndJobEntities } from '../../services/cache.service'
import { JobApplication } from '../../models/job-application'
import { JobStage } from '../../models/job-stage'
import { SegmentService } from '../../../core/services/segment.service'
import { SourceTypeIconMappings } from '../../models/wall-settings'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-card-container',
  templateUrl: './card-container.component.html'
})
export class CardContainerComponent implements OnChanges, OnInit {
  @Input() jobStage: JobStage
  @Input() jobApplication: JobApplication
  @Input() candidate: Candidate
  @Input() activityDayCategories: ActivityDayCategories
  @Input() interviewsAndJobEntities: InterviewsAndJobEntities
  interviews: InterviewWithJobStage[]
  @Input() sourceTypeIconMappings: SourceTypeIconMappings

  modalRef: NgbModalRef

  dragModel: {
    jobApplication: JobApplication,
    jobStage: JobStage
  }

  constructor(
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private segmentService: SegmentService,
  ) { }

  ngOnInit(): void {
    this.dragModel = {
      jobApplication: this.jobApplication,
      jobStage: this.jobStage
    }
  }

  // This is needed so that when we click to add a tag in card details, it updates the view
  ngOnChanges(changes: SimpleChanges) {
    if (changes.interviewsAndJobEntities) {
      const { interviewsByJobAppId, jobStageEntities } =
        changes.interviewsAndJobEntities.currentValue as InterviewsAndJobEntities
      this.interviews = (interviewsByJobAppId[this.jobApplication.id] || [])
        .map(int => ({ ...int, job_stage: jobStageEntities[int.job_stage_id] }))
        .sort((inta, intb) => this.compareTimes(inta.start_time, intb.start_time))
      this.cd.markForCheck()
    }
    this.updateModal()
  }

  compareTimes(a: string, b: string): number {
    return new Date(a).getTime() - new Date(b).getTime()
  }

  clickOpenDetails(): void {
    this.openDetailsModal()
    this.segmentService.track("Card Details (open modal)")
  }

  updateModal() {
    const modalRef = this.modalRef

    if (modalRef && modalRef.componentInstance) {
      modalRef.componentInstance.candidate = this.candidate
      modalRef.componentInstance.jobApplication = this.jobApplication
      modalRef.componentInstance.activityDayCategories = this.activityDayCategories
      modalRef.componentInstance.interviews = this.interviews
    }
  }

  openDetailsModal(): void {
    if (!this.modalRef) {
      this.modalRef = this.modalService.open(CardDetailsModalContainerComponent, {
        size: 'lg',
      })
      this.updateModal()

      this.modalRef.result
        .then(() => {
          this.modalRef = null
        })
        .catch(() => {
          this.modalRef = null
        })
    }
  }
}
