<div class="multiselect__wrapper">
  <div #drop ngbDropdown [autoClose]="false" class="filter-dropdown" placement="bottom top" (openChange)="onOpenChange($event)">
    <input #filter [ngStyle]="{'display': (showInput ? 'inline-flex' : 'none')}"
      [(ngModel)]="searchText" type="text" ngbDropdownToggle class="form-control"
      [placeholder]="'Search ' + manager.searchPlaceholder">
    
    <div *ngIf="!showInput" class="selected-count" tabindex=0 (focusin)="openDrop()">
      <span>{{manager.searchPlaceholder}}: {{manager.tempSelectedValuesCompact.length}} {{manager.shouldExcludeFilter ? '(excluded)' : ''}}</span>
    </div>
  
    <div ngbDropdownMenu *ngIf="manager.dataLoaded">
      <div *ngIf="manager.tempSelectedValuesCompact.length" class="selected__container">
        <div class="d-flex justify-content-between selected__title">
          <h3 class="filter-dropdown-title">{{ manager.shouldExcludeFilter ? 'Excludes' : 'Includes'}}: {{manager.tempSelectedValuesCompact.length}} <span>({{manager.searchPlaceholder.toLowerCase()}})</span></h3>
          <button class="button--link fs-unmask" (click)="clearAllSelected()">Clear all</button>
        </div>
  
        <div class="d-flex flex-column">
          <span class="d-flex justify-content-between selected__element" *ngFor="let value of selectedData"
            (click)="clearSelected(value.id)" [ngbTooltip]="manager.getHierarchyTooltip(value)">
            <span class="selected__text" 
              innerHTML="<span>{{ value.name | highlightHtml: searchText }}</span>">
            </span>
            <span class="selected__close-icon-wrapper">
              <i class="selected__close-icon fas fa-times"></i>
            </span>
          </span>
        </div>
      </div>
      <div class="result-container">
        <ng-container *ngIf="(filterData$ | async)?.length; else no_results">
          <span class="filter-result" *ngFor="let result of filterData$ | async; index as i" ngbDropdownItem
            [style.padding-left]="((result?.depth + 1) || 1) + 'rem'" [ngClass]="{'active' : i === 0}"
            (click)="onClick(result)" role="option" [ngbTooltip]="result.tooltip"
            innerHTML="<span>{{ result.name | highlightHtml: searchText }}</span>">
          </span>
        </ng-container>
        
        <ng-template #no_results>
          <span *ngIf="!manager.isInitialSearch" class="filter-no-result" ngbDropdownItem>No results found</span>
        
          <div class="filter-spinner" *ngIf="manager.isInitialSearch">
            <i class="fas fa-sync fa-2x fa-spin"></i>
          </div>
        </ng-template>
      </div>
      <p *ngIf="manager.maxFilteredResults < manager.filteredResultsLength" 
        class="tw-text-[13px] tw-text-cq-gray-400 tw-text-center tw-border-t tw-border-cq-gray-200 tw-p-2">
        Showing up to {{manager.maxFilteredResults}} results. <span class="tw-font-medium">Search to load more options.</span>
      </p>
    </div>

    <div class="input-section">
      <span class="tw-mr-2" (click)="clearTyping()" *ngIf="filter.value">
        <i class="fas fa-times"></i>
      </span>

      <div [ngStyle]="{'display': (isOpen() ? 'inline-flex' : 'none')}" class="btn-group btn-group-toggle btn-group-sm" role="group">
        <input type="radio" [value]="false" [checked]="!radioChecked" [name]="filtersRadioName" class="btn-check tw-hidden" 
          [id]="getRadioId('include')" autocomplete="off" (change)="setFilterExclusion(false)">
        <label class="btn btn-outline-secondary" [for]="getRadioId('include')">Incl.</label>

        <input type="radio" [value]="true" [checked]="radioChecked" [name]="filtersRadioName" class="btn-check  tw-hidden"
          [id]="getRadioId('exclude')" autocomplete="off" (change)="setFilterExclusion(true)">
        <label class="btn btn-outline-secondary" [for]="getRadioId('exclude')">Excl.</label>
      </div>

      <i *ngIf="isOpen()" class="fa-solid fa-caret-up" (click)="closeDrop()"></i>
      <i *ngIf="!isOpen()" class="fa-solid fa-caret-down" (click)="openDrop()"></i>
    </div>
  </div>
</div>
