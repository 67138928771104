import { CacheService } from "../../../../wall/services/cache.service"
import { CandidateCustomFieldDataManager, CandidateTagsDataManager,
  JobCustomFieldDataManager, OfferCustomFieldDataManager, dataForStoringFromManager
} from "../single-chart-filter/custom-fields-single-chart-filter.component"
import { ChartFilters } from "../../../models/chart-filters"
import { ClosedJobDataManager, OpenJobDataManager } from "../single-chart-filter/job-single-chart-filter.component"
import { Component } from "@angular/core"
import { CoordinatorDataManager, CreditedToDataManager, HiringManagerDataManager, JobCoordinatorDataManager,
  JobPrimaryRecruiterDataManager, JobSourcerDataManager, RecruiterDataManager, UserDataManager
} from "../single-chart-filter/user-single-chart-filter.component"
import { CustomFieldsService } from "../../../../custom-fields/services/custom-fields.service"
import { DepartmentDataManager } from "../single-chart-filter/department-single-chart-filter.component"
import { NgbActiveModal, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap"
import { OfficeDataManager } from "../single-chart-filter/office-single-chart-filter.component"
import {
  SearchTypeaheadDataManager, SingleChartFilterComponent
} from "../single-chart-filter/single-chart-filter.component"
import { SegmentService } from "../../../../core/services/segment.service"
import { SourceSearchTypeaheadDataManager } from "../single-chart-filter/source-single-chart-filter.component"
import { Store } from "@ngrx/store"
import { UntypedFormGroup } from "@angular/forms"
import { WallApiService } from "../../../../wall/services/wall-api.service"
import { dateFilterForm } from "../single-chart-filter/date-chart-filter/date-chart-filter.component"

@Component({
  selector: 'twng-chart-filters-modal',
  templateUrl: './chart-filters-modal.component.html',
  styleUrls: ['./chart-filters-modal.component.scss'],
})
export class ChartFiltersModalComponent extends SingleChartFilterComponent {
  id = 'ChartFiltersModalComponent'

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  managers: SearchTypeaheadDataManager<any>[] = []
  filters: ChartFilters
  dateFilterForm = new UntypedFormGroup(dateFilterForm)
  overrideFiltersSet: boolean

  dataManagers = {
    department: new DepartmentDataManager(this.cache),
    office: new OfficeDataManager(this.cache),
    openJob: new OpenJobDataManager(this.store, this.wallApi),
    closedJob: new ClosedJobDataManager(this.store, this.wallApi),
    user: new UserDataManager(this.cache),
    hiringManager: new HiringManagerDataManager(this.cache),
    recruiter: new RecruiterDataManager(this.cache),
    coordinator: new CoordinatorDataManager(this.cache),
    creditedTo: new CreditedToDataManager(this.cache),
    jobPrimaryRecruiter: new JobPrimaryRecruiterDataManager(this.cache),
    jobCoordinator: new JobCoordinatorDataManager(this.cache),
    jobSourcer: new JobSourcerDataManager(this.cache),
    sources: new SourceSearchTypeaheadDataManager(this.store),
    candidateTags: new CandidateTagsDataManager(this.store),
    jobCustomField: new JobCustomFieldDataManager(this.customFields),
    candidateCustomField: new CandidateCustomFieldDataManager(this.customFields),
    offerCustomField: new OfferCustomFieldDataManager(this.customFields)
  }

  constructor(
    segmentService: SegmentService,
    store: Store,
    private cache: CacheService,
    private customFields: CustomFieldsService,
    private wallApi: WallApiService,
    private activeModal: NgbActiveModal,
    private ngbDateParserFormatter: NgbDateParserFormatter
  ) {
    super(segmentService, store, '', '', '')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getDataManagers(): SearchTypeaheadDataManager<any>[] {
    return Object.values(this.dataManagers)
  }

  getChangedValueForStoring(): Partial<ChartFilters> {
    const formValue = this.dateFilterForm.value

    const userIds = []
    const sourcingIds = []

    this.dataManagers.user.tempSelectedIds.forEach(id => {
      const user = this.dataManagers.user.getValueFromId(id)
      if (user.sourcing_user) {
        sourcingIds.push(id)
        if (user.external_user_id) {
          userIds.push(user.external_user_id)
        }
      } else {
        userIds.push(id)
      }
    })

    const filters = {
      date_mode: formValue.dateFilterMode,
      start_date: this.ngbDateParserFormatter.format(formValue.startDate),
      end_date: this.ngbDateParserFormatter.format(formValue.endDate),
      department_ids: this.dataManagers.department.tempSelectedIds,
      should_exclude_departments: this.dataManagers.department.shouldExcludeFilter,
      office_ids: this.dataManagers.office.tempSelectedIds,
      should_exclude_offices: this.dataManagers.office.shouldExcludeFilter,
      source_ids: this.dataManagers.sources.tempSelectedIds.map(id => parseInt(id, 10)),
      should_exclude_sources: this.dataManagers.sources.shouldExcludeFilter,
      source_types: this.dataManagers.sources.sourceTypes,
      job_external_ids: [
        ...this.dataManagers.openJob.tempSelectedIds.map((v) => parseInt(v, 10)),
        ...this.dataManagers.closedJob.tempSelectedIds.map((v) => parseInt(v, 10)),
      ],
      should_exclude_jobs: this.dataManagers.openJob.shouldExcludeFilter,
      user_ids: userIds,
      sourcing_user_external_ids: sourcingIds,
      should_exclude_external_users: this.dataManagers.user.shouldExcludeFilter,
      hiring_manager_ids: this.dataManagers.hiringManager.tempSelectedIds,
      should_exclude_hiring_managers: this.dataManagers.hiringManager.shouldExcludeFilter,
      candidate_recruiter_ids: this.dataManagers.recruiter.tempSelectedIds,
      should_exclude_candidate_recruiter: this.dataManagers.recruiter.shouldExcludeFilter,
      candidate_coordinator_ids: this.dataManagers.coordinator.tempSelectedIds,
      should_exclude_candidate_coordinator: this.dataManagers.coordinator.shouldExcludeFilter,
      credited_to_ids: this.dataManagers.creditedTo.tempSelectedIds,
      should_exclude_credited_to: this.dataManagers.creditedTo.shouldExcludeFilter,
      job_primary_recruiter_external_ids: this.dataManagers.jobPrimaryRecruiter.tempSelectedIds,
      should_exclude_job_primary_recruiters: this.dataManagers.jobPrimaryRecruiter.shouldExcludeFilter,
      job_coordinator_external_ids: this.dataManagers.jobCoordinator.tempSelectedIds,
      should_exclude_job_coordinators: this.dataManagers.jobCoordinator.shouldExcludeFilter,
      job_sourcer_external_ids: this.dataManagers.jobSourcer.tempSelectedIds,
      should_exclude_job_sourcers: this.dataManagers.jobSourcer.shouldExcludeFilter,
      candidate_tags: this.dataManagers.candidateTags.tempSelectedIds,
      should_exclude_candidate_tags: this.dataManagers.candidateTags.shouldExcludeFilter,
      custom_fields: dataForStoringFromManager(this.dataManagers.jobCustomField),
      should_exclude_custom_fields: this.dataManagers.jobCustomField.shouldExcludeFilter,
      candidate_custom_fields: dataForStoringFromManager(this.dataManagers.candidateCustomField),
      should_exclude_candidate_custom_fields: this.dataManagers.candidateCustomField.shouldExcludeFilter,
      offer_custom_fields: dataForStoringFromManager(this.dataManagers.offerCustomField),
      should_exclude_offer_custom_fields: this.dataManagers.offerCustomField.shouldExcludeFilter,
    }

    return filters
  }

  closeModal() {
    this.activeModal.close()
  }

  apply() {
    this.applyFilters()
    this.closeModal()
  }
}
