import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs'

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input,
  OnChanges, OnDestroy, OnInit, SimpleChanges
} from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../reducers'
import { ActivityDayCategories } from '../models/activity-day-categories'
import { AppConfigService } from '../services/app-config.service'
import { CacheService, InterviewsAndJobEntities } from '../services/cache.service'
import { ConversionRatesRecord } from '../reducers/job-stage-stats.reducer'
import { Job } from '../models/job'
import { JobApplication } from '../models/job-application'
import { JobApplicationStageChangePayload, UpdateJobApplicationStage } from '../actions/job-applications.actions'
import { JobStage } from '../models/job-stage'
import { SegmentService } from '../../core/services/segment.service'
import { SourceTypeIconMappings } from '../models/wall-settings'
import { User } from '../models/user'
import { selectUser } from '../../reducers'
import { sortByLastActivity } from '../reducers/job-application.reducer'
import { startWith } from 'rxjs/operators'


@Component({
  selector: 'twng-job-stage',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./job-stage.component.scss'],
  templateUrl: 'job-stage.component.html',
})
export class JobStageComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
    jobStage: JobStage
  @Input()
    job: Job
  @Input()
    jobCollapsed: boolean

  @Input()
    activityDayCategories: ActivityDayCategories
  @Input()
    interviewsAndJobEntities: InterviewsAndJobEntities
  @Input()
    sourceTypeIconMappings: SourceTypeIconMappings

  @Input()
  private jobStageConversionRates: ConversionRatesRecord

  INITIAL_SHOW_QUANTITY = 8
  private SHOW_QUANTITY_GROW_BY = 8

  private showQuantity$ = new BehaviorSubject<number>(this.INITIAL_SHOW_QUANTITY)
  private reverse$ = new BehaviorSubject<boolean>(false)

  @Input()
  private visibleJobApplications: JobApplication[]
  private thisStageJobApplications: JobApplication[]
  jobApplications: JobApplication[] = []
  @Input()
    jobApplicationsTotal: number
  jobAppsCountStr: string

  rejectedCandidatesAtStageEnabled: boolean
  rejectedAtStageStr: string
  showQuantity: number
  jobStageTitle: string

  conversionRate: string

  user$: Observable<User>
  private manualRefresh$ = new Subject<void>()
  private sub = new Subscription()

  constructor(
    private store: Store<fromWall.State>,
    private cd: ChangeDetectorRef,
    public appConfig: AppConfigService,
    private segmentService: SegmentService,
    private cacheService: CacheService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.jobStageConversionRates) {
      const jobStageConversionRates = this.jobStageConversionRates

      this.jobStageTitle = this.jobStageConversionRates ?
        `${jobStageConversionRates.rejected || 0} rejected, ` +
        `${jobStageConversionRates.passed_through || 0} converted: ` +
        // `${jobStageConversionRates.active || 0} active, ` +
        `${jobStageConversionRates.converted_percent}% passthrough rate`
        : this.jobStage.name

      this.conversionRate = jobStageConversionRates ? `${jobStageConversionRates.converted_percent}%` : ''
      this.manualRefresh$.next()
    }

    if (changes.visibleJobApplications) {
      this.thisStageJobApplications = this.visibleJobApplications.filter(ja => ja.job_stage_id === this.jobStage.id)
      this.manualRefresh$.next()
    }
  }

  ngOnInit(): void {
    this.rejectedCandidatesAtStageEnabled = this.appConfig.rejectedCandidatesAtStageEnabled()
    this.rejectedAtStageStr = this.getRejectedAtStageStr()

    this.user$ = this.store.select(selectUser)

    // note: this didn't work because we don't always update updated_at
    // const compareApps = (appA: JobApplication, appB: JobApplication) => appA.id === appB.id && appA.updated_at === appB.updated_at
    // const diffAppLists = (prevApps: JobApplication[], nextApps: JobApplication[]) => _isEqualWith(prevApps, nextApps, compareApps)

    // Subscribe to candidates and job applications.
    // If we use these directly with `async` it causes extra re-renders.
    // Instead, we create parallel arrays of applications and their corresponding candidates.
    combineLatest([
      this.cacheService.candidatesEntities$,
      this.showQuantity$,
      this.reverse$,
      this.manualRefresh$.pipe(startWith(true)), // must emit some value in order to trigger render first time
    ]).subscribe(([candidateEntities, showQuantity, reverse]) => {
      const jobApplications = [...this.thisStageJobApplications].sort(sortByLastActivity)

      this.showQuantity = showQuantity

      // Since we only display the first 10 per stage or so by default,
      // it would be faster to look up candidates only for the visible ones
      this.jobApplications =
        this.sliceApplications(jobApplications, showQuantity, reverse).map(jobApp => ({
          ...jobApp,
          candidate: candidateEntities[jobApp.candidate_id],
        }))

      // NOTE: Must come after assignment of this.jobApplications, since it relies upon it
      this.jobAppsCountStr = this.getJobAppsCountStr()

      this.cd.markForCheck()
    })
  }

  private sliceApplications(jobApplications: JobApplication[], showQuantity: number, reverse: boolean) {
    const slice = reverse ?
      jobApplications.slice(Math.max(jobApplications.length - showQuantity, 0)) :
      jobApplications.slice(0, showQuantity)
    return reverse ? slice.reverse() : slice
  }

  toggleReverse(): void {
    this.segmentService.track("Toggle Order of Job Stage")
    this.reverse$.next(!this.reverse$.value)
  }

  showMore(): void {
    this.showQuantity$.next(this.showQuantity$.value + this.SHOW_QUANTITY_GROW_BY)
  }

  showFewer(): void {
    this.showQuantity$.next(this.showQuantity$.value - this.SHOW_QUANTITY_GROW_BY)
  }

  private jobAppsShown(): number {
    return Math.min(this.showQuantity$.value, this.jobApplications.length)
  }

  private getJobAppsCountStr() {
    const shown = this.jobAppsShown()
    const total = this.jobApplicationsTotal

    if (shown < total && !this.jobCollapsed) {
      return `${shown}/${total}`
    } else {
      return `${shown}`
    }
  }

  private getRejectedAtStageStr(): string {
    return `${this.jobStage.rejected_job_applications}`
  }

  updateJobApplication(payload: JobApplicationStageChangePayload): void {
    this.store.dispatch(new UpdateJobApplicationStage(payload))
  }

  trackAppById(_index: number, app: JobApplication) {
    return app ? app.id : null
  }

  updateJobApplicationStage(event) {
    this.updateJobApplication({
      jobApplication: event.jobApplication,
      fromStage: event.jobStage,
      toStage: this.jobStage,
    })

    this.segmentService.track("Update Application Stage")
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
