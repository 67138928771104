import { Subscription } from 'rxjs'
import { ToastrService } from 'ngx-toastr'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import * as fromDashboard from '../../reducers'
import { AppConfigService } from '../../../wall/services/app-config.service'
import { CreateDashboardTab, UpdateDashboardTab } from '../../actions/filters.actions'
import { DashboardTab } from '../../models/dashboard-tab'
import { DashboardTemplateList } from '../../../core/actions/loader.actions'
import { SegmentService } from '../../../core/services/segment.service'
import { cloneDeep } from 'lodash-es'

@Component({
  selector: 'twng-tab-form',
  styleUrls: ['./dashboard-form.scss'],
  templateUrl: './dashboard-form.html',
})
export class DashboardFormComponent implements OnInit, OnDestroy {
  dashboardTab: DashboardTab
  dashboardForm: UntypedFormGroup
  dashboardTabsSubs: Subscription
  tabs: DashboardTab[]
  demoMode = !!window.twng_demo
  dashboardTemplates: DashboardTemplateList

  constructor(
    private store: Store<fromDashboard.State>,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    public appConfig: AppConfigService,
    private segmentService: SegmentService,
  ) {
    this.dashboardForm = this.formBuilder.group({
      name: ['', Validators.required],
      template: [''],
    })
  }

  ngOnInit(): void {
    this.dashboardTabsSubs = this.store.select(fromDashboard.selectAllDashboardTabs).
      subscribe((tabs: DashboardTab[]) => {
        this.tabs = tabs
      })
    this.segmentService.track(`${this.actionName()} Tab (opened)`)
    this.dashboardTabsSubs.add(this.store.select(fromDashboard.selectDashboardTemplatesState).
      subscribe(state => {
        const templates = cloneDeep(state)
        templates[''] = {
          description: 'Create it from scratch.',
          icon_class: 'fa-file',
          name: 'Blank Dashboard',
        }
        this.dashboardTemplates = templates
      })
    )
  }

  ngOnDestroy(): void {
    this.dashboardTabsSubs.unsubscribe()
  }

  setEditingTab(dashboardTab: DashboardTab) {
    this.dashboardTab = dashboardTab
    this.dashboardForm.controls.name.setValue(dashboardTab.name)
  }

  isNewTab(): boolean {
    return !this.dashboardTab
  }

  actionName(): string {
    return this.isNewTab() ? 'Create Dashboard' : 'Rename Dashboard'
  }

  isFormDirty() {
    return this.dashboardForm.dirty
  }

  submit() {
    if (this.dashboardForm.invalid) {
      this.toastr.error('Please add a name to the DashboardTab')
      return
    }

    if (this.isNewTab()) {
      this.createTab()
    } else {
      if (this.isFormDirty()) {
        this.updateTab()
      }
    }
    this.activeModal.close('save DashboardTab')
  }

  createTab() {
    this.store.dispatch(new CreateDashboardTab({
      name: this.dashboardForm.value.name,
      template: this.dashboardForm.value.template ? this.dashboardForm.value.template : null,
    }))
    this.segmentService.track("Create Dashboard Tab (completed)")
  }

  updateTab() {
    this.store.dispatch(new UpdateDashboardTab({
      actualTab: this.dashboardTab,
      updatedTab: {
        ...this.dashboardTab,
        name: this.dashboardForm.value.name,
      }
    }))
    this.segmentService.track("Edit Dashboard Tab (completed)")
  }
}
