import { Observable } from 'rxjs'

import { Dictionary } from '@ngrx/entity'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { Job } from '../../wall/models/job'
import { LoadProspects, OpenCandidateCardModal } from '../actions/prospects.actions'
import { ProspectCandidate } from '../models/prospects-candidate.model'
import { ProspectJobApplication } from '../models/prospects-job-application.model'
import {
  ProspectsCandidate, selectGroupedProspectsByJobs
} from '../selectors/prospects-table.selectors'
import { ProspectsState } from '../reducers'
import { atsId } from '../../wall/models/types'
import {
  getProspectCandidateById, selectProspectJobApplicationById, selectProspectJobs
} from '../selectors/prospects.selectors'

@Injectable({ providedIn: 'root' })
export class ProspectsService {

  constructor(private store: Store<ProspectsState>) {
  }

  loadProspects() {
    this.store.dispatch(new LoadProspects())
  }

  getCandidateById(candidateId: atsId): Observable<ProspectCandidate> {
    return this.store.select(getProspectCandidateById(candidateId))
  }

  getJobApplicationById(jobApplicationId: atsId): Observable<ProspectJobApplication> {
    return this.store.select(selectProspectJobApplicationById(jobApplicationId))
  }

  getProspectJobs(): Observable<Job[]> {
    return this.store.select(selectProspectJobs)
  }

  getJobsWithProspects(): Observable<Dictionary<ProspectsCandidate[]>> {
    return this.store.select(selectGroupedProspectsByJobs)
  }

  openCandidateModal(jobApplicationId: string) {
    this.store.dispatch(new OpenCandidateCardModal({ jobApplicationId }))
  }
}
