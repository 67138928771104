import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Job } from '../../../wall/models/job';
import { Observable } from 'rxjs';
import { ProspectsCandidate } from '../../selectors/prospects-table.selectors';
import { ProspectsService } from '../../services/prospects.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.scss']
})
export class ProspectsComponent implements OnInit {

  jobsWithProspects$: Observable<Dictionary<ProspectsCandidate[]>>
  prospectJobs$: Observable<Job[]>

  constructor(private prospectsService: ProspectsService) {
  }

  ngOnInit() {
    this.prospectsService.loadProspects()
    this.prospectJobs$ = this.prospectsService.getProspectJobs()
    this.jobsWithProspects$ = this.prospectsService.getJobsWithProspects()
  }

  openCandidateModal(prospectCandidate: ProspectsCandidate) {
    this.prospectsService.openCandidateModal(prospectCandidate.jobApplication.id)
  }
}
