import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'

import { Job } from '../models/job'
import { UpdateJobUserSettings } from '../actions/jobs.actions'
import { present } from '../../shared/utils/general-utils'

@Component({
  selector: 'twng-interview-cost',
  templateUrl: 'interview-cost.component.html',
  styleUrls: [
    './job-analytics.component.scss',
    './interview-cost.component.scss'
  ],
})
export class InterviewCostComponent implements OnInit {
  @Input()
    job: Job

  @Input()
    interviewTime: number

  @Input()
    hires: number

  @Input()
    interviewHourlyCost: number

  @ViewChild('dollarCostInput', { static: false })
    dollarCostInputRef: ElementRef

  form: UntypedFormGroup

  submit = new Subject<void>()

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      dollarsPerHour: new UntypedFormControl(this.interviewHourlyCost, Validators.required),
    })

    this.submit.pipe(debounceTime(500)).subscribe(() => this.store.dispatch(new UpdateJobUserSettings(
      { jobId: this.job.id, jobUserSettings: { interview_hourly_cost: this.form.value.dollarsPerHour } })
    ))
  }

  onSubmit(): void {
    if (!present(this.form.value.dollarsPerHour)) {
      this.form.value.dollarsPerHour = 0
    }
    this.submit.next()
  }

  totalInterviewCost(): number {
    return Math.round(this.form.value.dollarsPerHour * this.interviewTime)
  }

  costPerHire(): string {
    if (this.hires === 0) {
      return "n/a"
    }
    return '$' + Math.round(this.form.value.dollarsPerHour * this.interviewTime / this.hires)
  }
}
