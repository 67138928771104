import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { Action } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import {
  AddTag, AddTagFailure, AddTagSuccess, RemoveTag, RemoveTagFailure, RemoveTagSuccess,
  ToggleTagActionTypes
} from '../actions/toggle-tag.actions'
import { apiHost, getHttpPostOptions } from '../../core/http-options'

@Injectable()
export class ToggleTagEffects {
  addTagInServer$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<AddTag>(ToggleTagActionTypes.AddTag),
    map(action => action.payload),
    mergeMap(({ candidate, tagName }) => {
      if (window.twng_demo) {
        return of(new AddTagSuccess({ candidate, tagName }))
      }

      return this.http
        .put(
          apiHost + `/twng/candidates/${candidate.id}/tags/${tagName}`,
          {},
          getHttpPostOptions(),
        )
        .pipe(
          map(
            () =>
              new AddTagSuccess({
                candidate,
                tagName,
              }),
          ),
          catchError(() => of(new AddTagFailure({ candidate, tagName }))),
        )
    }),
  ))

  RemoveTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<RemoveTag>(ToggleTagActionTypes.RemoveTag),
    map(action => action.payload),
    mergeMap(({ candidate, tagName }) => {
      if (window.twng_demo) {
        return of(new RemoveTagSuccess({ candidate, tagName }))
      }

      return this.http
        .delete(
          apiHost + `/twng/candidates/${candidate.id}/tags/${tagName}`,
          getHttpPostOptions(),
        )
        .pipe(
          map(
            () =>
              new RemoveTagSuccess({
                candidate,
                tagName,
              }),
          ),
          catchError(() => of(new RemoveTagFailure({ candidate, tagName }))),
        )
    }),
  ))

  constructor(private http: HttpClient, private actions$: Actions) { }
}
