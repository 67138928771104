import { BehaviorSubject, Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { CacheService } from '../../../../wall/services/cache.service'
import { FilterOption } from '../generic-filter/filter-option.interface'
import { OfficeWithChildren } from '../../../../wall/models/office'
import { filterSelectedItemsWithChildren } from '../utils'

@Component({
  selector: 'twng-office-filter',
  templateUrl: './office-filter.component.html',
  styleUrls: ['./office-filter.component.scss'],
})
export class OfficeFilterComponent implements OnInit {

  selectedOffices$: BehaviorSubject<OfficeWithChildren[]> = new BehaviorSubject([])
  officesWithChildren$: Observable<OfficeWithChildren[]>

  @Input()
    officeIds: string[] = []

  @Input()
    disabled = false

  @Output()
    filterChange = new EventEmitter<FilterOption[]>()

  constructor(private cacheService: CacheService) {
  }

  ngOnInit(): void {
    this.officesWithChildren$ = this.cacheService.officesWithChildren$
      .pipe(
        tap((officesWithChildren: OfficeWithChildren[]) =>
          this.loadSelectedOffices(officesWithChildren, this.officeIds)
        ),
        switchMap((officeWithChildren: OfficeWithChildren[]) => this.selectedOffices$.pipe(
          map((selectedOffices: OfficeWithChildren[]) =>
            filterSelectedItemsWithChildren(officeWithChildren, selectedOffices)
          )
        )),
      )
  }

  loadSelectedOffices(officesWithChildren: OfficeWithChildren[], officeIds: string[]): void {
    const offices = officesWithChildren.filter(office => (officeIds || []).includes(office.id))
    setTimeout(() => this.selectedOffices$.next(offices))
  }

  officeSelected(selectedOffice: FilterOption) {
    const selectedOffices = this.selectedOffices$.value
    this.selectedOffices$.next([...selectedOffices, (selectedOffice as OfficeWithChildren)])
    this.emitFilterValue()
  }

  removeOffice(officeOption: FilterOption) {
    const selectedOffices = this.selectedOffices$.value
      .filter((office: OfficeWithChildren) => office.id !== officeOption.id)
    this.selectedOffices$.next(selectedOffices)
    this.emitFilterValue()
  }

  emitFilterValue() {
    setTimeout(() => this.filterChange.emit(this.selectedOffices$.value))
  }

  reset() {
    while (this.selectedOffices$.value.length) {
      this.removeOffice(this.selectedOffices$.value[0])
    }
  }
}
