import { WallSettings } from '../models/wall-settings'

import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'


export interface State {
  wallSettings: WallSettings
}
const initialState: State = {
  wallSettings: null,
}

export function reducer(state = initialState, action: LoaderActions): State {
  switch (action.type) {
    case LoaderActionTypes.LoadSharedDataSuccess: {
      if (action.payload.wall_settings) {
        return { ...state, wallSettings: action.payload.wall_settings }
      }
      return state
    }

    default:
      return state
  }
}
