import { LoaderActionTypes, LoaderActions, SingleJobNamePayload } from "../../core/actions/loader.actions"

export interface State {
  closed_jobs: SingleJobNamePayload[],
  open_jobs: SingleJobNamePayload[],
}

const initialState: State = {
  closed_jobs: [],
  open_jobs: [],
}
export function reducer(state = initialState, action: LoaderActions): State {
  switch (action.type) {
    case LoaderActionTypes.LoadClosedJobNamesSuccess: {
      return {
        ...state,
        closed_jobs: action.payload.closed_jobs,
      }
    }
    case LoaderActionTypes.LoadOpenJobNamesSuccess: {
      return {
        ...state,
        open_jobs: action.payload.open_jobs,
      }
    }
  }
  return state
}
