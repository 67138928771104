<div class="d-flex wrapper secondary-text"
     [class.eeoc]="type === 'EEOC filtered'"
     [class.acceptance-rate]="isOfferAcceptenceRateStage"
     [openDelay]="200" [ngbPopover]="tooltip" [autoClose]="true"
     popoverClass="passthrough-explainer-popover"
     triggers="mouseenter:mouseleave" placement="top auto">
  <div class="number">
    <span>{{ finalNumber }}</span>
    <span class="percent fs-unmask">%</span>
  </div>
  <div class="triangle"></div>
</div>

<ng-template #tooltip>
  <div>
    <div class="section__header">
      {{ isOfferAcceptenceRateStage ? 'Offer Acceptence Rate' : 'Candidate Conversion Rate' }} ({{type}})</div>
    <div class="section--stats">
      <div class="stat">
        <div class="stat__count">{{ firstNumber }}</div>
        <div class="stat__info">
          <span class="stat__label">{{ firstNumberText }}</span>
        </div>
      </div>
      <div class="sep">÷</div>
      <div class="stat">
        <div class="stat__count">{{ secondNumber }}</div>
        <div class="stat__info">
          <span class="stat__label">{{ secondNumberText }}</span>
        </div>
      </div>
      <div class="sep">=</div>
      <div class="stat stat--right">
        <div class="stat__count stat__count--right">
          <span>{{ finalNumber }}</span>
          <span class="percent">%</span>
        </div>
        <div class="stat__info">
          <span class="stat__label">{{ finalNumberText }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>