<div class="name fs-unmask">
  <div *ngIf="!(editingChartNameId$| async)" class="actions-wrapper tw-flex tw-items-center">
    <!-- stopPropagation to prevent triggering a popover at the parent level simultaneously -->
    <span *ngIf="chartInfo | present" data-html2canvas-ignore class="info-icon up--1px" [openDelay]="0"
      [ngbPopover]="chartInfo" (click)="$event.stopPropagation()">
      <!-- Boxicons bx:bx-info-circle -->
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.75 6.75H10.25V5.25H8.75V6.75ZM9.5 15C6.1925 15 3.5 12.3075 3.5 9C3.5 5.6925 6.1925 3 9.5 3C12.8075 3 15.5 5.6925 15.5 9C15.5 12.3075 12.8075 15 9.5 15ZM9.5 1.5C8.51509 1.5 7.53982 1.69399 6.62987 2.0709C5.71993 2.44781 4.89314 3.00026 4.1967 3.6967C2.79018 5.10322 2 7.01088 2 9C2 10.9891 2.79018 12.8968 4.1967 14.3033C4.89314 14.9997 5.71993 15.5522 6.62987 15.9291C7.53982 16.306 8.51509 16.5 9.5 16.5C11.4891 16.5 13.3968 15.7098 14.8033 14.3033C16.2098 12.8968 17 10.9891 17 9C17 8.01509 16.806 7.03982 16.4291 6.12987C16.0522 5.21993 15.4997 4.39314 14.8033 3.6967C14.1069 3.00026 13.2801 2.44781 12.3701 2.0709C11.4602 1.69399 10.4849 1.5 9.5 1.5V1.5ZM8.75 12.75H10.25V8.25H8.75V12.75Z"
          fill="black" />
      </svg>
    </span>
    <span class="label" [ngbPopover]="label" [openDelay]="350" triggers="mouseenter:mouseleave"
      popoverClass="min-width--auto">{{ label }}</span>
  </div>

  <form class="form" *ngIf="(editingChartNameId$| async)" [formGroup]="labelForm" (ngSubmit)="onSubmit()">
    <input class="form-input" aria-label="Enter to Save" type="text" autocomplete="off" #inputLabel
      formControlName="label" (blur)="onSubmit()" [ngbPopover]="enterToSavePopover" popoverClass="min-width--auto"
      [autoClose]="false" placement="bottom auto" triggers="focus" />
  </form>
</div>

<ng-template #enterToSavePopover>
  <div class="kb-wrapper fs-unmask">
    <span class="kb-shortcut">Enter</span>
    <span class="kb-text">to save</span>
  </div>
</ng-template>