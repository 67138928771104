import { ChangeDetectionStrategy, ChangeDetectorRef, Component,
  EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ScheduleNewReportModalComponent } from '../schedule-new-report-modal/schedule-new-report-modal.component'
import { ScheduledReport } from '../scheduled-report.model'
import { ScheduledReportService } from '../scheduled-report.service'
import { SlackChannel, SlackService, SlackUser } from '../../../shared/services/slack.service'
import { ToastrService } from 'ngx-toastr'
import { daysOfWeek } from '../../../shared/utils/general-utils'

@Component({
  selector: 'twng-scheduled-report-row',
  templateUrl: './scheduled-report-row.component.html',
  styleUrls: ['./scheduled-report-row.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduledReportRowComponent implements OnChanges {
  @Input()
    scheduledReport: ScheduledReport

  @Output()
    deleted = new EventEmitter<void>()

  @Output()
    updated = new EventEmitter<ScheduledReport>()

  slackUser: SlackUser
  slackChannel: SlackChannel

  dayOfWeek = daysOfWeek

  sending = false
  rescheduling = false

  constructor(
    private cd: ChangeDetectorRef,
    private scheduledReports: ScheduledReportService,
    private toastr: ToastrService,
    private modal: NgbModal,
    private slack: SlackService,
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.scheduledReport) {
      this.slackUser = null
      this.slackChannel = null
      if (this.scheduledReport.destination_type === 'slack') {
        const channels = await this.slack.getChannels()
        this.slackUser = channels.users.find(user => user.id === this.scheduledReport.destination)
        this.slackChannel = channels.channels.find(channel => channel.id === this.scheduledReport.destination)
        this.cd.markForCheck()
      }
    }
  }

  async del() {
    try {
      await this.scheduledReports.deleteReport(this.scheduledReport.id)
      this.deleted.emit()
    } catch(err) {
      this.toastr.error("Error deleting report", "Please try again")
    }
  }

  async sendNow() {
    this.sending = true
    this.cd.markForCheck()
    try {
      this.scheduledReport = await this.scheduledReports.sendReportNow(this.scheduledReport.id)
      this.toastr.success('Sent')
    } catch(err) {
      this.toastr.error("Error sending report", "Please try again")
    }
    this.sending = false
    this.cd.markForCheck()
  }

  async editReport() {
    try {
      const modal = this.modal.open(ScheduleNewReportModalComponent)
      const component = modal.componentInstance as ScheduleNewReportModalComponent
      component.editReport(this.scheduledReport)
      this.updated.emit(await modal.result)
    } catch(err) {
      if (err) {
        throw err
      }
    }
  }

  async reschedule() {
    this.rescheduling = true
    this.cd.markForCheck()
    try {
      this.updated.emit(await this.scheduledReports.rescheduleReport(this.scheduledReport.id))
      this.toastr.success('Rescheduled')
    } catch(err) {
      this.toastr.error("Error rescheduling report", "Please try again")
    }
    this.rescheduling = false
    this.cd.markForCheck()
  }
}
