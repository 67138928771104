import { ExecutiveDashboardTab } from "../models/executive-dashboard"

export const EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID = -1

// Closed jobs tab is edited locally, it is not saved on backend. This
// is default state of closed jobs tab (when user opens talentwall).
export const defaultClosedJobTab: ExecutiveDashboardTab = {
  show_days_open: true,
  show_openings: true,
  show_job_stages: true,
  show_hires: true,
  show_proj_hires: true,
  show_notes: true,
  show_custom_fields: true,
  show_recruiter: false,
  show_hiring_manager: false,
  show_job_id: true,
  show_opening_id: false,
  show_office: true,
  show_total_active_candidates: false,
  show_opening_date: false,
  show_candidates_processed: false,
  show_offers_created: false,
  show_new_candidates: false,
  show_target_hire_days: true,
  show_requisition_id: false,
  custom_fields_to_show: [],
  excluded_job_stages: [],
  column_order: [
    {
      type: "regular",
      value: "days_open"
    },
    {
      type: "regular",
      value: "office"
    },
    {
      type: "regular",
      value: "openings"
    },
    {
      type: "regular",
      value: "hires"
    },
    {
      type: "regular",
      value: "target_hire_days"
    },
    {
      type: "regular",
      value: "job_stages"
    },
    {
      type: "regular",
      value: "proj_hires"
    },
    {
      type: "regular",
      value: "notes"
    },
    {
      type: "regular",
      value: "recruiter"
    },
    {
      type: "regular",
      value: "hiring_manager"
    },
    {
      type: "regular",
      value: "job_id"
    },
    {
      type: "regular",
      value: "total_active_candidates"
    },
    {
      type: "regular",
      value: "candidates_processed"
    },
    {
      type: "regular",
      value: "opening_id"
    },
    {
      type: "regular",
      value: "opening_date"
    },
    {
      type: "regular",
      value: "offers_created"
    },
    {
      type: "regular",
      value: "new_candidates"
    },
    {
      type: "regular",
      value: "requisition_id"
    }
  ],
  default_tab: false,
  name: "Closed Jobs",
  custom_fields: [],
  id: EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID,
  department_ids: [],
  office_ids: [],
  external_user_ids: [],
  job_ids: [],
  position: 999,
  sharable_token: 'xxx',
  view_job_phases: false,
  summary_date_mode: 'last3months',
  summary_custom_end_date: '',
  summary_custom_start_date: '',
}
