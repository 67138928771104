import {
  TransferCandidateUIActionTypes,
  TransferCandidateUIActions,
} from '../actions/transfer-candidate-ui.actions'
import { updateStatePerJobApplication } from './shared'

export interface State {
  [id: string]: {
    selectedJobId: string
    selectedJobStageId: string
    showError: boolean
  }
}

const initialJobAppState = {
  selectedJobId: null,
  selectedJobStageId: null,
  showError: false,
}

const initialState: State = {}

export function reducer(
  state = initialState,
  action: TransferCandidateUIActions,
): State {
  switch (action.type) {
    case TransferCandidateUIActionTypes.UpdateSelectedJob: {
      const { jobApplicationId, jobId } = action.payload
      return updateStatePerJobApplication(state, jobApplicationId, {
        selectedJobId: jobId,
        selectedJobStageId: null,
      })
    }

    case TransferCandidateUIActionTypes.UpdateSelectedJobStage: {
      const { jobApplicationId, jobStageId } = action.payload

      return updateStatePerJobApplication(state, jobApplicationId, {
        selectedJobStageId: jobStageId,
      })
    }

    case TransferCandidateUIActionTypes.TransferCandidateSuccess: {
      const { jobApplicationId } = action.payload
      return updateStatePerJobApplication(
        state,
        jobApplicationId,
        initialJobAppState,
      )
    }

    case TransferCandidateUIActionTypes.TransferCandidateFailure: {
      const { jobApplicationId } = action.payload
      return updateStatePerJobApplication(state, jobApplicationId, {
        showError: true,
      })
    }

    default: {
      return state
    }
  }
}
