/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, Optional, Self } from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { cloneDeep } from 'lodash-es'

export interface ReorderableEntry<ValueType> {
  display: string
  value: ValueType
  classes?: string[]
}

const REORDERABLE_DRAG_TYPE = 'REORDERABLE-INPUT-'

@Component({
  selector: 'twng-reorderable-input',
  templateUrl: './reorderable-input.component.html',
  styleUrls: ['./reorderable-input.component.scss']
})
export class ReorderableInputComponent implements OnInit, ControlValueAccessor {
  @Input() allEntries: ReorderableEntry<any>[]

  value: any[]

  constructor(
    @Self() @Optional() private ngControl: NgControl,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
  }

  get dragType() {
    return REORDERABLE_DRAG_TYPE + this.ngControl?.name
  }

  ngOnInit(): void {
    // If value is not set within 2 seconds, we automatically set it to all
    // entries ... This doesn't usually happen though.
    setTimeout(() => {
      if (!this.value) {
        console.warn(
          "Data didn't load for reorderable input after 2 seconds ... " +
          "This shouldn't happen, make sure you provide data upon initialization"
        )
        this.writeValue(this.allEntries.map(entry => entry.value))
      }
    }, 2000)

    this.refreshExcessValues()
  }

  writeValue(obj: any[]): void {
    this.value = obj
    this.refreshExcessValues()
  }
  private refreshExcessValues() {
    if (this.allEntries && this.value) {
      this.value = this.value.filter(
        value => this.getIndexByValue(value) !== -1
      )
    }
  }
  registerOnChange(fn: (value: any[]) => void): void {
    this.onChange = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  private onChange(_value: any[]) { }
  private onTouched() { }

  getDisplayNameByValue(value: any): string {
    return this.allEntries.find(entry => this.cmp(entry.value, value))?.display
  }

  getIndexByValue(value: any): number {
    return this.allEntries.findIndex(entry => this.cmp(entry.value, value))
  }

  private cmp(a: any, b: any) {
    return JSON.stringify(a) === JSON.stringify(b)
  }

  cloneObject(obj: any) {
    return cloneDeep(obj)
  }
}
