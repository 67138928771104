<div class="description" [dimensionMonitor]="1000" (heightChanged)="this.height = $event"
    [ngClass]="{ 'description--custom': dataSource === CUSTOM_BOX_DATA_SOURCE }">
  <twng-gridster-item-overlay *ngIf="editingGridster"></twng-gridster-item-overlay>
  <header [class.pointer-events--none]="editingGridster">
    <twng-chart-label class="center" [chart]="chart" [label]="label" *ngIf="!isInGridster"
      [chartInfo]="dataSources && dataSources[dataSource] && dataSources[dataSource].description"></twng-chart-label>
    <div class="header__actions-button" data-html2canvas-ignore>
      <twng-chart-drop-down-menu
        [chart]="isInGridster ? chart : null"
        [label]="label"
        chartType="free-text"
        [dataSource]="dataSource"
        [dataSourceParameters]="dataSourceParameters"
        [chartStatus]="chart?.data"
        [isCustomTitle]="chart?.is_custom_title"
      ></twng-chart-drop-down-menu>
    </div>
  </header>

  <div class="description__big-number-wrapper" [class.pointer-events--none]="editingGridster" [class.chart-as-title]="chart?.is_custom_title">
    <div placement="left bottom" [ngbTooltip]="setTooltip()" *ngIf="dataSource !== CUSTOM_BOX_DATA_SOURCE"
        [disableTooltip]="!clickable" (click)="clickable && onClick()">
        {{ valueStr(value) }}
      <div class="description__people-icon" *ngIf="clickable">
        <i class="fas" [ngClass]="dataType === 'jobs' ? 'fa-suitcase' : 'fa-user'"></i>
      </div>
    </div>

    <hr *ngIf="chart?.is_custom_title" class="flex-grow-1"/>
    <div *ngIf="dataSource === CUSTOM_BOX_DATA_SOURCE" placement="left bottom" class="content"
        [ngbTooltip]="gridItemType === 'gridster' ? 'Edit' : 'Send to Custom Dashboard to Edit'"
        [disableTooltip]="editingCustomValue">
      <div *ngIf="!editingCustomValue" class="custom-value" (click)="clickable && editValue()"
          [style.height]="height - 70 + 'px'">
        {{ (chart?.custom_value || stringValue || "?").trim() }}
      </div>
      <twng-free-text-custom-box-input *ngIf="editingCustomValue" [value]="chart?.custom_value || stringValue"
        [chart]="chart" (stopEditing)="stopEditingValue()"></twng-free-text-custom-box-input>
    </div>
    <hr *ngIf="chart?.is_custom_title" class="flex-grow-1"/>
  </div>

  <footer *ngIf="gridItemType === 'gridster' && chart?.data_type !== CUSTOM_BOX_DATA_TYPE" class="filters description__footer--gridster"
          [class.pointer-events--none]="editingGridster" data-html2canvas-ignore>
    <twng-chart-filters
      [filters]="chartFilters"
      [filtersEnabled]="filtersEnabled"
      [chart]="chart"
      [chartFiltersReadOnly]="chartFiltersReadOnly"
    >
    </twng-chart-filters>
  </footer>
</div>
