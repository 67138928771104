import { atsId } from './types'

export class EmailTemplate {
  id: atsId
  name: string
  kind: string
  is_default: boolean
  html_body: string
  from: string
  cc: string
  description: string
}

export const EMAIL_TEMPLATE_KIND_REJETION = 'candidate_rejection'
