/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { HttpErrorResponse } from '@angular/common/http'
import { JobApplication } from '../models/job-application'
import { JobStage } from '../models/job-stage'

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum JobApplicationActionTypes {
  UpdateJobApplicationStage = '[JobApplication] UpdateJobApplicationStage',
  UpdateJobApplications = '[JobApplication] UpdateJobApplications',
  UpdateJobApplicationStageSuccess = '[JobApplication] UpdateJobActionStageSuccess',
  UpdateJobApplicationStageFailure = '[JobApplication] UpdateJobActionStageFailure',
  JobApplicationsUpdatedOnServer = '[JobApplication] JobApplicationsUpdatedOnServer',
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */

export interface JobApplicationStageChangePayload {
  jobApplication: JobApplication
  fromStage: JobStage
  toStage: JobStage
}

export interface JobApplicationStageChangeFailurePayload {
  errorData: HttpErrorResponse
  jobApplication: JobApplication
  fromStage: JobStage
  toStage: JobStage
}

export class UpdateJobApplications implements Action {
  readonly type = JobApplicationActionTypes.UpdateJobApplications
  constructor(public payload: Update<JobApplication>[]) { }
}

export class UpdateJobApplicationStage implements Action {
  readonly type = JobApplicationActionTypes.UpdateJobApplicationStage
  constructor(public payload: JobApplicationStageChangePayload) { }
}

export class UpdateJobApplicationStageSuccess implements Action {
  readonly type = JobApplicationActionTypes.UpdateJobApplicationStageSuccess
  constructor(public payload: { response, jobApplication: JobApplication }) { }
}

interface JobApplicationError {
  jobApplication: JobApplication
  toStage: JobStage
  fromStage: JobStage
}

export class UpdateJobApplicationStageFailure implements Action {
  readonly type = JobApplicationActionTypes.UpdateJobApplicationStageFailure
  constructor(public payload: JobApplicationError) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type JobApplicationActions =
  | UpdateJobApplicationStage
  | UpdateJobApplications
  | UpdateJobApplicationStageSuccess
  | UpdateJobApplicationStageFailure
