import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'

import { AppConfigService } from '../../../wall/services/app-config.service'
import { Job } from '../../../wall/models/job'
import { ProspectsCandidate } from '../../selectors/prospects-table.selectors'
import { UrlService } from '../../../wall/services/url.service'
import { compareStringDates } from '../../../shared/utils/sorting/dates-utils';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.scss']
})
export class ProspectComponent {

  datesComparator = compareStringDates

  sortedProspectsCandidates: ProspectsCandidate[] = []

  @Input()
    prospectJob: Job

  @Input()
  set prospectCandidates(prospectCandidate: ProspectsCandidate[]) {
    this.sortedProspectsCandidates = prospectCandidate
  }

  @Output()
    showCandidateCard = new EventEmitter<ProspectsCandidate>()

  constructor(public urlService: UrlService,
    public appConfig: AppConfigService) {
  }

  rowClick(prospectCandidate: ProspectsCandidate) {
    this.showCandidateCard.emit(prospectCandidate)
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

}
