import { Subscription } from 'rxjs'

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../../wall/reducers'
import { TagMapping } from '../../wall/models/tag-mapping'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-tag-icons',
  styles: [
    `
      i {
        margin-right: 0.3rem;
      }
    `,
  ],
  template: `
    <i *ngFor="let tm of tagMappingsForThisCandidate()"
      [class]="iconClass(tm.icon)"
      [ngbTooltip]="tm.tag"
      data-html2canvas-ignore
    ></i>
  `,
})
export class TagIconsComponent implements OnInit, OnDestroy {
  @Input()
    tags: string[]

  tagMappingsSub: Subscription
  tagMappings: TagMapping[]

  constructor(private store: Store<fromWall.State>) { }

  ngOnInit(): void {
    this.tagMappingsSub = this.store.select(fromWall.selectAllTagMappings).subscribe(tagMappings => {
      this.tagMappings = tagMappings
    })
  }

  ngOnDestroy(): void {
    this.tagMappingsSub?.unsubscribe()
  }

  tagMappingsForThisCandidate() {
    return this.tagMappings.filter(tm => this.tags.indexOf(tm.tag) !== -1)
  }

  iconClass(tag: string): string {
    return `fas fa-${tag}`
  }
}
