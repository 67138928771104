<twng-modal>
  <twng-modal-header>
    <twng-modal-title slot="header-title">
      Send report on schedule
    </twng-modal-title>
  </twng-modal-header>
  <twng-modal-body>
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="row">
        <div class="col-4 tw-pt-4 d-flex flex-column">
          <twng-slack-destination-selector class="w-100 tw-mb-4" formControlName="destination">
          </twng-slack-destination-selector>
          <twng-select
            [options]="dashboardTabSelector$ | async"
            formControlName="dashboard_tab_id"
            label="Dashboard tab">
          </twng-select>
        </div>
        <div class="col-4 tw-pt-4">
          <twng-select
            [options]="frequencyOptions"
            formControlName="frequency"
            label="Frequency"
            class="tw-mb-4">
          </twng-select>
          <twng-select
            [options]="repetitionOptionsPerFrequency[formValue.frequency]"
            formControlName="repetition_day"
            label="Repetition day"
            *ngIf="formValue.frequency && formValue.frequency !== 'daily'">
          </twng-select>
        </div>
        <div class="col-4 d-flex align-items-center flex-column">
          <div>Time to send</div>
          <ngb-timepicker
            [(ngModel)]="time" (ngModelChange)="timeChanged()"
            [ngModelOptions]="{standalone: true}" [minuteStep]="15">
          </ngb-timepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
        </div>
        <div class="text-center col-4">
          <em>Note: This uses account's timezone <b>
            {{((appConfig.getConfig$() | async)?.account_timezone || "?").replace('/', ' / ').replace('_', ' ')}}
          </b></em>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          Message to send
          <textarea class="w-100" formControlName="text">
          </textarea>
        </div>
      </div>
    </form>
  </twng-modal-body>
  <twng-modal-footer>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="form.disabled || form.invalid">
      Save
    </button>
  </twng-modal-footer>
</twng-modal>