import { Observable, Subscription, combineLatest } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit,
  SimpleChanges
} from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../reducers'
import { ActivityDayCategories } from '../models/activity-day-categories'
import { AppConfigService } from '../services/app-config.service'
import { CacheService, InterviewsAndJobEntities } from '../services/cache.service'
import { Department } from '../models/department'
import { FetchJobById } from '../actions/wall.actions'
import { JOB_APPLICATION_STATUS_HIRED, JobApplication } from '../models/job-application'
import { Job } from '../models/job'
import { JobConversionRates } from '../reducers/job-stage-stats.reducer'
import { JobStage } from '../models/job-stage'
import { JobViewMode } from '../reducers/layout.reducer'
import { Office } from '../models/office'
import { PdfComponentTrackerService } from '../../shared/services/pdf-component-tracker.service'
import { SegmentService } from '../../core/services/segment.service'
import { SourceTypeIconMappings } from '../models/wall-settings'
import { UpdateJobViewMode } from '../actions/layout.actions'
import { UrlService } from '../services/url.service'
import { isEqual } from 'lodash-es'

@Component({
  selector: 'twng-job',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './job.component.html',
  styleUrls: [
    './job.component.scss',
    '../../../scss/effects.scss',
  ],
  providers: [PdfComponentTrackerService],
})
export class JobComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
    jobId: string

  @Input()
    showHeader = true

  @Input()
    showTagsInBottom = false

  @Input()
    visibleJobApplicationIds: string[]
  visibleJobApplications: JobApplication[] = []

  showAnalytics = false
  jobCollapsed = false

  job: Job

  jobStages$: Observable<JobStage[]>
  departments: Department[]
  offices: Office[]
  atsName: string
  atsIconUrl: string
  jobPriorityTooltip: string
  employmentTypeTooltip: string

  jobConversionRates: JobConversionRates

  sub = new Subscription()
  jobSub = new Subscription()
  jobApplicationsSub = new Subscription()

  activityDayCategories: ActivityDayCategories

  interviewsAndJobEntities: InterviewsAndJobEntities

  sourceTypeIconMappings: SourceTypeIconMappings

  showJobIdOnWall$: Observable<boolean>
  showHiringManagersOnWall$: Observable<boolean>

  hiredJobApplications: JobApplication[] = []

  showHiredCandidates$: Observable<boolean>

  openings = 3

  constructor(
    private store: Store<fromWall.State>,
    public urlService: UrlService,
    public appConfig: AppConfigService,
    private cacheService: CacheService,
    private segmentService: SegmentService,
    private cd: ChangeDetectorRef,
    private pdf: PdfComponentTrackerService,
  ) {
    this.atsName = this.appConfig.atsName()
    this.atsIconUrl = this.appConfig.atsIconUrl()
  }

  ngOnInit(): void {
    this.sub.add(this.store.select(fromWall.selectActivityDayCategories)
      .subscribe(activityDayCategories => this.activityDayCategories = activityDayCategories))
    this.sub.add(this.cacheService.interviewsAndJobEntities$
      .subscribe(interviewsAndJobEntities => this.interviewsAndJobEntities = interviewsAndJobEntities))
    this.sub.add(this.cacheService.sourceTypeIconMappings$
      .subscribe(sourceTypeIconMappings => this.sourceTypeIconMappings = sourceTypeIconMappings))

    this.jobStages$ = this.cacheService.jobIdsToJobStages$.pipe(
      map(jobStagesMap => jobStagesMap[this.jobId])
    )

    const thisJobModeWatcher$ = this.store.select(fromWall.selectJobViewModeByJobId(this.jobId)).pipe(
      distinctUntilChanged(),
    )
    this.sub.add(
      combineLatest([thisJobModeWatcher$]).subscribe(([newViewMode]) => {
        this.jobCollapsed = newViewMode === JobViewMode.Collapsed
        this.showAnalytics = newViewMode === JobViewMode.Analytics
        this.cd.markForCheck()
      })
    )

    this.sub.add(this.store.select(fromWall.getSelectConversionRatesForJob(this.jobId)).subscribe(
      (val) => {
        this.jobConversionRates = val
        this.cd.markForCheck()
      }
    ))
    this.showJobIdOnWall$ = this.appConfig.isFeatureFlagEnabled$('show_job_id_on_wall')
    this.showHiringManagersOnWall$ = this.appConfig.isFeatureFlagEnabled$('show_hiring_managers_on_wall')
    this.showHiredCandidates$ = this.appConfig.isFeatureFlagEnabled$('show_hired_candidates_on_wall')
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.jobId) {
      this.jobSub?.unsubscribe()
      this.jobSub =
        this.store.select(fromWall.selectJobEntities).subscribe(
          jobs => {
            this.job = jobs[this.jobId]
            if (this.job) {
              this.jobPriorityTooltip = this.jobPriorityString()
              this.employmentTypeTooltip = this.employmentTypeString()
              this.pdf.componentIsReady()
            } else {
              this.store.dispatch(new FetchJobById(this.jobId))
              this.pdf.componentIsNotReady()
            }
            this.cd.markForCheck()
          }
        )
    }

    if (changes.visibleJobApplicationIds) {
      this.jobApplicationsSub?.unsubscribe()
      this.jobApplicationsSub = combineLatest(
        [
          this.store.select(fromWall.selectJobApplicationEntities),
          this.store.select(fromWall.selectCandidateEntities)
        ]).subscribe(([jobApplications, candidates]) => {
        const newJobApplications = this.visibleJobApplicationIds.map(id => jobApplications[id])
        this.hiredJobApplications = newJobApplications
          .filter((ja: JobApplication) => ja.status === JOB_APPLICATION_STATUS_HIRED)

        if (!isEqual(this.visibleJobApplications, newJobApplications)) {
          this.visibleJobApplications = newJobApplications
        }

        const newHiredJobApplications = this.hiredJobApplications.map((jobApp: JobApplication) => ({
          ...jobApp,
          candidate: candidates[jobApp.candidate_id],
        }))
        if (!isEqual(this.hiredJobApplications, newHiredJobApplications)) {
          this.hiredJobApplications = newHiredJobApplications
        }
        this.cd.markForCheck()
      })
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
    this.jobSub.unsubscribe()
    this.jobApplicationsSub.unsubscribe()
  }

  jobPriorityString() {
    return "Priority :  " + (this.job.priority || "No Priority")
  }

  employmentTypeString() {
    return "Employment Type: " + (this.job.employment_type || "No Employment Type")
  }

  private updateJobMode(newJobMode: JobViewMode) {
    this.store.dispatch(new UpdateJobViewMode({jobId: this.jobId, viewMode: newJobMode}))
  }

  selectCollapse(): void {
    this.updateJobMode(JobViewMode.Collapsed)
  }

  selectWall(): void {
    this.updateJobMode(JobViewMode.Wall)
  }

  selectAnalytics(): void {
    this.segmentService.track("View Job Analytics")
    this.updateJobMode(JobViewMode.Analytics)
  }

  trackStageById(_index: number, stage: JobStage) {
    return stage ? stage.id : null
  }

}
