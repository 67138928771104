import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Store } from "@ngrx/store"
import { ToastrService } from "ngx-toastr"
import { apiHost } from "../http-options"
import { environment } from "../../../environments/environment"
import { isEqual } from "lodash-es"
import { selectImmediatelySync } from "../../shared/utils/store.utils"
import { selectSharedDataLoaded } from "../../shared/state/selectors"
import { timer } from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class FileFreshnessCheckerService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private store: Store,
  ) {
    if (!window.twng_demo && environment.production) {
      // every 2 mins
      timer(60000, 60000 * 2).subscribe(() => {
        // check only when we log in
        if (selectImmediatelySync(this.store, selectSharedDataLoaded)) {
          this.checkFiles()
        }
      })
    }
  }

  checkFiles() {
    this.http.get(`${apiHost}/twng/frontend_filenames.json`).subscribe(resp => {
      if (!isEqual(resp, window.sent_frontend_filenames)) {
        this.toastr.warning('Please refresh your browser', 'We have updated TalentWall!', {
          timeOut: 60000 * 2 - 10000,
        })
      }
    }, (err) => {
      console.warn("Error checking if application is up to date: ", err.message)
    })
  }
}
