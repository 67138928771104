import * as ActionCable from 'actioncable'
import { BehaviorSubject } from 'rxjs'
import { keys as _keys } from 'lodash-es'

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../reducers'
import { ACTION_CABLE } from '../models/app-config'
import { AppConfigService } from './app-config.service'
import { Chart, DashboardChart } from '../../dashboard/models/dashboard-chart'
import {
  DeleteFromServer,
  UpdateDashboardWidgetDataFromServer,
  UpdateFromServer,
  UpdateWidgetDataFromServer,
  UpdateWidgetStatusFromQueueKey,
  WallInit
} from '../../core/actions/loader.actions'
import { distinctUntilChanged, first, map } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { isHostedLocally, randomString } from '../../shared/utils/general-utils'
import { selectAppConfig } from '../../reducers'
import { selectDashboardChartById } from '../../dashboard/reducers'

interface CommonWebsocketCommand<T> {
  readonly command: 'widget_data' | 'conversion_rates_data'
  user_session_string: string
  data: T
}

interface WidgetDataCommand extends CommonWebsocketCommand<Chart> {
  readonly command: 'widget_data'
}

type PossibleWebsocketCommands = WidgetDataCommand

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function waitUntil(store: Store, selector: (state: Record<string, any>) => any) {
  return store.select(selector).pipe(first(v => v !== null))
}

@Injectable({ providedIn: 'root' })
export class ActionCableService {
  // each connection will have a unique string. This way we will differentiate
  // updates for different browser tabs, since all connections of this user
  // will receive updates from backend
  sessionString$ = new BehaviorSubject<string>(null)
  private cable: ActionCable.Cable

  // in production, the action cable host is specified in a meta tag
  private actionCableHost = () =>
    isHostedLocally() ? 'ws://localhost:3100/cable' : null

  private createTwngChannel(cable: ActionCable.Cable) {
    return cable.subscriptions.create(
      {
        channel: 'TwngChannel',
      },
      {
        connected: () => {
          if (!environment.production) {
            console.log('ActionCable: Connected to TwngChannel')
          }
        },
        received: payload => {
          if (!environment.production) {
            console.log(new Date().toLocaleString(), 'ActionCable: Received: ', payload)
          }

          if (payload.data && payload.data.update) {
            this.store.dispatch(new UpdateFromServer(payload.data.update))
            if (environment.production) {
              console.log(new Date().toLocaleString(), 'Received Update', _keys(payload.data.update))
            }
          }
          if (payload.data && payload.data.delete) {
            this.store.dispatch(new DeleteFromServer(payload.data.delete))
          }
          if (payload.command === 'reload') {
            console.log(new Date().toLocaleString(), 'ActionCable received command reload')
            this.store.dispatch(new WallInit())
          }
        },
      },
    )
  }

  private createTwngUserChannel(cable: ActionCable.Cable) {
    cable.subscriptions.create({
      channel: 'TwngUserChannel'
    }, {
      connected: () => {
        this.sessionString$.next(randomString(30))
        if (!environment.production) {
          console.log('ActionCable: Connected to TwngUserChannel')
        }
      },
      received: (payload: PossibleWebsocketCommands) => {
        if (payload?.data && payload.user_session_string === this.sessionString$.value) {
          switch(payload.command) {
            case 'widget_data': {
              const dashboardData = payload.data as DashboardChart
              if (dashboardData.data?.queue_key) {
                // this seems more reliable approach to update widgets
                this.store.dispatch(new UpdateWidgetStatusFromQueueKey(dashboardData.data))
              } else {
                if (dashboardData.id !== null) {
                  waitUntil(this.store, selectDashboardChartById(dashboardData.id)).subscribe(() => {
                    this.store.dispatch(new UpdateDashboardWidgetDataFromServer(dashboardData))
                  })
                } else {
                  this.store.dispatch(new UpdateWidgetDataFromServer(payload.data))
                }
              }
              break
            }
          }
        }
      },
      disconnected: () => {
        this.sessionString$.next(null)
      }
    })
  }

  constructor(
    private store: Store<fromWall.State>,
    private appConfig: AppConfigService,
  ) {
    if (!this.appConfig.demoEnabled()) {
      this.store.select(selectAppConfig).pipe(
        map(config => config?.update_protocol),
        distinctUntilChanged()
      ).subscribe(updateProtocol => {
        if (updateProtocol === ACTION_CABLE) {
          this.cable = ActionCable.createConsumer(this.actionCableHost())
          this.createTwngChannel(this.cable)
          this.createTwngUserChannel(this.cable)
        }
      })
    }
  }
}
