import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'

import { ConversionRatesRecord } from '../../../../wall/reducers/job-stage-stats.reducer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-candidate-conversion-popover',
  templateUrl: 'candidate-conversion-popover.component.html',
  styleUrls: ['candidate-conversion-popover.component.scss']
})
export class CandidateConversionPopoverComponent implements OnInit {
  @Input()
    isOfferAcceptenceRateStage = false

  @Input()
    jobStageConversionRates: Partial<ConversionRatesRecord>

  @Input()
    type: 'all' | 'EEOC filtered' = 'all'

  @Input()
    numConverted: number

  @Input()
    numProcessed: number

  @Input()
    conversionRate: number

  constructor() { }

  ngOnInit(): void { }

  get firstNumber(): number {
    return this?.jobStageConversionRates.passed_through || 0
  }

  get firstNumberText(): string {
    return this.isOfferAcceptenceRateStage ? "Hires" : "Converted"
  }

  get secondNumber(): number {
    return this.isOfferAcceptenceRateStage ?
      (this.jobStageConversionRates?.offers_rejected || 0) + (this.jobStageConversionRates?.hired || 0) :
      (this.jobStageConversionRates?.passed_through || 0) + (this.jobStageConversionRates?.rejected || 0)
  }

  get secondNumberText(): string {
    return this.isOfferAcceptenceRateStage ? 'Offers Processed' : 'Processed'
  }

  get finalNumber(): number {
    return this.isOfferAcceptenceRateStage ?
      (this.jobStageConversionRates?.offers_accepted_percent || 0) :
      (this.jobStageConversionRates?.converted_percent || 0)
  }

  get finalNumberText(): string {
    return this.isOfferAcceptenceRateStage ? 'Offer Acceptance Rate' : 'Conversion Rate'
  }
}
