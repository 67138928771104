<div class="CandidateCardComponent">
  <div appDraggable mainClass="CandidateCardComponent" [model]="dragModel">
    <twng-card
      (click)="clickOpenDetails()"
      [jobApplication]="jobApplication"
      [candidate]="candidate"
      [jobStage]="jobStage"
      [activityDayCategories]="activityDayCategories"
      [interviews]="interviews"
      [sourceTypeIconMappings]="sourceTypeIconMappings"
    ></twng-card>
  </div>
</div>