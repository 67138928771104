import { Observable, Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromDashboard from '../reducers'
import { CacheService } from '../../wall/services/cache.service'
import { ChartFilters } from '../models/chart-filters'
import { DashboardChart } from '../models/dashboard-chart'
import { DataSources } from '../reducers/dashboard.reducer'
import { EnableEditCustomStatBox } from '../actions/filters.actions'
import { ShowGenericTableModal } from '../../core/actions/generic-table.actions'
import { WidgetDataType, viewWidgetTypeTooltip } from '../../wall/models/offer'
import { atsId } from '../../wall/models/types'
import { findIdsInChartData } from '../../shared/utils/general-utils'

export const customBoxDataType = 'custom'
export const customBoxDataSource = 'number-box-custom'

@Component({
  selector: 'twng-dash-stats',
  templateUrl: './stats.component.html',
  styleUrls: [
    './stats.component.scss',
  ],
})
export class StatsComponent implements OnInit, OnDestroy {

  CUSTOM_BOX_DATA_TYPE = customBoxDataType
  CUSTOM_BOX_DATA_SOURCE = customBoxDataSource

  @Input()
    label: string

  @Input()
    value: number | string

  @Input()
    isInGridster = false

  @Input()
    bottom: string

  @Input()
    bottomLine: string

  @Input()
    dataSource: string

  @Input()
    dataSourceParameters: { [key: string]: string }

  @Input()
    chart: DashboardChart

  @Input()
    ids: atsId[]

  @Input()
    candidateDataType: WidgetDataType

  @Input()
    chartFilters: ChartFilters

  @Output()
    showCandidates = new EventEmitter()

  @Input()
    gridItemType: 'css' | 'gridster' = 'css'

  @Input()
    editingGridster: boolean

  @Input()
    filtersEnabled = true

  @Input()
    dataType: string

  @Input()
    chartFiltersReadOnly: boolean

  @Input()
    overrideFiltersSet: boolean

  // draw as title (without background, border, etc)
  @Input()
    useTitleStyle: boolean

  get isInsideCustomDashboard() {
    return this.gridItemType === 'gridster'
  }

  get clickable(): boolean {
    if (this.dataSource === this.CUSTOM_BOX_DATA_SOURCE) {
      return this.isInsideCustomDashboard
    } else {
      const ids = this.getIds()
      // For some reason, ids.length > 0 is necessary even though we have !!
      return !!ids && ids.length > 0
    }
  }

  get stringValue(): string {
    return typeof this.value === 'string' ? this.value : this.value?.toString()
  }

  dataSources: DataSources = {}
  dataSourcesSub: Subscription

  editingCustomValue$: Observable<boolean>
  demoMode = !!window.twng_demo

  present(str: string): boolean {
    return str && str.trim() !== ""
  }

  constructor(
    private cacheService: CacheService,
    private store: Store<fromDashboard.State>
  ) { }

  getIds() {
    return this.chart?.data?.data ? findIdsInChartData(this.chart.data.data[0]) : this.ids
  }

  ngOnInit(): void {
    this.dataSourcesSub = this.cacheService.dataSources$.subscribe(dataSources => {
      this.dataSources = dataSources
    })

    this.editingCustomValue$ = this.store.select(fromDashboard.selectEditingCustomStatBoxId).pipe(
      filter(() => !!this.chart),
      map((chartId: string) => chartId === this.chart.id),
    )
  }

  ngOnDestroy(): void {
    this.dataSourcesSub.unsubscribe()
  }

  onClick() {
    this.showCandidates.emit()
    const ids = this.getIds()
    if (this.candidateDataType && (!!ids || this.demoMode)) {
      this.store.dispatch(new ShowGenericTableModal({
        data_ids: ids,
        chart: this.dataSource || this.candidateDataType,
        clickedGraphTitle: this.label
      }))
    }
  }

  setTooltip() {
    return viewWidgetTypeTooltip(this.candidateDataType)
  }

  bigNumberClasses() {
    return {
      'stats__big-number': true,
      'stats__big-number--clickable': this.clickable,
      'stats__big-number--css': !this.isInsideCustomDashboard,
      'stats__big-number--gridster': this.isInsideCustomDashboard
    }
  }

  editValue() {
    this.store.dispatch(new EnableEditCustomStatBox({ chartId: this.chart.id }))
  }

  valueStr(value) {
    return value === undefined || value === null ? '?' : value
  }

  getTooltipText() {
    let text = ''
    if (!this.chart?.not_editable) {
      text = this.chart?.custom_value && this.clickable ? 'Edit' : 'Send to Custom Dashboard to Edit'
    }
    return text
  }
}
