import { Component, OnInit } from '@angular/core'
import { Disability, EeocGenders, EeocRaces, VeteranOptions } from '../../../core/models/eeoc'
import { EeocFilters, isAnythingInEeocSet } from '../../reducers/job-analytics.reducer'
import { FilterOption, parseIntId } from '../../../shared/components/filters/generic-filter/filter-option.interface'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { UpdateEeocFilters } from '../../actions/jobs.actions'
import { cloneDeep } from 'lodash-es'
import { enumToFilterOptions } from '../../../shared/utils/general-utils'
import { first } from 'rxjs/operators'
import { selectEeocFilters } from '../../reducers'

@Component({
  selector: 'twng-eeoc-filters-on-job-analytics-modal',
  templateUrl: './eeoc-filters-on-job-analytics-modal.component.html',
  styleUrls: ['./eeoc-filters-on-job-analytics-modal.component.css']
})
export class EeocFiltersOnJobAnalyticsModalComponent implements OnInit {
  genderOptions: FilterOption[]
  raceOptions: FilterOption[]
  veteranOptions: FilterOption[]
  disabilityOptions: FilterOption[]
  filters: EeocFilters

  constructor(
    private store: Store,
    private modal: NgbActiveModal,
  ) {
    this.genderOptions = enumToFilterOptions(EeocGenders)
    this.raceOptions = enumToFilterOptions(EeocRaces)
    this.veteranOptions = enumToFilterOptions(VeteranOptions)
    this.disabilityOptions = enumToFilterOptions(Disability)
    this.filters = {
      genders: [],
      races: [],
      disabilities: [],
      veterans: []
    }
  }

  ngOnInit(): void {
    this.store.select(selectEeocFilters).pipe(first()).subscribe(filters => {
      if (isAnythingInEeocSet(filters)) {
        this.filters = cloneDeep(filters)
      }
    })
  }

  defaultSelectedDisabilities() {
    return this.disabilityOptions.filter(option =>
      this.filters.disabilities.includes(parseIntId(option.id))
    )
  }

  defaultSelectedRaces() {
    return this.raceOptions.filter(option =>
      this.filters.races.includes(parseIntId(option.id))
    )
  }
  defaultSelectedVeterans() {
    return this.veteranOptions.filter(option =>
      this.filters.veterans.includes(parseIntId(option.id))
    )
  }
  defaultSelectedGenders() {
    return this.genderOptions.filter(option =>
      this.filters.genders.includes(parseIntId(option.id))
    )
  }

  genderSelectionChanged(newGenders: FilterOption[]) {
    this.filters.genders = newGenders.map(
      option => parseIntId(option.id)
    )
  }

  veteranSelectionChanged(newVeterans: FilterOption[]) {
    this.filters.veterans = newVeterans.map(
      option => parseIntId(option.id)
    )
  }

  disabilitySelectionChanged(newDisability: FilterOption[]) {
    this.filters.disabilities = newDisability.map(
      option => parseIntId(option.id)
    )
  }

  raceSelectionChanged(newRaces: FilterOption[]) {
    this.filters.races = newRaces.map(
      option => parseIntId(option.id)
    )
  }

  save() {
    this.store.dispatch(new UpdateEeocFilters(this.filters))
    this.modal.close()
  }
}
