<div class="transfer-to-job">
  <div class="section__header-wrapper fs-unmask">
    <h2 class="section__header m-0">
      <span class="section__icon-wrapper">
        <i class="fas fa-arrows-alt-h"></i>
      </span>
      <span>
         Transfer Job
      </span>
    </h2>
  </div>

  <div class="tw-mt-2">
    <dl class="section__item">
      <dt class="section__item-label fs-unmask">From</dt>
      <dd class="section__item-value">{{ job.name }}</dd>
    </dl>

    <form class="tw-mt-3 tw-mb-2">
      <div class="form-group">
        <dl class="section__item">
          <dt class="section__item-label fs-unmask">
            To Job
          </dt>
          <dd class="section__item-value">
            <select #jobSelect
              class="form-select"
              name="jobId"
              [ngModel]="selectedJobId"
              (change)="updateSelectedJobId(jobSelect.value)"
              *ngIf="jobsLoaded$ | async; else loading"
            >
              <option
                *ngFor="let job of jobs"
                [value]="job.id"
              >
                {{ jobNameForSelect(job) }}
              </option>
            </select>
          </dd>
        </dl>
      </div>
      <div class="form-group">
        <dl class="section__item">
          <dt class="section__item-label fs-unmask">
            To Stage
          </dt>
          <dd class="section__item-value">
            <select #jobStageSelect
              *ngIf="jobStagesForSelectedJob; else loading"
              class="form-select"
              name="jobStageId"
              [ngModel]="selectedJobStageId"
              (change)="updateSelectedStageId(jobStageSelect.value)"
            >
              <option
                *ngFor="let stage of jobStagesForSelectedJob"
                [value]="stage.id"
                >{{ stage.name }}</option
              >
            </select>
          </dd>
        </dl>
      </div>

      <div *ngIf="showTransferButton" class="actions-row form-group form-row">
        <button
          type="submit"
          class="btn btn-primary fs-unmask"
          mwlConfirmationPopover
          popoverTitle="Continue?"
          popoverMessage="Do you want to transfer {{ candidateName() }}?"
          (confirm)="transferCandidate()"
        >
          Transfer
        </button>

        <div *ngIf="showError" class="tw-mt-2 alert alert-danger fs-unmask">
          Sorry, an error occurred when attempting to transfer this candidate.
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #loading>
  <i class="fas fa-sync fa-spin"></i>
</ng-template>