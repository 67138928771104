<ng-container *ngIf="isDateValue">
  {{ value | date }}
</ng-container>
<ng-container *ngIf="isGreenhouseLink">
  <a *ngIf="isNotEmpty" class="button--ats" rel="noopener noreferrer" target="_blank" ngbTooltip="View in Greenhouse" [href]="value">
    <img class="ats-icon" height="32" width="32" src="/assets/greenhouse-icon.png">
  </a>
</ng-container>
<ng-container *ngIf="isTeamableLink">
  <a *ngIf="isNotEmpty" class="button--ats" rel="noopener noreferrer" target="_blank" ngbTooltip="View in Teamable" [href]="value">
    <img class="ats-icon" height="32" width="32" src="/assets/teamable-logo.png">
  </a>
</ng-container>
<ng-container *ngIf="isMultirow">
  <div *ngFor="let el of (value || [])" style="font-size: 0.9rem;">
    {{ el }}
  </div>
</ng-container>
<ng-container *ngIf="isToggle">
  <div class="tw-flex tw-items-center tw-justify-center">
    <input id="angular-checkbox" type="checkbox" value="" [ngbTooltip]="togglePopover" (change)="toggle()" [checked]="toggleValue.value"
      class="tw-cursor-pointer tw-w-5 tw-h-5 tw-appearance-none tw-bg-white tw-border-gray-400 tw-rounded tw-border-2 checked:tw-border-cq-primary-600 checked:tw-accent-cq-primary-600">
  </div>
</ng-container>
<ng-container *ngIf="!isInfoOnly && !isGreenhouseLink && !isDateValue && !isMultirow && !isToggle && !isTeamableLink">
  {{ value }}
</ng-container>
