
import * as chroma from 'chroma-js'
import { Color, ScaleType } from '@swimlane/ngx-charts'

// Duplicated in SCSS
// @todo: Combine SCSS + JS vars?
export const TwColors = {
  dark_green: '#0b683d',
  green: '#12B76A',
  yellow: '#FAC515',
  red: '#F04438',
  dark_red: '#8d2720',
  blue: '#2988E2',
  gray: '#9192A0',
  darkGray: '#555555',
  purple: '#B778CC',
  white: '#DDDDDD',
}

export const TwScale = chroma.scale([
  TwColors.green,
  TwColors.yellow,
  TwColors.red,
  TwColors.blue,
  TwColors.purple,
])

export const DefaultColorScheme: Color = {
  name: 'twColors',
  selectable: false,
  group: ScaleType.Ordinal,
  domain: TwScale.colors(10),
}

export const RedColorScheme: Color = {
  name: 'twDarkColors',
  selectable: false,
  group: ScaleType.Ordinal,
  domain: [TwColors.red],
}

export const BlueColorScheme: Color = {
  name: 'twBlue',
  selectable: false,
  group: ScaleType.Ordinal,
  domain: [TwColors.blue],
}

export const hiringGoalTrackingColors = [
  { name: 'Hires', value: TwColors.blue },
  { name: 'Not hired yet', value: TwColors.white },
  { name: 'Starts', value: TwColors.blue },
  { name: 'Not started yet', value: TwColors.white },
]

export const scorecardResultColors = [
  { name: 'Strong Yes', value: TwColors.dark_green },
  { name: 'Yes', value: TwColors.green },
  { name: 'No Decision', value: TwColors.yellow },
  { name: 'No', value: TwColors.red },
  { name: 'Strong No', value: TwColors.dark_red },
]

export const offerResponseColors = [
  { name: 'Accepted', value: TwColors.green },
  { name: 'Rejected', value: TwColors.red },
]

export const jobPerStatusColors = [
  { name: 'draft', value: TwColors.gray },
  { name: 'open', value: TwColors.green },
]

export const timeInStageHiresColors = [
  { name: 'Hired', value: TwColors.blue },
  { name: 'Not Hired', value: TwColors.red },
]