import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { JobStage } from '../models/job-stage'

import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { WallActionTypes, WallActions } from '../actions/wall.actions'

export interface State extends EntityState<JobStage> {
  allNames: string[]
}

export function sortByPosition(a: JobStage, b: JobStage): number {
  return a.position - b.position
}

export const adapter: EntityAdapter<JobStage> = createEntityAdapter<JobStage>({
  sortComparer: sortByPosition,
})

export const initialState: State = adapter.getInitialState({
  allNames: [],
})

export function reducer(
  state = initialState,
  action: LoaderActions | WallActions,
): State {
  switch (action.type) {
    case WallActionTypes.FetchJobsByIdsSuccessAction: {
      const tempState = adapter.upsertMany(action.payload.job_stages || [], state)
      return {
        ...tempState,
        allNames: state.allNames,
      }
    }

    case LoaderActionTypes.LoadSharedDataSuccess: {
      return {
        ...state,
        allNames: action.payload.job_stage_names,
      }
    }

    case LoaderActionTypes.UpdateFromServer: {
      const jobStages = action.payload.job_stages
      if (!jobStages) {
        return state
      }
      return adapter.upsertMany(jobStages || [], state)
    }

    default:
      return state
  }
}
