<ng-container *ngIf="historyChanges.length; else noUpdates;">
  <div *ngFor="let change of historyChanges" class="history-item">
    <div class="history-item--created">
      {{ change.created_at | date:'MMM d, y @ h:mma Z' }}
    </div>
    <div [innerHTML]="change.label"></div>
  </div>
</ng-container>

<ng-template #noUpdates>
  <div class="history-item no-updates">
    There are no updates for this job.
  </div>
</ng-template>
