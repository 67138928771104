import { AllConversionRates } from './job-stage-stats.reducer'
import { EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID, defaultClosedJobTab } from './executive-dashboard-closed-job-tab.data'
import { ExecutiveDashboardActions, ExecutiveDashboardActionsTypes } from '../actions/executive-dashboard.actions'
import { ExecutiveDashboardColumnOrderInfo, ExecutiveDashboardConfiguration,
  ExecutiveDashboardResponse, ExecutiveDashboardTab } from '../models/executive-dashboard'
import { Job } from '../models/job'
import { JobStage } from '../models/job-stage'
import { JobStatusUpdate } from '../models/job-status-update'
import { cloneDeep, pull, remove } from 'lodash-es'
import { find } from 'lodash-es'
import { objKeysSafe } from '../../shared/utils/general-utils'

export interface ClosedJobsInfo {
  jobs: Job[]
  job_stages: JobStage[]
  job_stage_conversion_stats: AllConversionRates
  executive_dashboard_configuration?: ExecutiveDashboardConfiguration
  offers_per_job: { [jobId: string]: number }
}

export interface ExecutiveDashboardState extends ExecutiveDashboardResponse {
  shared_tab: ExecutiveDashboardTab | null
  job_status_updates: JobStatusUpdate[]
  closed_jobs_info: ClosedJobsInfo | null
  closed_jobs_tab: ExecutiveDashboardTab
  loaded: boolean
  last_fetched_tab_job_ids: string[] | null
}

export const initialState: ExecutiveDashboardState = {
  job_status_updates: [],
  closed_jobs_info: null,
  executive_dashboard_configuration: {
    projected_hires_use_green_instead_of_gray: false,
    projected_hires_yellow_threshold: null,
    projected_hires_red_threshold: null,
    days_open_use_green_instead_of_gray: false,
    days_open_yellow_threshold: null,
    days_open_red_threshold: null,
    days_open_exclude_jobs_with_more_openings: false,
    conversion_rates_use_green_instead_of_gray: false,
    conversion_rates_yellow_threshold: null,
    conversion_rates_red_threshold: null,
    target_hire_days_after_opening: null,
    target_hire_date_use_green_instead_of_gray: false,
    target_hire_date_yellow_threshold: null,
    target_hire_date_red_threshold: null,
    job_stage_thresholds: null,
    use_individual_job_stage_thresholds: null,
    tabs: [],
  },
  loaded: false,
  job_days_open_colors: {},
  recruiters: [],
  offers_per_job: {},
  recent_candidates_per_job: {},
  per_job_configuration: {},
  closed_jobs_tab: defaultClosedJobTab,
  shared_tab: null,
  last_fetched_tab_job_ids: null,
}

function updateTabColumnOrder(tab: ExecutiveDashboardTab): ExecutiveDashboardTab {
  tab = cloneDeep(tab)
  for (const originalKey of objKeysSafe(tab)) {
    const key = originalKey as keyof ExecutiveDashboardTab
    if (key === 'show_custom_fields') {
      continue
    }
    if (key.startsWith('show_')) {
      const column: ExecutiveDashboardColumnOrderInfo = {
        type: 'regular',
        value: key.substr('show_'.length)
      }
      // if this column should be displayed
      if (tab[key]) {
        // if column is not found, push it
        if (!find(tab.column_order, column)) {
          tab.column_order.push(column)
        }
      } else {
        // remove column if it should be invisible
        const found = find(tab.column_order, column)
        if (found) {
          pull(tab.column_order, found)
        }
      }
    }
  }
  for (const cf of tab.custom_fields_to_show) {
    const column: ExecutiveDashboardColumnOrderInfo = {
      type: 'custom_field',
      value: cf
    }
    if (!find(tab.column_order, column)) {
      tab.column_order.push(column)
    }
  }
  remove(tab.column_order,
    column => !tab.custom_fields_to_show.includes(column.value) && column.type === 'custom_field')
  return tab
}


export function reducer(state = initialState, action: ExecutiveDashboardActions): ExecutiveDashboardState {
  switch (action.type) {
    case ExecutiveDashboardActionsTypes.FetchJobStatusesSuccess: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case ExecutiveDashboardActionsTypes.FetchExecutiveDashboard: {
      return {
        ...state,
        loaded: false,
      }
    }
    case ExecutiveDashboardActionsTypes.FetchExecutiveDashboardSuccess: {
      const dashboard = action.payload
      if (dashboard) {
        return {
          ...state,
          ...dashboard,
          loaded: true,
        }
      }
      break
    }
    case ExecutiveDashboardActionsTypes.SendJobStatusUpdateSuccess: {
      return {
        ...state,
        job_status_updates: [...state.job_status_updates, action.payload.job_status_update]
      }
    }
    case ExecutiveDashboardActionsTypes.FetchExecutiveDashboardClosedJobsSuccess: {
      if (action.payload) {
        const payload = { ...action.payload }
        const config = action.payload.executive_dashboard_configuration
        delete payload.executive_dashboard_configuration
        return {
          ...state,
          closed_jobs_info: payload,
          executive_dashboard_configuration: config,
        }
      }
      break
    }
    case ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardConfigSuccess: {
      if (action.payload) {
        return {
          ...state,
          executive_dashboard_configuration: {
            ...state.executive_dashboard_configuration,
            ...action.payload,
          }
        }
      }
      break
    }
    case ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabSuccess: {
      if (action.payload) {
        if (action.payload.id !== EXECUTIVE_DASHBOARD_CLOSED_JOBS_TAB_ID) {
          return {
            ...state,
            executive_dashboard_configuration: {
              ...state.executive_dashboard_configuration,
              tabs: state.executive_dashboard_configuration.tabs.map(
                tab => tab.id === action.payload.id ? action.payload : tab
              )
            }
          }
        } else {
          return {
            ...state,
            closed_jobs_tab: updateTabColumnOrder({
              ...state.closed_jobs_tab,
              ...action.payload,
            })
          }
        }
      }
      break
    }
    case ExecutiveDashboardActionsTypes.UpdatePerJobConfigSuccess: {
      if (action.payload) {
        return {
          ...state,
          per_job_configuration: {
            ...state.per_job_configuration,
            ...action.payload
          }
        }
      }
      break
    }
    case ExecutiveDashboardActionsTypes.DeleteExecutiveDashboardTabSuccess: {
      if (action.payload) {
        return {
          ...state,
          executive_dashboard_configuration: {
            ...state.executive_dashboard_configuration,
            tabs: state.executive_dashboard_configuration.tabs.filter(
              tab => tab.id !== action.payload.id
            )
          }
        }
      }
      break
    }
    case ExecutiveDashboardActionsTypes.CreateExecutiveDashboardTabSuccess: {
      if (action.payload) {
        return {
          ...state,
          executive_dashboard_configuration: {
            ...state.executive_dashboard_configuration,
            tabs: state.executive_dashboard_configuration.tabs.concat(action.payload)
          }
        }
      }
      break
    }
    case ExecutiveDashboardActionsTypes.ReorderExecutiveDashboardTabsSuccess: {
      if (action.payload) {
        return {
          ...state,
          executive_dashboard_configuration: {
            ...state.executive_dashboard_configuration,
            tabs: action.payload
          }
        }
      }
      break
    }
    case ExecutiveDashboardActionsTypes.FetchSharedExecutiveDashboardTabSuccess: {
      if (action.payload) {
        return {
          ...state,
          shared_tab: action.payload
        }
      }
      break
    }
    case ExecutiveDashboardActionsTypes.FetchJobIdsForExecutiveDashboardTab: {
      return {
        ...state,
        last_fetched_tab_job_ids: null,
      }
    }
    case ExecutiveDashboardActionsTypes.FetchJobIdsForExecutiveDashboardTabSuccess: {
      return {
        ...state,
        last_fetched_tab_job_ids: action.payload.job_ids,
      }
    }
    case ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardTabHideSectionSuccess: {
      if (action.payload) {
        return {
          ...state,
          executive_dashboard_configuration: {
            ...state.executive_dashboard_configuration,
            tabs: state.executive_dashboard_configuration.tabs.map(
              tab => tab.id === action.payload.id ? action.payload : tab
            )
          }
        }
      }
      break
    }
  }
  return state
}
