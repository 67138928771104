import { AppConfigService } from '../../../../../../wall/services/app-config.service'
import { ChartFilters } from '../../../../../models/chart-filters'
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'
import { UntypedFormGroup } from '@angular/forms'
import { previousQuarterStart, quarterYear, startOfQuarter } from '../../../../../../shared/utils/general-utils'

@Component({
  selector: 'twng-date-chart-filter-form',
  templateUrl: './date-chart-filter-form.component.html',
  styleUrls: ['../date-chart-filter.component.scss',
    '../../single-chart-filter-template/single-chart-filter-template.component.scss'
  ]
})
export class DateChartFilterFormComponent implements OnChanges {
  @Input() dateFilterForm: UntypedFormGroup
  @Input() filters: ChartFilters

  datesFilters = [
    {
      buttonText: 'YTD',
      value: 'ytd',
    },
    {
      buttonText: 'QTD',
      value: 'qtd'
    },
    {
      buttonText: 'MTD',
      value: 'mtd'
    },
    {
      buttonText: 'WTD',
      value: 'wtd'
    },
    {
      buttonText: 'Last 7 Days',
      value: 'last7days',
    },
    {
      buttonText: 'Last 3 Months',
      value: 'last3months',
    },
    {
      buttonText: 'Last 6 Months',
      value: 'last6months',
    },
    {
      buttonText: 'Last 12 Months',
      value: 'last12months'
    },
    {
      buttonText: 'Last Quarter',
      value: 'lastQuarter'
    },
    {
      buttonText: 'Last Business Week',
      value: 'lastBusinessWeek'
    },
    {
      buttonText: 'Last Year',
      value: 'lastYear'
    },
    {
      buttonText: 'Today',
      value: 'today'
    },
    {
      buttonText: 'Custom',
      value: 'custom'
    },
  ]

  constructor(
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private appConfig: AppConfigService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && this.filters) {
      this.dateFilterForm.setValue({
        dateFilterMode: this.filters.date_mode,
        startDate: this.ngbDateParserFormatter.parse(this.filters.start_date),
        endDate: this.ngbDateParserFormatter.parse(this.filters.end_date)
      })
    }
  }

  getFormatDate(date) {
    return this.ngbDateParserFormatter.format(date)
  }

  updateControlsValue() {
    this.dateFilterForm.setValue({
      dateFilterMode: this.dateFilterForm.controls['dateFilterMode'].value,
      startDate: this.dateFilterForm.controls['startDate'].value,
      endDate: this.dateFilterForm.controls['endDate'].value
    })
  }

  selectDate(date) {
    this.dateFilterForm.controls.dateFilterMode.setValue(date)
    this.setTimePeriod(date)
  }

  setTimePeriod(date) {
    const firstFiscalMonth = this.appConfig.firstFiscalMonth()
    const today = new Date()
    const currentMonth = today.getMonth()
    const currentYear = today.getFullYear()
    let startDate
    let endDate = new Date(today.setHours(0,0,0,0))
    switch (date) {
      case 'ytd':
        startDate = new Date(quarterYear(firstFiscalMonth), firstFiscalMonth, 1)
        break
      case 'qtd':
        startDate = new Date(startOfQuarter(firstFiscalMonth))
        break
      case 'mtd':
        startDate = new Date(currentYear, currentMonth, 1)
        break
      case 'wtd':
        const startOfWeek = today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
        startDate = new Date(today.setDate(startOfWeek))
        break
      case 'last7days':
        const start7Days = new Date().setDate(today.getDate() - 7)
        startDate = new Date(new Date(start7Days).setHours(0,0,0,0))
        break
      case 'last3months':
        const start3Months = new Date().setMonth(today.getMonth() - 3)
        startDate = new Date(new Date(start3Months).setHours(0,0,0,0))
        break
      case 'last6months':
        const start6Months = new Date().setMonth(today.getMonth() - 6)
        startDate = new Date(new Date(start6Months).setHours(0,0,0,0))
        break
      case 'last12months':
        const start12Months = new Date().setMonth(today.getMonth() - 12)
        startDate = new Date(new Date(start12Months).setHours(0,0,0,0))
        break
      case 'lastQuarter':
        startDate = previousQuarterStart(firstFiscalMonth)
        const currentQuarterStartDate = new Date(startOfQuarter(firstFiscalMonth))
        currentQuarterStartDate.setDate(currentQuarterStartDate.getDate() - 1)
        endDate = currentQuarterStartDate
        break
      case 'lastBusinessWeek':
        const startLBW = new Date().setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -13 : -6))
        startDate = new Date(new Date(startLBW).setHours(0,0,0,0))
        const endLBW = new Date(startDate).setDate(startDate.getDate() + 6)
        endDate = new Date(new Date(endLBW).setHours(0,0,0,0))
        break
      case 'lastYear':
        startDate = new Date(quarterYear(firstFiscalMonth) - 1, firstFiscalMonth, 1)
        endDate = new Date(quarterYear(firstFiscalMonth), firstFiscalMonth, 0)
        break
      case 'today':
        startDate = today
        endDate = today
        break
      default:
        return
    }

    if (this.dateFilterForm.controls.dateFilterMode.value !== 'custom') {
      const start = this.ngbDateParserFormatter.parse(startDate.toISOString())
      const end = this.ngbDateParserFormatter.parse(endDate.toISOString())

      this.dateFilterForm.setValue({
        dateFilterMode: date,
        startDate: start,
        endDate: end
      })
    }
  }
}
