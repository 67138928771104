<div class="name">
  <form class="form" [formGroup]="labelForm" (ngSubmit)="onSubmit()">
    <input class="form-input" aria-label="Enter to Save" type="text" autocomplete="off" #inputLabel
      formControlName="label" (blur)="onSubmit()" [ngbPopover]="enterToSavePopover" popoverClass="min-width--auto"
      [autoClose]="false" placement="bottom auto" triggers="focus" />
  </form>
</div>

<ng-template #enterToSavePopover>
  <div class="kb-wrapper">
    <span class="kb-shortcut">Enter</span>
    <span class="kb-text">to save</span>
  </div>
</ng-template>