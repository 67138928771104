import * as fromWall from '../../../wall/reducers'
import { CacheService } from '../../../wall/services/cache.service';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Department } from '../../../wall/models/department';
import { DepartmentService } from '../../../wall/services/department.service';
import { Job } from '../../../wall/models/job';
import { JobStatus, JobStatusUpdate } from '../../../wall/models/job-status-update';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Office } from '../../../wall/models/office';
import { OfficeService } from '../../../wall/services/office.service';
import { Store } from '@ngrx/store'
import { map, tap } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-job-status-and-notes-history-modal',
  templateUrl: './job-status-and-notes-history-modal.component.html',
  styleUrls: [
    '../../../../scss/components/_candidates_modal.scss',
    './job-status-and-notes-history-modal.component.scss'
  ]
})
export class JobStatusAndNotesHistoryModalComponent implements OnInit {

  @Input()
    job: Job

  @Input()
    showNotes = true

  @Input()
    showUpdateJobStatus = true

  jobStatusLabels: JobStatus
  jobStatusUpdates$: Observable<JobStatusUpdate[]>
  departments$: Observable<Department[]>
  offices$: Observable<Office[]>

  constructor(private activeModal: NgbActiveModal,
    private cacheService: CacheService,
    private officeService: OfficeService,
    private departmentService: DepartmentService,
    private store: Store) {
  }

  async ngOnInit() {
    this.jobStatusUpdates$ = this.cacheService.jobIdToStatusUpdates$.pipe(
      map(jobStatusUpdateMap => jobStatusUpdateMap[this.job.id]),
    )
    this.departments$ = this.departmentService.getDepartments$(this.job.department_ids)
    this.offices$ = this.officeService.getOffices$(this.job.office_ids)

    await this.store.select(fromWall.selectJobStatusLabels).pipe(
      tap((jobStatusLabels: JobStatus) => {
        this.jobStatusLabels = jobStatusLabels
      }),
    ).toPromise()
  }

  closeModal() {
    this.activeModal.close()
  }

  present(arr: unknown[]) {
    return arr && arr.length !== 0
  }

}
