<!-- "Note: we always hide private candidates, with the exception that, if you have the special permission in TalentWall, you may be able to view private hired candidates." -->
<!-- <b>" + pluralize(word, input_count - output_count) + "</b>" + " are private, confidential, or not visible based on your Greenhouse permissions. -->
<div>
  <div class="header fs-unmask" *ngIf="title">
    <div class="header__content">
      <div class="header__primary">
        <h1 class="modal-title">
          {{ title }}
        </h1>
        <h1 class="modal-chart-title-info">
          {{ charTitleInfo }}
        </h1>
      </div>

      <div class="header__secondary">
        <h2>
          <span *ngIf="!demoMode">{{ subtitle }}</span>
          <span *ngIf="demoMode">
            In a live TalentWall account, this would be a list of the candidates involved in this calculation
          </span>
          <i class="fas fa-info-circle tooltip-icon" *ngIf="subtitleTooltip" [ngbTooltip]="subtitleTooltip"></i>
        </h2>
      </div>
    </div>

    <button class="header__close header__close--large" type="button" aria-label="Close" (click)="closeModal()">
      <span class="large" aria-hidden="true">
        <i class="fas fa-times"></i>
      </span>
    </button>
  </div>
  <div class="body">
    <div *ngIf="showCsvDownloadOnly">
      There is too much data to display here. To view all data you can click "Download as CSV" button to the right.
    </div>
    <div class="actions tw-text-right">
      <button class="btn btn-light" (click)="downloadAsCsv()" *ngIf="result || showCsvDownloadOnly" [disabled]="!canClickDownloadCsv">
        Download as CSV
      </button>
    </div>
    <div class="table-wrap tw-rounded-lg tw-border" style="overflow-x: auto;" *ngIf="!showCsvDownloadOnly">
      <twng-generic-table [data]="result" *ngIf="result || hiddenDataTable; else spinner" (dataChanged)="dataChanged()">
      </twng-generic-table>
    </div>
    <div *ngIf="footer && !showCsvDownloadOnly">
      <div class="footer-warning-message">
        <span class="footer-big-font-warning">
          {{ footerBigFont }}
        </span>
        {{ footer.toLowerCase() }}
        <i class="fas fa-info-circle" [ngbTooltip]="footerTip"></i>
      </div>
      <div class="table-wrap tw-rounded-lg tw-border" style="overflow-x: auto;">
        <twng-generic-table [data]="hiddenDataTable" [isWarningTable]="true" *ngIf="result || hiddenDataTable; else spinner">
        </twng-generic-table>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <twng-spinner></twng-spinner>
</ng-template>
