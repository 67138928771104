import * as fromProspects from './reducers';
import { CandidateModalEffects } from './effects/candidate-modal.effects';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { ProspectsEffects } from './effects/prospects.effects';
import { StoreModule } from '@ngrx/store';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromProspects.prospectsStateKey, fromProspects.reducer),
    EffectsModule.forFeature([ProspectsEffects, CandidateModalEffects])
  ],
  exports: [ComponentsModule]
})
export class ProspectsModule {
}
