<div class="export-overlay overlay--showing"
  *ngIf="(exporting$ | async) || isExporting"
  data-html2canvas-ignore
  [style.position]="positionAbsolute ? 'absolute' : 'fixed'"
>
  <div class="content-wrapper">
    <h1 class="header">{{placeholder}}</h1>
    <h3 class="header"></h3>
    <div class="spinner-icon">
      <i class="fas fa-sync fa-spin"></i>
    </div>
  </div>
</div>
