import { Observable, Subscription } from 'rxjs'

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core'

import * as fromWall from '../../../wall/reducers'
import { AppConfigService } from '../../../wall/services/app-config.service'
import { Department } from '../../../wall/models/department'
import { DepartmentService } from '../../../wall/services/department.service'
import { Dictionary } from '@ngrx/entity'
import { ExternalUser } from '../../../wall/models/external-user'
import { Job } from '../../../wall/models/job'
import { JobService } from '../../../wall/services/job.service'
import { JobStatus, JobStatusUpdate } from '../../../wall/models/job-status-update'
import { Office } from '../../../wall/models/office'
import { OfficeService } from '../../../wall/services/office.service'
import { Store } from '@ngrx/store'
import { differenceInDays } from 'date-fns'
import { selectExternalUserEntities } from '../../../wall/reducers'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-job-info-header',
  templateUrl: './job-info-header.component.html',
  styleUrls: ['./job-info-header.component.scss']
})
export class JobInfoHeaderComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
    job: Job

  @Input()
    showJobId = true

  @Input()
    wallStyling = false

  @Input()
    showUpdateStatusButton = true

  @Input()
    showHiringManagers = false

  @Input()
    showTagsInBottom = false

  canViewJobStatus: boolean
  jobLastStatusUpdate$: Observable<JobStatusUpdate>
  departments$: Observable<Department[]>
  offices$: Observable<Office[]>
  private sub = new Subscription()
  userEntities$: Observable<Dictionary<ExternalUser>>
  jobStatusLabels$: Observable<JobStatus>

  openingsString: string
  openingsTooltip: string

  tagsCtx = {}

  constructor(
    private officeService: OfficeService,
    private departmentService: DepartmentService,
    private jobService: JobService,
    private appConfig: AppConfigService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.canViewJobStatus = this.appConfig.canViewJobStatusOnWall()
    this.jobLastStatusUpdate$ = this.jobService.getJobStatusUpdate(this.job.id)
    this.departments$ = this.departmentService.getDepartments$(this.job.department_ids)
    this.offices$ = this.officeService.getOffices$(this.job.office_ids)
    this.userEntities$ = this.store.select(selectExternalUserEntities)
    this.openingsString = this.job.job_openings.length === 1 ? 'opening' : 'openings'
    this.jobStatusLabels$ = this.store.select(fromWall.selectJobStatusLabels)
    this.refreshTooltipText()
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  ngOnChanges() {
    this.tagsCtx = {
      job: this.job,
      openingsString: this.openingsString,
    }
  }

  present(arr: unknown[]) {
    return arr && arr.length !== 0
  }

  isOpen(job: Job): boolean {
    return job.status === 'open'
  }

  get openDays(): number {
    return differenceInDays(new Date(), new Date(this.job.opened_at))
  }

  private refreshTooltipText() {
    this.openingsTooltip = ""
    const openingIds = this.job.job_openings.
      map(opening => opening.opening_id).
      filter(id => !!id)
    if (openingIds.length > 0) {
      for (let i=0; i<openingIds.length; i++) {
        if (i >= 10) {
          break
        }
        this.openingsTooltip += openingIds[i] + '\n'
      }
    }
    const usedOpenings = this.openingsTooltip.split('\n').length - 1
    if (usedOpenings !== this.job.job_openings.length && usedOpenings !== 0) {
      this.openingsTooltip += `+ ${this.job.job_openings.length - usedOpenings} more\n`
    }
    this.openingsTooltip = this.openingsTooltip.trim()
  }
}
