
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'

import { Tab } from '../../../wall/models/tab'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-sort-tab',
  styleUrls: ['./sort-tab.component.scss'],
  templateUrl: './sort-tab.component.html',
})
export class SortTabComponent implements OnInit {
  @Input()
    tab: Tab

  @Input()
    index: number

  dragModel: {
    tab: Tab,
    index: number
  }

  constructor() { }

  ngOnInit(): void {
    this.dragModel = {
      tab: this.tab,
      index: this.index
    }
  }
}
