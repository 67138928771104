import { Action, Store } from "@ngrx/store"
import { Actions, ofType } from "@ngrx/effects"
import { AppConfigService } from "../../wall/services/app-config.service"
import { DashboardLoad, WallInit } from "../../core/actions/loader.actions"
import { FetchAllProjectedHires } from "../../wall/actions/projected-hires.actions"
import { FetchExecutiveDashboard, FetchJobStatuses } from "../../wall/actions/executive-dashboard.actions"
import { Observable } from "rxjs"
import { ToastrService } from "ngx-toastr"
import { UpdateRequestAllJobsToLoad } from "../../wall/actions/layout.actions"
import { first, map } from "rxjs/operators"
import { getNeedsToFetchProjectedHires, selectAllJobsLoadedInCurrentWall,
  selectExecutiveDashboardLoaded
} from "../../wall/reducers"
import { selectDashboardLoadCompleted, selectWallLoadCompleted } from "../../reducers"

export class ActionError extends Error {
  constructor(public action: Action, message?: string) {
    super(message)
    // Errors are a bit special case when it comes to extending them. If we
    // don't do the next line, an instanced object would not be of type
    // "ActionError", it would simply be "Error". More info in the following
    // link
    // https://stackoverflow.com/questions/41102060/typescript-extending-error-class
    Object.setPrototypeOf(this, ActionError.prototype)
  }
}
export function observableImmediatelySync<T>(o: Observable<T>): T {
  let ret: T
  o.pipe(first()).subscribe(val => ret = val)
  return ret
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function selectImmediatelySync<T>(store: Store, selector: (state: Record<string, any>) => T) {
  return observableImmediatelySync(store.select(selector))
}
export function initWallIfNecessary(store: Store) {
  store.select(selectWallLoadCompleted).pipe(
    first()
  ).subscribe(loaded => {
    if (!loaded) {
      store.dispatch(new WallInit())
    }
  })
}
export function loadDashboardIfNecessary(store: Store) {
  store.select(selectDashboardLoadCompleted).pipe(
    first()
  ).subscribe(loaded => {
    if (!loaded) {
      store.dispatch(new DashboardLoad())
    }
  })
}
export function loadExecutiveDashboardIfNecessary(store: Store) {
  store.select(selectExecutiveDashboardLoaded).pipe(
    first()
  ).subscribe(loaded => {
    if (!loaded) {
      store.dispatch(new FetchExecutiveDashboard())
    }
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function selectImmediately<T>(store: Store, selector: (state: Record<string, any>) => T) {
  return await store.select(selector).pipe(first()).toPromise()
}

export async function loadProjectedHiresIfNecessary(store: Store) {
  const needsToFetch = await selectImmediately(store, getNeedsToFetchProjectedHires)
  if (needsToFetch) {
    store.dispatch(new FetchAllProjectedHires())
  }
}

// This function will resolve/reject when
export function toasterOnActionPromise(
  successActions: string[],
  failureActions: string[],
  successMessage: string,
  failureMessage: string,
  toastr: ToastrService,
  actions: Actions,
) {
  return actions.pipe(
    ofType(...successActions, ...failureActions),
    first(),
    map(action => {
      if (successActions.includes(action.type)) {
        if (successMessage) {
          toastr.success(successMessage)
        }
        return action
      } else {
        if (failureMessage) {
          toastr.error(failureMessage)
        }
        throw new ActionError(action, failureMessage)
      }
    })
  ).toPromise()
}

// This function will only toast on store action and not throw or return
// anything
export async function toasterOnAction(
  successActions: string[],
  failureActions: string[],
  successMessage: string,
  failureMessage: string,
  toastr: ToastrService,
  actions: Actions,
) {
  try {
    return await toasterOnActionPromise(successActions, failureActions, successMessage, failureMessage, toastr, actions)
  } catch (err) {
    console.log(err.name)
    if (err instanceof ActionError) {
      console.warn('Uncought error in store:', err.message, err.action)
    } else {
      throw err
    }
  }
}

export async function loadAllJobsOnWallAndWait(store: Store)  {
  store.dispatch(new UpdateRequestAllJobsToLoad())
  // wait until all jobs are downloaded
  await store.select(selectAllJobsLoadedInCurrentWall)
    .pipe(first(v => v))
    .toPromise()
}

export function loadJobStatusesIfPossible(store: Store, appConfig: AppConfigService) {
  if (appConfig.canViewJobStatusOnWall()) {
    store.dispatch(new FetchJobStatuses())
  }
}
