import { CacheService } from './cache.service';
import { Department } from '../models/department';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { WallState } from '../reducers';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private store: Store<WallState>,
    private cacheService: CacheService) {
  }

  getDepartments$(departmentIds: string[]): Observable<Department[]> {
    return this.cacheService.departmentEntities$.pipe(
      map(departmentsDic => departmentIds.map(departmentId => departmentsDic[departmentId])),
      map(departments => departments.filter(d => !!d))
    )
  }
}
