import { Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import { Job } from '../../../wall/models/job'
import { JobService } from '../../../wall/services/job.service';
import { JobStatus, JobStatusColor, JobStatusUpdate } from '../../../wall/models/job-status-update'
import { Observable } from 'rxjs';
import { SendJobStatusUpdate } from '../../../wall/actions/executive-dashboard.actions'

@Component({
  selector: 'twng-update-job-status',
  templateUrl: './update-job-status.component.html',
  styleUrls: ['./update-job-status.component.scss'],
})
export class UpdateJobStatusComponent implements OnInit {
  @Input() job: Job
  @Input() jobStatusLabels: JobStatus

  demoMode = !!window.twng_demo

  possibleOptions = []
  noteInput = ''
  noStatus = 'No Status'

  jobLastStatusUpdate$: Observable<JobStatusUpdate>
  selectedStatus: string

  constructor(
    private store: Store,
    private jobService: JobService
  ) {
  }

  async ngOnInit() {
    Object.values(this.jobStatusLabels).forEach(status => this.possibleOptions.push(status))
    this.jobLastStatusUpdate$ = this.jobService.getJobStatusUpdate(this.job.id)
  }

  getJobStatusLabel(key) {
    return !key ? this.jobStatusLabels[this.noStatus] : this.jobStatusLabels[key]
  }

  submit(formData) {
    const status = formData.status
    const jobStatus = Object.keys(this.jobStatusLabels)
      .find(key => this.jobStatusLabels[key] === status) as JobStatusColor

    this.store.dispatch(new SendJobStatusUpdate({
      jobStatusUpdate: {
        job_id: this.job.id,
        note: this.noteInput,
        status: jobStatus === this.noStatus ? null : jobStatus,
      }
    }))
    this.noteInput = ''
  }

}
