<div class="job-section tw-rounded-md" *ngIf="job$ | async as job; else loading" [class.exporting-exec-dash-to-pdf]="exportingPdf">
  <div class="job-info">
    <div class="d-flex job-status">
      <!-- Job header -->
      <ng-container *ngIf="jobStatusUpdates$ | async as jobStatusUpdates">
        <div
          *ngIf="jobStatusUpdates && jobStatusUpdates[0].status && isOneOfOpenJobsTab && enabledJobStatusOnWall() "
          twng-job-status-color [status]="jobStatusUpdates[0].status"
          [enabled]="isOneOfOpenJobsTab && enabledJobStatusOnWall()" class="job-status-bar tw-w-4 tw-m-2 tw-mr-0 tw-rounded-full"
          [ngbTooltip]="(jobStatusLabels$ | async)[jobStatusUpdates[0].status]">
        </div>
      </ng-container>
      <div>
        <div class="job-info-header">
          <div class="job-info-container">
            <span class="job-name" [ngbTooltip]="job.name">{{ job.name }}</span>

            <i *ngIf="job.confidential" class="job-confidential far fa-eye-slash"
              ngbTooltip="Confidential (read-only)">
            </i>

            <span class="categories">
              <span class="departments">
                <span
                  *ngFor="let department of departmentService.getDepartments$(job.department_ids) | async "
                  class="department">
                  {{ department.name }}
                </span>
              </span>

              <span *ngIf="job.department_ids?.length && job.office_ids?.length">
                |
              </span>

              <span class="offices">
                <span *ngFor="let office of officeService.getOffices$(job.office_ids) | async"
                  class="office">
                  {{ office.name }}
                </span>
              </span>
            </span>
          </div>
        </div>

        <div class="buttons" data-html2canvas-ignore>
          <button class="btn btn-light" ngbTooltip="Edit Job Status" jobStatusAndNotes [job]="job"
            #jobStatusAndNotesDirective="jobStatusAndNotes"
            (click)="jobStatusAndNotesDirective.openModal()"
            *ngIf="enabledJobStatusOnWall()">
            <i class="far fa-edit "></i>
          </button>

          <button *ngIf="isOneOfOpenJobsTab" class="btn btn-light" [class.selected]="shouldShowWall"
            ngbTooltip="Show Wall View"
            (click)="shouldShowAnalytics = false; shouldShowWall = !shouldShowWall">
            <i class="fas fa-table"></i>
          </button>

          <button class="btn btn-light" [class.selected]="shouldShowAnalytics"
            ngbTooltip="Show Job Analytics"
            (click)="shouldShowWall = false; shouldShowAnalytics = !shouldShowAnalytics">
            <i class="fas fa-chart-area"></i>
          </button>

          <a target="_blank" rel="noopener noreferrer" class="tw-mr-2 tw-inline-block tw-rounded tw-align-middle tw-overflow-hidden"
            href="{{ urlService.jobUrl(job) | async }}" ngbTooltip="View in {{ appConfig.atsName() }}">
            <img class="ats-icon" src="{{ appConfig.atsIconUrl() }}" width="38" height="38" />
          </a>
        </div>
      </div>
    </div>
  </div>
  
  <!-- START FLEX TABLE -->   
  <div class="d-flex flex-row job-table">
    <ng-container *ngFor="let column of tab.column_order">
      <ng-container *ngIf="column.type === 'regular'">
        <!-- Days Open -->
        <div class="flex-table-column" *ngIf="tab.show_days_open && column.value === 'days_open'">
          <div class="flex-table-header">
            Days Open
          </div>
          <div class="flex-table-value-wrapper">
            <div [ngClass]="getDaysOpenClass(job, config$ | async, daysOpenColors$ | async)" class="column-value">
              {{ daysOpen(job) }}
            </div>
          </div>
        </div>

        <!-- Number of openings -->
        <div class="flex-table-column" *ngIf="isOneOfOpenJobsTab && tab.show_openings && column.value === 'openings'">
          <div class="flex-table-header">
            Openings
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              {{ job.job_openings.length }}
            </div>
          </div>
        </div>

        <!-- Hires -->
        <div class="flex-table-column" *ngIf="tab.show_hires && column.value === 'hires'">
          <div class="flex-table-header">
            Hires
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              {{ job.num_hires || 0 }}
            </div>
          </div>
        </div>

        <!-- Project Hires -->
        <div class="flex-table-column" *ngIf="isOneOfOpenJobsTab && tab.show_proj_hires && column.value === 'proj_hires'">
          <div class="flex-table-header">
            Projected Hires
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              <div class="stats__big-number-wrapper">
                <div class="proj-hires-value">
                  <twng-projected-hires-number [jobId]="job.id"></twng-projected-hires-number>
                  <twng-configure-hiring-predictions
                    class="projected-hires-container" [job]="job">
                  </twng-configure-hiring-predictions>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Notes -->
        <div class="flex-table-column" *ngIf="tab.show_notes && column.value === 'notes'">
          <div class="flex-table-header">
            Notes
            <button ngbTooltip="Show History" class="btn btn-light btn-sm history-btn" jobStatusAndNotes
              #jobStatusAndNotesDirective=jobStatusAndNotes [job]="job" data-html2canvas-ignore
              (click)="jobStatusAndNotesDirective.openModal()">
                <i class="fas fa-list "></i>
            </button>
          </div>
          <div class="flex-table-value-wrapper width-500">
            <div class="column-value" *ngIf="jobStatusUpdates$ | async as jobStatusUpdates">
              <div *ngIf="jobStatusUpdates[0] as jobStatusUpdate"
                class="status-wrapper">
                <div class="status-note">{{ getLastValidNote(jobStatusUpdates) }}</div>

                <div class="status-meta">
                  <span class="user-name">{{ jobStatusUpdate.user_name }}</span>,
                  <span class="date">{{ jobStatusUpdate.created_at | date:'shortDate' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Recruiter -->
        <div class="flex-table-column" *ngIf="tab.show_recruiter && column.value === 'recruiter'">
          <div class="flex-table-header">
            Recruiter
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              {{ (cache.externalUsersById$ | async)[job.recruiter_ids[0]]?.name }}
            </div>
          </div>
        </div>
        
        <!-- Hiring manager- -->
        <div class="flex-table-column" *ngIf="tab.show_hiring_manager && column.value === 'hiring_manager'">
          <div class="flex-table-header">
            Hiring manager
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              {{ (cache.externalUsersById$ | async)[job.hiring_manager_ids[0]]?.name }}
            </div>
          </div>
        </div>

        <!-- Job ID  -->
        <div class="flex-table-column" *ngIf="tab.show_job_id && column.value === 'job_id'">
          <div class="flex-table-header">
            Job ID 
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              {{ job?.id }}
            </div>
          </div>
        </div>
      
        <!-- Opening IDs -->
        <div class="flex-table-column" *ngIf="isOneOfOpenJobsTab && tab.show_opening_id && column.value === 'opening_id'">
          <div class="flex-table-header">
            Opening IDs
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              <div *ngFor="let opening of job.job_openings || []">
                {{ opening.opening_id || '?' }}
              </div>
            </div>
          </div>
        </div>

        <!-- Office -->
        <div class="flex-table-column" *ngIf="tab.show_office && column.value === 'office'">
          <div class="flex-table-header">
            Office
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              <div *ngFor="let office of officeService.getOffices$(job.office_ids) | async" class="office">
                {{ office?.name }}
              </div>
            </div>
          </div>
        </div>
      
        <!-- Opening Date -->
        <div class="flex-table-column" *ngIf="tab.show_opening_date && column.value === 'opening_date'">
          <div class="flex-table-header">
            Opening Date 
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              {{ job?.opened_at | date:'shortDate' }}
            </div>
          </div>
        </div>

        <!-- Offers Created -->
        <div class="flex-table-column" *ngIf="tab.show_offers_created && column.value === 'offers_created'">
          <div class="flex-table-header">
            Offers Created 
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              {{ (offersCreated$ | async) || 0 }}
            </div>
          </div>
        </div>

        <!-- New Candidates -->
        <div class="flex-table-column" *ngIf="isOneOfOpenJobsTab && tab.show_new_candidates && column.value === 'new_candidates'">
          <div class="flex-table-header">
            New Candidates 
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              {{ (numRecentCandidates$ | async) || 0 }}
            </div>
          </div>
        </div>

        <!-- Target hire days -->
        <ng-container *ngIf="getTargetHireDays(job, jobConfiguration$ | async, config$ | async) as result">
          <div class="flex-table-column" *ngIf="tab.show_target_hire_days && column.value === 'target_hire_days'">
            <div class="flex-table-header">
              Target hire date
            </div>
            <div class="flex-table-value-wrapper flex-column">
              <div class="column-value" [ngbTooltip]="result.tooltip" [ngClass]="result.cssClasses">
                {{ result.text ? result.text : (result.date | date:'shortDate') }}
              </div>
              <twng-configure-per-job-value [jobId]="job.id" formKeyName="target_hire_days_after_opening">
              </twng-configure-per-job-value>
            </div>
          </div>
        </ng-container>

        <!-- Requisition ID -->
        <div class="flex-table-column" *ngIf="tab.show_requisition_id && column.value === 'requisition_id'">
          <div class="flex-table-header">
            Requisition ID 
          </div>
          <div class="flex-table-value-wrapper">
            <div class="column-value">
              {{ job.requisition_id }}
            </div>
          </div>
        </div>

        <!-- Things that require job conversion rates -->
        <ng-container *ngIf="conversionRates$ | async as jobStageConversionRates">
          <!-- Job stages -->
          <ng-container *ngIf="tab.show_job_stages && column.value === 'job_stages'">
            <ng-container *ngFor="let jobStage of jobStages$ | async">
              <div class="flex-table-column" *ngIf="shouldShowJobStage(jobStage.name) && jobStageConversionRates[jobStage.id]">
                <div class="flex-table-header max-width-overflow"  [ngbTooltip]="jobStage.name">
                  {{ jobStage.name }}
                </div>
                
                <div class="flex-table-value-wrapper" *ngIf="isOneOfOpenJobsTab">
                  <div class="column-value" ngbTooltip="Active Candidates">
                    {{ jobStageConversionRates[jobStage.id].active || 0 }}
                  </div>
                </div>
                <div class="flex-table-value-wrapper">
                  <div [ngClass]="hasProcessedCandidates(jobStageConversionRates[jobStage.id]) &&
                      getGenericColorClass(
                        jobStageConversionRates[jobStage.id].converted_percent,
                        config.conversion_rates_use_green_instead_of_gray,
                        getJobStageYellowThreshold(jobStage.name, config),
                        getJobStageRedThreshold(jobStage.name, config),
                        true
                      )
                    " class="column-value" ngbTooltip="Converted percent" *ngIf="config$ | async as config">
                      {{ jobStageConversionRates[jobStage.id].converted_percent }}%
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <!-- Total active candidates -->
          <div class="flex-table-column" *ngIf="tab.show_total_active_candidates && column.value === 'total_active_candidates'">
            <div class="flex-table-header">
              Total active candidates
            </div>
            <div class="flex-table-value-wrapper">
              <div class="column-value">
                {{ getTotalActiveCandidates(jobStageConversionRates) }}
              </div>
            </div>
          </div>
        
          <!-- Candidates processed -->
          <div class="flex-table-column" *ngIf="tab.show_candidates_processed && column.value === 'candidates_processed'">
            <div class="flex-table-header">
              Candidates processed 
            </div>
            <div class="flex-table-value-wrapper">
              <div class="column-value">
                {{ getTotalProcessedCandidates(jobStageConversionRates) }}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
   
      <!-- Custom fields -->
      <ng-container *ngIf="column.type === 'custom_field' && tab.show_custom_fields">
        <div class="flex-table-column"  *ngIf="column.type === 'custom_field' && tab.show_custom_fields && (customFieldMap$ | async) as customFieldMap">
          <div class="flex-table-header max-width-overflow" [ngbTooltip]="customFieldMap.get(column.value)?.name">
            {{ customFieldMap.get(column.value)?.name }}
          </div>
          <div class="flex-table-value-wrapper flex-wrap-reverse">
            <div class="column-value" *ngFor="let value of customFieldAsArray(job.custom_fields[column.value])">
              {{ value }}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <!-- END FLEX TABLE -->
</div>
<div *ngIf="shouldShowWall">
  <twng-jobs [filters]="singleJobFilter" [showJobHeaders]="false" [manageAllJobsLoadedInState]="false">
  </twng-jobs>
</div>
<div *ngIf="shouldShowAnalytics && job$ | async as job" class="job-analytics">
  <twng-job-analytics [job]="job" [jobStages]="jobStages"
    [exportingToPdf]="exportingPdf" *ngIf="jobStages$ | async as jobStages">
  </twng-job-analytics>
</div>
<ng-template #loading>
  <twng-spinner></twng-spinner>
</ng-template>