/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'
import { CandidateInStage } from '../models/candidates-in-stage'
import { HttpErrorResponse } from '@angular/common/http'

export enum CandidatesInStageTypes {
  LoadInitialData = '[Candidates In Stage] LoadInitialData',
  LoadInitialDataSuccess = '[Candidates In Stage] LoadInitialDataSuccess',
  LoadInitialDataError = '[Candidates In Stage] LoadInitialDataError',

  FetchCandidatesInStage = '[Candidates In Stage] FetchCandidatesInStage',
  FetchCandidatesInStageSuccess = '[Candidates In Stage] FetchCandidatesInStageSuccess',
  FetchCandidatesInStageError = '[Candidates In Stage] FetchCandidatesInStageError',
}

export class LoadInitialData implements Action {
  readonly type = CandidatesInStageTypes.LoadInitialData;

  constructor() {
  }
}

export interface CandidatesInStageBackendFilters {
  office_ids: string[],
  department_ids: string[],
  user_ids: string[],
  job_stage_names: string[],
}

export interface CandidatesInStageLoadPayload {
  candidates: CandidateInStage[]
  filters: CandidatesInStageBackendFilters
}

export class LoadInitialDataSuccess implements Action {
  readonly type = CandidatesInStageTypes.LoadInitialDataSuccess;

  constructor(public payload: CandidatesInStageLoadPayload) {
  }
}

export class LoadInitialDataError implements Action {
  readonly type = CandidatesInStageTypes.LoadInitialDataError;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class FetchCandidatesInStage implements Action {
  readonly type = CandidatesInStageTypes.FetchCandidatesInStage

  constructor(public payload: { filters: CandidatesInStageBackendFilters }) {
  }
}

export class FetchCandidatesInStageSuccess implements Action {
  readonly type = CandidatesInStageTypes.FetchCandidatesInStageSuccess

  constructor(public payload: CandidatesInStageLoadPayload) {
  }
}

export class FetchCandidatesInStageError implements Action {
  readonly type = CandidatesInStageTypes.FetchCandidatesInStageError

  constructor(public payload: HttpErrorResponse) {
  }
}

export type CandidatesInStageActions =
  LoadInitialData
  | LoadInitialDataSuccess
  | LoadInitialDataError
  | FetchCandidatesInStage
  | FetchCandidatesInStageSuccess
  | FetchCandidatesInStageError
