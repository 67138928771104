import { Subscription, combineLatest } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { groupBy as _groupBy, keys as _keys, padStart as _padStart } from 'lodash-es'
import { addDays, format } from 'date-fns'

import {
  AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy,
  OnInit, SecurityContext
} from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'

import * as fromCardDetails from '../reducers'
import * as fromWall from '../../wall/reducers'
import { AppConfigService } from '../../wall/services/app-config.service'
import { Candidate } from '../../wall/models/candidate'
import { EmailTemplate } from '../../wall/models/email-template'
import { JobApplication } from '../../wall/models/job-application'
import {
  RejectCandidate, UpdateSelectedEmailTemplate, UpdateSelectedRejectionReason,
  UpdateSelectedRejectionReasonText, UpdateSendEmailAt
} from '../actions/reject-candidate-ui.actions'
import { RejectionReason } from '../../wall/models/rejection-reason'
import { SELECT_REJECTION_REASON } from '../../wall/models/app-config'
import { SegmentService } from '../../core/services/segment.service'
import { present } from '../../shared/utils/general-utils'

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-reject-candidate-form',
  styleUrls: [
    './card-detail-item.component.scss',
    './card-details-modal.component.scss',
    './reject-candidate.component.scss',
  ],
  template: `
    <div class="reject-candidate">
      <div class="section__header-wrapper">
        <h2 class="section__header m-0">
          <span class="section__icon-wrapper">
            <i class="fas fa-ban"></i>
          </span>
          <span>
             {{appConfig.labelFor('reject')}} Candidate
          </span>
        </h2>
        <button class="header__close" type="button" aria-label="Close">
          <span class="small" aria-hidden="true">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </div>

      <form class="tw-mb-3 tw-mt-2">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label class="section__item-label fs-unmask" for="rejectionReasonId">Reason</label>
            <select #rejectSelect
              class="form-select section__item-value"
              name="rejectionReasonId"
              [ngModel]="selectedRejectionReasonId"
              *ngIf="selectRejectionReason()"
              (change)="updateSelectedRejectionReasonId(rejectSelect.value)"
            >
            <option class="fs-unmask" disabled selected value="undefined">Choose one... </option>
              <optgroup
                *ngFor="let typeName of rejectionReasonTypes"
                label="{{ typeName }}"
              >
                <option
                  *ngFor="let reason of rejectionReasonsByType[typeName]"
                  [value]="reason.id"
                >
                  {{ reason.name }}
                </option>
              </optgroup>
            </select>
          </div>

          <div class="form-group col-md-6">
              <textarea
                name="rejectionReason"
                class="form-control section__item-value"
                rows="6"
                required
                *ngIf="!selectRejectionReason()"
                (keyup)="updateSelectedRejectionText($event)"
              ></textarea>
          </div>
        </div>

        <div *ngIf="showEmailForm()">
          <div class="form-group">
            <label class="section__item-label fs-unmask" for="emailTemplateId">Email Template</label>
            <select #emailTemplateSelect
              class="form-select section__item-value"
              name="emailTemplateId"
              [ngModel]="selectedEmailTemplate?.id"
              (change)="updateSelectedEmailTemplateId(emailTemplateSelect.value)"
              [required]="true"
            >
              <option
                *ngFor="let template of emailTemplates"
                [value]="template.id"
                >{{ template.name }}</option
              >
            </select>
          </div>

          <div class="form-group">
            <div class="email-preview" *ngIf="selectedEmailTemplate">
              <div class="email-meta">
                <div>
                  <span class="section__item-label !tw-mr-2 fs-unmask">From</span>
                  <span class="section__item-value">{{ selectedEmailTemplate.from }}</span>
                </div>
                <div>
                  <span class="section__item-label !tw-mr-2 fs-unmask">CC</span>
                  <span class="section__item-value ">{{ selectedEmailTemplate.cc }}</span>
                </div>
              </div>
            </div>
            <iframe class="email-body" [src]="iframeContent()"></iframe>
            <div class="email-template-caption fs-unmask">* Templates are read-only. Please login to Greenhouse to modify them.</div>
          </div>
        </div>

        <div class="form-row fs-unmask" *ngIf="showEmailForm()">
          <div class="col tw-mb-2" style="border: none;">
            <label class="section__item-label" for="emailSchedule">Send email</label>
            <select #emailScheduleSelect class="form-select section__item-value"
                    id="emailSchedule"
                    (change)="scheduleEmailChange(emailScheduleSelect.value)"
            >
              <option value="now">Now</option>
              <option value="nextDay">Tomorrow at {{ currentHour() }}</option>
              <option value="twoDays">In 2 days at {{ currentHour() }}</option>
              <option value="custom"
                >Custom date and time {{ selectedDate() }}</option
              >
            </select>
          </div>
        </div>

        <div class="form-row tw-mb-3 fs-unmask" *ngIf="showEmailForm() && showCustomDate">
          <div class="col d-flex border-left-0">
            <form class="form-inline tw-mr-3">
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" placeholder="yyyy-mm-dd"
                          name="dp"
                          [(ngModel)]="selectedDateModel"
                          [ngModelOptions]="{ standalone: true }"
                          ngbDatepicker
                          (select)="updateSendEmailAtDate($event)"
                          #rejectPicker="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="date-calendar-button btn" (click)="rejectPicker.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <select #emailTimeSelect class="form-select w-auto fs-unmask" name="timeSelect" [ngModel]="selectedTimeString()"
            (change)="updateSendEmailAtHour(emailTimeSelect.value)">
            <option *ngFor="let time of quarterHourTimes()" [value]="time.militaryTime">
              {{time.clockTime}}
            </option>
          </select>
        </div>

        <div class="actions-row form-group form-row fs-unmask">
          <button
            *ngIf="showEmailForm()"
            type="submit"
            class="btn btn-danger btn-sm tw-mr-3"
            mwlConfirmationPopover
            popoverTitle="{{appConfig.labelFor('reject')}} and Send Email"
            popoverMessage="Do you really want to {{appConfig.labelFor('reject')}} this candidate?"
            (confirm)="rejectCandidate(true)"
            [disabled]="formDisabled"
            [title]="buttonTitle"
          >
            <i class="far fa-envelope tw-mr-1 "></i> Reject and Send Email
          </button>

          <button
            *ngIf="selectRejectionReason()"
            type="submit"
            class="btn btn-danger btn-sm tw-mr-2 fs-unmask"
            mwlConfirmationPopover
            popoverTitle="{{appConfig.labelFor('reject')}} without Email"
            popoverMessage="Do you really want to {{appConfig.labelFor('reject')}} this candidate?"
            (confirm)="rejectCandidate(false)"
            [disabled]="formDisabled"
            [title]="buttonTitle"
          >
            {{ noEmailRejectText() }}
          </button>

          <button
            *ngIf="!selectRejectionReason()"
            type="submit"
            class="btn btn-danger fs-unmask"
            mwlConfirmationPopover
            popoverTitle="{{appConfig.labelFor('reject')}}"
            popoverMessage="Do you really want to {{appConfig.labelFor('reject')}} this candidate?"
            (confirm)="rejectCandidate(false)"
            [disabled]="formDisabled"
            [title]="buttonTitle"
          >
            {{ noEmailRejectText() }}
          </button>
        </div>

        <div
          *ngIf="showEmailForm() && candidateHasEmail() === false"
          class="alert alert-warning no-email-warning tw-ml-2 fs-unmask"
        >
          Candidate has no email address. Email will not be sent.
        </div>

        <div *ngIf="showNetworkError" class="tw-mt-2 alert alert-danger fs-unmask">
          Could not reach server. Please make sure you are online and try again.
        </div>
      </form>
    </div>
  `
}) // TODO create a new html file for this
export class RejectCandidateComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input()
    jobApplication: JobApplication
  @Input()
    candidate: Candidate

  constructor(
    private store: Store<fromWall.State>,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private calendar: NgbCalendar,
    public appConfig: AppConfigService,
    private segmentService: SegmentService,
    private toastr: ToastrService,

  ) {
    this.selectedDateModel = this.calendar.getToday()
  }

  rejectionReasonsSub: Subscription
  templatesAndUISub: Subscription
  uiSub: Subscription

  rejectionReasonTypes: string[]
  rejectionReasonsByType: { [key: string]: RejectionReason[] }
  emailTemplates: EmailTemplate[] = []

  showNetworkError = false
  showCustomDate = false
  selectedRejectionReasonId: string
  selectedRejectionText: string

  selectedEmailTemplate: EmailTemplate

  scheduleEmail = false
  sendEmailAt: Date
  selectedDateModel: NgbDate
  selectedTime: string
  formDisabled: boolean
  buttonTitle = "Choose a reason"

  ngOnInit(): void {
    this.rejectionReasonsSub = this.store
      .select(fromWall.selectAllRejectionReasons)
      .subscribe(reasons => {
        this.rejectionReasonsByType = this.groupByType(reasons)
        // reverse alphabetical is coincidentally the right order
        // (better than hardcoding the order in case other types are added)
        this.rejectionReasonTypes = _keys(this.rejectionReasonsByType)
          .sort()
          .reverse()
      })

    this.templatesAndUISub = combineLatest([
      this.store.select(fromWall.selectRejectionEmailTemplates),
      this.store.select(fromCardDetails.selectRejectCandidateUI),
    ]).subscribe(([templates, uiByAppId]) => {
      this.emailTemplates = templates

      const ui = uiByAppId[this.jobApplication.id]

      if (ui) {
        this.showNetworkError = ui.showNetworkError

        if (ui.selectedEmailTemplateId) {
          this.selectedEmailTemplate = templates.find(
            t => t.id === ui.selectedEmailTemplateId,
          )
        }

        if (ui.selectedRejectionReasonId) {
          this.selectedRejectionReasonId = ui.selectedRejectionReasonId
        }

        this.selectedRejectionText = ui.selectedRejectionReasonText

        if (ui.sendEmailAt) {
          this.sendEmailAt = new Date(ui.sendEmailAt)
        }

        this.cd.markForCheck()
      }

      if (!this.selectedEmailTemplate) {
        // use default template
        this.selectedEmailTemplate = templates.find(
          template => template.is_default,
        )
      }

    })
    this.formDisabled = true
  }

  ngAfterViewChecked() {
    const picker = document.getElementById('customDatePicker') as HTMLElement
    if (picker !== null) {
      picker.scrollIntoView({ behavior: 'smooth' })
    }
  }

  groupByType(rejectionReasons) {
    return _groupBy(rejectionReasons, reason => reason.type_name)
  }

  ngOnDestroy(): void {
    this.rejectionReasonsSub.unsubscribe()
    this.templatesAndUISub.unsubscribe()
  }

  updateSelectedRejectionReasonId(rejectionReasonId: string): void {
    this.store.dispatch(
      new UpdateSelectedRejectionReason({
        jobApplicationId: this.jobApplication.id,
        rejectionReasonId,
      }),
    )
    this.formDisabled = false
    this.buttonTitle = ""
  }

  updateSelectedRejectionText(event: KeyboardEvent): void {
    const textArea = event.target as HTMLInputElement
    const textAreaBody = textArea.value
    this.store.dispatch(
      new UpdateSelectedRejectionReasonText({
        jobApplicationId: this.jobApplication.id,
        rejectionReasonText: textAreaBody,
      }),
    )
  }

  updateSelectedEmailTemplateId(emailTemplateId: string): void {
    this.store.dispatch(
      new UpdateSelectedEmailTemplate({
        jobApplicationId: this.jobApplication.id,
        emailTemplateId,
      }),
    )
  }

  // the date is specified in the datepicker
  updateSendEmailAtDate(eventDate) {
    const ngbDate = eventDate as NgbDate
    const datetime = this.sendEmailAt || this.roundToQuarterOfHour(new Date())
    datetime.setFullYear(ngbDate.year, ngbDate.month - 1, ngbDate.day)
    this.store.dispatch(
      new UpdateSendEmailAt({
        jobApplicationId: this.jobApplication.id,
        sendEmailAt: format(datetime, DATE_FORMAT),
      }),
    )
  }

  // the time is specified in a select box
  updateSendEmailAtHour(time: string) {
    const [hours, minutes] = time.split(':').map(s => parseInt(s, 10))
    const datetime = this.sendEmailAt || this.roundToQuarterOfHour(new Date())
    datetime.setHours(hours)
    datetime.setMinutes(minutes)
    this.store.dispatch(
      new UpdateSendEmailAt({
        jobApplicationId: this.jobApplication.id,
        sendEmailAt: format(datetime, DATE_FORMAT),
      }),
    )
  }

  rejectCandidate(sendEmail: boolean): void {
    let params = {
      jobApplicationId: this.jobApplication.id,
      rejectionReasonId: this.selectedRejectionReasonId,
      rejectionReasonText: this.selectedRejectionText,
      emailTemplateId: null,
      sendEmailAt: null,
      candidate: this.candidate
    }
    if (sendEmail) {
      params = {
        ...params,
        emailTemplateId: this.selectedEmailTemplate.id,
      }
    }
    if (this.scheduleEmail) {
      params = {
        ...params,
        sendEmailAt: format(this.sendEmailAt, DATE_FORMAT),
      }
    }
    this.store.dispatch(new RejectCandidate(params))
    this.segmentService.track("Reject Application", { sendEmail, scheduleEmail: this.scheduleEmail })
  }

  iframeContent(): SafeResourceUrl {
    // EmailTemplate's html_body has been sanitized server-side, so it should be safe,
    // but let's sanitize it again to be sure.
    const sanitizedBody = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.selectedEmailTemplate?.html_body,
    )

    // We encode the HTML in base64. This will keep the information on one line
    // then we let the browser decode the html accordingly
    const htmlInBase64 = window.btoa(
      `<html><body style="font-family:courier;
                          font-size: 14px;
                          color: black;
        ">${sanitizedBody}</body></html>`,
    )

    // Because now we're going to bypass security to create the iframe content.
    return this.sanitizer.bypassSecurityTrustResourceUrl(`
      data:text/html;base64,${htmlInBase64}
    `)
  }

  // candidateName(): string {
  //   return `${this.candidate.first_name} ${this.candidate.last_name}`
  // }

  candidateHasEmail(): boolean {
    return present(this.candidate.email_addresses)
  }

  showEmailForm(): boolean {
    return this.appConfig.sendRejectEmail() && this.candidateHasEmail() && this.emailTemplates.length > 0
  }

  selectRejectionReason(): boolean {
    return this.appConfig.rejectionReasonType() === SELECT_REJECTION_REASON
  }

  noEmailRejectText(): string {
    if (this.appConfig.sendRejectEmail()) {
      return `${this.appConfig.labelFor('reject')} Without Email`
    } else {
      return this.appConfig.labelFor('reject')
    }
  }

  // select one of the sending options
  scheduleEmailChange(option: string) {
    switch (option) {
      case 'notSend': {
        this.scheduleEmail = false
        this.showCustomDate = false
        break
      }
      case 'now': {
        this.scheduleEmail = false
        this.showCustomDate = false
        break
      }
      case 'nextDay': {
        this.scheduleEmail = true
        this.showCustomDate = false
        const nextDay = format(addDays(this.currentDateTime(), 1), DATE_FORMAT)
        this.store.dispatch(
          new UpdateSendEmailAt({
            jobApplicationId: this.jobApplication.id,
            sendEmailAt: nextDay,
          }),
        )
        break
      }
      case 'twoDays': {
        this.scheduleEmail = true
        this.showCustomDate = false
        const inTwoDays = format(addDays(this.currentDateTime(), 2), DATE_FORMAT)
        this.store.dispatch(
          new UpdateSendEmailAt({
            jobApplicationId: this.jobApplication.id,
            sendEmailAt: inTwoDays,
          }),
        )
        break
      }
      case 'custom': {
        this.scheduleEmail = true
        this.showCustomDate = true
        const now = format(this.roundToQuarterOfHour(new Date()), DATE_FORMAT)
        this.store.dispatch(
          new UpdateSendEmailAt({
            jobApplicationId: this.jobApplication.id,
            sendEmailAt: now,
          }),
        )
        break
      }
    }
  }

  currentDateTime(): Date {
    const now = new Date()
    return this.roundToQuarterOfHour(now)
  }

  roundToQuarterOfHour(date: Date): Date {
    const minutes = Math.ceil(date.getMinutes() / 15) * 15
    date.setMinutes(minutes)
    return date
  }

  currentHour(): string {
    return format(this.currentDateTime(), 'h:mma')
  }

  selectedDate(): string {
    if (this.scheduleEmail === true && this.sendEmailAt) {
      return `: ${format(this.sendEmailAt, 'MMM dd, yyyy h:mma O')}`
    } else {
      return ''
    }
  }

  selectedTimeString(): string {
    return this.sendEmailAt ?
      format(this.sendEmailAt, 'HH:mm')
      : '09:00'
  }

  quarterHourTimes(): { militaryTime: string, clockTime: string }[] {
    const quarterHours = [0, 15, 30, 45]
    const times = []

    for (let hour = 0; hour < 24; hour++) {
      const amOrPm = hour >= 12 ? "PM" : "AM"
      for (let quarters = 0; quarters < 4; quarters++) {
        const minutes = quarterHours[quarters]
        const militaryTime = `${this.padTo2(hour)}:${this.padTo2(minutes)}`
        const clockHour = hour === 0 ? '12' : (hour > 12 ? hour - 12 : this.padTo2(hour))
        const clockTime = `${clockHour}:${this.padTo2(minutes)}${amOrPm}`
        times.push({ militaryTime, clockTime })
      }
    }

    return times
  }

  padTo2(val): string {
    return _padStart(val.toString(), 2, '0')
  }
}
