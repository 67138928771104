/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectorRef,
  Component, EventEmitter, Input, Output
} from '@angular/core'
import {
  isArray
} from 'lodash-es'

export type PossibleColumnType = "number" |
"string" |
"date" |
"info" |
"link" |
"greenhouse_link" |
"multirow-text" |
"toggle" |
"teamable_link"

export interface GenericTableSchema {
  name: string
  text: string
  value_type: PossibleColumnType
  format?: string
  is_array?: boolean
  is_public?: boolean
  tooltip?: string
}

export interface GenericTableInput {
  name: string
  schema: GenericTableSchema[]
  visible: Record<string, any>[]
  hidden: Record<string, any>[]
  data_type: string
  extra_counts: Record<string, number>
}

@Component({
  selector: 'twng-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent {
  @Input()
    data: GenericTableInput

  @Output()
    dataChanged = new EventEmitter<void>()

  @Input()
    isWarningTable = false

  constructor(private change: ChangeDetectorRef) { }

  onDataChanged(event) {
    this.data.visible = this.data.visible.map(d => {
      if (d.excluded.body === event.body) {
        d.excluded = event
        d.is_excluded.value = event.value
      }
      return d
    })

    this.change.markForCheck()
    this.dataChanged.emit()
  }

  isArray(value) {
    return isArray(value)
  }
}
