<twng-single-chart-filter-template *ngIf="dataLoaded" (applyButtonClicked)="applyFilters()" iconClass="fa-calendar"
  [showAsIcon]="!!chart" [chartFiltersReadOnly]="chartFiltersReadOnly" [tooltipText]="tooltipText" (filterClicked)="clickOpenFilters(null)">
  <ng-container title-display>
    <i class="fas fa-calendar"></i>
    <div class="value-list">
      {{ filters.start_date | date:'mediumDate' }}
    </div>
    <div class="empty-value-list" *ngIf="filters.start_date && filters.end_date">
      -
    </div>
    <div class="value-list">
      {{ filters.end_date | date:'mediumDate' }}
    </div>
    <div class="empty-value-list" *ngIf="!filters.start_date && !filters.end_date">
      Date
    </div>
  </ng-container>
</twng-single-chart-filter-template>
