/* From: https://github.com/component/textarea-caret-position */
/* jshint browser: true */

// The properties that we copy into a mirrored div.
// Note that some browsers, such as Firefox,
// do not concatenate properties, i.e. padding-top, bottom etc. -> padding,
// so we have to do every single property specifically.
const properties = [
  'direction', // RTL support
  'boxSizing',
  'width', // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
  'height',
  'overflowX',
  'overflowY', // copy the scrollbar for IE

  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
  'borderLeftWidth',
  'borderStyle',

  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',

  // https://developer.mozilla.org/en-US/docs/Web/CSS/font
  'fontStyle',
  'fontVariant',
  'fontWeight',
  'fontStretch',
  'fontSize',
  'fontSizeAdjust',
  'lineHeight',
  'fontFamily',

  'textAlign',
  'textTransform',
  'textIndent',
  'textDecoration', // might not make a difference, but better be safe

  'letterSpacing',
  'wordSpacing',

  'tabSize',
  'MozTabSize',
]

const isBrowser = typeof window !== 'undefined'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFirefox = isBrowser && (window as any).mozInnerScreenX !== null

export function getCaretCoordinates(element, position, options = null) {
  if (!isBrowser) {
    throw new Error(
      'textarea-caret-position#getCaretCoordinates should only be called in a browser',
    )
  }

  const debug = (options && options.debug) || false
  if (debug) {
    const el = document.querySelector(
      '#input-textarea-caret-position-mirror-div',
    )
    if (el) {
      el.parentNode.removeChild(el)
    }
  }

  // mirrored div
  const div = document.createElement('div')
  div.id = 'input-textarea-caret-position-mirror-div'
  document.body.appendChild(div)

  const style = div.style
  const computed = window.getComputedStyle
    ? getComputedStyle(element)
    : element.currentStyle // currentStyle for IE < 9

  // default textarea styles
  style.whiteSpace = 'pre-wrap'
  if (element.nodeName !== 'INPUT') {
    style.wordWrap = 'break-word'
  } // only for textarea-s

  // position off-screen
  style.position = 'absolute' // required to return coordinates properly
  if (!debug) {
    style.visibility = 'hidden'
  } // not 'display: none' because we want rendering

  // transfer the element's properties to the div
  properties.forEach(function(prop) {
    style[prop] = computed[prop]
  })

  if (isFirefox) {
    // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
    if (element.scrollHeight > parseInt(computed.height, 10)) {
      style.overflowY = 'scroll'
    }
  } else {
    style.overflow = 'hidden' // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
  }

  div.textContent = element.value.substring(0, position)
  // the second special handling for input type="text" vs textarea:
  // spaces need to be replaced with non-breaking spaces -
  // http://stackoverflow.com/a/13402035/1269037
  if (element.nodeName === 'INPUT') {
    div.textContent = div.textContent.replace(/\s/g, '\u00a0')
  }

  const span = document.createElement('span')
  // Wrapping must be replicated *exactly*, including when a long word gets
  // onto the next line, with whitespace at the end of the line before (#7).
  // The  *only* reliable way to do that is to copy the *entire* rest of the
  // textarea's content into the <span> created at the caret position.
  // for inputs, just '.' would be enough, but why bother?
  span.textContent = element.value.substring(position) || '.' // || because a completely empty faux span doesn't render at all
  div.appendChild(span)

  const coordinates = {
    top: span.offsetTop + parseInt(computed['borderTopWidth'], 10),
    left: span.offsetLeft + parseInt(computed['borderLeftWidth'], 10),
  }

  if (debug) {
    span.style.backgroundColor = '#aaa'
  } else {
    document.body.removeChild(div)
  }

  return coordinates
}
