/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'

import { DateFilter } from '../../shared/components/filters/date-filter/date-filter.interface'
import { EeocFilteredJobAnalytics, EeocFilters, JobAnalytics } from '../reducers/job-analytics.reducer'
import { HttpResponseBase } from '@angular/common/http'
import { Job } from '../models/job'
import { JobUserSettings } from '../models/job-user-settings'
import { atsId } from '../models/types'

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum JobActionTypes {
  FetchJobAnalytics = '[Job] FetchJobAnalytics',
  FetchJobAnalyticsSuccess = '[Job] FetchJobAnalyticsSuccess',
  FetchJobAnalyticsFailure = '[Job] FetchJobAnalyticsFailure',

  UpdateJobUserSettings = '[Job] UpdateJobUserSettings',
  UpdateJobUserSettingsSuccess = '[Job] UpdateJobUserSettingsSuccess',
  UpdateJobUserSettingsFailure = '[Job] UpdateJobUserSettingsFailure',

  UpdateEeocFilters = '[Job] UpdateEeocFilters',
  UpdateEeocFiltersForJob = '[Job] UpdateEeocFiltersForJob',
  UpdateEeocFiltersForJobSuccess = '[Job] UpdateEeocFiltersForJobSuccess',
  UpdateEeocFiltersForJobFailure = '[Job] UpdateEeocFiltersForJobFailure',
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */

export interface JobActionPayload {
  job: Job
  httpResponse?: HttpResponseBase
  oldJob?: Job
}

export interface JobsActionPayload {
  jobs: Job[]
  oldJobs: Job[]
}

export interface FetchJobAnalyticsPayload {
  job: Job,
  filters?: {
    dateFilter: DateFilter
  }
}

export interface EeocFiltersForJobPayload {
  jobId: string,
  eeocFilters: EeocFilters,
  dateFilters?: DateFilter
}

export interface EeocFiltersForJobResponsePayload {
  jobId: string,
  response: EeocFilteredJobAnalytics
}

export class FetchJobAnalytics implements Action {
  readonly type = JobActionTypes.FetchJobAnalytics
  constructor(public payload: FetchJobAnalyticsPayload) { }

  hasFilters() {
    return !!this.payload?.filters
  }
}

export interface FetchJobAnalyticsSuccessPayload {
  job_analytics: JobAnalytics,
  job_id: atsId,
}

export class FetchJobAnalyticsSuccess implements Action {
  readonly type = JobActionTypes.FetchJobAnalyticsSuccess
  constructor(public payload: FetchJobAnalyticsSuccessPayload) { }
}

export class UpdateEeocFiltersSuccess implements Action {
  readonly type = JobActionTypes.FetchJobAnalyticsSuccess
  constructor(public payload: FetchJobAnalyticsSuccessPayload) { }
}

export class FetchJobAnalyticsFailure implements Action {
  readonly type = JobActionTypes.FetchJobAnalyticsFailure
  constructor(public payload: string) { }
}

export interface UpdateJobUserSettingsPayload {
  jobId: atsId,
  jobUserSettings: JobUserSettings,
}

export class UpdateJobUserSettings implements Action {
  readonly type = JobActionTypes.UpdateJobUserSettings
  constructor(public payload: UpdateJobUserSettingsPayload) { }
}

export class UpdateJobUserSettingsSuccess implements Action {
  readonly type = JobActionTypes.UpdateJobUserSettingsSuccess
  constructor() { }
}

export class UpdateJobUserSettingsFailure implements Action {
  readonly type = JobActionTypes.UpdateJobUserSettingsFailure
  constructor() { }
}

export class UpdateEeocFilters implements Action {
  readonly type = JobActionTypes.UpdateEeocFilters
  constructor(public payload: EeocFilters) { }
}

export class UpdateEeocFiltersForJob implements Action {
  readonly type = JobActionTypes.UpdateEeocFiltersForJob
  constructor(public payload: EeocFiltersForJobPayload) { }
}

export class UpdateEeocFiltersForJobFailure implements Action {
  readonly type = JobActionTypes.UpdateEeocFiltersForJobFailure
  constructor() { }
}

export class UpdateEeocFiltersForJobSuccess implements Action {
  readonly type = JobActionTypes.UpdateEeocFiltersForJobSuccess
  constructor(public payload: EeocFiltersForJobResponsePayload) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type JobActions = FetchJobAnalytics | FetchJobAnalyticsSuccess | FetchJobAnalyticsFailure |
UpdateEeocFilters | UpdateEeocFiltersForJob | UpdateEeocFiltersForJobSuccess | UpdateEeocFiltersForJobFailure
