import { JobStageCategory } from '../models/job-stage-category'
import { StageMappingsActions, StageMappingsActionsTypes } from '../actions/stage-mappings.actions'

export interface StageMappingsState {
  loadingCategories: boolean
  creatingCategory: boolean
  updatingCategory: boolean
  deletingCategory: boolean
  jobStageNames: string[]
  jobStageCategories: JobStageCategory[]
}

const initialState: StageMappingsState = {
  loadingCategories: false,
  creatingCategory: false,
  updatingCategory: false,
  deletingCategory: false,
  jobStageNames: [],
  jobStageCategories: []
}


/**
 * Removes from categoryIdFrom and adds it to categoryIdTo
 *
 * @param state
 * @param categoryIdFrom
 * @param categoryIdTo
 * @param jobStageName
 */
function updateCategory(
  state: StageMappingsState,
  categoryIdFrom: number,
  categoryIdTo: number,
  jobStageName: string): JobStageCategory[] {
  const filteredCategories = state.jobStageCategories.filter(jobStageCategory => (
    jobStageCategory.id !== categoryIdFrom && jobStageCategory.id !== categoryIdTo)
  )

  let categoryFrom = state.jobStageCategories.find(jobStageCategory => jobStageCategory.id === categoryIdFrom)
  let categoryTo = state.jobStageCategories.find(jobStageCategory => jobStageCategory.id === categoryIdTo)

  // Remove job stage category name
  if (categoryFrom) {
    categoryFrom = {
      ...categoryFrom,
      job_stage_names: categoryFrom.job_stage_names.filter(stageName => stageName !== jobStageName)
    }
    filteredCategories.push(categoryFrom)
  }

  // Add job stage category name
  if (categoryTo) {
    categoryTo = {
      ...categoryTo,
      job_stage_names: [...categoryTo.job_stage_names, jobStageName].sort()
    }
    filteredCategories.push(categoryTo)
  }

  return filteredCategories
}

function renameCategory(
  state: StageMappingsState,
  jobStageName: string, jobStageCategoryId: number): JobStageCategory[] {

  const category = state.jobStageCategories.find(jobStageCategory => jobStageCategory.id === jobStageCategoryId)
  const updatedCategory: JobStageCategory = {
    ...category,
    name: jobStageName
  }

  const filteredCategories = state.jobStageCategories
    .filter(jobStageCategory => jobStageCategory.id !== jobStageCategoryId)
  return [
    ...filteredCategories,
    updatedCategory
  ]
}

export function reducer(
  state: StageMappingsState = initialState,
  action: StageMappingsActions
): StageMappingsState {
  switch (action.type) {

    case StageMappingsActionsTypes.LoadStageMappings: {
      return {
        ...state,
        loadingCategories: true,
      }
    }

    case StageMappingsActionsTypes.LoadStageMappingsSuccess: {
      const categories = action.payload.job_stage_categories as JobStageCategory[]
      return {
        ...state,
        loadingCategories: false,
        jobStageNames: action.payload.job_stage_names,
        jobStageCategories: categories.map((category, index) => ({
          ...category,
          position: index
        }))
      }
    }

    case StageMappingsActionsTypes.LoadStageMappingsFailed: {
      return {
        ...state,
        loadingCategories: false
      }
    }

    case StageMappingsActionsTypes.CreateCategory: {
      return {
        ...state,
        creatingCategory: true,
      }
    }

    case StageMappingsActionsTypes.CreateCategorySuccess: {
      return {
        ...state,
        creatingCategory: false,
        jobStageCategories: [...state.jobStageCategories, {
          id: action.payload.id,
          name: action.payload.name,
          job_stage_names: action.payload.job_stage_names,
          position: state.jobStageCategories.length
        }]
      }
    }

    // Remove Category
    case StageMappingsActionsTypes.RemoveCategory: {
      return {
        ...state,
        deletingCategory: true,
        jobStageCategories: state.jobStageCategories
          .filter(jobStageCategory => jobStageCategory.id !== action.payload.jobStageCategory.id)
      }
    }

    case StageMappingsActionsTypes.RemoveCategorySuccess: {
      return {
        ...state,
        deletingCategory: false,
      }
    }

    case StageMappingsActionsTypes.RemoveCategoryFailed: {
      return {
        ...state,
        deletingCategory: false,
        jobStageCategories: [...state.jobStageCategories, {
          ...action.payload.jobStageCategory,
          position: state.jobStageCategories.length
        }]
      }
    }

    // Update Category
    case StageMappingsActionsTypes.UpdateCategory: {
      const { payload } = action
      return {
        ...state,
        updatingCategory: true,
        jobStageCategories:
          updateCategory(state, payload.jobStageCategoryIdFrom, payload.jobStageCategoryIdTo, payload.jobStageName),
      }
    }

    case StageMappingsActionsTypes.UpdateCategorySuccess: {
      return {
        ...state,
        updatingCategory: false,
      }
    }

    case StageMappingsActionsTypes.UpdateCategoryFailed: {
      const { payload } = action
      return {
        ...state,
        updatingCategory: false,
        jobStageCategories:
          updateCategory(state, payload.jobStageCategoryIdTo, payload.jobStageCategoryIdFrom, payload.jobStageName),
      }
    }

    // Rename
    case StageMappingsActionsTypes.RenameJobStageNameCategory:
      return {
        ...state,
        updatingCategory: true,
        jobStageCategories:
          renameCategory(state, action.payload.jobStageCategoryName, action.payload.jobStageCategoryId),
      }

    case StageMappingsActionsTypes.RenameJobStageNameCategorySuccess: {
      return {
        ...state,
        updatingCategory: false,
      }
    }

    case StageMappingsActionsTypes.RenameJobStageNameCategoryFailed:
      return {
        ...state,
        updatingCategory: false,
        jobStageCategories:
          renameCategory(state, action.payload.jobStageCategoryOldName, action.payload.jobStageCategoryId),
      }

    case StageMappingsActionsTypes.UpdateCategoryPositions:
      return {
        ...state,
        updatingCategory: true
      }

    case StageMappingsActionsTypes.UpdateCategoryPositionsSuccess:
      return {
        ...state,
        updatingCategory: false,
        jobStageCategories: action.payload.newJobStages.map((category, index) => ({
          ...category,
          position: index
        }))
      }

    case StageMappingsActionsTypes.UpdateCategoryPositionsFailed:
      return {
        ...state,
        updatingCategory: false,
        jobStageCategories: [...state.jobStageCategories]
      }

    default:
      return state
  }
}
