import { Component, Input } from '@angular/core';
import { ShowGenericTableModal } from '../../../../core/actions/generic-table.actions'
import { Store } from '@ngrx/store'
import { WidgetDataType } from '../../../../wall/models/offer'

@Component({
  selector: 'twng-empty-dropoff-graph',
  templateUrl: './empty-dropoff-graph.component.html',
  styleUrls: ['../dropoff-graph.component.scss', './empty-dropoff-graph.component.scss']
})
export class EmptyDropoffGraphComponent {
  @Input()
  private hiredOfferIds: string[]

  @Input()
  private jobName: string

  @Input()
  private areIdsJobApplicationIds = true

  get offerCount() {
    return this.hiredOfferIds ? this.hiredOfferIds.length : 0
  }

  constructor(
    private store: Store,
  ) { }

  onHiresClicked() {
    if (this.hiredOfferIds?.length > 0) {
      this.store.dispatch(new ShowGenericTableModal({
        chart: this.areIdsJobApplicationIds ?
          WidgetDataType.CANDIDATE_WITH_OFFER :
          WidgetDataType.HIRED,
        clickedGraphTitle: this.jobName,
        clickedLabel: 'Hired Candidates',
        data_ids: this.hiredOfferIds.map(v => v.toString()),
      }))
    }
  }
}
