import { Dictionary } from '@ngrx/entity'
import { createFeatureSelector, createSelector } from '@ngrx/store'

import { Job } from '../../wall/models/job'
import { ProspectCandidate } from '../models/prospects-candidate.model'
import { ProspectJobApplication } from '../models/prospects-job-application.model'
import { ProspectsState, prospectsStateKey } from '../reducers'
import { atsId } from '../../wall/models/types'
import { selectJobEntities } from '../../wall/reducers'

export const selectProspectsState = createFeatureSelector<ProspectsState>(prospectsStateKey)

export const selectProspectsJobApplications = createSelector(
  selectProspectsState,
  (state: ProspectsState): ProspectJobApplication[] => state.jobApplications
)

export const selectProspectJobApplicationById = (jobApplicationId: atsId) => createSelector(
  selectProspectsJobApplications,
  (jobApplications: ProspectJobApplication[]): ProspectJobApplication =>
    jobApplications.find(jobApp => jobApp.id === jobApplicationId)
)

export const getProspectCandidateById = (candidateId: atsId) => createSelector(
  selectProspectsState,
  (state: ProspectsState): ProspectCandidate =>
    state.candidates.find(candidate => candidate.id === candidateId)
)

export const selectProspectJobs = createSelector(
  selectProspectsJobApplications,
  selectJobEntities,
  (jobApplications, jobEntities: Dictionary<Job>): Job[] => {
    if (Object.values(jobEntities).length === 0) {
      return []
    }
    const jobIdSet = new Set(jobApplications.map(app => app.job_id))
    const jobIds = Array.from(jobIdSet)
    return jobIds.map(jobId => jobEntities[jobId]).sort((jobA, jobB) => jobA.name.localeCompare(jobB.name))
  }
)
