<div ngbDropdown placement="left" [autoClose]="false" #menuDropDown="ngbDropdown" [container]="'body'"
  (openChange)="openChange($event)">

  <button ngbDropdownToggle class="app__dropdown-toggle" ngbTooltip="Configure" *ngIf="jobProjectedHires" [disabled]="!(canConfigureHiringPredictions$ | async)">
    <i class="fas fa-wrench"></i>
  </button>

  <div ngbDropdownMenu aria-labelledby="widgetActionsMenu" class="app__dropdown-menu tw-rounded-md">
    <section class="section--with-separator section--header tw-mb-4">
      <div>
        <div class="section__header section__header--primary">
          <span>Hiring Predictions</span>
        </div>
        <span class="section__row-label">{{ job.name }}</span>
        <div class="instructions">
          <p>
            Hiring Predictions calculates the volume of candidates that are active in each
            stage and applies your expected conversion rates. The result of this calculation
            is the expected number of hires you have in the current pipeline if you were to
            simply let it play out and not add any new candidates to the top of the funnel.
          </p>
          <p>
            Enter an estimated conversion rate for each stage:
          </p>
        </div>
      </div>
      <button class="header__close header__close--small" type="button" aria-label="Close"
        (click)="menuDropDown.close()">
        <span class="small" aria-hidden="true">
          <i class="fas fa-times"></i>
        </span>
      </button>
    </section>

    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="form">
      <section class="section--with-separator tw-mt-3">
        <twng-job-stage-prediction-input *ngFor="let js of jobStages" [parentForm]="form" [jobStage]="js"
          [jobPrediction]="jobProjectedHires.stage_projection_info[js.id]"
          [actualConversionRate]="conversionRates[js.id].converted_percent">
        </twng-job-stage-prediction-input>
      </section>

      <div class="text-danger" *ngIf="!ratesInRange()">The conversion rate values need to be <br />
        larger than 0 and less than or equal to 100</div>

      <section class="section--with-separator section--content">
        <div ngbDropdownItem>
          <div class="section__row section__row--actions">

            <span ngbTooltip="Cannot change hiring predictions in demo mode" [disableTooltip]="!demoMode">
              <button type="submit" class="button--primary button--large tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-white tw-bg-cq-primary-600 tw-text-sm tw-p-3" (click)="menuDropDown.close();"
                [disabled]="demoMode || !ratesInRange()">
                Apply
              </button>
            </span>
          </div>
        </div>
      </section>
    </form>
  </div>
</div>