/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'
import { ChartFilters } from '../../dashboard/models/chart-filters'

import { Tab } from '../models/tab'
import { WallDataPaginatedTabFilter } from './wall.actions'

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum TabActionTypes {
  CreateTab = '[Tab] CreateTab',
  CreateTabSuccess = '[Tab] CreateTabSuccess',
  CreateTabFailure = '[Tab] CreateTabFailure',
  UpdateTab = '[Tab] UpdateTab',
  UpdateTabSuccess = '[Tab] UpdateTabSuccess',
  UpdateTabFailure = '[Tab] UpdateTabFailure',
  DeleteTab = '[Tab] DeleteTab',
  DeleteTabSuccess = '[Tab] DeleteTabSuccess',
  DeleteTabFailure = '[Tab] DeleteTabFailure',
  SharedTab = '[Tab] SharedTab',
  SharedTabSuccess = '[Tab] SharedTabSuccess',
  SharedTabFailure = '[Tab] SharedTabFailure'
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */

export interface TabActionPayload {
  tab: Tab
  oldTab?: Tab
}

export interface SharedTabsActionPayload {
  type: 'wall' |'dashboard'
  user_ids: number[],
  activeTab: number,
  sharedWithUsers: string[]
  filters?: ChartFilters | WallDataPaginatedTabFilter,
  current_user_new_tab_id?: number
}


export class CreateTab implements Action {
  readonly type = TabActionTypes.CreateTab
  constructor(public payload: TabActionPayload) { }
}

export class CreateTabSuccess implements Action {
  readonly type = TabActionTypes.CreateTabSuccess
  constructor(public payload: TabActionPayload) { }
}

export class CreateTabFailure implements Action {
  readonly type = TabActionTypes.CreateTabFailure
  constructor(public payload: TabActionPayload) { }
}

export class UpdateTab implements Action {
  readonly type = TabActionTypes.UpdateTab
  constructor(public payload: TabActionPayload) { }
}

export class UpdateTabSuccess implements Action {
  readonly type = TabActionTypes.UpdateTabSuccess
  constructor(public payload: TabActionPayload) { }
}

export class UpdateTabFailure implements Action {
  readonly type = TabActionTypes.UpdateTabFailure
  constructor(public payload: TabActionPayload) { }
}

export class DeleteTab implements Action {
  readonly type = TabActionTypes.DeleteTab
  constructor(public payload: TabActionPayload) { }
}

export class DeleteTabSuccess implements Action {
  readonly type = TabActionTypes.DeleteTabSuccess
  constructor(public payload: TabActionPayload) { }
}

export class DeleteTabFailure implements Action {
  readonly type = TabActionTypes.DeleteTabFailure
  constructor(public payload: TabActionPayload) { }
}

export class SharedTab implements Action {
  readonly type = TabActionTypes.SharedTab
  constructor(public payload: SharedTabsActionPayload) { }
}

export class SharedTabSuccess implements Action {
  readonly type = TabActionTypes.SharedTabSuccess
  constructor(public payload: SharedTabsActionPayload) { }
}

export class SharedTabFailure implements Action {
  readonly type = TabActionTypes.SharedTabFailure
  constructor(public payload: SharedTabsActionPayload) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type TabActions =
  | CreateTab
  | CreateTabSuccess
  | CreateTabFailure
  | UpdateTab
  | UpdateTabSuccess
  | UpdateTabFailure
  | DeleteTab
  | DeleteTabSuccess
  | DeleteTabFailure
  | SharedTab
  | SharedTabSuccess
  | SharedTabFailure
