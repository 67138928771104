import { Options as CanvasOptions } from 'dom-to-image'
import { Injectable } from '@angular/core'
import { NotificationService, NotificationType } from '../../shared/services/notification.service'
import { PdfGlobalService } from '../../shared/services/pdf-global.service'
import { PdfService } from '../../shared/services/pdf.service'
import { first } from 'rxjs/operators'
import { format } from 'date-fns'
import { jsPDF } from 'jspdf'
import { widthOfChildren } from '../../shared/utils/general-utils'

const summarySelector = '.summary-row'
const execDashJobContainerSelector = '.exec-dash-job-container'
const jobTableSelector = '.job-table'

@Injectable({
  providedIn: 'root'
})
export class ExportExecDashToPdfService extends PdfService {
  constructor(
    private notifications: NotificationService,
    private pdfGlobal: PdfGlobalService,
  ) {
    super()
  }

  private tabName: string

  async exportExecDashboardToPDF(tabName: string) {
    this.tabName = tabName
    return this.exportToPDF('.executive-dashboard')
  }

  protected async generatePDF(): Promise<void> {
    const pdfFile = this.createPDFFile(null, { orientation: 'landscape' })
    await this.addPageElements(pdfFile)

    // Download the pdf in the browser
    await pdfFile.save(
      "Executive dashboard - " +
      (this.tabName || "Open Jobs") + " - " +
      format(new Date(), 'yyyy-MM-dd') +
      '.pdf'
    )
  }

  getAllJobs(): HTMLElement[] {
    return Array.from(document.querySelectorAll(execDashJobContainerSelector))
  }

  /**
   * Returns the width of the largest job container
   */
  getLargestJobWidth(): number {
    const jobs = this.getAllJobs()
    let maxWidth = 0
    for (const job of jobs) {
      if (job.scrollWidth > maxWidth) {
        maxWidth = job.scrollWidth
      }
    }
    return maxWidth
  }

  getLargestTableWidth(): number {
    const jobContainers = this.getAllJobContainers()
    let maxWidth = 0
    jobContainers.forEach(jobContainer => {
      const table = jobContainer.querySelector(jobTableSelector) as HTMLElement
      const tableWidth = table.scrollWidth
      if (tableWidth > maxWidth) {
        maxWidth = tableWidth
      }
    })
    return maxWidth
  }

  async addPageElements(pdf: jsPDF): Promise<void> {
    const logoSizes = this.getLogoSectionDimensions()

    // Keeps track of the current vertical position on the pdf page
    let heightCounter = logoSizes.logoReactHeight

    heightCounter += await this.addElement('.pdf-title', pdf, heightCounter, 1)
    heightCounter += await this.addElement('.job-filters', pdf, heightCounter, 0.75, undefined, true)
    heightCounter += await this.addElement('.dashboard__grid--css', pdf, heightCounter, 0.95, undefined, true)
    heightCounter = await this.addSummary(pdf)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    heightCounter += await this.addJobs(pdf, heightCounter)
  }

  /**
   * Adds an elements to the page and returns it's height on the pdf
   *
   * @param elementSelector
   * @param pdf
   * @param heightPositionCounter
   * @param factor
   * @param canvasOptions
   * @param horizontallyCenter
   * @private
   */
  private async addElement(
    elementSelector: string,
    pdf: jsPDF,
    heightPositionCounter: number,
    factor = 1,
    canvasOptions?: Partial<CanvasOptions>,
    horizontallyCenter = false,
  ): Promise<number> {

    const htmlCanvasElement = await this.generateCanvasFromHTML(elementSelector, canvasOptions)
    const elementDimensions = this.getImageDimensions(elementSelector, 'landscape')

    const positionX = horizontallyCenter ? this.getPositionXForCentering(pdf, elementDimensions, factor) : 0

    const elementWidth = elementDimensions.width * factor
    const elementHeight = elementDimensions.height * factor

    // Add the job canvas element to the pdf
    pdf.addImage(
      htmlCanvasElement,
      'PNG',
      positionX, // x position on the page
      heightPositionCounter, // y position
      elementWidth,
      elementHeight,
      '', // alias
      'FAST'
    )

    return elementHeight
  }

  private async addJobs(pdf: jsPDF, heightCounter: number): Promise<number> {
    try {
      await this.pdfGlobal.allComponentsReady$.pipe(first(Boolean)).toPromise()
      const logoSizes = this.getLogoSectionDimensions()
      heightCounter += await this.addElement('.num-visible-jobs', pdf, heightCounter, 1)

      this.addTablesWidth()
      this.setJobContainersHeight()
      this.flushStyles()
      const pageHeight = pdf.internal.pageSize.getHeight()
      const jobs = this.getAllJobs()
      const canvasOptions: Partial<CanvasOptions> = {
        /**
         * This is needed so all jobs have the same width
         * so all columns are aligned from left to right.
         */
        width: this.getLargestJobWidth(),
      }

      const scaleFactor = 1
      let finishedJobs = 0
      this.notifications.updateNotification(NotificationType.GeneratingPDF, 'Generating PDF', 0, jobs.length)
      for (const job of jobs) {
        const jobSelector = this.getJobSelector(job)

        // Image dimension with aspect ratio
        const jobImageDimensions = this.getImageDimensions(jobSelector, 'landscape')

        // If the job height doesn't fit in the current page,
        // then create a new pdf page
        if ((heightCounter + jobImageDimensions.height + (PdfService.pagePadding * 2)) > pageHeight) {
          this.addPage(pdf, null, { orientation: 'landscape' })
          heightCounter = logoSizes.logoReactHeight
        }

        heightCounter += await this.addElement(jobSelector, pdf, heightCounter, scaleFactor, canvasOptions)
        finishedJobs++
        this.notifications.updateNotification(NotificationType.GeneratingPDF, undefined, finishedJobs, jobs.length)
      }
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      // Rollback changes to not break the UI
      this.notifications.closeNotification(NotificationType.GeneratingPDF)
      this.restoreTablesWidth()
      this.restoreJobContainersHeight()
    }

    return heightCounter
  }

  private async addSummary(pdf: jsPDF): Promise<number> {
    this.makeSummaryFullWidth()
    return new Promise((resolve) => {
      requestAnimationFrame(async () => {
        this.addPage(pdf, null, { orientation: 'landscape' })
        const heightCounter =
          this.getLogoSectionDimensions().logoHeight + this.getLogoSectionDimensions().logoVerticalMargin
        const summaryEl = document.querySelector(summarySelector)
        const analyticsEl = summaryEl.querySelector(".analytics") as HTMLElement
        const width = widthOfChildren(analyticsEl) + 70
        const canvasOptions: Partial<CanvasOptions> = {
          width,
        }
        const horizontallyCenter = false
        const scaleFactor = 1
        const elementHeight =
          await this.addElement(summarySelector, pdf, heightCounter, scaleFactor, canvasOptions, horizontallyCenter)
        this.restoreSummaryWidth()
        resolve(heightCounter + elementHeight)
      })
    })
  }

  private getAllJobContainers(): NodeListOf<HTMLElement> {
    return document.querySelectorAll(`.executive-dashboard ${execDashJobContainerSelector} .job-section`) as NodeListOf<HTMLElement>
  }

  /**
   * Set all tables the same width so they all
   * match the width of the pdf page.
   * The width is picked from the table with
   * the largest width
   */
  setAllTablesTheSameWidth() {
    const jobContainers = this.getAllJobContainers()
    const largestTableWidth = this.getLargestTableWidth()
    jobContainers.forEach(jobContainer => {
      const table = jobContainer.querySelector(jobTableSelector) as HTMLElement
      table.style.width = `${largestTableWidth}px`
    })
  }

  /**
   * The job-container is not expanding after changing the width
   * of the table. Quick solution is to get the full width of all elements
   * inside the container, sum them and set it to the container.
   * After getting the real width of each table, set all tables
   * to the largest table width to fit the pdf page.
   */
  addTablesWidth() {
    const jobContainers = this.getAllJobContainers()
    jobContainers.forEach(jobContainer => {
      const table = jobContainer.querySelector(jobTableSelector) as HTMLElement
      const jobInfoHeader = jobContainer.querySelector('.job-info') as HTMLElement

      jobContainer.style.width = `${table.scrollWidth + jobInfoHeader.scrollWidth}px`
      table.style.width = `${table.scrollWidth}px`
    })
    this.setAllTablesTheSameWidth()
  }

  /**
   * Rollback changes made by method addTablesWidth
   *
   * @private
   */
  private restoreTablesWidth() {
    const jobContainers = this.getAllJobContainers()
    jobContainers.forEach(jobContainer => {
      const table = jobContainer.querySelector(jobTableSelector) as HTMLElement
      jobContainer.style.width = '100%'
      table.style.width = '100%'
    })
  }

  private makeSummaryFullWidth() {
    const summaryEl = document.querySelector(summarySelector).querySelector(".stages") as HTMLElement
    summaryEl.style.overflowX = 'initial'
  }

  private restoreSummaryWidth() {
    const summaryEl = document.querySelector(summarySelector).querySelector(".stages") as HTMLElement
    summaryEl.style.overflowX = null
  }

  private setJobContainersHeight() {
    const jobs = this.getAllJobs()
    for (const job of jobs) {
      const jobSelector = this.getJobSelector(job)
      const jobEl = document.querySelector(jobSelector) as HTMLElement
      jobEl.style.height = `${jobEl.scrollHeight}px`
      const jobContainerEl = jobEl
        .querySelector('.job-container') as HTMLElement || jobEl.querySelector('.analytics-wrap')
      if (jobContainerEl) {
        jobContainerEl.style.height = `${jobContainerEl.scrollHeight}px`
      }
    }
  }

  private restoreJobContainersHeight() {
    const jobs = this.getAllJobs()
    for (const job of jobs) {
      const jobSelector = this.getJobSelector(job)
      const jobEl = document.querySelector(jobSelector) as HTMLElement
      jobEl.style.height = 'auto'
      const jobContainerEl = jobEl
        .querySelector('.job-container') as HTMLElement || jobEl.querySelector('.analytics-wrap')
      if (jobContainerEl) {
        jobContainerEl.style.height = 'auto'
      }
    }
  }

  getJobSelector(job: HTMLElement): string {
    return `[id='${job.id}']`
  }

}
