import { AfterViewInit, Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FeatureKey } from '../reducers/track-features.reducer';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TrackFeature } from '../actions/track-features.actions';
import { selectIsFeatureTracked } from '../selectors/track-features.selectors';
import { tap } from 'rxjs/operators';

@Directive({
  selector: '[trackFeatureHighlight]',
  exportAs: 'trackFeatureHighlight'
})
export class TrackFeatureHighlightDirective implements OnInit, AfterViewInit, OnDestroy {

  isFeatureTracked = false
  isTrackedSub: Subscription

  @Input()
    trackFeatureHighlight: FeatureKey

  @HostListener('click')
  trackFeature() {
    this.store.dispatch(new TrackFeature({
      featureKey: this.trackFeatureHighlight,
    }))
  }

  constructor(private store: Store,
    private ngbTooltip: NgbTooltip) {
  }

  ngOnInit() {
    this.isTrackedSub =
      this.store.select(selectIsFeatureTracked(this.trackFeatureHighlight)).pipe(
        tap((isTracked: boolean) => this.isFeatureTracked = isTracked),
        tap(() => this.setupTooltip()),
      ).subscribe()
  }

  ngAfterViewInit() {
    this.setupTooltip()
  }

  ngOnDestroy() {
    this.isTrackedSub.unsubscribe()
  }

  configureTooltip() {
    this.ngbTooltip.disableTooltip = true
    this.ngbTooltip.tooltipClass = 'twng-feature-highlight' // css class
    this.ngbTooltip.autoClose = false
  }

  showTooltip() {
    this.ngbTooltip.disableTooltip = false
    this.ngbTooltip.open()
  }

  hideTooltip() {
    this.ngbTooltip.close()
    this.ngbTooltip.disableTooltip = true
  }

  setupTooltip() {
    this.configureTooltip();
    if (!this.ngbTooltip) {
      return;
    }
    if (this.isFeatureTracked) {
      this.hideTooltip();
    } else {
      this.showTooltip()
    }
  }

}
