import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'

import {
  DateFilter, DateModeFilter
} from '../../../shared/components/filters/date-filter/date-filter.interface'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-analytics-date-range-filters',
  templateUrl: './analytics-date-range-filters.component.html',
  styleUrls: ['./analytics-date-range-filters.component.scss']
})
export class AnalyticsDateRangeFiltersComponent implements OnInit {

  dateFilterForm = new UntypedFormGroup({
    dateMode: new UntypedFormControl('allTime'),
    startDate: new UntypedFormControl(''),
    endDate: new UntypedFormControl(''),
  });

  demoMode = !!window.twng_demo
  demoDisabledTooltip = window.twng_demo ? "Filtering job analytics is unavailable in demo mode" : ""

  @Output()
    dateRangeChanged = new EventEmitter<DateFilter>()

  @Input()
    initialDateFilters: DateFilter

  @Input()
    showActive = false

  @Input()
    disabled = false

  constructor(private ngbDateParserFormatter: NgbDateParserFormatter, private toastr: ToastrService) { }

  ngOnInit() {
    if (this.initialDateFilters) {
      this.dateFilterForm.setValue({
        dateMode: this.initialDateFilters.dateMode,
        startDate: this.ngbDateParserFormatter.parse(this.initialDateFilters.startDate),
        endDate: this.ngbDateParserFormatter.parse(this.initialDateFilters.endDate),
      }, { emitEvent: false })
    }
  }

  applyFilter(dateModeFilter: DateModeFilter) {
    if (this.disabled) {
      this.toastr.warning("Date filters are read-only")
      return
    }
    this.dateFilterForm.controls.dateMode.setValue(dateModeFilter)
    this.dateRangeChanged.emit({
      dateMode: dateModeFilter,
      startDate: this.startDate,
      endDate: this.endDate
    })
  }

  get dateMode(): DateModeFilter {
    return this.dateFilterForm.value.dateMode
  }

  get startDate(): string {
    return this.ngbDateParserFormatter.format(this.dateFilterForm.value.startDate) || new Date().toString()
  }

  get endDate(): string {
    return this.ngbDateParserFormatter.format(this.dateFilterForm.value.endDate) || new Date().toString()
  }
}
