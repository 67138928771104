import { EMPTY, Observable, of } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { Action } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { EeocFilteredJobAnalytics, JobAnalytics, isAnythingInEeocSet } from '../reducers/job-analytics.reducer'
import {
  FetchJobAnalytics,
  FetchJobAnalyticsFailure,
  FetchJobAnalyticsSuccess,
  JobActionTypes,
  UpdateEeocFiltersForJob,
  UpdateEeocFiltersForJobFailure,
  UpdateEeocFiltersForJobSuccess
} from '../actions/jobs.actions'
import { apiHost, getHttpPostOptions, httpGetOptions } from '../../core/http-options'

@Injectable()
export class JobAnalyticsEffects {

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private toastr: ToastrService,
  ) { }

  fetchJobAnalytics$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<FetchJobAnalytics>(JobActionTypes.FetchJobAnalytics),
    map(action => action.payload),
    mergeMap(({ job, filters }) => {
      if (window.twng_demo) {
        return EMPTY
      }

      let dateFilters = (filters || {}).dateFilter
      if (dateFilters?.dateMode === 'allTime') {
        dateFilters = null
      }

      const transformedFilters = {
        date_mode: dateFilters?.dateMode,
        start_date: dateFilters?.startDate,
        end_date: dateFilters?.endDate,
      }

      const params = {
        date_filters: transformedFilters
      }
      return this.http
        .post<JobAnalytics>(apiHost + '/twng/jobs/' + job.id + '/analytics.json',
        params,
        getHttpPostOptions(),
      ).pipe(
        map(
          job_analytics => new FetchJobAnalyticsSuccess({ job_analytics, job_id: job.id }),
        ),
        catchError(() => of(new FetchJobAnalyticsFailure("failed"))),
      )
    })
  ))

  fetchEeocJobAnalytics$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateEeocFiltersForJob>(JobActionTypes.UpdateEeocFiltersForJob),
    map(action => action.payload),
    mergeMap(({ eeocFilters, jobId, dateFilters }) => {
      if (!isAnythingInEeocSet(eeocFilters)) {
        return EMPTY
      }
      if (dateFilters?.dateMode === 'allTime') {
        dateFilters = null
      }

      const transformedFilters = {
        date_mode: dateFilters?.dateMode,
        start_date: dateFilters?.startDate,
        end_date: dateFilters?.endDate,
      }

      // remove empty arrays from object values
      const removedEmptyFilters =
        Object.fromEntries(Object.entries((eeocFilters || {})).filter(([_, value]) => value.length !== 0))

      if (!window.twng_demo) {
        return this.http
          .post<EeocFilteredJobAnalytics>(apiHost + '/twng/jobs/' + jobId + '/eeoc_analytics.json',
          {
            eeoc_filters: removedEmptyFilters,
            date_filters: transformedFilters
          },
          getHttpPostOptions(),
        ).pipe(
          map(response => new UpdateEeocFiltersForJobSuccess({ jobId, response })),
          catchError(() => of(new UpdateEeocFiltersForJobFailure())),
        )
      } else {
        return this.http
          .get<EeocFilteredJobAnalytics>(apiHost + '/twng/jobs/' + jobId + '/eeoc_analytics.json',
          httpGetOptions,
        ).pipe(
          map(response => new UpdateEeocFiltersForJobSuccess({ jobId, response })),
          catchError(() => of(new UpdateEeocFiltersForJobFailure())),
        )
      }
    }),
  ))
}
