import { Subscription } from 'rxjs'

import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output
} from '@angular/core'

import { JobStageCategory } from '../../models/job-stage-category'
import {
  RenameJobStageCategoryNamePayload, UpdateCategoryPositionPayload, UpdateJobStageCategoryPayload
} from '../../actions/stage-mappings.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent {

  @Input()
    category: JobStageCategory

  @Output()
    updateCategory = new EventEmitter<UpdateJobStageCategoryPayload>()

  @Output()
    removeCategory = new EventEmitter<JobStageCategory>()

  @Output()
    renameCategory = new EventEmitter<RenameJobStageCategoryNamePayload>()

  @Output()
    updateCategoryPosition = new EventEmitter<UpdateCategoryPositionPayload>()

  @Output()
    commitUpdatingCategoryPositions = new EventEmitter()

  editingName = false

  subscriptions = new Subscription()

  constructor() {
  }

  updateStageCategory(event) {
    if (event.jobStageCategoryId !== this.category.id && event.jobStageName) {
      this.updateCategory.emit({
        jobStageName: event.jobStageName,
        jobStageCategoryIdTo: this.category.id,
        jobStageCategoryIdFrom: event.jobStageCategoryId
      })
    }
  }

  emitRemoveCategory() {
    this.removeCategory.emit(this.category)
  }

  editName() {
    this.editingName = true
  }

  setCategoryName(jobStageCategoryName: string) {
    this.editingName = false
    if (jobStageCategoryName && jobStageCategoryName.length > 0) {
      this.renameCategory.emit({
        jobStageCategoryOldName: this.category.name,
        jobStageCategoryName,
        jobStageCategoryId: this.category.id
      })
    }
  }
}
