/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from "@ngrx/store"

export enum ApiActionTypes {
  CallApi = "[Api] CallApi",
  SetApiStatus = "[Api] SetApiStatus",
}

export enum ApiStatus {
  Sent = "Sent",
  Canceled = "Canceled",
  Success = "Success",
  Failed = "Fail",
  // This happens if this api was ignored due to "ignoreIf" property
  Ignored = "Ignored",
}

// Can we send api request again for the given current status
export function isOverwritableApiStatus(apiStatus: ApiStatus): boolean {
  return apiStatus === ApiStatus.Failed || apiStatus === ApiStatus.Canceled
    || apiStatus === null || apiStatus === undefined
}

interface ActionWithParams extends Action {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any
}

export type ApiMatcher = string | RegExp | (string | RegExp)[]

interface CallApiPayloadNoID {
  startAction: ActionWithParams
  successAction: string
  failureAction: string
  cancelAction?: string
  apiName: string
  // ignore this api call if this call is active
  ignoreIf?: ApiMatcher
  // cancel these requests when this api starts
  cancel?: ApiMatcher
  // if this is true, this api call will always happen
  doAlways?: boolean
}

interface CallApiPayload extends CallApiPayloadNoID {
  id: number
}

interface SetApiStatusPayload {
  apiName: string
  newApiStatus: ApiStatus
  id: number
}

let ID = 0

export function getNextFetchId() {
  return ID
}

export class CallApi implements Action {
  type = ApiActionTypes.CallApi
  payload: CallApiPayload
  constructor(payload: CallApiPayloadNoID) {
    this.payload = {
      ...payload,
      id: ID++,
    }
  }
}

export class SetApiStatus implements Action {
  type = ApiActionTypes.SetApiStatus
  constructor(public payload: SetApiStatusPayload) { }
}

export type ApiActions = CallApi | SetApiStatus
