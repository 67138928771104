import { BooleanCustomField, CustomField, CustomFieldProps, MultiSelectCustomField,
  SingleSelectCustomField, UserCustomField
} from '../models/custom-field'
import { CustomFieldsState, customFieldsFeatureKey } from '../reducers'
import { createFeatureSelector, createSelector } from '@ngrx/store'

export const selectCustomFieldsState = createFeatureSelector<CustomFieldsState>(customFieldsFeatureKey)

export const selectCustomFieldsProps = createSelector(
  selectCustomFieldsState,
  state => state.customFields,
)

export const selectUnexcludedCustomFieldsProps = createSelector(
  selectCustomFieldsState,
  state => state.customFields.filter(customField => !customField.excluded_from_filters),
)

export const selectCandidateCustomFieldsProps = createSelector(
  selectCustomFieldsState,
  state => state.candidateCustomFields,
)

export const selectOfferCustomFieldsProps = createSelector(
  selectCustomFieldsState,
  state => state.offerCustomFields,
)

export const selectUnexcludedFilterOptions = createSelector(
  selectCustomFieldsState,
  state => state.filterOptions.filter(cfo => !cfo.excludedFromFilters),
)

export const selectFilterOptions = createSelector(
  selectCustomFieldsState,
  state => state.filterOptions
)

export const selectCandidateFilterOptions = createSelector(
  selectCustomFieldsState,
  state => state.candidateFilterOptions
)

export const selectOfferFilterOptions = createSelector(
  selectCustomFieldsState,
  state => state.offerFilterOptions
)

function customFieldMapper(customFieldsProps: CustomFieldProps[]): CustomField[] {
  return customFieldsProps
    .map((customField: CustomFieldProps) => {
      switch (customField.value_type) {
        case SingleSelectCustomField.TYPE:
          return new SingleSelectCustomField(customField)
        case UserCustomField.TYPE:
          return new UserCustomField(customField)
        case MultiSelectCustomField.TYPE:
          return new MultiSelectCustomField(customField)
        case BooleanCustomField.TYPE:
          return new BooleanCustomField(customField)
        default: {
          console.log('Unable to match customField props to class: ', customField)
          return null
        }
      }
    })
    .filter(customField => !!customField)
}

export const getUnexcludedCustomFields = createSelector(
  selectUnexcludedCustomFieldsProps,
  customFieldMapper
)

export const getCustomFields = createSelector(
  selectCustomFieldsProps,
  customFieldMapper
)

export const getCandidateCustomFields = createSelector(
  selectCandidateCustomFieldsProps,
  customFieldMapper
)

export const getOfferCustomFields = createSelector(
  selectOfferCustomFieldsProps,
  customFieldMapper
)

export const getCustomFieldsAsMap = createSelector(
  getCustomFields,
  (customFields: CustomField[]) => {
    const customFieldsMap = new Map<string, CustomField>()
    customFields.forEach(cf => customFieldsMap.set(cf.id, cf))
    return customFieldsMap
  }
)
