<div class="d-flex">
  <ng-container *ngIf="jobLastStatusUpdate$ | async as jobLastStatusUpdate">
    <div *ngIf="jobLastStatusUpdate?.status && isOpen(job) && canViewJobStatus" twng-job-status-color
      [status]="jobLastStatusUpdate?.status" [enabled]="isOpen(job) && canViewJobStatus" class="job-status-bar tw-w-4 tw-m-2 tw-mr-0 tw-rounded-full"
      [ngbTooltip]="(jobStatusLabels$ | async)[jobLastStatusUpdate?.status]">
    </div>
  </ng-container>
  <div class="job-info-header" [class.on-wall]="wallStyling">
    <div class="job-info-container">
      <span class="job-name tw-text-2xl tw-font-medium tw-mr-2 tw-!mb-0" [class.job-name-wall]="wallStyling" [ngbTooltip]="job.name">{{ job.name }}</span>

      <i *ngIf="job.confidential" class="job-confidential far fa-eye-slash" ngbTooltip="Confidential (read-only)"></i>

      <ng-container *ngIf="!showTagsInBottom">
        <ng-container *ngTemplateOutlet="tags;context:tagsCtx"></ng-container>
      </ng-container>

      <span class="categories">
        <span class="departments">
          <span *ngFor="let department of departments$ | async " class="department">
            {{ department.name }}
          </span>
        </span>

        <span *ngIf="present(job.department_ids) && present(job.office_ids)">
          |
        </span>

        <span class="offices">
          <span *ngFor="let office of offices$ | async" class="office">
            {{ office.name }}
          </span>
        </span>
      </span>
      <ng-container *ngIf="showTagsInBottom">
        <ng-container *ngTemplateOutlet="tags;context:tagsCtx"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #tags>
  <div class="tags-container">
    <span *ngIf="job.job_openings?.length" class="job-tag num-openings tw-py-1 tw-px-2 tw-rounded-full tw-font-medium tw-text-sm h-7 tw-mr-2"
      [ngbTooltip]="openingsTooltipTemplate">&times;{{job.job_openings.length}}</span>

    <span *ngIf="job.requisition_id" ngbTooltip="Requisition ID"
      class="job-tag tw-py-1 tw-px-2 tw-rounded-full tw-font-medium tw-text-sm tw-h-7 tw-mr-2">Req: {{job.requisition_id}}</span>

    <span *ngIf="!job.requisition_id || showJobId" ngbTooltip="Job ID"
      class="job-tag tw-py-1 tw-px-2 tw-rounded-full tw-font-medium tw-text-sm tw-h-7 tw-mr-2">Job: {{job.id}}</span>

    <span *ngIf="job.opened_at" ngbTooltip="{{ job.opened_at | date }}" class="job-tag tw-py-1 tw-px-2 tw-rounded-full tw-font-medium tw-text-sm tw-h-7 tw-mr-2">Open
      {{ openDays }} days</span>

    <span *ngIf="job.priority" class="job-tag tw-py-1 tw-px-2 tw-rounded-full tw-font-medium tw-text-sm tw-h-7 tw-mr-2" ngbTooltip="Job priority">{{ job.priority }}</span>

    <ng-container *ngIf="userEntities$ | async as userEntities">
      <ng-container *ngIf="showHiringManagers">
        <span class="job-tag tw-py-1 tw-px-2 tw-rounded-full tw-font-medium tw-text-sm tw-h-7 tw-mr-2"
          *ngFor="let userId of job.hiring_manager_ids" ngbTooltip="Job hiring manager">
          {{ userEntities[userId]?.name }}
        </span>
      </ng-container>
      <span *ngIf="job.primary_recruiter_id"
        class="job-tag tw-py-1 tw-px-2 tw-rounded-full tw-font-medium tw-text-sm tw-h-7 tw-mr-2"
        ngbTooltip="Job primary recruiter">
        {{ userEntities[job.primary_recruiter_id]?.name }}
      </span>
    </ng-container>
  </div>
</ng-template>

<ng-template #openingsTooltipTemplate>
  <div class="d-flex flex-column">
    <span *ngFor="let line of openingsTooltip.split('\n')">
      {{ line }}
    </span>
    <span style="width: 1px; height: 1rem" *ngIf="openingsTooltip.length > 0"></span>
    <span>{{this.job.job_openings.length}} {{this.openingsString}}</span>
  </div>
</ng-template>