/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from "@ngrx/store"
import { Job } from "../models/job"
import { ProjectedHiresResponse } from "../models/projected-hires"

export enum ProjectedHiresActionTypes {
  FetchAllProjectedHires = "[ProjectedHires] FetchAllProjectedHires",
  FetchAllProjectedHiresSuccess = "[ProjectedHires] FetchAllProjectedHiresSuccess",
  FetchAllProjectedHiresFailure = "[ProjectedHires] FetchAllProjectedHiresFailure",
  UpdateHiringPredictions = "[ProjectedHires] UpdateHiringPredictions",
  UpdateHiringPredictionsSuccess = "[ProjectedHires] UpdateHiringPredictionsSuccess",
  UpdateHiringPredictionsFailure = "[ProjectedHires] UpdateHiringPredictionsFailure",
}

export interface UpdateHiringPredictionPayload {
  job_stage_id: string
  value: number
}

export class FetchAllProjectedHires implements Action {
  readonly type = ProjectedHiresActionTypes.FetchAllProjectedHires
}

export class FetchAllProjectedHiresSuccess implements Action {
  readonly type = ProjectedHiresActionTypes.FetchAllProjectedHiresSuccess
  constructor(public payload: ProjectedHiresResponse) { }
}

export class FetchAllProjectedHiresFailure implements Action {
  readonly type = ProjectedHiresActionTypes.FetchAllProjectedHiresFailure
}

export class UpdateHiringPredictions implements Action {
  readonly type = ProjectedHiresActionTypes.UpdateHiringPredictions
  constructor(public payload: { job: Job, hiringPredictions: UpdateHiringPredictionPayload[] }) { }
}

export class UpdateHiringPredictionsSuccess implements Action {
  readonly type = ProjectedHiresActionTypes.UpdateHiringPredictionsSuccess
  constructor(public payload: ProjectedHiresResponse) { }
}

export class UpdateHiringPredictionsFailure implements Action {
  readonly type = ProjectedHiresActionTypes.UpdateHiringPredictionsFailure
}

export type ProjectedHiresActions =
  UpdateHiringPredictions | UpdateHiringPredictionsSuccess | UpdateHiringPredictionsFailure |
  FetchAllProjectedHires | FetchAllProjectedHiresSuccess | FetchAllProjectedHiresFailure
