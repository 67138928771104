export enum EeocGenders {
  "Male" = 0,
  "Female" = 1,
  "Decline To Self Identify" = 2,
}

export enum EeocRaces {
  'American Indian or Alaskan Native' = 0,
  'Asian' = 1,
  'Black or African American' = 2,
  'Hispanic or Latino' = 3,
  'White' = 4,
  'Native Hawaiian or Other Pacific Islander' = 5,
  'Two or More Races' = 6,
  'Decline To Self Identify' = 7,
}

export enum VeteranOptions {
  'I am not a protected veteran' = 0,
  'I identify as one or more of the classifications of a protected veteran' = 1,
  "I don't wish to answer" = 2,
}

export enum Disability {
  "Yes, I have a disability (or previously had a disability)" = 0,
  "No, I don't have a disability" = 1,
  "I don't wish to answer" = 2,
}
