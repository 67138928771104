import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { Office } from '../models/office'
import { sortByName } from './sort-comparers'

export type State = EntityState<Office>

export const adapter: EntityAdapter<Office> = createEntityAdapter<Office>({
  sortComparer: sortByName,
})

export const initialState: State = adapter.getInitialState()

export function reducer(state = initialState, action: LoaderActions): State {
  switch (action.type) {
    case LoaderActionTypes.LoadSharedDataSuccess:
      return adapter.setAll(action.payload.offices, state)

    default:
      return state
  }
}
