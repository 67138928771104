import { Attachment } from '../../wall/models/attachment'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { apiHost } from '../../core/http-options'

@Component({
  selector: 'twng-attachment-icon',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="attachment-wrapper tw-text-cq-primary-600">
      <i class="fas fa-fw fa-external-link-alt"></i>
      <a
        [href]="url()"
        target="_blank"
        rel="noopener noreferrer"
        ngbTooltip="View document">{{ attachment.filename }}
      </a>
    </div>
  `,
  styles: [
    `
    i {
      color: #767676;
      font-size: 1rem;
      margin-right: 0.5rem;
      vertical-align: middle;
    }

    a {
      position: relative;
      top: 1px;
      vertical-align: middle;
    }

    .attachment-wrapper {
      margin-top: 1.25rem;
      padding-top: 1rem;
      border-top: 1px solid #e6e6e6;
    }
  `],
})
export class AttachmentIconComponent {
  @Input()
    googlePreview: boolean

  @Input()
    attachment: Attachment

  constructor() { }

  url(): string | null {
    if (this.attachment) {
      if (this.googlePreview) {
        return `${apiHost}/twng/wall/attachment?url=${encodeURIComponent(this.attachment.url)}`
      }
      return this.attachment.url
    }
  }
}
