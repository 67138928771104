import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { SocialMediaLink } from '../../wall/models/social-media-link'

@Component({
  selector: 'twng-social-media-icon',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <a class='icon fs-exclude tw-text-cq-primary-600'
      href="{{ link.url }}" ngbTooltip="{{ link.title }}" target="_blank" rel="noopener noreferrer">
      <i class="fa-fw {{ link.icon_css_classes }}"></i>
    </a>`,
  styles: [
    `
      i {
        font-size: 1.1rem;
        margin-right: 0.25rem;
      }
    `,
  ],
})
export class SocialMediaIconComponent {
  @Input()
    link: SocialMediaLink
}
