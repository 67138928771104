<div class="name fs-unmask">
  <form class="form" *ngIf="(editingCustomStatBoxValue$| async)" [formGroup]="customStatBoxForm"
    (ngSubmit)="onSubmit()">
    <input class="form-input" maxlength="50" aria-label="Enter to Save" type="text" autocomplete="off"
      #inputCustomStatValue formControlName="customValue" (blur)="onSubmit()" [ngbPopover]="enterToSavePopover"
      popoverClass="min-width--auto" [autoClose]="false" placement="bottom auto" triggers="focus" />
  </form>
</div>

<ng-template #enterToSavePopover>
  <div class="kb-wrapper fs-unmask">
    <span class="kb-shortcut">Enter</span>
    <span class="kb-text">to save</span>
  </div>
</ng-template>
