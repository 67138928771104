import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'

import { ActivityDayCategories } from '../../wall/models/activity-day-categories';
import { AppConfigService } from '../../wall/services/app-config.service';
import { CandidatesInStageBackendFilters } from '../actions/candidates-in-stage.actions'
import { CandidatesInStagePdfService } from '../services/candidates-in-stage-pdf.service';
import { CandidatesInStageService } from '../services/candidates-in-stage.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { FullCandidateInStage } from '../models/candidates-in-stage';
import { SegmentService } from '../../core/services/segment.service';
import { Store } from '@ngrx/store'
import { State as WallState, selectActivityDayCategories } from '../../wall/reducers';
import { compareStringDates } from '../../shared/utils/sorting/dates-utils';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-candidates-in-stage',
  templateUrl: './candidates-in-stage.component.html',
  styleUrls: ['./candidates-in-stage.component.scss']
})
export class CandidatesInStageComponent implements OnInit {

  datesComparator = compareStringDates

  tableId = 'candidates-in-stage-table'

  isLoadingInitialData$: Observable<boolean>

  isFetchingCandidates$: Observable<boolean>

  candidates$: Observable<FullCandidateInStage[]>

  activityDayCategories$: Observable<ActivityDayCategories>

  exporting$: Observable<boolean>

  viewInTooltipText: string
  greenHouseImgLink: string

  constructor(private candidatesInStageService: CandidatesInStageService,
    private store: Store<WallState>,
    public appConfig: AppConfigService,
    private candidatesInStagePdfService: CandidatesInStagePdfService,
    private segmentService: SegmentService) {
  }

  ngOnInit() {
    this.viewInTooltipText = `View in ${this.appConfig.atsName()}`
    this.greenHouseImgLink = this.appConfig.atsIconUrl()
    this.candidatesInStageService.loadInitialData()
    this.isLoadingInitialData$ = this.candidatesInStageService.isLoadingInitialData()
    this.isFetchingCandidates$ = this.candidatesInStageService.isFetchingCandidates()
    this.activityDayCategories$ = this.store.select(selectActivityDayCategories).pipe(shareReplay(1))
    this.exporting$ = this.candidatesInStagePdfService.exporting$
    this.candidates$ = this.candidatesInStageService.getCandidates().pipe(
      map(candidates => candidates.map(candidate => ({
        ...candidate,
        role: candidate.job_name,
        recruiter_name: candidate.recruiter?.name,
        coordinatorName: candidate.coordinator?.name,
        firstDepartment: candidate.departments[0]?.name,
        firstOffice: candidate.offices[0]?.name
      })))
    )
    this.segmentService.track('View Candidates in Stage')
  }

  filtersChanged(filters: CandidatesInStageBackendFilters) {
    this.candidatesInStageService.fetchCandidatesInStage(filters)
  }

  async exportToPdf() {
    await this.candidatesInStagePdfService.exportToPDF(`#${this.tableId}`)
  }

  trackByCandidate(_, candidate: FullCandidateInStage) {
    // We track by an immutable array, since no other property makes sense
    return candidate.office_ids
  }

}
