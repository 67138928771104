import { atsId } from './types'

export class JobStatus {
  Green: string
  Yellow: string
  Red: string
  'On Hold': string
  'No Status': string
}

export enum JobStatusColor {
  Green = 'Green',
  Yellow = 'Yellow',
  Red = 'Red',
  OnHold = 'On Hold',
  NoStatus = 'No Status',
}

export class JobStatusUpdate {
  id: atsId
  user_id: atsId
  user_name: string
  job_id: atsId
  note: string
  status: JobStatusColor | null
  created_at: string
}

