/* eslint-disable space-before-function-paren */
/* eslint-disable @typescript-eslint/no-use-before-define */
import * as computedStyleToInlineStyle from 'computed-style-to-inline-style'
import { ToastrService } from 'ngx-toastr'
import { svgAsDataUri } from 'save-svg-as-png'
import domtoimage from 'dom-to-image'
import mergeImages from 'merge-images'

import { Injectable } from '@angular/core'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Clipboard { write(items: any[]): any }
}

declare const ClipboardItem: new (arg0: { [x: string]: Blob }) => unknown

const conversionOptions = {
  /*
    Setting this to empty should supress CORS CSS warnings,
    coming from Google font stylesheets declared in `index.html`.
    Warnings still happening- leaving comment here for further testing
    https://github.com/exupero/saveSvgAsPng/issues/169#issuecomment-389356451
  */
  fonts: [],

  // Accept values 0 to 1. Indicates image quality. Defaults to 0.8
  encoderOptions: 0.5
}

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {

  /*
    Uses new Clipboard API to copy chart image to clipboard

    Compatibility:
      Clipboard API / write
        Only supported for pages served over HTTPS
        currently only writes image in PNG format
        https://developers.google.com/web/updates/2019/07/image-support-for-async-clipboard
        https://stackoverflow.com/questions/57278923/chrome-76-copy-content-to-clipboard-using-navigator

        Chrome 76: full support, no flags needed
        Firefox: partial
        Internet Explorer, Edge, Safari: none

      Dom-to-image:  https://github.com/tsayen/dom-to-image#browsers
        Firefox : problem with some external stylesheets
        IE: none

      svgAsDataUri:
        Chrome: limits data URIs to 2MB
        IE: Only works if canvg is passed in, otherwise it will throw a
            SecurityError when calling toDataURL on a canvas that's been written to.
            canvg may have it's own issues with SVG support, so make sure to test the output.

    These steps are all client-side, in memory, without saving an intemediary file to disk:
      1. Remove tooltips from SVG element inside the NGX DOM container
      2. Legend loads out of view, under the chart via z-index + abs CSS positioning
      3. Generate the header DOM element to a Base 64 Uri via dom-to-image module
      4. Generate the chart SVG element to a Base 64 Uri via svgAsDataUri module
      5. Generate the legend DOM element to a Base 64 Uri via dom-to-image module, if enabled via param
      5. Generate the filters DOM element to a Base 64 Uri via dom-to-image module, if enabled via param
      6. Merge the Base 64 Uri's via merge-images module
      7. Convert the merged Base 64 Uri to a Png blob
      8. Pass Png blob to the Clipboard API
  */
  copyChartToClipboard(chartEl: Element, showLegend = false, showFilters = false) {
    interface HeaderElement {
      el: HTMLElement
      height: number
      width: number
      dataUri: string,
      originalPosition: string,
      originalLeft: string
    }

    interface LegendElement {
      el: HTMLElement
      height: number
      width: number
      dataUri: string,
      originalPosition: string,
      originalLeft: string
    }

    interface ChartSvgElement {
      el: SVGElement
      height: number
      width: number
      dataUri: string
    }

    interface FiltersElement {
      el: HTMLElement
      height: number
      width: number
      dataUri: string,
      originalPosition: string,
      originalLeft: string
    }

    const header = {} as HeaderElement
    const chartSvg = {} as ChartSvgElement
    const legend = {} as LegendElement
    const filters = {} as FiltersElement

    // make sure we don't try to copy filters if there aren't any (e.g. for Active Pipeline charts)
    if (!getFilters()) {
      showFilters = false
    }

    function prepareChartSVG() {
      chartSvg.el = chartEl.getElementsByClassName('ngx-charts')[0] as SVGElement
      chartSvg.height = chartSvg.el.scrollHeight
      chartSvg.width = chartSvg.el.scrollWidth

      /*
        For some reason, setting borders in CSS displays in the browser
        but doesn't stick when creating the blob image for export. The
        `box-shadow` with inset technique works, but needs different values
        for display in the browser vs the export

        Avoid a bunch of CSS complexity by creating a clone of the SVG
        without attaching it to the DOM, add necessary styling, and
        send this to the export. This leaves what is displayed to user untouched.
      */
      const chartSvgClone = chartSvg.el.cloneNode(true) as SVGElement
      chartSvg.el = chartSvgClone
      chartSvg.el.style.boxShadow = "0 0 0 1px #ccc inset" // all sides
    }

    function prepareHeader() {
      // use a similar cloning technique for the header + legend
      const originalHeader = chartEl.getElementsByClassName('header')[0] as HTMLElement

      computedStyleToInlineStyle(originalHeader, {
        recursive: true,
        properties: ["font-size", "width", "height"]
      })

      const headerClone = originalHeader.cloneNode(true) as HTMLElement
      header.height = originalHeader.scrollHeight
      header.width = originalHeader.scrollWidth

      // remove the clipboard button and popover
      const headerActionsEl = headerClone.getElementsByClassName('header-actions')[0] as HTMLElement
      headerActionsEl.remove()

      /*
        Bug with library, cloned node element needs to be written to the
        DOM before allowing it to be saved to image.
        https://github.com/tsayen/dom-to-image/issues/236
      */
      headerClone.id = "headerExportClone"

      /*
        Position it off screen so the export is invisible to user
        Remove cloned element in the image export callback
        https://github.com/tsayen/dom-to-image/issues/36#issuecomment-379593426
      */
      header.originalPosition = headerClone.style.position
      header.originalLeft = headerClone.style.left
      headerClone.style.position = 'absolute'
      headerClone.style.left = '-8000px'

      document.body.appendChild(headerClone)
      header.el = document.getElementById('headerExportClone')
    }

    function prepareLegend() {
      const originalLegend = chartEl.getElementsByClassName('chart-legend')[0] as HTMLElement

      computedStyleToInlineStyle(originalLegend, {
        recursive: true,
        properties: ["width", "height", "background-color", "position", "left"]
      })

      const legendClone = originalLegend.cloneNode(true) as HTMLElement
      legend.height = originalLegend.scrollHeight
      legend.width = originalLegend.scrollWidth

      // remove the legend title and background
      const legendTitleEl = legendClone.getElementsByClassName('legend-title')[0] as HTMLElement
      legendTitleEl.remove()

      /*
        bug with library, cloned node element needs to be written to the
        DOM before allowing it to be saved to image.
        https://github.com/tsayen/dom-to-image/issues/236
      */
      legendClone.id = "legendExportClone"
      legendClone.style.padding = "0.75rem 0 0.75rem 0.5rem"
      legendClone.style.boxShadow = "inset 0 -1px 0 0 #ccc" // bottom border

      /*
        Position it off screen so the export is invisible to user
        Remove cloned element in the image export callback
        https://github.com/tsayen/dom-to-image/issues/36#issuecomment-379593426
      */
      legend.originalPosition = legendClone.style.position
      legend.originalLeft = legendClone.style.left
      legendClone.style.position = 'absolute'
      legendClone.style.left = '-8000px'

      document.body.appendChild(legendClone)
      legend.el = document.getElementById('legendExportClone')
    }

    function getFilters() {
      return chartEl.getElementsByClassName('filters')[0] as HTMLElement
    }

    function prepareFilters() {
      const originalFilters = getFilters()

      computedStyleToInlineStyle(originalFilters, {
        recursive: true,
        properties: ["font-size", "width", "height"]
      })

      const filtersClone = originalFilters.cloneNode(true) as HTMLElement
      filters.height = originalFilters.scrollHeight
      filters.width = originalFilters.scrollWidth

      /*
        Bug with library, cloned node element needs to be written to the
        DOM before allowing it to be saved to image.
        https://github.com/tsayen/dom-to-image/issues/236
      */
      filtersClone.id = "filtersExportClone"

      /*
        Position it off screen so the export is invisible to user
        Remove cloned element in the image export callback
        https://github.com/tsayen/dom-to-image/issues/36#issuecomment-379593426
      */
      filters.originalPosition = filtersClone.style.position
      filters.originalLeft = filtersClone.style.left
      filtersClone.style.position = 'absolute'
      filtersClone.style.left = '-8000px'

      document.body.appendChild(filtersClone)
      filters.el = document.getElementById('filtersExportClone')
    }

    //////////////
    prepareHeader()
    prepareChartSVG()
    if (showLegend) {
      prepareLegend()
    }
    if (showFilters) {
      prepareFilters()
    }
    cleanTooltips(chartSvg)

    ///////////
    const promises = [
      makeHeaderPngUri(header),
      makeChartPngUri(chartSvg),
    ]
    if (showLegend) {
      promises.push(makeLegendPngUri(legend))
    }
    if (showFilters) {
      promises.push(makeFiltersPngUri(filters))
    }

    let resolveCopyToClipboard; let rejectCopyToClipboard
    const copyingPromise = new Promise<void>((resolve, reject) => {
      resolveCopyToClipboard = resolve
      rejectCopyToClipboard = reject
    })

    // these can run concurrently, not dependent on each other
    Promise.all(promises)
      .then(function () {
        let imageHeight = header.height + chartSvg.height
        const imageBlobsToMerge = [
          // set the positioning so it's header -> chart -> legend -> filters, top to bottom
          { src: header.dataUri, x: 0, y: 0 },
          { src: chartSvg.dataUri, x: 0, y: header.height }
        ]

        if (showLegend) {
          imageHeight += legend.height
          imageBlobsToMerge.push({
            src: legend.dataUri,
            x: 0,
            y: header.height + chartSvg.height
          })
        }

        if (showFilters) {
          imageBlobsToMerge.push({ src: filters.dataUri, x: 0, y: imageHeight })
          imageHeight += filters.height
        }

        // merge in memory instead of saving to DOM or disk
        mergeImages(
          imageBlobsToMerge,
          {
            // no need to declare width, uses widest image
            height: imageHeight,
            quality: 0.9
          }
        )
          .then(function (dataUri) {
            // use arrow function so `this` is passed down for toastr access
            const copy = async (dataUri: string) => {
              const blob = await dataURItoBlob(dataUri)
              await navigator.clipboard.write([
                new ClipboardItem({
                  /*
                    The ClipboardItem takes an object with the MIME type of the
                    image as the key, and the actual blob as the value.
                    The sample code below shows a flexible way to do this
                    by using the new dynamic property keys syntax.
                    The MIME type used as the key is retrieved from blob.type.
                    This approach ensures that your code will be ready for future
                    image types as well as other MIME types that may be
                    supported in the future.
                    More: https://web.dev/image-support-for-async-clipboard/
                  */
                  [blob.type]: blob
                })
              ])

              this.toastr.success('Copied image to clipboard')
              resolveCopyToClipboard()
            }

            copy(dataUri)
              .catch(function handleError(err) {
                this.toastr.info('Failed to copy image to clipboard :(. Experimental: Chrome version 76+ only')
                console.log(err.message)
                rejectCopyToClipboard()
              }.bind(this))
          }.bind(this))

          .catch(function (error) {
            this.toastr.info('Failed to copy image to clipboard :(. Experimental: Chrome version 76+ only')
            console.error('Merging the 3 base 64 images failed.', error)
            rejectCopyToClipboard()
          }.bind(this))
      }.bind(this))

      .catch(function (error) {
        this.toastr.info('Failed to copy image to clipboard :(. Experimental: Chrome version 76+ only')
        console.error('Generating the 3 base64 images via Promise.all failed.', error)
        rejectCopyToClipboard()
      }.bind(this))

    /*
      NGX tooltips insert non-standard syntax into the SVG DOM,
      whether or not tooltips are disabled in the NGX chart options.

      svgAsPngUri conversion expects a squeaky clean SVG, so to get around
      this let's remove the tooltip attributes.
      This shouldn't affect tooltip behavior in the chart after copying

      Open issues:
      https://github.com/swimlane/ngx-charts/issues/1250
      https://github.com/exupero/saveSvgAsPng/issues/199
    */
    function cleanTooltips(chartSvg: ChartSvgElement) {
      const elArray = chartSvg.el.querySelectorAll('[ngx-tooltip]')
      elArray.forEach(function (element) {
        element.removeAttribute("ng-reflect-tooltip-title")
      })
    }

    function makeHeaderPngUri(header) {
      return domtoimage.toPng(header.el, {
        style: {
          // Revert the off-screen positioning
          position: header.originalPosition,
          left: header.originalLeft
        }
      })
        .then(dataUri => {
          header.dataUri = dataUri
          document.body.removeChild(header.el)
        })
        .catch(function (error) {
          console.error('domtoimage.toPng() in makeHeaderPngUri() failed', error)
        })
    }

    function makeLegendPngUri(legend: LegendElement) {
      return domtoimage.toPng(legend.el, {
        style: {
          // Revert the off-screen positioning
          position: legend.originalPosition,
          left: legend.originalLeft
        }
      })
        .then(dataUri => {
          legend.dataUri = dataUri
          document.body.removeChild(legend.el)
        })
        .catch(function (error) {
          console.error('domtoimage.toPng() in makeLegendPngUri () failed', error)
        })
    }

    function makeChartPngUri(chartSvg: ChartSvgElement) {
      return svgAsDataUri(chartSvg.el, conversionOptions)
        .then(dataUri => {
          chartSvg.dataUri = dataUri
        })
        .catch(function (error) {
          console.error('svgAsDataUri() in makeChartPngUri() failed', error)
        })
    }

    function makeFiltersPngUri(filters: FiltersElement) {
      return domtoimage.toPng(filters.el, {
        style: {
          // Revert the off-screen positioning
          position: filters.originalPosition,
          left: filters.originalLeft
        }
      })
        .then(dataUri => {
          filters.dataUri = dataUri
          document.body.removeChild(filters.el)
        })
        .catch(function (error) {
          console.error('domtoimage.toPng() in makeFiltersPngUri() failed', error)
        })
    }

    // From: https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
    function dataURItoBlob(val: string) {
      // Convert base64 to raw binary data held in a string
      // Doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      const byteString = atob(val.split(',')[1])

      // Separate out the mime component
      const mimeString = val.split(',')[0].split(':')[1].split(';')[0]

      // Write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ab], { type: mimeString })
    }

    return copyingPromise
  }

  constructor(
    private toastr: ToastrService
  ) { }
}
