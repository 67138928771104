import { Observable, Subscription, combineLatest } from 'rxjs'

import {
  AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy,
  OnInit
} from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'

import * as fromWall from '../../wall/reducers'
import { Account } from '../../wall/models/account'
import { ActivityDayCategories } from '../../wall/models/activity-day-categories'
import { Candidate } from '../../wall/models/candidate'
import { ExternalUser } from '../../wall/models/external-user'
import { FetchCardDetails } from '../../wall/actions/card-details.actions'
import { FetchNotes } from '../../wall/actions/notes.actions'
import { InterviewLoaded, InterviewWithJobStage } from '../../wall/models/interview'
import { Job } from '../../wall/models/job'
import { JobApplication } from '../../wall/models/job-application'
import { Office } from '../../wall/models/office'
import { User } from '../../wall/models/user'
import { selectAccount, selectUser } from '../../reducers'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <twng-card-details-modal
      [jobApplication]="jobApplication"
      [candidate]="candidate"
      [activityDayCategories]="activityDayCategories"
      [recruiter]="recruiter"
      [coordinator]="coordinator"
      [creditedTo]="creditedTo"
      [jobApplication]="jobApplication"
      [job]="job"
      [jobOffices]="jobOffices"
      [jobHiringManagers]="jobHiringManagers"
      [interviews]="interviewsLoaded"
      [user]="user$ | async"
      [account]="account$ | async"
      [otherApplications]="otherApplications"
      [showTags]="showTags"
    ></twng-card-details-modal>
  `,
})
export class CardDetailsModalContainerComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input()
    jobApplication: JobApplication
  @Input()
    candidate: Candidate
  @Input()
    activityDayCategories: ActivityDayCategories
  @Input()
    interviews: InterviewWithJobStage[]
  @Input()
    showTags = true

  activityLevel$: Observable<string>
  account$: Observable<Account>
  user$: Observable<User>

  job: Job
  jobOffices: Office[]
  interviewsLoaded: InterviewLoaded[]
  otherApplications: JobApplication[]
  jobHiringManagers: ExternalUser[]

  coordinator: ExternalUser
  recruiter: ExternalUser
  creditedTo: ExternalUser

  jobAndOfficesSub: Subscription
  externalUserSub: Subscription
  interviewsSub: Subscription
  otherApplicationsSub: Subscription

  constructor(
    private store: Store<fromWall.State>,
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.externalUserSub = this.store
      .select(fromWall.selectExternalUserEntities)
      .subscribe(exteralUserEntities => {
        this.coordinator = exteralUserEntities[this.candidate.coordinator_id]
        this.recruiter = exteralUserEntities[this.candidate.recruiter_id]
        this.creditedTo = exteralUserEntities[this.jobApplication.credited_to_external_user_id]
      })

    this.account$ = this.store.select(selectAccount)
    this.user$ = this.store.select(selectUser)

    this.otherApplicationsSub = combineLatest([
      this.store.select(fromWall.selectActiveJobApplications),
      this.store.select(fromWall.selectJobEntities),
      this.store.select(fromWall.selectJobStageEntities),
    ]).subscribe(([applications, jobEntities, jobStageEntities]) => {
      this.otherApplications = applications
        .filter(application =>
          application.candidate_id === this.candidate.id &&
          application.id !== this.jobApplication.id &&
          jobEntities[application.job_id] && !jobEntities[application.job_id].confidential
        ).map(application => ({
          ...application,
          job: jobEntities[application.job_id],
          stage: jobStageEntities[application.job_stage_id],
        }))
    })

    this.jobAndOfficesSub = combineLatest([
      this.store.select(fromWall.selectJobEntities),
      this.store.select(fromWall.selectOfficeEntities),
      this.store.select(fromWall.selectExternalUserEntities),
    ]).subscribe(([jobEntities, officeEntities, externalUserEntities]) => {
      this.job = jobEntities[this.jobApplication.job_id]
      this.jobOffices = this.job.office_ids.map(
        office_id => officeEntities[office_id],
      ).filter(o => o)
      this.jobHiringManagers = this.job.hiring_manager_ids.map(
        hiring_manager_id => externalUserEntities[hiring_manager_id],
      ).filter(u => u)
    })

    this.interviewsSub = combineLatest([
      this.store.select(fromWall.selectScorecardEntities),
      this.store.select(fromWall.selectExternalUserEntities),
    ]).subscribe(
      ([scorecardEntities, externalUserEntities]) => {
        this.interviewsLoaded = (this.interviews || []).map(interview => {
          const interviewers = (interview.interviewers || []).map(interviewer => ({
            ...interviewer,
            external_user: externalUserEntities[interviewer.external_user_id],
            scorecard: scorecardEntities[interviewer.scorecard_id],
          }))
          return {
            ...interview,
            interviewers,
          }
        })
        this.cd.markForCheck()
      },
    )
  }

  ngAfterContentInit() {
    // this.cd.detach();
    // these cause lag on big accounts
    setTimeout(() => {
      this.store.dispatch(new FetchCardDetails({ jobApplication: this.jobApplication }))
      this.store.dispatch(new FetchNotes({ candidate: this.candidate }))
    }, 50)
    // But even this no-op causes the same lag! WTF! Must be something with events trigger change detection...?
    // this.store.dispatch(new FetchNotesFailure(null))
  }

  ngOnDestroy() {
    if (this.externalUserSub !== undefined) {
      this.externalUserSub.unsubscribe()
      this.jobAndOfficesSub.unsubscribe()
      this.interviewsSub.unsubscribe()
      this.otherApplicationsSub.unsubscribe()
    }
  }
}
