import { DisplayGrid, GridsterConfig,
  GridsterItem, GridsterItemComponentInterface
} from 'angular-gridster2'


import { EditModeOptions } from './edit-mode-options.interface'
import { TWGridsterItemComponentInterface } from './gridster-item'

export type GridsterItemCallbackChange = (item: GridsterItem) => void

export class Grid {

  private onItemChangeCallbacks: Array<GridsterItemCallbackChange> = []

  options: GridsterConfig = {
    itemChangeCallback: (_item: GridsterItem, _itemComponent: GridsterItemComponentInterface): void => {
    },
    itemResizeCallback: (_item: GridsterItem, _itemComponent: GridsterItemComponentInterface): void => {
    },

    /*
      Config 1a:

      Charts resize with viewport width.

      When narrow viewport, like tablet, the items get small
      minItemArea, defaultItem sizes, and min/max are relative,
      making hard to keep charts the right size.

      Requires a CSS height on the Gridster element, there's a
      scrolling issue: need to scroll when hovered over an item.

      This might be related to the declared CSS height,
      but without it, the grid height doesn't expand vertically.

      gridType: 'scrollVertical',
      compactType: 'compactUp&Left',
    */

    /*
      Config 2:
      Same as 1, but but can't expand horizontally
      Same scrolling issue
    */
    gridType: 'scrollVertical',

    // use same number so widgets can't be dragged to the right of the viewport
    minCols: 24,
    maxCols: 24,

    /*
      Config 3:

      Turning on fixed sizes leaves empty space to the right when
      tab is resized, until browser refresh.

      This needs no container height in CSS to work.
      If a height is set, there's an issue where items
      that get dragged down go out of the container.

      Items can be constrained absolutely.

      Charts don't resize with viewport width changes.
      gridType: 'scrollVertical',
      setGridSize: true,
      fixedRowHeight: 64,
      minCols: 24,
      maxCols: 24,
      minRows: 15,
      minItemArea: 9,
    */

    /* Config 4
      Keeps everything in one viewport. No scrolling issues
      but charts resize without control.
      gridType: 'fit',
    */

    /////////////////////////////////////////////
    // Safe settings for all configs
    defaultItemCols: 6,
    defaultItemRows: 8,
    minItemArea: 6,
    displayGrid: 'onDrag&Resize',

    swap: false,
    pushItems: true,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: { north: true, east: false, south: true, west: false },

    pushResizeItems: true,
    margin: 16,
    draggable: {
      enabled: true,
      // doesnt seem to do anything with this config
      disableScrollHorizontal: true
    },
    resizable: {
      enabled: true
    },
    mobileBreakpoint: 1024,

    ...this.disabledOptions,
  };

  constructor() {
    this.options.itemChangeCallback = this.itemChange
    this.options.itemResizeCallback = this.itemResize
  }

  onItemChange(callback: GridsterItemCallbackChange) {
    this.onItemChangeCallbacks.push(callback)
  }

  itemChange = (item: GridsterItem, _): void => {
    this.onItemChangeCallbacks.forEach((callback: GridsterItemCallbackChange) => callback(item))
  }

  private getItemSize(itemComponent: TWGridsterItemComponentInterface): { width: number, height: number } {
    const grid = itemComponent.gridster
    if (grid.mobile) {
      return {
        width: itemComponent.el.clientWidth,
        height: itemComponent.el.clientHeight,
      }
    }
    return {
      width: itemComponent.width,
      height: itemComponent.height,
    }
  }

  itemResize = (item: GridsterItem, itemComponent: TWGridsterItemComponentInterface): void => {
    // TODO: get the height size from the header on runtime
    // 64 is the height of the header
    const headerHeight = 64 // 4rem
    const filterHeight = 60 // 3.75rem

    const itemSize = this.getItemSize(itemComponent)

    const chartHeight = itemSize.height - headerHeight - filterHeight

    const paddingPercent = 0.075
    const verticalPadding = chartHeight * paddingPercent
    const horizontalPadding = itemSize.width * paddingPercent

    const calculatedHeight = chartHeight - verticalPadding
    const calculatedWidth = itemSize.width - horizontalPadding

    itemComponent.parent?.setView([calculatedWidth, calculatedHeight], [itemSize.width, chartHeight ])
  }

  get enabledOption(): EditModeOptions {
    return {
      displayGrid: DisplayGrid.Always,
      draggable: {
        enabled: true
      },
      resizable: {
        enabled: true
      },
    }
  }

  get disabledOptions(): EditModeOptions {
    return {
      displayGrid: DisplayGrid.None,
      draggable: {
        enabled: false
      },
      resizable: {
        enabled: false
      },
    }
  }

  disableEditMode() {
    this.updateOptions(this.disabledOptions)
  }

  enableEditMode() {
    this.updateOptions(this.enabledOption)
  }

  updateOptions(options: Partial<GridsterConfig>) {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options = { ...this.options, ...options }
      this.options.api.optionsChanged()
    }
  }
}
