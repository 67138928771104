import { ConversionRatesRecord } from './job-stage-stats.reducer'
import { JobActionTypes, JobActions } from '../actions/jobs.actions'
import { LoaderActionTypes, LoaderActions } from '../../core/actions/loader.actions'
import { atsId } from '../models/types'
import { some } from 'lodash-es'

type jobStageId = atsId
type StagesAnalytics = Record<jobStageId, { value: number }>

export interface DropoffConversionRates extends ConversionRatesRecord {
  dropped_off: number
  dropoff_percent: number
  active_app_ids: number[]
  passed_through_app_ids: number[]
  rejected_app_ids: number[]
  hired_app_ids: number[]
}

export type DropoffAnalytics = Record<jobStageId, DropoffConversionRates>

export interface CountAndOffers {
  count: number,
  offer_ids: number[]
}

// key is job stage id, value is dropoff analytics for that stage
export type EeocFilteredDropoffAnalytics = Record<atsId, DropoffConversionRates>

export interface JobAnalytics {
  total_active: { count: number, job_application_ids: atsId[] },
  total_applications_processed: { count: number, job_application_ids: atsId[] },
  total_offers: CountAndOffers,
  total_hires: CountAndOffers,
  declined_offers: CountAndOffers,

  time_interviewing: StagesAnalytics,
  candidates_interviewed: StagesAnalytics,
  dropoff_rates: DropoffAnalytics,

  interview_hourly_cost: number,
  projected_hires: number,
}
export interface EeocFilteredJobAnalytics {
  dropoff_rates: EeocFilteredDropoffAnalytics
}
// key is job id
export type AllJobAnalytics = Record<atsId, JobAnalytics>

// key is job id
export type AllEeocFilteredJobAnalytics = Record<atsId, EeocFilteredJobAnalytics>
export interface EeocFilters {
  genders: number[]
  races: number[]
  disabilities: number[]
  veterans: number[]
}

export interface State {
  jobAnalytics: AllJobAnalytics
  eeocFilters: EeocFilters
  eeocFilteredJobAnalytics: AllEeocFilteredJobAnalytics
}

const initialState: State = {
  jobAnalytics: {},
  eeocFilters: {
    genders: [],
    races: [],
    disabilities: [],
    veterans: []
  },
  eeocFilteredJobAnalytics: {},
}


export function isAnythingInEeocSet(filters: EeocFilters) {
  return filters && some(
    Object.values(filters),
    array => array?.length > 0
  )
}

export function reducer(
  state: State = initialState,
  action: JobActions | LoaderActions,
): State {
  switch (action.type) {
    case LoaderActionTypes.WallSharedSuccess: {
      const { job_analytics } = action.payload
      if (!job_analytics) {
        return state
      }
      return { ...state, jobAnalytics: job_analytics }
    }

    case JobActionTypes.FetchJobAnalytics: {
      const { job } = action.payload

      return {
        ...state,
        jobAnalytics: {
          ...state.jobAnalytics,
          [`${job.id}`]: null,
        }
      }
    }

    case JobActionTypes.FetchJobAnalyticsSuccess: {
      const { job_analytics, job_id } = action.payload
      return {
        ...state,
        jobAnalytics: {
          ...state.jobAnalytics,
          [`${job_id}`]: job_analytics,
        }
      }
    }

    case JobActionTypes.UpdateEeocFilters: {
      let analytics = state.eeocFilteredJobAnalytics
      if (!isAnythingInEeocSet(action.payload)) {
        analytics = {}
      }
      return {
        ...state,
        eeocFilters: action.payload,
        eeocFilteredJobAnalytics: analytics
      }
    }

    case JobActionTypes.UpdateEeocFiltersForJobSuccess: {
      return {
        ...state,
        eeocFilteredJobAnalytics: { ...state.eeocFilteredJobAnalytics, [action.payload.jobId]: action.payload.response }
      }
    }

    default: {
      return state
    }
  }
}
