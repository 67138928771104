import { ConfirmationPopoverModule } from 'angular-confirmation-popover'
import { GridsterModule } from 'angular-gridster2'

import { ClipboardModule } from 'ngx-clipboard'
import { NgModule } from '@angular/core'
import { NgxChartsModule } from '@swimlane/ngx-charts'

import { CandidatesInStageModule } from "../../candidates-in-stage/candidates-in-stage.module";
import { ChartDropDownMenuComponent } from './chart-drop-down-menu/chart-drop-down-menu.component'
import { ChartFiltersComponent } from './chart-filters/chart-filters.component'
import { ChartFiltersModalComponent } from './chart-filters/chart-filters-modal/chart-filters-modal.component'
import { ChartLabelComponent } from './chart-label/chart-label.component'
import { ChartWrapperComponent } from './chart-wrapper/chart-wrapper.component'
import { ComboChartComponent } from './combo-chart.component'
import { CustomDashboardComponent } from './custom-dashboard.component'
import {
  CustomFieldSingleChartFilterComponent
} from './chart-filters/single-chart-filter/custom-fields-single-chart-filter.component'
import { CustomFieldsModule } from '../../custom-fields/custom-fields.module'
import { DashBarChartComponent } from './bar-chart.component'
import { DashLineChartComponent } from './line-chart.component'
import { DashPieChartComponent } from './pie-chart.component'
import { DashStackedBarChartComponent } from './stacked-bar-chart.component'
import { DashboardComponent } from './dashboard.component'
import { DashboardFormComponent } from './dashboard-form/dashboard-form.component'
import {
  DateChartFilterComponent
} from './chart-filters/single-chart-filter/date-chart-filter/date-chart-filter.component'
import {
  DateChartFilterFormComponent
} from './chart-filters/single-chart-filter/date-chart-filter/date-chart-filter-form/date-chart-filter-form.component'
import {
  DepartmentSingleChartFilterComponent
} from './chart-filters/single-chart-filter/department-single-chart-filter.component'
import { DescriptionComponent } from './description.component'
import {
  FilterSearchTypeaheadComponent
} from './chart-filters/single-chart-filter/filter-search-typeahead/filter-search-typeahead.component'
import {
  FreeTextCustomBoxInputComponent
} from './free-text-custom-box-input/free-text-custom-box-input.component'
import { GptComponent } from './gpt/gpt.component'
import { GridChartComponent } from './grid-chart.component'
import {
  GridsterItemOverlayComponent
} from './gridster/gridster-item-overlay/gridster-item-overlay.component'
import { GridsterItemWrapperComponent } from './gridster/gridster-item-wrapper.component'
import { JobSingleChartFilterComponent } from './chart-filters/single-chart-filter/job-single-chart-filter.component'
import {
  OfficeSingleChartFilterComponent
} from './chart-filters/single-chart-filter/office-single-chart-filter.component'
import { ProgressChartComponent } from './progress-chart.component'
import { SharedCustomDashboardComponent } from './shared-custom-dashboard/shared-custom-dashboard.component'
import { SharedModule } from '../../shared/shared.module'
import {
  SingleChartFilterTemplateComponent
} from './chart-filters/single-chart-filter/single-chart-filter-template/single-chart-filter-template.component'
import { SortChartDirective } from '../directives/sort-chart.directive'
import { SortChartMenuComponent } from './sort-chart-menu/sort-chart-menu.component'
import {
  SourceSingleChartFilterComponent
} from './chart-filters/single-chart-filter/source-single-chart-filter.component'
import {
  StatCustomBoxInputComponent
} from './stat-custom-box-input/stat-custom-box-input.component'
import { StatsComponent } from './stats.component'
import { TableChartComponent } from './table-chart/table-chart.component'
import { UserSingleChartFilterComponent } from './chart-filters/single-chart-filter/user-single-chart-filter.component'
import { WidgetLibraryTabHeaderComponent } from './widget-library-tab-header.component'

export const COMPONENTS = [
  ChartDropDownMenuComponent,
  ChartFiltersComponent,
  ChartFiltersModalComponent,
  ChartLabelComponent,
  ChartWrapperComponent,
  ComboChartComponent,
  CustomDashboardComponent,
  CustomFieldSingleChartFilterComponent,
  DashBarChartComponent,
  DashboardComponent,
  DashboardFormComponent,
  DashLineChartComponent,
  DashPieChartComponent,
  DashStackedBarChartComponent,
  DateChartFilterComponent,
  DateChartFilterFormComponent,
  DepartmentSingleChartFilterComponent,
  DescriptionComponent,
  FilterSearchTypeaheadComponent,
  FreeTextCustomBoxInputComponent,
  GptComponent,
  GridChartComponent,
  GridsterItemOverlayComponent,
  GridsterItemWrapperComponent,
  JobSingleChartFilterComponent,
  OfficeSingleChartFilterComponent,
  ProgressChartComponent,
  SharedCustomDashboardComponent,
  SingleChartFilterTemplateComponent,
  SortChartDirective,
  SortChartMenuComponent,
  SourceSingleChartFilterComponent,
  StatCustomBoxInputComponent,
  StatsComponent,
  UserSingleChartFilterComponent,
  WidgetLibraryTabHeaderComponent,
  TableChartComponent
]

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    SharedModule,
    // TODO: remove NgxChartsModule for lighter build in production
    NgxChartsModule,
    // ChartCommonModule,
    // PieChartModule,
    // BarChartModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger',
      cancelButtonType: 'secondary',
    }),
    GridsterModule,
    CustomFieldsModule,
    ClipboardModule,
    CandidatesInStageModule
  ]
})
export class ComponentsModule { }
