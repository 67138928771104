import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ScheduleNewReportModalComponent } from './schedule-new-report-modal/schedule-new-report-modal.component'
import { ScheduledReport } from './scheduled-report.model'
import { ScheduledReportService } from './scheduled-report.service'
import { Subscription, timer } from 'rxjs'

@Component({
  selector: 'twng-scheduled-reports-page',
  templateUrl: './scheduled-reports-page.component.html',
  styleUrls: ['./scheduled-reports-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduledReportsPageComponent implements OnInit, OnDestroy {
  data: ScheduledReport[]
  private sub = new Subscription()

  constructor(
    private scheduledReports: ScheduledReportService,
    private cd: ChangeDetectorRef,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.refreshReports()
    this.sub.add(timer(60000, 60000).subscribe(() => {
      this.refreshReports()
    }))
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  private async refreshReports() {
    this.data = await this.scheduledReports.getReports()
    this.cd.markForCheck()
  }

  async openNewReportModal() {
    try {
      const newRow = await this.modal.open(ScheduleNewReportModalComponent).result
      this.data.push(newRow)
      this.cd.markForCheck()
    } catch(err) {}
  }

  deleteReport(id: number) {
    this.data.splice(this.data.findIndex(report => report.id === id), 1)
    this.cd.markForCheck()
  }

  updateReport(report: ScheduledReport) {
    this.data[this.data.findIndex(rep => rep.id === report.id)] = report
    this.cd.markForCheck()
  }
}
