import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { Action } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { DemoService } from '../../wall/services/demo.service'
import {
  TransferCandidate, TransferCandidateFailure, TransferCandidateSuccess,
  TransferCandidateUIActionTypes
} from '../actions/transfer-candidate-ui.actions'
import { apiHost, getHttpPostOptions } from '../../core/http-options'

@Injectable()
export class TransferCandidateEffects {
  transferCandidate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<TransferCandidate>(TransferCandidateUIActionTypes.TransferCandidate),
    map(action => action.payload),
    mergeMap(({ jobApplicationId, jobStageId, jobId }) => {
      if (window.twng_demo) {
        const requestData = this.demoService.successfulResponse()
        return of(new TransferCandidateSuccess({ requestData, jobApplicationId }))
      }

      return this.http
        .post(
          apiHost + `/twng/job_applications/${jobApplicationId}/transfer`,
          {
            target_job_stage_id: jobStageId,
            target_job_id: jobId,
          },
          getHttpPostOptions(),
        )
        .pipe(
          map(
            requestData =>
              new TransferCandidateSuccess({
                requestData,
                jobApplicationId,
              }),
          ),
          catchError(() => of(
            new TransferCandidateFailure({ jobApplicationId }),
          )),
        )
    }),
  ))

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private demoService: DemoService,
  ) { }
}
