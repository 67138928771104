import { Pipe, PipeTransform } from '@angular/core'

import { present } from '../utils/general-utils'

@Pipe({ name: 'present' })
export class PresentPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: string | any[]): boolean {
    return present(value)
  }
}
