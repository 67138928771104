import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { Observable, OperatorFunction, Subject, merge } from 'rxjs'
import { SlackReceiver, SlackService } from '../../services/slack.service'
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators'
import { sortBy } from 'lodash-es'

@Component({
  selector: 'twng-slack-destination-selector',
  templateUrl: './slack-destination-selector.component.html',
  styleUrls: ['./slack-destination-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: SlackDestinationSelectorComponent,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlackDestinationSelectorComponent implements OnInit, ControlValueAccessor {
  disabled: boolean
  selectedReceiverId: string
  selectedReceiver: SlackReceiver

  formatter = (receiver: SlackReceiver) => receiver.name

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  receivers: Array<SlackReceiver>
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;

  constructor(
    private slack: SlackService,
    private cd: ChangeDetectorRef,
  ) { }

  async ngOnInit() {
    const response = await this.slack.getChannels()
    this.receivers = [...sortBy(response.channels, 'name'), ...sortBy(response.users, 'name')]
    if (this.selectedReceiverId) {
      this.writeValue(this.selectedReceiverId)
    }
    this.cd.markForCheck()
  }

  newReceiverSelected(newReceiver: SlackReceiver) {
    this.selectedReceiver = newReceiver
    if (!newReceiver) {
      this.selectedReceiverId = null
    } else {
      this.selectedReceiverId = newReceiver.id
    }
    if (this.onChangeListener) {
      this.onChangeListener(newReceiver?.id)
    }
    this.cd.markForCheck()
  }

  writeValue(value: string): void {
    this.selectedReceiverId = value
    if (this.receivers) {
      this.newReceiverSelected(this.receivers.find(
        receiver => receiver.id === value
      ))
    }
  }
  onChangeListener: (newValue: string) => void
  registerOnChange(fn: (newValue: string) => void): void {
    this.onChangeListener = fn
  }
  // we dont need this
  registerOnTouched(_fn): void {}
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
    this.cd.markForCheck()
  }

  search: OperatorFunction<string, readonly SlackReceiver[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, clicksWithClosedPopup$, inputFocus$).pipe(
      map(term => this.receivers.filter(
        state => new RegExp(term, 'mi').test(state.name)
      ))
    )
  }
}
