import { Subscription } from 'rxjs'

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Store, select } from '@ngrx/store'

import * as fromWall from '../../wall/reducers'
import { AppConfigService } from '../../wall/services/app-config.service'
import { ChartFilters } from '../../dashboard/models/chart-filters'
import { FilterOption } from './filters/generic-filter/filter-option.interface';
import { SegmentService } from '../../core/services/segment.service'
import { SharedTab } from '../../wall/actions/tabs.actions'
import { User } from '../../wall/models/user'
import { WallDataPaginatedTabFilter } from '../../wall/actions/wall.actions'

@Component({
  selector: 'twng-tab-share',
  styles: [],
  template: `
    <twng-modal>
      <twng-modal-header>
        <twng-modal-title slot="header-title">Send a copy to a colleague</twng-modal-title>
      </twng-modal-header>
      <twng-modal-body>
        <div control="form-group">
        <label for="tab_name">Select the users who will receive a copy of your dashboard tab:</label>
          <twng-generic-filter [options]="users"
                               placeholder="Users"
                               (selectedOptionsChange)="selectedUsersChanged($event)">
          </twng-generic-filter>
        </div>
      </twng-modal-body>
      <twng-modal-footer>
        <button type="submit"
          class="tw-mt-2 button--primary button--large tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-white tw-bg-cq-primary-600 tw-text-sm tw-p-3"
          (click)="tabAction()">
          Send
        </button>
      </twng-modal-footer>
    </twng-modal>`,
})

export class TabShareComponent implements OnInit, OnDestroy {
  @Output()
    jobsChange = new EventEmitter()

  @Input()
    tabType: 'wall' | 'dashboard'

  @Input()
    selectedTabId: number

  @Input()
    filterUsersCanViewDashboard = false

  @Input()
    overrideFilters: ChartFilters | WallDataPaginatedTabFilter

  selectedUsersIds: number[] = []

  usersSub: Subscription
  users: User[]

  constructor(
    private store: Store<fromWall.State>,
    public activeModal: NgbActiveModal,
    public appConfig: AppConfigService,
    private segmentService: SegmentService,
  ) {
  }

  ngOnInit(): void {
    this.usersSub = this.store
      .pipe(select(fromWall.selectAllUsers))
      .subscribe(users => {
        this.users = users
          .filter(user =>
            !this.filterUsersCanViewDashboard || (this.filterUsersCanViewDashboard && user.can_view_dashboard)
          )
          .sort((a, b) =>
            a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
          )
      })
    this.segmentService.track(`Share Tab (opened) (${this.tabType})`)
  }

  tabAction() {
    const sharedWithUsers = this.selectedUsersIds.map(userId => this.users.find(x => x.id === userId).name)

    this.store.dispatch(new SharedTab({
      type: this.tabType,
      activeTab: this.selectedTabId,
      user_ids: this.selectedUsersIds,
      filters: this.overrideFilters,
      sharedWithUsers
    }))

    this.activeModal.close()
    this.segmentService.track(`Share Tab (completed) (${this.tabType})`)
  }

  ngOnDestroy(): void {
    this.usersSub.unsubscribe()
  }

  selectedUsersChanged(selectedUsers: FilterOption[]) {
    this.selectedUsersIds = selectedUsers.map(user => +user.id)
    this.jobsChange.emit(this.selectedUsersIds)
  }
}
