import { Observable, Subscription } from 'rxjs'

import { Component, NgZone, OnInit } from '@angular/core'

import { JobStageCategory } from '../../models/job-stage-category'
import {
  RenameJobStageCategoryNamePayload, UpdateCategoryPositionPayload, UpdateJobStageCategoryPayload
} from '../../actions/stage-mappings.actions'
import { StageMappingsService } from '../../services/stage-mappings.service'
import { cloneDeep } from 'lodash-es'
import { distinctUntilChanged } from 'rxjs/operators'

@Component({
  selector: 'twng-stage-mappings',
  templateUrl: './stage-mappings.component.html',
  styleUrls: ['./stage-mappings.component.scss']
})
export class StageMappingsComponent implements OnInit {

  jobStageNames$: Observable<string[]>
  // this variable is not observable because we want to modify it locally, not
  // in state. Otherwise, when dragging category for re-orderring, dragging
  // would cancel because that JobStageCategory instance would disappear from
  // memory
  jobStageCategories: JobStageCategory[]
  creatingCategory$: Observable<boolean>
  isSyncing$: Observable<boolean>

  private subscriptions = new Subscription()

  constructor(private stageMappingsService: StageMappingsService, private zone: NgZone) {
  }

  ngOnInit(): void {
    this.stageMappingsService.loadCategories()
    this.creatingCategory$ = this.stageMappingsService.getIsCreatingCategory()
    this.jobStageNames$ = this.stageMappingsService.getJobStageNames()
    this.isSyncing$ = this.stageMappingsService.getIsSyncing()
    this.subscriptions.add(this.stageMappingsService.getJobStageCategories().pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    ).subscribe(categories => {
      this.jobStageCategories = cloneDeep(categories)
    }))
  }

  createCategory() {
    this.stageMappingsService.createCategory()
  }

  updateCategory(updateJobStageCategoryPayload: UpdateJobStageCategoryPayload) {
    this.zone.run(() => {
      this.stageMappingsService.updateCategory(updateJobStageCategoryPayload)
    })
  }

  updateCategoryPosition(payload: UpdateCategoryPositionPayload) {
    const draggedIndex = this.jobStageCategories.findIndex((value) => value.id === payload.draggedCategoryId)
    const targetIndex = this.jobStageCategories.findIndex((value) => value.id === payload.replacePositionWithCategoryId)
    if (draggedIndex === targetIndex) {
      return
    }
    const direction = (draggedIndex > targetIndex) ? -1 : 1
    for (let i = draggedIndex; i !== targetIndex; i += direction) {
      const tmp = this.jobStageCategories[i]
      this.jobStageCategories[i] = this.jobStageCategories[i + direction]
      this.jobStageCategories[i + direction] = tmp
    }
  }

  commitUpdatingCategoryPositions() {
    this.zone.run(() => {
      this.stageMappingsService.commitJobStagePositions(this.jobStageCategories)
    })
  }

  removeCategory(category: JobStageCategory) {
    this.stageMappingsService.removeCategory(category)
  }

  renameCategory(renameJobStageCategoryNamePayload: RenameJobStageCategoryNamePayload) {
    this.stageMappingsService.renameCategory(renameJobStageCategoryNamePayload)
  }

}
