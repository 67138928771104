<div class="single-chart-filter-container" [ngbPopover]="filterTooltip" popoverClass="min-width--auto"
  container="body" autoClose="outside" triggers="mouseenter:mouseleave" [class.borderless]="showAsIcon">
  <div class="d-flex align-items-center justify-content-center" 
    [class.dropdown-padding]="!showAsIcon" (click)="onFilterClicked($event)">
   
    <ng-content select="[title-display]" *ngIf="!showAsIcon"></ng-content>

    <div class="value-count" [class.value-count-in-icon]="showAsIcon" *ngIf="!!countBadgeValue">
      {{ countBadgeValue }}
    </div>
    <i class="fas" [ngClass]="iconClass" *ngIf="showAsIcon"></i>
  </div>
</div>

<ng-template #filterTooltip>
  <ng-container *ngFor="let splitString of tooltipText">
    {{splitString}}<br />
  </ng-container>
</ng-template>