<twng-modal class="site-notifications small-info-modal">
  <twng-modal-header>
    <twng-modal-title slot="header-title">{{ siteNotification.title }}</twng-modal-title>
  </twng-modal-header>
  <twng-modal-body>
    <div class="tw-p-2" [innerHTML]="body"></div>
  </twng-modal-body>
  <twng-modal-footer>
    <button type="button" (click)="deleteNotification()"
      class="tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-white tw-bg-cq-primary-600 tw-text-sm tw-p-3"
    >
    Don't show again
    </button>
  </twng-modal-footer>
</twng-modal>
