/* eslint-disable @typescript-eslint/no-explicit-any */
import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import interact from 'interactjs';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective implements OnInit {

  @Input()
    model: unknown;

  @Input()
    options: unknown;

  @Input()
    mainClass = '';

  @Input()
    placement: 'normal' | 'custom-y'  | 'custom-x' = 'normal';

  @Output()
    draggableClick = new EventEmitter();

  private currentlyDragged = false;

  constructor(private element: ElementRef) {}

  @HostListener('click', ['$event'])
  public onClick(): void {
    if (!this.currentlyDragged) {
      this.draggableClick.emit();
    }
  }

  ngOnInit(): void {
    interact(this.element.nativeElement)
      .draggable(Object.assign({}, this.options || {}))
      .on('dragstart', (event) => {
        const target = event.target;

        if (event.target.classList.contains('fixed-drag')) {
          const position = target.getBoundingClientRect()

          target.style.top = position.top + "px"
          target.classList.add('tw-fixed')
        }
      })
      .on('dragmove', (event) => {
        const target = event.target;

        const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
        const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

        target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);

        target.classList.add('getting-dragged');
        this.currentlyDragged = true;
        (window as any).dragData = this.model;
      })
      .on('dragend', (event) => {
        const target = event.target
        const dropzone = event.relatedTarget;
        target.classList.remove('tw-fixed')
        target.style.top = "auto"

        target.style.transform = 'none';
        target.removeAttribute('data-x');
        target.removeAttribute('data-y');
        target.classList.remove('getting-dragged');


        if (dropzone) {
          const currentChildren = dropzone.getElementsByClassName(this.mainClass)
          let closest;
          let closestIndex;

          if (this.placement === 'custom-y') {
            if (currentChildren.length > 0) {
              for (let i = 0; i < currentChildren.length; i++) {
                const currentYDiff = event.client.y - currentChildren[i].getBoundingClientRect().y
                const yDiffAbs =  Math.abs(currentYDiff);
                if (!closest || yDiffAbs < closest) {
                  closest = yDiffAbs;
                  closestIndex = i;
                }
              }
            }
          }


          if (this.placement === 'custom-x') {
            for (let i = 0; i < currentChildren.length; i++) {
              const currentXDiff = event.client.x - currentChildren[i].getBoundingClientRect().x
              const xDiffAbs =  Math.abs(currentXDiff);
              if (!closest || xDiffAbs < closest) {
                closest = xDiffAbs;
                closestIndex = i;
              }
            }

            closestIndex -= 1;
          }

          if (typeof ((window as any).dragData) !== 'object') {
            (window as any).dragData = {
              value: (window as any).dragData,
              newPosition: closestIndex
            }
          } else {
            (window as any).dragData.newPosition = closestIndex;
          }
        }

        setTimeout(() => {
          (window as any).dragData = null;
          this.currentlyDragged = false;
        });
      });
  }
}
