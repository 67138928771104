import { CallApi } from '../../../../shared/actions/api.actions'
import { ChartFilters } from '../../../models/chart-filters'
import { Component } from '@angular/core'
import { Job } from '../../../../wall/models/job'
import { MemoizedSelector, Store } from '@ngrx/store'
import { SearchTypeaheadDataManager, SingleChartFilterComponent } from './single-chart-filter.component'
import { SegmentService } from '../../../../core/services/segment.service'
import { WallApiService } from '../../../../wall/services/wall-api.service'
import { first } from 'rxjs/operators'
import { isApiLoaded } from '../../../../shared/state/selectors'
import { selectClosedJobsForFilterState, selectJobsForFilterState } from '../../../../wall/reducers'

async function getAllData(
  store: Store,
  api: CallApi,
  selector: MemoizedSelector<Record<string, unknown>, Job[]>
): Promise<Job[]> {
  store.dispatch(api)
  await isApiLoaded(store, api.payload.apiName).pipe(
    first(loaded => loaded)
  ).toPromise()
  return await store.select(selector).pipe(
    first()
  ).toPromise()
}

export const JobDataManagerNames = {
  open: 'OpenJobDataManager',
  closed: 'ClosedJobDataManager'
}

export class OpenJobDataManager extends SearchTypeaheadDataManager<Job> {
  name = JobDataManagerNames.open

  constructor(private store: Store, private wallApi: WallApiService) {
    super('Open Jobs')
  }

  protected loadAllData(): Promise<Job[]> {
    return getAllData(this.store, this.wallApi.getFetchOpenJobNamesAction(), selectJobsForFilterState)
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.job_external_ids
      .map(v => v.toString())
      .filter(id => this.allData.find(job => job.id === id))
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_jobs
  }
}

export class ClosedJobDataManager extends SearchTypeaheadDataManager<Job> {
  name = JobDataManagerNames.closed

  constructor(private store: Store, private wallApi: WallApiService) {
    super('Closed Jobs')
  }

  protected loadAllData(): Promise<Job[]> {
    return getAllData(this.store, this.wallApi.getFetchClosedJobNamesAction(), selectClosedJobsForFilterState)
  }
  protected getIdsFromFilters(): string[] {
    return this.filters.job_external_ids
      .map(v => v.toString())
      .filter(id => this.allData.find(job => job.id === id))
  }

  getExclusionParamFromFilters(): boolean {
    return this.filters.should_exclude_jobs
  }
}

@Component({
  selector: 'twng-job-single-chart-filter',
  templateUrl: './single-chart-filter.component.html',
  styleUrls: ['./single-chart-filter.component.scss',
    './single-chart-filter-template/single-chart-filter-template.component.scss'
  ],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  providers: [{ provide: SingleChartFilterComponent, useExisting: JobSingleChartFilterComponent }]
})
export class JobSingleChartFilterComponent extends SingleChartFilterComponent {
  id = 'JobSingleChartFilterComponent';

  constructor(
    segmentService: SegmentService,
    store: Store,
    private wallApi: WallApiService,
  ) {
    super(segmentService, store, 'Jobs', 'Job', 'fa-suitcase')
  }

  getChangedValueForStoring(): Partial<ChartFilters> {
    return {
      job_external_ids: [
        ...this.managers[0].tempSelectedIds.map((v) => parseInt(v, 10)),
        ...this.managers[1].tempSelectedIds.map((v) => parseInt(v, 10)),
      ],
      should_exclude_jobs: this.managers[0].shouldExcludeFilter
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getDataManagers(): SearchTypeaheadDataManager<any>[] {
    return [
      new OpenJobDataManager(this.store, this.wallApi),
      new ClosedJobDataManager(this.store, this.wallApi),
    ]
  }
}
