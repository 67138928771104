import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

// WARNING:
// AVOID USING THIS UNTIL WE DETERMINE IF IT IS SAFE TO IFRAME
// END WARNING
@Component({
  selector: 'twng-video-modal',
  template: `
    <twng-modal>
      <twng-modal-header>
        <twng-modal-title slot="header-title">{{ title }}</twng-modal-title>
      </twng-modal-header>
      <twng-modal-body>
        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
          <iframe sandbox="allow-scripts allow-same-origin" [src]="url"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
          </iframe>
        </div>
      </twng-modal-body>
    </twng-modal>
  `,
  styles: [``]
})
export class VideoModalComponent implements OnInit {
  @Input() title: string

  url: SafeUrl

  @Input()
  public set embedUrl(url: string) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${url}?autoplay=1`)
  }

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void { }
}
