<div ngbDropdownItem class="prediction-item" [formGroup]="parentForm">
  <input type="number" min="0" max="100" maxlength="3" class="form-input" [id]="jobStage.id"
    [formControlName]="jobStage.id" [placeholder]="this.jobPrediction.recommended_percent?.toFixed(0)"/>
  <span class="percent">%</span>
  <div class="tw-ml-4">
    <label [for]="jobStage.id">{{ jobStage.name }}</label>
    <div class="actual-rate" [ngbTooltip]="jobPrediction.text">
      <span class="value">{{ jobPrediction.recommended_percent?.toFixed(0) }}</span>
      <span class="percent">%</span>
      <span class="label">{{ typeOfPrediction() }}</span>
    </div>
    <div class="actual-rate" ngbTooltip="Actual conversion rate extracted from this job's stage">
      <span class="value">{{ actualConversionRateFixed() }}</span>
       <span class="percent">%</span>
      <span class="label">Actual</span>
    </div>
  </div>
</div>