<form [formGroup]="dateFilterForm">

  <div class="btn-group date-date-filters" role="group">
    <button [disabled]="demoMode || disabled" [title]="demoDisabledTooltip" type="button" class="btn btn-primary card-menu-button"
      (click)="applyFilter(null)" [class.active]="dateMode === null" *ngIf="showActive">
      Active Jobs
    </button>

    <button [disabled]="demoMode || disabled" [title]="demoDisabledTooltip" type="button"
      ngbTooltip="All imported data (last 2 years by default)" class="btn btn-primary card-menu-button"
      (click)="applyFilter('allTime')" [class.active]="dateMode === 'allTime'">
      All time
      <i class="fas fa-info-circle"></i>
    </button>

    <button [disabled]="demoMode || disabled" [title]="demoDisabledTooltip" [class.active]="dateMode === 'last6months'"
      type="button" class="btn btn-primary card-menu-button" (click)="applyFilter('last6months')">
      Last 6 months
    </button>

    <button type="button" [disabled]="demoMode || disabled" [title]="demoDisabledTooltip" class="btn btn-primary card-menu-button"
      [class.active]="dateMode === 'last3months'" (click)="applyFilter('last3months')">
      Last 3 months
    </button>

    <div class="drop-down-custom-range" ngbDropdown #dropdown="ngbDropdown">
      <button [disabled]="demoMode || disabled" [title]="demoDisabledTooltip" type="button" ngbTooltip="Select Date Range"
        class="btn btn-primary card-menu-button" [class.active]="dateMode === 'custom'" ngbDropdownToggle>
        Custom
      </button>

      <div ngbDropdownMenu>
        <div class="dropdown-content">
          <input readonly class="form-control form-control-sm" type="text" autocomplete="off" ngbDatepicker
            #startDateDatepicker="ngbDatepicker" formControlName="startDate" (click)="startDateDatepicker.toggle()" />
          <span class="showing__to">to</span>
          <input readonly class="form-control form-control-sm" type="text" autocomplete="off" ngbDatepicker
            #endDateDatepicker="ngbDatepicker" formControlName="endDate" (click)="endDateDatepicker.toggle()" />
        </div>
        <button class="btn btn-primary apply-btn tw-mb-2" (click)="dropdown.close();applyFilter('custom');">
          Apply
        </button>
      </div>
    </div>
  </div>
</form>
