import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { ExternalUser } from '../models/external-user'
import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { sortByName } from './sort-comparers'

export type State = EntityState<ExternalUser>

export const adapter: EntityAdapter<ExternalUser> = createEntityAdapter<
ExternalUser
>({
  sortComparer: sortByName,
})

export const initialState: State = adapter.getInitialState()

export function reducer(state = initialState, action: LoaderActions): State {
  switch (action.type) {
    case LoaderActionTypes.LoadSharedDataSuccess:
      return adapter.setAll(action.payload.external_users, state)

    default:
      return state
  }
}
