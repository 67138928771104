import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Interview } from '../models/interview'
import {
  LoaderActionTypes,
  LoaderActions,
} from '../../core/actions/loader.actions'
import { WallActionTypes, WallActions } from '../actions/wall.actions'

export type State = EntityState<Interview>

export const adapter: EntityAdapter<Interview> = createEntityAdapter<Interview>()

export const initialState: State = adapter.getInitialState()

export function reducer(state = initialState, action: LoaderActions | WallActions): State {
  switch (action.type) {
    case WallActionTypes.FetchWallDataPaginatedSuccessAction:
    case WallActionTypes.FetchWallDataPaginatedTabSuccessAction: {
      return adapter.addMany(action.payload.interviews || [], state)
    }

    case LoaderActionTypes.DeleteFromServer: {
      const interview_id = action.payload.interview
      if (!interview_id) {
        return state
      }
      return adapter.removeOne(interview_id, state)
    }
    case LoaderActionTypes.UpdateFromServer: {
      const interviews = action.payload.interviews
      if (!interviews) {
        return state
      }
      return adapter.upsertMany(interviews, state)
    }

    default: {
      return state
    }
  }
}
