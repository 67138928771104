import { ActionCableService } from "../../wall/services/action-cable.service"
import { AppConfigService } from "../../wall/services/app-config.service"
import { Chart, ChartStatus } from "../../dashboard/models/dashboard-chart"
import { HttpClient } from "@angular/common/http"
import { Injectable, NgZone } from "@angular/core"
import { Store } from "@ngrx/store"
import { UpdateWidgetStatusFromQueueKey } from "../actions/loader.actions"
import { apiHost, getHttpPostOptions } from "../http-options"
import { concatMap, first } from "rxjs/operators"
import { objValuesSafe } from "../../shared/utils/general-utils"
import { observableImmediatelySync, selectImmediatelySync } from "../../shared/utils/store.utils"
import { selectAllDashboardCharts, selectWidgetLibraryData } from "../../dashboard/reducers"
import { timer } from "rxjs"

function filterForCorrectChart(chart: Chart) {
  return (chart.data?.status === "queued" ||
         chart.data?.status === "running") &&
         chart.data.queue_key &&
         chart.data_source !== 'number-box-custom'
}

function getChartQueue(chart: Chart) {
  return chart.data?.queue_key
}

function filterAndMapCharts(charts: Chart[]) {
  return charts
    .filter(filterForCorrectChart)
    .map(getChartQueue)
}

interface CheckBody {
  params: {
    queues: string[]
  }
}

interface CheckResponse {
  statuses: ChartStatus[]
}

@Injectable({
  providedIn: 'root'
})
export class ChartStatusCheckerService {
  constructor(
    private store: Store,
    private zone: NgZone,
    private http: HttpClient,
    private actionCable: ActionCableService,
    config: AppConfigService,
  ) {
    // run this outside for performance reasons
    zone.runOutsideAngular(() => {
      timer(10000, 10000).subscribe(() => {
        const cachingEnabled = observableImmediatelySync(config.isFeatureFlagEnabled$('chart_caching'))
        if (cachingEnabled) {
          const pendingWidgets = this.collectAllPendingWidgets()
          if (pendingWidgets.length) {
            this.fetchStatusesFromBackend(pendingWidgets)
          }
        }
      })
    })
  }

  refreshChartData(chartQueue: string) {
    this.store.dispatch(new UpdateWidgetStatusFromQueueKey({
      queue_key: chartQueue,
      status: "queued",
    }))
    this.actionCable.sessionString$.pipe(
      first(sessionString => !!sessionString),
      concatMap(sessionString => this.http.post<ChartStatus>(
        apiHost + `/twng/dashboard/charts/refresh_chart_data?user_session_string=${sessionString}`,
        {
          params: { queue: chartQueue }
        },
        getHttpPostOptions(),
      ))
    ).subscribe({
      next: (newStatus) => {
        this.store.dispatch(new UpdateWidgetStatusFromQueueKey(newStatus))
      },
      error: (error) => {
        this.store.dispatch(new UpdateWidgetStatusFromQueueKey({
          queue_key: chartQueue,
          status: "error",
          message: "An error happened when we tried to update the widget data.",
        }))
        console.warn("Error during request for widget update:", error)
      }
    })
  }

  private fetchStatusesFromBackend(queues: string[]) {
    const body: CheckBody = {
      params: {
        queues
      }
    }
    this.http.post<CheckResponse>(apiHost + '/twng/dashboard/charts/get_chart_status', body, getHttpPostOptions())
      .subscribe(response => this.applyWidgetData(response))
  }

  private applyWidgetData(backendResponse: CheckResponse) {
    this.zone.run(() => {
      backendResponse.statuses.forEach(status => {
        this.store.dispatch(new UpdateWidgetStatusFromQueueKey(status))
      })
    })
  }

  private collectDashboardWidgets() {
    const charts = selectImmediatelySync(this.store, selectAllDashboardCharts)
    return filterAndMapCharts(charts)
  }

  private collectWidgetLibraryWidgets() {
    const tabs = selectImmediatelySync(this.store, selectWidgetLibraryData)
    const libraryTabs = objValuesSafe(tabs)
    const sections = libraryTabs.flatMap(t => t?.sections || [])
    const charts = sections.flatMap(s => s?.charts || [])
    return filterAndMapCharts(charts)
  }

  private collectAllPendingWidgets() {
    return [
      ...this.collectDashboardWidgets(),
      ...this.collectWidgetLibraryWidgets(),
    ]
  }
}
