import {
  BaseChartComponent,
  ColorHelper,
  LegendOptions,
  LegendPosition,
  LineSeriesComponent,
  Orientation,
  ScaleType,
  StringOrNumberOrDate,
  ViewDimensions,
  calculateViewDimensions
} from '@swimlane/ngx-charts';
import {
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ScaleBand, ScaleLinear, scaleBand, scaleLinear } from 'd3-scale';
import { curveLinear } from 'd3-shape';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ngx-charts-combo',
  templateUrl: './ngx-charts-combo.component.html',
  styleUrls: ['./ngx-charts-combo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgxChartsComboComponent extends BaseChartComponent {
  @Input() curve = curveLinear;
  @Input() legend = false;
  @Input() legendTitle = 'Legend';
  @Input() legendPosition: LegendPosition = LegendPosition.Right;
  @Input() xAxis;
  @Input() yAxis;
  @Input() showXAxisLabel;
  @Input() showYAxisLabel;
  @Input() showRightYAxisLabel;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() yAxisLabelRight;
  @Input() tooltipDisabled = false;
  @Input() gradient: boolean;
  @Input() showGridLines = true;
  @Input() activeEntries = [];
  @Input() schemeType: ScaleType;
  @Input() xAxisTickFormatting;
  @Input() yAxisTickFormatting;
  @Input() yRightAxisTickFormatting;
  @Input() roundDomains = false;
  @Input() colorSchemeLine;
  @Input() autoScale;
  @Input() lineChart;
  @Input() yAxisScaleFactor;
  @Input() rangeFillOpacity: number;
  @Input() animations = false;
  @Input() noBarWhenZero = true;

  @Output() activate: EventEmitter<unknown> = new EventEmitter();
  @Output() deactivate: EventEmitter<unknown> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<unknown>;
  @ContentChild('seriesTooltipTemplate') seriesTooltipTemplate: TemplateRef<unknown>;

  @ViewChild(LineSeriesComponent) lineSeriesComponent: LineSeriesComponent;

  dims: ViewDimensions;
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number, never>;
  xDomain;
  yDomain;
  transform: string;
  colors: ColorHelper;
  margin = [10, 20, 10, 20];
  xAxisHeight = 0;
  yAxisWidth = 0;
  legendOptions: LegendOptions;
  scaleType = ScaleType.Linear;
  xScaleLine: ScaleBand<string>;
  xDomainLine;
  yDomainLine;
  seriesDomain;
  scaledAxis;
  combinedSeries;
  xSet;
  filteredDomain;
  hoveredVertical;
  yOrientLeft = Orientation.Left;
  yOrientRight = Orientation.Right;
  legendSpacing = 0;
  bandwidth;
  barPadding = 8;

  trackBy(index, item): string {
    return item.name;
  }

  update(): void {
    super.update();
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (!this.yAxis) {
      this.legendSpacing = 0;
    } else if (this.showYAxisLabel && this.yAxis) {
      this.legendSpacing = 100;
    } else {
      this.legendSpacing = 40;
    }
    this.xScale = this.getXScale();
    this.yScale = this.getYScale();

    // line chart
    this.xDomainLine = this.getXDomainLine();
    if (this.filteredDomain) {
      this.xDomainLine = this.filteredDomain;
    }

    this.yDomainLine = this.getYDomain();
    this.seriesDomain = this.getSeriesDomain();

    this.scaleLines();

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;
  }

  deactivateAll() {
    this.activeEntries = [...this.activeEntries];
    for (const entry of this.activeEntries) {
      this.deactivate.emit({ value: entry, entries: [] });
    }
    this.activeEntries = [];
  }

  @HostListener('mouseleave')
  hideCircles(): void {
    this.hoveredVertical = null;
    this.deactivateAll();
  }

  updateHoveredVertical(item): void {
    this.hoveredVertical = item.value;
    this.deactivateAll();
  }

  updateDomain(domain): void {
    this.filteredDomain = domain;
    this.xDomainLine = this.filteredDomain;
    this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
  }

  scaleLines() {
    this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
  }

  getSeriesDomain(): unknown[] {
    this.combinedSeries = [{
      name: this.yAxisLabel,
      series: this.results
    }];
    this.combinedSeries.push(...this.lineChart.slice(0));
    return this.combinedSeries.map(d => d.name);
  }

  isDate(value): boolean {
    if (value instanceof Date) {
      return true;
    }

    return false;
  }

  getScaleType(values): ScaleType {
    let date = true;
    let num = true;

    for (const value of values) {
      if (!this.isDate(value)) {
        date = false;
      }

      if (typeof value !== 'number') {
        num = false;
      }
    }

    if (date) {
      return ScaleType.Time;
    }
    if (num) {
      return ScaleType.Linear;
    }
    return ScaleType.Ordinal;
  }

  getXDomainLine(): unknown[] {
    let values = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (!values.includes(d.name)) {
          values.push(d.name);
        }
      }
    }

    this.scaleType = this.getScaleType(values);
    let domain = [];

    if (this.scaleType === 'time') {
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else if (this.scaleType === 'linear') {
      values = values.map(v => Number(v));
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else {
      domain = values;
    }

    this.xSet = values;
    return domain;
  }

  getYDomainLine(): number[] {
    const domain = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      }
    }

    let min = Math.min(...domain);
    const max = Math.max(...domain);
    if (this.yAxisScaleFactor) {
      const minMax = this.yAxisScaleFactor(min, max);
      return [Math.min(0, minMax.min), minMax.max];
    } else {
      min = Math.min(0, min);
      // multiply with 1.1 so that we get a bit space above bars
      return [min, max * 1.1];
    }
  }

  getXScaleLine(domain, width): ScaleBand<string> {
    if (this.bandwidth === undefined) {
      this.bandwidth = width - this.barPadding;
    }
    const offset = Math.floor((width + this.barPadding - (this.bandwidth + this.barPadding) * domain.length) / 2);
    return this.getXScale(offset + this.bandwidth / 2);
  }

  getXScale(margins = 0): ScaleBand<string> {
    this.xDomain = this.getXDomain();
    const spacing = this.xDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand().range([margins, this.dims.width + margins]).paddingInner(spacing).domain(this.xDomain);
  }

  getYScale(): ScaleLinear<number, number, never> {
    this.yDomain = this.getYDomain();
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.yDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  getXDomain(): StringOrNumberOrDate[] {
    return this.results.map(d => d.name);
  }

  getYDomain() {
    const values = this.results.map(d => d.value);
    const [lineMin, lineMax] = (this.getYDomainLine() || [0, 0])
    const min = Math.min(0, ...values, lineMin || 0);
    const max = Math.max(...values, lineMax || 0);
    if (this.yAxisScaleFactor) {
      const minMax = this.yAxisScaleFactor(min, max);
      return [Math.min(0, minMax.min), minMax.max];
    } else {
      // multiply with 1.1 so that we get a bit space above bars
      return [min, max * 1.1];
    }
  }

  onClick(data) {
    this.select.emit(data);
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.xDomain;
    } else {
      domain = this.yDomain;
    }
    const colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    const colorsLine = new ColorHelper(this.colorSchemeLine, this.schemeType, domain, this.customColors);
    const customColorsForGraphs = this.seriesDomain.map(value => ({
      name: value,
      value: value === this.yAxisLabel ? colors.getColor(value) : colorsLine.getColor(value),
    }))
    const customColorsForXAxis = this.getXDomain().map(value => ({
      name: value,
      value: colors.getColor(value),
    }))
    this.colors = new ColorHelper({
      domain: this.seriesDomain,
      group: ScaleType.Ordinal,
      name: "legend",
      selectable: false,
    }, ScaleType.Ordinal, this.seriesDomain, customColorsForGraphs.concat(customColorsForXAxis))
  }

  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors.scale;
    }
    return opts;
  }

  updateLineWidth(width): void {
    this.bandwidth = width;
    this.scaleLines();
  }

  updateYAxisWidth({ width }): void {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({ height }): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(item) {
    const idx = this.activeEntries
      .findIndex(d => d.name === item.name && d.value === item.value && d.series === item.series);
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item, ...this.activeEntries];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item) {
    const idx = this.activeEntries
      .findIndex(d => d.name === item.name && d.value === item.value && d.series === item.series);

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }
}
