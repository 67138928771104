import { catchError, concatMap, toArray } from 'rxjs/operators'
import { from, range, throwError } from 'rxjs'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { GenericTableInput } from './generic-table.component'
import { NotificationService, NotificationType } from '../../../shared/services/notification.service'
import {
  NotifyDataChangedInModal, ShowGenericTableModalPayload, genericTableModalTitle, getChartTitleInfo
} from '../../actions/generic-table.actions'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { apiHost, getHttpPostOptions } from '../../http-options'
import { demoResult } from './generic-table-demo'
import { exportCsv } from '../../../shared/utils/general-utils'

@Component({
  selector: 'twng-generic-table-modal',
  templateUrl: './generic-table-modal.component.html',
  styleUrls: [
    '../../../../scss/components/_candidates_modal.scss',
    './generic-table-modal.component.scss'
  ],
})
export class GenericTableModalComponent implements OnInit, OnDestroy {
  payload: ShowGenericTableModalPayload
  result: GenericTableInput
  hiddenDataTable: GenericTableInput

  title: string
  charTitleInfo: string
  subtitle: string
  footer: string
  footerBigFont: string
  subtitleTooltip: string
  footerTip: string
  private shouldDisplayFooter: boolean
  // did user edit data from within the table (for example, clicking exclude button)
  private didDataChange = false

  demoMode = !!window.twng_demo

  // we allow this to be overriden by developers in production because we would
  // like to be able to play with numbers for testing purposes.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PER_PAGE = (window as any).GENERIC_TABLE_MAX_ROWS || 500
  showCsvDownloadOnly = false
  canClickDownloadCsv = true

  constructor(
    private http: HttpClient,
    private activeModal: NgbActiveModal,
    private store: Store,
    private notifications: NotificationService,
    private toastr: ToastrService,
  ) { }

  async ngOnInit() {
    if (this.demoMode) {
      this.title = "Candidates"
      this.result = demoResult
    } else {
      if (this.payload.data_ids.length <= this.PER_PAGE) {
        this.result = await this.loadTableData(this.payload)
      } else {
        this.showCsvDownloadOnly = true
      }
    }
    this.loadTextInformationFromResult()
  }

  ngOnDestroy() {
    if (this.didDataChange) {
      this.store.dispatch(new NotifyDataChangedInModal())
    }
  }

  dataChanged() {
    this.didDataChange = true
  }

  private loadTableData(fetchTableDataParams: ShowGenericTableModalPayload, page = 0) {
    const url = `${apiHost}/twng/table_data.json`
    const ids = fetchTableDataParams.data_ids.slice(page * this.PER_PAGE, (page + 1) * this.PER_PAGE)
    const paramsToSend: ShowGenericTableModalPayload = {
      ...fetchTableDataParams,
      data_ids: ids,
    }
    return this.http.post<GenericTableInput>(
      url,
      { params: paramsToSend },
      getHttpPostOptions(),
    ).pipe(
      catchError(errorResponse => {
        this.activeModal.dismiss(errorResponse)
        this.toastr.error("An error has occured during data download", errorResponse.message)
        return throwError(errorResponse)
      }),
    ).toPromise()
  }

  private loadTextInformationFromResult() {
    this.title = genericTableModalTitle(this.payload)
    this.charTitleInfo = getChartTitleInfo(this.payload, ': ')
    this.subtitle = this.getSubtitle()
    if (!this.result) {
      return
    }

    this.shouldDisplayFooter = this.result.hidden.length > 0
    if (this.shouldDisplayFooter) {
      this.footer = this.getFooter()
      this.footerTip = this.getFooterTip()
      this.subtitleTooltip = this.getSubtitleTooltip()
      this.footerBigFont = this.getFooterBigFont()
    }
    if (this.result.hidden && this.result.hidden.length > 0) {
      this.hiddenDataTable = {
        ...this.result,
        visible: this.result.hidden,
        schema: this.result.schema.filter(sc => sc.is_public)
      }
    }
  }

  // job -> Job
  private capitalizeDataType(dataType = this?.result?.data_type) {
    if (dataType) {
      return dataType.charAt(0).toUpperCase() + dataType.slice(1)
    } else {
      return "Unknown"
    }
  }

  // job -> Jobs
  private pluralizeDataType(dataType = this?.result?.data_type) {
    return this.capitalizeDataType(dataType) + "s"
  }

  // job -> Job / Jobs
  private pluralizeDataTypeBasedOnCount(num: number, dataType = this?.result?.data_type) {
    return num === 1 ?
      this.capitalizeDataType(dataType) :
      this.pluralizeDataType(dataType)
  }

  // 3 Jobs
  private dataTypeWithNumber(num: number, dataType = this?.result?.data_type) {
    return num + " " + this.pluralizeDataTypeBasedOnCount(num, dataType)
  }

  private getSubtitle() {
    if (this.result?.visible) {
      let ret = ""
      // extra counts (for example 3 candidates)
      for (const [dataType, countOfData] of Object.entries(this.result.extra_counts || {})) {
        ret += this.dataTypeWithNumber(countOfData, dataType) + ', '
      }
      ret += this.dataTypeWithNumber(this.result.visible.length + this.result.hidden.length)
      // add + 3 candidates are private ... stuff
      if (this.result.hidden.length > 0) {
        const dif = this.result.hidden.length
        ret += " (" + this.dataTypeWithNumber(dif)
        if (dif === 1) {
          ret += " is "
        } else {
          ret += " are "
        }
        ret += "private, confidential, or not visible based on your Greenhouse permissions)."
      }
      return ret
    } else {
      return this.showCsvDownloadOnly ? this.payload.data_ids.length + " rows expected" : "Loading ..."
    }
  }

  private getFooter() {
    if (this.result) {
      return "more hidden " + this.pluralizeDataTypeBasedOnCount(this.result.hidden.length)
    } else {
      return "Loading ..."
    }
  }

  private getFooterTip() {
    return `Note: ${this.pluralizeDataType()} are hidden based on your Greenhouse permissions. ` +
      "Private candidates and confidential jobs are always hidden, " +
      "with the exception that it may be possible to view hired private candidates " +
      "if you have a special permission in TalentWall"
  }

  private getFooterBigFont() {
    if (this.result?.visible) {
      return (this.result.hidden.length).toString()
    }
  }

  private getSubtitleTooltip() {
    return this.getFooterTip()
  }

  async downloadAsCsv() {
    this.canClickDownloadCsv = false
    let resultForCsv = this.showCsvDownloadOnly ? null : this.result
    if (this.showCsvDownloadOnly) {
      try {
        const numPages = Math.floor((this.payload.data_ids.length - 1) / this.PER_PAGE) + 1
        // this makes notification appear initially
        this.notifications.updateNotification(
          NotificationType.DownloadingTableRows, "Downloading rows ...", 0, numPages
        )
        const allResults = await range(0, numPages).pipe(
          concatMap(page => from(
            this.loadTableData(this.payload, page)
              .then((data) => {
                // update Downloading ... notification
                this.notifications.updateNotification(
                  NotificationType.DownloadingTableRows, "Downloading rows ...", page + 1, numPages
                )
                return data
              })
          )),
          toArray()
        ).toPromise()
        this.notifications.closeNotification(NotificationType.DownloadingTableRows)
        // merge all downloaded results into single object
        resultForCsv = {
          visible: [],
          data_type: allResults[0]?.data_type, // not needed
          extra_counts: {}, // not needed
          hidden: [],
          name: allResults[0]?.name, // not needed
          schema: [],
        }
        for (const result of allResults){
          resultForCsv.visible.push(...result.visible)
          resultForCsv.hidden.push(...result.hidden)
          for (const columnDescription of result.schema) {
            if (!resultForCsv.schema.find(existingColumn => existingColumn.name === columnDescription.name)) {
              resultForCsv.schema.push(columnDescription)
            }
          }
        }
      } catch(err) {
        console.warn("An error occured when we tried to download data for CSV")
        console.warn(err)
        // this modal will close automatically whenever an error in http happens
        // we just want to not save csv data in that case
        return
      }
    }
    if (resultForCsv) {
      const name = genericTableModalTitle(this.payload)
      const data = resultForCsv.visible.concat(resultForCsv.hidden)
      exportCsv(data, name + '.csv', resultForCsv.schema.filter(
        schema => schema.value_type !== 'greenhouse_link' && schema.value_type !== 'link'
          && schema.value_type !== 'toggle'
      ).map(
        schema => schema.name
      ))
    }
    this.canClickDownloadCsv = true
  }

  closeModal() {
    this.activeModal.close()
  }
}
