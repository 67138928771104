import { atsId } from '../../wall/models/types'

export function updateStatePerJobApplication(
  state,
  jobApplicationId: atsId,
  changes,
  defaultSubState = {},
) {
  return {
    ...state,
    [jobApplicationId]: {
      ...defaultSubState,
      ...state[jobApplicationId],
      ...changes,
    },
  }
}
