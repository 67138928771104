import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { GenericTableModalActionTypes, ShowGenericTableModal,
  ShowGenericTableModalPayload
} from '../actions/generic-table.actions'
import { GenericTableModalComponent } from '../components/generic-table/generic-table-modal.component'

@Injectable({ providedIn: 'root' })
export class GenericTableEffects {

  constructor(
    private actions$: Actions,
    private modalService: NgbModal,
  ) { }

  showModal$: Observable<ShowGenericTableModal> = createEffect(() => this.actions$.pipe(
    ofType<ShowGenericTableModal>(GenericTableModalActionTypes.ShowModal),
    tap((action) => {
      this.showModal(action.payload)
    })
  ), { dispatch: false })

  private showModal(payload: ShowGenericTableModalPayload) {
    const modal = this.modalService.open(GenericTableModalComponent, { windowClass: 'modal-size' })
    const modalInstance = modal.componentInstance as GenericTableModalComponent
    modalInstance.payload = payload
  }
}
