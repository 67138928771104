import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'

import { State, selectUser } from '../../reducers'

@Injectable({ providedIn: 'root' })
export class IsAdminUserGuard  {
  constructor(private store: Store<State>,
    private router: Router) {
  }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(selectUser),
      filter(user => !!user),
      map(user => {
        if (!user.admin) {
          this.router.navigateByUrl('')
          return false
        }
        return true
      }),
    )
  }
}
