import { BehaviorSubject, Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { CacheService } from '../../../../wall/services/cache.service'
import { DepartmentWithChildren } from '../../../../wall/models/department'
import { FilterOption } from '../generic-filter/filter-option.interface'
import { filterSelectedItemsWithChildren } from '../utils'

@Component({
  selector: 'twng-department-filter',
  templateUrl: './department-filter.component.html',
  styleUrls: ['./department-filter.component.scss'],
})
export class DepartmentFilterComponent implements OnInit {

  selectedDepartments$: BehaviorSubject<DepartmentWithChildren[]> = new BehaviorSubject([])
  departmentsWithChildren$: Observable<DepartmentWithChildren[]>

  @Input()
    departmentIds: string[] = []

  @Input()
    disabled = false

  @Output()
    filterChange = new EventEmitter<FilterOption[]>()

  constructor(private cacheService: CacheService) {
  }

  ngOnInit(): void {
    this.departmentsWithChildren$ = this.cacheService.departmentsWithChildren$
      .pipe(
        tap((departmentsWithChildren: DepartmentWithChildren[]) =>
          this.loadSelectedDepartments(departmentsWithChildren, this.departmentIds)
        ),
        switchMap((departmentWithChildren: DepartmentWithChildren[]) => this.selectedDepartments$.pipe(
          map((selectedDepartments: DepartmentWithChildren[]) =>
            filterSelectedItemsWithChildren(departmentWithChildren, selectedDepartments)
          )
        )),
      )
  }

  loadSelectedDepartments(departmentsWithChildren: DepartmentWithChildren[], departmentIds: string[]): void {
    const departments = departmentsWithChildren.filter(department => (departmentIds || []).includes(department.id))
    setTimeout(() => this.selectedDepartments$.next(departments))
  }

  departmentsSelected(selectedDepartment: FilterOption) {
    const selectedDepartments = this.selectedDepartments$.value
    this.selectedDepartments$.next([...selectedDepartments, (selectedDepartment as DepartmentWithChildren)])
    this.emitFilterValue()
  }

  removeDepartment(departmentOption: FilterOption) {
    const selectedDepartment = this.selectedDepartments$.value.filter(
      (department: DepartmentWithChildren) => department.id !== departmentOption.id)
    this.selectedDepartments$.next(selectedDepartment)
    this.emitFilterValue()
  }

  emitFilterValue() {
    setTimeout(() => this.filterChange.emit(this.selectedDepartments$.value))
  }

  reset() {
    while (this.selectedDepartments$.value.length) {
      this.removeDepartment(this.selectedDepartments$.value[0])
    }
  }
}
