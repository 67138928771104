<twng-modal>
  <twng-modal-header>
    <twng-modal-title slot="header-title">
      Configure "{{ tab.default_tab ? "Open Jobs" : tab.name }}" Tab
    </twng-modal-title>
  </twng-modal-header>
  <twng-modal-body>
    <form [formGroup]="form" (ngSubmit)="save()">
      <div *ngIf="!tab.default_tab && tab.id !== -1" class="form-group">
        <div class="label">Tab name</div>
        <input type="text" name="name" formControlName="name" class="form-control">
      </div>

      <span>Show the selected columns:</span>
      <div class="two-column">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_days_open" name="show_days_open"
            formControlName="show_days_open">
          <label class="form-check-label" for="show_days_open">Days Open</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_openings" name="show_openings"
            formControlName="show_openings">
          <label class="form-check-label" for="show_openings">Openings</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_job_stages" name="show_job_stages"
            formControlName="show_job_stages">
          <label class="form-check-label" for="show_job_stages">Job Stages</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_hires" name="show_hires"
            formControlName="show_hires">
          <label class="form-check-label" for="show_hires">Hires</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_proj_hires" name="show_proj_hires"
            formControlName="show_proj_hires">
          <label class="form-check-label" for="show_proj_hires">Projected Hires</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_notes" name="show_notes"
            formControlName="show_notes">
          <label class="form-check-label" for="show_notes">Notes</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_recruiter" name="show_recruiter"
            formControlName="show_recruiter">
          <label class="form-check-label" for="show_recruiter">Recruiter</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_hiring_manager" name="show_hiring_manager"
            formControlName="show_hiring_manager">
          <label class="form-check-label" for="show_hiring_manager">Hiring Manager</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_job_id" name="show_job_id"
            formControlName="show_job_id">
          <label class="form-check-label" for="show_job_id">Job ID</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_opening_id" name="show_opening_id"
            formControlName="show_opening_id">
          <label class="form-check-label" for="show_opening_id">Opening ID</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_office" name="show_office"
            formControlName="show_office">
          <label class="form-check-label" for="show_office">Office</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_total_active_candidates"
            name="show_total_active_candidates" formControlName="show_total_active_candidates">
          <label class="form-check-label" for="show_total_active_candidates">Total Active Candidates</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_opening_date" name="show_opening_date"
            formControlName="show_opening_date">
          <label class="form-check-label" for="show_opening_date">Opening Date</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_candidates_processed"
            name="show_candidates_processed" formControlName="show_candidates_processed">
          <label class="form-check-label" for="show_candidates_processed">Candidates Processed</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_offers_created" name="show_offers_created"
            formControlName="show_offers_created">
          <label class="form-check-label" for="show_offers_created">Offers Created</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_new_candidates" name="show_new_candidates"
            formControlName="show_new_candidates">
          <label class="form-check-label" for="show_new_candidates">New Candidates</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_target_hire_days" name="show_target_hire_days"
            formControlName="show_target_hire_days">
          <label class="form-check-label" for="show_target_hire_days">Target Hire Date</label>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="show_requisition_id" name="show_requisition_id"
            formControlName="show_requisition_id">
          <label class="form-check-label" for="show_requisition_id">Requisition ID</label>
        </div>
      </div>
      <div *ngIf="shouldShowJobStages()">
        <div class="label">Excluded job stages</div>
        <twng-typeahead-input formControlName="excluded_job_stages" [filterItems]="jobStageNames$ | async"
          [placeholder]="'Job stages'">
        </twng-typeahead-input>
      </div>
      <div *ngIf="shouldShowCustomFields()">
        <div class="label">Custom fields to show</div>
        <twng-typeahead-input formControlName="custom_fields_to_show" [filterItems]="customFields$ | async">
        </twng-typeahead-input>
      </div>
    </form>
  </twng-modal-body>
  <twng-modal-footer>
    <span class="text-danger" *ngIf="isDemo">
      Cannot save configuration in demo mode.
    </span>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="form.disabled || form.invalid || isDemo">
      Save
    </button>
  </twng-modal-footer>
</twng-modal>