/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store'

import { Candidate } from '../models/candidate'
import { JobApplication } from '../models/job-application'
import { Scorecard } from '../models/scorecard'

export enum CardDetailsActionTypes {
  FetchCardDetails = '[CardDetails] FetchCardDetails',
  FetchCardDetailsSuccess = '[CardDetails] FetchCardDetailsSuccess',
  FetchCardDetailsFailure = '[CardDetails] FetchCardDetailsFailure',
}

export interface FetchCardDetailsPayload {
  jobApplication: JobApplication
}

export class FetchCardDetails implements Action {
  readonly type = CardDetailsActionTypes.FetchCardDetails
  constructor(public payload: { jobApplication: JobApplication }) { }
}

export interface FetchCardDetailsPayload {
  scorecards: Scorecard[],
  candidate: Candidate,
  jobApplication: JobApplication,
}

export class FetchCardDetailsSuccess implements Action {
  readonly type = CardDetailsActionTypes.FetchCardDetailsSuccess
  constructor(public payload: FetchCardDetailsPayload) { }
}

export class FetchCardDetailsFailure implements Action {
  readonly type = CardDetailsActionTypes.FetchCardDetailsFailure
  constructor() { }
}


export type CardDetailsActions =
  | FetchCardDetails
  | FetchCardDetailsSuccess
  | FetchCardDetailsFailure
