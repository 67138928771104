<div class="prospect-job">
  <h1 class="job-name">{{ prospectJob.name }}</h1>

  <div class="prospect-job__container">
    <table class="table table-striped">
      <thead twngTableSortHandler [tableElements]="sortedProspectsCandidates">
        <tr>
          <th class="table-head" twngTableSort sortByAttribute="name">Name</th>
          <th class="table-head" twngTableSort sortByAttribute="currentTitle">Current Title</th>
          <th class="table-head" twngTableSort sortByAttribute="source">Source</th>
          <th class="table-head" twngTableSort sortByAttribute="prospectOwner">Prospect Owner</th>
          <th class="table-head" twngTableSort sortByAttribute="lastActivity" [comparator]="datesComparator">Last Activity</th>
          <th class="table-head">Link</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let candidate of sortedProspectsCandidates" (click)="rowClick(candidate)" class="table-row">
          <td>{{ candidate.name }}</td>
          <td>{{ candidate.currentTitle }}</td>
          <td>{{ candidate.source }}</td>
          <td>{{ candidate.prospectOwner?.name }}</td>
          <td>{{ candidate.lastActivity | timeAgo }}</td>

          <td (click)="stopPropagation($event)">
            <a class="atsLink" target="_blank" rel="noopener noreferrer"
              href="{{ urlService.jobApplicationUrl(candidate.jobApplication) | async }}"
              ngbTooltip="View in {{ appConfig.atsName() }}">
              <img src="{{ appConfig.atsIconUrl() }}" width="16" height="16" class="tw-rounded" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
