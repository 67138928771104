<div class="wrapper tw-rounded-md">
  <div class="chart-container align-self-stretch">
    <ng-container *ngIf="conversionRates">
      <!-- Processed -->
      <div class="chart chart-processed"
        [style.top]="(100 - totalProcessed() / yAxisMax * 100) + '%'"
        [ngbTooltip]="totalProcessed() + ' processed candidate' + ((totalProcessed() === 1) ? '' : 's')"
        (click)="onProcessedJobStageClick()">
      </div>
      <div class="chart chart-active"
        [style.top]="(100 - (totalProcessed() + conversionRates.active) / yAxisMax * 100) + '%'"
        [ngbTooltip]="conversionRates.active + ' active candidate' + ((conversionRates.active === 1) ? '' : 's')"
        (click)="onActiveJobStageClick()">
      </div>
    </ng-container>
    <div class="passthrough-arrow-wrapper d-flex flex-column align-items-center">
      <twng-candidate-conversion-popover
        [jobStageConversionRates]="conversionRates"
        [isOfferAcceptenceRateStage]="isOfferAcceptenceRateStage"
        *ngIf="conversionRates">
      </twng-candidate-conversion-popover>
      <twng-candidate-conversion-popover
        [jobStageConversionRates]="eeocConversionRates"
        [isOfferAcceptenceRateStage]="isOfferAcceptenceRateStage"
        type="EEOC filtered"
        *ngIf="eeocConversionRates">
      </twng-candidate-conversion-popover>
    </div>
  </div>
  
  
  <span class="dropoff-label secondary-text"
    ngbTooltip="% rejected for the reason type 'They Rejected Us' (all)">
    Dropoff: {{ conversionRates?.dropoff_percent || 0 }}%
  </span>
  
  <span *ngIf="eeocConversionRates" class="dropoff-label dropoff-label-red secondary-text"
    ngbTooltip="% rejected for the reason type 'They Rejected Us' (EEOC Filtered)">
    Dropoff: {{ eeocConversionRates.dropoff_percent || 0 }}%
  </span>
  
  <div class="stage-name secondary-text ellipsis-overflow" [ngbTooltip]="stageName">{{ stageName }}</div>
  
  <hr/>
  
  <div class="d-flex justify-content-center align-items-center">
    <div class="big-num secondary-text" [class.clickable]="conversionRates?.active"
        ngbTooltip="Candidates currently in stage (may include private)" placement="top auto"
        aria-label="Active" (click)="onActiveJobStageClick()">
      <div class="big-num-value">{{ conversionRates?.active || 0 }}</div>
      <div class="big-num-label"><i class="fas fa-circle icon-active"></i> Active</div>
    </div>
    <div class="icon-divider"></div>
    <div class="big-num secondary-text" [class.clickable]="totalProcessed()"
        ngbTooltip="Candidates who advanced or were rejected" placement="top auto" aria-label="Processed"
        (click)="onProcessedJobStageClick()">
      <div class='big-num-value'>{{ totalProcessed() }}</div>
      <div class='big-num-label'><i class="fas fa-circle icon-processed"></i> Processed</div>
    </div>
  </div>
  
  <hr/>
  
  <div class="interview-info d-flex flex-column secondary-text">
    <div class="interview-stat" ngbTooltip="Time Spent Interviewing">
      <i class="fas fa-clock"></i>
      <span class="interview-stat-value">{{ interviewTimeForStage() || 0 }}</span>
      <span class="interview-stat-label">hours</span>
    </div>
  
    <div class="interview-stat" ngbTooltip="Candidates Interviewed">
      <i class="fas fa-user"></i>
      <span class="interview-stat-value">{{ candidatesInterviewed || 0 }}</span>
      <span class="interview-stat-label">interviewed</span>
    </div>
  </div>
</div>