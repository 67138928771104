import { FeatureKey, TrackFeatureKeyState, TrackFeaturesState } from '../reducers/track-features.reducer'
import { createSelector } from '@ngrx/store'
import { differenceInMonths } from 'date-fns'
import { isNil } from 'lodash-es'
import { selectSharedState } from '../state/selectors'

const MAX_MONTHS_TRACKED = 3

export const selectTrackFeaturesState = createSelector(
  selectSharedState,
  (state): TrackFeaturesState => state.trackFeatures
)

const selectTrackedFeature = (featureKey: FeatureKey) => createSelector(
  selectTrackFeaturesState,
  (trackFeaturesState: TrackFeaturesState): TrackFeatureKeyState => trackFeaturesState.seenFeatures
    .find(({ feature }) => feature === featureKey)
)

/**
 * A feature is considered tracked if it has been seen in the last 3 months
 *
 * @param featureKey
 */
export const selectIsFeatureTracked = (featureKey: FeatureKey) => createSelector(
  selectTrackedFeature(featureKey),
  (featureKeyState: TrackFeatureKeyState): boolean => {
    /**
     * If the featureKey is not in the store,
     * then we can't be sure we want to show that feature.
     * In this case, we return as we already seen it.
     */
    if (isNil(featureKeyState)) {
      return true
    }
    // in case of demo, something is messed up with date and time ...
    // but we don't care about that anyway
    if (window.twng_demo) {
      return !!featureKeyState.seen_at
    }
    const seenAt = new Date(featureKeyState.seen_at)
    return differenceInMonths(new Date(), seenAt) <= MAX_MONTHS_TRACKED
  }
)
