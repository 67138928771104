import { JobCustomFields } from './job_custom_fields'
import { JobOpening } from './job-opening'
import { Named } from './named'
import { atsId } from './types'

export class Job implements Named {
  id: atsId
  name: string
  status: 'open' | 'closed'
  opened_at: string
  closed_at: string | null
  priority: string
  employment_type: string

  department_ids?: atsId[]
  office_ids?: atsId[]

  recruiter_ids: atsId[]
  coordinator_ids: atsId[]
  hiring_manager_ids: atsId[]
  sourcer_ids: atsId[]
  primary_recruiter_id: atsId | null

  job_admin_ids: atsId[]

  confidential: boolean
  url?: string
  position: number

  num_hires: number
  // open openings only
  job_openings: JobOpening[]
  // all openings
  total_job_openings: number
  custom_fields: JobCustomFields

  requisition_id: string
}

export const JOB_STATUS_ACTIVE = 'open'
