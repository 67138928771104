import { BehaviorSubject, Observable } from 'rxjs'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CustomFieldFilterOption } from '../models/custom-field-filter-option'
import { CustomFieldsService, PossibleCustomFieldSource } from '../services/custom-fields.service'
import { FilterOption } from '../../shared/components/filters/generic-filter/filter-option.interface'
import { SelectedCustomFieldsFilter } from '../models/selected-custom-fields-filter'
import { first, map, switchMap, tap } from 'rxjs/operators'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-custom-fields-filters',
  templateUrl: './custom-fields-filter.component.html',
  styleUrls: ['./custom-fields-filter.component.scss'],
})
export class CustomFieldsFilterComponent implements OnInit {

  customFieldFilterOptions$: Observable<CustomFieldFilterOption[]>
  selectedFilterOptions$ = new BehaviorSubject<CustomFieldFilterOption[]>([])

  @Input()
    selectedCustomFieldFilterOption: SelectedCustomFieldsFilter[]

  @Input()
    placeholder = 'Search for Custom Fields'

  @Input()
    customFieldType: PossibleCustomFieldSource = 'job'

  @Input()
    disabled = false

  /**
   * Emits the Custom Fields Object that are used to be stored
   * in the backend.
   */
  @Output()
    filterChange = new EventEmitter<SelectedCustomFieldsFilter[]>()

  constructor(private customFieldsService: CustomFieldsService) {
  }

  ngOnInit() {
    this.customFieldsService.mapToCustomFieldFilterOptions(this.selectedCustomFieldFilterOption || []).pipe(
      tap(selectedFilterOptions => this.selectedFilterOptions$.next(selectedFilterOptions)),
      first(),
    ).subscribe()

    this.customFieldFilterOptions$ = this.selectedFilterOptions$.pipe(
      switchMap(() => this.customFieldsService.getFilterOptions(this.customFieldType).pipe(
        map((customFieldOptions) => customFieldOptions.filter(cf => !this.isOptionSelected(cf)))
      )),
    )
  }

  selectFilterOption(filterOption: FilterOption) {
    this.selectedFilterOptions$.next(
      [...this.selectedFilterOptions$.value, filterOption as CustomFieldFilterOption]
    )
    this.emitChange()
  }

  removeFilterOption(filterOptionToRemove: FilterOption) {
    this.selectedFilterOptions$.next(
      this.selectedFilterOptions$.value.filter(filterOption => filterOption.id !== filterOptionToRemove.id)
    )
    this.emitChange()
  }

  emitChange() {
    this.filterChange.emit(
      this.customFieldsService.mapToDashboardCustomFieldsFilter(this.selectedFilterOptions$.value)
    )
  }

  isOptionSelected(filterOption: FilterOption): boolean {
    return this.selectedFilterOptions$.value.some(filterOpt => filterOpt.id === filterOption.id)
  }
}
