import { CandidateInStage } from '../models/candidates-in-stage';
import { CandidatesInStageActions,
  CandidatesInStageBackendFilters, CandidatesInStageTypes
} from '../actions/candidates-in-stage.actions';


export interface CandidatesInStageState {
  loadingInitialData: boolean
  fetchingCandidates: boolean
  candidates: CandidateInStage[]
  filters: CandidatesInStageBackendFilters
}

const initialState: CandidatesInStageState = {
  loadingInitialData: false,
  fetchingCandidates: false,
  candidates: [],
  filters: {
    department_ids: [],
    job_stage_names: [],
    office_ids: [],
    user_ids: [],
  }
}

export function reducer(
  state: CandidatesInStageState = initialState,
  action: CandidatesInStageActions
): CandidatesInStageState {
  switch (action.type) {

    // Initial Load
    case CandidatesInStageTypes.LoadInitialData: {
      return {
        ...state,
        loadingInitialData: true,
        candidates: [],
      };
    }
    case CandidatesInStageTypes.LoadInitialDataSuccess: {
      return {
        ...state,
        loadingInitialData: false,
        candidates: action.payload.candidates,
        filters: action.payload.filters,
      };
    }

    case CandidatesInStageTypes.LoadInitialDataError: {
      return {
        ...state,
        loadingInitialData: false
      }
    }

    // Fetch Candidates
    case CandidatesInStageTypes.FetchCandidatesInStage: {
      return {
        ...state,
        fetchingCandidates: true,
        candidates: [],
      }
    }

    case CandidatesInStageTypes.FetchCandidatesInStageSuccess: {
      return {
        ...state,
        fetchingCandidates: false,
        candidates: action.payload.candidates,
        filters: action.payload.filters,
      }
    }

    case CandidatesInStageTypes.FetchCandidatesInStageError: {
      return {
        ...state,
        fetchingCandidates: false,
      }
    }
    default:
      return state;
  }
}
