import { CallApi } from "../../shared/actions/api.actions"
import { CandidateFilters, JobFilters } from "../reducers/layout.reducer"
import { Chart } from "../../dashboard/models/dashboard-chart"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { InterviewCalendarEvent } from "../models/interview"
import { LoaderActionTypes } from "../../core/actions/loader.actions"
import { Observable, combineLatest, of } from "rxjs"
import { PossibleActivityLevels } from "../models/activity-day-categories"
import {
  PossibleJobSortingTypes,
  WallActionTypes,
  WallDataPaginatedAllJobsFilter,
  WallDataPaginatedCandidateFilter,
  WallDataPaginatedFilterAllJobsPayload,
  WallDataPaginatedFilterExtraInfo,
  WallDataPaginatedFilterTabPayload,
  WallDataPaginatedJobFilter, WallDataPaginatedTabFilter
} from '../actions/wall.actions'
import { SortingOptionType, SortingOptions } from "../models/sorting-options"
import { Store } from "@ngrx/store"
import { Tab } from "../models/tab"
import { apiHost, getHttpPostOptions } from "../../core/http-options"
import { debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators"
import { format } from "date-fns"
import { isEmpty, isEqual, keys, negate, omit, some } from "lodash-es"
import { objValuesSafe } from "../../shared/utils/general-utils"
import { selectActiveTab, selectAllJobsFilters,
  selectAllJobsSortingOptions, selectCandidateFilters
} from "../reducers"

export const API_CLOSED_JOB_NAMES = 'closedJobs'
export const API_OPEN_JOB_NAMES = 'openJobs'
const API_WALL_DATA_PAGINATED = 'wall-data-paginated - '

export const JOBS_PER_PAGE = 20

interface WallChartResponse {
  charts: Record<string, Chart>
}

function candidateFiltersToBackendCandidateFilters(filters: CandidateFilters): WallDataPaginatedCandidateFilter {
  const ret: WallDataPaginatedCandidateFilter = {
    matching_activity_levels: filters.matchingActivityLevel as PossibleActivityLevels[],
    matching_candidate_name: filters.matchingCandidateName,
    matching_credited_to: filters.creditedTo,
    matching_ratings: filters.rating,
    matching_recruiters: filters.recruiters,
    matching_sources: filters.matchingSourceFilter,
    matching_tags: keys(filters.matchingTagFilters).filter((tag) => filters.matchingTagFilters[tag]),
    show_my_candidates_only: filters.myCandidatesOnly,
    show_starred_only: filters.starredOnly,
    show_with_completed_interviews: filters.showInterviewCompleted,
    show_with_scheduled_interviews: filters.showInterviewScheduled,
    show_without_interviews: filters.showNoInterview,
    custom_fields: filters.candidateCustomFields,
  }
  return ret
}

function jobFiltersToBackendJobFilters(filters: JobFilters): WallDataPaginatedJobFilter {
  const ret: WallDataPaginatedJobFilter = {
    custom_fields: filters.custom_fields,
    department_external_ids: filters.department_ids,
    employment_types: filters.employment_types,
    external_user_external_ids: filters.external_user_ids,
    job_external_ids: filters.job_ids,
    job_priorities: filters.job_priorities,
    matching_job_name: filters.matchingJobName,
    office_external_ids: filters.office_ids,
  }
  return ret
}

function anyCandidateFiltersSet(filters: WallDataPaginatedCandidateFilter): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return filters && some(objValuesSafe<any>(filters), v => v === true || !isEmpty(v))
}

function anyJobFiltersSet(filters: JobFilters): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return filters && some(objValuesSafe<any>(filters), negate(isEmpty))
}

const FILTER_DEBOUNCE_TIME = 500

@Injectable({ providedIn: 'root' })
export class WallApiService {
  allJobsFilters$: Observable<WallDataPaginatedAllJobsFilter>
  // this will emit either when you change tab on wall, or when you change
  // actual filters
  currentlyVisibleFilters$: Observable<WallDataPaginatedAllJobsFilter | WallDataPaginatedTabFilter>
  // same as above, except that it doesn't emit when irellevant things are
  // changed, such as sort property
  currentlyVisibleRelevantFilters$: Observable<WallDataPaginatedAllJobsFilter | WallDataPaginatedTabFilter>

  constructor(
    private store: Store,
    private http: HttpClient,
  ) {
    this.allJobsFilters$ = combineLatest([
      store.select(selectCandidateFilters),
      store.select(selectAllJobsFilters),
      store.select(selectAllJobsSortingOptions),
    ]).pipe(
      debounceTime(FILTER_DEBOUNCE_TIME),
      map(([candidateFilters, jobFilters, sortingOptions]) => {
        const sortOption: PossibleJobSortingTypes = this.getSortOption(sortingOptions)
        const ret: WallDataPaginatedAllJobsFilter = {
          candidate_filter: candidateFiltersToBackendCandidateFilters(candidateFilters),
          job_filter: anyJobFiltersSet(jobFilters) ? jobFiltersToBackendJobFilters(jobFilters) : null,
          sort_by: sortOption,
          sort_dir: sortingOptions.direction,
        }
        return ret
      }))
    this.currentlyVisibleFilters$ = store.select(selectActiveTab).pipe(
      distinctUntilChanged(isEqual),
      switchMap(tab => {
        if (tab) {
          return this.getTabJobFilters$(of(tab))
        } else {
          return this.allJobsFilters$
        }
      }),
      distinctUntilChanged(isEqual),
    )
    this.currentlyVisibleRelevantFilters$ = this.currentlyVisibleFilters$.pipe(
      distinctUntilChanged((a, b) => {
        const propertiesToIgnore = ['sort_by', 'sort_dir', 'frontendFetchId']
        return isEqual(omit(a, ...propertiesToIgnore), omit(b, ...propertiesToIgnore))
      })
    )
  }

  getSharedTabJobFilters$(tabId: string, sharableToken: string) {
    return this.store.select(selectCandidateFilters).pipe(
      debounceTime(FILTER_DEBOUNCE_TIME),
      map((candidateFilters) => {
        const ret: WallDataPaginatedTabFilter = {
          candidate_filter: candidateFiltersToBackendCandidateFilters(candidateFilters),
          tab_id: tabId,
          tab_sharable_token: sharableToken,
        }
        return ret
      })
    )
  }

  getTabJobFilters$(tab$: Observable<Tab>) {
    return combineLatest([
      tab$,
      this.store.select(selectCandidateFilters).pipe(debounceTime(FILTER_DEBOUNCE_TIME)),
      this.store.select(selectAllJobsSortingOptions),
    ]).pipe(map(([tab, candidateFilters, sortingOptions]) => {
      if (!tab) {
        return
      }

      const sortOption: PossibleJobSortingTypes = this.getSortOption(sortingOptions)
      const ret: WallDataPaginatedTabFilter = {
        candidate_filter: candidateFiltersToBackendCandidateFilters(candidateFilters),
        tab_id: tab.id.toString(),
        sort_by: sortOption,
        sort_dir: sortingOptions.direction,
      }
      return ret
    }))
  }

  private getSortOption(sortingOptions: SortingOptions) {
    if (sortingOptions.type === SortingOptionType.Name) {
      return 'name'
    } else if (sortingOptions.type === SortingOptionType.EmploymentType) {
      return 'employment_type'
    } else if (sortingOptions.type === SortingOptionType.Priority) {
      return 'priority'
    }
  }
  getJobFilterForSingleJob(jobId: string) {
    const ret: WallDataPaginatedAllJobsFilter = {
      candidate_filter: null,
      job_filter: {
        job_external_ids: [jobId]
      } as WallDataPaginatedJobFilter,
      sort_by: null,
      sort_dir: null,
    }
    return ret
  }

  getFetchJobsPaginatedAction(filters: WallDataPaginatedAllJobsFilter, page: number, fetchCreditedTo: boolean) {
    const payload: WallDataPaginatedFilterAllJobsPayload = {
      ...filters,
      page,
      per_page: JOBS_PER_PAGE,
      show_jobs_without_apps: !anyCandidateFiltersSet(filters.candidate_filter),
      fetch_credited_to_ids: fetchCreditedTo,
    }
    return new CallApi({
      startAction: {
        type: WallActionTypes.FetchWallDataPaginatedAction,
        payload
      },
      apiName: API_WALL_DATA_PAGINATED + JSON.stringify(payload),
      successAction: WallActionTypes.FetchWallDataPaginatedSuccessAction,
      failureAction: WallActionTypes.FetchWallDataPaginatedFailureAction,
      doAlways: true,
    })
  }

  getFetchTabJobsPaginatedAction(filters: WallDataPaginatedTabFilter, page: number, fetchCreditedTo: boolean) {
    const payload: WallDataPaginatedFilterTabPayload = {
      ...filters,
      page,
      per_page: JOBS_PER_PAGE,
      show_jobs_without_apps: !anyCandidateFiltersSet(filters.candidate_filter),
      fetch_credited_to_ids: fetchCreditedTo,
    }
    return new CallApi({
      startAction: {
        type: WallActionTypes.FetchWallDataPaginatedTabAction,
        payload
      },
      apiName: API_WALL_DATA_PAGINATED + JSON.stringify(payload),
      successAction: WallActionTypes.FetchWallDataPaginatedTabSuccessAction,
      failureAction: WallActionTypes.FetchWallDataPaginatedTabFailureAction,
      doAlways: true,
    })
  }

  getFetchClosedJobNamesAction() {
    return new CallApi({
      startAction: {
        type: LoaderActionTypes.LoadClosedJobNames
      },
      apiName: API_CLOSED_JOB_NAMES,
      failureAction: LoaderActionTypes.LoadClosedJobNamesFailure,
      successAction: LoaderActionTypes.LoadClosedJobNamesSuccess,
    })
  }

  getFetchOpenJobNamesAction() {
    return new CallApi({
      startAction: {
        type: LoaderActionTypes.LoadOpenJobNames
      },
      apiName: API_OPEN_JOB_NAMES,
      failureAction: LoaderActionTypes.LoadOpenJobNamesFailure,
      successAction: LoaderActionTypes.LoadOpenJobNamesSuccess,
    })
  }

  getUpcomingInterviews(filters: WallDataPaginatedAllJobsFilter | WallDataPaginatedTabFilter, start: Date, end: Date) {
    return this.http.post<InterviewCalendarEvent[]>(apiHost + '/twng/wall/interviews.json', {
      ...filters,
      start_date: format(start, "yyyy-MM-dd"),
      end_date: format(end, "yyyy-MM-dd"),
    }, getHttpPostOptions())
  }

  getJobApplicationInterviews(job_application_id: string) {
    return this.http.post<InterviewCalendarEvent[]>(apiHost + '/twng/wall/interviews_by_application.json', {
      job_application_id,
    }, getHttpPostOptions())
  }

  getWallCharts(filters: WallDataPaginatedAllJobsFilter | WallDataPaginatedTabFilter) {
    const body: WallDataPaginatedFilterExtraInfo = {
      ...filters,
      show_jobs_without_apps: !anyCandidateFiltersSet(filters.candidate_filter),
      page: 0,
      per_page: -1,
      fetch_credited_to_ids: false,
    }
    return this.http.post<WallChartResponse>(apiHost + '/twng/wall/charts', body, getHttpPostOptions())
  }
}
