
import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output
} from '@angular/core'

import { UpdateJobStageCategoryPayload } from '../../actions/stage-mappings.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-job-stage-name-list',
  templateUrl: './job-stage-name-list.component.html',
  styleUrls: ['./job-stage-name-list.component.scss']
})
export class JobStageNameListComponent {

  @Input()
    jonStageNames: string[]

  @Output()
    updateCategory = new EventEmitter<UpdateJobStageCategoryPayload>()

  constructor() {
  }

  updateStageCategory(event) {
    if (event.jobStageName) {
      this.updateCategory.emit({
        jobStageName: event.jobStageName,
        jobStageCategoryIdFrom: event.jobStageCategoryId,
        jobStageCategoryIdTo: null,
      })
    }
  }
}
