<twng-modal>
  <twng-modal-header>
    <twng-modal-title slot="header-title">Drag tabs to sort them</twng-modal-title>
  </twng-modal-header>
  <twng-modal-body>
    <div *ngIf="(tabs$ | async) as tabs" appDroppable (dropping)="updateTabPosition($event, tabs)" class="tw-py-8">
      <twng-sort-tab *ngFor="let tab of tabs; index as i" [tab]="tab" [index]="i">
      </twng-sort-tab>
    </div>
  </twng-modal-body>
  <twng-modal-footer>
    <button type="submit" (click)="closeModal()" class="btn btn-primary">Done</button>
  </twng-modal-footer>
</twng-modal>