import { Subject, Subscription, combineLatest } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit
} from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../../wall/reducers'
import { AddNote } from '../../wall/actions/notes.actions'
import { AppConfigService } from '../../wall/services/app-config.service'
import { Candidate } from '../../wall/models/candidate'
import { ExternalUser } from '../../wall/models/external-user'
import { Note } from '../../wall/models/note'
import { NotesUI } from '../reducers/notes-ui.reducer'
import { SegmentService } from '../../core/services/segment.service'
import { UpdateNotesUI } from '../actions/notes-ui.actions'
import { User } from '../../wall/models/user'
import { default as removeDeletedMentionsFromText } from '../../shared/utils/deleted-mentions'
import { selectNotesUI } from '../reducers'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-note-panel',
  styleUrls: [
    './card-details-modal.component.scss',
    './note-panel.component.scss',
  ],
  templateUrl: './note-panel.component.html',
})
export class NotePanelComponent implements OnInit, OnDestroy {
  @Input()
    candidate: Candidate
  @Input()
    user: User

  notesSub: Subscription
  notes: Note[]

  notesUiSub: Subscription

  users: ExternalUser[]

  body = ''
  public = false
  formEnabled = true
  mentionedUsers: Set<ExternalUser> = new Set<ExternalUser>()

  textAreaKeyUp$: Subject<KeyboardEvent> = new Subject<KeyboardEvent>()

  constructor(
    private store: Store<fromWall.State>,
    private cd: ChangeDetectorRef,
    public appConfig: AppConfigService,
    private segmentService: SegmentService,
  ) { }

  ngOnInit(): void {
    this.textAreaKeyUp$
      .pipe(
        debounceTime(500),
      )
      .subscribe((event: KeyboardEvent) => this.onBodyKeyUp(event))

    this.notesSub = combineLatest([
      this.store.select(fromWall.selectAllNotes),
      this.store.select(fromWall.selectExternalUserEntities),
      this.store.select(fromWall.selectActiveExternalUsers),
    ]).subscribe(([notes, userEntities, externalUsers]) => {
      this.users = externalUsers
      this.notes = notes
        // find notes for this candidate only
        .filter(note => note.candidate_id === this.candidate.id)
        // patch in the external user
        .map(note => ({ ...note, external_user: userEntities[note.user_id] }))

      // since this is the result of an effect, we need to manually markForCheck
      this.cd.markForCheck()
    })

    this.notesUiSub = this.store
      .select(selectNotesUI)
      .subscribe(notesUIForApp => {
        const notesUI: NotesUI = notesUIForApp[this.candidate.id]
        if (notesUI) {
          this.formEnabled = notesUI.submitEnabled
          this.public = notesUI.isPublic
          this.body = notesUI.body
          this.mentionedUsers = new Set(notesUI.mentionedUsers)
          this.cd.markForCheck()
        }
      })

  }

  ngOnDestroy(): void {
    this.notesSub.unsubscribe()
    this.notesUiSub.unsubscribe()
  }

  onBodyKeyUp(event: KeyboardEvent): void {
    const textArea = event.target as HTMLInputElement
    const textAreaBody = textArea.value
    const currentPosition: number = textArea.selectionStart
    const eventCode = event.code

    if (eventCode === 'Backspace' && this.mentionedUsers.size > 0) {
      const { newBody, removedMentionedUsers } = removeDeletedMentionsFromText(
        textAreaBody,
        currentPosition,
        this.mentionedUsers,
      )
      removedMentionedUsers.forEach(mentionedUser => {
        this.mentionedUsers.delete(mentionedUser)
      })

      this.store.dispatch(
        new UpdateNotesUI({
          body: newBody,
          candidate_id: this.candidate.id,
          mentionedUsers: Array.from(this.mentionedUsers),
        }),
      )
    } else {
      this.store.dispatch(
        new UpdateNotesUI({
          body: textAreaBody,
          candidate_id: this.candidate.id,
        }),
      )
    }
  }

  onTextAreaKeyUp(event: KeyboardEvent) {
    this.textAreaKeyUp$.next(event)
  }

  onBodyChange(event: Event) {
    const newBody = (event.target as HTMLTextAreaElement).value
    this.store.dispatch(
      new UpdateNotesUI({
        body: newBody,
        candidate_id: this.candidate.id,
      }),
    )
  }

  onPublicChange(isPublic: boolean): void {
    this.store.dispatch(
      new UpdateNotesUI({
        candidate_id: this.candidate.id,
        isPublic,
      }),
    )
  }

  addNote(): void {
    this.store.dispatch(
      new AddNote({
        body: this.body,
        isPublic: this.public,
        candidate_id: this.candidate.id,
        mentionedUsers: Array.from(this.mentionedUsers),
      }),
    )
    this.segmentService.track("Add Note",
      { is_public: this.public, mentioned_users: Array.from(this.mentionedUsers).length }
    )
  }

  // Format the mention before insertion into text area
  mentionSelect(item: ExternalUser, host: NotePanelComponent): string {
    host.addUser(item)
    return '@' + item['name']
  }

  addUser(item: ExternalUser): void {
    this.mentionedUsers.add(item)
    this.store.dispatch(
      new UpdateNotesUI({
        candidate_id: this.candidate.id,
        mentionedUsers: Array.from(this.mentionedUsers),
      }),
    )
  }

}
