import { ProjectedHiresActionTypes, ProjectedHiresActions } from "../actions/projected-hires.actions"
import { ProjectedHiresResponse } from "../models/projected-hires"

export interface State {
  projectedHires: ProjectedHiresResponse,
  isFetching: boolean,
  didFetch: boolean,
}

const initialState = {
  projectedHires: {},
  isFetching: false,
  didFetch: false,
}

export function reducer(
  state: State = initialState,
  action: ProjectedHiresActions,
): State {
  switch (action.type) {
    case ProjectedHiresActionTypes.FetchAllProjectedHires:
      return {
        ...state,
        isFetching: true,
      }
    case ProjectedHiresActionTypes.FetchAllProjectedHiresSuccess:
    case ProjectedHiresActionTypes.UpdateHiringPredictionsSuccess:
      return {
        ...state,
        projectedHires: {
          ...state.projectedHires,
          ...action.payload,
        },
        isFetching: false,
        didFetch: true,
      }
    case ProjectedHiresActionTypes.FetchAllProjectedHiresFailure:
      return {
        ...state,
        isFetching: false,
        didFetch: false,
      }
    default:
      return state
  }
}
