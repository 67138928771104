import { BehaviorSubject } from "rxjs"
import { Injectable } from "@angular/core"
import { debounceTime, map } from "rxjs/operators"

@Injectable({providedIn: 'root'})
export class PdfGlobalService {
  // The value is set from pdf export overlay component
  globalPdfExporting$ = new BehaviorSubject<boolean>(false)
  private unreadyComponents$ = new BehaviorSubject<number>(0)
  allComponentsReady$ = this.unreadyComponents$.pipe(map(v => v === 0), debounceTime(200))

  componentIsNotReady() {
    this.unreadyComponents$.next(this.unreadyComponents$.value + 1)
  }

  componentIsReady() {
    if (this.unreadyComponents$.value === 0) {
      throw new Error("Component ready called before component not ready.")
    }
    this.unreadyComponents$.next(this.unreadyComponents$.value - 1)
  }
}
